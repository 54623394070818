define('webui/pods/reset/password/route', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    feedback: Ember.inject.service('user-feedback'),

    model: function model(params) {
      var model = {};

      model.token = params.token;

      return model;
    },
    afterModel: function afterModel(model) {
      var resetToken = model.token;
      var url = _environment.default.APP.apiUrl + 'users/reset';
      var route = this;

      $.ajax({
        method: 'POST',
        contentType: 'json',
        url: url,
        data: JSON.stringify({
          resetToken: resetToken,
          onlyValidate: true
        }),
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).done(function () /* response */{
        // console.log('token still valid?', response);
      }).fail(function (response) {
        var feedback = route.get('feedback');

        console.error(response);
        if (response.status === 403) {
          route.transitionTo('reset.expired');
        } else {
          feedback.reportError(response.responseJSON);
        }
      });
    },


    actions: {
      reset: function reset(newPassword, confirmationPassword, resetToken) {
        var url = _environment.default.APP.apiUrl + 'users/reset';
        var route = this;

        if (!newPassword || newPassword.length < 8) {
          route.controller.set('passwordError', 'Password isn’t long enough. Must be at least 8 characters long.');
          return;
        }
        route.controller.set('passwordError', null);

        if (newPassword !== confirmationPassword) {
          route.controller.set('confirmationError', 'Passwords don’t match.');
          return;
        }
        route.controller.set('confirmationError', null);

        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          data: JSON.stringify({
            newPassword: newPassword,
            confirmationPassword: confirmationPassword,
            resetToken: resetToken,
            onlyValidate: false
          }),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).done(function () {
          route.controller.set('successMessage', true);
          setTimeout(function () {
            route.transitionTo('login');
          }, 3500);
        }).fail(function (response) {
          var feedback = route.get('feedback');

          console.error(response);
          if (response.status === 403) {
            route.transitionTo('reset.expired');
          } else {
            feedback.reportError(response.responseJSON);
          }
        });
      }
    }
  });
});