define('webui/pods/components/region-earthwork-data-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settingsManager: Ember.inject.service('settings-manager'),
    store: Ember.inject.service(),
    feedback: Ember.inject.service('user-feedback'),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('earthwork.status') === 'failed') {
        // TODO: disabled for now due to fuzziness with GIS error states etc
        // this.get('feedback').reportError(this.get('earthwork.messageDisplay'));
      }
    },


    actions: {
      calculateEarthwork: function calculateEarthwork(earthwork) {
        var currentEarthwork = this.get('store').peekRecord('earthwork-analysis', earthwork.id);
        if (currentEarthwork) {
          currentEarthwork.set('status', 'pending');
          currentEarthwork.save();
        }
      }
    }
  });
});