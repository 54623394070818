define('webui/pods/components/admin-flight-form/component', ['exports', 'webui/mixins/error-message'], function (exports, _errorMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorMessage.default, {
    doTransition: 'doTransition',
    store: Ember.inject.service(),
    showDisableMessage: false,
    showEnableMessage: false,
    showDeleteMessage: false,
    feedback: Ember.inject.service('user-feedback'),

    init: function init() {
      this._super();

      this.set('showDisableMessage', false);
      this.set('showEnableMessage', false);
      this.set('showDeleteMessage', false);
      this.set('disableError', false);
      this.set('deleteError', false);
      this.set('sortedSites', this.get('store').peekAll('site').sortBy('name'));
    },


    actions: {
      submitModel: function submitModel() {
        var component = this;

        this.get('model.flight').save().then(this.setMessage('Flight saved')).catch(function (error) {
          component.setError(error);
        });
      },
      selectSite: function selectSite(siteId) {
        var store = this.get('store');
        var model = this.get('model');
        var selectedSite = store.peekRecord('site', siteId);
        model.flight.set('site', selectedSite);
      },
      selectLockStatus: function selectLockStatus(lockStatus) {
        var model = this.get('model');
        model.flight.set('locked', lockStatus === 'locked');
      },
      toggleDisableWarning: function toggleDisableWarning() {
        if (this.get('showDisableMessage') === true) {
          this.set('showDisableMessage', false);
        } else {
          this.set('showDisableMessage', true);
        }
      },
      disableFlight: function disableFlight() {
        var component = this;
        component.get('store').findRecord('flight', component.get('model.flight.id')).then(function (updateFlight) {
          updateFlight.set('disabled', !component.get('model.flight.disabled'));
          updateFlight.save().then(function () {
            component.set('showDisableMessage', false);
          }).catch(function () {
            component.set('showDisableMessage', false);
          });
        });
      },
      toggleDeleteWarning: function toggleDeleteWarning() {
        if (this.get('showDeleteMessage') === true) {
          this.set('showDeleteMessage', false);
        } else {
          this.set('showDeleteMessage', true);
        }
      },
      deleteFlight: function deleteFlight() {
        var _this = this;

        var component = this;var site = component.get('model.flight.site.id');

        component.get('store').findRecord('flight', component.get('model.flight.id'), { reload: true }).then(function (updateFlight) {
          updateFlight.deleteRecord();
          updateFlight.save().then(function () {
            _this.get('feedback').reportSuccess({ title: 'Success', detail: 'Flight was successfully deleted' });
            component.sendAction('doTransition', 'admin.site.edit', site);
          }).catch(function () {
            component.set('showDeleteMessage', false);
          });
        });
      }
    }
  });
});