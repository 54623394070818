define('webui/pods/login/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    hasErrors: false,
    errorMessage: '',
    formControlClass: function formControlClass() {
      var errorClass = this.get('hasErrors') ? ' error-outline' : '';
      return 'form-control' + errorClass;
    }.property('hasErrors'),

    session: Ember.inject.service('session'),

    init: function init() {
      this._super();

      var displaySessionTimeoutError = document.referrer.indexOf(location.protocol + '//' + location.host) === 0 && location.protocol + '//' + location.host + '/' !== document.referrer && document.referrer.indexOf('login') === -1 && document.referrer.indexOf('logout') === -1;

      if (displaySessionTimeoutError) {
        this.set('errorMessage', 'Your session has timed out. Please login to continue.');
        this.set('hasErrors', true);
      }
    },


    actions: {
      authenticate: function authenticate() {
        var self = this;

        var _getProperties = this.getProperties('email', 'password'),
            email = _getProperties.email,
            password = _getProperties.password;

        return this.get('session').authenticate('authenticator:custom', { email: email, password: password, type: 'siteIQ' }).then(function () {
          self.set('hasErrors', false);
          return self.transitionToRoute('index');
        }, function (err) {
          var serverResponse = err.responseJSON;
          var errMessage = '';
          if (serverResponse && serverResponse.detail) {
            // If the server sent reponse in expected error format, show that error`
            errMessage = ' ' + serverResponse.detail;
          } else if (err.status === 403) {
            // If forbidden and we don't have error in the expected format show a generic error
            errMessage = 'Invalid credentials. Try again with valid email and password.';
          } else {
            // if not authentication error the most likely case is server is down
            errMessage = 'Server Error. Please try again in a few minutes';
          }
          self.set('errorMessage', errMessage);
          return self.set('hasErrors', true);
        });
      }
    }
  });
});