define('webui/pods/components/cross-sections-chart/component', ['exports', 'npm:chartist', 'webui/mixins/chartist-legend', 'webui/mixins/region-manager', 'webui/mixins/string-formatting', 'npm:chartist-plugin-legend', 'npm:chartist-plugin-zoom', 'npm:jquery-nearest'], function (exports, _npmChartist, _chartistLegend, _regionManager, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_chartistLegend.default, _regionManager.default, _stringFormatting.default, {
    dataType: 'cross-section',
    store: Ember.inject.service('store'),
    mapData: Ember.inject.service('mapData'),
    lastIndex: null,
    currentIndex: null,
    chartIsZoomedIn: false,
    resetFunction: null,
    chart: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortedSiteGrades', this.get('siteGrades').sortBy('name'));
    },
    setCurrent: function setCurrent() {
      this.set('currentGeo', this.get('section.points'));
      this.set('currentSeries', this.get('section.report.legend'));
      this.set('currentStatus', this.get('section.status'));
    },
    didInsertElement: function didInsertElement() {
      this.checkIfRenderIsNeeded();
      this.setupSurfaceSelectPicker();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('section.status') === 'pending') {
        this.removeChart();
      }

      this.checkIfRenderIsNeeded();
      this.setupSurfaceSelectPicker();
    },
    willDestroyElement: function willDestroyElement() {
      var id = this.get('section.id');
      if ($('#surface-selector-' + id)) {
        $('#surface-selector-' + id).selectpicker('destroy');
      }
    },
    removeChart: function removeChart() {
      this.get('chart').detach();
      var chartElem = document.querySelector('#chart-' + this.get('section.id') + ' svg');
      if (chartElem) {
        chartElem.remove();
      }
    },
    checkIfRenderIsNeeded: function checkIfRenderIsNeeded() {
      var acceptableStatus = this.get('section.status') === 'succeeded' || this.get('section.status') === 'warning';
      var chartHasData = this.get('section.report.series');

      if (acceptableStatus && chartHasData) {
        this.setCurrent();
        this.renderChart();
      }
    },
    getSeries: function getSeries() {
      var series = [];

      for (var j = 0; j < this.get('section.report.series').length; j += 1) {
        var ser = this.get('section.report.series')[j];
        ser.className = 'color-series-' + j;
        series.push(ser);
      }

      return series;
    },
    renderChart: function renderChart() {
      var _this = this;

      if (document.getElementById('legend-' + this.get('section.id'))) {
        document.getElementById('legend-' + this.get('section.id')).innerHTML = '';
      }

      var component = this;
      var series = this.getSeries();
      var tickCount = 0;

      var indexOfFirstFlight = component.get('section.blueprints.length');
      var indexOfLastFlight = component.get('section.report.legend').length - 1;

      function SuppressForeignObjectPlugin(chart) {
        chart.supportsForeignObject = false;
      }

      var chart = new _npmChartist.default.Line('#chart-' + component.get('section.id'), {
        series: [series[indexOfFirstFlight], series[indexOfLastFlight]]
      }, {
        showArea: false,
        showLine: true,
        showPoint: true,
        lineSmooth: false,
        onlyInteger: false,
        axisX: {
          showLabel: false,
          showGrid: true,
          type: _npmChartist.default.FixedScaleAxis,
          ticks: component.get('section.report.series') && component.get('section.report.series')[0] ? component.get('section.report.series')[0].map(function () {
            tickCount += 1;
            return tickCount - 1;
          }) : 0
        },
        axisY: {
          type: _npmChartist.default.AutoScaleAxis
        },
        low: 0,
        plugins: [SuppressForeignObjectPlugin, component.LegendPlugin({
          legendNames: component.get('section.report.legend').map(function (name) {
            return _this.dateTimeString(name, '/', ':', '—', true).indexOf('NaN') !== -1 ? name : _this.dateTimeString(name, '/', ':', '—', true);
          }),
          position: document.getElementById('legend-' + this.get('section.id')),
          container: '#legend-' + this.get('section.id'),
          removeAll: true,
          startingSeries: [indexOfFirstFlight, indexOfLastFlight],
          series: series
        }), _npmChartist.default.plugins.zoom({
          pointClipOffset: 5,
          onZoom: function onZoom(zoomChart, reset) {
            component.set('resetFunction', reset);component.set('chartIsZoomedIn', true);
          }
        })]
      });

      this.set('chart', chart);

      chart.on('created', function () {
        var formatData = function formatData(num) {
          return num < 10 && num >= 0 ? '0' + String(num) : String(num);
        };
        var mouseClicked = false;
        var mouseInChart = false;

        var removeHoverDisplay = function removeHoverDisplay() {
          if (component.get('lastIndex')) {
            $('#chart-' + component.get('section.id') + ' .ct-grid.ct-horizontal:nth-of-type(' + component.get('lastIndex') + ')').css('stroke-width', 0);
          }
          component.get('crossSectionMarker').setLngLat([0, 0]);

          var flightCounter = 0;
          var legendSelector = '#legend-' + component.get('section.id') + ' .ct-series-';
          var legendValue = $(legendSelector + flightCounter).html();
          var data = component.get('section.report.series');
          while (legendValue && data.length) {
            var legendValueLength = legendValue.indexOf(' —— ') !== -1 ? legendValue.indexOf(' —— ') : legendValue.length;
            $(legendSelector + flightCounter).html(legendValue.substring(0, legendValueLength));

            flightCounter += 1;
            legendValue = $(legendSelector + flightCounter).html();
          }
        };

        $('#chart-' + component.get('section.id')).on('mousedown', null, function () {
          mouseClicked = true;
          removeHoverDisplay();
        });

        $('#chart-' + component.get('section.id')).on('mouseup', null, function () {
          mouseClicked = false;
          removeHoverDisplay();
        });

        $('#chart-' + component.get('section.id')).on('mouseleave', null, function () {
          mouseInChart = false;
          removeHoverDisplay();
        });

        $('#chart-' + component.get('section.id')).on('mouseenter', null, function () {
          mouseInChart = true;
        });

        $('#chart-' + component.get('section.id')).on('mousemove', null, _.debounce(function (event) {
          if (!mouseInChart || mouseClicked) {
            return;
          }

          var nearest = $.nearest({ x: event.clientX, y: event.clientY }, '#chart-' + component.get('section.id') + ' .ct-series:first .ct-point', { onlyX: true, conainer: '#chart-' + component.get('section.id') + ' .ct-series:first' }).attr('ct:value');
          if (nearest) {
            var index = nearest.split(',')[0];
            var indexdata = index;
            index = parseInt(index, 10) + 1;
            if (component.get('lastIndex')) {
              $('#chart-' + component.get('section.id') + ' .ct-grid.ct-horizontal:nth-of-type(' + component.get('lastIndex') + ')').css('stroke-width', 0);
            }

            var griditem = $('#chart-' + component.get('section.id') + ' .ct-grid.ct-horizontal:nth-of-type(' + index + ')');
            var rect1 = griditem[0].getBoundingClientRect();
            var rect2 = $('#chart-' + component.get('section.id') + ' .ct-labels')[0].getBoundingClientRect();
            var overlap = rect1.left < rect2.right;
            if (overlap) {
              return;
            }

            griditem.css('stroke-width', 2);
            component.set('lastIndex', index);

            var flightCounter = 0;
            var legendSelector = '#legend-' + component.get('section.id') + ' .ct-series-';
            var legendValue = $(legendSelector + flightCounter).html();
            var data = component.get('section.report.series');
            while (legendValue && data.length) {
              var legendValueLength = legendValue.indexOf(' —— ') !== -1 ? legendValue.indexOf(' —— ') : legendValue.length;
              if (data[flightCounter][indexdata].y) {
                $(legendSelector + flightCounter).html(legendValue.substring(0, legendValueLength) + ' \u2014\u2014 ' + formatData(data[flightCounter][indexdata].y));
              } else {
                $(legendSelector + flightCounter).html('' + legendValue.substring(0, legendValueLength));
              }

              flightCounter += 1;
              legendValue = $(legendSelector + flightCounter).html();
            }

            component.get('crossSectionMarker').setLngLat([component.get('section.chunks')[indexdata].lng, component.get('section.chunks')[indexdata].lat]);
          }
        }, 1));
      });
    },
    setupSurfaceSelectPicker: function setupSurfaceSelectPicker() {
      var context = this;

      var _get = this.get('section'),
          id = _get.id;

      if (this.get('isReadOnly')) {
        return;
      }

      $('#surface-selector-' + id).selectpicker();
      $('#surface-selector-' + id).on('changed.bs.select', function () {
        context.set('doesSectionContainUnsavedChanges', true);
      });

      Ember.run.next(function () {
        var selectPicker = document.querySelector('#surface-selector-' + id).querySelectorAll('option');
        selectPicker.forEach(function (option) {
          if (context.get('section.blueprints').map(function (surface) {
            return surface.id;
          }).indexOf(option.value) !== -1) {
            option.selected = true;
          } else {
            option.selected = false;
          }
        });

        $('#surface-selector-' + id).selectpicker('refresh');
      });
    },
    selectSiteGrade: function selectSiteGrade(blueprintIds) {
      var store = this.get('store');
      var section = this.get('section');
      var blueprints = store.peekAll('blueprint').filter(function (blueprint) {
        return blueprintIds.indexOf(blueprint.id) !== -1;
      });
      section.set('status', 'pending');
      section.set('report.series', null);
      section.set('blueprints', blueprints);
      section.save();
    },


    removeRegion: 'removeRegion',
    toggleSectionDisplay: 'toggleSectionDisplay',

    actions: {
      removeRegion: function removeRegion(region) {
        this.sendAction('removeRegion', region);
      },
      toggleSectionDisplay: function toggleSectionDisplay(region) {
        this.sendAction('toggleSectionDisplay', region);
      },
      changeName: function changeName(id) {
        // TODO aso needs triggered when enter is pressed
        this.get('store').findRecord('crossSection', id).then(function (section) {
          section.save();
        });
      },
      saveChanges: function saveChanges() {
        var id = this.get('section.id');
        var selectedSurfaces = $('#surface-selector-' + id).val() || [];
        this.set('doesSectionContainUnsavedChanges', false);
        this.selectSiteGrade(selectedSurfaces);
      },
      discardChanges: function discardChanges() {
        var _this2 = this;

        var id = this.get('section.id');
        this.set('doesSectionContainUnsavedChanges', false);
        var selectPicker = document.querySelector('#surface-selector-' + id).querySelectorAll('option');
        selectPicker.forEach(function (option) {
          if (_this2.get('section.blueprints').map(function (surface) {
            return surface.id;
          }).indexOf(option.value) !== -1) {
            option.selected = true;
          } else {
            option.selected = false;
          }
        });
        Ember.run.next(function () {
          $('#surface-selector-' + id).selectpicker('refresh');
        });
      },
      resetChart: function resetChart() {
        this.resetFunction();
        this.set('chartIsZoomedIn', false);
      },
      selectAll: function selectAll() {
        var chart = this.get('chart');
        chart.data.series = this.getSeries();
        chart.update();
      },
      deselectAll: function deselectAll() {
        var chart = this.get('chart');
        chart.data.series = [];
        chart.update();
      },
      toggleIsShowingConfirmation: function toggleIsShowingConfirmation() {
        this.toggleProperty('isShowingConfirmation');
      }
    }
  });
});