define("webui/pods/site-file-manager/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 2
          }
        },
        "moduleName": "webui/pods/site-file-manager/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "file-manager", [], ["site", ["subexpr", "@mut", [["get", "model.site", ["loc", [null, [2, 7], [2, 17]]]]], [], []], "availableSites", ["subexpr", "@mut", [["get", "model.availableSites", ["loc", [null, [3, 17], [3, 37]]]]], [], []], "startingMode", ["subexpr", "@mut", [["get", "model.startingMode", ["loc", [null, [4, 15], [4, 33]]]]], [], []], "doTransition", "doTransition", "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [6, 9], [6, 13]]]]], [], []]], ["loc", [null, [1, 0], [7, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});