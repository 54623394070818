define('webui/pods/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),

    init: function init() {
      setInterval(function () {
        if (!io.socket.isConnected() && !io.socket.isConnecting()) {
          io.socket.reconnect();
        }
      }, 10000);
    },


    actions: {
      invalidateSession: function invalidateSession() {
        this.transitionToRoute('logout');
      }
    }
  });
});