define('webui/pods/components/confirm-button-pop-up/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    actions: {
      prompt: function prompt() {
        var el = this.get('element');
        var $btn = $('.confirm-button-pop-up', el);

        $btn.fadeIn();
      },
      cancel: function cancel() {
        var el = this.get('element');
        var $confirm = $('.confirm-button-pop-up', el);
        $confirm.fadeOut();
      },
      confirmAction: function confirmAction() {
        var el = this.get('element');
        var $confirm = $('.confirm-button-pop-up', el);
        $confirm.fadeOut();

        this.sendAction('action', this.get('job') ? this.get('job') : this.get('model'));
      }
    }
  });
});