define('webui/models/cross-section', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    name: _emberData.default.attr('string'),
    display: true,
    points: _emberData.default.attr(),
    chunks: _emberData.default.attr(),
    totalLength: _emberData.default.attr('number'),
    site: _emberData.default.belongsTo('site', { async: true }),
    blueprints: _emberData.default.hasMany('blueprint', { async: true }),
    report: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),

    mapboxPointFeature: Ember.computed('name', 'chunks', function mapboxPointFeature() {
      var coords = this.get('chunks').map(function (point) {
        return [point.lng, point.lat];
      });
      var center = coords[Math.round(coords.length / 2)];

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: center
        },
        properties: {
          name: this.get('name'),
          title: this.get('name')
        }
      };
    }),
    messageDisplay: Ember.computed('message', function messageDisplay() {
      var _this = this;

      var message = '';
      var combinedMessages = '';

      if (Array.isArray(this.get('message.message'))) {
        var messages = this.get('message.message');
        var messagesWithFormattedFlightDates = messages.map(function (mapMessage) {
          var messageWithFormatedFlight = mapMessage;
          messageWithFormatedFlight.flight = _this.dateTimeString(mapMessage.flight, '/', ':', '—', true).indexOf('NaN') !== -1 ? mapMessage.flight : _this.dateTimeString(mapMessage.flight, '/', ':', '—', true);
          return messageWithFormatedFlight;
        });
        var stringifiedMessages = messagesWithFormattedFlightDates.map(function (mapMessage) {
          return mapMessage.flight + ': ' + mapMessage.message;
        });
        combinedMessages = stringifiedMessages.join(', ');
      } else {
        combinedMessages = this.get('message.message');
      }

      if (this.get('message.name')) {
        message += this.get('message.name') + ': ';
      }

      if (this.get('message.message')) {
        message += combinedMessages;
      }

      if (this.get('message.statusCode')) {
        message += '(' + this.get('message.statusCode') + ')';
      }

      return message;
    }),
    succeeded: Ember.computed('status', function succeeded() {
      if (this.get('status') === 'succeeded') {
        return true;
      }
      return false;
    }),
    pending: Ember.computed('status', function pending() {
      if (this.get('status') === 'pending') {
        return true;
      }
      return false;
    }),
    failed: Ember.computed('status', function failed() {
      if (this.get('status') === 'failed') {
        return true;
      }
      return false;
    })
  });
});