define("webui/pods/components/product-list/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 6
                },
                "end": {
                  "line": 11,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "productButton glyphicon glyphicon-cloud-upload");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element20 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element20);
              return morphs;
            },
            statements: [["element", "action", ["toggleUploadForm"], [], ["loc", [null, [10, 14], [10, 43]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 4
              },
              "end": {
                "line": 12,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "tool-tip", [], ["tooltipContent", "<p>Upload User File</p>", "tooltipPlace", "right", "class", "clickable"], 0, null, ["loc", [null, [9, 6], [11, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 6
                  },
                  "end": {
                    "line": 15,
                    "column": 6
                  }
                },
                "moduleName": "webui/pods/components/product-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1, "src", "assets/glyphicons-basic/individual-svg/glyphicons-basic-94-crop.svg");
                dom.setAttribute(el1, "height", "16");
                dom.setAttribute(el1, "width", "16");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element19 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element19);
                return morphs;
              },
              statements: [["element", "action", ["toggleCroppingForm"], [], ["loc", [null, [14, 13], [14, 44]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 4
                },
                "end": {
                  "line": 16,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "tool-tip", [], ["tooltipContent", "<p>Crop Flight Resources</p>", "tooltipPlace", "right", "class", "clickable"], 0, null, ["loc", [null, [13, 6], [15, 19]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "allowCropping", ["loc", [null, [12, 14], [12, 27]]]]], [], 0, null, ["loc", [null, [12, 4], [16, 4]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "glyphicon glyphicon-question-sign");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [["element", "action", ["toggleDescription"], [], ["loc", [null, [18, 73], [18, 103]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 8
                },
                "end": {
                  "line": 29,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "file-input-name-display");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "newFile.name", ["loc", [null, [28, 47], [28, 63]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 6
                },
                "end": {
                  "line": 33,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-right");
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn btn-success");
              var el3 = dom.createTextNode("Upload File");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element14 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element14, 'disabled');
              morphs[1] = dom.createElementMorph(element14);
              return morphs;
            },
            statements: [["attribute", "disabled", ["get", "isUploading", ["loc", [null, [32, 75], [32, 86]]]]], ["element", "action", ["uploadFile"], [], ["loc", [null, [32, 89], [32, 112]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 2
              },
              "end": {
                "line": 35,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "padding: 5px 0;");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "file-input-wrapper");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "btn btn-file");
            dom.setAttribute(el3, "id", "product-upload-button");
            var el4 = dom.createTextNode("\n          BROWSE ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("input");
            dom.setAttribute(el4, "style", "display: none;");
            dom.setAttribute(el4, "type", "file");
            dom.setAttribute(el4, "name", "user_upload");
            dom.setAttribute(el4, "id", "user_upload");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1]);
            var element16 = dom.childAt(element15, [1]);
            var element17 = dom.childAt(element16, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element17, 'onchange');
            morphs[1] = dom.createMorphAt(element16, 3, 3);
            morphs[2] = dom.createMorphAt(element15, 3, 3);
            return morphs;
          },
          statements: [["attribute", "onchange", ["subexpr", "action", ["onFileChange"], [], ["loc", [null, [25, 104], [25, 129]]]]], ["block", "if", [["get", "newFile", ["loc", [null, [27, 14], [27, 21]]]]], [], 0, null, ["loc", [null, [27, 8], [29, 15]]]], ["block", "if", [["get", "newFile", ["loc", [null, [31, 12], [31, 19]]]]], [], 1, null, ["loc", [null, [31, 6], [33, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 48,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "padding: 5px 0;");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createElement("i");
            var el4 = dom.createTextNode("Draw Region(s) of Interest on the Map");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n        File Name:\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "text-right");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "btn btn-danger");
            var el4 = dom.createTextNode("Cancel");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "id", "crop-products-button");
            dom.setAttribute(el3, "class", "btn btn-success");
            dom.setAttribute(el3, "disabled", "");
            var el4 = dom.createTextNode("Crop to Region(s)");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [5]);
            var element12 = dom.childAt(element11, [1]);
            var element13 = dom.childAt(element11, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
            morphs[1] = dom.createElementMorph(element12);
            morphs[2] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [["inline", "input", [], ["id", "crop-filename-input", "value", ["subexpr", "@mut", [["get", "defaultDisplayName", ["loc", [null, [41, 47], [41, 65]]]]], [], []], "placeholder", "Enter file name"], ["loc", [null, [41, 8], [41, 97]]]], ["element", "action", ["toggleCroppingForm"], [], ["loc", [null, [44, 39], [44, 70]]]], ["element", "action", ["cropProducts"], [], ["loc", [null, [45, 75], [45, 100]]]]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 14
                    },
                    "end": {
                      "line": 67,
                      "column": 14
                    }
                  },
                  "moduleName": "webui/pods/components/product-list/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "extension", ["loc", [null, [66, 17], [66, 30]]]]],
                locals: ["extension"],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 70,
                      "column": 14
                    },
                    "end": {
                      "line": 72,
                      "column": 14
                    }
                  },
                  "moduleName": "webui/pods/components/product-list/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "software", ["loc", [null, [71, 18], [71, 30]]]]],
                locals: ["software"],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 10
                  },
                  "end": {
                    "line": 75,
                    "column": 10
                  }
                },
                "moduleName": "webui/pods/components/product-list/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "type.extensions", ["loc", [null, [65, 22], [65, 37]]]]], [], 0, null, ["loc", [null, [65, 14], [67, 23]]]], ["block", "each", [["get", "type.softwares", ["loc", [null, [70, 22], [70, 36]]]]], [], 1, null, ["loc", [null, [70, 14], [72, 23]]]]],
              locals: ["type"],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 2
                },
                "end": {
                  "line": 79,
                  "column": 2
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "table-responsive");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("table");
              dom.setAttribute(el2, "class", "table table-bordered text-left");
              var el3 = dom.createTextNode("\n       ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("caption");
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("thead");
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("tr");
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("th");
              var el6 = dom.createTextNode(" Filetype ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("th");
              var el6 = dom.createTextNode(" Application(s) ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n          ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tbody");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [5]), 1, 1);
              return morphs;
            },
            statements: [["content", "description.caption", ["loc", [null, [54, 17], [54, 40]]]], ["block", "each", [["get", "description.types", ["loc", [null, [62, 18], [62, 35]]]]], [], 0, null, ["loc", [null, [62, 10], [75, 19]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 2
              },
              "end": {
                "line": 80,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isShowingDescription", ["loc", [null, [51, 8], [51, 28]]]]], [], 0, null, ["loc", [null, [51, 2], [79, 9]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child6 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 8
                },
                "end": {
                  "line": 88,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Are you sure you want to delete ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("?");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-danger");
              var el2 = dom.createTextNode("Delete");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-cancel");
              var el2 = dom.createTextNode("Cancel");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [3]);
              var element7 = dom.childAt(fragment, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              morphs[1] = dom.createElementMorph(element6);
              morphs[2] = dom.createElementMorph(element7);
              return morphs;
            },
            statements: [["content", "product.displayName", ["loc", [null, [85, 45], [85, 68]]]], ["element", "action", ["deleteItem", ["get", "product.id", ["loc", [null, [86, 63], [86, 73]]]]], [], ["loc", [null, [86, 41], [86, 75]]]], ["element", "action", ["toggleRemoveForm", ["get", "product.id", ["loc", [null, [87, 69], [87, 79]]]]], [], ["loc", [null, [87, 41], [87, 81]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 90,
                    "column": 12
                  },
                  "end": {
                    "line": 92,
                    "column": 12
                  }
                },
                "moduleName": "webui/pods/components/product-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "badge");
                var el2 = dom.createTextNode("  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["content", "product.size", ["loc", [null, [91, 36], [91, 52]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 10
                  },
                  "end": {
                    "line": 97,
                    "column": 10
                  }
                },
                "moduleName": "webui/pods/components/product-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "glyphicon glyphicon-repeat spinner");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 98,
                      "column": 12
                    },
                    "end": {
                      "line": 100,
                      "column": 12
                    }
                  },
                  "moduleName": "webui/pods/components/product-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "style", "padding: 1px 6px;");
                  dom.setAttribute(el1, "class", "glyphicon-full glyphicons-warning-sign regionHeaderIcon failed");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element4, 'title');
                  return morphs;
                },
                statements: [["attribute", "title", ["concat", [["get", "product.message.message", ["loc", [null, [99, 126], [99, 149]]]]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 101,
                        "column": 14
                      },
                      "end": {
                        "line": 103,
                        "column": 14
                      }
                    },
                    "moduleName": "webui/pods/components/product-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "style", "padding: 1px 6px;");
                    dom.setAttribute(el1, "class", "glyphicon-full glyphicons-warning-sign regionHeaderIcon warning");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element3, 'title');
                    return morphs;
                  },
                  statements: [["attribute", "title", ["concat", [["get", "product.message.message", ["loc", [null, [102, 129], [102, 152]]]]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 105,
                        "column": 16
                      },
                      "end": {
                        "line": 107,
                        "column": 16
                      }
                    },
                    "moduleName": "webui/pods/components/product-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "productButton glyphicon glyphicon-cloud-download");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element2);
                    return morphs;
                  },
                  statements: [["element", "action", ["downloadItem", ["get", "product.url", ["loc", [null, [106, 50], [106, 61]]]], ["get", "product.type", ["loc", [null, [106, 62], [106, 74]]]]], [], ["loc", [null, [106, 26], [106, 76]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child2 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 110,
                        "column": 16
                      },
                      "end": {
                        "line": 113,
                        "column": 16
                      }
                    },
                    "moduleName": "webui/pods/components/product-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "productButton glyphicon glyphicon-copy");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element1);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    return morphs;
                  },
                  statements: [["element", "action", ["copyItem", ["get", "product", ["loc", [null, [111, 46], [111, 53]]]]], [], ["loc", [null, [111, 26], [111, 55]]]], ["inline", "modal-wrapper", [], ["modalId", ["subexpr", "@mut", [["get", "product.id", ["loc", [null, [112, 42], [112, 52]]]]], [], []], "componentName", "copy-product-links", "value", ["subexpr", "@mut", [["get", "product.shareUrl", ["loc", [null, [112, 94], [112, 110]]]]], [], []], "title", "Shareable Link:", "class", "copyProductLinks"], ["loc", [null, [112, 18], [112, 161]]]]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 100,
                      "column": 12
                    },
                    "end": {
                      "line": 115,
                      "column": 12
                    }
                  },
                  "moduleName": "webui/pods/components/product-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "product.status", ["loc", [null, [101, 24], [101, 38]]]], "warning"], [], ["loc", [null, [101, 20], [101, 49]]]]], [], 0, null, ["loc", [null, [101, 14], [103, 21]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Download</p>", "tooltipPlace", "top", "class", "productButton"], 1, null, ["loc", [null, [105, 16], [107, 29]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Get shareable link</p>", "tooltipPlace", "top", "class", "productButton"], 2, null, ["loc", [null, [110, 16], [113, 29]]]]],
                locals: [],
                templates: [child0, child1, child2]
              };
            }();
            var child2 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 118,
                        "column": 16
                      },
                      "end": {
                        "line": 120,
                        "column": 16
                      }
                    },
                    "moduleName": "webui/pods/components/product-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "productButton glyphicon glyphicon-trash");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element0);
                    return morphs;
                  },
                  statements: [["element", "action", ["toggleRemoveForm", ["get", "product.id", ["loc", [null, [119, 54], [119, 64]]]]], [], ["loc", [null, [119, 26], [119, 66]]]]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 116,
                      "column": 12
                    },
                    "end": {
                      "line": 122,
                      "column": 12
                    }
                  },
                  "moduleName": "webui/pods/components/product-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "tool-tip", [], ["tooltipContent", "<p>Delete</p>", "tooltipPlace", "top", "class", "productButton"], 0, null, ["loc", [null, [118, 16], [120, 29]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 10
                  },
                  "end": {
                    "line": 123,
                    "column": 10
                  }
                },
                "moduleName": "webui/pods/components/product-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "product.status", ["loc", [null, [98, 22], [98, 36]]]], "failed"], [], ["loc", [null, [98, 18], [98, 46]]]]], [], 0, 1, ["loc", [null, [98, 12], [115, 19]]]], ["block", "if", [["get", "allowDeletion", ["loc", [null, [116, 18], [116, 31]]]]], [], 2, null, ["loc", [null, [116, 12], [122, 19]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 8
                },
                "end": {
                  "line": 124,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/product-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "flex-grow:1;");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element5, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "product.size", ["loc", [null, [90, 18], [90, 30]]]]], [], 0, null, ["loc", [null, [90, 12], [92, 19]]]], ["content", "product.displayName", ["loc", [null, [93, 18], [93, 41]]]], ["block", "if", [["subexpr", "eq", [["get", "product.status", ["loc", [null, [95, 20], [95, 34]]]], "pending"], [], ["loc", [null, [95, 16], [95, 45]]]]], [], 1, 2, ["loc", [null, [95, 10], [123, 17]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 4
              },
              "end": {
                "line": 126,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/product-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "list-group-item products-list-item");
            dom.setAttribute(el1, "style", "display:flex; align-items:baseline;");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "product.removeMode", ["loc", [null, [84, 14], [84, 32]]]]], [], 0, 1, ["loc", [null, [84, 8], [124, 15]]]]],
          locals: ["product"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 129,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/product-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "list-group");
          dom.setAttribute(el1, "style", "text-overflow:ellipsis");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "style", "display:flex;");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          dom.setAttribute(el3, "class", "list-group-item-heading text-left");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "flex-spacer-narrow");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "list-group text-left");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [0]);
          var element22 = dom.childAt(element21, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element22, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(element22, 5, 5);
          morphs[2] = dom.createMorphAt(element22, 6, 6);
          morphs[3] = dom.createMorphAt(element21, 3, 3);
          morphs[4] = dom.createMorphAt(element21, 4, 4);
          morphs[5] = dom.createMorphAt(element21, 6, 6);
          morphs[6] = dom.createMorphAt(dom.childAt(element21, [8]), 1, 1);
          return morphs;
        },
        statements: [["content", "label", ["loc", [null, [5, 6], [5, 15]]]], ["block", "if", [["get", "allowUploads", ["loc", [null, [8, 10], [8, 22]]]]], [], 0, 1, ["loc", [null, [8, 4], [16, 11]]]], ["block", "if", [["get", "description", ["loc", [null, [17, 10], [17, 21]]]]], [], 2, null, ["loc", [null, [17, 4], [19, 11]]]], ["block", "if", [["get", "isShowingUploadForm", ["loc", [null, [21, 8], [21, 27]]]]], [], 3, null, ["loc", [null, [21, 2], [35, 9]]]], ["block", "if", [["get", "isShowingCroppingForm", ["loc", [null, [36, 8], [36, 29]]]]], [], 4, null, ["loc", [null, [36, 2], [48, 9]]]], ["block", "if", [["get", "description", ["loc", [null, [50, 8], [50, 19]]]]], [], 5, null, ["loc", [null, [50, 2], [80, 9]]]], ["block", "each", [["get", "products", ["loc", [null, [82, 12], [82, 20]]]]], [], 6, null, ["loc", [null, [82, 4], [126, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 130,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/product-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "products.length", ["loc", [null, [1, 10], [1, 25]]]], ["get", "displayIfEmpty", ["loc", [null, [1, 26], [1, 40]]]]], [], ["loc", [null, [1, 6], [1, 41]]]]], [], 0, null, ["loc", [null, [1, 0], [129, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});