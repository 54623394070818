define('webui/models/historical-export', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var displayTypes = {
    'cross-section': 'Cross Section',
    'tracking-cut-fill': 'Tracking Cut/Fill',
    measurement: 'Measurement',
    quantity: 'Quantity',
    'quality-report': 'Quality Report',
    picture: 'Picture',
    'volume-calc': 'Volume Calc',
    'true-tonnage': 'True Tonnage Report'
  };

  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    urlPrefix: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    fileSuffix: _emberData.default.attr('string'),
    exportType: _emberData.default.attr('string'),
    site: _emberData.default.belongsTo('site', { async: true }),
    user: _emberData.default.belongsTo('user', { async: true }),
    createdAt: _emberData.default.attr('string'),

    displayDate: function revisionDate() {
      return this.dateTimeString(this.get('createdAt'));
    }.property('createdAt'),

    displayType: function displayType() {
      return this.get('exportType') ? displayTypes[this.get('exportType')] : null;
    }.property('exportType'),

    displayUrl: function displayUrl() {
      return this.get('urlPrefix') + this.get('fileUrl');
    }.property('fileUrl'),

    encodedUrl: Ember.computed('fileUrl', function encodedUrl() {
      var fileID = encodeURIComponent(decodeURIComponent(this.get('fileUrl')));
      return fileID.replace(/%2F/gi, '/');
    }),

    shareUrl: function shareUrl() {
      return this.get('urlPrefix') + this.get('encodedUrl');
    }.property('fileUrl')
  });
});