define('webui/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    statusService: Ember.inject.service('status-panel'),
    featureFlags: Ember.inject.service('feature-flags'),

    // This hook is executed when the router enters the route. It is not executed when the model for the route changes.
    activate: function activate() {
      this._super();

      var user = this.get('session.data.authenticated');
      if (user.status && user.status === 'pending') {
        this.transitionTo('account');
      }

      var userHasSiteIQAccess = this.get('session.isAuthenticated') && typeof user.id === 'string';
      if (userHasSiteIQAccess) {
        this.get('statusService').loadPending();
      }
    },
    beforeModel: function beforeModel(transition) {
      var validRoutesForFieldUsersUsers = ['field', 'field-login-error'];
      var validRoutesForUnauthenticatedUsers = ['login', 'field-login', 'field-login-error'];
      var user = this.get('session.data.authenticated');
      var userHasSiteIQAccess = this.get('session.isAuthenticated') && typeof user.id === 'string';
      var userHasFieldAccess = this.get('session.isAuthenticated') && user.fieldUser !== null && _typeof(user.fieldUser) === 'object' && typeof user.fieldUser.id === 'string';
      var userHasNoAccess = !userHasSiteIQAccess && !userHasFieldAccess;

      this.get('featureFlags').loadFeaturesMap();
      this.get('session').set('target', transition.targetName);

      if (transition.targetName.split('.')[0] === 'reset') {
        return null;
      }

      if (transition.targetName.split('.')[0] === 'tools') {
        return null;
      }

      if (transition.targetName.split('.')[0] === 'login') {
        if (userHasSiteIQAccess) {
          transition.abort();
          return this.transitionTo('index');
        }
        return null;
      }

      if (userHasNoAccess && transition) {
        if (transition.targetName === 'field') {
          transition.abort();
          return this.transitionTo('field-login-error');
        }

        if (!(validRoutesForUnauthenticatedUsers.indexOf(transition.targetName) !== -1)) {
          if (transition.handlerInfos.objectAt(1)) {
            var intendedTransition = transition.handlerInfos.objectAt(1);
            if (intendedTransition.name && intendedTransition.params && intendedTransition.params.id) {
              this.set('session.intendedTransition', transition.intent.url);
            }
          }

          transition.abort();
          return this.transitionTo('login');
        }
      }

      if (userHasFieldAccess && userHasSiteIQAccess) {
        return null;
      }

      if (userHasFieldAccess && transition) {
        if (transition.targetName === 'field-login') {
          transition.abort();
          return this.transitionTo('field', transition.params['field-login'].flight);
        }

        if (!(validRoutesForFieldUsersUsers.indexOf(transition.targetName) !== -1)) {
          transition.abort();
          return this.transitionTo('field', '');
        }
      }

      if (userHasSiteIQAccess && transition) {
        if (validRoutesForFieldUsersUsers.indexOf(transition.targetName) !== -1) {
          transition.abort();
          return this.transitionTo('index');
        }
      }

      return null;
    },


    // Called by ember-cli-segment everytime the URL changes for tracking
    identifyUser: function identifyUser() {
      // session.data comes from ember-simple-auth and is a dumping ground for our session data
      // session.data.authenticated is read-only and returned from the authorizer on first auth
      //   for us, this is our user data.
      var user = this.get('session.data.authenticated');
      var traits = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        createdAt: user.createdAt,
        isAdmin: user.isAdmin,
        clientIds: user.clientIds,
        siteIds: user.siteIds
      };

      this.segment.identifyUser(user.id, traits);
    },


    actions: {
      doTransition: function doTransition(route, id) {
        if (id) {
          this.transitionTo(route, id);
        } else {
          this.transitionTo(route);
        }
      }
    }
  });
});