define("webui/pods/components/field-header/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 8
            },
            "end": {
              "line": 15,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/field-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "flight.subtitle", ["loc", [null, [14, 15], [14, 34]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 6
            },
            "end": {
              "line": 21,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/field-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "header-menu");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "glyphicon glyphicon glyphicon-option-vertical");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element9, 'class');
          morphs[1] = dom.createElementMorph(element9);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "isMenuOpen", ["loc", [null, [19, 34], [19, 44]]]], "active", ""], [], ["loc", [null, [19, 29], [19, 58]]]]]]], ["element", "action", ["toggleMenu"], [], ["loc", [null, [19, 60], [19, 83]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 10
              },
              "end": {
                "line": 34,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/field-header/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'value');
            morphs[1] = dom.createAttrMorph(element1, 'selected');
            morphs[2] = dom.createMorphAt(element1, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "navsite.id", ["loc", [null, [33, 29], [33, 39]]]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "navsite.id", ["loc", [null, [33, 57], [33, 67]]]], ["get", "selectedSiteId", ["loc", [null, [33, 68], [33, 82]]]]], [], ["loc", [null, [33, 52], [33, 84]]]]], ["content", "navsite.name", ["loc", [null, [33, 85], [33, 101]]]]],
          locals: ["navsite"],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 14
                },
                "end": {
                  "line": 46,
                  "column": 14
                }
              },
              "moduleName": "webui/pods/components/field-header/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                - ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "navflight.subtitle", ["loc", [null, [45, 18], [45, 40]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 10
              },
              "end": {
                "line": 48,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/field-header/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'selected');
            morphs[2] = dom.createMorphAt(element0, 1, 1);
            morphs[3] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "navflight.id", ["loc", [null, [42, 29], [42, 41]]]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "navflight.id", ["loc", [null, [42, 59], [42, 71]]]], ["get", "selectedFlightId", ["loc", [null, [42, 72], [42, 88]]]]], [], ["loc", [null, [42, 54], [42, 90]]]]], ["content", "navflight.displayName", ["loc", [null, [43, 14], [43, 39]]]], ["block", "if", [["get", "navflight.subtitle", ["loc", [null, [44, 20], [44, 38]]]]], [], 0, null, ["loc", [null, [44, 14], [46, 21]]]]],
          locals: ["navflight"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 0
            },
            "end": {
              "line": 62,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/field-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "field-navigation-panel-body");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "field-panel-row select-row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4, "for", "field-site");
          var el5 = dom.createTextNode("Site:");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("select");
          dom.setAttribute(el4, "id", "field-site");
          dom.setAttribute(el4, "class", "field-panel-input");
          dom.setAttribute(el4, "name", "field-site");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(">\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "field-panel-row select-row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4, "for", "field-flight");
          var el5 = dom.createTextNode("Flight:");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("select");
          dom.setAttribute(el4, "id", "field-flight");
          dom.setAttribute(el4, "class", "field-panel-input");
          dom.setAttribute(el4, "name", "field-flight");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(">\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "field-panel-row text-right");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5, "class", "btn btn-cancel");
          var el6 = dom.createTextNode("Cancel");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("button");
          dom.setAttribute(el5, "class", "btn btn-primary");
          var el6 = dom.createTextNode("Change Flight");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element3, [1, 3]);
          var element5 = dom.childAt(element3, [3, 3]);
          var element6 = dom.childAt(element3, [5]);
          var element7 = dom.childAt(element6, [1, 1]);
          var element8 = dom.childAt(element6, [3, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createAttrMorph(element4, 'onchange');
          morphs[2] = dom.createMorphAt(element4, 1, 1);
          morphs[3] = dom.createAttrMorph(element5, 'onchange');
          morphs[4] = dom.createMorphAt(element5, 1, 1);
          morphs[5] = dom.createElementMorph(element7);
          morphs[6] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["field-navigation-panel ", ["subexpr", "if", [["get", "flight.subtitle", ["loc", [null, [27, 42], [27, 57]]]], "header-with-subtitle", "header-without-subtitle"], [], ["loc", [null, [27, 37], [27, 108]]]]]]], ["attribute", "onchange", ["subexpr", "action", ["changeSite"], ["value", "target.value"], ["loc", [null, [31, 85], [31, 129]]]]], ["block", "each", [["subexpr", "sort-by", ["name", ["get", "sitesForNavigation", ["loc", [null, [32, 34], [32, 52]]]]], [], ["loc", [null, [32, 18], [32, 53]]]]], [], 0, null, ["loc", [null, [32, 10], [34, 19]]]], ["attribute", "onchange", ["subexpr", "action", ["changeFlight"], ["value", "target.value"], ["loc", [null, [40, 89], [40, 135]]]]], ["block", "each", [["subexpr", "sort-by", ["name:desc", ["get", "flightsForNavigation", ["loc", [null, [41, 39], [41, 59]]]]], [], ["loc", [null, [41, 18], [41, 60]]]]], [], 1, null, ["loc", [null, [41, 10], [48, 19]]]], ["element", "action", ["toggleMenu"], [], ["loc", [null, [54, 41], [54, 64]]]], ["element", "action", ["navigateToNewFlight"], [], ["loc", [null, [57, 42], [57, 74]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 7
          }
        },
        "moduleName": "webui/pods/components/field-header/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "field-header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("nav");
        dom.setAttribute(el2, "class", "navbar navbar-default");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "nav-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "navbar-header");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "target", "_blank");
        dom.setAttribute(el5, "href", "http://www.identifiedtech.com");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6, "class", "normal logo");
        dom.setAttribute(el6, "src", "assets/images/logo.png");
        dom.setAttribute(el6, "alt", "Logo");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "header-info");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [1, 1]);
        var element12 = dom.childAt(element11, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element10, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(element12, 5, 5);
        morphs[4] = dom.createMorphAt(element11, 5, 5);
        morphs[5] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["row ", ["subexpr", "if", [["get", "flight.subtitle", ["loc", [null, [1, 39], [1, 54]]]], "header-with-subtitle", "header-without-subtitle"], [], ["loc", [null, [1, 34], [1, 105]]]]]]], ["content", "site.name", ["loc", [null, [11, 13], [11, 26]]]], ["content", "flight.displayFlightTime", ["loc", [null, [12, 13], [12, 41]]]], ["block", "if", [["get", "flight.subtitle", ["loc", [null, [13, 14], [13, 29]]]]], [], 0, null, ["loc", [null, [13, 8], [15, 15]]]], ["block", "if", [["get", "sitesForNavigation", ["loc", [null, [17, 12], [17, 30]]]]], [], 1, null, ["loc", [null, [17, 6], [21, 13]]]], ["block", "if", [["get", "isMenuOpen", ["loc", [null, [26, 6], [26, 16]]]]], [], 2, null, ["loc", [null, [26, 0], [62, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});