define("webui/pods/components/client-overview/materials/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 10
            },
            "end": {
              "line": 8,
              "column": 10
            }
          },
          "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          dom.setAttribute(el1, "selected", "selected");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element6, 'value');
          morphs[1] = dom.createMorphAt(element6, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["get", "material", ["loc", [null, [7, 28], [7, 36]]]]], ["content", "material", ["loc", [null, [7, 59], [7, 71]]]]],
        locals: ["material"],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 16
                },
                "end": {
                  "line": 17,
                  "column": 16
                }
              },
              "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "map-subtitle");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element4, 'value');
              morphs[1] = dom.createMorphAt(element4, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["get", "flight.id", ["loc", [null, [16, 34], [16, 43]]]]], ["content", "flight.displayFlightTime", ["loc", [null, [16, 66], [16, 94]]]], ["content", "flight.subtitle", ["loc", [null, [16, 121], [16, 140]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 16
                },
                "end": {
                  "line": 19,
                  "column": 16
                }
              },
              "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "map-subtitle");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element3, 'value');
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["get", "flight.id", ["loc", [null, [18, 34], [18, 43]]]]], ["content", "flight.displayFlightTime", ["loc", [null, [18, 46], [18, 74]]]], ["content", "flight.subtitle", ["loc", [null, [18, 101], [18, 120]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 14
              },
              "end": {
                "line": 20,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "index", ["loc", [null, [15, 26], [15, 31]]]], 0], [], ["loc", [null, [15, 22], [15, 34]]]]], [], 0, 1, ["loc", [null, [15, 16], [19, 23]]]]],
          locals: ["flight", "index"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 10
            },
            "end": {
              "line": 22,
              "column": 10
            }
          },
          "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("optgroup");
          dom.setAttribute(el1, "data-max-options", "1");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'label');
          morphs[1] = dom.createMorphAt(element5, 1, 1);
          return morphs;
        },
        statements: [["attribute", "label", ["get", "site.name", ["loc", [null, [13, 30], [13, 39]]]]], ["block", "each", [["subexpr", "sort-by", ["executeAt:desc", ["get", "site.flights", ["loc", [null, [14, 48], [14, 60]]]]], [], ["loc", [null, [14, 22], [14, 61]]]]], [], 0, null, ["loc", [null, [14, 14], [20, 23]]]]],
        locals: ["site"],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 14
              },
              "end": {
                "line": 47,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "colspan", "3");
            var el2 = dom.createElement("strong");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "text-right");
            var el2 = dom.createElement("strong");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "text-right");
            var el2 = dom.createElement("strong");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 0]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 0]), 0, 0);
            return morphs;
          },
          statements: [["content", "material.name", ["loc", [null, [43, 40], [43, 57]]]], ["content", "material.displayVolume", ["loc", [null, [44, 47], [44, 73]]]], ["content", "material.displayTonnage", ["loc", [null, [45, 47], [45, 74]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 18
                },
                "end": {
                  "line": 56,
                  "column": 18
                }
              },
              "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["glyphicon ", ["subexpr", "if", [["get", "material.showRegions", ["loc", [null, [55, 48], [55, 68]]]], "glyphicon-chevron-up", "glyphicon-chevron-down"], [], ["loc", [null, [55, 43], [55, 118]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 14
              },
              "end": {
                "line": 58,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "text-right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "text-right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "text-right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(fragment, [9]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(fragment, [11]), 1, 1);
            return morphs;
          },
          statements: [["content", "material.name", ["loc", [null, [48, 20], [48, 37]]]], ["content", "material.key", ["loc", [null, [49, 20], [49, 36]]]], ["content", "material.density", ["loc", [null, [50, 39], [50, 59]]]], ["content", "material.displayVolume", ["loc", [null, [51, 39], [51, 65]]]], ["content", "material.displayTonnage", ["loc", [null, [52, 39], [52, 66]]]], ["block", "if", [["subexpr", "gt", [["get", "material.regions.length", ["loc", [null, [54, 28], [54, 51]]]], 0], [], ["loc", [null, [54, 24], [54, 54]]]]], [], 0, null, ["loc", [null, [54, 18], [56, 25]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 22
                },
                "end": {
                  "line": 84,
                  "column": 22
                }
              },
              "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1, "class", "info");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
              return morphs;
            },
            statements: [["content", "region.site", ["loc", [null, [77, 30], [77, 45]]]], ["content", "region.flight", ["loc", [null, [78, 30], [78, 47]]]], ["content", "region.name", ["loc", [null, [79, 30], [79, 45]]]], ["content", "region.displayVolume", ["loc", [null, [80, 49], [80, 73]]]], ["content", "region.displayTonnage", ["loc", [null, [81, 49], [81, 74]]]]],
            locals: ["region"],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 12
              },
              "end": {
                "line": 89,
                "column": 12
              }
            },
            "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "colspan", "6");
            dom.setAttribute(el2, "style", "padding:0;");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "table table-sm");
            dom.setAttribute(el3, "style", "margin:0;");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("thead");
            var el5 = dom.createTextNode("\n                      ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            dom.setAttribute(el5, "class", "info");
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            var el7 = dom.createTextNode("Site");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            var el7 = dom.createTextNode("Flight");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            var el7 = dom.createTextNode("Region");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "text-right");
            var el7 = dom.createTextNode("Volume (yd");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("sup");
            var el8 = dom.createTextNode("3");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode(")");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "class", "text-right");
            var el7 = dom.createTextNode("Tonnage (US Tons)");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                        ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "style", "width:35px;");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                      ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tbody");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                    ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1, 3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "material.regions", ["loc", [null, [75, 30], [75, 46]]]]], [], 0, null, ["loc", [null, [75, 22], [84, 31]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 10
            },
            "end": {
              "line": 90,
              "column": 10
            }
          },
          "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "gt", [["get", "material.regions.length", ["loc", [null, [41, 68], [41, 91]]]], 0], [], ["loc", [null, [41, 64], [41, 94]]]], "pointer-on-hover", ""], [], ["loc", [null, [41, 59], [41, 118]]]]]]], ["element", "action", ["toggleRegions", ["get", "material", ["loc", [null, [41, 41], [41, 49]]]]], [], ["loc", [null, [41, 16], [41, 51]]]], ["block", "if", [["get", "material.is_total", ["loc", [null, [42, 20], [42, 37]]]]], [], 0, 1, ["loc", [null, [42, 14], [58, 21]]]], ["block", "if", [["get", "material.showRegions", ["loc", [null, [60, 18], [60, 38]]]]], [], 2, null, ["loc", [null, [60, 12], [89, 19]]]]],
        locals: ["material"],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 96,
            "column": 6
          }
        },
        "moduleName": "webui/pods/components/client-overview/materials/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row tab-data account-material-tab");
        dom.setAttribute(el1, "style", "max-width: 1200px;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-12");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel panel-default");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "panel-body");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("select");
        dom.setAttribute(el5, "id", "material-selector");
        dom.setAttribute(el5, "class", "selectpicker");
        dom.setAttribute(el5, "data-width", "25%");
        dom.setAttribute(el5, "multiple", "");
        dom.setAttribute(el5, "data-selected-text-format", "static");
        dom.setAttribute(el5, "title", "Materials");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("select");
        dom.setAttribute(el5, "id", "flight-selector");
        dom.setAttribute(el5, "class", "selectpicker");
        dom.setAttribute(el5, "data-width", "25%");
        dom.setAttribute(el5, "multiple", "");
        dom.setAttribute(el5, "data-selected-text-format", "static");
        dom.setAttribute(el5, "title", "Flights");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "pull-right");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "class", "btn primary");
        var el7 = dom.createTextNode("Export Material Totals");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "tableFixHead");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("table");
        dom.setAttribute(el5, "class", "table table-sm table-bordered table-hover table-fixed tbl-account-material");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("thead");
        dom.setAttribute(el6, "class", "thead-account-material");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        var el9 = dom.createTextNode("Material");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        var el9 = dom.createTextNode("Key");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8, "class", "text-right");
        var el9 = dom.createTextNode("Density (tons/yd");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("sup");
        var el10 = dom.createTextNode("3");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8, "class", "text-right");
        var el9 = dom.createTextNode("Volume (yd");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("sup");
        var el10 = dom.createTextNode("3");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(")");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8, "class", "text-right");
        var el9 = dom.createTextNode("Tonnage (US Tons)");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("th");
        dom.setAttribute(el8, "style", "width:35px;");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n          ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tbody");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0, 1, 1]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [5, 0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[2] = dom.createElementMorph(element9);
        morphs[3] = dom.createMorphAt(dom.childAt(element7, [3, 1, 3]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "materialsForSelect", ["loc", [null, [6, 18], [6, 36]]]]], [], 0, null, ["loc", [null, [6, 10], [8, 19]]]], ["block", "each", [["subexpr", "sort-by", ["name", ["get", "model.sites", ["loc", [null, [12, 34], [12, 45]]]]], [], ["loc", [null, [12, 18], [12, 46]]]]], [], 1, null, ["loc", [null, [12, 10], [22, 19]]]], ["element", "action", ["exportMaterialTotals"], [], ["loc", [null, [25, 61], [25, 94]]]], ["block", "each", [["get", "reportData", ["loc", [null, [40, 18], [40, 28]]]]], [], 2, null, ["loc", [null, [40, 10], [90, 19]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});