define('webui/pods/components/instance-display/component', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    wizardData: null,

    store: Ember.inject.service('store'),

    jobs: null,

    pending: [],
    succeeded: [],
    needs_human: [],
    human_verified: [],
    needs_class: [],
    human_class: [],
    upload_pending: [],
    running_part_one: [],
    running_part_two: [],
    running_part_three: [],
    running_upload: [],
    failed_part_one: [],
    failed_part_two: [],
    failed_part_three: [],
    failed_upload: [],

    state: null,
    state_label: null,
    runningHours: 0,
    publicDnsName: null,
    isExpanded: false,
    canControlState: true,
    canStop: false,
    canStart: false,
    instanceType: null,
    instanceTask: null,
    taskOptions: ['user', 'proc'],

    didInsertElement: function didInsertElement() {},
    updateDescription: function updateDescription() {
      var component = this;
      var instance = this.get('instance');
      var describeUrl = _environment.default.APP.apiUrl + 'instances/' + instance.id + '/describe';

      return $.ajax({
        method: 'GET',
        contentType: 'json',
        url: describeUrl,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).then(function (body) {
        var state = body.state;

        var launchDate = new Date(body.launchTime);
        var runningHours = Math.round((new Date() - launchDate) / 3.6e6);
        var state_label = 'label label-default';
        var canStop = false;
        var canStart = false;
        var instanceType = body.instanceType;
        var instanceTask = body.instanceTask;

        // states: pending | running | shutting-down | terminated | stopping | stopped

        if (state === 'running') {
          canStop = true;

          if (runningHours > 48) {
            state_label = 'label label-danger';
          } else if (runningHours > 24) {
            state_label = 'label label-warning';
          } else {
            state_label = 'label label-success';
            if (runningHours === 0) {
              runningHours = 1;
            }
          }
        } else {
          runningHours = 0;
        }

        if (state === 'stopped') {
          canStart = true;
        }

        Ember.run(function () {
          component.set('canStart', canStart);
          component.set('canStop', canStop);
          component.set('state', state);
          component.set('runningHours', runningHours);
          component.set('publicDnsName', body.publicDnsName);
          component.set('state_label', state_label);
          component.set('instanceType', instanceType);
          component.set('instanceTask', instanceTask);
        });
      });
    },
    updateJobs: function updateJobs(jobs) {
      var succeeded = void 0;var component = this;var store = this.get('store');

      jobs.forEach(function (job) {
        var flight = job.get('flight');
        var date = new Date(flight.get('executeAt'));

        job.set('dateString', date.toDateString());
        job.set('date', date);

        try {
          store.findRecord('site', flight.get('site').get('id')).then(function (site) {
            job.set('siteName', site.get('name'));
          });
        } catch (e) {
          console.error('unable to get site from flight for job labeling', e, flight, flight.get('site'));
        }
      });

      component.set('pending', jobs.filterBy('status', 'pending').sortBy('date').reverseObjects());
      component.set('needs_human', jobs.filterBy('status', 'needs-human').sortBy('date').reverseObjects());
      component.set('human_verified', jobs.filterBy('status', 'human-verified').sortBy('date').reverseObjects());
      component.set('needs_class', jobs.filterBy('status', 'needs-class').sortBy('date').reverseObjects());
      component.set('human_class', jobs.filterBy('status', 'human-class').sortBy('date').reverseObjects());
      component.set('upload_pending', jobs.filterBy('status', 'upload-pending').sortBy('date').reverseObjects());
      component.set('running_part_one', jobs.filterBy('status', 'running-part-one').sortBy('date').reverseObjects());
      component.set('running_part_two', jobs.filterBy('status', 'running-part-two').sortBy('date').reverseObjects());
      component.set('running_part_three', jobs.filterBy('status', 'running-part-three').sortBy('date').reverseObjects());
      component.set('running_upload', jobs.filterBy('status', 'running-upload').sortBy('date').reverseObjects());
      component.set('failed_part_one', jobs.filterBy('status', 'failed-part-one').sortBy('date').reverseObjects());
      component.set('failed_part_two', jobs.filterBy('status', 'failed-part-two').sortBy('date').reverseObjects());
      component.set('failed_part_three', jobs.filterBy('status', 'failed-part-three').sortBy('date').reverseObjects());
      component.set('failed_upload', jobs.filterBy('status', 'failed-upload').sortBy('date').reverseObjects());

      var cutoff = new Date();
      cutoff.setDate(cutoff.getDate() - 30);
      var cutoff_t = cutoff.getTime();

      succeeded = jobs.filterBy('status', 'succeeded');
      succeeded = succeeded.filter(function (item) {
        var date_t = new Date(item.get('updatedAt')).getTime();

        // updatedAt time must be past the cutoff date to show.
        return date_t > cutoff_t;
      });

      component.set('succeeded', succeeded.sortBy('date').reverseObjects());
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var instance = this.get('instance');
      var store = this.get('store');
      var component = this;

      this.updateDescription();

      // populate job info
      store.query('job', { instance: instance.id, limit: 1000 }).then(function (jobs) {
        component.updateJobs(jobs);
      }).catch(function (err) {
        console.error('error querying jobs for instance', instance.id, err);
      });
    },


    /**
     * This function polls our API, which in turn polls the AWS API for instance
     * status.  Instances go stopped->running very fast, but take a very
     * very long time to go running->stopping->stopped. While they are in
     * transition we want to make sure we,
     *  1. keep input controls disabled so there aren't multiple start/stops
     *  2. update status to it's final state
     *  3. fetch the url so you can VPN into the instance
     * The function calls itself on an interval, until it's tried 6 times
     * or until it reaches the desired state.
     *
     * Stopping can take 5-10 minutes, so there's a decent chance this code
     * doesn't help
     */
    pollStatusUntil: function pollStatusUntil(expectedState, iterations) {
      var component = this;

      if (!iterations) {
        iterations = 0;
      }

      this.updateDescription().then(function () {
        var state = component.get('state');

        if (iterations > 5) {
          component.set('canControlState', true);
          return;
        }

        if (state !== expectedState) {
          iterations += 1;
          Ember.run.debounce(component, component.pollStatusUntil, expectedState, iterations, 10e3);
          return;
        }

        component.set('canControlState', true);
      });
    },


    actions: {
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      },
      startInstance: function startInstance() {
        var url = _environment.default.APP.apiUrl + 'instances/' + this.get('instance.id') + '/start';
        var component = this;

        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).done(function () {
          component.set('canControlState', false);
          Ember.run.debounce(component, component.pollStatusUntil, 'running', 10e3);
        }).fail(function (res) {
          // TODO : display this somewhere
          console.error('startInstance fail', res);
        });
      },
      stopInstance: function stopInstance() {
        var url = _environment.default.APP.apiUrl + 'instances/' + this.get('instance.id') + '/stop';
        var component = this;

        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).done(function () {
          component.set('canControlState', false);
          component.pollStatusUntil('stopped');
        }).fail(function (res) {
          // TODO : display this somewhere
          console.error('stopInstance fail', res);
        });
      },
      updateJobStatus: function updateJobStatus(newStatus, job) {
        var thisJobId = job.get('id');
        var oldStatus = job.get('status');

        job.set('status', newStatus);

        var url = _environment.default.APP.apiUrl + 'jobs/' + job.id;
        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          crossDomain: true,
          data: JSON.stringify({ job: { status: newStatus } }),
          xhrFields: { withCredentials: true }
        }).done(function () {}).fail(function (res) {
          console.error('Could not update job status', res);
        });

        var oldStatusKey = oldStatus.replace(/-/g, '_');
        var newStatusKey = newStatus.replace(/-/g, '_');

        var oldStatuses = this.get(oldStatusKey);
        var newStatuses = this.get(newStatusKey);

        // push to new status list
        newStatuses.pushObject(job);
        newStatuses = newStatuses.sortBy('date').reverseObjects();
        this.set(newStatusKey, newStatuses);

        // remove from old status list
        var updatedOldStatuses = oldStatuses.rejectBy('id', thisJobId);
        this.set(oldStatusKey, updatedOldStatuses);
      },
      updateInstanceTask: function updateInstanceTask(newTask) {
        var instance = this.get('instance');

        instance.set('task', newTask);
        instance.save();

        this.set('instanceTask', newTask);

        var url = _environment.default.APP.apiUrl + 'instances/' + instance.id;

        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          crossDomain: true,
          data: JSON.stringify({ instance: { task: newTask } }),
          xhrFields: { withCredentials: true }
        }).done(function () {}).fail(function (res) {
          console.error('Could not change instance task', res);
        });
      },
      updateInstanceType: function updateInstanceType(instanceType, instance) {
        var url = _environment.default.APP.apiUrl + 'instances/' + instance.get('id') + '/modifyType';

        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          crossDomain: true,
          data: JSON.stringify({ type: instanceType }),
          xhrFields: { withCredentials: true }
        }).done(function () {}).fail(function (res) {
          console.error('Could not change instance type', res);
        });
      }
    }
  });
});