define('webui/pods/components/ui-feedback/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    feedback: Ember.inject.service('user-feedback'),
    uiError: null,
    uiSuccess: null,
    uiInfo: null,

    actions: {
      clearCurrentError: function clearCurrentError() {
        var feedback = this.get('feedback');
        this.set('uiError', feedback.nextError());
        this.set('pendingErrors', !!this.get('uiError'));
      },
      clearCurrentSuccess: function clearCurrentSuccess() {
        this.set('uiSuccess', this.get('feedback').nextSuccess());
      },
      clearCurrentInfo: function clearCurrentInfo() {
        this.set('uiInfo', this.get('feedback').nextInfo());
      }
    }
  });
});