define("webui/pods/components/table-edit-fields/remove-row/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 2
          }
        },
        "moduleName": "webui/pods/components/table-edit-fields/remove-row/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "btn btn-default");
        var el2 = dom.createTextNode("Remove");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'onclick');
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "onclick", ["subexpr", "action", ["showConfirmation", ["get", "modelID", ["loc", [null, [1, 68], [1, 75]]]]], [], ["loc", [null, [1, 40], [1, 77]]]]], ["inline", "modal-wrapper", [], ["modalId", ["subexpr", "@mut", [["get", "modelID", ["loc", [null, [4, 10], [4, 17]]]]], [], []], "componentName", ["subexpr", "@mut", [["get", "componentName", ["loc", [null, [5, 16], [5, 29]]]]], [], []], "mainMessage", ["subexpr", "@mut", [["get", "deleteMessage", ["loc", [null, [6, 14], [6, 27]]]]], [], []], "confirmButtonAction", ["subexpr", "action", ["confirmRemove", ["get", "modelID", ["loc", [null, [7, 46], [7, 53]]]]], [], ["loc", [null, [7, 22], [7, 54]]]], "confirmButtonText", "Remove this material", "error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [9, 8], [9, 13]]]]], [], []], "hideHeader", "true"], ["loc", [null, [3, 0], [11, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});