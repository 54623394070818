define('webui/pods/components/drop-down/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run(function () {
        $('.selectpicker').selectpicker();
      });
    },

    actions: {
      setSelectedValue: function setSelectedValue() {
        var selectedValue = this.$('select')[0].value;
        this.sendAction('update', selectedValue);
      }
    }
  });
});