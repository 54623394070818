define('webui/pods/components/create-map-step-photo-upload/component', ['exports', 'npm:async', 'webui/mixins/data-panel', 'npm:exifreader'], function (exports, _npmAsync, _dataPanel, _npmExifreader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_dataPanel.default, {
    feedback: Ember.inject.service('user-feedback'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    mapData: Ember.inject.service('map-data'),
    fileManager: Ember.inject.service('file-manager'),
    isMSEdge: false,
    metaProgress: 0,
    metaLoading: false,
    browseStyle: '',

    testForEdge: function testForEdge() {
      var filter = new RegExp('Edge/');
      return filter.test(navigator.userAgent);
    },


    supportDragDrop: null,
    queueLength: 0,
    displayRetry: null,

    activeQueue: null,

    currentlyUploading: null,
    attemptedRetries: 0,

    wizardData: null, /* object created in create-map-wizard */
    wizard: null, /* this is passed via the template */

    allowedFileTypes: ['image/jpg', 'image/jpeg'],
    allowedPpkFileExtensionsRegex: /\.((mrk)|(obs))$/i,

    queueSegmentName: 'flightPhotos',

    init: function init() {
      this._super();
      this.set('isMSEdge', this.testForEdge());

      var component = this;
      var fileManager = this.get('fileManager');
      var flightId = this.wizardData.get('targetFlightId');

      var queue = fileManager.getQueueSegment(flightId, this.get('queueSegmentName'));
      if (!queue) {
        var apiParams = {
          type: 'photos',
          controller: 'photos',
          method: 'upload',
          context: component,
          data: {
            flightId: flightId
          },
          onSuccess: function onSuccess() {
            /* Need to do anything after photos uploaded? */
          },
          onUploadProgress: function onUploadProgress() /* progress */{
            /* callback for upload progress reporting */
          }
        };

        queue = fileManager.createQueueSegment(flightId, this.get('queueSegmentName'), apiParams);
      }

      this.set('activeQueue', Ember.A(queue.files));
      this.set('queueLength', component.get('activeQueue.length'));

      fileManager.addObserver('queues.' + flightId + '.segments.flightPhotos.filesChanged', function () {
        component.set('queueLength', component.get('activeQueue.length'));
        component.resetFileInput();
      });

      fileManager.set('currentFlight', this.wizardData.get('targetFlightId'));
    },
    convertDMSToDD: function convertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction === 'S' || direction === 'W') {
        dd *= -1;
      }

      return dd;
    },
    didInsertElement: function didInsertElement() {
      var feedback = this.get('feedback');
      var component = this;

      this.$('#file-' + this.elementId).bind('change', function change() {
        if (this.files.length > 0) {
          component.addFiles(this.files);
        }
      });

      if (!this.testDragDrop()) {
        feedback.reportInfo('Drag and drop file upload not supported by this browser. Please click browse to select files.');
        this.set('supportDragDrop', false);
        return;
      }
      this.set('supportDragDrop', true);

      var upload_form = this.$('#drag_upload_form');
      var fileDropStatus = this.$('.fileDropStatus');

      upload_form.on('drag dragstart dragend dragover dragenter dragleave drop', function (event) {
        event.preventDefault();
        event.stopPropagation();
      }).on('dragover dragenter', function () {
        upload_form.addClass('dragover');
      }).on('dragleave dragend drop', function () {
        upload_form.removeClass('dragover');
      }).on('drop', function (event) {
        fileDropStatus.addClass('processing');
        component.addFiles(event.originalEvent.dataTransfer.files);
      });

      if (component.get('activeQueue.length') > 0) {
        this.allowSave();
      }
    },
    willDestroyElement: function willDestroyElement() {
      // for clearing out any out-of-Ember-scope listeners we've set
      var flightId = this.wizardData.get('targetFlightId');
      var fileManager = this.get('fileManager');
      fileManager.removeObserver('queues.' + flightId + '.segments.flightPhotos.filesChanged');
      this.set('activeQueue', null);
      this.$('#file-' + this.elementId).off();
      return this.$('#drag_upload_form').off();
    },
    testDragDrop: function testDragDrop() {
      /* Copied wholesale from the original modal's logic */
      var div = document.createElement('div');
      return ('draggable' in div || 'ondragstart' in div && 'ondrop' in div) && 'FormData' in window && 'FileReader' in window;
    },
    addFiles: function addFiles(fileList) {
      var fileManager = this.get('fileManager');
      var component = this;
      var fileDropStatus = this.$('.fileDropStatus');

      var failedImages = [];
      var ppkFiles = [];
      var passedImages = []; /* NOTE: the FileList object is readonly in most cases so we have to divide into arrays. */
      var originalFileListLength = fileList.length;
      var originalActiveQueueLength = this.get('activeQueue.length');

      if (fileList.length > 25) {
        component.set('metaLoading', true);
        component.set('browseStyle', 'display:none;');
      }

      if (fileList.constructor.name && fileList.constructor.name !== 'FileList' || !fileList.constructor.name && fileList.toString().indexOf('FileList') === -1) {
        component.wizard.setMessage('danger', 'Error reading photos - please try again.');
        console.error('Files were not submitted in a FileList object and could not be processed.');
        return;
      }

      for (var i = 0; i < fileList.length; i += 1) {
        if (component.get('allowedFileTypes').indexOf(fileList[i].type.toLowerCase()) > -1) {
          passedImages.push(fileList[i]);
        } else if (this.get('allowedPpkFileExtensionsRegex').test(fileList[i].name)) {
          ppkFiles.push(fileList[i]);
        } else {
          failedImages.push(fileList[i]);
        }
      }

      if (failedImages.length > 0 && failedImages.length === originalFileListLength || passedImages.length === 0) {
        fileDropStatus.removeClass('processing');
        component.wizard.setMessage('danger', 'Photos must be in JPG or JPEG format.');
        return;
      }if (failedImages.length > 0) {
        component.wizard.setMessage('warning', 'Some photos were not in JPG or JPEG format and were skipped. Please verify all necessary files were attached.');
      }

      var queueLength = fileManager.addBatch(component.wizardData.get('targetFlightId'), component.get('queueSegmentName'), passedImages);
      component.set('queueLength', queueLength);
      // component.set('activeQueue', Ember.A(passedImages));

      if (queueLength === originalActiveQueueLength) {
        // in this case, the queue won't be perceived to have changed--possibly because we're
        // adding files that have already been added. So let's make sure we don't show the spinner.
        fileDropStatus.removeClass('processing');
      }

      this.processPhotoMeta(passedImages);
      this.set('ppkFiles', ppkFiles);
    },
    allowSave: function allowSave() {
      this.$('.js-trigger-save').removeAttr('disabled');
    },
    disallowSave: function disallowSave() {
      this.$('.js-trigger-save').attr('disabled', 'disabled');
    },
    resetFileInput: function resetFileInput() {
      this.$('#file-' + this.elementId).val(null); /* This is to bypass an idiosyncrasy of the change event that stops an update if the user removes and then adds the same files. */
    },
    processPhotoMeta: function processPhotoMeta(fileList) {
      var component = this;
      var fileNames = component.wizardData.get('photoMeta').map(function (file) {
        return file.fileName;
      });
      var filesThatWereNotProcessedYet = fileList.filter(function (file) {
        return !fileNames.contains(file.name);
      });
      var fileManager = component.get('fileManager');
      var flightId = component.wizardData.get('targetFlightId');
      var reader = new FileReader();
      var fileCount = filesThatWereNotProcessedYet.length;
      var checkLatLng = [];
      var photoMeta = [];

      filesThatWereNotProcessedYet.forEach(function (fileToCheck) {
        checkLatLng.push(function check() {
          var fileName = fileToCheck.name;

          return function LatLng(callback) {
            try {
              component.disallowSave();
              reader.onload = function onload() {
                try {
                  var expectedRtkFlag = 50;
                  var tags = _npmExifreader.default.load(reader.result);
                  var orientation = tags.Orientation.value;
                  var width = tags.PixelXDimension.value;
                  var height = tags.PixelYDimension.value;
                  var latitude = tags.GPSLatitude.value;
                  var longitude = tags.GPSLongitude.value;
                  var GpsLatitude = component.convertDMSToDD(latitude[0], latitude[1], latitude[2], tags.GPSLatitudeRef.value[0]);
                  var GpsLongitude = component.convertDMSToDD(longitude[0], longitude[1], longitude[2], tags.GPSLongitudeRef.value[0]);
                  var ShowAccuracyWarning = tags.RtkFlag ? parseFloat(tags.RtkFlag.value) !== expectedRtkFlag : true;

                  photoMeta.push({
                    fileName: fileName,
                    orientation: orientation,
                    width: width,
                    height: height,
                    GpsLongitude: GpsLongitude,
                    GpsLatitude: GpsLatitude,
                    ShowAccuracyWarning: ShowAccuracyWarning,
                    taggedGCPs: []
                  });

                  var progress = parseInt((photoMeta.length / fileCount).toFixed(2) * 100, 10);
                  // update in intervals of 5% progress because rendering is expensive
                  if (progress % 5 === 0 && progress !== component.get('metaProgress') && progress > component.get('metaProgress')) {
                    component.set('metaProgress', progress);
                  }
                } catch (error) {
                  // issue getting meta for photo, it wont be displayed
                }
                callback();
              };
              reader.readAsArrayBuffer(fileToCheck);
            } catch (error) {
              callback();
            }
          };
        }());
      });

      _npmAsync.default.series(checkLatLng, function () {
        var filesThatWereNotRemovedDuringMetaProcessing = fileManager.getQueueSegment(flightId, component.get('queueSegmentName')).files.map(function (file) {
          return file.name;
        });
        var joinedPhotoMeta = component.wizardData.get('photoMeta').concat(photoMeta.filter(function (photo) {
          return filesThatWereNotRemovedDuringMetaProcessing.contains(photo.fileName);
        }));
        var photoMetaWithBadLatLngRemoved = joinedPhotoMeta.filter(function (photo) {
          return photo.GpsLatitude !== null && photo.GpsLongitude !== null && !isNaN(photo.GpsLatitude) && !isNaN(photo.GpsLongitude);
        });

        component.wizardData.set('photoMeta', photoMetaWithBadLatLngRemoved);
        component.set('metaLoading', false);
        component.set('browseStyle', '');
        component.set('metaProgress', 0);

        if (component.get('activeQueue.length') > 0) {
          component.allowSave();
        }
      });
    },


    actions: {
      save: function save() {
        var fileManager = this.get('fileManager');
        var queueSegment = fileManager.getQueueSegment(this.wizardData.get('targetFlight.id'), this.get('queueSegmentName'));
        this.wizardData.set('photos', Ember.A(queueSegment.files));

        var currentPPKFiles = this.wizardData.get('ppkFiles') || [];
        var newPPKFiles = this.get('ppkFiles').filter(function (newFile) {
          return !(currentPPKFiles.map(function (file) {
            return file.name;
          }).indexOf(newFile.name) !== -1);
        });
        if (newPPKFiles.length) {
          var unionOfCurrentAndNewPPKFiles = [].concat(_toConsumableArray(currentPPKFiles), _toConsumableArray(newPPKFiles));
          this.wizardData.set('ppkFiles', unionOfCurrentAndNewPPKFiles);
        }

        this.sendAction('advanceNextStep'); /* Go to step 4 */
      },
      evaluateSave: function evaluateSave() {
        var queue = this.get('activeQueue');
        var metaLoading = this.get('metaLoading');

        if (queue.length < 1 || metaLoading) {
          this.disallowSave();
        } else {
          this.allowSave();
        }
      },
      removeAllFiles: function removeAllFiles() {
        var fileManager = this.get('fileManager');
        var currentFlight = this.wizardData.get('targetFlightId');

        fileManager.emptyQueueSegment(currentFlight, this.get('queueSegmentName'));
        this.resetFileInput();
        this.disallowSave();
        this.wizardData.set('photoMeta', []);

        this.actions.queueChanged.call(this);
        this.sendAction('queueChanged'); /* This is included to allow a parent to catch the event as well. */
      },
      queueChanged: function queueChanged(fileName) {
        var queue = this.get('activeQueue');
        var metaLoading = this.get('metaLoading');
        this.set('queueLength', queue.get('length'));
        var photoMeta = this.wizardData.get('photoMeta').filter(function (photo) {
          return photo.fileName !== fileName;
        });
        var photoMetaWithBadLatLngRemoved = photoMeta.filter(function (photo) {
          return photo.GpsLatitude !== null && photo.GpsLongitude !== null && !isNaN(photo.GpsLatitude) && !isNaN(photo.GpsLongitude);
        });
        this.wizardData.set('photoMeta', photoMetaWithBadLatLngRemoved);

        if (queue.length < 1 || metaLoading) {
          this.disallowSave();
        }

        this.sendAction('evaluateSave', queue); /* This is included to allow a parent to catch the event as well. */
      }
    }
  });
});