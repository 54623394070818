define('webui/components/app-version', ['exports', 'ember-cli-app-version/components/app-version', 'webui/config/environment'], function (exports, _appVersion, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var name = _environment.default.APP.name;
  var version = _environment.default.APP.version;

  exports.default = _appVersion.default.extend({
    version: version,
    name: name
  });
});