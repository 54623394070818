define('webui/mixins/region-panel-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Mixin.create({
    activateRegionCard: function activateRegionCard(selector) {
      if (!$(selector).position()) return;
      if (typeof selector === 'string') {
        ;
        var _selector$split = selector.split('_');

        var _selector$split2 = _slicedToArray(_selector$split, 1);

        selector = _selector$split2[0];
      } // some tools like workactivies have multiple regions per card, denoted by _0, _1, etc

      $('.regionActive').removeClass('regionActive');
      $(selector).addClass('regionActive');

      var containerSelector = void 0;
      var cardPostion = $(selector).position().top;
      if ($(selector).parents('#volumeCalcPanel').length > 0) {
        containerSelector = '#volumeCalcPanel .regionsList';
      } else if ($(selector).parents('#trackingRegionsPanel').length > 0) {
        containerSelector = '#trackingRegionsPanel .panel-body';
      } else if ($(selector).parents('#crossSectionsPanel').length > 0) {
        containerSelector = '#crossSectionsPanel .panel-body';
      } else if ($(selector).parents('#quantitiesPanel').length > 0) {
        containerSelector = '#quantitiesPanel .panel-body';
      } else if ($(selector).parents('#workActivityPanel').length > 0) {
        containerSelector = '#workActivityPanel .regionsList';
      } else if ($(selector).parents('.panel-blueprints').length > 0) {
        containerSelector = '.panel-blueprints';
      }

      $(containerSelector).animate({ scrollTop: cardPostion + $(containerSelector).scrollTop() }, 500);
    }
  });
});