define('webui/pods/components/admin-site-form/component', ['exports', 'webui/mixins/error-message', 'webui/config/environment'], function (exports, _errorMessage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorMessage.default, {
    removeSite: 'removeSite',
    doTransition: 'doTransition',
    store: Ember.inject.service(),
    feedback: Ember.inject.service('user-feedback'),
    showDeleteMessage: false,

    newClient: '',

    init: function init() {
      this._super();

      this.set('showDeleteMessage', false);
    },
    didInsertElement: function didInsertElement() {
      $('#status').val(this.get('model.status'));
    },


    actions: {
      submitModel: function submitModel() {
        var feedback = this.get('feedback');
        var action = this.get('save_action');
        var store = this.get('store');
        var site = this.get('model');

        if (site.get('clients.length') > 1) {
          feedback.reportError({ title: 'Site Update Error', detail: 'A site may only have 1 client.' });
          return;
        }

        if (action === 'edit') {
          this.set('model.status', $('#status').val());

          site.save().then(feedback.reportSuccess('Site saved')).catch(function (error) {
            feedback.reportError(error);
          });
        } else if (action === 'create') {
          var newSite = store.createRecord('site', {
            name: this.get('model.name'),
            description: this.get('model.description'),
            latitudeBoundaryA: this.get('model.latitudeBoundaryA'),
            longitudeBoundaryA: this.get('model.longitudeBoundaryA'),
            latitudeBoundaryB: this.get('model.latitudeBoundaryB'),
            longitudeBoundaryB: this.get('model.longitudeBoundaryB'),
            status: $('#status').val()
          });
          newSite.save().then(feedback.reportSuccess('Site created')).catch(function (error) {
            feedback.reportError(error);
          });
        }
      },
      loadFlightForEdit: function loadFlightForEdit(id) {
        this.sendAction('loadFlightForEdit', id);
      },
      selectSiteStatus: function selectSiteStatus(status) {
        if (this.get('save_action') === 'create') {
          this.set('model.status', status);
        } else if (this.get('save_action') === 'edit') {
          this.set('model.site.status', status);
        }
      },
      selectClientValue: function selectClientValue(client) {
        this.set('newClient', client);
      },
      confirmChangeClient: function confirmChangeClient() {
        if (this.get('newClient') !== '') {
          $('#siteClientChangeConfirm').modal('show');
        }
      },
      changeClient: function changeClient() {
        var feedback = this.get('feedback');
        var client_id = this.get('newClient');
        var store = this.get('store');
        var newClient = store.peekRecord('client', client_id);
        var site = this.get('model');
        var currentClient = site.get('clients').get('firstObject');

        if (client_id === '') {
          return;
        }

        if (site.get('clients.length') > 0) {
          $('#siteClientChangeConfirm').modal('hide');
          site.get('clients').removeObject(currentClient);
        }

        site.get('clients').pushObject(newClient);

        if (site.get('clients.length') > 1) {
          feedback.reportError('A site may only have 1 client.');
          site.get('clients').removeObject(newClient);
          site.get('clients').pushObject(currentClient);
        } else {
          site.save().then(feedback.reportSuccess('Client changed')).catch(function (error) {
            feedback.reportError(error);
          });
        }
      },
      confirmRemoveClient: function confirmRemoveClient(client_id) {
        this.set('clientToRemove', client_id);
        $('#siteClientRemoveConfirm').modal('show');
      },
      removeClient: function removeClient() {
        var feedback = this.get('feedback');
        var site = this.get('model');
        var store = this.get('store');
        var clientBeingRemoved = this.get('clientToRemove');
        var client = store.peekRecord('client', clientBeingRemoved);

        $('#siteClientRemoveConfirm').modal('hide');

        site.get('clients').removeObject(client);

        jQuery.ajax({
          url: _environment.default.APP.apiUrl + 'sites/' + site.id + '/removeClient',
          method: 'post',
          success: function success() {
            return store.findRecord('site', site.id).then(function () {
              return feedback.reportSuccess('Client removed');
            });
          },
          error: function error(_error) {
            return feedback.reportError(_error);
          }
        });
      },
      toggleDeleteWarning: function toggleDeleteWarning() {
        if (this.get('showDeleteMessage') === true) {
          this.set('showDeleteMessage', false);
        } else {
          this.set('showDeleteMessage', true);
        }
      },
      deleteSite: function deleteSite() {
        var _this = this;

        var component = this;
        var site = this.get('model');
        var siteId = component.get('model.id');
        if (component.get('model.id') === null || component.get('model.id') === undefined) {
          siteId = site._internalModel.id;
        }
        component.get('store').findRecord('site', siteId, { reload: true }).then(function (updateSite) {
          updateSite.deleteRecord();
          updateSite.save().then(function () {
            _this.get('feedback').reportSuccess({ title: 'Success', detail: 'Site was successfully deleted' });
            component.sendAction('removeSite', siteId);
            component.sendAction('doTransition', 'admin.site');
          }).catch(function () {
            component.set('showDeleteMessage', false);
          });
        });
      }
    }
  });
});