define("webui/pods/components/data-panel/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/data-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", [["get", "dataPanelService.dataPanelComponent", ["loc", [null, [4, 13], [4, 48]]]]], ["activeSite", ["subexpr", "@mut", [["get", "activeSite", ["loc", [null, [5, 15], [5, 25]]]]], [], []], "activeFlight", ["subexpr", "@mut", [["get", "activeFlight", ["loc", [null, [6, 17], [6, 29]]]]], [], []], "products", ["subexpr", "@mut", [["get", "products", ["loc", [null, [7, 13], [7, 21]]]]], [], []], "activeSiteUsers", ["subexpr", "@mut", [["get", "activeSiteUsers", ["loc", [null, [8, 20], [8, 35]]]]], [], []], "updateSiteUsers", "updateSiteUsers", "regions", ["subexpr", "@mut", [["get", "regions", ["loc", [null, [10, 12], [10, 19]]]]], [], []], "trackingregions", ["subexpr", "@mut", [["get", "trackingregions", ["loc", [null, [11, 20], [11, 35]]]]], [], []], "blueprints", ["subexpr", "@mut", [["get", "blueprints", ["loc", [null, [12, 15], [12, 25]]]]], [], []], "siteGrades", ["subexpr", "@mut", [["get", "siteGrades", ["loc", [null, [13, 15], [13, 25]]]]], [], []], "siteGrades", ["subexpr", "@mut", [["get", "siteGrades", ["loc", [null, [14, 15], [14, 25]]]]], [], []], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [15, 11], [15, 15]]]]], [], []], "goToFileManager", ["subexpr", "action", ["goToFileManager"], [], ["loc", [null, [16, 20], [16, 46]]]], "goToMaterialManager", ["subexpr", "action", ["goToMaterialManager"], [], ["loc", [null, [17, 24], [17, 54]]]], "goToRegionManager", ["subexpr", "action", ["goToRegionManager"], [], ["loc", [null, [18, 22], [18, 50]]]], "authenticatedUser", ["subexpr", "@mut", [["get", "authenticatedUser", ["loc", [null, [19, 22], [19, 39]]]]], [], []], "updateFlightModelProducts", "updateFlightModelProducts", "trueTonnageReport", ["subexpr", "@mut", [["get", "trueTonnageReport", ["loc", [null, [21, 22], [21, 39]]]]], [], []], "historicalExports", ["subexpr", "@mut", [["get", "historicalExports", ["loc", [null, [22, 22], [22, 39]]]]], [], []]], ["loc", [null, [4, 1], [23, 4]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 2
            },
            "end": {
              "line": 37,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/data-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", [["get", "dataPanelService.floatingPanelComponent", ["loc", [null, [28, 16], [28, 55]]]]], ["activeFlight", ["subexpr", "@mut", [["get", "activeFlight", ["loc", [null, [29, 19], [29, 31]]]]], [], []], "activeSite", ["subexpr", "@mut", [["get", "activeSite", ["loc", [null, [30, 17], [30, 27]]]]], [], []], "allFlights", ["subexpr", "@mut", [["get", "allFlights", ["loc", [null, [31, 17], [31, 27]]]]], [], []], "changeDetections", ["subexpr", "@mut", [["get", "changeDetections", ["loc", [null, [32, 23], [32, 39]]]]], [], []], "tiledMaps", ["subexpr", "@mut", [["get", "tiledMaps", ["loc", [null, [33, 16], [33, 25]]]]], [], []], "goToFileManager", ["subexpr", "action", ["goToFileManager"], [], ["loc", [null, [34, 22], [34, 48]]]], "authenticatedUser", ["subexpr", "@mut", [["get", "authenticatedUser", ["loc", [null, [35, 24], [35, 41]]]]], [], []]], ["loc", [null, [28, 4], [36, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/data-panel/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["data-panel ", ["subexpr", "if", [["get", "dataPanelService.isOpen", ["loc", [null, [2, 28], [2, 51]]]], "visible", ""], [], ["loc", [null, [2, 23], [2, 66]]]], " ", ["get", "dataPanelService.dataPanelComponent", ["loc", [null, [2, 69], [2, 104]]]]]]], ["block", "if", [["get", "dataPanelService.isOpen", ["loc", [null, [3, 6], [3, 29]]]]], [], 0, null, ["loc", [null, [3, 0], [24, 7]]]], ["block", "if", [["get", "dataPanelService.floatingPanelIsOpen", ["loc", [null, [27, 8], [27, 44]]]]], [], 1, null, ["loc", [null, [27, 2], [37, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});