define('webui/pods/components/visual-file-uploader/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fileManager: Ember.inject.service('file-manager'),

    pendingQueue: null,

    pendingFiles: null,

    pendingFileDictionary: {},

    init: function init() {
      var _this = this;

      var component = this;
      var fileManager = this.get('fileManager');
      var currentFlight = this.get('flightId');

      this._super();

      this.set('pendingFiles', Ember.A());

      /* Check for any abandoned files that user may have added before changing steps. */
      var purgatoryFiles = fileManager.getQueueSegment(currentFlight, 'flightPhotos');
      if (purgatoryFiles) {
        component.set('pendingQueue', purgatoryFiles.files);

        if (purgatoryFiles.files.length > 0) {
          component.populateAddedFiles();
        }
      } else {
        component.set('pendingQueue', Ember.A());
        component.set('pendingFileDictionary', {});
      }

      /*
       The observer is designed to update the display of file queues.  In order
       to have live DOM updates, these need to be ember mutable array objects, which need to
       be updated explicitly from the file manager's vanilla queues.
       */

      fileManager.addObserver('queues.' + currentFlight + '.segments.flightPhotos.filesChanged', function () {
        var updatedQueue = fileManager.getQueueSegment(currentFlight, 'flightPhotos');
        var previousFiles = Object.keys(component.get('pendingFileDictionary'));

        if (!component.get('pendingFiles') || updatedQueue.files.length === 0) {
          component.set('pendingFiles', Ember.A());
          previousFiles.forEach(function (filename) {
            delete component.pendingFileDictionary[filename];
          });
          component.set('pendingFileDictionary', {});
        }

        Ember.run.debounce(_this, component.populateAddedFiles, 1000);
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      var flightId = this.get('flightId');
      var fileManager = this.get('fileManager');
      var pendingFileDictionary = this.get('pendingFileDictionary');
      var previousFiles = Object.keys(pendingFileDictionary);
      fileManager.removeObserver('queues.' + flightId + '.segments.flightPhotos.filesChanged');

      previousFiles.forEach(function (filename) {
        delete _this2.pendingFileDictionary[filename];
      });

      this.set('pendingFiles', null);
      this.set('pendingFileDictionary', {});
    },
    addToEmberArray: function addToEmberArray(array, values, source) {
      var _this3 = this;

      var target = this.get(array);
      var component = this;
      var fileDropStatus = window.$('.fileDropStatus');

      values.forEach(function (file) {
        if (!component.pendingFileDictionary[file.name]) {
          component.pendingFileDictionary[file.name] = true;
          target.pushObject(file);
          component.set(array, target);
          if (source) {
            _this3.removeFromEmberArray(source, file);
          }
        }
      });

      fileDropStatus.removeClass('processing');

      return this.set(array, target.sortBy('name'));
    },
    removeFromEmberArray: function removeFromEmberArray(array, value) {
      var target = this.get(array);
      delete this.pendingFileDictionary[value.name];
      return this.set(array, target.removeObject(value).compact());
    },
    populateAddedFiles: function populateAddedFiles() {
      var fileManager = this.get('fileManager');
      var currentFlight = this.get('flightId');
      var unprocessedFiles = fileManager.getQueueSegment(currentFlight, 'flightPhotos');
      if (unprocessedFiles) {
        this.set('pendingQueue', unprocessedFiles.files);
        this.addToEmberArray('pendingFiles', this.get('pendingQueue'));
      }
    },
    removeFile: function removeFile(file) {
      /* This should almost certainly be a method of the file-manager service, but is included here to reduce complexity of the update. */
      var fmq = this.get('pendingQueue');
      var l = fmq.indexOf(file);

      if (l > -1) {
        fmq.splice(l, 1);

        this.populateAddedFiles();
        this.sendAction('queueChanged', file.name);
        this.removeFromEmberArray('pendingFiles', file);
      }
    },


    actions: {
      removeFile: function removeFile(file, index) {
        this.removeFile(file, index);
      }
    }

  });
});