define('webui/services/svg-to-bitmap', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    convertSVGToBitmap: function convertSVGToBitmap(chartSvgNode, canvas) {
      var SVG2Bitmap = function SVG2Bitmap(e, t, n) {
        if (n || (n = {}), (!n.scale || n.scale < 0) && (n.scale = 1), e && e.nodeName) {
          var r;if (e.nodeName === 'OBJECT' || e.nodeName === 'IFRAME') {
            if (!e.contentDocument || e.contentDocument.readyState === 'complete' && !e.contentDocument.documentElement) return void console.error('Unable to access the svg node : make sure it comes from the same domain or that the container has finished loading');if (e.contentDocument.readyState !== 'complete') return void e.addEventListener('load', d);r = e, e = e.contentDocument.documentElement;
          } else if (e.nodeName === 'EMBED' && e.getSVGDocument && (r = e, !(e = e.getSVGDocument()))) return r.addEventListener('load', d), r.onerror = function () {
            console.error('Unable to access the svg node : make sure it comes from the same domain or that the container has finished loading');
          }, void (r.src = r.src);if (e.nodeName !== 'svg') {
            var a = e.querySelector('svg');if (!a) {
              var o = '[src*=".svg"]';var i = e.querySelector('iframe' + o + ', embed' + o) || e.querySelector('object[data*=".svg"]');return i ? void SVG2Bitmap(i, t, n) : void console.error('unable to access the svg node, make sure it has been appended to the document');
            }e = a;
          }var l;var s = 'http://www.w3.org/1999/xlink';var c = 'http://www.w3.org/2000/svg';var h = e.cloneNode(!0);var u = function () {
            var e = document.createElement('canvas');var t = e.getContext('2d');e.width = e.height = 1;var n = document.implementation.createHTMLDocument('test');var r = document.createElement('base');n.head.appendChild(r);var a = document.createElement('a');return n.body.appendChild(a), {
              isTainted: function isTainted(n) {
                var r = !1;t.drawImage(n, 0, 0);try {
                  e.toDataURL();
                } catch (n) {
                  r = !0, e = e.cloneNode(!0), t = e.getContext('2d');
                }return r;
              },
              URL: function URL(e, t) {
                return r.href = t, a.href = e, a;
              }
            };
          }();!function () {
            var t = void 0;var n = [];var r = [];var a = e.ownerDocument.styleSheets;for (t = 0; t < a.length; t++) {
              r.push(a[t]);
            }r.length && (m(), e.matches = e.matches || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector || e.oMatchesSelector);for (t = 0; t < r.length; t++) {
              var o;var _i = r[t];try {
                o = _i.cssRules;
              } catch (e) {
                continue;
              }for (var d = document.createElement('style'), f = o && o.length, g = 0; g < f; g++) {
                var p = o[g].selectorText;if (p && (e.matches && e.matches(p) || e.querySelector(p))) {
                  for (var w = o[g].cssText, b = new RegExp(/url\((.*?)\)/g), E = []; (E = b.exec(w)) !== null;) {
                    var y = E[1].replace(/"/g, '');var S = _i.href || location.href;n.push([y, S]);var x = u.URL(y, S);var C = S === location.href && y.indexOf('.svg') < 0 ? x.hash : x.href.substring(x.href.lastIndexOf('/') + 1);var N = '#' + C.replace(/\//g, '_').replace(/\./g, '_').replace('#', '_');w = w.replace(y, N);
                  }d.innerHTML += w + '\n';
                }
              }d.innerHTML && l.appendChild(d);
            }var A = h.style;A.border = A.padding = A.margin = 0, A.transform = 'initial', function (t) {
              var n = void 0;var r = 0;var a = 0;var o = [{
                href: location.href.replace(location.hash, '').replace(/#/g, ''), pathname: location.pathname, filename: '', innerElements: [], parsedElements: [], doc: e.ownerDocument, base: location.href.replace(location.hash, '').replace(/#/g, '')
              }];var i = function () {
                var e = document.createElementNS(c, 'use');e.setAttributeNS(s, 'href', '#__#'), h.appendChild(e);var t = !!h.querySelector('[*|href*="#"]');return h.removeChild(e), t;
              }();var d = function d(e, t) {
                var r = void 0;var a = void 0;var o = void 0;var l = void 0;var c = void 0;var h = [];var u = function u(e, n) {
                  var r = void 0;var a = {};if (a.element = e, a.type = n, a.attributes = [], a.requestedElements = [], a.parentDoc = t, n === 'xl') {
                    if (!(r = e.attributes['xlink:href'])) {
                      var _o = e.attributes.href;if (!(_o && _o.namespaceURI && _o.namespaceURI.indexOf('xlink') > -1)) return !1;r = _o;
                    }a.attributes.push(r), a.requestedElements.push(r.value);
                  } else {
                    r = e.attributes;for (var _i2 = 0; _i2 < r.length; _i2++) {
                      for (var _l = new RegExp(/url\((.*?)\)/g), _s = []; (_s = _l.exec(r[_i2].value)) !== null;) {
                        a.attributes.push(r[_i2]), a.requestedElements.push(_s[1].replace(/"/g, ''));
                      }
                    }
                  }return a;
                };var d = (r = e, i ? r.querySelectorAll('[*|href*="#"]') : function () {
                  var e = [];var t = r.querySelectorAll('*');for (n = 0; n < t.length; n++) {
                    var _a = t[n].getAttributeNS(s, 'href');_a && _a.indexOf('#') > -1 && e.push(t[n]);
                  }return e;
                }());var m = (a = e, o = '[*|' + ['style', 'clip-path', 'src', 'cursor', 'fill', 'filter', 'marker', 'marker-start', 'marker-mid', 'marker-end', 'mask', 'stroke'].join('*="url"], *[*|') + '*="url"]', a.querySelectorAll(o));for (l = 0; l < d.length; l++) {
                  (c = u(d[l], 'xl')) && (h.push(c), c = null);
                }for (l = 0; l < m.length; l++) {
                  (c = u(m[l], 'url')) && (h.push(c), c = null);
                }var f = e.attributes;for (l = 0; l < f.length; l++) {
                  var _g = f[l];if (_g.name === 'xlink:href') h.push(new u(e, 'xl'));else {
                    var _v = _g.value.match(/url\((.*)\)/);_v && _v.length > 1 && h.push(new u(e, 'url'));
                  }
                }return h;
              };var f = function f(e, t) {
                for (var _n = e.querySelectorAll('image'), _r = 0; _r < _n.length; _r++) {
                  var _a2 = _n[_r].getAttributeNS(s, 'href');var _o2 = u.URL(_a2, t).href;_a2 !== _o2 && _n[_r].setAttributeNS(s, 'href', _o2);
                }
              };var g = function g() {
                var e = void 0;for (e = 0; e < o.length; e++) {
                  var _t = o[e];if (_t.doc) {
                    var n;var _i3 = _t.innerElements;if (_i3.length !== _t.parsedElements.length) for (n = 0; n < _i3.length; n++) {
                      var _s2 = _t.doc.getElementById(_i3[n]);if (_s2) {
                        var _c = _s2.cloneNode(!0);_c.id = _t.filename + '_' + _i3[n], f(_c, _t.base), l.appendChild(_c), w(d(_c, _t)), _t.parsedElements.push(_i3[n]), r--;
                      } else console.warn("Couldn't find this element", _i3[n]), r--;
                    }
                  }
                }a || r || v();
              };var p = function p(e, t) {
                for (var n = u.URL(e, t.base), i = n.href.substring(n.href.lastIndexOf('/') + 1).replace(n.hash, ''), l = i.replace(/\./g, '_'), s = n.hash.replace('#', ''), c = n.href.replace(n.hash, ''), d = l + '_' + s, m = 0; m < o.length; m++) {
                  var _f = o[m];if (_f.href === c) {
                    if (m === 0) {
                      if (h.getElementById(s)) return s;d = '_' + s;
                    }return _f.innerElements.indexOf(s) < 0 ? (_f.doc !== null ? r++ : console.warn('this element is also lost ', s), _f.innerElements.push(s), d) : d;
                  }
                }r++, a++;var v = void 0;var p = void 0;var w = void 0;var b = {
                  href: c, filename: l, innerElements: [s], parsedElements: []
                };return o.push(b), p = (v = b).href, (w = new XMLHttpRequest()).onload = function () {
                  if (this.status === 200) {
                    var _e = this.responseText || this.response;if (!_e) return;try {
                      v.doc = new DOMParser().parseFromString(_e, 'text/html');
                    } catch (t) {
                      v.doc = document.implementation.createHTMLDocument(v.filename), v.doc.body.innerHTML = _e;
                    }v.base = p;
                  } else v.doc = null, r -= v.innerElements.length, console.warn('could not load this external document :', p, '\nThose elements are lost : ', v.innerElements.join(' , '));--a || g();
                }, w.onerror = function () {
                  v.doc = null, r -= v.innerElements.length, console.warn('could not load this external document', p), console.warn('Those elements are lost : ', v.innerElements.join(' , ')), --a || g();
                }, w.open('GET', p), w.send(), d;
              };var w = function w(e) {
                var t = void 0;var n = void 0;for (e.length && !l && m(), t = 0; t < e.length; t++) {
                  var _r2 = e[t];for (n = 0; n < _r2.requestedElements.length; n++) {
                    var _a3 = _r2.requestedElements[n];var _o3 = '#' + p(_a3, _r2.parentDoc);var _i4 = _r2.attributes[n];var _s3 = _i4.value.replace(_a3, _o3);var _c2 = _i4.name.toUpperCase() === _i4.name ? _i4.name.toLowerCase() : _i4.name;_r2.element.setAttribute(_c2, _s3);
                  }
                }
              };for (n = 0; n < t.length; n++) {
                p(t[n][0], { base: t[n][1] });
              }w(d(h, o[0])), a || (r ? g() : v());
            }(n);
          }();
        } else console.error('Wrong arguments : should be \n SVG2Bitmap(SVGElement, function([canvasElement],[dataURL]) || IMGElement || CanvasElement [, {parameters})');function d() {
          this.removeEventListener('load', d), SVG2Bitmap(this, t, n);
        }function m() {
          (l = h.querySelector('defs') || document.createElementNS(c, 'defs')).parentNode || h.insertBefore(l, h.firstElementChild);
        }function f() {
          var a = void 0;var o = r ? r.getBoundingClientRect() : e.getBoundingClientRect();function i(e) {
            for (var _t2 = Array.prototype.slice.call(e.attributes), _n2 = 0; _n2 < _t2.length; _n2++) {
              var _r3 = _t2[_n2].name;_r3.indexOf(':') > -1 && _r3.indexOf('xlink') < 0 && e.removeAttribute(_r3);
            }
          }if (e.width.baseVal.unitType !== 1 && h.setAttribute('width', o.width), e.height.baseVal.unitType !== 1 && h.setAttribute('height', o.height), typeof ActiveXObject !== 'undefined') {
            i(h);for (var _l2 = h.querySelectorAll('*'), _s4 = 0; _s4 < _l2.length; _s4++) {
              i(_l2[_s4]);
            }
          }h.removeAttribute('style'), a = new XMLSerializer().serializeToString(h);var c = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(a);var d = new Image();d.onload = function a() {
            var i = t && t.nodeName === 'CANVAS' ? t : document.createElement('canvas');if (i.originalSVG = r || e, i.width = o.width * n.scale, i.height = o.height * n.scale, i.width && i.height) {
              var _l3 = i.getContext('2d');n.backgroundColor && (_l3.fillStyle = n.backgroundColor, _l3.fillRect(0, 0, i.width, i.height));var _s5 = n.scale;var _c3 = r ? e.getBoundingClientRect() : { top: 0, left: 0 };try {
                _l3.drawImage(this, _c3.left, _c3.top, this.width * _s5 || i.width, this.height * _s5 || i.height);
              } catch (e) {
                setTimeout(a.bind(this), 200);
              }if (t || (t = function t(_t3) {
                var n = r || e;n.parentNode.replaceChild(_t3, n);
              }), u.isTainted(i)) return console.warn('Your browser has tainted the canvas.'), void (t.nodeName === 'IMG' ? t.parentNode.replaceChild(i, t) : (i.setAttribute('style', g(i)), t !== i && t.appendChild ? t.appendChild(i) : typeof t === 'function' && t(i, null)));t.nodeName === 'IMG' ? (t.setAttribute('style', g(t)), t.src = i.toDataURL(n.type, n.quality)) : (i.setAttribute('style', g(i)), t !== i && t.appendChild ? t.appendChild(i) : typeof t === 'function' && t(i, i.toDataURL(n.type, n.quality)));
            } else console.error('The document is not visible and can not be rendered');
          }, d.onerror = function () {
            console.error("Couldn't export svg, please check that the svgElement passed is a valid svg document.");
          }, d.src = c;
        }
        function g(t) {
          if (e && e.parentNode) {
            var _n3 = t.cloneNode(!0);e.parentNode.documentElement ? e.parentNode.documentElement.appendChild(_n3) : e.parentNode.insertBefore(_n3, e);for (var a = getComputedStyle(_n3), o = getComputedStyle(r || e), i = '', l = 0; l < o.length; l++) {
              o[l] !== 'width' && o[l] !== 'height' && o[o[l]] !== a[o[l]] && (i += o[l] + ':' + o[o[l]] + ';');
            }return _n3.parentNode.removeChild(_n3), i;
          }
        }
        function v() {
          var t = void 0;var r = h.querySelectorAll('image');var a = r.length;var o = 0;if (a !== 0) {
            var _i5 = [];var _l4 = e.querySelectorAll('image');for (t = 0; t < r.length; t++) {
              if (_l4[t] && _l4[t].isEqualNode(r[t])) _i5.push(_l4[t]);else {
                for (var c = null, d = 0; d < _l4.length; d++) {
                  if (_l4[d].isEqualNode(r[t])) {
                    c = _l4[d];break;
                  }
                }_i5.push(c);
              }
            }var m = document.createElement('canvas');var g = m.getContext('2d');for (t = 0; t < r.length; t++) {
              var _v2 = r[t].getAttributeNS(s, 'href');if (_v2 && _v2.indexOf('data:image') < 0) _v2.indexOf('.svg') > 0 ? b(_v2, r[t]) : w(r[t], _i5[t]);else if (++o === a) return void f();
            }
          } else f();function p(e) {
            var t = new Image();t.onload = function () {
              ++o === a && f();
            }, t.src = e;
          }function w(e, t) {
            var r = new Image();var i = function i() {
              console.warn('failed to load an image at : ', r.src), n.keepImageHolder || e.parentNode.removeChild(e), --a === o && f();
            };n.noCORS || (r.crossOrigin = 'Anonymous'), r.onload = function () {
              var r = void 0;var a = void 0;if (t && (r = e.getAttribute('preserveAspectRatio'), a = t.getBoundingClientRect()), t && a && (a.width * n.scale < this.width || a.height * n.scale < this.height)) {
                m.width = a.width * n.scale, m.height = a.height * n.scale;var _o4 = function (e, t, n) {
                  var r = e.width;var a = e.height;var o = t.width;var i = t.height;function l(e) {
                    var t = a / r;var n = i / o;var l = n > t ? o : i / t;var h = n > t ? o * t : i;function u(e, t, n) {
                      var r = Math.max(t, n);var a = Math.min(t, n);switch (e) {case 'Min':
                          return 0;case 'Mid':
                          return (r - a) / 2;case 'Max':
                          return r - a;default:
                          return 'invalid';}
                    }var d = [s, 0, 0, r, a, u(e[0], l, o), u(e[1], h, i), l, h];return d[5] === 'invalid' || d[6] === 'invalid' ? c : d;
                  }var s = e.nodeName === 'IMG' || e.nodeName === 'VIDEO' || e.nodeName === 'CANVAS' ? e : null;var c = l(['Mid', 'Mid']);{
                    if (!n) return c;var _h = n.trim().split(' ');var _u = _h[0].replace('x', '').split('Y');switch (_h[_h.length - 1]) {case 'meet':
                        return l(_u);case 'slice':
                        return function (e) {
                          var t = void 0;var n = void 0;function l() {
                            t = o, n = a * o / r;
                          }function h() {
                            t = r * i / a, n = i;
                          }o > i ? (l(), i > n && h()) : o === i ? r > a ? h() : l() : (h(), o > t && l());function u(e, t, n, r) {
                            switch (e) {case 'Min':
                                return 0;case 'Mid':
                                return (t - n) / 2 * r / t;case 'Max':
                                return (t - n) * r / t;default:
                                return 'invalid';}
                          }var d = u(e[0], t, o, r);var m = u(e[1], n, i, a);var f = [s, d, m, r - d, a - m, 0, 0, t - d * (t / r), n - m * (n / a)];if (f[1] === 'invalid' || f[2] === 'invalid') return c;return f;
                        }(_u);default:
                        return c;}
                  }
                }(this, m, r);g.drawImage.apply(g, _o4);
              } else m.width = this.width, m.height = this.height, g.drawImage(this, 0, 0);if (u.isTainted(m)) i();else {
                var _l5 = m.toDataURL();e.setAttributeNS(s, 'href', _l5), p(_l5);
              }
            }, r.onerror = function () {
              var e = this.src;this.onerror = i, this.removeAttribute('crossorigin'), this.src = '', this.src = e;
            }, r.src = e.getAttributeNS(s, 'href');
          }function b(e, t) {
            var n = new XMLHttpRequest();n.onload = function () {
              if (this.status === 200) {
                var _e2 = this.responseText || this.response;var _n4 = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent('<svg' + _e2.split('<svg')[1]);t.setAttributeNS(s, 'href', _n4), p(_n4);
              } else w(t);
            }, n.onerror = function () {
              w(t);
            };try {
              n.open('GET', e);
            } catch (e) {
              return void w(t);
            }n.send();
          }
        }
      };

      return SVG2Bitmap(chartSvgNode, canvas);
    }
  });
});