define('webui/models/job', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    flight: _emberData.default.belongsTo('flight', { async: true }),
    instance: _emberData.default.belongsTo('instance', { async: true }),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    epsgCode: _emberData.default.attr('number'),
    photosCount: _emberData.default.attr('number'),
    dsmGridDistance: _emberData.default.attr('number'),
    gcpRequiredAccuracy: _emberData.default.attr('number'),
    contourInterval: _emberData.default.attr('number'),
    contourResolution: _emberData.default.attr('number'),
    contourSize: _emberData.default.attr('number'),
    classification: _emberData.default.attr('string'),
    baseStationLatitude: _emberData.default.attr('number'),
    baseStationLongitude: _emberData.default.attr('number'),
    baseStationEllipsoidHeight: _emberData.default.attr('number'),
    baseStationAntennaHeight: _emberData.default.attr('number'),
    miscNotes: _emberData.default.attr('string'),
    correctionMethod: _emberData.default.attr('string'),
    postedDate: _emberData.default.attr('date'),
    postedVersion: _emberData.default.attr('number'),
    useTurboProcessing: _emberData.default.attr('boolean'),
    errMessage: _emberData.default.attr('string'),
    durationInHours: Ember.computed('createdAt', function durationInHours() {
      return Math.round((new Date() - new Date(this.get('createdAt'))) / (1000 * 3600));
    }),
    durationStyling: Ember.computed('createdAt', 'status', function durationStyling() {
      var createdAtDate = new Date(this.get('createdAt'));
      var diffDateInHours = Math.round((new Date() - createdAtDate) / (1000 * 3600));

      if (this.get('status') === 'succeeded') {
        return '';
      }
      if (diffDateInHours >= 48) {
        return 'text-danger';
      }
      if (diffDateInHours >= 24) {
        return 'text-warning';
      }

      return '';
    }),
    statusType: Ember.computed('status', function styling() {
      var status = this.get('status');
      var blockedStatuses = ['needs-human', 'needs-class'];
      var failedStatuses = ['failed-part-one', 'failed-part-two', 'failed-part-three', 'failed-upload'];
      var succeededStatuses = ['succeeded'];

      if (failedStatuses.indexOf(status) !== -1) {
        return 'failed';
      }
      if (blockedStatuses.indexOf(status) !== -1) {
        return 'blocked';
      }
      if (succeededStatuses.indexOf(status) !== -1) {
        return 'succeeded';
      }

      return 'ready';
    }),
    styling: Ember.computed('statusType', function styling() {
      var statusType = this.get('statusType');

      if (statusType === 'failed') {
        return 'danger';
      }
      if (statusType === 'blocked') {
        return 'warning';
      }
      if (statusType === 'succeeded') {
        return 'success';
      }

      return 'info';
    })
  });
});