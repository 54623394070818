define('webui/pods/components/map-layer-selector/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    mapData: Ember.inject.service('map-data'), // This service stores the google map (and google API) for sharing with other map components.

    didInsertElement: function didInsertElement() {
      // using this hook to restore map state if we're auto-loading layers.
      var component = this;
      var mapData = component.get('mapData');
      var mapLayer = component.get('map');

      Ember.run.next(function () {
        // needs to be in next() to wait for the layer to be loaded.
        if (mapLayer && mapData.currentActiveLayers[mapLayer.get('type')]) {
          component.selectOption(mapLayer);
        }
      });
    },
    selectOption: function selectOption(layer) {
      var selector = '.layer-toggle.' + layer.get('type') + '.' + layer.id;
      $(selector).addClass('on');
      return $(selector + ' > .glyphicons-unchecked').removeClass('glyphicons-unchecked').addClass('glyphicons-check');
    },
    deselectOption: function deselectOption(layer) {
      var selector = '.layer-toggle.' + layer.get('type') + '.' + layer.id;
      $(selector).removeClass('on');
      return $(selector + ' > .glyphicons-check').removeClass('glyphicons-check').addClass('glyphicons-unchecked');
    },


    actions: {
      toggleLayerVisibility: function toggleLayerVisibility(layer) {
        var mapData = this.get('mapData');

        if (mapData.currentActiveLayers[layer.get('type')]) {
          // if it exists, destroy it.
          this.deselectOption(layer);
        } else {
          this.selectOption(layer);
        }

        mapData.toggleLayerVisibility(layer.get('type'));

        if (layer.get('type') !== 'orthomosaic') {
          this.trackEvent('Overlay View (' + layer.get('type') + ')', { siteId: mapData.get('currentSite.id'), siteName: mapData.get('currentSite.name'), flightId: mapData.get('currentFlightId') });
        }
      }
    }

  });
});