define('webui/mixins/file-download', ['exports', 'npm:xlsx'], function (exports, _npmXlsx) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    _parseTableDataFromCsv: function _parseTableDataFromCsv(csvData) {
      try {
        var csvLines = csvData.split('\n');
        var parsedTables = [];
        var startedNewTable = true;
        var tableIndex = 0;
        var tableName = [];

        csvLines.forEach(function (line) {
          var values = line.split(',').map(Function.prototype.call, String.prototype.trim);
          if (values.length === 0 || values.length === 1 && values[0].length === 0) {
            // We're on a blank table separator row
            startedNewTable = true;
          } else if (values.length < 3) {
            // We're on a section header row
            startedNewTable = true;
            tableName = values;
          } else if (startedNewTable) {
            // We're on the table header row
            var thisTable = { rows: [], name: tableName };
            thisTable.rows.push(values);
            parsedTables.push(thisTable);
            tableIndex = parsedTables.length - 1;
            startedNewTable = false;
            tableName = [];
          } else {
            // We're on a regular data row
            parsedTables[tableIndex].rows.push(values);
          }
        });

        return parsedTables;
      } catch (err) {
        return [];
      }
    },
    _removeDataFromTable: function _removeDataFromTable(table, columnsToOmit, ttrColumnsToOmit) {
      var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var isExcel = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var rowsToOmit = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];

      var removeEmptyColumns = !isExcel && (type === 'volume-calc' || type === 'true-tonnage');

      if (columnsToOmit.length <= 0 && ttrColumnsToOmit.length <= 0 && rowsToOmit.length <= 0 && !removeEmptyColumns) {
        return table;
      }

      var columnHeaders = table.rows[0];
      var rows = table.rows.slice([1]);
      var condensedTable = { rows: [], name: table.name };
      var omittedColumnIndices = [];

      var condensedHeader = columnHeaders.filter(function (name, columnIndex) {
        if (type === 'true-tonnage' && ttrColumnsToOmit.indexOf(name) !== -1) {
          omittedColumnIndices.push(columnIndex);
          return false;
        }
        if (type !== 'true-tonnage' && columnsToOmit.indexOf(name) !== -1) {
          omittedColumnIndices.push(columnIndex);
          return false;
        }
        if (type === 'volume-calc') {
          if (rowsToOmit.indexOf('Regions') !== -1 && ['Region Name', 'Baseplane Type', 'Baseplane Elev (ft)'].indexOf(name) !== -1) {
            omittedColumnIndices.push(columnIndex);
            return false;
          }
        }
        if (type === 'true-tonnage') {
          if (rowsToOmit.indexOf('Regions') !== -1 && ['Name'].indexOf(name) !== -1) {
            omittedColumnIndices.push(columnIndex);
            return false;
          }
        }

        if (removeEmptyColumns) {
          // check column values of each row and add to omittedColumn if all are "blank"
          var columnIsEmpty = rows.reduce(function (accumulator, row) {
            var value = row[columnIndex];
            var valueIsEmpty = !value || value === '' || value === 'N/A' || value === 'None Selected';

            return accumulator && valueIsEmpty ? true : null;
          }, true);
          if (columnIsEmpty) {
            omittedColumnIndices.push(columnIndex);
            return false;
          }
        }
        return true;
      });
      condensedTable.rows.push(condensedHeader);

      rows.forEach(function (row) {
        if (type === 'volume-calc') {
          if (rowsToOmit.indexOf('Regions') !== -1 && row[0] !== 'Total') {
            return;
          }
          if (rowsToOmit.indexOf('Material Totals') !== -1 && row[0] === 'Total') {
            return;
          }
        } else if (type === 'true-tonnage') {
          if (rowsToOmit.indexOf('Regions') !== -1 && row[0] !== row[1] + ' Total') {
            if (row[0] === 'Final Total') {
              row[1] = 'Final Total';
            } else {
              return;
            }
          }
          if (rowsToOmit.indexOf('Material Totals') !== -1 && row[0] === row[1] + ' Total') {
            return;
          }
        }
        var condensedRow = row.filter(function (value, columnIndex) {
          return !(omittedColumnIndices.indexOf(columnIndex) !== -1);
        });
        condensedTable.rows.push(condensedRow);
      });

      return condensedTable;
    },
    _addDataToTable: function _addDataToTable(table, data, dataName) {
      var header = table[0];
      var rows = table.slice(1);

      header.unshift(dataName);
      rows.forEach(function (row) {
        var key = row[row.length - 1];
        var newValue = data[key] ? data[key] : '';
        row.unshift(newValue.toString());
      });
    },
    _removeColumnFromTable: function _removeColumnFromTable(table, columnName) {
      var header = table[0];
      var rows = table.slice(1);
      var columnIndex = header.indexOf(columnName);

      header.splice(columnIndex, 1);
      rows.forEach(function (row) {
        row.splice(columnIndex, 1);
      });
    },
    _sortTableByColumn: function _sortTableByColumn(table, columnName) {
      var header = table[0];
      var rows = table.slice(1);
      var columnIndex = header.indexOf(columnName);

      rows.sort(function (a, b) {
        var x = a[columnIndex];
        var y = b[columnIndex];
        if (x === '' && y !== '') return 1;
        if (x !== '' && y === '') return -1;
        if (Number(x) > Number(y)) return 1;
        if (Number(x) < Number(y)) return -1;
        return 0;
      });

      return [header].concat(_toConsumableArray(rows));
    },
    _addCustomHeaderToTable: function _addCustomHeaderToTable(table, customHeader) {
      table.unshift([]);
      table.unshift([customHeader]);
    },
    _addCustomHeaderToAccountingTable: function _addCustomHeaderToAccountingTable(tableData, headers) {
      var lines = headers.split('\n');
      var output = [];

      lines.forEach(function (line) {
        var _line$split$map = line.split(':').map(function (part) {
          return part.trim();
        }),
            _line$split$map2 = _toArray(_line$split$map),
            key = _line$split$map2[0],
            valueParts = _line$split$map2.slice(1);

        var value = valueParts.join(':');

        if (key === 'Survey Time') {
          output.push([key, value]);
        } else {
          output.push([key, value]);
        }
      });

      output.reverse().forEach(function (item) {
        return tableData.unshift(item);
      });
    },
    _createAccountingWorkook: function _createAccountingWorkook(reportData, siteName, flightTimeStamp) {
      var workBook = _npmXlsx.default.utils.book_new();
      var workSheet = _npmXlsx.default.utils.aoa_to_sheet(reportData);

      var range = _npmXlsx.default.utils.decode_range(workSheet['!ref']);

      // Adjust column widths
      var colWidths = [];
      for (var C = range.s.c; C <= range.e.c; C += 1) {
        var maxWidth = 0;
        for (var R = range.s.r; R <= range.e.r; R += 1) {
          var cellAddress = { r: R, c: C };
          var cellRef = _npmXlsx.default.utils.encode_cell(cellAddress);
          if (workSheet[cellRef]) {
            var cellValue = workSheet[cellRef].v ? String(workSheet[cellRef].v) : '';
            var cellLength = cellValue.length;
            maxWidth = Math.max(maxWidth, cellLength);
          }
        }
        colWidths[C] = { width: maxWidth };
      }
      workSheet['!cols'] = colWidths;

      _npmXlsx.default.utils.book_append_sheet(workBook, workSheet, 'Pile Identification');
      _npmXlsx.default.writeFile(workBook, siteName + ' (Inventory Reporting - ' + flightTimeStamp.date + ').xlsx');
    },
    _addAccountingIndexingHeaders: function _addAccountingIndexingHeaders(reportData, simplifiedRegionNames) {
      // traverse json data and add the simplified region names to the data
      for (var i = 0; i < reportData.jsonData.length; i += 1) {
        var lastIndex = reportData.jsonData[i].length - 1;
        var currentRegion = reportData.jsonData[i][lastIndex];
        var pileNumber = Object.prototype.hasOwnProperty.call(simplifiedRegionNames, currentRegion) ? simplifiedRegionNames[currentRegion] : null;
        reportData.jsonData[i][4] = pileNumber;
        reportData.jsonData[i].pop();
      }

      // sort the json data by the simplified region names
      reportData.jsonData.sort(function (pileA, pileB) {
        return pileA[4] - pileB[4];
      });

      // add correct number formatting due to discrepencies in removed excluded data from the TTR
      for (var _i = 0; _i < reportData.jsonData.length; _i += 1) {
        reportData.jsonData[_i][4] = _i + 1;
      }

      return reportData.jsonData;
    },
    _addAccountingTableDataAndClean: function _addAccountingTableDataAndClean(tableData, columnsToOmit, siteName, dateAndTime, coLocLob, customHeader, columnHeaders) {
      var reportJsonData = tableData;

      // set the starting index depending on if a custom header was added or not
      var startingIndex = customHeader ? 4 : 0;

      // add column headers to the row data
      if (customHeader) {
        reportJsonData.splice(startingIndex, 0, columnHeaders);
      } else {
        reportJsonData.unshift(columnHeaders);
      }

      // add the remaining row data to the json data
      for (var i = startingIndex + 1; i < reportJsonData.length; i += 1) {
        reportJsonData[i][0] = coLocLob || null;
        reportJsonData[i][1] = siteName || null;
        reportJsonData[i][2] = dateAndTime.date || null;
        reportJsonData[i][3] = dateAndTime.time || null;
      }

      // find columns that need to be removed
      var indicesToRemove = columnsToOmit.map(function (item) {
        return columnHeaders.indexOf(item);
      });

      // remove the columns and the json data that the user selected to remove
      for (var _i2 = startingIndex; _i2 < reportJsonData.length; _i2 += 1) {
        var row = reportJsonData[_i2];
        for (var j = indicesToRemove.length - 1; j >= 0; j -= 1) {
          var index = indicesToRemove[j];
          row.splice(index, 1);
        }
      }

      return reportJsonData;
    },
    saveFile: function saveFile(_ref) {
      var body = _ref.body,
          filename = _ref.filename,
          _ref$type = _ref.type,
          type = _ref$type === undefined ? 'octet/stream' : _ref$type;

      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';

      var blob = new Blob([body], { type: type });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  });
});