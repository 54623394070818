define('webui/pods/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'webui/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    coalesceFindRequests: true,
    host: _environment.default.APP.host,
    namespace: 'v1',
    authorizer: 'authorizer:application',

    init: function init() {
      this._super();
      var context = this;

      $.ajaxSetup({
        xhrFields: {
          withCredentials: true
        }
      });

      $(document).ajaxError(function (event, jqxhr) {
        var status = jqxhr.status;


        if (status === 401 && context.get('session.isAuthenticated')) {
          context.get('session').invalidate();
        }
      });
    },
    handleResponse: function handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this._super.apply(this, [status].concat(_toConsumableArray(args)));
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, params) {
      var queryString = '';
      if (params.adapterOptions && params.adapterOptions.data) {
        queryString = '?' + Object.keys(params.adapterOptions.data).map(function (prop) {
          return prop + '=' + params.adapterOptions.data[prop];
        }).join('&');
      }

      return this._super(id, modelName) + queryString;
    }
  });
});