define('webui/pods/account/index/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    feedback: Ember.inject.service('user-feedback'),

    model: function model() {
      return this.store.find('user', this.get('session.data.authenticated.id'));
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('userID', model.get('id'));
      controller.set('userFirstName', model.get('firstName'));
      controller.set('userLastName', model.get('lastName'));
      controller.set('userEmail', model.get('email'));

      var status = model.get('status');

      if (status === 'pending') {
        controller.set('userPending', true);
      } else {
        controller.set('userPending', false);
      }
    },
    refreshModel: function refreshModel() {
      this.refresh();
    },


    actions: {
      checkPassword: function checkPassword(model) {
        var feedback = this.get('feedback');
        var password = model.get('password');
        var passwordConfirm = model.get('passwordConfirm');
        var error = false;

        if (password && password === passwordConfirm) {
          if (!password.length || password.length > 7) {
            return this.send('submitModel', model);
          }
          error = 'Your password must be at least eight characters long.';
        } else if (password) {
          error = 'Your password and password confirmation must match.';
        }

        if (error) {
          return feedback.reportError({ title: 'Account update error', detail: error });
        }

        return this.send('submitModel', model);
      },
      submitModel: function submitModel(model) {
        var route = this;var previousStatus = model.get('status');
        var feedback = this.get('feedback');

        if (previousStatus === 'pending') {
          model.set('status', 'active');
        }
        // this makes sure that we save the new status on the session:
        this.set('session.data.authenticated.status', model.get('status'));
        model.save().then(function () {
          route.controller.set('model', model);
          route.controller.set('updateError', false);
          route.refreshModel();

          if (previousStatus === 'active') {
            feedback.reportSuccess('Your account was updated successfully.');
          } else {
            route.transitionTo('index');
          }
        }).catch(function (err) {
          Ember.Logger.error(err);
          route.controller.set('updateSuccess', false);
          feedback.handleResponse(err);
        });
      },
      error: function error(_error) {
        this.get('feedback').reportError({ title: 'Account update error', detail: _error });
      }
    }
  });
});