define('webui/pods/components/product-list/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/region-manager', 'webui/config/environment'], function (exports, _mixin, _regionManager, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, _regionManager.default, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    feedback: Ember.inject.service('user-feedback'),
    mapData: Ember.inject.service('mapData'),
    isShowingDescription: false,
    isShowingUploadForm: false,
    isUploading: false,
    isShowingCroppingForm: false,
    drawingMode: false,
    dataType: 'generic-region',
    listenerId: 'polygon_generic_region',
    'draw-data': [],

    init: function init() {
      this._super();
      var component = this;
      var mapData = this.get('mapData');

      mapData.resetDrawingMode();
      mapData.setListeningMode(this.listenerId);
      Ember.run.next(function () {
        component.setListeners(component.dataType);
      });
    },
    didInsertElement: function didInsertElement() {
      $('[data-toggle=tooltip]').tooltip({
        placement: 'bottom',
        title: 'click for more details'
      });
    },
    willDestroyElement: function willDestroyElement() {
      var mapData = this.get('mapData');
      this.send('exitDrawingMode');
      mapData.deleteListenersForQualifier(this.listenerId);
    },
    saveNewRegion: function saveNewRegion(polygon) {
      var id = polygon.features[0].id;

      var currentRegions = this.get('draw-data');

      currentRegions[id] = polygon.features[0].geometry.coordinates[0].map(function (coords) {
        return { lng: coords[0], lat: coords[1] };
      });
      document.getElementById('crop-products-button').disabled = false;
    },
    clearRegions: function clearRegions() {
      var regions = this.get('draw-data');
      Object.keys(regions).map(function (key) {
        return delete regions[key];
      });
    },


    actions: {
      toggleDescription: function toggleDescription() {
        this.toggleProperty('isShowingDescription');
      },
      downloadItem: function downloadItem(url, type) {
        this.trackEvent('Download Product', { type: type });
        window.open(url, '_blank');
      },
      copyItem: function copyItem(product) {
        $('#' + product.id).modal('show');
        this.trackEvent('Copy Product URL', { type: product.type });
      },
      deleteItem: function deleteItem(id) {
        var _this = this;

        var store = this.get('store');
        var product = store.peekRecord('product', id);
        product.destroyRecord().then(function () {
          return _this.sendAction('updateFlightModelProducts');
        });
      },
      toggleRemoveForm: function toggleRemoveForm(id) {
        this.get('store').peekRecord('product', id).toggleProperty('removeMode');
        this.sendAction('updateFlightModelProducts');
      },
      toggleUploadForm: function toggleUploadForm() {
        this.toggleProperty('isShowingUploadForm');

        if (!this.get('isShowingUploadForm')) {
          this.set('newFile', null);
        } else {
          Ember.run.next(function () {
            return document.getElementById('product-upload-button').scrollIntoView();
          });
        }
      },
      onFileChange: function onFileChange(event) {
        this.set('newFile', event.target.files[0]);
      },
      uploadFile: function uploadFile() {
        var component = this;
        var store = component.get('store');
        var feedback = this.get('feedback');
        var flightId = this.get('session.currentFlight');
        var file = this.get('newFile');
        var data = new FormData();
        data.append('flightId', flightId);
        data.append('productType', 'user-upload');
        data.append('file', file);

        component.set('isUploading', true);

        $.ajax({
          url: _environment.default.APP.apiUrl + 'products/upload',
          data: data,
          processData: false,
          contentType: false,
          type: 'POST',
          success: function success(res) {
            var uploadedProduct = store.push({ data: { type: 'product', id: res.product.id, attributes: res.product } });
            var flight = store.peekRecord('flight', flightId);

            uploadedProduct.set('flight', flight);

            component.sendAction('updateFlightModelProducts');
            component.set('isShowingUploadForm', false);
            component.set('newFile', null);
            component.set('isUploading', false);

            feedback.reportSuccess({ detail: file.name + ' was successfully uploaded.' });
          },
          error: function error() {
            feedback.reportError({ detail: 'An error occurred while uploading your file. Please try again.' });
            component.set('isUploading', false);
          }
        });
      },
      toggleCroppingForm: function toggleCroppingForm() {
        this.toggleProperty('isShowingCroppingForm');
        this.clearRegions();
        if (!this.get('isShowingCroppingForm')) {
          this.send('exitDrawingMode');
        } else {
          this.setDrawingModeForNewRegions(this.listenerId);
          this.set('drawingMode', 'include');
          this.set('defaultDisplayName', new Date(Date.now()).toLocaleString());
          Ember.run.next(function () {
            return document.getElementById('crop-products-button').scrollIntoView();
          });
        }
      },
      cropProducts: function cropProducts() {
        var component = this;
        var store = component.get('store');
        var feedback = this.get('feedback');
        var flightId = this.get('session.currentFlight');
        var regions = component.get('draw-data');
        var displayName = document.getElementById('crop-filename-input').value;
        var boundaries = [];

        Object.keys(regions).forEach(function (region) {
          if (Object.prototype.hasOwnProperty.call(regions, region)) {
            boundaries.push(regions[region]);
          }
        });

        var product = {
          displayName: displayName,
          flightId: flightId,
          boundaries: JSON.stringify(boundaries)
        };

        io.socket.post('/v1/products/crop', { product: product }, function (res) {
          if (res.product && res.product.id) {
            store.findRecord('product', res.product.id).then(function () {
              return component.sendAction('updateFlightModelProducts');
            });
            feedback.reportSuccess({ detail: displayName + ' is being cropped.' });
            component.send('toggleCroppingForm');
          } else if (res.errors && res.errors[0]) {
            feedback.reportError(res.errors[0]);
          } else {
            feedback.reportError({ detail: 'An error occurred while cropping your file. Please try again.' });
          }
        });
      },
      exitDrawingMode: function exitDrawingMode() {
        this.set('drawingMode', false);
        this.cleanUpRegionUI(this.dataType);
      }
    }
  });
});