define('webui/mixins/export-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),

    quantityReportColumns: ['Surface', 'Flight Date', 'Amount to Fill (yds3)', 'Amount Filled (yds3)', 'Percent Complete', 'Area (Sq Ft)'],

    trackingCutFillReportColumns: ['Tracking Region Name', 'Flight Date', 'Total Cut (Cubic Yds)', 'Difference in Cut from Previous Flight (Cubic Yds)', 'Total Fill (Cubic Yds)', 'Difference in Fill from Previous Flight (Cubic Yds)', 'Total Net (Cubic Yds)', 'Difference in Net from Previous Flight (Cubic Yds)', 'Area (Sq Ft)'],

    trueTonnageReportColumns: ['Name', 'Material', 'Code', 'Revision', 'Change Notes', 'Volume', 'Density', 'True Tonnage', 'On Hand Inventory', 'Tonnage Difference', 'Variance', 'Change Requested By'],

    volumeCalcReportColumns: ['Region Name', 'Material', 'Code', 'Cut (yds3)', 'Fill (yds3)', 'Net (yds3)', 'Density (US Tons per yd3)', 'Tonnage (US)', 'Tonnage (Metric)', 'On Hand Inventory (US)', 'Tonnage Difference', '% Difference', 'Area (ft2)', 'Dollar Value', 'Baseplane Type', 'Baseplane Elev (ft)'],

    volumeCalcReportRows: ['Regions', 'Material Totals'],

    trueTonnageAccountingReportColumns: ['CO.LOC.LOB', 'Location', 'Survey Date', 'Survey Time', 'Pile', 'Product Code', 'Product Description', 'Survey Volume (yds3)', 'Comments'],

    getQuantityReportColumns: function getQuantityReportColumns() {
      var unselectedColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return this.get('quantityReportColumns').map(function (name) {
        return { name: name, checked: !(unselectedColumns.indexOf(name) !== -1) };
      });
    },
    getTrackingCutFillReportColumns: function getTrackingCutFillReportColumns() {
      var unselectedColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return this.get('trackingCutFillReportColumns').map(function (name) {
        return { name: name, checked: !(unselectedColumns.indexOf(name) !== -1) };
      });
    },
    getTrueTonnageReportColumns: function getTrueTonnageReportColumns() {
      var unselectedColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return this.get('trueTonnageReportColumns').map(function (name) {
        return { name: name, checked: !(unselectedColumns.indexOf(name) !== -1) };
      });
    },
    getVolumeCalcReportColumns: function getVolumeCalcReportColumns() {
      var unselectedColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return this.get('volumeCalcReportColumns').map(function (name) {
        return { name: name, checked: !(unselectedColumns.indexOf(name) !== -1) };
      });
    },
    getTrueTonnageAccountingReportColumns: function getTrueTonnageAccountingReportColumns() {
      var unselectedColumns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return this.get('trueTonnageAccountingReportColumns').map(function (name) {
        return { name: name, checked: !(unselectedColumns.indexOf(name) !== -1) };
      });
    },
    getVolumeCalcReportRows: function getVolumeCalcReportRows() {
      return this.get('volumeCalcReportRows');
    },
    formatAccountingTimeStamp: function formatAccountingTimeStamp(dateObj) {
      var accountingDate = new Date(dateObj);

      var month = (accountingDate.getMonth() + 1).toString().padStart(2, '0');
      var day = accountingDate.getDate().toString().padStart(2, '0');
      var year = accountingDate.getFullYear();
      var date = month + '/' + day + '/' + year;

      accountingDate.setHours(accountingDate.getUTCHours() - 4);
      var hours = accountingDate.getHours();
      var minutes = accountingDate.getMinutes().toString().padStart(2, '0');
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      var time = hours + ':' + minutes + ' ' + ampm;

      var formattedAccountingTime = { date: date, time: time };

      return formattedAccountingTime;
    },
    getFlightOptions: function getFlightOptions() {
      var _this = this;

      var omittedFlights = this.get('pdfOptions.flightsToOmit');
      var flights = this.get('store').peekAll('flight').filter(function (flight) {
        return flight.get('site.id') === _this.get('session.currentSite') && flight.get('status') === 'succeeded';
      });
      var orderedFlights = flights.sortBy('executeAt').reverse();

      return orderedFlights.map(function (flight) {
        return {
          id: flight.get('id'),
          displayFlightTime: flight.get('displayFlightTime'),
          subtitle: flight.get('subtitle'),
          selected: !(omittedFlights.indexOf(flight.get('id')) !== -1)
        };
      });
    },
    getBlueprintOptions: function getBlueprintOptions() {
      var _this2 = this;

      var blueprints = this.get('store').peekAll('blueprint').filter(function (blueprint) {
        return blueprint.get('site.id') === _this2.get('session.currentSite') && blueprint.get('type') === 'site-grade' && blueprint.get('status') === 'succeeded';
      });
      var orderHasBeenSet = !!blueprints.get('firstObject.order');
      var orderedBlueprints = blueprints.sortBy(orderHasBeenSet ? 'order' : 'createdAt');

      return orderedBlueprints.map(function (blueprint) {
        return blueprint.get('name');
      });
    },
    getDefaultHeader: function getDefaultHeader(type) {
      var reportHeaders = void 0;

      if (type === 'ttrHeaders') {
        var flight = this.get('store').peekRecord('flight', this.get('session.currentFlight'));
        var flightDate = flight.get('displayDate');
        var clientName = flight.get('site.client.name');
        var siteName = flight.get('site.name');
        var today = new Date();
        var exportDate = today.toLocaleDateString();
        var exportTime = today.toTimeString();
        reportHeaders = 'Report for ' + flightDate + ' ' + clientName + ' - ' + siteName + '\nExported on ' + exportDate + ' at ' + exportTime;
      }
      if (type === 'accountingHeaders') {
        var _flight = this.get('store').peekRecord('flight', this.get('session.currentFlight'));
        var formattedDateAndTime = this.formatAccountingTimeStamp(_flight.get('executeAt'));
        var _siteName = _flight.get('site.name');
        var siteDescription = this.get('pdfOptions.coLocLob');

        var splitValue = function splitValue(value) {
          var regex = new RegExp(/\.([^.]+)\./);
          var output = regex.exec(value);
          return output ? output[1] : value;
        };

        var plantNumber = splitValue(siteDescription);

        reportHeaders = 'Plant Number: ' + plantNumber + '\nPlant Name: ' + _siteName + ' \nSurvey Date: ' + formattedDateAndTime.date + ' \nSurvey Time: ' + formattedDateAndTime.time;
      }
      return reportHeaders;
    }
  });
});