define('webui/pods/components/true-tonnage-report/planned-tonnage-table/component', ['exports', 'npm:xlsx', 'webui/mixins/true-tonnage-report'], function (exports, _npmXlsx, _trueTonnageReport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_trueTonnageReport.default, {
    feedback: Ember.inject.service('user-feedback'),
    apiAction: 'updatePlannedTonnages',
    importedMaterials: Ember.A(),
    importedColumnNames: Ember.A(),
    importedColumnMap: {},

    getMaterialData: function getMaterialData() {
      var _this = this;

      return this.getAllMaterialIDs().map(function (materialId) {
        var trueTonnageReportMaterial = _this.getTrueTonnageReportMaterial(materialId);
        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial.get('id'),
          materialId: trueTonnageReportMaterial.get('material'),
          name: trueTonnageReportMaterial.get('name'),
          key: trueTonnageReportMaterial.get('key'),
          density: trueTonnageReportMaterial.get('density'),
          volume: _this.formatNumberForDisplay(_this.getVolumeTotalForMaterial(materialId)),
          tonnage: _this.formatNumberForDisplay(_this.getTonnageTotalForMaterial(materialId)),
          tonnageNumber: _this.getTonnageTotalForMaterial(materialId),
          plannedTonnage: trueTonnageReportMaterial.get('plannedTonnage'),
          tonnageVarianceDisplay: trueTonnageReportMaterial.get('density'),
          changeRequested: trueTonnageReportMaterial.get('changeRequested'),
          changeRequestTonnage: trueTonnageReportMaterial.get('changeRequestTonnage'),
          changeRequestReason: trueTonnageReportMaterial.get('changeRequestReason'),
          regionData: _this.getRegionDataForMaterial(materialId),
          hideMaterial: trueTonnageReportMaterial.get('hideFromTrueTonnageReport')
        };
      }).filter(function (material) {
        return !material.hideMaterial;
      });
    },
    getRegionDataForMaterial: function getRegionDataForMaterial(materialId) {
      var _this2 = this;

      var materialForName = this.get('store').peekRecord('material', materialId);

      return this.getUniqueRegionIDsForMaterial(materialId).map(function (regionId) {
        var revisions = _this2.getRevisionDataForRegion(regionId);

        var _revisions = _slicedToArray(revisions, 1),
            latestRevision = _revisions[0];

        return {
          trueTonnageReportRegionId: latestRevision.get('id'),
          regionId: latestRevision.get('region'),
          name: latestRevision.get('name'),
          nameWithMaterialCode: materialForName.get('key') ? latestRevision.get('name').replace(materialForName.get('name'), materialForName.get('key')) : latestRevision.get('name'),
          netVolume: latestRevision.get('netVolume'),
          density: latestRevision.get('displayDensity'),
          displayTonnage: latestRevision.get('displayTonnage'),
          plannedTonnage: latestRevision.get('plannedTonnage'),
          hideRegion: latestRevision.get('hideFromTrueTonnageReport')
        };
      }).filter(function (region) {
        return !region.hideRegion;
      });
    },
    getRegionsForMaterialTotals: function getRegionsForMaterialTotals(materialId) {
      var _this3 = this;

      return this.getUniqueRegionIDsForMaterial(materialId).map(function (regionId) {
        return _this3.getRevisionDataForRegion(regionId)[0];
      }).filter(function (trueTonnageReportRegion) {
        return !trueTonnageReportRegion.get('hideFromTrueTonnageReport');
      });
    },
    getMaterialChanges: function getMaterialChanges() {
      return this.getMaterialsFromForm().map(function (trueTonnageReportMaterial) {
        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial.trueTonnageReportMaterialId,
          plannedTonnage: trueTonnageReportMaterial.plannedTonnage !== null ? trueTonnageReportMaterial.plannedTonnage : 0
        };
      });
    },
    getRegionChanges: function getRegionChanges() {
      return [];
    },
    clearImport: function clearImport() {
      this.set('importedColumnNames', Ember.A());
      this.set('displayFieldSelectModal', false);
      document.getElementById('on-hand-inventory-input-file').value = null;
    },
    importPlannedTonnage: function importPlannedTonnage(selectedFile) {
      var _this4 = this;

      var importedColumnNames = [];
      var importedColumnMap = {};

      if (selectedFile) {
        var fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = function (fileLoadEvent) {
          var data = fileLoadEvent.target.result;
          var workbook = _npmXlsx.default.read(data, { type: 'binary' });
          var firstSheet = _npmXlsx.default.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
          var firstRow = firstSheet[0];

          var foundHeaders = true;
          firstSheet.forEach(function (row) {
            return Object.keys(row).forEach(function (key) {
              if (key.indexOf('__EMPTY') !== -1) foundHeaders = false;
            });
          });

          if (!foundHeaders) return _this4.get('feedback').reportError({ title: 'Import Error', detail: 'File could not be imported. Please ensure that the first row contains column names and that each column has a value.' });

          var headerText = Object.keys(firstRow);
          headerText.forEach(function (header) {
            var columnName = header.replace(/[^\w\s]/gi, '');
            var isValidColumn = firstSheet.filter(function (materialRow) {
              return materialRow[columnName];
            }).length > 0;

            if (isValidColumn) {
              importedColumnNames.push(columnName);
              importedColumnMap[columnName] = header;
            }
          });

          _this4.set('importedMaterials', firstSheet);
          _this4.set('importedColumnNames', importedColumnNames.sort());
          _this4.set('importedColumnMap', importedColumnMap);
          return _this4.set('displayFieldSelectModal', true);
        };
      }
    },


    actions: {
      importPlannedTonnage: function importPlannedTonnage(event) {
        this.importPlannedTonnage(event.target.files[0]);
      },
      cancelImport: function cancelImport() {
        this.clearImport();
      },
      copyImportDataIntoPlannedTonnageField: function copyImportDataIntoPlannedTonnageField(processedImportData) {
        var _this5 = this;

        this.get('reportData').forEach(function (material, index) {
          var materialId = material.materialId,
              name = material.name,
              regionData = material.regionData;

          if (regionData && regionData.length > 0) {
            var matchingRows = processedImportData.filter(function (m) {
              return m.materialId === materialId;
            });
            Ember.set(_this5.get('reportData.' + index), 'plannedTonnage', matchingRows[0].plannedTonnage);
          }

          if (name === 'Total') {
            var plannedTonnage = _this5.get('reportData').reduce(function (total, currentMaterial) {
              return currentMaterial.plannedTonnage && currentMaterial.regionData.length > 0 ? total + currentMaterial.plannedTonnage : total;
            }, 0);
            Ember.set(material, 'plannedTonnage', _this5.formatNumberForDisplay(plannedTonnage));
          }
        });

        this.clearImport();
      }
    }
  });
});