define('webui/mixins/default-flight', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getDefaultFlightForSite: function getDefaultFlightForSite(allFlightsForTransitioningSite) {
      var nonDisabledSucceddedFlightsOrderedByFlightData = allFlightsForTransitioningSite.filterBy('disabled', false).filterBy('status', 'succeeded').sortBy('executeAt').reverse();
      var nonDisabledSucceddedFlightsOrderedByFlightDataWithTiledMaps = nonDisabledSucceddedFlightsOrderedByFlightData.filter(function (flight) {
        return flight.get('tiledMaps.firstObject');
      });

      if (nonDisabledSucceddedFlightsOrderedByFlightDataWithTiledMaps[0]) {
        return nonDisabledSucceddedFlightsOrderedByFlightDataWithTiledMaps[0];
      }

      if (nonDisabledSucceddedFlightsOrderedByFlightData[0]) {
        return nonDisabledSucceddedFlightsOrderedByFlightData[0];
      }

      return null;
    }
  });
});