define('webui/pods/components/true-tonnage-report/material-trends-chart/component', ['exports', 'npm:chartist', 'webui/mixins/material-trends-chart-data', 'webui/mixins/string-formatting', 'npm:chartist-plugin-tooltips', 'npm:chartist-plugin-legend'], function (exports, _npmChartist, _materialTrendsChartData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_materialTrendsChartData.default, _stringFormatting.default, {
    store: Ember.inject.service('store'),
    pdfOptions: Ember.inject.service('pdf-options'),
    allFlights: [],
    selectedFlights: [],
    materials: [],
    regionsWereLoaded: false,

    init: function init() {
      this._super();
      this.setupComponent();
    },
    didInsertElement: function didInsertElement() {
      this.setupSelectPickers();
      this.renderChart();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.renderChart();
    },
    setupComponent: function setupComponent() {
      var _this = this;

      var store = this.get('store');
      var flightsAtSite = store.peekAll('flight').filter(function (flight) {
        return flight.get('site.id') === _this.get('siteId');
      }).sortBy('executeAt');
      var materials = this.get('store').peekAll('material').filter(function (material) {
        return material.get('site.id') === _this.get('siteId');
      }).sortBy('name');
      this.set('allFlights', flightsAtSite);
      this.set('selectedFlights', flightsAtSite);
      this.get('pdfOptions').set('selectedFlightsForMaterialTrends', flightsAtSite);
      this.get('pdfOptions').set('selectedMaterialsForMaterialTrends', materials.map(function (material) {
        return material.get('id');
      }));
      this.set('materials', materials);
    },
    renderChart: function renderChart() {
      var _this2 = this;

      function SuppressForeignObjectPlugin(chart) {
        chart.supportsForeignObject = false;
      }

      var data = this.getMaterialTrendsChartData(this.get('selectedFlights'), this.get('materials'), this.get('displayMaterialAsCode'));
      var options = {
        axisX: {
          labelOffset: {
            x: 0,
            y: 5
          }
        },
        axisY: {
          labelInterpolationFnc: function labelInterpolationFnc(text) {
            return text + ' tons';
          },
          offset: 75
        },
        plugins: [SuppressForeignObjectPlugin, _npmChartist.default.plugins.legend({
          position: document.getElementById('legend'),
          onClick: function onClick(legend) {
            setTimeout(function () {
              var selectedMaterialsForMaterialTrends = legend.data.series.map(function (_ref) {
                var materialId = _ref.materialId;
                return materialId;
              });
              _this2.get('pdfOptions').set('selectedMaterialsForMaterialTrends', selectedMaterialsForMaterialTrends);
            }, 500);
          }
        }), _npmChartist.default.plugins.tooltip({
          appendToBody: true,
          class: 'id-tooltip',
          transformTooltipTextFnc: function transformTooltipTextFnc(text) {
            return parseFloat(text).toFixed(2) + ' tons';
          }
        })]
      };

      this.clearChart();
      _npmChartist.default.Line('#material-trends-chart', data, options);
    },
    clearChart: function clearChart() {
      $('#material-trends-chart').empty();
      $('#legend').empty();
    },
    setupSelectPickers: function setupSelectPickers() {
      var context = this;
      $('#flight-selector').selectpicker();
      $('#flight-selector').on('changed.bs.select', function () {
        return context.send('saveFlightSelection');
      });
    },
    willDestroyElement: function willDestroyElement() {
      $('#flight-selector').selectpicker('destroy');
    },


    actions: {
      hideGraph: function hideGraph() {
        this.set('showMaterialTrendsGraph', false);
        this.set('pdfOptions.materialTrendsInExport', false);
      },
      saveFlightSelection: function saveFlightSelection() {
        var selectedFlightIds = $('#flight-selector').val() ? $('#flight-selector').val() : [];
        var selectedFlights = this.get('allFlights').filter(function (flight) {
          return selectedFlightIds.indexOf(flight.get('id')) !== -1;
        });
        this.set('selectedFlights', selectedFlights);
        this.get('pdfOptions').set('selectedFlightsForMaterialTrends', selectedFlights);

        this.renderChart();
      }
    }
  });
});