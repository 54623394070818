define('webui/models/product', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fileUrl: _emberData.default.attr('string'),
    historicalFileUrl: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    urlPrefix: _emberData.default.attr('string'),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    productType: _emberData.default.attr('string'),
    displayName: _emberData.default.attr('string'),
    isCustom: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    fileExtension: Ember.computed('fileUrl', function fileExtension() {
      var fileName = this.get('fileUrl');
      return fileName.substr(-3);
    }),
    encodedUrl: Ember.computed('fileUrl', function encodedUrl() {
      var fileID = encodeURIComponent(decodeURIComponent(this.get('fileUrl')));
      return fileID.replace(/%2F/gi, '/');
    }),
    parsedDisplayName: Ember.computed('fileUrl', function parsedDisplayName() {
      var parsedName = void 0;
      // search from beginning of string to last '/' and remove them
      parsedName = this.get('fileUrl').replace(/%2F/g, '/');
      parsedName = parsedName.replace(/^.*[\\/]/, '');
      return parsedName;
    }),
    rasterIsUserReplaced: Ember.computed('fileUrl', function rasterIsUserReplaced() {
      return this.get('fileUrl').substr(-21) === 'user_replaced_dsm.tif';
    }),

    rasterIsClassified: Ember.computed('fileUrl', function rasterIsClassified() {
      return this.get('fileUrl').substr(-18) === 'classified_dsm.tif';
    })
  });
});