define('webui/pods/components/jobs-list-display/component', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    siteDataAccess: Ember.inject.service('data-access/site'),
    jobs: [],
    jobStatuses: {
      pending: { selected: false, disabled: false },
      'running-part-one': { selected: false, disabled: true },
      'failed-part-one': { selected: false, disabled: false },
      'needs-human': { selected: false, disabled: false },
      'human-verified': { selected: false, disabled: false },
      'running-part-two': { selected: false, disabled: true },
      'failed-part-two': { selected: false, disabled: false },
      'needs-class': { selected: false, disabled: false },
      'human-class': { selected: false, disabled: false },
      'running-part-three': { selected: false, disabled: true },
      'failed-part-three': { selected: false, disabled: false },
      'upload-pending': { selected: false, disabled: false },
      'running-upload': { selected: false, disabled: true },
      'failed-upload': { selected: false, disabled: false },
      succeeded: { selected: false, disabled: false }
    },

    formatAndSort: function formatAndSort(jobs) {
      jobs.forEach(function (job) {
        var flight = job.get('flight');
        var date = new Date(flight.get('executeAt'));

        job.set('siteName', flight.get('site') ? flight.get('site.name') : '');
        job.set('dateString', date.toDateString());
        job.set('date', date);
      });

      var completed = jobs.filter(function (job) {
        return job.get('status') === 'succeeded';
      }).sortBy('updatedAt').reverse();

      var inProgress = jobs.filter(function (job) {
        return job.get('status') !== 'succeeded';
      }).sortBy('createdAt');

      completed.forEach(function (item) {
        inProgress.pushObject(item);
      });

      return inProgress;
    },
    generateModel: function generateModel() {
      var component = this;var store = this.get('store');

      store.query('job', { type: 'statusPage' }).then(function (jobs) {
        component.set('jobs', component.formatAndSort(jobs));
        component.rerender();
        setTimeout(function () {
          if (window.statusDisplay) {
            component.generateModel();
          }
        }, 60000);
      });
    },
    didInsertElement: function didInsertElement() {
      window.statusDisplay = true;
      this._super.apply(this, arguments);

      this.generateModel();
    },
    willDestroyElement: function willDestroyElement() {
      window.statusDisplay = false;
      this._super.apply(this, arguments);
      $(window).off();
    },


    actions: {
      updateJobStatus: function updateJobStatus(updatedJob, newStatus) {
        var _this = this;

        var url = _environment.default.APP.apiUrl + 'jobs/' + updatedJob.id;
        updatedJob.set('status', newStatus);
        // instead of saving stale data - just post new status
        // updatedJob.save();
        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          crossDomain: true,
          data: JSON.stringify({ job: { status: newStatus } }),
          xhrFields: { withCredentials: true }
        }).done(function () {}).fail(function (res) {
          console.error('Could not update job status', res);
        });

        // this.generateModel(); add local sort function
        this.set('jobs', this.formatAndSort(this.get('jobs').map(function (job) {
          if (job.id === updatedJob.id) {
            job = _this.get('store').peekRecord('job', updatedJob.id);
          }
          return job;
        })));
      },
      searchJobs: function searchJobs(query) {
        var results = [];
        var component = this;var store = this.get('store');
        store.query('job', { id: query }).then(function (jobs) {
          jobs.forEach(function (job) {
            results.pushObject(job);
          });
          store.query('flight', { site: query }).then(function (flights) {
            flights.forEach(function (flight) {
              flight.get('jobs').forEach(function (job) {
                results.pushObject(job);
              });
            });
            component.set('jobs', component.formatAndSort(results));
            component.rerender();
          });
        });
      },
      deleteJob: function deleteJob(deletedJob) {
        deletedJob.destroyRecord();
        this.set('jobs', this.get('jobs').filter(function (job) {
          return job.id !== deletedJob.id;
        }));
      },
      deleteJobAndFlight: function deleteJobAndFlight(deletedJob) {
        var component = this;
        deletedJob.get('flight').then(function (flight) {
          flight.destroyRecord();
          deletedJob.destroyRecord();
          component.set('jobs', component.get('jobs').filter(function (job) {
            return job.id !== deletedJob.id;
          }));
        });
      }
    }

  });
});