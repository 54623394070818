define('webui/mixins/error-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    resetErrorMessage: function resetErrorMessage() {
      this.set('errorMessage', null);
    },
    setError: function setError(error) {
      // TODO: figure out when ember returns objects with error arrays vs flat strings
      // and add logic to handle either case
      /* var message = '';
      error.errors.forEach(function (e) {
        message += e.status+': '+e.title+'\n';
      });
      this.set('errorMessage', message); */
      this.set('errorMessage', error);
    },
    setMessage: function setMessage(message) {
      this.set('successMessage', message);
    }
  });
});