define('webui/pods/admin/user/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    updateUsers: function updateUsers(data) {
      var controller = this.get('controller');
      if (data) {
        controller.set('model', data.sortBy('fullName'));
      }
    },


    actions: {
      updateUsers: function updateUsers(data) {
        this.updateUsers(data);
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);
      controller.set('columns', [{
        propertyName: 'fullName',
        title: 'Name',
        routeName: 'admin.user.edit'
      }, {
        propertyName: 'email'
      }, {
        propertyName: 'status',
        filterWithSelect: true
      }, {
        component: 'clients-for-table',
        title: 'Client'
      }]);
      this.updateUsers(this.get('store').peekAll('user'));
    }
  });
});