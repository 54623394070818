define("webui/pods/components/true-tonnage-report/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 5
            },
            "end": {
              "line": 11,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "true-tonnage-report/material-trends-chart", [], ["siteId", ["subexpr", "@mut", [["get", "siteId", ["loc", [null, [7, 17], [7, 23]]]]], [], []], "showMaterialTrendsGraph", ["subexpr", "@mut", [["get", "showMaterialTrendsGraph", ["loc", [null, [8, 34], [8, 57]]]]], [], []], "displayMaterialAsCode", ["subexpr", "@mut", [["get", "displayMaterialAsCode", ["loc", [null, [9, 32], [9, 53]]]]], [], []]], ["loc", [null, [6, 9], [10, 10]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 14
              },
              "end": {
                "line": 18,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "glyphicon glyphicon-chevron-up");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("Material Trends");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "material-trends-graph-tab");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "graph-tab");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "style", "text-align:center");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'onClick');
          morphs[1] = dom.createMorphAt(element5, 1, 1);
          return morphs;
        },
        statements: [["attribute", "onClick", ["subexpr", "action", ["showMaterialTrendsGraph"], [], ["loc", [null, [14, 51], [14, 87]]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Show Material Trends</p>", "tooltipPlace", "top"], 0, null, ["loc", [null, [15, 14], [18, 27]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 18
                  },
                  "end": {
                    "line": 66,
                    "column": 18
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "step-divider");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 14
                },
                "end": {
                  "line": 68,
                  "column": 14
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "step-name");
              var el3 = dom.createElement("strong");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createAttrMorph(element2, 'class');
              morphs[2] = dom.createMorphAt(element2, 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [3, 0]), 0, 0);
              morphs[4] = dom.createMorphAt(element1, 5, 5);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["step ", ["subexpr", "if", [["subexpr", "eq", [["get", "reportStep.order", ["loc", [null, [61, 42], [61, 58]]]], 7], [], ["loc", [null, [61, 38], [61, 61]]]], "step-last", ""], [], ["loc", [null, [61, 33], [61, 78]]]]]]], ["attribute", "class", ["concat", ["step-count ", ["subexpr", "if", [["subexpr", "gte", [["get", "step", ["loc", [null, [62, 51], [62, 55]]]], ["get", "reportStep.order", ["loc", [null, [62, 56], [62, 72]]]]], [], ["loc", [null, [62, 46], [62, 73]]]], "active", ""], [], ["loc", [null, [62, 41], [62, 87]]]]]]], ["content", "reportStep.displayOrder", ["loc", [null, [62, 89], [62, 116]]]], ["content", "reportStep.displayName", ["loc", [null, [63, 49], [63, 75]]]], ["block", "unless", [["subexpr", "eq", [["get", "reportStep.code", ["loc", [null, [64, 32], [64, 47]]]], "complete"], [], ["loc", [null, [64, 28], [64, 59]]]]], [], 0, null, ["loc", [null, [64, 18], [66, 29]]]]],
            locals: ["reportStep", "index"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 12
              },
              "end": {
                "line": 69,
                "column": 12
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "steps", ["loc", [null, [60, 22], [60, 27]]]]], [], 0, null, ["loc", [null, [60, 14], [68, 23]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 12
              },
              "end": {
                "line": 71,
                "column": 12
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("strong");
            var el2 = dom.createTextNode("Create True Tonnage Report");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 6
              },
              "end": {
                "line": 86,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "display:flex;justify-content:flex-end;align-items:baseline;");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "for", "tonnageVarianceWarningThreshold");
            var el3 = dom.createTextNode("True Tonnage Variance Warning Threshold:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "style", "position:relative;");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "style", "top:10px;right:5px;color:#c4c4c4;position:absolute;z-index: 1;");
            var el4 = dom.createTextNode("%");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("input");
            dom.setAttribute(el3, "style", "margin-left:10px; width:70px;");
            dom.setAttribute(el3, "type", "text");
            dom.setAttribute(el3, "name", "tonnageVarianceWarningThreshold");
            dom.setAttribute(el3, "class", "form-control");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 3, 3]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'onkeyup');
            return morphs;
          },
          statements: [["attribute", "value", ["get", "tonnageVarianceWarningThreshold", ["loc", [null, [83, 137], [83, 168]]]]], ["attribute", "onkeyup", ["subexpr", "action", ["setTonnageVarianceWarningThreshold"], ["value", "target.value"], ["loc", [null, [83, 179], [83, 247]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 6
              },
              "end": {
                "line": 102,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["get", "tableName", ["loc", [null, [89, 10], [89, 19]]]]], ["trueTonnageReport", ["subexpr", "@mut", [["get", "trueTonnageReport", ["loc", [null, [90, 28], [90, 45]]]]], [], []], "regions", ["subexpr", "@mut", [["get", "regions", ["loc", [null, [91, 18], [91, 25]]]]], [], []], "earthworks", ["subexpr", "@mut", [["get", "earthworks", ["loc", [null, [92, 21], [92, 31]]]]], [], []], "flightId", ["subexpr", "@mut", [["get", "flightId", ["loc", [null, [93, 19], [93, 27]]]]], [], []], "siteId", ["subexpr", "@mut", [["get", "siteId", ["loc", [null, [94, 17], [94, 23]]]]], [], []], "activeSiteUsers", ["subexpr", "@mut", [["get", "activeSiteUsers", ["loc", [null, [95, 26], [95, 41]]]]], [], []], "tonnageVarianceWarningThreshold", ["subexpr", "@mut", [["get", "tonnageVarianceWarningThreshold", ["loc", [null, [96, 42], [96, 73]]]]], [], []], "displayMaterialAsCode", ["subexpr", "@mut", [["get", "displayMaterialAsCode", ["loc", [null, [97, 32], [97, 53]]]]], [], []], "refreshReport", ["subexpr", "action", ["refreshReport"], [], ["loc", [null, [98, 24], [98, 48]]]], "excludeMaterial", ["subexpr", "action", ["excludeMaterial"], [], ["loc", [null, [99, 26], [99, 52]]]], "excludeRegion", ["subexpr", "action", ["excludeRegion"], [], ["loc", [null, [100, 24], [100, 48]]]]], ["loc", [null, [88, 8], [101, 10]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 2
            },
            "end": {
              "line": 105,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "true-tonnage-report");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "panel-top");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          dom.setAttribute(el3, "style", "margin-bottom: 10px;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-xs-12 text-left");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "stepper stepper-horizontal");
          dom.setAttribute(el5, "style", "width:100%");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 1, 1, 1]), 1, 1);
          morphs[1] = dom.createAttrMorph(element4, 'class');
          morphs[2] = dom.createMorphAt(element4, 1, 1);
          morphs[3] = dom.createMorphAt(element4, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "trueTonnageReport", ["loc", [null, [59, 18], [59, 35]]]]], [], 0, 1, ["loc", [null, [59, 12], [71, 19]]]], ["attribute", "class", ["concat", ["panel-body ", ["subexpr", "if", [["get", "shouldHideFooter", ["loc", [null, [77, 32], [77, 48]]]], "no-footer", ""], [], ["loc", [null, [77, 27], [77, 65]]]]]]], ["block", "if", [["get", "shouldShowSettings", ["loc", [null, [78, 12], [78, 30]]]]], [], 2, null, ["loc", [null, [78, 6], [86, 13]]]], ["block", "if", [["get", "tableName", ["loc", [null, [87, 12], [87, 21]]]]], [], 3, null, ["loc", [null, [87, 6], [102, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 107,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/true-tonnage-report/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "true-tonnage-panel");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12 text-left");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12 text-left");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12 text-left");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [4, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element6, [6, 1]), 1, 1);
        morphs[3] = dom.createMorphAt(element6, 8, 8);
        return morphs;
      },
      statements: [["block", "if", [["get", "showMaterialTrendsGraph", ["loc", [null, [5, 11], [5, 34]]]]], [], 0, 1, ["loc", [null, [5, 5], [22, 13]]]], ["inline", "true-tonnage-report/true-tonnage-tier2-analytics", [], ["siteId", ["subexpr", "@mut", [["get", "siteId", ["loc", [null, [30, 15], [30, 21]]]]], [], []], "shouldRefreshChart", ["subexpr", "@mut", [["get", "shouldRefreshChart", ["loc", [null, [31, 27], [31, 45]]]]], [], []], "excludedMaterials", ["subexpr", "@mut", [["get", "excludedMaterials", ["loc", [null, [32, 26], [32, 43]]]]], [], []], "excludedRegions", ["subexpr", "@mut", [["get", "excludedRegions", ["loc", [null, [33, 24], [33, 39]]]]], [], []], "displayMaterialAsCode", ["subexpr", "@mut", [["get", "displayMaterialAsCode", ["loc", [null, [34, 30], [34, 51]]]]], [], []], "hideGraph", ["subexpr", "action", ["hideGraph"], [], ["loc", [null, [35, 18], [35, 38]]]], "showTier2Graph", ["subexpr", "action", ["showTier2Graph"], [], ["loc", [null, [36, 23], [36, 48]]]]], ["loc", [null, [29, 6], [37, 8]]]], ["inline", "true-tonnage-report/true-tonnage-chart", [], ["siteId", ["subexpr", "@mut", [["get", "siteId", ["loc", [null, [44, 15], [44, 21]]]]], [], []], "shouldRefreshChart", ["subexpr", "@mut", [["get", "shouldRefreshChart", ["loc", [null, [45, 27], [45, 45]]]]], [], []], "excludedMaterials", ["subexpr", "@mut", [["get", "excludedMaterials", ["loc", [null, [46, 26], [46, 43]]]]], [], []], "excludedRegions", ["subexpr", "@mut", [["get", "excludedRegions", ["loc", [null, [47, 24], [47, 39]]]]], [], []], "displayMaterialAsCode", ["subexpr", "@mut", [["get", "displayMaterialAsCode", ["loc", [null, [48, 30], [48, 51]]]]], [], []]], ["loc", [null, [43, 6], [49, 8]]]], ["block", "if", [["get", "showMaterialReport", ["loc", [null, [53, 8], [53, 26]]]]], [], 2, null, ["loc", [null, [53, 2], [105, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});