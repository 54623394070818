define('webui/pods/components/field-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    doTransition: 'doTransition',

    setNavigationDefaults: function setNavigationDefaults() {
      var _this = this;

      this.set('selectedSiteId', this.get('site.id'));
      this.set('selectedFlightId', this.get('flight.id') ? this.get('flight.id') : this.get('sitesForNavigation')[0].flights[0]);

      if (this.get('selectedSiteId')) {
        this.set('flightsForNavigation', this.get('sitesForNavigation').filter(function (site) {
          return site.id === _this.get('selectedSiteId');
        })[0].flights);
      } else {
        this.set('flightsForNavigation', this.get('sitesForNavigation')[0].flights);
      }
    },


    actions: {
      toggleMenu: function toggleMenu() {
        this.setNavigationDefaults();
        this.toggleProperty('isMenuOpen');
      },
      changeSite: function changeSite(siteId) {
        var _this2 = this;

        this.set('selectedSiteId', siteId);
        this.set('flightsForNavigation', this.get('sitesForNavigation').filter(function (site) {
          return site.id === _this2.get('selectedSiteId');
        })[0].flights);

        var flightListDoesNotIncludeCurrentlyViewedFlight = this.get('flightsForNavigation').filter(function (flight) {
          return flight.id === _this2.get('flight.id');
        }).length === 0;
        if (flightListDoesNotIncludeCurrentlyViewedFlight) {
          this.set('selectedFlightId', this.get('flightsForNavigation')[0].id);
        }
      },
      changeFlight: function changeFlight(flightId) {
        this.set('selectedFlightId', flightId);
      },
      navigateToNewFlight: function navigateToNewFlight() {
        this.sendAction('doTransition', 'field', this.get('selectedFlightId'));
      }
    }
  });
});