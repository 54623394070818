define('webui/pods/components/cdt-menu/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/region-manager', 'npm:lodash', 'webui/config/environment'], function (exports, _mixin, _regionManager, _npmLodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, _regionManager.default, {
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    statusService: Ember.inject.service('status-panel'),
    mapData: Ember.inject.service('mapData'),
    settingsManager: Ember.inject.service('settings-manager'),
    closePanel: 'closePanel',
    setCurrentChangeDetection: 'setCurrentChangeDetection',
    changeDetectionLayers: Ember.A(),
    defaultMaxElevationChangeFt: 10,
    defaultElevationThresholdFt: 0.2,
    defaultAreaThresholdSqFt: 1,
    cutBlueFillRed: true, // These properties are convenient initialization steps.
    showDiffLabel: true, // Actual property values will be stored in mapData
    showUAV1Label: true, //
    showUAV2Label: true, //
    gridLabelTemplate: '{diff}\n{uav1}\n{uav2}',
    activeChangeDetectionHasLabels: false,
    drawingMode: false,
    numRegions: 0,
    featureGeometry: [],
    excludedFeatureGeometry: [],
    dataType: 'change-detection',
    listenerId: 'polygon_change_detection',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var component = this;
      var settingsManager = this.get('settingsManager');
      var mapData = this.get('mapData');
      var map = this.get('mapData.mapInstance');

      mapData.resetDrawingMode();
      mapData.setListeningMode(this.listenerId);
      this.setDeleteListener();
      this.set('draw-data', []);
      Ember.run.next(function () {
        component.setListeners(component.dataType);
      });

      this.set('maxElevationChangeFt', this.get('defaultMaxElevationChangeFt'));
      this.set('elevationThresholdFt', this.get('defaultElevationThresholdFt'));
      this.set('areaThresholdSqFt', this.get('defaultAreaThresholdSqFt'));
      this.set('currentThresholdMax', this.calculateMaxElevationThreshold(this.get('maxElevationChangeFt')));
      this.set('cdtShowDifference', this.get('settingsManager.cdtShowDifference'));
      this.set('cdtShowCurrentElevation', this.get('settingsManager.cdtShowCurrentElevation'));
      this.set('cdtShowComparisonSurface', this.get('settingsManager.cdtShowComparisonSurface'));
      this.set('cdtGridSpacing', this.get('settingsManager.cdtGridSpacing'));

      settingsManager.addObserver('cutFillColorMap', function () {
        Ember.run.once(_this, function () {
          // Loop through map's layer names, look for cut-fill-layer names and changes the color map they use
          map.getStyle().layers.forEach(function (layer) {
            if (layer.id.indexOf('cut-fill-layer-') !== -1) {
              map.setPaintProperty(layer.id, 'fill-color', {
                property: 'VALUE',
                stops: settingsManager.get('cutFillColorMap')
              });
            }
          });
        });
      });
    },
    configureLabelTemplate: function configureLabelTemplate() {
      var labels = [];

      if (this.get('settingsManager.cdtShowDifference')) {
        labels.push('{diff}');
      }
      if (this.get('settingsManager.cdtShowCurrentElevation')) {
        labels.push(this.determineCdtValueForDisplay('{uav1}'));
      }
      if (this.get('settingsManager.cdtShowComparisonSurface')) {
        labels.push(this.determineCdtValueForDisplay('{uav2}'));
      }
      var labelTemplate = labels.join('\n');
      this.set('gridLabelTemplate', labelTemplate);
      return labelTemplate;
    },
    configureLabelLevels: function configureLabelLevels() {
      var gridSpacing = this.get('settingsManager.cdtGridSpacing');
      var labelLevels = {}; // Keys are levels at which labels have been generated (should correspond to keys in labelGeojsons), values are objects containing various properties that will be passed to mapbox
      if (gridSpacing === '25') {
        labelLevels['25'] = {
          minzoom: 13,
          maxzoom: 24,
          'text-size': { stops: [[15, 1], [16, 2], [17, 3.5], [18, 8], [19, 14]] },
          'text-allow-overlap': true
        };
      } else if (gridSpacing === '100') {
        labelLevels['100'] = {
          minzoom: 13,
          maxzoom: 24,
          'text-size': { stops: [[15, 2], [16, 4], [17, 8], [18, 14]] },
          'text-allow-overlap': true
        };
      } else if (gridSpacing === '250') {
        labelLevels['250'] = {
          minzoom: 13,
          maxzoom: 24,
          'text-size': { stops: [[15, 2], [16, 4], [17, 8], [18, 14]] },
          'text-allow-overlap': true
        };
      } else {
        labelLevels = {
          25: {
            minzoom: 19,
            maxzoom: 24,
            'text-size': 14,
            'text-allow-overlap': true
          },
          100: {
            minzoom: 17,
            maxzoom: 19,
            'text-size': 14,
            'text-allow-overlap': true
          },
          250: {
            minzoom: 13,
            maxzoom: 17,
            'text-size': { stops: [[13, 8], [14, 9], [15, 10], [16, 11], [17, 12]] },
            'text-allow-overlap': true
          }
        };
      }
      return labelLevels;
    },
    determineCdtValueForDisplay: function determineCdtValueForDisplay(origCdtVal) {
      // See PROD-221 for full details.
      // This method is required for displaying CDT value labels due to a logic
      // error in the workers which swap uav1 and uav2 for flight to flight
      // compparisons; flight to blueprint comparisons are correct and should not
      // be swapped.
      // TODO: Remove this method when the worker code and generated geojsons are
      // updated to correct swapped uav1 and uav2 values.
      if (this.get('comparisonType') === 'flight') {
        return origCdtVal === '{uav1}' ? '{uav2}' : '{uav1}';
      }
      return origCdtVal;
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var component = this;
      var layers = this.get('mapData.mapInstance.style._layers'); // mapBox private vars for layer names
      var cachedProperties = ['showDiffLabel', 'showUAV1Label', 'showUAV2Label', 'cutBlueFillRed'];

      // set up mapData active Change Detection cache if it doesn't exist yet.
      if (!this.get('mapData.activeChangeDetection')) {
        this.set('mapData.activeChangeDetection', {});
      }

      // sync mapData-cached properties with defaults if they aren't already set.
      cachedProperties.forEach(function (prop) {
        if (_npmLodash.default.isNil(_this2.get('mapData.activeChangeDetection.' + prop))) {
          // can't do a falsy-check, because false is an acceptable value
          _this2.set('mapData.activeChangeDetection.' + prop, _this2.get(prop));
        }
      });

      this.configureLabelTemplate(); // reset default property based on visual prefs.

      // loop through mapBox layer names, look for cut-fill-layer names and extract changeDetection ID (injected on initial create)
      // from name, then restore the values for the changeDetection, including menu state
      Object.keys(layers).forEach(function (layer) {
        if (layer.indexOf('cut-fill-layer-') !== -1) {
          var cdId = layer.replace('cut-fill-layer-', '');

          var cd = component.get('params.changeDetections').findBy('id', cdId);

          if (cd.get('comparisonFlight.id')) {
            component.set('comparisonType', 'flight');
            component.set('comparisonModel', cd.get('comparisonFlight'));
          } else {
            component.set('comparisonType', 'blueprint');
            component.set('comparisonModel', cd.get('comparisonBlueprint'));
          }
          component.set('currentChangeDetection', cd);
          component.sendAction('setCurrentChangeDetection', cd);
          component.set('activeChangeDetection', cdId);
        } else if (layer.indexOf('-labels-layer-') !== -1) {
          component.set('activeChangeDetectionHasLabels', true);
        }
      });
    },
    didRender: function didRender() {
      var component = this;

      $('#maxElevationChangeFt').slider({
        min: 1,
        max: 100,
        enabled: false
      });
      $('#areaThresholdSqFt').slider({
        min: 1,
        max: 2500,
        step: 100,
        enabled: false
      });
      $('#elevationThresholdFt').slider({
        min: 0,
        value: component.get('defaultElevationThresholdFt'),
        step: 0.1,
        max: component.get('currentThresholdMax'),
        enabled: false
      });

      $('input.ui-slider').on('change', function (event) {
        component.set(event.target.id, event.value.newValue);
        if (event.target.id === 'maxElevationChangeFt') {
          var threshMax = component.calculateMaxElevationThreshold(component.get('maxElevationChangeFt'));
          threshMax = threshMax.toPrecision(3);
          component.set('currentThresholdMax', threshMax);
          $('#elevationThresholdFt').slider('setAttribute', 'max', threshMax);
          if (component.get('elevationThresholdFt') > threshMax) {
            component.set('elevationThresholdFt', threshMax);
            $('#elevationThresholdFt').slider('setValue', threshMax);
          } else {
            $('#elevationThresholdFt').slider('setValue', component.get('elevationThresholdFt'));
          }
        }
      });

      $('#cdtDeleteConfirm').on('deleteCD', function (event, params) {
        var comparisonModel = void 0;

        if (params.cd.get('comparisonFlight.id')) {
          comparisonModel = component.get('params.availableFlights').findBy('id', params.cd.get('comparisonFlight.id'));
        } else {
          comparisonModel = component.get('params.availableBlueprints').findBy('id', params.cd.get('comparisonBlueprint.id'));
        }

        comparisonModel.set('changeDetection', null);
      });
    },
    willDestroyElement: function willDestroyElement() {
      var activeChangeDetection = this.get('activeChangeDetection');
      var mapData = this.get('mapData');
      mapData.deleteListenersForQualifier(this.listenerId);
      this.cleanUpRegionUI(this.dataType);
      $('#cdt-tool-bar').removeClass('active'); // # grrr...  -_-
      if (!activeChangeDetection) {
        mapData.set('activeChangeDetection', null);
      }
    },
    updateRegion: function updateRegion(polygon) {
      this.saveNewRegion(polygon);
    },
    saveNewRegion: function saveNewRegion(polygon) {
      var id = polygon.features[0].id;

      var currentRegions = this.get('draw-data');
      var featureGeometry = this.get('featureGeometry');
      var excludedFeatureGeometry = this.get('excludedFeatureGeometry');
      var currentDrawingMode = this.get('drawingMode');

      currentRegions[id] = polygon.features[0].geometry.coordinates[0].map(function (coords) {
        return { lng: coords[0], lat: coords[1] };
      });

      if (currentDrawingMode === 'include' || featureGeometry[id]) {
        featureGeometry[id] = currentRegions[id];
      } else if (currentDrawingMode === 'exclude' || excludedFeatureGeometry[id]) {
        this.redrawRegionWithColor(currentRegions[id], id, 'red');
        excludedFeatureGeometry[id] = currentRegions[id];
      }

      this.set('numRegions', Object.keys(currentRegions).length);
    },
    redrawRegionWithColor: function redrawRegionWithColor(region, regionId, color) {
      var featureProps = {
        id: regionId,
        regionId: regionId,
        color: color,
        observable_as: this.listenerId,
        points: region,
        type: 'Polygon'
      };

      this.get('mapData').drawFeature(featureProps);
    },
    setDeleteListener: function setDeleteListener() {
      var component = this;
      var drawingManager = this.get('mapData.drawingManager');
      var regionMetaManager = this.get('regionMetaManager');

      $('.maps-container').unbind('keyup');
      $('.maps-container').on('keyup', function (event) {
        if (event.keyCode === 8 || event.keyCode === 46) {
          var selectedRegions = drawingManager.getSelectedIds();

          if (selectedRegions.length > 0) {
            regionMetaManager.removeDrawingRegionHelperInformationLayer();
            var currentRegions = component.get('draw-data');
            var featureGeometry = component.get('featureGeometry');
            var excludedFeatureGeometry = component.get('excludedFeatureGeometry');

            selectedRegions.forEach(function (regionId) {
              drawingManager.delete(regionId);
              delete currentRegions[regionId];
              delete featureGeometry[regionId];
              delete excludedFeatureGeometry[regionId];
            });

            component.set('numRegions', Object.keys(currentRegions).length);
          }
        }
      });
    },
    calculateMaxElevationThreshold: function calculateMaxElevationThreshold(maxElevationChange) {
      var maxElevationThreshold = maxElevationChange / 10 - 0.5;
      return maxElevationThreshold > 0 ? maxElevationThreshold : 0;
    },


    actions: {
      closePanel: function closePanel() {
        this.sendAction('closePanel');
      },
      deleteCurrentCalculation: function deleteCurrentCalculation() {
        var cdt = this.get('currentChangeDetection');

        if (!cdt || cdt.get('status') === 'pending') {
          return;
        }
        this.send('hideAllComparisons');
        this.send('resetSliders');
        this.send('resetRegions');
        this.set('currentChangeDetection', null);
        this.set('comparisonModel', null);
        this.set('comparisonType', null);

        $('#cdtDeleteConfirm').modal('show');
      },
      calculate: function calculate() {
        var component = this;
        var feedback = this.get('feedback');
        var store = this.get('store');
        var statusService = this.get('statusService');
        var newChangeDetection = {};
        var regions = this.get('draw-data');
        var featureGeometry = [];
        var excludedFeatureGeometry = [];
        var record = null;

        if (this.get('comparisonType') === 'flight') {
          newChangeDetection.comparisonFlight = this.get('comparisonModel');
        } else if (this.get('comparisonType') === 'blueprint') {
          newChangeDetection.comparisonBlueprint = this.get('comparisonModel');
        } else {
          feedback.reportError({ title: 'No comparison selected', detail: 'You must select either a flight or a blueprint to compare.' });
          return;
        }

        this.send('exitDrawingMode');
        this.cleanUpRegionUI(this.dataType);

        newChangeDetection.flight = this.get('params.currentFlight');
        newChangeDetection.status = 'pending';

        newChangeDetection.maxElevationChangeFt = component.get('maxElevationChangeFt');
        newChangeDetection.elevationThresholdFt = component.get('elevationThresholdFt');
        newChangeDetection.areaThresholdSqFt = component.get('areaThresholdSqFt');

        Object.keys(this.get('featureGeometry')).forEach(function (region) {
          if (Object.prototype.hasOwnProperty.call(regions, region)) {
            featureGeometry.push(regions[region]);
          }
        });

        newChangeDetection.featureGeometry = featureGeometry;

        Object.keys(this.get('excludedFeatureGeometry')).forEach(function (excludedRegion) {
          if (Object.prototype.hasOwnProperty.call(regions, excludedRegion)) {
            excludedFeatureGeometry.push(regions[excludedRegion]);
          }
        });

        newChangeDetection.excludedFeatureGeometry = excludedFeatureGeometry;

        record = store.createRecord('changeDetection', newChangeDetection);

        $('.ui-slider').slider('disable');

        record.save().then(function (response) {
          var comparisonModel = void 0;

          if (component.get('comparisonType') === 'flight') {
            comparisonModel = component.get('params.availableFlights').findBy('id', newChangeDetection.comparisonFlight.id);
          } else {
            comparisonModel = component.get('params.availableBlueprints').findBy('id', newChangeDetection.comparisonBlueprint.id);
          }
          comparisonModel.set('changeDetection', response);
          component.set('currentChangeDetection', response);
          component.sendAction('setCurrentChangeDetection', response);

          $('#cdtCalculateConfirm').modal('show');

          statusService.pushItem('status-panel-cdt', response, 'changeDetection');

          /*
          There needs to be code here that's going to get the tiled map and update the tiled map record.
          Otherwise, it's on the server, but disconnected from the changeDetection.
          */

          // these may be necessary to refresh Ember data and display the map when it's ready:

          store.findRecord('changeDetection', response.get('id'));
          store.findRecord('tiledMap', response.get('tiledMap.id'));

          return response;
        }, function (error) {
          console.error('CDT ember save error', error);
        });

        this.trackEvent('CDT Calculated', { flight: this.get('params.currentFlight.id') });
      },
      toggleLayer: function toggleLayer() {
        if (!this.get('currentChangeDetection')) {
          console.error('toggleLayer action called when it should not be available');
          return;
        }
        if (this.get('activeChangeDetection')) {
          // layer active, remove it
          this.send('hideAllComparisons');
        } else {
          this.send('displayComparison', this.get('currentChangeDetection'), this.get('comparisonModel'), this.get('comparisonType'));
        }
      },
      resetSliders: function resetSliders() {
        var defaultMaxElevationChangeFt = this.get('defaultMaxElevationChangeFt');
        var defaultElevationThresholdFt = this.get('defaultElevationThresholdFt');
        var defaultAreaThresholdSqFt = this.get('defaultAreaThresholdSqFt');
        var threshMax = this.calculateMaxElevationThreshold(defaultMaxElevationChangeFt);

        this.set('maxElevationChangeFt', defaultMaxElevationChangeFt);
        this.set('elevationThresholdFt', defaultElevationThresholdFt);
        this.set('areaThresholdSqFt', defaultAreaThresholdSqFt);
        this.set('currentThresholdMax', threshMax);

        $('#maxElevationChangeFt').slider('setValue', defaultMaxElevationChangeFt);
        $('#elevationThresholdFt').slider('setAttribute', 'max', threshMax);
        $('#elevationThresholdFt').slider('setValue', defaultElevationThresholdFt);
        $('#areaThresholdSqFt').slider('setValue', defaultAreaThresholdSqFt);
      },
      resetRegions: function resetRegions() {
        this.set('numRegions', 0);
        this.set('draw-data', []);
        this.set('featureGeometry', []);
        this.set('excludedFeatureGeometry', []);
        this.cleanUpRegionUI(this.dataType);
        this.send('exitDrawingMode');
      },
      saveCdtSettings: function saveCdtSettings() {
        var settingsManager = this.get('settingsManager');
        var p = _environment.default.preferences.cdt;
        settingsManager.setSitePreference(p.showDifference.name, document.getElementById('cdtShowDifference').checked);
        settingsManager.setSitePreference(p.showCurrentElevation.name, document.getElementById('cdtShowCurrentElevation').checked);
        settingsManager.setSitePreference(p.showComparisonSurface.name, document.getElementById('cdtShowComparisonSurface').checked);
        settingsManager.setSitePreference(p.gridSpacing.name, document.getElementById('gridSpaceDropdown').value);
        this.send('hideAllComparisons');
        this.send('displayComparison', this.get('currentChangeDetection'), this.get('comparisonModel'), this.get('comparisonType'));
      },
      selectComparison: function selectComparison(type, model) {
        this.set('currentChangeDetection', null);
        this.set('comparisonModel', model);
        this.set('comparisonType', type);
        this.send('hideAllComparisons');

        this.send('resetSliders');
        this.send('resetRegions');
        $('.ui-slider').slider('enable');
      },
      hideAllComparisons: function hideAllComparisons() {
        var map = this.get('mapData.mapInstance');
        var layers = this.get('changeDetectionLayers');
        layers.forEach(function (layer) {
          if (map.getLayer(layer)) {
            // Leaving inactive code here in case we want to rethink this behavior
            // map.setLayoutProperty(layer, 'visibility', 'none');
            map.removeLayer(layer);
          }
        });
        layers.clear();

        this.set('activeChangeDetection', null);
        this.set('activeChangeDetectionHasLabels', false);
      },
      hideComparison: function hideComparison() {
        // params: changeDetection, model
        // for now, ignore specific layer and just hide all
        this.send('hideAllComparisons');
      },
      displayComparison: function displayComparison(changeDetection, model, type) {
        var settingsManager = this.get('settingsManager');
        var feedback = this.get('feedback');
        var map = this.get('mapData.mapInstance');
        var layerUrl = changeDetection.get('tiledMap.displayUrl');
        var layerMaxZoomLevel = 20;
        var labelGeojsons = changeDetection.get('labelGeojsons'); // Keys are levels at which labels have been generated (if any), values are relative paths within S3 bucket for each label's geojson file
        var s3BucketUrl = changeDetection.get('urlPrefix');
        var labelLevels = this.configureLabelLevels();
        var numRegions = changeDetection.get('featureGeometry').length + changeDetection.get('excludedFeatureGeometry').length;
        var threshMax = void 0;
        var component = this;

        this.set('currentChangeDetection', changeDetection);
        this.sendAction('setCurrentChangeDetection', changeDetection); // send up to cdt-ui component for delete actions
        this.set('comparisonModel', model);
        this.set('comparisonType', type);

        $('.ui-slider').slider('disable');
        this.send('resetRegions');

        threshMax = this.calculateMaxElevationThreshold(changeDetection.get('maxElevationChangeFt'));
        threshMax = threshMax.toPrecision(3);

        this.set('maxElevationChangeFt', changeDetection.get('maxElevationChangeFt'));
        this.set('elevationThresholdFt', changeDetection.get('elevationThresholdFt'));
        this.set('areaThresholdSqFt', changeDetection.get('areaThresholdSqFt'));
        this.set('currentThresholdMax', threshMax);
        this.set('numRegions', numRegions);

        $('#maxElevationChangeFt').slider('setValue', changeDetection.get('maxElevationChangeFt'));
        $('#elevationThresholdFt').slider('setAttribute', 'max', threshMax);
        $('#elevationThresholdFt').slider('setValue', changeDetection.get('elevationThresholdFt'));
        $('#areaThresholdSqFt').slider('setValue', changeDetection.get('areaThresholdSqFt'));

        // Remove any existing layers no matter what (for now)
        this.send('hideAllComparisons');

        if (changeDetection.get('status') === 'failed') {
          return feedback.reportError({
            title: 'Change Detection Failed',
            detail: 'Please delete the calculation and try again. ' + changeDetection.get('message.message')
          });
        }

        if (changeDetection.get('status') === 'pending') {
          return feedback.reportInfo({
            title: 'Change Detection Not Ready',
            detail: 'The selected change detection map is not ready yet. Please wait until it loads and try again.'
          });
        }

        // Set change detection information so that it can be used in map exports if required
        var changeDetectionInfo = this.get('mapData.activeChangeDetection');

        changeDetectionInfo.comparisonType = this.get('comparisonType');
        changeDetectionInfo.comparisonFlightDate = '';
        changeDetectionInfo.comparisonBlueprintName = '';

        if (this.get('comparisonType') === 'flight') {
          changeDetectionInfo.comparisonFlightDate = this.get('comparisonModel.executeAt');
        } else {
          changeDetectionInfo.comparisonBlueprintName = this.get('comparisonModel.name');
        }
        this.set('mapData.activeChangeDetection', changeDetectionInfo);
        this.set('activeChangeDetection', changeDetection.id);

        // Display the colored CDT polygons
        var polygonsSourceName = 'cut-fill-' + changeDetection.id;
        var polygonsLayerName = 'cut-fill-layer-' + changeDetection.id;

        if (!map.getSource(polygonsSourceName)) {
          map.addSource(polygonsSourceName, {
            type: 'vector',
            tiles: [layerUrl + '{z}/{x}/{y}.pbf'],
            maxzoom: layerMaxZoomLevel
          });
        }

        if (map.getLayer(polygonsLayerName)) {
          map.setLayoutProperty(polygonsLayerName, 'visibility', 'visible');
          this.get('changeDetectionLayers').push(polygonsLayerName);
        } else {
          map.addLayer({
            id: polygonsLayerName, // unique for this layer
            source: polygonsSourceName, // where to look for this
            'source-layer': 'change-detection', // used to filter multiple objects inside

            // styling for fill layer: https://www.mapbox.com/mapbox-gl-style-spec/#layers-fill
            type: 'fill',
            paint: {
              'fill-color': {
                property: 'VALUE',
                stops: settingsManager.get('cutFillColorMap')
              },
              'fill-opacity': 0.7
            },
            // our geojson is tagged as 'value' for the classification
            // https://www.mapbox.com/mapbox-gl-style-spec/#types-filter
            filter: ['has', 'VALUE']
          });

          this.get('changeDetectionLayers').push(polygonsLayerName);
        }

        // Display the CDT cut/fill/elevation labels if any were generated
        if (labelGeojsons) {
          this.configureLabelTemplate();
          Object.keys(labelLevels).forEach(function (labelKey) {
            if (Object.prototype.hasOwnProperty.call(labelGeojsons, labelKey)) {
              component.set('activeChangeDetectionHasLabels', true);

              var labelDict = labelLevels[labelKey];

              var sourceName = 'cut-fill-' + labelKey + '-labels-' + changeDetection.id;
              var layerName = 'cut-fill-' + labelKey + '-labels-layer-' + changeDetection.id;

              // Add/update the geojson source
              if (!map.getSource(sourceName)) {
                var geojsonSource = {
                  type: 'geojson',
                  data: s3BucketUrl + labelGeojsons[labelKey],
                  maxzoom: layerMaxZoomLevel
                };
                map.addSource(sourceName, geojsonSource);
              } else {
                map.getSource(sourceName).setData(s3BucketUrl + labelGeojsons[labelKey]);
              }

              // Add the labels to the map
              if (map.getLayer(layerName)) {
                map.setLayoutProperty(layerName, 'visibility', 'visible');
                component.get('changeDetectionLayers').push(layerName);
              } else {
                var labelsLayer = {
                  id: layerName,
                  type: 'symbol',
                  source: sourceName,
                  layout: {
                    'symbol-placement': 'point',
                    'text-field': component.get('gridLabelTemplate'),
                    'text-size': labelDict['text-size'],
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                    'text-allow-overlap': labelDict['text-allow-overlap'],
                    'text-padding': 1
                  },
                  minzoom: labelDict.minzoom,
                  maxzoom: labelDict.maxzoom,
                  paint: {
                    'text-color': '#000',
                    'text-halo-color': '#fff',
                    'text-halo-width': 1
                  }
                };
                map.addLayer(labelsLayer);
                component.get('changeDetectionLayers').push(layerName);
              }
            }
          });
        }

        var logMessage = {
          changeDetectionId: component.get('currentChangeDetection.id'),
          comparisonFlightId: component.get('currentChangeDetection.comparisonFlight.id'),
          comparisonFlightExecuteAt: component.get('currentChangeDetection.comparisonFlight.executeAt'),
          comparisonBlueprintId: component.get('currentChangeDetection.comparisonBlueprint.id'),
          flightId: component.get('params.currentFlight.id'),
          executeAt: component.get('params.currentFlight.executeAt'),
          siteId: component.get('params.currentFlight').get('site.id'),
          site: component.get('params.currentFlight').get('site.name')
        };

        return component.trackEvent('Viewing Change Detection', logMessage);
      },
      goToFileManager: function goToFileManager(id, mode) {
        this.sendAction('goToFileManager', id, mode);
      },
      toggleDrawingMode: function toggleDrawingMode(drawingMode) {
        var currentDrawingMode = this.get('drawingMode');
        if (currentDrawingMode === drawingMode) {
          this.send('exitDrawingMode');
        } else if (!currentDrawingMode) {
          this.setDrawingModeForNewRegions(this.listenerId);
          this.set('drawingMode', drawingMode);
        } else {
          this.set('drawingMode', drawingMode);
        }
      },
      exitDrawingMode: function exitDrawingMode() {
        var currentDrawingMode = this.get('drawingMode');
        if (currentDrawingMode) {
          this.setDrawingModeForNewRegions(this.listenerId);
        }
        this.set('drawingMode', false);
      }
    }
  });
});