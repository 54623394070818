define('webui/pods/components/gcp-tagging-validation-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      confirm: function confirm() {
        this.sendAction('confirmButtonAction');
      }
    }
  });
});