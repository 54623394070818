define('webui/pods/components/quantity-display/component', ['exports', 'npm:chartist', 'npm:lodash', 'webui/mixins/chartist-legend', 'webui/mixins/string-formatting', 'npm:chartist-plugin-tooltips', 'npm:chartist-plugin-axistitle'], function (exports, _npmChartist, _npmLodash, _chartistLegend, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_chartistLegend.default, _stringFormatting.default, {
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    siteGrades: 'siteGrades',
    isShowingConfirmation: false,

    isQuantityATrackingCutFill: true,
    isChartDisplayingPercentage: true,
    doesQuantityContainUnsavedChanges: false,

    siteGradesForSelect: Ember.A(),
    selectedSurfaces: [],
    staticSurfacesForSelect: Ember.A(),
    surfacesLoaded: false,

    init: function init() {
      this._super();
      this.loadSiteGrades();
      this.setupDataForFlightSelector();
      this.set('name', this.get('quantity.name'));
    },
    didInsertElement: function didInsertElement() {
      this.renderChart();
      this.setupSurfaceSelectPicker();
      this.setupFlightSelectPicker();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var context = this;
      this.setupDataForFlightSelector();
      this.renderChart();
      Ember.run.next(function () {
        context.setupSurfaceSelectPicker();
        context.refreshFlightSelectPicker();
      });

      var values = [];
      var selected = this.get('quantity.staticElevations');
      var staticSurfacesForSelect = Ember.A();
      this.get('staticSurfaces').forEach(function (surface) {
        values.push(surface.value);
        staticSurfacesForSelect.pushObject({ value: surface.value, selected: selected.indexOf(surface.value) !== -1 });
      });

      selected.forEach(function (value) {
        if (!(values.indexOf(value) !== -1)) {
          staticSurfacesForSelect.pushObject({ value: value, selected: true });
        }
      });

      this.set('staticSurfacesForSelect', staticSurfacesForSelect);
    },
    willDestroyElement: function willDestroyElement() {
      var _get = this.get('quantity'),
          id = _get.id;

      if (!this.get('isReadOnly')) {
        if ($('#' + id + '-surface-selector').children()[0].childElementCount > 0) {
          $('#' + id + '-surface-selector').selectpicker('destroy');
        }
      }
    },
    loadSiteGrades: function loadSiteGrades() {
      var siteGrades = this.get('siteGrades');
      var siteGradesForSelect = Ember.A();
      var selectedSiteGrades = this.get('quantity.blueprints');

      siteGrades.forEach(function (siteGrade) {
        var id = siteGrade.get('id');
        var name = siteGrade.get('name');
        var selected = selectedSiteGrades.filterBy('id', id).length > 0;

        siteGradesForSelect.pushObject({ id: id, name: name, selected: selected });
      });

      this.set('siteGradesForSelect', siteGradesForSelect);
    },
    setupDataForFlightSelector: function setupDataForFlightSelector() {
      var _this = this;

      var activeFlightsForCurrentSite = this.get('activeSite').get('flights').rejectBy('status', 'pending').sortBy('executeAt');
      var flightDataForSelector = activeFlightsForCurrentSite.map(function (flight) {
        return {
          id: flight.get('id'),
          executeAt: flight.get('executeAt'),
          name: _this.dateTimeString(flight.get('executeAt'), '/', ':', '—', true).slice(0, 10),
          subtitle: flight.get('subtitle'),
          selected: true
        };
      });

      var _get2 = this.get('quantity.report'),
          labels = _get2.labels;

      var filteredFlightDataForSelector = flightDataForSelector.filter(function (flight) {
        return labels && labels.indexOf(flight.executeAt) !== -1;
      });

      this.set('flightsForSelect', filteredFlightDataForSelector);
      this.set('selectedFlights', filteredFlightDataForSelector.map(function () {
        return true;
      }));
    },
    renderChart: function renderChart() {
      if (this.get('quantity.status') !== 'succeeded' && this.get('quantity.status') !== 'warning') {
        return;
      }

      var id = this.get('quantity.id');

      var _get3 = this.get('quantity.report'),
          legend = _get3.legend;

      var isPercentage = this.get('isChartDisplayingPercentage');
      var series = isPercentage ? this.getPercentageSeries() : this.getAbsoluteSeries();
      var labels = this.getLabels();
      var yAxisTitle = isPercentage ? '% Complete' : 'Cubic Yards';

      this.clearCurrentChart();

      function SuppressForeignObjectPlugin(chart) {
        chart.supportsForeignObject = false;
      }

      var component = this;
      var counter = -1;
      if ($('#quantity-chart-' + id).length) {
        var chartistObject = new _npmChartist.default.Line('#quantity-chart-' + id, { series: series, labels: labels }, {
          showArea: true,
          showPoint: true,
          lineSmooth: false,
          onlyInteger: true,
          axisX: {
            showGrid: true
          },
          axisY: {
            labelInterpolationFnc: function labelInterpolationFnc(text) {
              return '' + text + (isPercentage ? '%' : ' yds3');
            },
            offset: 75
          },
          plugins: [_npmChartist.default.plugins.tooltip({
            appendToBody: true,
            class: 'id-tooltip tooltip-' + id,
            tooltipFnc: function tooltipFnc(meta, value) {
              var txt = document.createElement('textarea');
              txt.innerHTML = meta;
              var metaValues = JSON.parse(txt.value).data;
              var displayValue = 'Value: ' + parseFloat(value).toFixed(2) + (isPercentage ? '%' : ' (yds<sup>3</sup>)');
              var displayTarget = '<br/>Target: ' + parseFloat(metaValues.target).toFixed(2) + ' (yds<sup>3</sup>)';
              var displayChange = metaValues.change !== null ? '<br/>Change: ' + parseFloat(metaValues.change).toFixed(2) + (isPercentage ? '%' : ' (yds<sup>3</sup>)') : '';
              var displayTonnage = '';

              if (metaValues.density !== null) {
                var tonnage = Math.abs(Math.round(metaValues.volume * metaValues.density * 100) / 100);
                displayTonnage = metaValues.density ? '<br/>Tonnage: ' + tonnage + ' (US Tons)' : '';
              }

              return '' + displayValue + displayTarget + displayChange + displayTonnage;
            }
          }), _npmChartist.default.plugins.ctAxisTitle({
            axisX: {
              axisTitle: '',
              axisClass: 'ct-axis-title',
              offset: {
                x: 0,
                y: 0
              },
              textAnchor: 'middle'
            },
            axisY: {
              axisTitle: yAxisTitle,
              axisClass: 'ct-axis-title',
              offset: {
                x: 0,
                y: 15
              },
              flipTitle: true
            }
          }), SuppressForeignObjectPlugin, component.LegendPlugin({
            legendNames: legend,
            position: document.getElementById('legend-' + id),
            container: '#legend-' + this.get('quantity.id'),
            removeAll: true,
            startingSeries: series.map(function () {
              counter += 1;
              return counter;
            }),
            series: series
          })]
        });

        this.set('chartistObject', chartistObject);
      }
    },
    getAbsoluteSeries: function getAbsoluteSeries() {
      var selectedFlights = this.get('selectedFlights');
      var allSeries = _npmLodash.default.cloneDeep(this.get('quantity.report.series'));
      var series = allSeries.map(function (surface) {
        return surface.filter(function (flight, index) {
          return selectedFlights[index];
        });
      });
      var surfaces = _npmLodash.default.cloneDeep(this.get('quantity.report.surfaces'));

      for (var i = 0; i < series.length; i += 1) {
        series[i].className = 'color-series-' + i;
        for (var j = 0; j < series[i].length; j += 1) {
          var target = series[i][j][0];
          var value = series[i][j][1];
          var volume = series[i][j][1];
          var change = j > 0 ? value - series[i][j - 1].value : null;
          var density = null;
          if (surfaces && surfaces[i]) {
            density = this.get('store').peekRecord('blueprint', surfaces[i]).get('density');
          }

          series[i][j] = { meta: { change: change, density: density, volume: volume, target: target }, value: value };
        }
      }

      return series;
    },
    getPercentageSeries: function getPercentageSeries() {
      var selectedFlights = this.get('selectedFlights');
      var allSeries = _npmLodash.default.cloneDeep(this.get('quantity.report.series'));
      var series = allSeries.map(function (surface) {
        return surface.filter(function (flight, index) {
          return selectedFlights[index];
        });
      });
      var surfaces = _npmLodash.default.cloneDeep(this.get('quantity.report.surfaces'));

      for (var i = 0; i < series.length; i += 1) {
        series[i].className = 'color-series-' + i;
        for (var j = 0; j < series[i].length; j += 1) {
          var target = series[i][j][0];
          var value = series[i][j][0] > 0 ? series[i][j][1] / series[i][j][0] * 100 : 0;
          var volume = series[i][j][1];
          var change = j > 0 ? value - series[i][j - 1].value : null;
          var density = null;
          if (surfaces && surfaces[i]) {
            density = this.get('store').peekRecord('blueprint', surfaces[i]).get('density');
          }

          series[i][j] = { meta: { change: change, density: density, volume: volume, target: target }, value: value };
        }
      }

      return series;
    },
    getLabels: function getLabels() {
      var _this2 = this;

      var selectedFlights = this.get('selectedFlights');

      var _get4 = this.get('quantity.report'),
          labels = _get4.labels;

      var filteredLabels = labels.filter(function (label, index) {
        return selectedFlights[index];
      });
      var humanFriendlyLabels = filteredLabels.map(function (label) {
        return _this2.dateTimeString(label, '/', ':', '—', true);
      }).map(function (label) {
        if (filteredLabels.length < 10) return '' + label.slice(0, 10);
        if (filteredLabels.length < 14) return '' + label.slice(0, 6) + label.slice(8, 10);

        return '' + label.slice(0, 5);
      });

      return humanFriendlyLabels;
    },
    clearCurrentChart: function clearCurrentChart() {
      var _get5 = this.get('quantity'),
          id = _get5.id;

      var chartistObject = this.get('chartistObject');

      if (chartistObject) {
        chartistObject.detach();
        $('#quantity-chart-' + id).empty();
        $('#legend-' + id).empty();
        $('.tooltip-' + id).remove();
      }
    },
    setupSurfaceSelectPicker: function setupSurfaceSelectPicker() {
      var context = this;

      var _get6 = this.get('quantity'),
          id = _get6.id;

      if (this.get('isReadOnly')) {
        return;
      }

      if ($('#' + id + '-surface-selector').children()[0].childElementCount > 0) {
        if (this.get('surfacesLoaded') === false) {
          this.toggleProperty('surfacesLoaded');

          $('#' + id + '-surface-selector').selectpicker();
          $('#' + id + '-surface-selector').on('changed.bs.select', function () {
            context.set('doesQuantityContainUnsavedChanges', true);
          });
        } else {
          $('#' + id + '-surface-selector').selectpicker('refresh');
        }
      }

      setTimeout(function () {
        return $('#' + id + '-surface-selector').selectpicker('toggle');
      }, 500);
    },
    setupFlightSelectPicker: function setupFlightSelectPicker() {
      var _this3 = this;

      var context = this;

      var _get7 = this.get('quantity'),
          id = _get7.id;

      $('#' + id + '-flight-selector').selectpicker();
      $('#' + id + '-flight-selector').on('rendered.bs.select', function () {
        var flightsFromSelect = $('#' + id + '-flight-selector').val() !== null ? $('#' + id + '-flight-selector').val() : [];
        var selectedFlights = _this3.get('flightsForSelect').map(function (flight) {
          return flightsFromSelect.indexOf(flight.id) !== -1;
        });

        context.set('selectedFlights', selectedFlights);
        context.renderChart();
      });
    },
    refreshFlightSelectPicker: function refreshFlightSelectPicker() {
      $('#' + this.get('quantity.id') + '-flight-selector').selectpicker('refresh');
    },
    generateReport: function generateReport() {
      var context = this;
      var store = this.get('store');

      var _get8 = this.get('quantity'),
          id = _get8.id;

      var selectedSurfaces = $('#' + id + '-surface-selector').val();
      var surfaceIds = selectedSurfaces.filter(function (filterId) {
        return isNaN(filterId);
      });
      var staticElevations = selectedSurfaces.filter(function (filterId) {
        return !isNaN(filterId);
      }).sort(function (a, b) {
        return b - a;
      }).map(function (ele) {
        return parseFloat(ele);
      });
      var quantity = store.peekRecord('quantity', id);

      quantity.set('name', context.get('name'));
      quantity.set('staticElevations', staticElevations);
      quantity.set('blueprints', Ember.A());
      surfaceIds.forEach(function (surfaceId) {
        return quantity.get('blueprints').pushObject(store.peekRecord('blueprint', surfaceId));
      });

      quantity.save().then(function () {
        Ember.run.next(function () {
          context.setupSurfaceSelectPicker();
          context.setupFlightSelectPicker();
        });
      });

      this.set('doesQuantityContainUnsavedChanges', false);
    },
    validate: function validate() {
      var _get9 = this.get('quantity'),
          id = _get9.id;

      var selectedSurfaces = $('#' + id + '-surface-selector').val();

      return selectedSurfaces.length > 1;
    },
    displayIvalidMessage: function displayIvalidMessage() {
      this.get('feedback').reportError({ title: 'Error', detail: 'A minimum of two surfaces required to generate a quantity report.' });
    },


    removeQuantity: 'removeQuantity',
    toggleRegionDisplay: 'toggleRegionDisplay',

    actions: {
      saveChanges: function saveChanges() {
        if (this.validate()) {
          this.generateReport();
        } else {
          this.displayIvalidMessage();
        }
      },
      discardChanges: function discardChanges() {
        var context = this;

        if (this.get('quantity.waiting')) {
          this.sendAction('removeQuantity', this.get('quantity'));
        } else {
          this.set('name', this.get('quantity.name'));
          this.set('doesQuantityContainUnsavedChanges', false);
          this.loadSiteGrades();
          Ember.run.next(function () {
            return context.setupSurfaceSelectPicker();
          });
        }
      },
      updateName: function updateName() {
        this.set('doesQuantityContainUnsavedChanges', true);
      },
      removeQuantity: function removeQuantity(quantity) {
        this.sendAction('removeQuantity', quantity);
      },
      toggleChartDisplay: function toggleChartDisplay(type) {
        var isChartDisplayingPercentage = this.get('isChartDisplayingPercentage');
        var wasPercentageClicked = type === '%';

        if (isChartDisplayingPercentage !== wasPercentageClicked) {
          this.toggleProperty('isChartDisplayingPercentage');

          this.renderChart();
        }
      },
      toggleIsShowingConfirmation: function toggleIsShowingConfirmation() {
        this.toggleProperty('isShowingConfirmation');
      },
      toggleRegionDisplay: function toggleRegionDisplay(region) {
        this.sendAction('toggleRegionDisplay', region);
      }
    }
  });
});