define('webui/mixins/volume-calc-region', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    areaDisplay: Ember.computed('area', function areaDisplay() {
      return this.numberWithCommas(this.get('area'));
    }),

    displayDensity: Ember.computed('density', function displayDensity() {
      return this.get('density') ? this.numberWithCommas(this.get('density')) : '';
    }),

    baseplaneElevationDisplay: Ember.computed('baseplaneElevation', function baseplaneElevationDisplay() {
      return this.numberWithCommas(this.get('baseplaneElevation'));
    }),

    displayPlannedTonnage: Ember.computed('plannedTonnage', function displayPlannedTonnage() {
      return this.numberWithCommas(this.get('plannedTonnage'));
    }),

    netVolume: Ember.computed('cubicYardsFill', 'cubicYardsCut', function netVolume() {
      if (this.get('cubicYardsCut') === null && this.get('cubicYardsFill') === null) return '';

      return this.numberWithCommas(this.get('cubicYardsCut') + this.get('cubicYardsFill'));
    }),

    netVolumeNumber: Ember.computed('cubicYardsFill', 'cubicYardsCut', function netVolumeNumber() {
      return this.get('cubicYardsCut') + this.get('cubicYardsFill');
    }),

    tonnage: Ember.computed('density', 'cubicYardsCut', 'cubicYardsFill', function tonnage() {
      var density = this.get('density');
      var yardsCut = this.get('cubicYardsCut') ? this.get('cubicYardsCut') : 0;
      var yardsFill = this.get('cubicYardsFill') ? this.get('cubicYardsFill') : 0;

      return Math.abs(Math.round((yardsCut + yardsFill) * density * 100) / 100);
    }),

    displayTonnage: Ember.computed('density', 'tonnage', 'cubicYardsCut', 'cubicYardsFill', function displayTonnage() {
      return this.get('density') ? this.numberWithCommas(Math.abs(this.get('tonnage'))) : '';
    }),

    tonnageDifference: Ember.computed('plannedTonnage', 'tonnage', function tonnageDifference() {
      var tonnage = this.get('tonnage');
      var plannedTonnage = this.get('plannedTonnage');
      if (tonnage === null || plannedTonnage === null) return null;

      return (plannedTonnage - tonnage).toFixed(2);
    }),

    tonnageVariance: Ember.computed('plannedTonnage', 'tonnage', function tonnageVariance() {
      var tonnage = this.get('tonnage');
      var plannedTonnage = this.get('plannedTonnage');
      if (!plannedTonnage || tonnage === 0) return null;

      return 100 * (Math.abs(plannedTonnage - tonnage) / tonnage);
    }),

    tonnageVarianceDisplay: Ember.computed('tonnageVariance', 'plannedTonnage', 'tonnage', function tonnageVarianceDisplay() {
      if (this.get('plannedTonnage') === null || this.get('tonnageVariance') === null) return 'N/A';

      return '' + this.numberWithCommas(this.get('tonnageVariance').toFixed(2)) + (isFinite(this.get('tonnageVariance')) ? '%' : '');
    })
  });
});