define('webui/mixins/region-manager', ['exports', 'webui/mixins/numerical-conversion', 'webui/mixins/region-panel-scroll', 'ember-cli-segment/mixin', 'webui/mixins/filter-earthworks-by-blueprint', 'webui/mixins/site-grade', 'webui/mixins/map-layer-formatting'], function (exports, _numericalConversion, _regionPanelScroll, _mixin, _filterEarthworksByBlueprint, _siteGrade, _mapLayerFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Mixin.create(_mapLayerFormatting.default, _numericalConversion.default, _regionPanelScroll.default, _mixin.default, _filterEarthworksByBlueprint.default, _siteGrade.default, {
    GEOAPI: window.turf,
    session: Ember.inject.service('session'),
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    mapData: Ember.inject.service('mapData'),
    regionMetaManager: Ember.inject.service('region-meta-manager'),
    crossSectionMarker: null,
    currentFlight: null,
    currentSite: null,
    unsavedPolygonsExist: false,
    dirtyPolygons: {},

    dataTypes: {
      'tracking-region': {
        sideload: 'trackingRegions',
        association: 'site',
        associationProperty: 'currentSite',
        display: 'cut/fill area',
        listenerId: 'polygon_tracking_cut_fill'
      },
      region: {
        sideload: 'regions',
        association: 'flight',
        associationProperty: 'activeFlight',
        display: 'volume calc area',
        listenerId: 'polygon_volume_calc'
      },
      quantity: {
        sideload: 'quantities',
        association: 'site',
        associationProperty: 'currentSite',
        display: 'quantity',
        listenerId: 'quantity'
      },
      'cross-section': {
        sideload: 'crossSections',
        association: 'site',
        associationProperty: 'currentSite',
        display: 'cross section',
        listenerId: 'cross_section'
      },
      classification: {
        sideload: 'classifications',
        association: 'flight',
        associationProperty: 'currentFlight',
        display: 'classification',
        listenerId: 'polygon_classification'
      },
      'change-detection': {
        sideload: 'change-detection',
        association: 'flight',
        associationProperty: 'currentFlight',
        display: 'change detection',
        listenerId: 'polygon_change_detection'
      },
      'generic-region': {
        sideload: 'generic-region',
        association: 'flight',
        associationProperty: 'currentFlight',
        display: 'generic region',
        listenerId: 'polygon_generic_region'
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      if (window && window.mapboxgl) {
        this.setCrossSectionMarker();
      }
    },
    setCrossSectionMarker: function setCrossSectionMarker() {
      var newDiv = document.createElement('div');
      this.set('crossSectionMarker', new window.mapboxgl.Marker({ element: newDiv }).setLngLat([0, 0]).addTo(this.get('mapData.mapInstance')));
    },
    getRegionConfig: function getRegionConfig(dataType) {
      return this.dataTypes[dataType];
    },
    getRegionConfigByQualifier: function getRegionConfigByQualifier(qualifier) {
      if (qualifier === 'polygon_tracking_cut_fill') {
        return this.getRegionConfig('tracking-region');
      }
      return this.getRegionConfig('region');
    },
    areRegionPrerequisitesFullFilled: function areRegionPrerequisitesFullFilled(dataType) {
      var siteGrade = this.get('currentSiteGradeBlueprint');
      var mapData = this.get('mapData');
      var dsms = mapData.get('activeFlight.products').filterBy('productType', 'digital-surface-model').filterBy('fileExtension', 'tif');

      if (dataType === 'tracking-region' && !siteGrade) {
        return false;
      }if (dataType === 'region' && dsms.get('length') === 0) {
        return false;
      }

      return true;
    },
    getHighestNumberedRegion: function getHighestNumberedRegion(pattern) {
      var regions = this.get('draw-data');
      var arr = [];

      regions.forEach(function (region) {
        var name = region.get('name');

        if (pattern.test(name)) {
          var number = Number(name.match(pattern)[1]);
          arr[number] = number; // this guarantees self-sorting.
        }
      });

      if (!arr.length || arr.length === 0) {
        return '01';
      }

      var num = Number(arr[arr.length - 1]) + 1;
      if (num < 10) {
        num = '0' + num;
      }

      return num;
    },
    saveNewRegion: function saveNewRegion(geometry, dataType) {
      var newRecordParams = this.getNewRegionParams(geometry);
      var drawID = geometry.features[0].id;

      this.createNewRecord(newRecordParams, dataType, drawID);
      this.trackEvent('Region Create', { regionType: this.get('regionType') });
    },
    scrollToNewRegion: function scrollToNewRegion(id) {
      var context = this;

      if ($('#' + id).length) {
        context.activateRegionCard('#' + id);
        setTimeout(function () {
          if (context.dataType === 'quantity') $('[data-id="' + id + '-surface-selector"]').trigger('click');
        }, 500);
      } else {
        setTimeout(function () {
          return context.scrollToNewRegion(id);
        }, 500);
      }
    },
    createNewRecord: function createNewRecord(newRecordParams, dataType, drawID) {
      var context = this;
      var feedback = this.get('feedback');
      var mapData = this.get('mapData');
      var store = this.get('store');
      var newRecord = store.createRecord(dataType, newRecordParams);

      return newRecord.save().then(function (savedRecord) {
        mapData.set('polygons.' + savedRecord.id, drawID);
        mapData.set('polygonsByMbId.' + drawID, savedRecord.id);
        context.subscribeToRegion(savedRecord, dataType);
        context.scrollToNewRegion(savedRecord.id);

        if (dataType === 'tracking-region') {
          context.loadAndSetDrawData();
          savedRecord.set('needsEarthworkRecalculated', true);
          savedRecord.save(); // trigger earthworks after region is saved so that there is not a race condition with sockets.
        }
      }).then(function () {
        if (dataType === 'tracking-region') {
          $('#createTrackingRegionModal').modal('show');
        }

        Ember.run.later(context, function () {
          $('.regionEdit').off('focus');
          $('.regionEdit').off('blur');
          context.setInputListeners();
          context.updateRegionLabels();
          if (mapData.drawingManager.getMode() === 'simple_select') {
            mapData.changeDrawingMode(dataType);
          }
        }, 500);
      }).catch(function (error) {
        console.error('error in saving new ' + dataType, error);
        feedback.reportError({ title: 'Error', detail: 'Error saving new ' + dataType });
      });
    },
    updateRegionLabels: function updateRegionLabels() {
      var mapData = this.get('mapData');
      var source = mapData.getDrawSource();
      var geometries = this.get('draw-data');

      if (geometries) {
        var displayedGeometries = geometries.filter(function (geometry) {
          return geometry.display;
        });
        var features = displayedGeometries.map(function (geometry) {
          return geometry.get('mapboxPointFeature');
        });
        var regionsSource = { type: 'FeatureCollection', features: features };
        source.setData(regionsSource);
      }
    },
    setupEarthWorksForRegion: function setupEarthWorksForRegion(region) {
      var context = this;
      var earthworkAnalyses = region.get('earthworkAnalyses');
      var sortedEarthworkAnalyses = earthworkAnalyses.sortBy('executeAt');

      sortedEarthworkAnalyses.forEach(function (earthwork) {
        return context.subscribeToEarthwork(earthwork.get('id'), context.dataType);
      });
      region.set('earthworkAnalyses', sortedEarthworkAnalyses);
    },
    updateRegionAndEarthworkDisplay: function updateRegionAndEarthworkDisplay(regionId, dataType) {
      var context = this;
      var store = this.get('store');
      var earthworks = void 0;

      if (dataType === 'tracking-region') {
        return null;
      }

      return store.findRecord(dataType, regionId).then(function (region) {
        if (dataType === 'cross-section' || dataType === 'quantity') {
          return { report: region.get('report'), regionId: regionId };
        }

        region.get('earthworkAnalyses').forEach(function (ew) {
          ew.get('flight');
        });

        if (dataType === 'tracking-region') {
          earthworks = context.filterEarthworksByBlueprint(region.get('earthworkAnalyses'), context.get('currentSiteGradeBlueprint.id'));
        } else if (dataType === 'region') {
          earthworks = region.get('earthworkAnalyses');
        }

        return { earthworks: earthworks.sortBy('executeAt'), regionId: regionId };
      }).then(function () {
        return context.loadAndSetDrawData();
      });
    },
    updateRegion: function updateRegion(newRegion, regionId) {
      var region = this.get('draw-data').findBy('id', regionId);

      this.updateRegionProperties(region, newRegion);
      this.set('dirtyPolygons.' + regionId, region);
      this.set('unsavedPolygonsExist', true);
    },
    cancelRegionChanges: function cancelRegionChanges() {
      var dirtyPolygons = this.get('dirtyPolygons');
      var mapData = this.get('mapData');
      var context = this;

      if (this.get('unsavedPolygonsExist')) {
        Object.keys(dirtyPolygons).forEach(function (regionId) {
          var target = context.get('draw-data').findBy('id', regionId);
          if (target) {
            target.rollbackAttributes();
          }
        });

        this.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();
        if (mapData.mapInstance.getLayer('region-labels')) {
          // If we've added region labels, remove them
          mapData.mapInstance.removeLayer('region-labels');
        }
        this.set('unsavedPolygonsExist', false);
        this.set('dirtyPolygons', {});
        this.set('dirtyUpdatesQueued', 0);
      }
    },
    saveRegionChanges: function saveRegionChanges() {
      this.updateDirtyPolygons();
    },
    updateDirtyPolygons: function updateDirtyPolygons() {
      var _this = this;

      var dirtyPolygons = this.get('dirtyPolygons');
      var context = this;

      if (dirtyPolygons) {
        Object.keys(dirtyPolygons).forEach(function (regionId) {
          var target = context.get('draw-data').findBy('id', regionId);
          var polygon = dirtyPolygons[regionId];

          if (target && polygon) {
            target.set('needsEarthworkRecalculated', true);

            if (_this.get('dataType') === 'cross-section') {
              target.set('status', 'pending');
            }

            target.save().then(function () {
              if (_this.get('dataType') === 'tracking-region') {
                // weird hack because updating a tracking region was causeing earthworks to become unassociated, could not find root cause
                _this.get('store').query('earthwork-analysis', { trackingRegion: regionId }, { reload: true }).then(function () {
                  return context.triggerChartToRerenderWithUpdatedEarthwork();
                });
              }
            });
          }
        });

        context.set('unsavedPolygonsExist', false);
        context.set('dirtyPolygons', {});
        context.set('dirtyUpdatesQueued', 0);
      }
    },
    addRegionLabelLayer: function addRegionLabelLayer() {
      this.get('mapData').mapInstance.addLayer(this.getRegionLabelLayerProperties({ id: 'region-labels', source: 'draw-source' }));
    },


    // socket subscriptions
    subscribeToRegionAndEarthworkUpdates: function subscribeToRegionAndEarthworkUpdates(dataType) {
      var context = this;
      var store = this.get('store');

      io.socket.on(context.socket.toLowerCase(), function (event) {
        if (event.verb === 'addedTo' && event.attribute === 'earthworkAnalyses') {
          context.subscribeToEarthwork(event.addedId, dataType, 'new', event.id);
        } else if (event.verb === 'updated') {
          return store.push({
            data: {
              type: context.socket,
              id: event.id,
              attributes: event.data
            }
          });
        }

        return null;
      });

      io.socket.on('earthworkanalysis', function (event) {
        var region = store.peekRecord(context.socket, event.data[context.socket]);
        var earthwork = store.peekRecord('earthwork-analysis', event.id);

        if (earthwork && earthwork.get('type') === 'difference') {
          if (context.triggerChartToRerenderWithUpdatedEarthwork) {
            context.triggerChartToRerenderWithUpdatedEarthwork();
          }
        }

        if (region && event.data[context.socket]) {
          var data = [{
            type: context.socket,
            id: event.data[context.socket],
            attributes: {
              needsEarthworkRecalculated: false
            }
          }, {
            type: 'earthwork-analysis',
            id: event.id,
            attributes: event.data
          }];

          return Ember.RSVP.resolve(store.push({ data: data })).then(function () {
            if (store.peekRecord('earthwork-analysis', event.id).get('status') === 'pending') {
              return true;
            }

            if (context.get('dataType') === 'region') {
              context.setRegionsForTrueTonnageReport();
            }

            return context.updateRegionAndEarthworkDisplay(event.data[context.socket], dataType);
          }).catch(function (error) {
            console.error('error pushing to store in Earthwork Analysis socket.', error);
          });
        }

        return null;
      });
    },
    subscribeToNonEarthworkRegion: function subscribeToNonEarthworkRegion(region, dataType) {
      var context = this;

      region.addObserver('mapboxPointFeature', this, function () {
        context.updateRegionLabels();
      });
      io.socket.get('/v1/' + this.socketUri + '/' + region.id, function (body) {
        return context.processNonEarthworkFromSocket(body, dataType);
      });
    },
    processNonEarthworkFromSocket: function processNonEarthworkFromSocket(body) {
      var store = this.get('store');

      Ember.RSVP.resolve(store.pushPayload(body));
    },
    subscribeToRegion: function subscribeToRegion(region, dataType) {
      var context = this;

      region.addObserver('mapboxPointFeature', this, function () {
        context.updateRegionLabels();
      });
      io.socket.get('/v1/' + this.socketUri + '/' + region.id, function (body) {
        return context.processEarthworkFromSocket(body, dataType);
      });

      if (dataType === 'region') {
        this.get('store').query('changeDetection', { comparisonBaseRegion: region.id });
      }
    },
    subscribeToEarthwork: function subscribeToEarthwork(earthworkId, dataType, mode, regionId) {
      // todo: make sure subscriptions happen for earthwork changes
      var context = this;

      if (mode === 'new') {
        io.socket.get('/v1/earthworkAnalyses/' + earthworkId, function (body) {
          if (body.earthworkAnalysis && body.earthworkAnalysis.id) {
            return context.processEarthworkFromSocket(body, dataType);
          }if (!body.earthworkAnalysis) {
            // region was likely deleted, seems like we dont want to show error and probably need to clean up
            var region = context.get('store').peekRecord(dataType, regionId);
            if (region) {
              region.unloadRecord(region);
            }
          }

          return null;
        });
      }
    },
    processEarthworkFromSocket: function processEarthworkFromSocket(body, dataType) {
      var context = this;
      var store = this.get('store');
      var regionConfig = this.getRegionConfig(dataType);

      // pushPayload passes the response off to Ember Data to figure out how to add the relationships
      Ember.RSVP.resolve(store.pushPayload(body)).then(function () {
        if (body[regionConfig.sideload] && body[regionConfig.sideload][0] && body[regionConfig.sideload][0].id) {
          // since we're pushing and not saving, we use this method to make sure the component context updates.
          // this would be an earthwork-based socket
          context.updateRegionAndEarthworkDisplay(body[regionConfig.sideload][0].id, dataType);
        } else if (body[context.socket] && body[context.socket].id) {
          // then it's a singular from a GET
          // updating region from a region socket event
          context.updateRegionAndEarthworkDisplay(body[context.socket].id, dataType);
        }
      });
    },
    setListeners: function setListeners(dataType) {
      var _this2 = this;

      var context = this;
      var mapData = this.get('mapData');
      var feedback = this.get('feedback');
      var store = this.get('store');
      var regionConfig = this.getRegionConfig(dataType);
      var qualifier = regionConfig.listenerId;

      if (mapData.listeners.deleteKey) {
        $(document).off('keypress', mapData.listeners.deleteKey);
      }

      if (!mapData.hasListeners(qualifier)) {
        mapData.addListener('create', qualifier, function (data) {
          _this2.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();
          if (mapData.get('currentDrawingMode') === qualifier && data.features.length) {
            if (!context.areRegionPrerequisitesFullFilled(dataType)) {
              var feedbackMessage = 'There must be at least one ' + (dataType === 'tracking-region' ? 'site grade' : 'digital surface model') + ' in the system';
              mapData.drawingManager.delete(data.features[0].id);
              feedback.reportError({
                title: 'Error',
                detail: feedbackMessage + ' for ' + regionConfig.display + ' analysis.'
              });
              return false;
            }

            mapData.drawingManager.setFeatureProperty(data.features[0].id, 'observable_as', qualifier);
            mapData.drawingManager.setFeatureProperty(data.features[0].id, 'drawing_type', 'manual');

            return context.saveNewRegion(data, dataType);
          }

          return false;
        }).addListener('update', qualifier, function (data) {
          if (data.action === 'move') {
            _this2.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();
            _this2.get('regionMetaManager').displayRegionInformationLayerFromMapboxDrawHotSource(qualifier);
          }
          if (data.features.length && (mapData.get('currentDrawingMode') === qualifier || data.features[0].properties.observable_as === qualifier)) {
            return context.updateRegion(data, mapData.polygonsByMbId[data.features[0].id]);
          }
          return false;
        }).addListener('selectionchange', qualifier, function (data) {
          if (data.features.length === 0) {
            _this2.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();
          }

          if (data.features[0] && (data.features[0].properties.observable_as === 'polygon_volume_calc' || data.features[0].properties.observable_as === 'cross_section' || data.features[0].properties.observable_as === 'polygon_classification' || data.features[0].properties.observable_as === 'polygon_change_detection')) {
            _this2.get('regionMetaManager').displayRegionInformationLayerForSelectedRegion(data);
          }

          if (qualifier === 'polygon_change_detection' || qualifier === 'polygon_generic_region') {
            return false;
          }

          if (qualifier === 'polygon_classification') {
            if (data.features.length > 0) {
              var id = data.features[0].id;

              context.activateRegion(id);
            } else {
              context.activateRegion(null);
            }
            return false;
          }

          if ((data.features.length && data.features[0].geometry.type === 'Polygon' || data.features.length && data.features[0].geometry.type === 'LineString') && (mapData.get('currentDrawingMode') === qualifier || data.features[0].properties.observable_as === qualifier)) {
            if (data.features && data.features.length > 0) {
              // This conditional limits this event to just this polygon

              var mbId = data.features[0].id;
              var _id = mapData.get('polygonsByMbId.' + mbId);

              if (_id) {
                store.findRecord(dataType, _id).then(function (regionData) {
                  if (regionData) {
                    context.activateRegionCard('#' + _id);
                  }
                });
              }
            }
          }

          return null;
        }).addListener('modechange', qualifier, function (mode) {
          if (mode.mode === 'simple_select') {
            _this2.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();
          }

          if ((mapData.get('currentDrawingMode') === qualifier || qualifier === 'work-activity' && mapData.get('currentDrawingMode').indexOf('work_activity') !== -1) && mode.mode === 'simple_select') {
            mapData.changeDrawingMode(mapData.get('currentDrawingMode')); /* Unintuitive naming, but this sets both the named drawing mode and the map's real drawing mode */
          }
        }).addListener('render', qualifier, function () {
          if (mapData.get('currentDrawingMode') === qualifier || mapData.drawingManager.getMode() === 'direct_select') {
            _this2.get('regionMetaManager').displayRegionInformationLayerFromMapboxDrawHotSource(qualifier);
          }
        });
      }

      /* Because these are jQuery events and applied per-element we essentially have to recreate them every time the polygons are drawn */
      $('.regionEdit').off('focus');
      $('.regionEdit').off('blur');

      this.setInputListeners();
    },


    listeners: {},

    selectAndZoomToRegionOnMap: function selectAndZoomToRegionOnMap(id) {
      var context = this;
      var mapData = this.get('mapData');
      var mbId = mapData.get('polygons.' + id);

      if (context.get('lastSelectedId') === id) return;

      context.set('lastSelectedId', id);
      context.activateRegionCard('#' + id);

      if (mbId) {
        // if this is a polygon, we can do polygon things. If not, it's probably density.
        var polygon = mapData.drawingManager.get(mbId);

        if (!context.get('isReadOnly') && !polygon.properties.isLocked) {
          mapData.set('selectedPolygons.' + id);
          mapData.changeDrawingMode('select', { featureId: mbId });
        }

        var boundaryPoints = polygon.geometry.coordinates;
        if (polygon.geometry.type !== 'LineString') {
          var _boundaryPoints = boundaryPoints;

          var _boundaryPoints2 = _slicedToArray(_boundaryPoints, 1);

          boundaryPoints = _boundaryPoints2[0];
        }
        mapData.fitMapToPoints(boundaryPoints, 0, 1);
      }
    },
    setInputListeners: function setInputListeners() {
      var context = this;
      var mapData = this.get('mapData');
      // these next values work for regions, but not tracking regions
      var selectorForListener = '.regionElement';
      var eventForListener = 'click';
      var idAttrInSelector = 'id';

      this.listeners.regionSelected = $(selectorForListener).on(eventForListener, function (event) {
        var didUserClickOnBootStrapSelectBox = false;
        if (event.originalEvent.path) {
          event.originalEvent.path.forEach(function (element) {
            if (element.className && element.className.indexOf('bootstrap-select') !== -1) didUserClickOnBootStrapSelectBox = true;
          });
        }

        if (didUserClickOnBootStrapSelectBox) return;
        if (event.target.className.indexOf('display-toggle') !== -1) return;

        context.selectAndZoomToRegionOnMap($(event.currentTarget).attr(idAttrInSelector));
      });

      this.listeners.regionBlurred = $('.regionEdit').on('blur', function (event) {
        var domTarget = $(event.currentTarget);
        var id = domTarget.attr('name');
        var mbId = mapData.get('polygons.' + id);
        var regionId = void 0;var targetRegion = void 0;

        // TODO: refactor to compress this further

        if (mbId) {
          regionId = id;
          targetRegion = context.get('draw-data').findBy('id', regionId);
          targetRegion.set('name', domTarget.val());
        } else {
          regionId = String(id).replace('-density', '');
          targetRegion = context.get('draw-data').findBy('id', regionId);
          targetRegion.set('density', domTarget.val());
        }

        targetRegion.set('needsEarthworkRecalculated', false);
        targetRegion.save().then(function () {
          if (mbId) {
            context.get('store').query('earthwork-analysis', { trackingRegion: regionId }); // for some reason saving tracking regions could result in sails returning a subste of earthworks, causing them to disappear from the display. This reloads them.
          }
        }); // this triggers the PUT to the server.
      });
    },


    /**
     * cleanUpRegionUI
     *
     * @description :: Deletes polygons and clears dom listeners when switching
     *              :: between flights.
     */

    cleanUpRegionUI: function cleanUpRegionUI(dataType) {
      var _this3 = this;

      var regionConfig = this.getRegionConfig(dataType);
      var qualifier = regionConfig.listenerId;
      var mapData = this.get('mapData');
      var context = this;

      this.cancelRegionChanges();
      if (mapData.get('currentDrawingMode') === qualifier) {
        mapData.changeDrawingMode('move');
      }

      $('.regionEdit').off('focus');
      $('.regionEdit').off('blur');

      // Clear geojson observers
      var regions = this.get('draw-data');
      if (regions) {
        regions.forEach(function (region) {
          try {
            region.removeObserver('mapboxPointFeature', _this3, function () {
              context.updateRegionLabels();
            });
          } catch (e) {
            /* Pass through */
          }
        });
      }

      if (mapData.mapInstance.getLayer('region-labels')) {
        // If we've added region labels, remove them
        mapData.mapInstance.removeLayer('region-labels');
      }

      var layerName = this.get('activeSite.id') + '-cross-sections';
      if (mapData.mapInstance.getLayer(layerName)) {
        mapData.mapInstance.removeLayer(layerName);
        mapData.mapInstance.removeSource(layerName);
      }

      this.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();

      return mapData.cleanUp(this.socket);
    },
    setDrawingModeForNewRegions: function setDrawingModeForNewRegions(drawingMode) {
      var mapData = this.get('mapData');

      if (mapData.get('currentDrawingMode') === drawingMode) {
        mapData.changeDrawingMode('move');

        // TODO wtf is this, it was used in tracking regions nut no where else
        // mapData.revertListeningMode();
        this.get('regionMetaManager').removeDrawingRegionHelperInformationLayer();
      } else {
        mapData.setListeningMode(drawingMode);
        mapData.changeDrawingMode(drawingMode);
      }
    }
  });
});