define('webui/mixins/true-tonnage-chart-data', ['exports', 'webui/mixins/string-formatting'], function (exports, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_stringFormatting.default, {
    showFlight: false,
    dataPanelService: Ember.inject.service('data-panel'),
    getFlightsWithTrueTonnageReport: function getFlightsWithTrueTonnageReport(siteId) {
      var store = this.get('store');
      var allFlights = store.peekAll('flight').filter(function (flight) {
        return flight.get('site.id') === siteId;
      });
      var flightsWithTrueTonnageReport = allFlights.filter(function (flight) {
        return flight.get('trueTonnageReport.trueTonnageReportStep.code') === 'complete';
      });
      return flightsWithTrueTonnageReport.filter(function (flight) {
        var millisecondsInAYear = 365 * 24 * 60 * 60 * 1000;
        var oneYearAgo = new Date().getTime() - millisecondsInAYear;
        var flightDate = new Date(flight.get('executeAt')).getTime();
        return flightDate >= oneYearAgo;
      });
    },
    getFlightsWithTier2TrueTonnageReport: function getFlightsWithTier2TrueTonnageReport(siteId) {
      var store = this.get('store');
      var allFlights = store.peekAll('flight').filter(function (flight) {
        return flight.get('site.id') === siteId;
      });
      var flightsWithTrueTonnageReport = allFlights.filter(function (flight) {
        return flight.get('trueTonnageReport.trueTonnageReportStep.code') === 'complete';
      });
      return flightsWithTrueTonnageReport;
    },
    filterMostRecentRegionRevisions: function filterMostRecentRegionRevisions(regions) {
      var mostRecentRegionRevisions = {};
      var sortedRegionRevisions = regions.sortBy('revisionDate').reverse();
      return sortedRegionRevisions.filter(function (region) {
        var regionId = region.get('region');
        if (mostRecentRegionRevisions[regionId]) {
          return false;
        }
        mostRecentRegionRevisions[regionId] = region;
        return true;
      });
    },
    getTonnages: function getTonnages(excludedMaterials, excludedRegions, trueTonnageReport, materialId) {
      var materials = trueTonnageReport.get('trueTonnageReportMaterials');
      var regions = this.filterMostRecentRegionRevisions(trueTonnageReport.get('trueTonnageReportRegions'));

      materials = materials.filter(function (material) {
        return excludedMaterials.indexOf(material.get('material')) === -1;
      }); // temporarily excluded materials
      regions = regions.filter(function (region) {
        return excludedMaterials.indexOf(region.get('material')) === -1;
      }).filter(function (region) {
        return excludedRegions.indexOf(region.get('region')) === -1;
      }); // temporarily excluded regions

      materials = materials.filter(function (material) {
        return !material.get('hideFromTrueTonnageReport');
      }); // permanently excluded materials
      regions = regions.filter(function (region) {
        return materials.filter(function (material) {
          return material.get('material') === region.get('material');
        }).length;
      }).filter(function (region) {
        return !region.get('hideFromTrueTonnageReport');
      }); // permanently excluded regions

      if (materialId) {
        materials = materials.filter(function (material) {
          return material.get('material') === materialId;
        });
        regions = regions.filter(function (region) {
          return region.get('material') === materialId;
        });
      }

      var expectedTonnage = materials.reduce(function (accumulator, material) {
        var materialRegions = regions.filter(function (region) {
          return region.get('material') === material.get('material');
        });
        if (materialRegions.length === 0) return accumulator;

        return accumulator + (material.get('plannedTonnage') || 0);
      }, 0);

      var actualTonnage = regions.reduce(function (accumulator, region) {
        var regionTonnage = region.get('density') * Math.abs(region.get('cubicYardsCut') + region.get('cubicYardsFill'));
        return accumulator + (regionTonnage || 0);
      }, 0);

      return { expectedTonnage: expectedTonnage, actualTonnage: actualTonnage };
    },
    getTrueTonnageChartData: function getTrueTonnageChartData(excludedMaterials, excludedRegions, siteId) {
      var _this = this;

      var materialId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      var labels = [];
      var expectedTonnages = [];
      var actualTonnages = [];
      var flightsWithTrueTonnageReport = this.getFlightsWithTrueTonnageReport(siteId);
      flightsWithTrueTonnageReport.sortBy('executeAt').forEach(function (flight) {
        var _getTonnages = _this.getTonnages(excludedMaterials, excludedRegions, flight.get('trueTonnageReport'), materialId),
            expectedTonnage = _getTonnages.expectedTonnage,
            actualTonnage = _getTonnages.actualTonnage;

        labels.push(_this.dateString(flight.get('executeAt'), false, true));
        expectedTonnages.push(expectedTonnage);
        actualTonnages.push(actualTonnage);
      });

      return { labels: labels, expectedTonnages: expectedTonnages, actualTonnages: actualTonnages };
    },
    getShowFlightDates: function getShowFlightDates() {
      // this.toggleProperty('showFlightDates');
      Ember.$('#hide-elem').attr('style', 'display: inline-block !important; width: fit-content; padding-right: 10px;');
      Ember.$('#hide-element').attr('style', 'display: inline-block !important; width: fit-content;');
    },


    // Nayan sanadi added new code for tier2 Analytics
    getTrueTonnageDifferenceReport: function getTrueTonnageDifferenceReport(selectedFlights, excludedMaterials, excludedRegions) {
      var _this2 = this;

      var materialId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      var labels = [];
      var trueTonnageDifference = [];
      var difference = 0;
      var totalTTR = 0;
      var totalOH = 0;
      var totalDifference = 0;
      selectedFlights.sortBy('executeAt').forEach(function (flight) {
        var _getTonnages2 = _this2.getTonnages(excludedMaterials, excludedRegions, flight.get('trueTonnageReport'), materialId),
            expectedTonnage = _getTonnages2.expectedTonnage,
            actualTonnage = _getTonnages2.actualTonnage;

        difference = actualTonnage - expectedTonnage;
        totalTTR += actualTonnage;
        totalOH += expectedTonnage;
        trueTonnageDifference.push({
          meta: 'OH#: ' + expectedTonnage.toFixed(2) + ' Ton TT#: ' + actualTonnage.toFixed(2) + ' Ton',
          value: difference.toFixed(2)
        });
        labels.push(_this2.dateString(flight.get('executeAt'), false, true));
      });

      if (labels.length > 1) {
        var avgTotalTTR = totalTTR / labels.length;
        var avgTotalOH = totalOH / labels.length;
        totalDifference = avgTotalTTR - avgTotalOH;
        var differenceDateRange = labels[0] + ' to ' + labels.slice(-1).pop();
        var totalDifferenceObj = {
          meta: 'OH#:' + avgTotalOH.toFixed(2) + ' Ton TT#:' + avgTotalTTR.toFixed(2) + ' Ton',
          value: totalDifference.toFixed(2)
        };
        trueTonnageDifference.push(totalDifferenceObj);
        labels.push(differenceDateRange);
      }

      labels.reverse();
      trueTonnageDifference.reverse();

      return { labels: labels, trueTonnageDifference: trueTonnageDifference };
    },
    getTrueTonnageDifferenceTableReport: function getTrueTonnageDifferenceTableReport(selectedFlights, excludedMaterials, excludedRegions) {
      var _this3 = this;

      var materialId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      var dateWiseArray = [];
      selectedFlights.sortBy('executeAt').forEach(function (flight) {
        var materialTableArray = [];
        var actualTonnageTotal = 0;
        var expectedTonnageTotal = 0;
        var materials = flight.get('trueTonnageReport.trueTonnageReportMaterials');

        if (materialId) {
          materials = materials.filter(function (material) {
            return material.get('material') === materialId;
          });
        }

        materials.forEach(function (material) {
          var _getMaterialTonnages = _this3.getMaterialTonnages(excludedMaterials, excludedRegions, flight.get('trueTonnageReport'), material.get('material')),
              expectedTonnage = _getMaterialTonnages.expectedTonnage,
              actualTonnage = _getMaterialTonnages.actualTonnage;

          var trueTonnageDifference = actualTonnage - expectedTonnage;
          var inventory_variance = 0;
          if (trueTonnageDifference !== 0 && actualTonnage !== 0) {
            inventory_variance = parseFloat((trueTonnageDifference / actualTonnage * 100).toFixed(2));
          }
          actualTonnageTotal += actualTonnage;
          expectedTonnageTotal += expectedTonnage;
          var materialArray = {
            trueTonnageReport: material.get('trueTonnageReport'),
            showVarianceWarning: inventory_variance >= _this3.get('dataPanelService.tonnageVarianceWarningThreshold'),
            material: material.get('material'),
            material_name: material.get('name'),
            OH: expectedTonnage,
            TTR: actualTonnage,
            trueTonnageDifference: trueTonnageDifference,
            inventory_variance: inventory_variance + '%'
          };
          materialTableArray.push(materialArray);
        });
        var totalTrueTonnageDifference = actualTonnageTotal - expectedTonnageTotal;
        var total_inventory_variance = 0;

        if (totalTrueTonnageDifference !== 0 && actualTonnageTotal !== 0) {
          total_inventory_variance = parseFloat((totalTrueTonnageDifference / actualTonnageTotal * 100).toFixed(2));
        }

        var totalObj = {
          trueTonnageReport: '',
          showVarianceWarning: total_inventory_variance >= _this3.get('dataPanelService.tonnageVarianceWarningThreshold'),
          material: 'total',
          material_name: 'Total',
          OH: expectedTonnageTotal,
          TTR: actualTonnageTotal,
          trueTonnageDifference: totalTrueTonnageDifference,
          inventory_variance: total_inventory_variance + '%'
        };
        materialTableArray.push(totalObj);

        var finalMaterialArray = {
          date: _this3.dateString(flight.get('executeAt'), false, true),
          materialData: materialTableArray
        };
        dateWiseArray.push(finalMaterialArray);
      });

      if (dateWiseArray.length > 1) {
        var differenceDateRange = dateWiseArray[0].date + ' to ' + dateWiseArray.slice(-1).pop().date;
        var combinedData = [];

        dateWiseArray.forEach(function (entry) {
          entry.materialData.forEach(function (materialEntry) {
            var material = materialEntry.material,
                material_name = materialEntry.material_name,
                OH = materialEntry.OH,
                TTR = materialEntry.TTR;

            var combinedMaterial = combinedData.find(function (obj) {
              return obj.material === material;
            });

            if (combinedMaterial) {
              combinedMaterial.OH += OH;
              combinedMaterial.TTR += TTR;
            } else {
              combinedData.push({
                material: material,
                material_name: material_name,
                OH: OH,
                TTR: TTR
              });
            }
          });
        });

        combinedData.forEach(function (data) {
          // Calculate the average OH by dividing the sum by the length
          var avgTotalOH = data.OH / dateWiseArray.length;
          var avgTotalTTR = data.TTR / dateWiseArray.length;
          // Calculate trueTonnageDifference as the difference between averages
          var trueTonnageDifference = avgTotalTTR - avgTotalOH;
          var percentage = 0;
          if (trueTonnageDifference !== 0 && avgTotalTTR !== 0) {
            percentage = parseFloat((trueTonnageDifference / avgTotalTTR * 100).toFixed(2));
          }
          data.trueTonnageDifference = trueTonnageDifference;
          data.showVarianceWarning = percentage >= _this3.get('dataPanelService.tonnageVarianceWarningThreshold');
          data.TTR = avgTotalTTR;
          data.OH = avgTotalOH;
          data.inventory_variance = percentage + '%';
        });

        var CombinedArray = {
          date: differenceDateRange,
          materialData: combinedData
        };
        dateWiseArray.push(CombinedArray);
      }

      // Iterate through each date entry and sort materialData arrays
      dateWiseArray.forEach(function (dateEntry) {
        dateEntry.materialData.sort(function (a, b) {
          var nameA = a.material_name.toLowerCase();
          var nameB = b.material_name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      });

      dateWiseArray.forEach(function (dateRange) {
        _this3.moveTotalToLastPosition(dateRange);
      });

      dateWiseArray.forEach(function (entry) {
        entry.materialData.forEach(function (materialEntry) {
          materialEntry.OH = _this3.numberWithCommas(materialEntry.OH.toFixed(2));
          materialEntry.TTR = _this3.numberWithCommas(materialEntry.TTR.toFixed(2));
          materialEntry.trueTonnageDifference = _this3.numberWithCommas(materialEntry.trueTonnageDifference.toFixed(2));
        });
      });

      return { dateWiseArray: dateWiseArray };
    },
    getTrueTonnageDerivateReport: function getTrueTonnageDerivateReport(selectedFlights, excludedMaterials, excludedRegions) {
      var _this4 = this;

      var materialId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      var labels = [];
      var dateArray = [];
      selectedFlights.sortBy('executeAt').forEach(function (flight) {
        var _getTonnages3 = _this4.getTonnages(excludedMaterials, excludedRegions, flight.get('trueTonnageReport'), materialId),
            expectedTonnage = _getTonnages3.expectedTonnage,
            actualTonnage = _getTonnages3.actualTonnage;

        dateArray.push({
          flight: _this4.dateString(flight.get('executeAt'), false, true),
          TTR: actualTonnage,
          OH: expectedTonnage
        });
      });

      var trueTonnageDerivativeTTR = [];
      var trueTonnageDerivativeOH = [];
      var totlDifferenceofOH = 0;
      var totlDifferenceofTTR = 0;
      var toolTipArray = [];
      var OHTTRDiff = 0;

      for (var i = 0; i < dateArray.length - 1; i += 1) {
        var startDate = dateArray[i].flight;
        var endDate = dateArray[i + 1].flight;
        var TTRDifference = dateArray[i].TTR - dateArray[i + 1].TTR;
        var OHDifference = dateArray[i].OH - dateArray[i + 1].OH;
        OHTTRDiff = TTRDifference - OHDifference;

        var dateRange = startDate + ' to ' + endDate;

        trueTonnageDerivativeTTR.push({
          value: TTRDifference.toFixed(2)
        });
        trueTonnageDerivativeOH.push({
          value: OHDifference.toFixed(2)
        });
        toolTipArray.push({
          label: dateRange,
          meta: 'OH#: ' + OHDifference.toFixed(2) + '<br>TTR#: ' + TTRDifference.toFixed(2) + '<br>Difference#: ' + OHTTRDiff.toFixed(2)
        });
        labels.push(dateRange);
      }
      // Iterate through the array and calculate the total OH & TTR values
      dateArray.forEach(function (material) {
        totlDifferenceofTTR += material.TTR; // Accumulate TTR values
        totlDifferenceofOH += material.OH; // Accumulate OH values
      });

      if (dateArray.length > 2) {
        var differenceDateRange = dateArray[0].flight + ' to ' + dateArray.slice(-1).pop().flight;
        var totalDifference = totlDifferenceofTTR / dateArray.length - totlDifferenceofOH / dateArray.length;
        var totalTTRDiff = {
          value: (totlDifferenceofTTR / dateArray.length).toFixed(2)
        };
        var totalOHDiff = {
          value: (totlDifferenceofOH / dateArray.length).toFixed(2)
        };
        var finalmeta = {
          label: differenceDateRange,
          meta: 'OH#:' + (totlDifferenceofOH / dateArray.length).toFixed(2) + '<br>TTR#: ' + (totlDifferenceofTTR / dateArray.length).toFixed(2) + '<br>Difference#: ' + totalDifference.toFixed(2)
        };

        labels.push(differenceDateRange);
        trueTonnageDerivativeOH.push(totalOHDiff);
        trueTonnageDerivativeTTR.push(totalTTRDiff);
        toolTipArray.push(finalmeta);
      }

      return {
        labels: labels,
        trueTonnageDerivativeTTR: trueTonnageDerivativeTTR,
        trueTonnageDerivativeOH: trueTonnageDerivativeOH,
        toolTipArray: toolTipArray
      };
    },
    getTrueTonnageDerivativeTableReport: function getTrueTonnageDerivativeTableReport(selectedFlights, excludedMaterials, excludedRegions) {
      var _this5 = this;

      var materialId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      var dateWiseArray = [];
      selectedFlights.sortBy('executeAt').forEach(function (flight) {
        var materialTableArray = [];
        var expectedTonnageTotal = 0;
        var actualTonnageTotal = 0;
        var materials = flight.get('trueTonnageReport.trueTonnageReportMaterials');

        if (materialId) {
          materials = flight.get('trueTonnageReport.trueTonnageReportMaterials').filter(function (material) {
            return material.get('material') === materialId;
          });
        }

        materials.forEach(function (material) {
          var _getMaterialTonnages2 = _this5.getMaterialTonnages(excludedMaterials, excludedRegions, flight.get('trueTonnageReport'), material.get('material')),
              expectedTonnage = _getMaterialTonnages2.expectedTonnage,
              actualTonnage = _getMaterialTonnages2.actualTonnage;

          expectedTonnageTotal += expectedTonnage;
          actualTonnageTotal += actualTonnage;

          var materialArray = {
            material: material.get('material'),
            material_name: material.get('name'),
            OH: expectedTonnage,
            TTR: actualTonnage
          };

          materialTableArray.push(materialArray);
        });

        var totalObj = {
          material: 'total',
          material_name: 'Total',
          OH: expectedTonnageTotal,
          TTR: actualTonnageTotal
        };
        materialTableArray.push(totalObj);

        var finalMaterialArray = {
          date: _this5.dateString(flight.get('executeAt'), false, true),
          materialData: materialTableArray
        };
        dateWiseArray.push(finalMaterialArray);
      });

      var dateRangeArray = [];
      var cumulativeResultsMap = new Map();

      var _loop = function _loop(i) {
        var startDate = dateWiseArray[i].date;
        var endDate = dateWiseArray[i + 1].date;
        var dateRange = startDate + ' to ' + endDate;

        var dateRangeObj = {
          date: dateRange,
          materialData: []
        };
        dateWiseArray[i].materialData.forEach(function (material) {
          var cumulativematerialId = material.material;
          var materialname = material.material_name;

          var nextMaterialData = dateWiseArray[i + 1].materialData.find(function (nextMaterial) {
            return nextMaterial.material === cumulativematerialId;
          });

          if (!nextMaterialData) {
            var OH_difference = parseInt(material.OH, 10);
            var TTR_difference = parseInt(material.TTR, 10);
            var TTR_minus_OH = TTR_difference - OH_difference;
            var inventory_variance = 0;

            if (TTR_minus_OH !== 0 && TTR_difference !== 0) {
              inventory_variance = parseFloat((TTR_minus_OH / TTR_difference * 100).toFixed(2));
            }

            dateRangeObj.materialData.push({
              material: cumulativematerialId,
              material_name: materialname,
              OH: parseInt(material.OH, 10),
              TTR: parseInt(material.TTR, 10),
              TTR_minus_OH: TTR_minus_OH,
              inventory_variance: inventory_variance + '%',
              showVarianceWarning: inventory_variance >= _this5.get('dataPanelService.tonnageVarianceWarningThreshold')
            });
          } else {
            var _OH_difference = parseInt(material.OH, 10) - parseInt(nextMaterialData.OH, 10);
            var _TTR_difference = parseInt(material.TTR, 10) - parseInt(nextMaterialData.TTR, 10);
            var _TTR_minus_OH = _TTR_difference - _OH_difference;
            var _inventory_variance = 0;
            if (_TTR_minus_OH !== 0 && _TTR_difference !== 0) {
              _inventory_variance = parseFloat((_TTR_minus_OH / _TTR_difference * 100).toFixed(2));
            }

            dateRangeObj.materialData.push({
              material: cumulativematerialId,
              material_name: materialname,
              OH: _OH_difference,
              TTR: _TTR_difference,
              TTR_minus_OH: _TTR_minus_OH,
              inventory_variance: _inventory_variance + '%',
              showVarianceWarning: _inventory_variance >= _this5.get('dataPanelService.tonnageVarianceWarningThreshold')
            });
          }
        });
        // Add materials from next object's materialData that don't exist in current object's materialData
        dateWiseArray[i + 1].materialData.forEach(function (material) {
          var cumulativematerialId = material.material;
          var materialname = material.material_name;

          var existingMaterialData = dateWiseArray[i].materialData.find(function (existingMaterial) {
            return existingMaterial.material === cumulativematerialId;
          });

          if (!existingMaterialData) {
            var OH_difference = parseInt(material.OH, 10);
            var TTR_difference = parseInt(material.TTR, 10);
            var TTR_minus_OH = TTR_difference - OH_difference;
            var inventory_variance = 0;
            if (TTR_minus_OH !== 0 && TTR_difference !== 0) {
              inventory_variance = parseFloat(TTR_minus_OH / TTR_difference * 100).toFixed(2);
            }

            dateRangeObj.materialData.push({
              material: cumulativematerialId,
              material_name: materialname,
              OH: OH_difference,
              TTR: TTR_difference,
              TTR_minus_OH: TTR_minus_OH,
              inventory_variance: inventory_variance + '%',
              showVarianceWarning: inventory_variance >= _this5.get('dataPanelService.tonnageVarianceWarningThreshold')
            });
          }
        });

        dateRangeArray.push(dateRangeObj);
      };

      for (var i = 0; i < dateWiseArray.length - 1; i += 1) {
        _loop(i);
      }

      dateWiseArray.forEach(function (dateWise) {
        dateWise.materialData.forEach(function (material) {
          var cumulativematerialId = material.material;
          var materialname = material.material_name;
          if (!cumulativeResultsMap.has(cumulativematerialId)) {
            cumulativeResultsMap.set(cumulativematerialId, {
              material_name: materialname,
              cumulative_OH_difference: material.OH,
              cumulative_TTR_difference: material.TTR,
              cumulative_TTR_minus_OH: 0,
              cumulative_inventory_variance: 0
            });
          } else {
            var materialResult = cumulativeResultsMap.get(cumulativematerialId);
            materialResult.cumulative_OH_difference += material.OH;
            materialResult.cumulative_TTR_difference += material.TTR;
            materialResult.cumulative_TTR_minus_OH += 0;
            materialResult.cumulative_inventory_variance += 0;
            cumulativeResultsMap.set(cumulativematerialId, materialResult);
          }
        });
      });

      if (dateWiseArray.length > 2) {
        var cumulativeResultsArray = [];
        cumulativeResultsMap.forEach(function (materialResult, commanmaterialId) {
          var cumulative_inventory_variance_percent = 0;
          var cumulative_TTR_minus_OH = materialResult.cumulative_TTR_difference / dateWiseArray.length - materialResult.cumulative_OH_difference / dateWiseArray.length;
          if (cumulative_TTR_minus_OH !== 0 && materialResult.cumulative_TTR_difference / dateWiseArray.length !== 0) {
            cumulative_inventory_variance_percent = parseFloat((cumulative_TTR_minus_OH / (materialResult.cumulative_TTR_difference / dateWiseArray.length) * 100).toFixed(2));
          }
          cumulativeResultsArray.push({
            material: commanmaterialId,
            material_name: materialResult.material_name,
            OH: materialResult.cumulative_OH_difference / dateWiseArray.length,
            TTR: materialResult.cumulative_TTR_difference / dateWiseArray.length,
            TTR_minus_OH: cumulative_TTR_minus_OH,
            inventory_variance: cumulative_inventory_variance_percent + '%',
            showVarianceWarning: cumulative_inventory_variance_percent >= _this5.get('dataPanelService.tonnageVarianceWarningThreshold')
          });
        });

        var differenceDateRange = dateWiseArray[0].date + '  to  ' + dateWiseArray.slice(-1).pop().date;
        dateRangeArray.push({
          date: differenceDateRange,
          materialData: cumulativeResultsArray
        });
      }

      // Iterate through each date entry and sort materialData arrays
      dateRangeArray.forEach(function (dateEntry) {
        dateEntry.materialData.sort(function (a, b) {
          var nameA = a.material_name.toLowerCase();
          var nameB = b.material_name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      });

      dateRangeArray.forEach(function (dateRange) {
        _this5.moveTotalToLastPosition(dateRange);
      });

      dateRangeArray.forEach(function (entry) {
        entry.materialData.forEach(function (materialEntry) {
          materialEntry.OH = _this5.numberWithCommas(materialEntry.OH.toFixed(2));
          materialEntry.TTR = _this5.numberWithCommas(materialEntry.TTR.toFixed(2));
          materialEntry.TTR_minus_OH = _this5.numberWithCommas(materialEntry.TTR_minus_OH.toFixed(2));
        });
      });

      return { dateRangeArray: dateRangeArray };
    },
    moveTotalToLastPosition: function moveTotalToLastPosition(obj) {
      var materialData = obj.materialData;

      var totalIndex = materialData.findIndex(function (material) {
        return material.material === 'total';
      });

      if (totalIndex !== -1) {
        var totalMaterial = materialData.splice(totalIndex, 1)[0];
        materialData.push(totalMaterial);
      }
    },
    getFirstLastFlightsWithTrueTonnageReport: function getFirstLastFlightsWithTrueTonnageReport(siteId, firsFlightDate, lastFigthDate) {
      var objLastFigthDate = new Date(lastFigthDate);
      objLastFigthDate = objLastFigthDate.setDate(objLastFigthDate.getDate() + 1);
      var store = this.get('store');
      var allFlights = store.peekAll('flight').filter(function (flight) {
        return flight.get('site.id') === siteId;
      });
      var filterFligth = allFlights.filter(function (item) {
        return new Date(item.get('executeAt')) >= new Date(firsFlightDate) && new Date(item.get('executeAt')) < objLastFigthDate;
      });
      var stepNotCompletedflightsWithTrueTonnageReport = filterFligth.filter(function (flight) {
        return flight.get('trueTonnageReport.trueTonnageReportStep.code') !== 'complete';
      });
      var flightsWithTrueTonnageReport = filterFligth.filter(function (flight) {
        return flight.get('trueTonnageReport.trueTonnageReportStep.code') === 'complete';
      });
      return { stepNotCompletedflightsWithTrueTonnageReport: stepNotCompletedflightsWithTrueTonnageReport, flightsWithTrueTonnageReport: flightsWithTrueTonnageReport };
    },
    getMaterialTonnages: function getMaterialTonnages(excludedMaterials, excludedRegions, trueTonnageReport, materialId) {
      var materials = trueTonnageReport.get('trueTonnageReportMaterials');
      var regions = this.filterMostRecentRegionRevisions(trueTonnageReport.get('trueTonnageReportRegions'));

      materials = materials.filter(function (material) {
        return excludedMaterials.indexOf(material.get('material')) === -1;
      }); // temporarily excluded materials
      regions = regions.filter(function (region) {
        return excludedMaterials.indexOf(region.get('material')) === -1;
      }).filter(function (region) {
        return excludedRegions.indexOf(region.get('region')) === -1;
      }); // temporarily excluded regions

      materials = materials.filter(function (material) {
        return !material.get('hideFromTrueTonnageReport');
      }); // permanently excluded materials
      regions = regions.filter(function (region) {
        return materials.filter(function (material) {
          return material.get('material') === region.get('material');
        }).length;
      }).filter(function (region) {
        return !region.get('hideFromTrueTonnageReport');
      }); // permanently excluded regions

      if (materialId) {
        materials = materials.filter(function (material) {
          return material.get('material') === materialId;
        });
        regions = regions.filter(function (region) {
          return region.get('material') === materialId;
        });
      }

      var expectedTonnage = materials.reduce(function (accumulator, material) {
        var materialRegions = regions.filter(function (region) {
          return region.get('material') === material.get('material');
        });
        if (materialRegions.length === 0) return accumulator;

        return accumulator + (material.get('plannedTonnage') || 0);
      }, 0);

      var actualTonnage = regions.reduce(function (accumulator, region) {
        var regionTonnage = region.get('density') * Math.abs(region.get('cubicYardsCut') + region.get('cubicYardsFill'));
        return accumulator + (regionTonnage || 0);
      }, 0);

      return { expectedTonnage: expectedTonnage, actualTonnage: actualTonnage };
    }
  });
});