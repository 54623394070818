define('webui/react/FileManagerList', ['exports', 'npm:react'], function (exports, _npmReact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var FileManagerList = function (_React$Component) {
    _inherits(FileManagerList, _React$Component);

    /* ====== Lifecycle events ====== */

    function FileManagerList(props) {
      _classCallCheck(this, FileManagerList);

      var _this = _possibleConstructorReturn(this, (FileManagerList.__proto__ || Object.getPrototypeOf(FileManagerList)).call(this, props));

      _this.state = { isDragging: false }; /* Not really any use of state here, mostly just a separate component for organizational reasons */

      _this.addNewFile = _this.addNewFile.bind(_this);
      _this.cancelNewFile = _this.cancelNewFile.bind(_this);
      _this.updateOrder = _this.updateOrder.bind(_this);
      _this.updateFileStatus = _this.updateFileStatus.bind(_this);
      _this.updateHover = _this.updateHover.bind(_this);
      _this.updateMove = _this.updateMove.bind(_this);
      _this.toggleDrag = _this.toggleDrag.bind(_this);
      return _this;
    }

    _createClass(FileManagerList, [{
      key: 'handleEnter',
      value: function handleEnter() {
        this.props.updateHover('top');
      }
    }, {
      key: 'toggleDrag',
      value: function toggleDrag(isDragging) {
        this.setState({ isDragging: isDragging });
      }
    }, {
      key: 'updateMove',
      value: function updateMove(key) {
        this.props.updateMove(key);
      }
    }, {
      key: 'updateHover',
      value: function updateHover(key) {
        this.props.updateHover(key);
      }
    }, {
      key: 'updateFileStatus',
      value: function updateFileStatus(key, status, count) {
        this.props.updateFileStatus(key, status, count);
      }
    }, {
      key: 'updateOrder',
      value: function updateOrder(from) {
        this.props.updateOrder(from);
      }
    }, {
      key: 'addNewFile',
      value: function addNewFile() {
        this.props.onChangeNewFileMode(true);
      }
    }, {
      key: 'cancelNewFile',
      value: function cancelNewFile() {
        this.props.onChangeNewFileMode(false);
      }
    }, {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var component = this;
        var site = this.props.site;

        var namedFileType = this.props.mode.toUpperCase();
        var FilePlugin = this.props.plugin.element;
        var showTop = this.props.lastHover === 'top';

        var buttonText = void 0;var headerText = void 0;var subText = void 0;
        switch (namedFileType) {
          case 'DSM':
            buttonText = '+ Add New Comparison Surface';
            headerText = 'My Comparison Surfaces';
            subText = 'compatible with .XML using triangle geometry or .TIF';
            break;
          case 'DXF':
            buttonText = '+ Add New DXF';
            headerText = 'My DXFs';
            break;
          case 'REGION-DXF':
            buttonText = 'Import Regions From DXF';
            headerText = 'Imported DXFs';
            break;
          default:
            break;
        }

        var fetchingFiles = _npmReact.default.createElement(
          'div',
          { className: 'placeholder row' },
          namedFileType === 'REGION-DXF' ? 'Loading DXFs...' : 'Loading ' + namedFileType + 's...'
        );
        var notFetchingFiles = _npmReact.default.createElement(
          'div',
          { className: 'placeholder row' },
          namedFileType === 'REGION-DXF' ? 'You have no DXFs imported yet.' : 'You have no ' + namedFileType + 's uploaded yet.'
        );
        var fileDisplay = component.props.fetchingFiles ? fetchingFiles : notFetchingFiles;

        return _npmReact.default.createElement(
          'div',
          { className: 'file-manager-list file-type-' + this.props.mode + '-list' },
          _npmReact.default.createElement(
            'h2',
            null,
            headerText
          ),
          _npmReact.default.createElement(
            'div',
            { className: 'button-row' },
            _npmReact.default.createElement(
              'button',
              { className: 'btn accent', type: 'button', disabled: component.props.newFileMode, onClick: component.addNewFile },
              buttonText
            ),
            _npmReact.default.createElement(
              'span',
              { style: { paddingLeft: '10px', fontStyle: 'italic' } },
              subText
            )
          ),
          _npmReact.default.createElement(
            'div',
            { className: 'file-list col-sm-12 col-md-10 col-lg-8' },
            _npmReact.default.createElement(
              'div',
              { className: 'list row' },
              component.props.newFileMode && _npmReact.default.createElement(FilePlugin, {
                site: site,
                updateFileStatus: component.updateFileStatus,
                fileUploadService: component.props.fileUploadService,
                dataService: component.props.dataService,
                statusService: component.props.statusService,
                onCancel: component.cancelNewFile,
                onDeleteFile: component.props.onDeleteFile,
                onUploadFile: component.props.onUploadFile,
                onRaiseMessage: component.props.onRaiseMessage,
                onUploadProgress: component.props.onUploadProgress
              })
            ),
            !component.props.files.length ? fileDisplay : null,
            _npmReact.default.createElement(
              'div',
              { className: 'list row' },
              _npmReact.default.createElement('div', {
                className: 'col-xs-12 item processing',
                style: {
                  backgroundColor: 'white', marginBottom: 0, minHeight: '15px', borderBottom: showTop ? '1px solid black' : '0'
                },
                onDragOver: function onDragOver(e) {
                  return e.preventDefault();
                },
                onDragEnter: function onDragEnter() {
                  return _this2.handleEnter();
                }
              }),
              component.props.files.map(function (file, index) {
                return _npmReact.default.createElement(FilePlugin, {
                  order: index + 1,
                  key: file.id,
                  updateOrder: component.updateOrder,
                  updateFileStatus: component.updateFileStatus,
                  updateHover: component.updateHover,
                  updateMove: component.updateMove,
                  lastHover: component.props.lastHover,
                  toggleDrag: component.toggleDrag,
                  isDragging: component.state.isDragging,
                  fileUploadService: component.props.fileUploadService,
                  dataService: component.props.dataService,
                  statusService: component.props.statusService,
                  site: site,
                  file: file,
                  onDeleteFile: component.props.onDeleteFile,
                  onChangeFile: component.props.onChangeFile,
                  onRaiseMessage: component.props.onRaiseMessage,
                  onUploadProgress: component.props.onUploadProgress,
                  changeMode: component.props.changeMode,
                  saveOrder: component.props.saveOrder
                });
              })
            )
          )
        );
      }
    }]);

    return FileManagerList;
  }(_npmReact.default.Component);

  exports.default = FileManagerList;
});