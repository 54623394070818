define('webui/pods/components/wizard-embedded/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var WizardAssistant = Ember.Object.extend({
    message: null,
    data: null, /* this is used to store state data by the wizard plugins */

    isCanceled: false, setMessage: function setMessage(type, copy) {
      this.set('message', {
        type: type,
        copy: copy
      });
    },
    clearMessage: function clearMessage() {
      this.set('message', null);
    },
    clearSuccessMessage: function clearSuccessMessage() {
      var currentMessage = this.get('message');
      if (currentMessage && currentMessage.type && currentMessage.type === 'success') {
        this.clearMessage();
      }
    }
  });

  exports.default = Ember.Component.extend({

    session: Ember.inject.service('session'),
    fileManager: Ember.inject.service('file-manager'),

    componentName: null,
    htmlId: null, /* not required, but when the template is embedded an ID may be assigned */

    assistant: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.assistant = WizardAssistant.create();

      if (!this.get('htmlId')) {
        this.set('htmlId', this.elementId + '-wizard');
      }
    },
    didInsertElement: function didInsertElement() {
      $('body').addClass('with-wizard');

      var $header = $('#id-header');
      var $messageBar = $('.wizard-messages');
      $(window).scroll(function () /* event */{
        var offset = $(window).scrollTop();
        if (offset > $header.height()) {
          $messageBar.css('height', $('.alert', $messageBar).outerHeight(true));
          $messageBar.addClass('fixed');
        } else {
          $messageBar.css('height', 'auto');
          $messageBar.removeClass('fixed');
        }
      });
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      $('body').removeClass('with-wizard');
      $(window).off('scroll');
    },


    actions: {
      doWizardExit: function doWizardExit() {
        /* passed to the child wizard and to be used as a default escape route */
        var sessionFlight = this.get('session.currentFlight');
        if (sessionFlight) {
          this.sendAction('doTransition', 'flight', sessionFlight);
        } else {
          /* This just defaults to the site which redirects to the newest flight. */
          this.sendAction('doTransition', 'site', this.assistant.get('data.activeSite.id'));
        }
      },
      doWizardCancel: function doWizardCancel() {
        this.assistant.set('isCanceled', true);
        this.actions.doWizardExit.call(this);
      },
      doTransition: function doTransition(route, id) {
        /* passed to the child wizard so the wizard has access to the route's transition capabilities for non-standard redirects. */
        this.sendAction('doTransition', route, id);
      },
      closeAlert: function closeAlert() {
        this.assistant.clearMessage();
      }
    }
  });
});