define('webui/pods/site/route', ['exports', 'webui/mixins/check-for-map-load', 'webui/mixins/default-flight', 'webui/mixins/site-user-access-processor', 'webui/pods/route'], function (exports, _checkForMapLoad, _defaultFlight, _siteUserAccessProcessor, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend(_checkForMapLoad.default, _defaultFlight.default, _siteUserAccessProcessor.default, {
    mapData: Ember.inject.service('map-data'), // This service stores the google map (and google API) for sharing with other map components.
    dataPanelService: Ember.inject.service('data-panel'),
    siteDataAccess: Ember.inject.service('data-access/site'),

    model: function model(params) {
      var route = this;

      if (route.get('session.data.authenticated.status') === 'pending') {
        return route.transitionTo('account');
      }
      this.get('session').set('siteForNavigation', params.id);

      route.store.peekAll('annotation-layer').forEach(function (annotationLayer) {
        return annotationLayer.set('displayOnFlightChange', false);
      });

      return route.store.findRecord('site', params.id, { reload: true }).then(function (site) {
        if (site.get('status') === 'inactive') {
          return route.transitionTo('');
        }

        return route.store.findRecord('user', route.get('session.data.authenticated.id'), { reload: true }).then(function (authenticatedUser) {
          return route.processSiteUserAccess(site, authenticatedUser).then(function () {
            return Ember.RSVP.resolve(route.store.query('flight', { site: params.id }).then(function (allFlightsForSite) {
              var defaultFlight = route.getDefaultFlightForSite(allFlightsForSite);

              route.get('dataPanelService').closeOpenPanels();
              if (defaultFlight && defaultFlight.id) {
                return route.transitionTo('flight', defaultFlight.id);
              }

              route.get('session').set('currentFlight', null);

              return Ember.RSVP.hash({
                site: route.store.findRecord('site', params.id),
                blueprints: route.store.query('overlayBlueprint', { site: params.id }),
                regionBlueprints: route.get('siteDataAccess').getRegionBlueprintsForSite(params.id),
                showSearchBox: true,
                disablePanels: {
                  downloads: true, qualityReports: true, flightInfo: true, regions: true, trackingRegions: true, workActivities: true, crossSections: true, quantities: true, 'cdt-ui': true, classifications: true
                },
                authenticatedUser: authenticatedUser,
                picture: route.store.query('picture', { site: params.id })
              });
            }));
          });
        });
      }).catch(function () {
        return route.transitionTo('');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.checkForMapLoad(controller);
    },


    actions: {
      doTransition: function doTransition(route, id) {
        this.transitionTo(route, id);
      },
      goToFileManager: function goToFileManager(id, mode) {
        this.transitionTo('site-file-manager', id, mode);
      },
      doSiteUsersUpdate: function doSiteUsersUpdate(params) {
        var route = this;
        var currentSiteId = this.controller.get('model.site.id');

        if (params.action === 'remove') {
          route.store.findRecord('site', currentSiteId).then(function (site) {
            site.get('users').removeObject(params.user);
            site.save();
          });
        }

        route.store.findRecord('site', currentSiteId, { reload: true }).then(function (site) {
          /* Because users have changed we reprocess the entire list for permissions. This may not be entirely necessary,
            * but it does seem a safer bet than only updating the singular user passed in params. */
          route.processSiteUserAccess(site, route.controller.get('model.authenticatedUser'));
        });
      }
    }
  });
});