define('webui/pods/logout/route', ['exports', 'webui/pods/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    session: Ember.inject.service('session'),

    init: function init() {
      this._super();

      this.get('session').invalidate();
    }
  });
});