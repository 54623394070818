define('webui/models/change-detection', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    tiledMap: _emberData.default.belongsTo('tiled-map', { async: true }),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    comparisonFlight: _emberData.default.belongsTo('flight', { async: true }),
    comparisonBlueprint: _emberData.default.belongsTo('blueprint', { async: true }),
    comparisonBaseRegion: _emberData.default.belongsTo('region', { async: true }),
    elevationThresholdFt: _emberData.default.attr('number'),
    maxElevationChangeFt: _emberData.default.attr('number'),
    areaThresholdSqFt: _emberData.default.attr('number'),
    featureGeometry: _emberData.default.attr(),
    excludedFeatureGeometry: _emberData.default.attr(),
    numClasses: _emberData.default.attr('number', { defaultValue: 10 }),
    message: _emberData.default.attr(),
    labelGeojsons: _emberData.default.attr(),
    urlPrefix: _emberData.default.attr('string'),

    comparisonType: Ember.computed('comparisonFlight', 'comparisonBlueprint', function comparisonType() {
      if (this.get('comparisonFlight.id')) {
        return 'flight';
      }

      return 'blueprint';
    }),

    comparisonModel: Ember.computed('comparisonType', 'comparisonFlight', 'comparisonBlueprint', function comparisonModel() {
      return this.get('comparisonType') === 'flight' ? this.get('comparisonFlight') : this.get('comparisonBlueprint');
    }),

    displayName: Ember.computed('comparisonType', 'comparisonFlight', 'comparisonBlueprint', function displayName() {
      if (this.get('comparisonType') === 'flight') {
        return this.get('comparisonFlight.displayFlightTime');
      }

      return this.get('comparisonBlueprint.name');
    })
  });
});