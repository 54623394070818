define("webui/pods/components/true-tonnage-report/true-tonnage-report-form/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    Updating Report ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "glyphicon glyphicon-repeat spinner");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 4
                  },
                  "end": {
                    "line": 11,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-success");
                var el2 = dom.createTextNode("Create Report");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element9, 'disabled');
                morphs[1] = dom.createElementMorph(element9);
                return morphs;
              },
              statements: [["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [9, 50], [9, 62]]]], "disabled", null], [], ["loc", [null, [9, 45], [9, 80]]]]], ["element", "action", ["submitTrueTonnageReport"], [], ["loc", [null, [9, 81], [10, 8]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 12,
                      "column": 4
                    },
                    "end": {
                      "line": 15,
                      "column": 4
                    }
                  },
                  "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "btn btn-success");
                  var el2 = dom.createTextNode("Submit Report");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element8, 'disabled');
                  morphs[1] = dom.createElementMorph(element8);
                  return morphs;
                },
                statements: [["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [13, 50], [13, 62]]]], "disabled", null], [], ["loc", [null, [13, 45], [13, 80]]]]], ["element", "action", ["toggleSubmitConfirmation"], [], ["loc", [null, [13, 81], [14, 8]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 4
                    },
                    "end": {
                      "line": 23,
                      "column": 4
                    }
                  },
                  "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  var el2 = dom.createTextNode("\n      Are you sure you want to submit these changes?\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("button");
                  dom.setAttribute(el2, "class", "btn btn-success");
                  var el3 = dom.createTextNode("Submit");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("button");
                  dom.setAttribute(el2, "class", "btn btn-cancel");
                  var el3 = dom.createTextNode("Cancel");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var element6 = dom.childAt(element5, [1]);
                  var element7 = dom.childAt(element5, [3]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element6, 'disabled');
                  morphs[1] = dom.createElementMorph(element6);
                  morphs[2] = dom.createAttrMorph(element7, 'disabled');
                  morphs[3] = dom.createElementMorph(element7);
                  return morphs;
                },
                statements: [["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [18, 52], [18, 64]]]], "disabled", null], [], ["loc", [null, [18, 47], [18, 82]]]]], ["element", "action", ["submitTrueTonnageReport"], [], ["loc", [null, [18, 83], [19, 10]]]], ["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [20, 51], [20, 63]]]], "disabled", null], [], ["loc", [null, [20, 46], [20, 81]]]]], ["element", "action", ["toggleSubmitConfirmation"], [], ["loc", [null, [20, 82], [21, 10]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 4
                  },
                  "end": {
                    "line": 24,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "showSubmitConfirmation", ["loc", [null, [12, 14], [12, 36]]]]], [], 0, 1, ["loc", [null, [12, 4], [23, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 4
                },
                "end": {
                  "line": 25,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "creatingReport", ["loc", [null, [8, 10], [8, 24]]]]], [], 0, 1, ["loc", [null, [8, 4], [24, 11]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 4
                },
                "end": {
                  "line": 29,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-info");
              var el2 = dom.createTextNode("Download CSV");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element4);
              return morphs;
            },
            statements: [["element", "action", ["toggleDownloadForm"], [], ["loc", [null, [28, 33], [28, 65]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 4
                  },
                  "end": {
                    "line": 34,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-danger pull-right");
                var el2 = dom.createTextNode("Delete Report");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element3, 'disabled');
                morphs[1] = dom.createElementMorph(element3);
                return morphs;
              },
              statements: [["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [32, 60], [32, 72]]]], "disabled", null], [], ["loc", [null, [32, 55], [32, 90]]]]], ["element", "action", ["toggleDeleteConfirmation"], [], ["loc", [null, [33, 6], [33, 44]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 4
                  },
                  "end": {
                    "line": 42,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "pull-right");
                var el2 = dom.createTextNode("\n      Are you sure you want to delete this report?\n      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "btn btn-danger");
                var el3 = dom.createTextNode("Delete");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "btn btn-cancel");
                var el3 = dom.createTextNode("Cancel");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1]);
                var element2 = dom.childAt(element0, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element1, 'disabled');
                morphs[1] = dom.createElementMorph(element1);
                morphs[2] = dom.createAttrMorph(element2, 'disabled');
                morphs[3] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [37, 51], [37, 63]]]], "disabled", null], [], ["loc", [null, [37, 46], [37, 81]]]]], ["element", "action", ["deleteTrueTonnageReport"], [], ["loc", [null, [37, 82], [38, 10]]]], ["attribute", "disabled", ["subexpr", "if", [["get", "isFormSaving", ["loc", [null, [39, 51], [39, 63]]]], "disabled", null], [], ["loc", [null, [39, 46], [39, 81]]]]], ["element", "action", ["toggleDeleteConfirmation"], [], ["loc", [null, [39, 82], [40, 10]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 4
                },
                "end": {
                  "line": 43,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["get", "showDeleteConfirmation", ["loc", [null, [31, 14], [31, 36]]]]], [], 0, 1, ["loc", [null, [31, 4], [42, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 4
              },
              "end": {
                "line": 44,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "canSubmitTrueTonnageForm", ["loc", [null, [7, 10], [7, 34]]]]], [], 0, null, ["loc", [null, [7, 4], [25, 11]]]], ["block", "if", [["get", "canDownloadExcel", ["loc", [null, [27, 10], [27, 26]]]]], [], 1, null, ["loc", [null, [27, 4], [29, 11]]]], ["block", "if", [["get", "canDeleteTrueTonnageForm", ["loc", [null, [30, 10], [30, 34]]]]], [], 2, null, ["loc", [null, [30, 4], [43, 11]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 0
              },
              "end": {
                "line": 53,
                "column": 0
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "color: #333333;");
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
            return morphs;
          },
          statements: [["inline", "csv-export-settings-modal", [], ["type", "true-tonnage", "toggleDownloadForm", ["subexpr", "action", ["toggleDownloadForm"], [], ["loc", [null, [50, 70], [50, 99]]]], "downloadCSV", ["subexpr", "action", ["downloadCSV"], [], ["loc", [null, [50, 112], [51, 16]]]]], ["loc", [null, [50, 2], [51, 19]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes", "wrong-type"]
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "true-tonnage-form-footer-container");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "id", "true-tonnage-form-footer");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "isFormSaving", ["loc", [null, [4, 10], [4, 22]]]]], [], 0, 1, ["loc", [null, [4, 4], [44, 11]]]], ["block", "if", [["get", "showCSVSettings", ["loc", [null, [48, 6], [48, 21]]]]], [], 2, null, ["loc", [null, [48, 0], [53, 7]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 56,
            "column": 4
          }
        },
        "moduleName": "webui/pods/components/true-tonnage-report/true-tonnage-report-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n    ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "canSubmitTrueTonnageForm", ["loc", [null, [1, 10], [1, 34]]]], ["get", "canDeleteTrueTonnageForm", ["loc", [null, [1, 35], [1, 59]]]], ["get", "canDownloadExcel", ["loc", [null, [1, 60], [1, 76]]]]], [], ["loc", [null, [1, 6], [1, 77]]]]], [], 0, null, ["loc", [null, [1, 0], [54, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});