define('webui/pods/maps/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

    session: Ember.inject.service('session'),

    beforeModel: function beforeModel(transition) {
      var route = this;
      transition.abort();
      if (!this.get('session.isAuthenticated') && transition) {
        return this.transitionTo('login');
      }

      if (transition.params.maps && transition.params.maps.id) {
        return Ember.RSVP.resolve(route.store.query('map', { id: transition.params.maps.id }).then(function (maps) {
          return route.transitionTo('flight', maps.objectAt(0).get('flight.id'));
        }).catch(function () {
          return route.transitionTo('index');
        }) // If there's no flight for this map id, let index handle it.
        );
      }
      return this.transitionTo('index');
    }
  });
});