define("webui/pods/components/admin-user-form/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/admin-user-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "alert error-message");
          dom.setAttribute(el1, "role", "alert");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["content", "errorMessage", ["loc", [null, [7, 10], [7, 26]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 13,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/admin-user-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert success-message");
            dom.setAttribute(el1, "role", "alert");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "successMessage", ["loc", [null, [11, 10], [11, 28]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/admin-user-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "successMessage", ["loc", [null, [9, 19], [9, 33]]]]], [], 0, null, ["loc", [null, [9, 8], [13, 6]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 40,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/admin-user-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" this is so dumb ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Create User");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" Receive new data upload emails\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "for", "status");
          var el2 = dom.createTextNode("Status");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "status");
          dom.setAttribute(el1, "name", "status");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("option");
          dom.setAttribute(el2, "value", "pending");
          var el3 = dom.createTextNode("Pending");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("option");
          dom.setAttribute(el2, "value", "active");
          var el3 = dom.createTextNode("Active");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("option");
          dom.setAttribute(el2, "value", "inactive");
          var el3 = dom.createTextNode("Inactive");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [19]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 13, 13, contextualElement);
          morphs[5] = dom.createMorphAt(dom.childAt(fragment, [15, 1]), 1, 1);
          morphs[6] = dom.createAttrMorph(element8, 'onchange');
          return morphs;
        },
        statements: [["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.firstName", ["loc", [null, [17, 29], [17, 44]]]]], [], []], "label", "First Name", "class", "first-name"], ["loc", [null, [17, 10], [17, 84]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.lastName", ["loc", [null, [19, 29], [19, 43]]]]], [], []], "label", "Last Name", "class", "last-name"], ["loc", [null, [19, 10], [19, 81]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.email", ["loc", [null, [21, 29], [21, 40]]]]], [], []], "label", "Email", "class", "email"], ["loc", [null, [21, 10], [21, 70]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [23, 29], [23, 43]]]]], [], []], "type", "password", "label", "Password", "class", "password"], ["loc", [null, [23, 10], [23, 95]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.passwordConfirm", ["loc", [null, [25, 29], [25, 50]]]]], [], []], "type", "password", "label", "Password Confirm", "class", "password-confirm"], ["loc", [null, [25, 10], [25, 118]]]], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.sendFlightDataUpdateEmails", ["loc", [null, [29, 46], [29, 78]]]]], [], []], "name", "sendFlightDataUpdateEmails"], ["loc", [null, [29, 14], [29, 114]]]], ["attribute", "onchange", ["subexpr", "action", ["selectUserStatus"], ["value", "target.value"], ["loc", [null, [34, 53], [34, 103]]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 10
              },
              "end": {
                "line": 70,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/admin-user-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "");
            var el3 = dom.createTextNode("Send Reminder");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element6);
            return morphs;
          },
          statements: [["element", "action", ["sendInviteReminder"], [], ["loc", [null, [68, 25], [68, 56]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 8
            },
            "end": {
              "line": 72,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/admin-user-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Edit User");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("ID: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" Receive new data upload emails\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "for", "status");
          var el2 = dom.createTextNode("Status");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "status");
          dom.setAttribute(el1, "name", "status");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("option");
          dom.setAttribute(el2, "value", "pending");
          var el3 = dom.createTextNode("Pending");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("option");
          dom.setAttribute(el2, "value", "active");
          var el3 = dom.createTextNode("Active");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("option");
          dom.setAttribute(el2, "value", "inactive");
          var el3 = dom.createTextNode("Inactive");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [19]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 13, 13, contextualElement);
          morphs[6] = dom.createMorphAt(dom.childAt(fragment, [15, 1]), 1, 1);
          morphs[7] = dom.createAttrMorph(element7, 'value');
          morphs[8] = dom.createAttrMorph(element7, 'onchange');
          morphs[9] = dom.createMorphAt(fragment, 21, 21, contextualElement);
          return morphs;
        },
        statements: [["content", "model.user.id", ["loc", [null, [42, 18], [42, 35]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.user.firstName", ["loc", [null, [43, 29], [43, 49]]]]], [], []], "label", "First Name", "class", "first-name"], ["loc", [null, [43, 10], [43, 89]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.user.lastName", ["loc", [null, [45, 29], [45, 48]]]]], [], []], "label", "Last Name", "class", "last-name"], ["loc", [null, [45, 10], [45, 86]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.user.email", ["loc", [null, [47, 29], [47, 45]]]]], [], []], "label", "Email", "class", "email"], ["loc", [null, [47, 10], [47, 75]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.user.password", ["loc", [null, [49, 29], [49, 48]]]]], [], []], "type", "password", "label", "Password", "class", "password"], ["loc", [null, [49, 10], [49, 100]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "model.user.passwordConfirm", ["loc", [null, [51, 29], [51, 55]]]]], [], []], "type", "password", "label", "Password Confirm", "class", "password-confirm"], ["loc", [null, [51, 10], [51, 123]]]], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.user.sendFlightDataUpdateEmails", ["loc", [null, [55, 46], [55, 83]]]]], [], []], "name", "sendFlightDataUpdateEmails"], ["loc", [null, [55, 14], [55, 119]]]], ["attribute", "value", ["concat", [["get", "model.user.status", ["loc", [null, [60, 53], [60, 70]]]]]]], ["attribute", "onchange", ["subexpr", "action", ["selectUserStatus"], ["value", "target.value"], ["loc", [null, [60, 83], [60, 133]]]]], ["block", "if", [["get", "model.user.isPending", ["loc", [null, [66, 16], [66, 36]]]]], [], 0, null, ["loc", [null, [66, 10], [70, 17]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 10
              },
              "end": {
                "line": 86,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/admin-user-form/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "associatedItem badge");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "glyphicon glyphicon-remove");
            dom.setAttribute(el2, "title", "Remove association");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createAttrMorph(element2, 'clientid');
            morphs[2] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [["content", "client.name", ["loc", [null, [83, 14], [83, 29]]]], ["attribute", "clientid", ["concat", [["get", "client.id", ["loc", [null, [84, 94], [84, 103]]]]]]], ["element", "action", ["removeClientAndSites", ["get", "client.id", ["loc", [null, [84, 139], [84, 148]]]]], [], ["loc", [null, [84, 107], [84, 150]]]]],
          locals: ["client"],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 12
              },
              "end": {
                "line": 97,
                "column": 12
              }
            },
            "moduleName": "webui/pods/components/admin-user-form/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "client.id", ["loc", [null, [94, 31], [94, 40]]]]]]], ["content", "client.name", ["loc", [null, [95, 16], [95, 31]]]]],
          locals: ["client"],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 6
            },
            "end": {
              "line": 101,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/admin-user-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("#need to separate these into bootstrap rows so client association div can have more columns ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("Client Associations");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "clientAssociations");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          Available clients\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("select");
          dom.setAttribute(el2, "name", "addClient");
          dom.setAttribute(el2, "class", "form-control");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("option");
          dom.setAttribute(el3, "value", "");
          var el4 = dom.createTextNode("-");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary");
          var el3 = dom.createTextNode("Add Client");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [7]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
          morphs[1] = dom.createAttrMorph(element4, 'onchange');
          morphs[2] = dom.createMorphAt(element4, 3, 3);
          morphs[3] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["block", "each", [["get", "model.user.clients", ["loc", [null, [81, 18], [81, 36]]]]], [], 0, null, ["loc", [null, [81, 10], [86, 19]]]], ["attribute", "onchange", ["subexpr", "action", ["selectClientValue"], ["value", "target.value"], ["loc", [null, [91, 65], [91, 116]]]]], ["block", "each", [["get", "model.availableClients", ["loc", [null, [93, 20], [93, 42]]]]], [], 1, null, ["loc", [null, [93, 12], [97, 21]]]], ["element", "action", ["addClient"], [], ["loc", [null, [99, 42], [99, 64]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 109,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/admin-user-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container admin form");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-lg-3 col-xs-2");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-lg-6 col-xs-8");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "class", "btn btn-primary btn-block");
        dom.setAttribute(el6, "type", "submit");
        var el7 = dom.createTextNode("Save");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-lg-3 col-xs-2");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0, 1, 3]);
        var element10 = dom.childAt(element9, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element9, 1, 1);
        morphs[1] = dom.createElementMorph(element10);
        morphs[2] = dom.createMorphAt(element10, 1, 1);
        morphs[3] = dom.createMorphAt(element9, 5, 5);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "errorMessage", ["loc", [null, [5, 12], [5, 24]]]]], [], 0, 1, ["loc", [null, [5, 6], [13, 13]]]], ["element", "action", ["submitModel", ["get", "model", ["loc", [null, [14, 35], [14, 40]]]]], ["on", "submit"], ["loc", [null, [14, 12], [14, 54]]]], ["block", "if", [["get", "createMode", ["loc", [null, [15, 14], [15, 24]]]]], [], 2, 3, ["loc", [null, [15, 8], [72, 15]]]], ["block", "unless", [["get", "createMode", ["loc", [null, [77, 16], [77, 26]]]]], [], 4, null, ["loc", [null, [77, 6], [101, 17]]]], ["content", "yield", ["loc", [null, [108, 0], [108, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});