define('webui/pods/components/site-info-panel/component', ['exports', 'webui/mixins/data-panel', 'webui/config/environment'], function (exports, _dataPanel, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dataPanel.default, {

    mapData: Ember.inject.service('map-data'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    feedback: Ember.inject.service('user-feedback'),
    settingsManager: Ember.inject.service('settings-manager'),

    siteGrades: null,
    displayNameEdit: null,
    closeDataPanel: 'closeDataPanel',

    editSiteSettings: false,

    siteCutColorPreference: '#4A90E2',
    siteFillColorPreference: '#D0021B',

    listenerId: 'polygon_site_info',

    didReceiveAttrs: function didReceiveAttrs() {
      var context = this;
      var mapData = this.get('mapData');
      var isReadOnly = this.get('authenticatedUser.isLimitedAccess') || this.get('authenticatedUser.isViewOnly');

      this.set('isReadOnly', isReadOnly);
      this.set('siteCutColorPreference', this.get('settingsManager.siteCutColor'));
      this.set('siteFillColorPreference', this.get('settingsManager.siteFillColor'));

      /* Force a drawing mode reset in case another mode is toggled. */
      mapData.resetDrawingMode();
      mapData.setListeningMode(this.listenerId);
      if (isReadOnly) {
        mapData.changeDrawingMode('static');
      }

      mapData.set('restrictions.maxPolygons', { value: 1, message: 'Sites can only have one boundary, so you can only draw one polygon in the site info interface.' });

      return mapData.set('updatePolygon', function (polygon) {
        Ember.run.debounce(context, function () {
          context.saveSiteBoundaries(polygon);
        }, 3500);
      });
    },
    drawSiteBoundaries: function drawSiteBoundaries() {
      var mapData = this.get('mapData');
      var boundaries = this.get('activeSite.boundaries');
      var featureProps = {
        id: this.get('activeSite.id'),
        color: 'blue',
        observable_as: this.listenerId,
        points: boundaries,
        type: 'Polygon'
      };

      mapData.fitMapToPoints(boundaries, 'lng', 'lat');
      return mapData.drawFeature(featureProps);
    },
    saveSiteBoundaries: function saveSiteBoundaries(polygon, points, area) {
      var mapData = this.get('mapData');
      var store = this.get('store');
      if (!points) {
        points = mapData.extractPointsFromPolygon(polygon);
      }
      if (!area) {
        area = mapData.calculateArea(polygon);
      }
      return store.findRecord('site', this.get('activeSite.id')).then(function (site) {
        site.set('boundaries', points);
        site.set('area', area);
        site.save();
      });
    },
    didInsertElement: function didInsertElement() {
      var mapData = this.get('mapData');
      var component = this;
      var id = this.get('activeSite.id');
      var isReadOnly = this.get('isReadOnly');

      mapData.addMinZoomRestriction('site info panel', 2);

      var qualifier = this.listenerId;

      return Ember.run.next(function () {
        if (component.get('activeSite.boundaries')) {
          component.drawSiteBoundaries();
        } else {
          mapData.addSearchBox();
        }

        if (!mapData.hasListeners(qualifier)) {
          mapData.addListener('create', qualifier, function (data) {
            if (data.features.length && data.features[0].geometry.type === 'Polygon') {
              var existingPolygons = mapData.get('polygons');
              var feedback = component.get('feedback');
              var keys = Object.keys(existingPolygons);

              if (keys.length > 0) {
                mapData.drawingManager.delete(data.features[0].id);
                feedback.reportError({ title: 'Site Boundary Error', detail: 'Sites can only have one boundary.' });
              } else {
                var points = mapData.extractPointsFromPolygon(data);
                var area = mapData.calculateArea(data);
                var featureProps = {
                  id: id,
                  color: 'blue',
                  observable_as: qualifier,
                  points: points,
                  type: 'Polygon'
                };

                mapData.drawingManager.delete(data.features[0].id);
                mapData.drawFeature(featureProps);
                mapData.changeDrawingMode(isReadOnly ? 'static' : 'move');
                component.saveSiteBoundaries(data, points, area);
              }
            }
          }).addListener('update', qualifier, function (data) {
            if (data.features.length && data.features[0].geometry.type === 'Polygon') {
              return component.saveSiteBoundaries(data);
            }
            return false;
          });
          component.set('site_info_listeners_registered', true);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      var mapData = this.get('mapData');
      mapData.cleanUp('siteInfo');
      mapData.removeMinZoomRestriction('site info panel');
      delete mapData.restrictions.maxPolygons;
      delete mapData.updatePolygon;
      mapData.deleteListeners('create', this.listenerId);
      mapData.deleteListeners('update', this.listenerId);

      if (mapData.get('currentDrawingMode') === this.listenerId) {
        mapData.changeDrawingMode('move');
      }
    },


    actions: {
      toggleSiteNameEdit: function toggleSiteNameEdit() {
        if (this.get('activeSite.hasDirtyAttributes')) {
          this.get('activeSite').rollbackAttributes();
        }
        this.toggleProperty('displayNameEdit');
      },
      leavePanel: function leavePanel() {
        this.sendAction('closeDataPanel');
        $('#site-info-tool-bar').removeClass('active'); // #TODO: this 'distributed' method of making action bar icons inactive is fragile, hard to maintain, and causes behavior issues (ie this doesn't unset the corresponding openPanel property)
      },
      updateSiteName: function updateSiteName() {
        this.get('activeSite').save();
        this.toggleProperty('displayNameEdit');
      },
      enableEditMode: function enableEditMode() {
        this.set('editSiteSettings', true);
      },
      saveSiteSettings: function saveSiteSettings() {
        var feedback = this.get('feedback');
        var settingsManager = this.get('settingsManager');
        var p = _environment.default.preferences.site;
        settingsManager.setSitePreference(p.cutColor.name, this.get('siteCutColorPreference'));
        settingsManager.setSitePreference(p.fillColor.name, this.get('siteFillColorPreference'));
        /* The cut and fill color preferences have already been saved to the database as part of setSitePreference. Now we
           save the site model itself to capture any changes to name or description. If the site is saved before the preferences
           there is a race condition where the async relationships will update independently of the setSitePreference save
           leading to bad entries.
        */
        if (this.get('activeSite.clients.length') > 1) {
          feedback.reportError({ title: 'Site Update Error', detail: 'Sites can only belong to one client.  Please contact customer support.' });
          return;
        }
        this.get('activeSite').save();
        this.set('editSiteSettings', false);
      },
      doSetCutFillColors: function doSetCutFillColors() {
        var cutColor = this.get('siteCutColorPreference');
        var fillColor = this.get('siteFillColorPreference');

        this.set('siteCutColorPreference', fillColor);
        this.set('siteFillColorPreference', cutColor);
      },
      clearBoundaries: function clearBoundaries() {
        var mapData = this.get('mapData');
        if (mapData.get('currentDrawingMode') === this.listenerId) {
          mapData.changeDrawingMode('move');
          this.drawSiteBoundaries();
        } else {
          mapData.deletePolygonFromPanel(this.get('activeSite.id'));
          mapData.changeDrawingMode(this.listenerId);
        }
      },
      createBoundaries: function createBoundaries() {
        var mapData = this.get('mapData');
        if (mapData.get('currentDrawingMode') === this.listenerId) {
          mapData.changeDrawingMode('move');
        } else {
          mapData.changeDrawingMode(this.listenerId);
        }
      }
    }
  });
});