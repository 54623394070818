define('webui/services/pdf-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    columnsToOmit: [],
    ttrColumnsToOmit: [],
    tablesToOmit: [],
    flightsToOmit: [],
    activeTrackingCutFillSurface: null,
    archiveExport: true,
    selectedMaterialForTrueTonnageChart: null,
    excludedMaterialsForTrueTonnageChart: [],
    excludedRegionsForTrueTonnageChart: [],
    useMinifiedRegionNames: false,
    displayMaterialAsCode: false,
    customHeader: null,
    coLocLob: null,
    totalColumns: [],
    totalTTRColumns: [],
    myData: [],
    materials: [],

    setData: function setData(data) {
      this.set('myData', data);
    },
    setMaterial: function setMaterial(data) {
      this.set('materials', data);
    },
    getData: function getData() {
      var daterange = this.get('myData');
      return daterange;
    },
    getMateial: function getMateial() {
      return this.get('materials');
    }
  });
});