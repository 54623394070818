define('webui/services/xhr-uploader', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    feedback: Ember.inject.service('user-feedback'),

    queue: [],
    status: null,
    currentUpload: null,
    currentUploadType: null,

    advanceQueue: function advanceQueue() {
      if (this.queue.length === 0) {
        return false;
      }
      var payload = this.queue.shift();
      this.set('status', 'uploading');
      this.set('currentUpload', payload);
      return this.doUpload(payload.params, payload.callback, payload.returnFile);
    },
    upload: function upload(params, callback, returnFile) {
      // interface for doUpload
      this.queue.push({
        params: params,
        callback: callback,
        returnFile: returnFile
      });

      if (this.get('status') !== 'uploading') {
        return this.advanceQueue();
      }

      return null;
    },
    doUpload: function doUpload(params, callback, returnFile) {
      /**
       * Service for uploading multipart binaries without having to go through the
       * Ember Data adapter.
       *
       * @param {file handler} file - Required. Handler for file in the filesystem. Should be
       * passed from the file attribute of the onchange event for a file browser.
       * This is an array of the files that have been attached.  The old uploader
       * just took one, but this one should be able to do more than one.
       *
       * @param {Ember A} returnFile - Optional. Wrapper object for original file pointer and
       * other properties to be used by the originating context (ie Drag and Drop uploader)
       *
       * @param {string} controller - Required. The name of the API controller that should
       * handle this file.
       *
       * @param {string} uuid - The uuid for any existing database entity that
       * should be related to this file.
       *
       * @param {string} method - The name of the API method that should handle
       * this file
       *
       * @param {string} fieldname - The name of the form field that the upload
       * receiver is going to look for in the multipart POST.
       *
       * @param {string} context - Object passing in the calling scope for showing updates
       * in the DOM--this is most likely going to be a component or a route's controller.
       *
       * @param {string} progressBar - Name of DOM element that would show upload progress.
       * @param {function} callback - Callback function returning error and
       * response objects.  TODO: make this a promise, if we want to use promises
       * consistently in Ember.
       *
       * {
       *   file:
       *   controller:
       *   uuid:
       *   method:
       *   fieldname:
       *   context:
       *   progressBar:
       * }
       *
       */
      var feedback = this.get('feedback');
      var xhr = new XMLHttpRequest();
      var formData = new FormData();
      var url = _environment.default.APP.apiUrl + params.controller;
      var context = params.context || params.component;
      var uploadProgressCallback = params.onUploadProgress || null;
      var service = this;
      var i = void 0;

      if (params.type === 'photos') {
        xhr.timeout = 240000; // time in milliseconds
      }

      if (params.uuid) {
        url += '/' + params.uuid;
      }
      if (params.method) {
        url += '/' + params.method;
      }

      // sails.js wants to see the form body set before attached multipart files.
      if (params.data) {
        Object.keys(params.data).forEach(function (key) {
          formData.append(key, params.data[key]);
        });
      }

      if (params.file && params.file.length > 0) {
        for (i = 0; i < params.file.length; i += 1) {
          formData.append(params.fieldname, params.file[i], params.flightId);
        }
      }

      xhr.open('POST', url);
      xhr.processData = false; /* TODO: Is this unnecessary? I believe this is a legacy jQuery-specific property. */
      xhr.withCredentials = true;
      if (Object.prototype.hasOwnProperty.call(params, 'progressBar') || Object.prototype.hasOwnProperty.call(params, 'onUploadProgress')) {
        xhr.upload.onprogress = function onprogress(e) {
          var progress = void 0;
          if (e.lengthComputable) {
            progress = Math.round(e.loaded / e.total * 100);
            if (context && params.progressBar) {
              context.set(params.progressBar, 'Upload ' + progress + '% complete');
            }
            if (uploadProgressCallback) {
              uploadProgressCallback(progress); /* TODO - now that this is being used more broadly, it should probably pass the current file details as well as the progress. */
            }
          }
        };
      }

      xhr.onreadystatechange = function onreadystatechange() {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 200 || this.status === 201) {
            service.set('success', true);
          } else if (this.status === 0 || xhr.response.length === 0) {// connection lost
            // feedback.reportError({title: 'Error', detail: 'Internet connection lost during upload. Non-uploaded files will be retried.'});
          } else if (xhr.response.indexOf('nginx') === -1 && this.status !== 502) {
            feedback.reportError(xhr.response);
          }

          if (typeof callback === 'function') {
            callback(xhr.response, returnFile);
          }

          service.set('status', 'done');
          service.set('currentUpload', null);
          return service.advanceQueue();
        }
        return null;
      };

      if (params.type === 'photos') {
        xhr.ontimeout = function ontimeout() {
          feedback.reportError({ title: 'Error', detail: 'Internet connection lost during upload. Non-uploaded files will be retried.' });
          service.set('status', 'done');
          service.set('currentUpload', null);
          return service.advanceQueue();
        };
      }

      xhr.send(formData);
    },
    startJob: function startJob(params, callback) {
      /**
       * Start a job through the API
       *
       *
       * @param {string} controller - Required. The name of the API controller that should
       * handle this file.
       *
       * @param {string} uuid - The uuid for any existing database entity that
       * should be related to this file.
       *
       * @param {string} method - The name of the API method that should handle
       * this file
       *
       * @param {object} body - Any object that should be sent with this request.
       * Added because the API expects EPSG code in the body when it reprocesses blueprints,
       * but this also seems like a generally useful pattern.
       *
       * @param {function} callback - Callback function returning error and
       * response objects.  TODO: make this a promise, if we want to use promises
       * consistently in Ember.
       *
       * {
       *   controller:
       *   uuid:
       *   method:
       * }
       *
       */
      var feedback = this.get('feedback'); /* This is ubiquitous so we're still going to use it. */
      var xhr = new XMLHttpRequest();
      var url = _environment.default.APP.apiUrl + params.controller;
      var body = params.body || {};

      if (params.uuid) {
        url += '/' + params.uuid;
      }
      if (params.method) {
        url += '/' + params.method;
      }

      xhr.open('PATCH', url);
      xhr.processData = false;
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

      xhr.onload = function () {
        if (xhr.status === 202) {
          // console.log('Accepted!');
        } else {
          feedback.reportError(xhr.response);
        }
        if (typeof callback === 'function') {
          callback(xhr.status, xhr.response);
        }
      };
      xhr.send(JSON.stringify(body));
    }
  });
});