define('webui/pods/components/tracking-region-display/component', ['exports', 'npm:chartist', 'webui/mixins/filter-earthworks-by-blueprint', 'webui/mixins/string-formatting', 'npm:chartist-plugin-tooltips'], function (exports, _npmChartist, _filterEarthworksByBlueprint, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_filterEarthworksByBlueprint.default, _stringFormatting.default, {
    dataType: 'tracking-region',
    isShowingConfirmation: false,
    currentlyRenderedBlueprint: null,
    currentlyRenderedEarthworkCount: 0,

    getEarthworkCountForStatus: function getEarthworkCountForStatus(status) {
      return this.get('filteredEarthworks').filter(function (earthwork) {
        return earthwork.get('status') === status;
      }).length;
    },
    getChartData: function getChartData(earthworkAnalyses) {
      var context = this;
      var validEarthworks = earthworkAnalyses.filter(function (earthwork) {
        return earthwork.get('executeAt');
      });
      var sortedEarthworks = validEarthworks.sortBy('executeAt');
      var chartData = {};

      sortedEarthworks.forEach(function (earthwork) {
        if (earthwork.get('status') === 'pending' || earthwork.get('status') === 'failed' || earthwork.get('status') === 'waiting') {
          return;
        }

        var blueprint = earthwork.get('blueprint.id');
        var yardsCut = earthwork.get('cubicYardsCut') ? earthwork.get('cubicYardsCut') : 0;
        var yardsFill = earthwork.get('cubicYardsFill') ? earthwork.get('cubicYardsFill') : 0;

        if (!chartData[blueprint]) {
          chartData[blueprint] = {
            labels: [],
            series: [[], []]
          };
        }

        chartData[blueprint].labels.push(context.dateString(earthwork.get('executeAt')));
        chartData[blueprint].series[0].push({ meta: 'Cut', value: yardsCut });
        chartData[blueprint].series[1].push({ meta: 'Fill', value: yardsFill });
      });

      return chartData;
    },
    setUpComponent: function setUpComponent() {
      var _this = this;

      var currentBlueprint = this.get('currentSiteGradeBlueprint.id');
      var currentlyRenderedBlueprint = this.get('currentlyRenderedBlueprint');
      var currentlyRenderedEarthworkCount = this.get('currentlyRenderedEarthworkCount');
      var region = this.get('region');
      var earthworkAnalyses = region.get('earthworkAnalyses');

      var validEarthworks = earthworkAnalyses.filter(function (earthwork) {
        return earthwork.get('executeAt');
      });
      var sortedEarthworks = validEarthworks.sortBy('executeAt');
      var earthworksForSelectedFlights = sortedEarthworks.filter(function (earthwork) {
        return !_this.get('selectedFlights') || _this.get('selectedFlights').indexOf(earthwork.get('flight.id')) !== -1;
      });
      var filteredEarthworks = this.filterEarthworksByBlueprint(earthworksForSelectedFlights, currentBlueprint);
      var chartData = this.getChartData(filteredEarthworks)[currentBlueprint]; // this.get('region.chartData.' + currentBlueprint);
      this.set('filteredEarthworks', filteredEarthworks);

      var currentEarthworkCount = this.getEarthworkCountForStatus('succeeded') + this.getEarthworkCountForStatus('warning');
      var shouldRender = currentEarthworkCount > 0 && typeof chartData !== 'undefined' && (currentBlueprint !== currentlyRenderedBlueprint || currentEarthworkCount !== currentlyRenderedEarthworkCount);
      if ($('#tracking-region-chart-' + this.get('region.id')).length) {
        if (shouldRender) {
          this.set('currentlyRenderedBlueprint', currentBlueprint);
          this.set('currentlyRenderedEarthworkCount', currentEarthworkCount);

          var newChart = new _npmChartist.default.Line('#tracking-region-chart-' + this.get('region.id'), chartData, {
            showArea: false,
            lineSmooth: false,
            onlyInteger: true,
            axisX: {
              showGrid: false
            },
            plugins: [_npmChartist.default.plugins.tooltip({ appendToBody: true, anchorToPoint: true, class: 'id-tooltip tooltip-' + this.get('region.id') })]
          });

          this.set('currentChart', newChart);
          $('#tracking-region-chart-' + this.get('region.id')).show();
        } else if (!chartData) {
          this.set('currentlyRenderedBlueprint', currentBlueprint);
          this.set('currentlyRenderedEarthworkCount', currentEarthworkCount);
          this.removeCurrentChart();
        }
      }
    },
    removeCurrentChart: function removeCurrentChart() {
      var currentChart = this.get('currentChart');
      if (currentChart) {
        currentChart.detach();
      }

      $('#tracking-region-chart-' + this.get('region.id')).empty();
      $('#tracking-region-chart-' + this.get('region.id')).hide();
      $('.tooltip-' + this.get('region.id')).remove();
    },


    removeRegion: 'removeRegion',
    toggleRegionDisplay: 'toggleRegionDisplay',

    actions: {
      removeRegion: function removeRegion(region) {
        this.sendAction('removeRegion', region);
      },
      toggleIsShowingConfirmation: function toggleIsShowingConfirmation() {
        this.set('isShowingConfirmation', !this.get('isShowingConfirmation'));
      },
      toggleRegionDisplay: function toggleRegionDisplay(region) {
        this.sendAction('toggleRegionDisplay', region);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('currentSiteGradeBlueprint')) {
        this.setUpComponent();
      }
    },
    didInsertElement: function didInsertElement() {
      var context = this;
      this.get('region').addObserver('earthworkAnalyses', this, function () {
        return context.setUpComponent();
      }); // to populate earthworks on new regions
    },
    willDestroyElement: function willDestroyElement() {
      this.removeCurrentChart();
      this.get('region').removeObserver('earthworkAnalyses');
    }
  });
});