define('webui/pods/account/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model(client) {
      var currentUser = this.get('session.data.authenticated');
      var route = this;
      var obj = {};

      return this.store.find('user', currentUser.id).then(function (user) {
        obj.user = user;

        if (client) {
          obj.activeClient = client;
        }

        return route.store.query('client', {}).then(function (clients) {
          var sortedClients = clients.sortBy('name');
          obj.clients = sortedClients;
          return clients;
        });
      }).then(function () {
        return obj;
      });
    },

    actions: {
      doTransition: function doTransition(route, id, queryParams) {
        if (queryParams) {
          this.transitionTo(route, id, queryParams);
        }
        if (id) {
          this.transitionTo(route, id);
        }

        this.transitionTo(route);
      }
    }
  });
});