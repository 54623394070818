define('webui/models/picture', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    urlPrefix: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    fileSuffix: _emberData.default.attr('string'),
    ratio: _emberData.default.attr('number'),
    site: _emberData.default.belongsTo('site', { async: true }),
    createdAt: _emberData.default.attr('date'),
    lat: _emberData.default.attr('number'),
    long: _emberData.default.attr('number'),
    elevation: _emberData.default.attr('number'),
    timeTaken: _emberData.default.attr('date'),
    comment: _emberData.default.attr('string'),
    uploadedBy: _emberData.default.attr('string'),
    isDeleted: _emberData.default.attr('boolean'),
    rotation: _emberData.default.attr('number'),

    displayTime: function displayTime() {
      // Do MMMM YYYY, h:mm a
      return (0, _moment.default)(this.get('timeTaken').getTime()).format('MM/DD/YYYY h:mm a');
    }.property('timeTaken'),

    displayUrl: function displayUrl() {
      return this.get('urlPrefix') + this.get('fileUrl');
    }.property('fileUrl'),

    displayComment: function displayComment() {
      if (this.get('comment') === null) {
        return null;
      }

      return this.get('comment').length > 30 ? this.get('comment').substr(0, 30) + '...' : this.get('comment');
    }.property('displayComment'),

    displayName: function displayName() {
      return this.get('name').length > 30 ? this.get('name').substr(0, 30) + '...' : this.get('name');
    }.property('displayName')
  });
});