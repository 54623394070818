define('webui/pods/components/true-tonnage-report/component', ['exports', 'webui/mixins/string-formatting'], function (exports, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_stringFormatting.default, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    dataPanelService: Ember.inject.service('data-panel'),
    shouldRefreshChart: false,
    excludedMaterials: Ember.A(),
    excludedRegions: Ember.A(),
    showMaterialReport: true,

    init: function init() {
      this._super();
      this.setup();
      this.setShouldHideFooter();
      this.setShouldShowSettings();
    },
    setup: function setup() {
      var _this = this;

      this.set('nameOfActiveStep', null);
      this.set('nameOfUserResponsibleForActiveStep', null);
      this.set('steps', this.get('store').peekAll('true-tonnage-report-step').sortBy('order').filter(function (trueTonnageReportStep) {
        return _this.get('trueTonnageReport.' + trueTonnageReportStep.get('activeUserField') + '.id') || trueTonnageReportStep.get('code') === 'complete';
      }).map(function (trueTonnageReportStep, index) {
        trueTonnageReportStep.set('displayOrder', index + 1);
        return trueTonnageReportStep;
      }));

      if (!this.get('trueTonnageReport')) {
        this.set('tableName', 'true-tonnage-report/create-table');
      } else {
        this.set('step', this.get('trueTonnageReport.trueTonnageReportStep.order'));
        this.set('nameOfActiveStep', this.get('trueTonnageReport.trueTonnageReportStep.displayName'));
        this.set('tableName', this.isLoggedInUserResponsibleForCurrentStep() ? this.get('trueTonnageReport.trueTonnageReportStep.displayComponentName') : 'true-tonnage-report/read-only-table');
        if (this.get('trueTonnageReport.activeUser')) this.set('nameOfUserResponsibleForActiveStep', this.get('trueTonnageReport.activeUser.fullName') + ' (' + this.get('trueTonnageReport.activeUser.email') + ')');
      }

      this.set('tonnageVarianceWarningThreshold', this.get('dataPanelService.tonnageVarianceWarningThreshold'));
    },
    setShouldShowSettings: function setShouldShowSettings() {
      this.set('shouldShowSettings', ['true-tonnage-report/approver-table', 'true-tonnage-report/read-only-table'].indexOf(this.get('tableName')) !== -1);
    },
    setShouldHideFooter: function setShouldHideFooter() {
      this.set('shouldHideFooter', this.get('tableName') === 'true-tonnage-report/read-only-table' && this.get('trueTonnageReport.trueTonnageReportStep.code') !== 'complete');
    },
    isLoggedInUserResponsibleForCurrentStep: function isLoggedInUserResponsibleForCurrentStep() {
      if (this.get('session.data.authenticated.isAdmin')) return true;

      return this.get('session.data.authenticated.id') === this.get('trueTonnageReport.activeUser.id');
    },
    getFlight: function getFlight() {
      return this.get('store').peekRecord('flight', this.get('flightId'));
    },
    refreshChart: function refreshChart() {
      var trueTonnageReport = this.get('trueTonnageReport');
      var wasDeleted = !trueTonnageReport;
      var wasCompleted = trueTonnageReport && trueTonnageReport.get('trueTonnageReportStep.code') === 'complete';
      if (wasDeleted || wasCompleted) {
        this.set('shouldRefreshChart', true);
      }
    },


    actions: {
      refreshReport: function refreshReport() {
        this.set('trueTonnageReport', this.getFlight().get('trueTonnageReport'));
        this.sendAction('refreshRegionPanelReadOnlyState');
        this.setup();
        this.setShouldHideFooter();
        this.setShouldShowSettings();
        this.refreshChart();
      },
      showMaterialTrendsGraph: function showMaterialTrendsGraph() {
        this.set('showMaterialTrendsGraph', true);
      },
      excludeMaterial: function excludeMaterial(materialId) {
        var excludedMaterials = this.get('excludedMaterials').toArray();
        var alreadyExcluded = excludedMaterials.filter(function (currentId) {
          return currentId === materialId;
        }).length;
        if (alreadyExcluded) {
          excludedMaterials = excludedMaterials.filter(function (currentId) {
            return currentId !== materialId;
          });
        } else {
          excludedMaterials.push(materialId);
        }

        this.set('excludedMaterials', Ember.A(excludedMaterials));
      },
      excludeRegion: function excludeRegion(regionId) {
        var excludedRegions = this.get('excludedRegions').toArray();
        var alreadyExcluded = excludedRegions.filter(function (currentId) {
          return currentId === regionId;
        }).length;
        if (alreadyExcluded) {
          excludedRegions = excludedRegions.filter(function (currentId) {
            return currentId !== regionId;
          });
        } else {
          excludedRegions.push(regionId);
        }

        this.set('excludedRegions', Ember.A(excludedRegions));
      },
      setTonnageVarianceWarningThreshold: function setTonnageVarianceWarningThreshold(tonnageVarianceWarningThreshold) {
        var dataPanelService = this.get('dataPanelService');
        dataPanelService.setTonnageVarianceWarningThreshold(tonnageVarianceWarningThreshold);
        this.set('tonnageVarianceWarningThreshold', tonnageVarianceWarningThreshold);
      },
      hideGraph: function hideGraph() {
        this.set('showMaterialReport', true);
      },
      showTier2Graph: function showTier2Graph() {
        this.set('showMaterialReport', false);
      }
    }
  });
});