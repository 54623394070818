define('webui/pods/components/site-flight-navigation/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    store: Ember.inject.service('store'),
    dataPanelService: Ember.inject.service('data-panel'),
    settingsManager: Ember.inject.service('settings-manager'),

    doTransition: 'doTransition',
    toggleUI: 'toggleUI',
    doSiteUsersUpdate: 'doSiteUsersUpdate',
    goToFileManager: 'goToFileManager',
    goToMaterialManager: 'goToMaterialManager',
    goToRegionManager: 'goToRegionManager',
    activeComponent: 'tiled-map',
    isActionBarOpen: true,
    activeSite: null,

    toggleActionBarVisibility: function toggleActionBarVisibility(view) {
      if (view === 'tiled-map') {
        this.set('isActionBarOpen', true);
      } else {
        this.set('isActionBarOpen', false);
        this.set('dataPanelService.isOpen', false);
        this.set('dataPanelService.floatingPanelIsOpen', false);
      }
    },


    actions: {
      changeView: function changeView(view) {
        this.sendAction('toggleUI', view);
        this.set('activeComponent', view);
        this.toggleActionBarVisibility(view);
        if (view === '3d-embed') this.trackEvent('3D Model Viewed', { flight: this.get('activeFlight.id') });
      },
      doSiteUsersUpdate: function doSiteUsersUpdate(user) {
        this.sendAction('doSiteUsersUpdate', user);
      },
      goToFileManager: function goToFileManager(id, mode) {
        this.sendAction('goToFileManager', id, mode);
      },
      goToMaterialManager: function goToMaterialManager(id) {
        this.sendAction('goToMaterialManager', id);
      },
      goToRegionManager: function goToRegionManager(id) {
        this.sendAction('goToRegionManager', id);
      },
      updateFlightModelProducts: function updateFlightModelProducts() {
        this.sendAction('updateFlightModelProducts');
      }
    }
  });
});