define('webui/pods/components/pdf-options/component', ['exports', 'webui/mixins/export-options'], function (exports, _exportOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_exportOptions.default, {
    store: Ember.inject.service('store'),
    pdfOptions: Ember.inject.service('pdf-options'),
    columnOptions: [],
    tableOptions: [],
    flightOptions: [],
    rowOptions: [],
    previousPanel: null,

    didInsertElement: function didInsertElement() {
      this.set('previousPanel', this.get('panelName'));
      this.set('openedPanel', this.get('panelName'));
      this.set('previousShowTrueTonnageReport', this.get('showTrueTonnageReport'));
      this.setPdfOptions(this.get('panelName'));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      if (!this.get('panelIsOpen')) {
        this.set('panelName', null);
      }

      var openedPanel = this.get('panelName');
      var panelChanged = openedPanel !== this.get('previousPanel');
      var ttrChanged = this.get('showTrueTonnageReport') !== this.get('previousShowTrueTonnageReport');
      var selectedSurface = this.get('pdfOptions.activeTrackingCutFillSurface');
      var selectedSurfaceChanged = selectedSurface && selectedSurface.get('name') !== this.get('activeSurface');
      this.set('openedPanel', openedPanel);

      if (panelChanged) {
        this.set('previousPanel', openedPanel);
        this.set('pdfOptions.columnsToOmit', []);
        this.set('pdfOptions.ttrColumnsToOmit', []);
        this.set('pdfOptions.tablesToOmit', []);
        this.set('pdfOptions.rowsToOmit', []);
        this.set('pdfOptions.totalColumns', []);
        this.set('pdfOptions.totalTTRColumns', []);
        this.set('pdfOptions.totalTables', []);
        this.setPdfOptions(openedPanel);
        Ember.run.next(function () {
          $('#pdf-column-selector').selectpicker('refresh');
          $('#pdf-ttr-column-selector').selectpicker('refresh');
          $('#pdf-table-selector').selectpicker('refresh');
          $('#pdf-flight-selector').selectpicker('refresh');
          $('#pdf-row-selector').selectpicker('refresh');
        });
      } else if (selectedSurfaceChanged) {
        this.setSurfaceSelection();
        Ember.run.next(function () {
          $('#pdf-table-selector').selectpicker('refresh');
        });
      } else if (ttrChanged) {
        this.set('previousShowTrueTonnageReport', this.get('showTrueTonnageReport'));
        this.setTTROptions(openedPanel);
        Ember.run.next(function () {
          $('#pdf-column-selector').selectpicker({ title: _this.get('showTrueTonnageReport') ? 'Volume Calc Columns' : 'Columns' });
          $('#pdf-column-selector').selectpicker('refresh');
          $('#pdf-ttr-column-selector').selectpicker('refresh');
        });
      }
    },
    didRender: function didRender() {
      this.setupSelectPickers();
    },
    setSurfaceSelection: function setSurfaceSelection() {
      var activeSurface = this.get('pdfOptions.activeTrackingCutFillSurface');
      if (activeSurface) {
        var surfaceName = activeSurface.get('name');
        var tablesToOmit = this.getBlueprintOptions().filter(function (tableName) {
          return tableName !== surfaceName;
        });
        this.set('activeSurface', surfaceName);
        this.set('pdfOptions.tablesToOmit', tablesToOmit);
      }
    },
    setTTROptions: function setTTROptions(panelName) {
      var currentTrueTonnageReport = this.get('store').peekRecord('flight', this.get('activeFlight')).get('trueTonnageReport');
      var shouldDisplayTrueTonnageReportOptions = this.get('showTrueTonnageReport') && currentTrueTonnageReport && currentTrueTonnageReport.get('trueTonnageReportStep.code') === 'complete';
      var ttrColumnOptions = [];

      if (panelName === 'regions-panel' && shouldDisplayTrueTonnageReportOptions) {
        ttrColumnOptions = this.getTrueTonnageReportColumns();
        var ttrColumnsToOmit = ttrColumnOptions.filter(function (option) {
          return !option.checked;
        }).map(function (option) {
          return option.name;
        });
        this.set('pdfOptions.ttrColumnsToOmit', ttrColumnsToOmit);
        this.set('columnOptions', []);
        this.set('pdfOptions.columnsToOmit', []);
      } else {
        this.set('columnOptions', this.getVolumeCalcReportColumns(['On Hand Inventory (US)', 'Tonnage Difference', '% Difference']));
        this.set('pdfOptions.ttrColumnsToOmit', []);
      }

      this.set('ttrColumnOptions', ttrColumnOptions);
      this.set('pdfOptions.totalTTRColumns', ttrColumnOptions);
    },
    setPdfOptions: function setPdfOptions(panelName) {
      var currentTrueTonnageReport = this.get('store').peekRecord('flight', this.get('activeFlight')).get('trueTonnageReport');
      var shouldDisplayTrueTonnageReportOptions = this.get('showTrueTonnageReport') && currentTrueTonnageReport && currentTrueTonnageReport.get('trueTonnageReportStep.code') === 'complete' && panelName === 'regions-panel';
      var columnOptions = [];
      var ttrColumnOptions = [];
      var tableOptions = [];
      var flightOptions = [];
      var rowOptions = [];

      if (panelName === 'regions-panel') {
        rowOptions = this.getVolumeCalcReportRows();
        this.set('pdfOptions.rowsToOmit', []);

        if (shouldDisplayTrueTonnageReportOptions) {
          ttrColumnOptions = this.getTrueTonnageReportColumns();
          var ttrColumnsToOmit = ttrColumnOptions.filter(function (option) {
            return !option.checked;
          }).map(function (option) {
            return option.name;
          });
          this.set('pdfOptions.ttrColumnsToOmit', ttrColumnsToOmit);
          this.set('pdfOptions.columnsToOmit', []);
        } else {
          columnOptions = this.getVolumeCalcReportColumns(['On Hand Inventory (US)', 'Tonnage Difference', '% Difference']);
          var columnsToOmit = columnOptions.filter(function (option) {
            return !option.checked;
          }).map(function (option) {
            return option.name;
          });
          this.set('pdfOptions.columnsToOmit', columnsToOmit);
          this.set('pdfOptions.ttrColumnsToOmit', []);
        }
      } else if (panelName === 'tracking-regions-panel') {
        columnOptions = this.getTrackingCutFillReportColumns();
        tableOptions = this.getBlueprintOptions();
        flightOptions = this.getFlightOptions();
        this.setSurfaceSelection();
      } else if (panelName === 'quantities-panel') {
        columnOptions = this.getQuantityReportColumns();
        flightOptions = this.getFlightOptions();
      } else if (panelName === 'work-activity-panel') {
        tableOptions = ['Site Measurements', 'Recent Flight Measurements', 'Flight Measurements Progress', 'Cumulative Flight Measurements'];
      }

      this.set('columnOptions', columnOptions);
      this.set('ttrColumnOptions', ttrColumnOptions);
      this.set('tableOptions', tableOptions);
      this.set('flightOptions', flightOptions);
      this.set('rowOptions', rowOptions);
      this.set('pdfOptions.useMinifiedRegionNames', false);
      this.set('pdfOptions.totalTTRColumns', ttrColumnOptions);
      this.set('pdfOptions.totalColumns', columnOptions);
      this.set('pdfOptions.totalTables', tableOptions);
    },
    setupSelectPickers: function setupSelectPickers() {
      var context = this;
      var shouldDisplayTrueTonnageReportOptions = this.get('ttrColumnOptions').length;

      $('#pdf-column-selector').selectpicker({ title: shouldDisplayTrueTonnageReportOptions ? 'Volume Calc Columns' : 'Columns' });
      $('#pdf-column-selector').on('rendered.bs.select', function () {
        return context.saveColumnSelection();
      });

      $('#pdf-ttr-column-selector').selectpicker();
      $('#pdf-ttr-column-selector').on('rendered.bs.select', function () {
        return context.saveTTRColumnSelection();
      });

      $('#pdf-table-selector').selectpicker({ title: this.get('panelName') === 'tracking-regions-panel' ? 'Comparison Surfaces' : 'Tables' });
      $('#pdf-table-selector').on('rendered.bs.select', function () {
        return context.saveTableSelection();
      });

      $('#pdf-flight-selector').selectpicker();
      $('#pdf-flight-selector').on('rendered.bs.select', function () {
        return context.saveFlightSelection();
      });

      $('#pdf-row-selector').selectpicker();
      $('#pdf-row-selector').on('rendered.bs.select', function () {
        return context.saveRowSelection();
      });
    },
    willDestroyElement: function willDestroyElement() {
      $('#pdf-column-selector').selectpicker('destroy');
      $('#pdf-ttr-column-selector').selectpicker('destroy');
      $('#pdf-table-selector').selectpicker('destroy');
      $('#pdf-flight-selector').selectpicker('destroy');
      $('#pdf-row-selector').selectpicker('destroy');

      this.set('previousPanel', null);
      this.set('pdfOptions.columnsToOmit', []);
      this.set('pdfOptions.ttrColumnsToOmit', []);
      this.set('pdfOptions.tablesToOmit', []);
      this.set('pdfOptions.rowsToOmit', []);
      this.set('pdfOptions.useMinifiedRegionNames', false);

      this.set('columnOptions', []);
      this.set('ttrColumnOptions', []);
      this.set('tableOptions', []);
      this.set('flightOptions', []);
      this.set('rowOptions', []);
    },
    getDropdownOptions: function getDropdownOptions(selectElementId) {
      return Array.from(document.querySelector(selectElementId).querySelectorAll('option'));
    },
    getUnselectedItems: function getUnselectedItems(selectElementId) {
      return this.getDropdownOptions(selectElementId).filter(function (option) {
        return !option.selected;
      }).map(function (option) {
        return option.value;
      });
    },
    saveColumnSelection: function saveColumnSelection() {
      this.set('pdfOptions.columnsToOmit', this.getUnselectedItems('#pdf-column-selector'));
    },
    saveTTRColumnSelection: function saveTTRColumnSelection() {
      this.set('pdfOptions.ttrColumnsToOmit', this.getUnselectedItems('#pdf-ttr-column-selector'));
    },
    saveTableSelection: function saveTableSelection() {
      this.set('pdfOptions.tablesToOmit', this.getUnselectedItems('#pdf-table-selector'));
    },
    saveFlightSelection: function saveFlightSelection() {
      this.set('pdfOptions.flightsToOmit', this.getUnselectedItems('#pdf-flight-selector'));
    },
    saveRowSelection: function saveRowSelection() {
      this.set('pdfOptions.rowsToOmit', this.getUnselectedItems('#pdf-row-selector'));
    },


    actions: {
      toggleArchiveExport: function toggleArchiveExport() {
        this.toggleProperty('pdfOptions.archiveExport');
      },
      toggleUseMinifiedRegionNames: function toggleUseMinifiedRegionNames() {
        this.toggleProperty('pdfOptions.useMinifiedRegionNames');
      }
    }
  });
});