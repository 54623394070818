define('webui/pods/components/message-panel/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    closeMessagePanel: 'closeMessagePanel',
    comment: '',
    isFormSaving: false,

    init: function init() {
      this._super();
      this.set('currentUser', this.get('store').peekRecord('user', this.get('session.data.authenticated.id')));
    },


    actions: {
      closeMessagePanel: function closeMessagePanel() {
        this.sendAction('closeMessagePanel');
      },
      captureComment: function captureComment(comment) {
        this.set('comment', comment);
        this.set('userIsWritingComment', comment.length > 0);
      },
      cancelComment: function cancelComment() {
        this.set('comment', '');
      },
      saveComment: function saveComment() {
        var context = this;
        var feedback = this.get('feedback');
        var store = this.get('store');

        context.set('isFormSaving', true);
        var newCommunication = store.createRecord('communication', { message: this.get('comment'), flight: this.get('flight'), createdAt: new Date() });
        newCommunication.save().then(function () {
          feedback.reportSuccess({ detail: 'Message successfully saved.' });
          context.set('comment', '');
          context.set('isFormSaving', false);
        }).catch(function () {
          newCommunication.deleteRecord();
          context.set('isFormSaving', false);
          feedback.reportError({ detail: 'Message could not be saved. Please try again.' });
        });

        this.trackEvent('Communications', { flight: this.get('flight.id') });
      }
    }
  });
});