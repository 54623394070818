define('webui/helpers/in-arr', ['exports', 'ember-inline-edit/helpers/in-arr'], function (exports, _inArr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inArr.default;
    }
  });
  Object.defineProperty(exports, 'inArr', {
    enumerable: true,
    get: function () {
      return _inArr.inArr;
    }
  });
});