define('webui/services/region-meta-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var drawingRegionHelperInformationSourceName = 'drawing-region-information';
  var drawingRegionHelperInformationElevationLayerName = 'drawing-region-elevation-information';
  var drawingRegionHelperInformationDistanceLayerName = 'drawing-region-distance-information';
  var units = 'ft';
  var GEOAPI = window.turf;

  exports.default = Ember.Service.extend({
    mapData: Ember.inject.service('mapData'),
    activePointCount: 0,

    displayRegionInformationLayerForSelectedRegion: function displayRegionInformationLayerForSelectedRegion(data) {
      var features = data.features;
      var points = data.points;


      if (features.length === 0) {
        this.removeDrawingRegionHelperInformationLayer();
      } else if (features.length === 1 && points.length === 0) {
        var regionVerticesCoordinatesFromRegion = Array.isArray(features[0].geometry.coordinates[0][0]) ? features[0].geometry.coordinates[0] : features[0].geometry.coordinates;
        this.removeDrawingRegionHelperInformationLayer();
        this.displayRegionInformationLayer(regionVerticesCoordinatesFromRegion);
      }
    },
    displayRegionInformationLayerFromMapboxDrawHotSource: function displayRegionInformationLayerFromMapboxDrawHotSource(regionType) {
      var regionVerticiesFromHotSource = this.getRegionVerticiesCoordinatesFromMapboxDrawHotSource(regionType);
      this.displayRegionInformationLayer(regionVerticiesFromHotSource, regionType);
    },
    getRegionVerticiesCoordinatesFromMapboxDrawHotSource: function getRegionVerticiesCoordinatesFromMapboxDrawHotSource(regionType) {
      var currentDrawingMode = this.get('mapData').get('currentDrawingMode');
      var source = this.get('mapData.mapInstance').getSource('mapbox-gl-draw-hot');
      var features = source._data.features;


      if (source) {
        for (var i = 0; i < features.length; i += 1) {
          var skip = currentDrawingMode !== 'polygon_volume_calc' && currentDrawingMode !== 'cross_section' && currentDrawingMode !== 'polygon_classification' && currentDrawingMode !== 'polygon_change_detection' && features[i].properties.user_observable_as !== 'polygon_volume_calc' && features[i].properties.user_observable_as !== 'cross_section' && features[i].properties.user_observable_as !== 'polygon_classification' && features[i].properties.user_observable_as !== 'polygon_change_detection';

          if (!skip && features[i].geometry.type === 'Polygon') {
            return features[i].geometry.coordinates[0];
          }

          if (!skip && features[i].geometry.type === 'LineString' && (features.length < 3 || regionType === 'cross_section')) {
            return features[i].geometry.coordinates;
          }
        }
      }

      return [];
    },
    displayRegionInformationLayer: function displayRegionInformationLayer(coordinates) {
      var activePointCount = this.get('activePointCount');
      var source = this.get('mapData.mapInstance').getSource(drawingRegionHelperInformationSourceName);

      if (coordinates.length > 0) {
        var featuresForRegionInformationLayer = this.generateFeaturesForRegionInformationLayer(coordinates);

        if (featuresForRegionInformationLayer.length !== activePointCount || !source) {
          this.removeDrawingRegionHelperInformationLayer();
          this.addDrawingRegionHelperInformationLayer(featuresForRegionInformationLayer);
          this.set('activePointCount', featuresForRegionInformationLayer.length);
        } else {
          this.updateDrawingRegionHelperInformationLayer(featuresForRegionInformationLayer);
        }

        this.set('lastCoordinates', coordinates);
      }
    },
    generateFeaturesForRegionInformationLayer: function generateFeaturesForRegionInformationLayer(regionCoordinates) {
      var _this = this;

      var mapData = this.get('mapData');
      var featuresForRegionInformationLayer = [];
      var lastCoordinates = null;
      var index = 0;

      regionCoordinates.forEach(function (coordinates) {
        if (lastCoordinates) {
          var _featureInformation = {
            index: index, coordinates: coordinates, lastCoordinates: lastCoordinates, midPointCoordinates: [(coordinates[0] + lastCoordinates[0]) / 2, (coordinates[1] + lastCoordinates[1]) / 2]
          };
          var distanceFeature = _this.generateDistanceFeature(_featureInformation);
          featuresForRegionInformationLayer.push(distanceFeature);

          index += 1;
        }

        var featureInformation = { index: index, coordinates: coordinates, elevationFeatures: mapData.getElevationFeaturesForCurrentFlight(coordinates) };
        var elevationFeature = _this.generateElevationFeature(featureInformation);
        featuresForRegionInformationLayer.push(elevationFeature);

        lastCoordinates = coordinates;
        index += 1;
      });

      return featuresForRegionInformationLayer;
    },
    generateDistanceFeature: function generateDistanceFeature(featureInformation) {
      return {
        type: 'Feature',
        properties: {
          description: this.get('mapData').calculateLength(GEOAPI.lineString([featureInformation.lastCoordinates, featureInformation.coordinates])) + ' ' + units,
          type: 'distance',
          start: featureInformation.lastCoordinates,
          end: featureInformation.coordinates,
          id: featureInformation.index
        },
        geometry: {
          type: 'Point',
          coordinates: featureInformation.midPointCoordinates
        }
      };
    },
    generateElevationFeature: function generateElevationFeature(featureInformation) {
      return {
        type: 'Feature',
        properties: {
          description: featureInformation.elevationFeatures && featureInformation.elevationFeatures[0] ? featureInformation.elevationFeatures[0].properties.label : '',
          type: 'elevation',
          id: featureInformation.index
        },
        geometry: {
          type: 'Point',
          coordinates: featureInformation.coordinates
        }
      };
    },
    addDrawingRegionHelperInformationLayer: function addDrawingRegionHelperInformationLayer(features) {
      this.get('mapData.mapInstance').addSource(drawingRegionHelperInformationSourceName, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features
        }
      });

      this.get('mapData.mapInstance').addLayer({
        id: drawingRegionHelperInformationDistanceLayerName,
        type: 'symbol',
        source: drawingRegionHelperInformationSourceName,
        layout: {
          'text-field': '{description}',
          'text-font': ['DIN Offc Pro Italic', 'Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0],
          'text-anchor': 'top',
          'text-justify': 'left',
          'text-allow-overlap': false,
          visibility: 'visible'
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#000',
          'text-halo-width': 1,
          'text-halo-blur': 2
        },

        filter: ['==', 'type', 'distance']
      });

      this.get('mapData.mapInstance').addLayer({
        id: drawingRegionHelperInformationElevationLayerName,
        type: 'symbol',
        source: drawingRegionHelperInformationSourceName,
        layout: {
          'text-field': '{description}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0],
          'text-anchor': 'top',
          'text-justify': 'left',
          'text-allow-overlap': false,
          visibility: 'visible'
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#000',
          'text-halo-width': 1,
          'text-halo-blur': 2
        },

        filter: ['==', 'type', 'elevation']
      });
    },
    updateDrawingRegionHelperInformationLayer: function updateDrawingRegionHelperInformationLayer(features) {
      var mapData = this.get('mapData');
      var map = mapData.mapInstance;
      var source = map.getSource(drawingRegionHelperInformationSourceName);

      source._data.features.forEach(function (el, index) {
        el.geometry.coordinates = features[index].geometry.coordinates;
        if (index % 2 === 1) {
          // display distance between two points
          var startIndex = index - 1;
          var endIndex = features[index + 1] ? index + 1 : 0;
          var startPoint = features[startIndex];
          var endPoint = features[endIndex];

          el.properties.description = mapData.calculateLength(GEOAPI.lineString([startPoint.geometry.coordinates, endPoint.geometry.coordinates])) + ' ' + units;
        } else {
          var elevationFeatures = mapData.getElevationFeaturesForCurrentFlight(features[index].geometry.coordinates);
          el.properties.description = elevationFeatures && elevationFeatures[0] ? elevationFeatures[0].properties.label : '';
        }
      });

      source.fire('dataloading', { dataType: 'source' });
      source._updateWorkerData(function (err) {
        if (err) {
          return source.fire('error', { error: err });
        }
        source.fire('data', { dataType: 'source', sourceDataType: 'content' });

        return null;
      });
    },
    removeDrawingRegionHelperInformationLayer: function removeDrawingRegionHelperInformationLayer() {
      var mapData = this.get('mapData');

      if (mapData.mapInstance.getLayer(drawingRegionHelperInformationElevationLayerName)) {
        mapData.mapInstance.removeLayer(drawingRegionHelperInformationElevationLayerName);
      }

      if (mapData.mapInstance.getLayer(drawingRegionHelperInformationDistanceLayerName)) {
        mapData.mapInstance.removeLayer(drawingRegionHelperInformationDistanceLayerName);
      }

      if (mapData.mapInstance.getSource(drawingRegionHelperInformationSourceName)) {
        mapData.mapInstance.removeSource(drawingRegionHelperInformationSourceName);
      }
    }
  });
});