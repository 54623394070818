define("webui/pods/components/pdf-options/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 6
                },
                "end": {
                  "line": 8,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element9, 'value');
              morphs[1] = dom.createMorphAt(element9, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "columnOption.name", ["loc", [null, [7, 25], [7, 42]]]]]]], ["content", "columnOption.name", ["loc", [null, [7, 66], [7, 87]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 6
                },
                "end": {
                  "line": 10,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element8, 'value');
              morphs[1] = dom.createMorphAt(element8, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "columnOption.name", ["loc", [null, [9, 25], [9, 42]]]]]]], ["content", "columnOption.name", ["loc", [null, [9, 46], [9, 67]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/pdf-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "columnOption.checked", ["loc", [null, [6, 12], [6, 32]]]]], [], 0, 1, ["loc", [null, [6, 6], [10, 13]]]]],
          locals: ["columnOption"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/pdf-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "pdf-column-selector");
          dom.setAttribute(el1, "class", "selectpicker open-right");
          dom.setAttribute(el1, "data-width", "fit");
          dom.setAttribute(el1, "multiple", "");
          dom.setAttribute(el1, "data-actions-box", "true");
          dom.setAttribute(el1, "data-selected-text-format", "static");
          dom.setAttribute(el1, "title", "Columns");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "columnOptions", ["loc", [null, [5, 12], [5, 25]]]]], [], 0, null, ["loc", [null, [5, 4], [11, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 6
                },
                "end": {
                  "line": 20,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element7, 'value');
              morphs[1] = dom.createMorphAt(element7, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "columnOption.name", ["loc", [null, [19, 25], [19, 42]]]]]]], ["content", "columnOption.name", ["loc", [null, [19, 66], [19, 87]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 6
                },
                "end": {
                  "line": 22,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element6, 'value');
              morphs[1] = dom.createMorphAt(element6, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "columnOption.name", ["loc", [null, [21, 25], [21, 42]]]]]]], ["content", "columnOption.name", ["loc", [null, [21, 46], [21, 67]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 4
              },
              "end": {
                "line": 23,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/pdf-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "columnOption.checked", ["loc", [null, [18, 12], [18, 32]]]]], [], 0, 1, ["loc", [null, [18, 6], [22, 13]]]]],
          locals: ["columnOption"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/pdf-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "pdf-ttr-column-selector");
          dom.setAttribute(el1, "class", "selectpicker open-right");
          dom.setAttribute(el1, "data-width", "fit");
          dom.setAttribute(el1, "multiple", "");
          dom.setAttribute(el1, "data-actions-box", "true");
          dom.setAttribute(el1, "data-selected-text-format", "static");
          dom.setAttribute(el1, "title", "True Tonnage Columns");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "ttrColumnOptions", ["loc", [null, [17, 12], [17, 28]]]]], [], 0, null, ["loc", [null, [17, 4], [23, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 4
              },
              "end": {
                "line": 31,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/pdf-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            dom.setAttribute(el1, "selected", "selected");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'value');
            morphs[1] = dom.createMorphAt(element5, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "rowOption", ["loc", [null, [30, 25], [30, 34]]]]]]], ["content", "rowOption", ["loc", [null, [30, 58], [30, 71]]]]],
          locals: ["rowOption"],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 33,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/pdf-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "pdf-row-selector");
          dom.setAttribute(el1, "class", "selectpicker open-right");
          dom.setAttribute(el1, "data-width", "fit");
          dom.setAttribute(el1, "multiple", "");
          dom.setAttribute(el1, "data-actions-box", "true");
          dom.setAttribute(el1, "data-selected-text-format", "static");
          dom.setAttribute(el1, "title", "Rows");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "rowOptions", ["loc", [null, [29, 12], [29, 22]]]]], [], 0, null, ["loc", [null, [29, 4], [31, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 6
                },
                "end": {
                  "line": 40,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element4, 'value');
              morphs[1] = dom.createMorphAt(element4, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "tableOption", ["loc", [null, [39, 25], [39, 36]]]]]]], ["content", "tableOption", ["loc", [null, [39, 60], [39, 75]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 6
                },
                "end": {
                  "line": 42,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element3, 'value');
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "tableOption", ["loc", [null, [41, 25], [41, 36]]]]]]], ["content", "tableOption", ["loc", [null, [41, 40], [41, 55]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 4
              },
              "end": {
                "line": 43,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/pdf-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "tableOption", ["loc", [null, [38, 20], [38, 31]]]], ["get", "activeSurface", ["loc", [null, [38, 32], [38, 45]]]]], [], ["loc", [null, [38, 16], [38, 46]]]], ["subexpr", "eq", [["get", "panelName", ["loc", [null, [38, 51], [38, 60]]]], "work-activity-panel"], [], ["loc", [null, [38, 47], [38, 83]]]]], [], ["loc", [null, [38, 12], [38, 84]]]]], [], 0, 1, ["loc", [null, [38, 6], [42, 13]]]]],
          locals: ["tableOption"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/pdf-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "pdf-table-selector");
          dom.setAttribute(el1, "class", "selectpicker open-right");
          dom.setAttribute(el1, "data-width", "fit");
          dom.setAttribute(el1, "multiple", "");
          dom.setAttribute(el1, "data-actions-box", "true");
          dom.setAttribute(el1, "data-selected-text-format", "static");
          dom.setAttribute(el1, "title", "Tables");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "tableOptions", ["loc", [null, [37, 12], [37, 24]]]]], [], 0, null, ["loc", [null, [37, 4], [43, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 6
                },
                "end": {
                  "line": 52,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "map-subtitle");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element2, 'value');
              morphs[1] = dom.createMorphAt(element2, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "flight.id", ["loc", [null, [51, 25], [51, 34]]]]]]], ["content", "flight.displayFlightTime", ["loc", [null, [51, 58], [51, 86]]]], ["content", "flight.subtitle", ["loc", [null, [51, 113], [51, 132]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 6
                },
                "end": {
                  "line": 54,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/pdf-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "map-subtitle");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'value');
              morphs[1] = dom.createMorphAt(element1, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "flight.id", ["loc", [null, [53, 25], [53, 34]]]]]]], ["content", "flight.displayFlightTime", ["loc", [null, [53, 38], [53, 66]]]], ["content", "flight.subtitle", ["loc", [null, [53, 93], [53, 112]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 4
              },
              "end": {
                "line": 55,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/pdf-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "flight.selected", ["loc", [null, [50, 12], [50, 27]]]]], [], 0, 1, ["loc", [null, [50, 6], [54, 13]]]]],
          locals: ["flight"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 57,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/pdf-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("select");
          dom.setAttribute(el1, "id", "pdf-flight-selector");
          dom.setAttribute(el1, "class", "selectpicker open-right");
          dom.setAttribute(el1, "data-width", "fit");
          dom.setAttribute(el1, "multiple", "");
          dom.setAttribute(el1, "data-actions-box", "true");
          dom.setAttribute(el1, "data-selected-text-format", "static");
          dom.setAttribute(el1, "title", "Flights");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "flightOptions", ["loc", [null, [49, 12], [49, 25]]]]], [], 0, null, ["loc", [null, [49, 4], [55, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/pdf-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1, "type", "checkbox");
          dom.setAttribute(el1, "id", "min-names");
          dom.setAttribute(el1, "name", "min-names");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  Number Regions  \n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          if (this.cachedFragment) {
            dom.repairClonedNode(element0, [], true);
          }
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'checked');
          morphs[1] = dom.createAttrMorph(element0, 'onchange');
          return morphs;
        },
        statements: [["attribute", "checked", ["get", "pdfOptions.useMinifiedRegionNames", ["loc", [null, [60, 67], [60, 100]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleUseMinifiedRegionNames"], [], ["loc", [null, [60, 112], [60, 153]]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/pdf-options/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "checkbox");
        dom.setAttribute(el1, "id", "archive");
        dom.setAttribute(el1, "name", "archive");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("  Archive  \n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element10, [], true);
        }
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element10, 'checked');
        morphs[1] = dom.createAttrMorph(element10, 'onchange');
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "checked", ["get", "pdfOptions.archiveExport", ["loc", [null, [1, 61], [1, 85]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleArchiveExport"], [], ["loc", [null, [1, 97], [1, 129]]]]], ["block", "if", [["get", "columnOptions.length", ["loc", [null, [3, 6], [3, 26]]]]], [], 0, null, ["loc", [null, [3, 0], [13, 7]]]], ["block", "if", [["get", "ttrColumnOptions.length", ["loc", [null, [15, 6], [15, 29]]]]], [], 1, null, ["loc", [null, [15, 0], [25, 7]]]], ["block", "if", [["get", "rowOptions.length", ["loc", [null, [27, 6], [27, 23]]]]], [], 2, null, ["loc", [null, [27, 0], [33, 7]]]], ["block", "if", [["get", "tableOptions.length", ["loc", [null, [35, 6], [35, 25]]]]], [], 3, null, ["loc", [null, [35, 0], [45, 7]]]], ["block", "if", [["get", "flightOptions.length", ["loc", [null, [47, 6], [47, 26]]]]], [], 4, null, ["loc", [null, [47, 0], [57, 7]]]], ["block", "if", [["subexpr", "eq", [["get", "openedPanel", ["loc", [null, [59, 10], [59, 21]]]], "regions-panel"], [], ["loc", [null, [59, 6], [59, 38]]]]], [], 5, null, ["loc", [null, [59, 0], [61, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});