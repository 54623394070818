define('webui/pods/components/quality-reports-panel/component', ['exports', 'npm:react', 'npm:react-dom', 'ember-cli-segment/mixin', 'webui/react/QualityReportsPanel', 'webui/config/environment'], function (exports, _npmReact, _npmReactDom, _mixin, _QualityReportsPanel, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    mapData: Ember.inject.service('map-data'),
    store: Ember.inject.service('store'),
    feedback: Ember.inject.service('user-feedback'),

    makeReactElement: function makeReactElement() {
      this.set('reactComponent', _npmReact.default.createElement(_QualityReportsPanel.default, {
        apiURL: _environment.default.APP.apiUrl,
        isReadOnly: this.get('authenticatedUser.isLimitedAccess') || this.get('authenticatedUser.isViewOnly'),
        flight: this.get('activeFlight'),
        mapData: this.get('mapData'),
        dataService: this.get('store'),
        feedback: this.get('feedback'),
        emberComponent: this,
        emberComponentParent: this.get('parent')
      }));
    },
    renderReactElement: function renderReactElement() {
      _npmReactDom.default.render(this.get('reactComponent'), document.getElementById('quality-reports-react-mount-point'));
    },
    generateReactElement: function generateReactElement() {
      this.makeReactElement();
      this.renderReactElement();
    },
    didRender: function didRender() {
      this.generateReactElement();
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      var mountPoint = document.getElementById('quality-reports-react-mount-point');
      if (mountPoint) {
        _npmReactDom.default.unmountComponentAtNode(document.getElementById('quality-reports-react-mount-point'));
      }
    },


    actions: {
      displayInfo: function displayInfo() {
        $('#point-sample-info-modal').modal('show');
      },
      displayError: function displayError(error, errorDetails) {
        this.set('error', error);
        this.set('errorDetails', errorDetails);
        $('#point-sample-error-modal').modal('show');
      }
    }
  });
});