define('webui/models/quantity', ['exports', 'ember-data', 'webui/mixins/numerical-conversion', 'webui/mixins/string-formatting', 'npm:lodash', 'npm:polylabel'], function (exports, _emberData, _numericalConversion, _stringFormatting, _npmLodash, _npmPolylabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_numericalConversion.default, _stringFormatting.default, {
    mapData: Ember.inject.service('map-data'),

    name: _emberData.default.attr('string'),
    featureType: _emberData.default.attr('string'),
    featureGeometry: _emberData.default.attr(),
    site: _emberData.default.belongsTo('site', { async: true }),
    blueprints: _emberData.default.hasMany('blueprint', { async: true }),
    staticElevations: _emberData.default.attr(),
    report: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    display: true,
    area: _emberData.default.attr('number'),
    areaDisplay: Ember.computed('area', function areaDisplay() {
      return this.numberWithCommas(this.get('area'));
    }),

    // ember only
    messageDisplay: Ember.computed('message', function messageDisplay() {
      var message = '';

      if (this.get('message.name')) {
        message += this.get('message.name') + ': ';
      }

      if (this.get('message.message')) {
        message += this.get('message.message');
      }

      if (this.get('message.statusCode')) {
        message += '(' + this.get('message.statusCode') + ')';
      }

      return message;
    }),

    succeeded: Ember.computed('status', function succeeded() {
      if (this.get('status') === 'succeeded') {
        return true;
      }
      return false;
    }),
    pending: Ember.computed('status', function pending() {
      if (this.get('status') === 'pending') {
        return true;
      }
      return false;
    }),
    waiting: Ember.computed('status', function waiting() {
      if (this.get('status') === 'waiting') {
        return true;
      }
      return false;
    }),
    warning: Ember.computed('status', function warning() {
      if (this.get('status') === 'warning') {
        return true;
      }
      return false;
    }),
    failed: Ember.computed('status', function failed() {
      if (this.get('status') === 'failed') {
        return true;
      }
      return false;
    }),

    mapboxPointFeature: Ember.computed('name', 'featureGeometry', function mapboxPointFeature() {
      var coords = _npmLodash.default.map(this.get('featureGeometry'), function (point) {
        return [point.lng, point.lat];
      });
      var center = (0, _npmPolylabel.default)([coords]);

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: center
        },
        properties: {
          name: this.get('name')
        }
      };
    }),

    chartOptions: {
      showArea: false,
      lineSmooth: false,
      onlyInteger: true,
      axisX: {
        showGrid: false
      }
    },

    chartData: Ember.computed('earthworkAnalyses', function chartData() {
      var context = this;
      var earthworkAnalyses = this.get('earthworkAnalyses');
      var sortedEarthworks = earthworkAnalyses.sortBy('executeAt');
      var data = {};

      sortedEarthworks.forEach(function (earthwork) {
        if (earthwork.get('status') === 'pending' || earthwork.get('status') === 'failed') {
          return;
        }

        var blueprint = earthwork.get('blueprint.id');
        var yardsCut = earthwork.get('cubicYardsCut') ? earthwork.get('cubicYardsCut') : 0;
        var yardsFill = earthwork.get('cubicYardsFill') ? earthwork.get('cubicYardsFill') : 0;

        if (!data[blueprint]) {
          data[blueprint] = {
            labels: [],
            series: [[], []]
          };
        }

        data[blueprint].labels.push(context.dateString(earthwork.get('executeAt')));
        data[blueprint].series[0].push({ meta: 'Cut', value: yardsCut });
        data[blueprint].series[1].push({ meta: 'Fill', value: yardsFill });
      });

      return data;
    })
  });
});