define('webui/pods/components/photo-upload-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fileManager: Ember.inject.service('file-manager'),

    errorQueue: null,
    pendingQueue: null,
    finishedQueue: null,

    errorFiles: Ember.A(),
    pendingFiles: Ember.A(),
    finishedFiles: Ember.A(),

    init: function init() {
      var component = this;
      var fileManager = this.get('fileManager');
      var currentFlight = fileManager.get('currentFlight');

      this._super();

      /*
        All of these observers are designed to update the display of file queues.  In order
        to have live DOM updates, these need to be ember mutable array objects, which need to
        be updated explicitly from the file manager's vanilla queues.
      */

      fileManager.addObserver('filesAdded', function () {
        component.set('pendingQueue', fileManager.get('queues.' + currentFlight + '.files'));
        component.populateAddedFiles();
      });

      fileManager.addObserver('currentFile', function () {
        // observers for managing display of ember array objects
        component.set('errorQueue', fileManager.get('queues.' + currentFlight + '.errorFiles'));
        component.set('pendingQueue', fileManager.get('queues.' + currentFlight + '.files'));
        component.set('finishedQueue', fileManager.get('queues.' + currentFlight + '.finishedFiles'));

        if (component.get('errorQueue.length') === 0) {
          component.set('errorFiles', component.get('errorFiles').clear());
        }
        if (component.get('pendingQueue.length') === 0) {
          component.set('pendingFiles', component.get('pendingFiles').clear());
        }

        component.updatePhotoUploadStatus();
      });

      fileManager.addObserver('uploadComplete', function uploadComplete() {
        this.set('pendingFiles', this.get('pendingFiles').clear());
        fileManager.set('uploadComplete', false);
      });
    },
    didInsertElement: function didInsertElement() {
      this.set('pendingFiles', this.get('pendingFiles').clear());
      this.set('finishedFiles', this.get('finishedFiles').clear());
      this.set('errorFiles', this.get('errorFiles').clear());
    },
    willDestroyElement: function willDestroyElement() {
      var fileManager = this.get('fileManager');
      fileManager.removeObserver('filesAdded');
      fileManager.removeObserver('currentFile');
      fileManager.removeObserver('uploadComplete');
      this.set('pendingFiles', this.get('pendingFiles').clear());
      this.set('finishedFiles', this.get('finishedFiles').clear());
      this.set('errorFiles', this.get('errorFiles').clear());
    },
    addToEmberArray: function addToEmberArray(array, values, source) {
      var target = this.get(array);
      var length = values.length;

      var i = void 0;var obj = void 0;

      for (i = 0; i < length; i += 1) {
        obj = target.findBy('name', values[i].name);
        if (!obj) {
          target.pushObject(values[i]);
          if (source) {
            this.removeFromEmberArray(source, values[i]);
          }
        }

        if (i === length - 1) {
          return this.set(array, target.sortBy('name'));
        }
      }

      return null;
    },
    removeFromEmberArray: function removeFromEmberArray(array, value) {
      var target = this.get(array);
      return this.set(array, target.removeObject(value).compact());
    },
    populateAddedFiles: function populateAddedFiles() {
      return this.addToEmberArray('pendingFiles', this.get('pendingQueue'));
    },
    updatePhotoUploadStatus: function updatePhotoUploadStatus() {
      // a method for updating the photos as they're uploaded
      this.addToEmberArray('finishedFiles', this.get('finishedQueue'), 'pendingFiles');
      return this.addToEmberArray('errorFiles', this.get('errorQueue'), 'pendingFiles');

      // this.set('photoUrlPrefix', Config.APP.S3host);
    }
  });
});