define('webui/pods/components/front-end-uploader/component', ['exports', 'moment', 'ember-cli-segment/mixin'], function (exports, _moment, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    flightId: null,
    files: null,
    site: null,
    currDateTime: null,
    dateTimeFormat: 'Do MMMM YYYY, h:mm a',
    uploader: Ember.inject.service('xhr-uploader'),
    requestParams: {},

    feedback: Ember.inject.service('user-feedback'),

    didInsertElement: function didInsertElement() {
      var component = this;

      this.set('currDateTime', (0, _moment.default)());

      $('[name="file"]').bind('change', function change() {
        component.files = this.files;
      });
    },


    actions: {
      closeDataPanel: function closeDataPanel() {
        this.get('dataPanelService').set('isOpen', false);
      },
      selectSite: function selectSite(site) {
        this.set('site', site);
      },
      submitUpload: function submitUpload() {
        var component = this;
        var datetime = $('.date.input-group').find('input').val();
        var fullDate = (0, _moment.default)(datetime, this.get('dateTimeFormat'));
        var uploadParams = void 0;

        var feedback = this.get('feedback');

        if (!Ember.isEmpty(component.activeSite)) {
          component.site = this.get('activeSite.id');
        }

        if (Ember.isEmpty(component.files)) {
          feedback.reportError({ title: 'Upload error', detail: 'No files selected.' });
        } else if (Ember.isEmpty(component.site)) {
          feedback.reportError({ title: 'Upload error', detail: 'No site selected' });
        } else if (!fullDate.isValid() && !component.blueprintMode) {
          feedback.reportError({ title: 'Upload error', detail: 'No flight date/time selected.' });
        } else {
          $('#submitBtn').prop('disabled', true).html('Uploading..');
          var data = { executeAt: fullDate.toISOString(), siteId: component.site };

          uploadParams = {
            data: data,
            file: component.files,
            controller: 'jobs',
            method: 'upload',
            fieldname: 'file',
            context: component,
            progressBar: 'UploadProgressIndicator'
          };

          if (component.requestParams.remoteController === 'sites') {
            uploadParams.uuid = component.site;
          }

          component.get('uploader').upload(uploadParams, function (err) {
            $('#submitBtn').prop('disabled', false).html('Upload');
            if (err) {
              feedback.handleResponse(err);
            } else {
              $('[name="file"]').val('');
              $('.date.input-group').find('input').val('');
              component.set('UploadProgressIndicator', '');
              feedback.reportSuccess('Upload successful');
              component.trackEvent('Customer Photo Upload', { site: component.site });
            }
          });
        }
      }
    }
  });
});