define('webui/pods/components/coordinate-converter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mapData: Ember.inject.service('map-data'),
    store: Ember.inject.service('store'),
    statePlane: Ember.inject.service('state-plane'),
    validConversionTypes: ['globalToLocal', 'localToGlobal'],
    p4RtkAntennaHeight: 0.2113, // meters
    drtk2AntennaHeight: 1.8011, // meters
    antennaCorrection: null,
    antennaHeight: 0,
    conversionType: null,
    result: null,
    defaultMapCenter: [-95, 39],
    selectedEpsgCode: '3419',
    units: 'feet',
    debouncedCalculate: null,

    init: function init() {
      this._super();
      var defaultConversionType = this.get('validConversionTypes.firstObject');
      this.set('conversionType', defaultConversionType);

      var epsgDict = this.get('statePlane').getAllEpsgCodes();
      this.set('epsgToName', epsgDict);
      this.set('epsgOptions', Object.keys(epsgDict));

      this.setupSocketOnHandler();
      var debouncedCalculate = _.debounce(this.calculate, 500, {
        leading: true,
        trailing: true
      });
      this.set('debouncedCalculate', debouncedCalculate);
    },
    didInsertElement: function didInsertElement() {
      this.setupMapDisplay();
    },
    setupSocketOnHandler: function setupSocketOnHandler() {
      var _this = this;

      var context = this;

      io.socket.on('coordinateConversion', function (event) {
        if (event.verb === 'updated') {
          var _event$data = event.data,
              result = _event$data.result,
              status = _event$data.status,
              id = _event$data.id;

          if (id !== _this.get('coordinateConversion.id')) {
            return;
          }
          context.set('status', status);
          if (status === 'succeeded') {
            context.updateResult(result);
          } else if (event.data.status === 'failed') {
            var errorMessage = { title: 'Error', detail: 'Coordinates could not be converted, please try again.' };
            if (event.data.message) {
              errorMessage = { title: event.data.message.name, detail: event.data.message.message };
            }
            context.set('errorMessage', errorMessage);
          }
        }
      });
    },
    setupMapDisplay: function setupMapDisplay() {
      var mapboxToken = this.get('mapData.accessToken');
      window.mapboxgl.accessToken = mapboxToken;

      var style = {
        version: 8,
        sources: {
          'base-layer': {
            type: 'raster',
            tiles: ['https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + mapboxToken],
            tileSize: 512,
            zoomOffset: -1
          },
          'satellite-streets-layer': {
            type: 'raster',
            tiles: ['https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=' + mapboxToken],
            tileSize: 512,
            zoomOffset: -1
          },
          'draw-source': {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] }
          }
        },
        layers: [{
          id: 'id-base-layer',
          type: 'raster',
          source: 'base-layer',
          'source-layer': 'base-layer',
          minzoom: 0,
          maxzoom: 24
        }, {
          id: 'id-satellite-streets-layer',
          type: 'raster',
          source: 'satellite-streets-layer',
          'source-layer': 'satellite-streets-layer',
          minzoom: 0,
          maxzoom: 24,
          layout: { visibility: 'none' }
        }],
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        sprite: 'mapbox://sprites/mapbox/light-v9'
      };

      var map = new window.mapboxgl.Map({
        container: 'coordinate-map',
        style: style,
        center: this.get('defaultMapCenter'),
        zoom: 3.5,
        dragRotate: false,
        touchZoomRotate: false,
        keyboard: false,
        minZoom: 3,
        maxZoom: 20,
        doubleClickZoom: false
      });

      this.set('map', map);

      map.on('load', function () {
        map.getCanvas().style.cursor = 'crosshair';
        map.addLayer({
          id: 'coordinate-pin',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: []
            }
          },
          layout: {
            'icon-image': '{icon}',
            'icon-size': 1
          }
        });
      });

      map.on('mousemove', function (e) {
        document.getElementById('map-info').innerHTML = 'lat: ' + e.lngLat.lat + ' &ensp; lng: ' + e.lngLat.lng;
      });

      map.on('mouseout', function () {
        document.getElementById('map-info').innerHTML = '';
      });

      var i = new Image();
      var xhr = new XMLHttpRequest();
      xhr.open('GET', '/assets/images/glyphicons/png/glyphicons-378-riflescope.png');
      xhr.responseType = 'arraybuffer';
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        i.onload = function () {
          map.addImage('riflescope', i);
          urlCreator.revokeObjectURL(i.src);
        };

        var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

        var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
        i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
      };
      xhr.send();
    },
    resetMapView: function resetMapView() {
      var map = this.get('map');
      map.getSource('coordinate-pin').setData({
        type: 'FeatureCollection',
        features: []
      });

      map.flyTo({
        center: this.get('defaultMapCenter'),
        essential: true,
        speed: 2,
        bearing: 0,
        zoom: 3.5
      });

      map.dragPan.enable();
      map.scrollZoom.disable();
      map.scrollZoom.enable();
    },
    updateMapPin: function updateMapPin(latitude, longitude) {
      var map = this.get('map');
      var features = [{
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude]
        },
        properties: {
          icon: 'riflescope',
          height: 10,
          width: 10
        }
      }];

      try {
        map.getSource('coordinate-pin').setData({
          type: 'FeatureCollection',
          features: features
        });

        map.flyTo({
          center: [longitude, latitude],
          essential: true,
          speed: 3,
          bearing: 0,
          zoom: 15
        });

        map.dragPan.disable();
        map.scrollZoom.disable();
        map.scrollZoom.enable({ around: 'center' });
      } catch (err) {
        this.resetMapView();
      }
    },
    handleLatLonUpdate: function handleLatLonUpdate() {
      var latitude = this.get('latitude');
      var longitude = this.get('longitude');
      if (latitude && longitude) {
        if (!this.get('epsgWasSelectedByUser')) {
          this.detectEpsgCode(latitude, longitude);
        }
        this.updateMapPin(latitude, longitude);
      } else {
        this.resetMapView();
      }
    },
    detectEpsgCode: function detectEpsgCode(latitude, longitude) {
      var statePlane = this.get('statePlane').latLonToStatePlane(latitude, longitude);
      if (statePlane) {
        var epsg = statePlane.properties.defaultEPSG;
        var units = statePlane.properties.defaultUnits;
        this.set('selectedEpsgCode', epsg);
        this.set('units', units);
      }
    },
    resetResult: function resetResult() {
      var _this2 = this;

      this.set('result', null);
      this.set('status', null);
      this.set('coordinateConversion', null);
      Ember.run.next(function () {
        _this2.get('debouncedCalculate')(_this2);
      });
    },
    updateResult: function updateResult(result) {
      this.set('result', result);
      if (result.latitude && result.longitude) {
        this.updateMapPin(result.latitude, result.longitude);
      }
    },
    calculate: function calculate() {
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this;

      var conversionType = context.get('conversionType');
      var x = void 0;
      var y = void 0;
      var z = void 0;
      if (conversionType === 'globalToLocal') {
        x = context.get('longitude');
        y = context.get('latitude');
        z = context.get('elipsoidElevationMeters');
      } else {
        x = context.get('easting');
        y = context.get('northing');
        z = context.get('orthoElevation');
      }

      var isInputInvalid = !(x && y && z);
      if (isInputInvalid) {
        return;
      }

      var coordinateConversion = {
        type: conversionType,
        epsgCode: parseInt(context.get('selectedEpsgCode'), 10),
        antennaHeight: context.get('antennaHeight'),
        x: parseFloat(x),
        y: parseFloat(y),
        z: parseFloat(z),
        status: 'pending'
      };

      io.socket.post('/v1/coordinateConversions', { coordinateConversion: coordinateConversion }, function (res) {
        context.set('status', res.coordinateConversion.status);
        if (res.coordinateConversion.status === 'failed') {
          var errorMessage = { title: 'Error', detail: 'Coordinates could not be converted, please try again.' };
          if (res.coordinateConversion.message) {
            errorMessage.detail = res.coordinateConversion.message.message;
          }
          context.set('errorMessage', errorMessage);
        } else {
          context.set('coordinateConversion', res.coordinateConversion);
        }
      });
    },


    actions: {
      setConversionType: function setConversionType(index) {
        var newConversionType = this.get('validConversionTypes')[index];
        this.resetResult();
        this.set('conversionType', newConversionType);
        if (newConversionType === 'globalToLocal') {
          this.updateMapPin(this.get('latitude'), this.get('longitude'));
        } else {
          this.resetMapView();
        }
      },
      updateAntennaHeight: function updateAntennaHeight(antennaHeight) {
        var antennaCorrection = null;
        if (parseFloat(antennaHeight) === this.get('p4RtkAntennaHeight')) {
          antennaCorrection = 'p4rtk';
        } else if (parseFloat(antennaHeight) === this.get('drtk2AntennaHeight')) {
          antennaCorrection = 'drtk2';
        }
        this.set('antennaCorrection', antennaCorrection);
        this.set('antennaHeight', parseFloat(antennaHeight));
        this.resetResult();
      },
      updateLatitude: function updateLatitude(value) {
        this.set('latitude', value);
        this.handleLatLonUpdate();
        this.resetResult();
      },
      updateLongitude: function updateLongitude(value) {
        this.set('longitude', value);
        this.handleLatLonUpdate();
        this.resetResult();
      },
      updateElipsoidElevation: function updateElipsoidElevation(value) {
        this.set('elipsoidElevationMeters', value);
        this.resetResult();
      },
      updateEasting: function updateEasting(value) {
        this.set('easting', value);
        this.resetMapView();
        this.resetResult();
      },
      updateNorthing: function updateNorthing(value) {
        this.set('northing', value);
        this.resetMapView();
        this.resetResult();
      },
      updateOrthoElevation: function updateOrthoElevation(value) {
        this.set('orthoElevation', value);
        this.resetResult();
      },
      updateEpsgCode: function updateEpsgCode(epsgCode) {
        var units = this.get('statePlane').epsgToUnits(epsgCode);
        this.set('selectedEpsgCode', epsgCode);
        this.set('units', units);
        this.set('epsgWasSelectedByUser', true);
        this.resetResult();
      },
      toggleSatelliteView: function toggleSatelliteView() {
        var map = this.get('map');

        var currentVisibility = map.getLayoutProperty('id-satellite-streets-layer', 'visibility');

        map.setLayoutProperty('id-satellite-streets-layer', 'visibility', currentVisibility === 'none' ? 'visible' : 'none');
      }
    }
  });
});