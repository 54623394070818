define('webui/pods/reset/request/route', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    feedback: Ember.inject.service('user-feedback'),
    actions: {
      request: function request(email) {
        var url = _environment.default.APP.apiUrl + 'users/forgot';
        var route = this;

        if (!email) {
          this.controller.set('emailError', 'You must enter your email address.');
          return;
        }

        $.ajax({
          method: 'POST',
          contentType: 'json',
          url: url,
          data: JSON.stringify({
            email: email
          }),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).done(function () {
          route.transitionTo('reset.sent');
        }).fail(function (response) {
          var feedback = route.get('feedback');
          if (response.status === 403) {
            route.controller.set('emailNotFound', true);
            return;
          }
          feedback.reportError(response.responseJSON);
          console.error(response);
        });
      }
    }
  });
});