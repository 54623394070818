define('webui/models/communication', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    message: _emberData.default.attr('string'),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    user: _emberData.default.belongsTo('user', { async: true }),
    createdAt: _emberData.default.attr('date'),

    displayDate: function displayDate() {
      var date = this.get('createdAt').toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
      var time = this.get('createdAt').toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

      return date + ', ' + time;
    }.property('executeAt')
  });
});