define('webui/models/quality-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    flight: _emberData.default.belongsTo('flight', { async: true }),
    label: _emberData.default.attr('string'),
    photosCount: _emberData.default.attr('number'),
    gcpsCount: _emberData.default.attr('number'),
    groundSamplingDistance: _emberData.default.attr('number'),
    rmsError: _emberData.default.attr('string'),
    rmsErrorZ: _emberData.default.attr('string'),
    coordinateSystem: _emberData.default.attr('string'),
    gcpPoints: _emberData.default.attr()
  });
});