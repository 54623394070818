define('webui/pods/admin/job/history/route', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend({
    jobReportsUrl: _environment.default.APP.apiUrl + 'jobstatushistories',
    columns: [{
      propertyName: 'Customer'
    }, {
      propertyName: 'SiteName',
      title: 'Site Name'
    }, {
      propertyName: 'SiteID',
      title: 'Site ID',
      isHidden: true
    }, {
      propertyName: 'FlightDate',
      sortedBy: 'FlightDateRaw',
      title: 'Flight Date'
    }, {
      propertyName: 'JobID',
      title: 'Job ID',
      isHidden: true
    }, {
      propertyName: 'UploadedBy',
      title: 'Uploaded By'
    }, {
      component: 'total-hours-for-table',
      title: 'Total Hours',
      propertyName: 'totalHoursInSeconds'
    }, {
      propertyName: 'NumberOfPhotos',
      title: 'Number of Photos'
    }, {
      propertyName: 'pending-duration',
      title: 'Pending (Idle after Upload)',
      isHidden: true
    }, {
      propertyName: 'running-part-one-duration',
      title: 'Running Part One',
      isHidden: true
    }, {
      propertyName: 'needs-human-duration',
      title: 'Prepping for Part Two (Needs Human)',
      isHidden: true
    }, {
      propertyName: 'human-verified-duration',
      title: 'Ready for Part Two',
      isHidden: true
    }, {
      propertyName: 'running-part-two-duration',
      title: 'Running Part Two',
      isHidden: true
    }, {
      propertyName: 'failed-part-one-duration',
      title: 'Failed Part One',
      isHidden: true
    }, {
      propertyName: 'failed-part-two-duration',
      title: 'Failed Part Two',
      isHidden: true
    }, {
      propertyName: 'GCPUploaded',
      sortedBy: 'GCPUploadedRaw',
      title: 'GCP Uploaded',
      sortPrecedence: 1,
      sortDirection: 'desc'
    }, {
      propertyName: 'running-part-one-timestamp',
      sortedBy: 'running-part-one-timestampRaw',
      title: 'Running Part One'
    }, {
      propertyName: 'needs-human-timestamp',
      sortedBy: 'needs-human-timestampRaw',
      title: 'Needs Human'
    }, {
      propertyName: 'human-verified-timestamp',
      sortedBy: 'human-verified-timestampRaw',
      title: 'Human Verified'
    }, {
      propertyName: 'running-part-two-timestamp',
      sortedBy: 'running-part-two-timestampRaw',
      title: 'Running Part Two'
    }, {
      propertyName: 'succeeded-timestamp',
      sortedBy: 'succeeded-timestampRaw',
      title: 'Succeeded'
    }, {
      propertyName: 'failed-part-one-timestamp',
      sortedBy: 'failed-part-one-timestampRaw',
      title: 'Failed Part One',
      isHidden: true
    }, {
      propertyName: 'failed-part-two-timestamp',
      sortedBy: 'failed-part-two-timestampRaw',
      title: 'Failed Part Two',
      isHidden: true
    }],

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('columns', this.get('columns'));

      return Ember.RSVP.resolve(Ember.$.ajax({
        method: 'GET',
        contentType: 'json',
        url: this.get('jobReportsUrl'),
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).then(function (results) {
        var rows = results.map(function (row) {
          var extendedRow = row;

          if (row.TotalHours) {
            var _row$TotalHours$split = row.TotalHours.split(':'),
                _row$TotalHours$split2 = _slicedToArray(_row$TotalHours$split, 3),
                hours = _row$TotalHours$split2[0],
                minutes = _row$TotalHours$split2[1],
                seconds = _row$TotalHours$split2[2];

            var totalSeconds = hours * 3600 + minutes * 60 + seconds;

            extendedRow.totalHoursTruncated = parseInt(hours, 10);
            extendedRow.totalHoursInSeconds = parseInt(totalSeconds, 10);
          }

          return row;
        });
        return Ember.run(function () {
          return controller.set('model', rows);
        });
      }, function (xhr) {
        var errorMessage = 'Try again later.';
        if (xhr.responseJSON && xhr.responseJSON.detail) {
          errorMessage = xhr.responseJSON.detail;
        }
        return Ember.run(function () {
          return controller.set('errorMessage', errorMessage);
        });
      }));
    }
  });
});