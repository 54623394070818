define("webui/pods/components/true-tonnage-report/editor-table/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 73
              },
              "end": {
                "line": 18,
                "column": 137
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "material.key", ["loc", [null, [18, 121], [18, 137]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 137
              },
              "end": {
                "line": 18,
                "column": 162
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "material.name", ["loc", [null, [18, 145], [18, 162]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 14
                },
                "end": {
                  "line": 31,
                  "column": 14
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element6, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["glyphicon ", ["subexpr", "if", [["get", "material.showRegions", ["loc", [null, [30, 44], [30, 64]]]], "glyphicon-chevron-up", "glyphicon-chevron-down"], [], ["loc", [null, [30, 39], [30, 114]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 10
              },
              "end": {
                "line": 33,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createElementMorph(element7);
            morphs[2] = dom.createMorphAt(element7, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["btn btn-table-action ", ["subexpr", "if", [["get", "material.showRegions", ["loc", [null, [28, 53], [28, 73]]]], "active", ""], [], ["loc", [null, [28, 48], [28, 87]]]]]]], ["element", "action", ["toggleRegions", ["get", "material", ["loc", [null, [28, 114], [28, 122]]]]], [], ["loc", [null, [28, 89], [28, 124]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Show All Regions</p>", "tooltipPlace", "left"], 0, null, ["loc", [null, [29, 14], [31, 27]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 81
                  },
                  "end": {
                    "line": 40,
                    "column": 141
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "region.nameWithMaterialCode", ["loc", [null, [40, 110], [40, 141]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 141
                  },
                  "end": {
                    "line": 40,
                    "column": 164
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "region.name", ["loc", [null, [40, 149], [40, 164]]]]],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 16
                  },
                  "end": {
                    "line": 52,
                    "column": 16
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "glyphicon glyphicon-list-alt");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child3 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 10
                  },
                  "end": {
                    "line": 63,
                    "column": 10
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "colspan", "9");
                dom.setAttribute(el2, "style", "padding:0;");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "true-tonnage-report/true-tonnage-report-revision-table", [], ["revisions", ["subexpr", "@mut", [["get", "region.revisions", ["loc", [null, [60, 83], [60, 99]]]]], [], []]], ["loc", [null, [60, 16], [60, 101]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 8
                },
                "end": {
                  "line": 64,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "scope", "row");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              dom.setAttribute(el2, "style", "padding: 2px;");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var element4 = dom.childAt(element3, [1]);
              var element5 = dom.childAt(element3, [17, 1]);
              var morphs = new Array(13);
              morphs[0] = dom.createAttrMorph(element4, 'class');
              morphs[1] = dom.createMorphAt(element4, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element3, [5]), 0, 0);
              morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]), 0, 0);
              morphs[5] = dom.createMorphAt(dom.childAt(element3, [9]), 0, 0);
              morphs[6] = dom.createMorphAt(dom.childAt(element3, [11]), 0, 0);
              morphs[7] = dom.createMorphAt(dom.childAt(element3, [13]), 0, 0);
              morphs[8] = dom.createMorphAt(dom.childAt(element3, [15]), 0, 0);
              morphs[9] = dom.createAttrMorph(element5, 'class');
              morphs[10] = dom.createElementMorph(element5);
              morphs[11] = dom.createMorphAt(element5, 1, 1);
              morphs[12] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "region.regionWasModified", ["loc", [null, [40, 40], [40, 64]]]], "warning", ""], [], ["loc", [null, [40, 35], [40, 79]]]]]]], ["block", "if", [["get", "displayMaterialAsCode", ["loc", [null, [40, 87], [40, 108]]]]], [], 0, 1, ["loc", [null, [40, 81], [40, 171]]]], ["content", "region.netVolume", ["loc", [null, [41, 35], [41, 55]]]], ["content", "region.density", ["loc", [null, [42, 35], [42, 53]]]], ["content", "region.displayTonnage", ["loc", [null, [43, 35], [43, 60]]]], ["content", "region.plannedTonnage", ["loc", [null, [44, 35], [44, 60]]]], ["content", "region.changeRequestTonnage", ["loc", [null, [45, 35], [45, 66]]]], ["content", "region.changeRequestReason", ["loc", [null, [46, 16], [46, 46]]]], ["content", "region.changeRequestedBy", ["loc", [null, [47, 16], [47, 44]]]], ["attribute", "class", ["concat", ["btn btn-table-action ", ["subexpr", "if", [["get", "region.showRevisions", ["loc", [null, [49, 55], [49, 75]]]], "active", ""], [], ["loc", [null, [49, 50], [49, 89]]]]]]], ["element", "action", ["toggleRevisions", ["get", "region", ["loc", [null, [49, 118], [49, 124]]]]], [], ["loc", [null, [49, 91], [49, 126]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Show Revisions</p>", "tooltipPlace", "left"], 2, null, ["loc", [null, [50, 16], [52, 29]]]], ["block", "if", [["get", "region.showRevisions", ["loc", [null, [57, 16], [57, 36]]]]], [], 3, null, ["loc", [null, [57, 10], [63, 17]]]]],
            locals: ["region"],
            templates: [child0, child1, child2, child3]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 6
              },
              "end": {
                "line": 65,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["subexpr", "sort-by", ["name", ["get", "material.regionData", ["loc", [null, [38, 32], [38, 51]]]]], [], ["loc", [null, [38, 16], [38, 52]]]]], [], 0, null, ["loc", [null, [38, 8], [64, 17]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 4
            },
            "end": {
              "line": 66,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          dom.setAttribute(el1, "class", "active");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "scope", "row");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "text-right");
          dom.setAttribute(el2, "style", "padding: 2px;");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [1]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element9, 'class');
          morphs[1] = dom.createMorphAt(element9, 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element8, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element8, [7]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element8, [9]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element8, [11]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element8, [13]), 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element8, [15]), 0, 0);
          morphs[9] = dom.createMorphAt(dom.childAt(element8, [17]), 1, 1);
          morphs[10] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "material.wasModified", ["loc", [null, [18, 36], [18, 56]]]], "warning", ""], [], ["loc", [null, [18, 31], [18, 71]]]]]]], ["block", "if", [["subexpr", "and", [["get", "displayMaterialAsCode", ["loc", [null, [18, 84], [18, 105]]]], ["get", "material.key", ["loc", [null, [18, 106], [18, 118]]]]], [], ["loc", [null, [18, 79], [18, 119]]]]], [], 0, 1, ["loc", [null, [18, 73], [18, 169]]]], ["content", "material.volume", ["loc", [null, [19, 31], [19, 50]]]], ["content", "material.density", ["loc", [null, [20, 31], [20, 51]]]], ["content", "material.tonnage", ["loc", [null, [21, 31], [21, 51]]]], ["content", "material.plannedTonnage", ["loc", [null, [22, 31], [22, 58]]]], ["content", "material.changeRequestTonnage", ["loc", [null, [23, 31], [23, 64]]]], ["content", "material.changeRequestReason", ["loc", [null, [24, 12], [24, 44]]]], ["content", "material.changeRequestedBy", ["loc", [null, [25, 12], [25, 42]]]], ["block", "unless", [["subexpr", "eq", [["get", "material.name", ["loc", [null, [27, 24], [27, 37]]]], "Total"], [], ["loc", [null, [27, 20], [27, 46]]]]], [], 2, null, ["loc", [null, [27, 10], [33, 21]]]], ["block", "if", [["get", "material.showRegions", ["loc", [null, [37, 12], [37, 32]]]]], [], 3, null, ["loc", [null, [37, 6], [65, 13]]]]],
        locals: ["material"],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 36
                },
                "end": {
                  "line": 78,
                  "column": 100
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "material.key", ["loc", [null, [78, 84], [78, 100]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 100
                },
                "end": {
                  "line": 78,
                  "column": 125
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "material.name", ["loc", [null, [78, 108], [78, 125]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 2
              },
              "end": {
                "line": 84,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "change-request-row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-2");
            var el3 = dom.createElement("strong");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-2 text-right");
            var el3 = dom.createTextNode("On Hand Inventory:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-8");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("input");
            dom.setAttribute(el3, "class", "form-control ember-view ember-text-field");
            dom.setAttribute(el3, "type", "number");
            dom.setAttribute(el3, "placeholder", "On Hand Inventory");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [5, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 0]), 0, 0);
            morphs[1] = dom.createAttrMorph(element2, 'data-material-id');
            morphs[2] = dom.createAttrMorph(element2, 'value');
            morphs[3] = dom.createAttrMorph(element2, 'oninput');
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "displayMaterialAsCode", ["loc", [null, [78, 47], [78, 68]]]], ["get", "material.key", ["loc", [null, [78, 69], [78, 81]]]]], [], ["loc", [null, [78, 42], [78, 82]]]]], [], 0, 1, ["loc", [null, [78, 36], [78, 132]]]], ["attribute", "data-material-id", ["get", "material.materialId", ["loc", [null, [81, 129], [81, 148]]]]], ["attribute", "value", ["get", "material.plannedTonnage", ["loc", [null, [81, 159], [81, 182]]]]], ["attribute", "oninput", ["subexpr", "action", ["updatePlannedTonnage"], [], ["loc", [null, [81, 193], [81, 226]]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 89,
                    "column": 30
                  },
                  "end": {
                    "line": 89,
                    "column": 90
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "region.nameWithMaterialCode", ["loc", [null, [89, 59], [89, 90]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 89,
                    "column": 90
                  },
                  "end": {
                    "line": 89,
                    "column": 113
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "region.name", ["loc", [null, [89, 98], [89, 113]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 4
                },
                "end": {
                  "line": 95,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "change-request-row");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-2");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-2 text-right");
              var el3 = dom.createTextNode("Change Notes:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-8");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "displayMaterialAsCode", ["loc", [null, [89, 36], [89, 57]]]]], [], 0, 1, ["loc", [null, [89, 30], [89, 120]]]], ["inline", "input", [], ["type", "text", "class", "form-control", "value", ["subexpr", "@mut", [["get", "region.newChangeRequestFulfilledComment", ["loc", [null, [92, 57], [92, 96]]]]], [], []], "placeholder", "Change Notes"], ["loc", [null, [92, 10], [92, 125]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 2
              },
              "end": {
                "line": 96,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "region.regionWasModified", ["loc", [null, [87, 10], [87, 34]]]]], [], 0, null, ["loc", [null, [87, 4], [95, 11]]]]],
          locals: ["region"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 75,
              "column": 0
            },
            "end": {
              "line": 97,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "material.changeRequested", ["loc", [null, [76, 8], [76, 32]]]]], [], 0, null, ["loc", [null, [76, 2], [84, 9]]]], ["block", "each", [["subexpr", "sort-by", ["name", ["get", "material.regionData", ["loc", [null, [86, 26], [86, 45]]]]], [], ["loc", [null, [86, 10], [86, 46]]]]], [], 1, null, ["loc", [null, [86, 2], [96, 11]]]]],
        locals: ["material"],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 106,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/true-tonnage-report/editor-table/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "table table-sm table-bordered table-sticky");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("Region");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("Volume (yd");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("sup");
        var el6 = dom.createTextNode("3");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("Density (tons/yd");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("sup");
        var el6 = dom.createTextNode("3");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("True Tonnage (US Tons)");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("On Hand Inventory");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("Expected Tonnage");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("Reason For Change Request");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("Change Requested By");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "style", "width:35px;");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bs-callout bs-callout-primary bs-callout-slim");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("Changes Requested");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Please review the regions and materials below.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        return morphs;
      },
      statements: [["block", "each", [["get", "reportData", ["loc", [null, [16, 12], [16, 22]]]]], [], 0, null, ["loc", [null, [16, 4], [66, 13]]]], ["block", "each", [["get", "reportData", ["loc", [null, [75, 8], [75, 18]]]]], [], 1, null, ["loc", [null, [75, 0], [97, 9]]]], ["inline", "true-tonnage-report/true-tonnage-report-form", [], ["deleteTrueTonnageReport", ["subexpr", "action", ["deleteTrueTonnageReport"], [], ["loc", [null, [100, 26], [100, 60]]]], "submitTrueTonnageReport", ["subexpr", "action", ["submitTrueTonnageReport"], [], ["loc", [null, [101, 26], [101, 60]]]], "isFormSaving", ["subexpr", "@mut", [["get", "isFormSaving", ["loc", [null, [102, 15], [102, 27]]]]], [], []], "canSubmitTrueTonnageForm", true, "canDeleteTrueTonnageForm", ["subexpr", "@mut", [["get", "canDeleteTrueTonnageForm", ["loc", [null, [104, 27], [104, 51]]]]], [], []]], ["loc", [null, [99, 0], [105, 2]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});