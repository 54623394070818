define('webui/services/file-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    uploader: Ember.inject.service('xhr-uploader'),
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),

    // queues are indexed by FK UUID, at this time that will be flightId for photo
    // uploads, but it should work seamlessly for other applications
    /* *
     * Example structure hierarchy:
     * queues: {
     *  73f1057a-88b9-4c65-809e-31baac39aff8: { //The flight ID used here is dynamically added
     *    segments: {
     *      flightPhotos: { //The segment key is arbitrary
     *         apiParameters: {
     *            ..., //Whatever is passed as part of the upload params
     *            onSuccess: function(){} //Optional callback to execute whenever the segment queue is fully uploaded
     *         },
     *         filesAdded: null,
     *         filesChanged: Date.now(),
     *         files: [],
     *         finishedFiles: [],
     *         errorFiles: []
     *      }
     *    },
     *    status: 'pending', //one of pending|completed|failed
     *    onCompleted: function(){} //Optional callback to execute whenever the entire queue for this flight is completed.
     *  }
     * }
     * */
    queues: {},
    currentFlight: null,
    currentFile: null,
    filesAdded: null,
    filesChanged: null,
    uploadComplete: false,

    addBatch: function addBatch(flightId, segment, fileList) {
      var fileNames = [];

      for (var i = 0; i < fileList.length; i += 1) {
        this.pushFile(flightId, segment, fileList[i]);
        fileNames.push(fileList[i].name);
      }

      this.set('filesAdded', fileNames.join('|')); // this should provide a unique-ish key to trigger rerendering on drop.
      return this.getQueueSegmentLength(flightId, segment);
    },
    createQueue: function createQueue(flightId, onCompleted) {
      var newQueue = {
        flightId: flightId,
        segments: {},
        processedSegments: [],
        status: 'pending'
      };

      if (typeof onCompleted === 'function') {
        newQueue.onCompleted = onCompleted;
      }

      var store = this.get('store');
      var service = this;
      store.findRecord('flight', flightId).then(function (record) {
        service.set('queues.' + flightId + '.flight', record);
      });

      this.set('queues.' + flightId, newQueue);
      this.set('queues.' + flightId + '.observableSegments', Ember.A()); /* This is used by status-panel service to monitor for new segments. */

      return newQueue;
    },
    createQueueSegment: function createQueueSegment(flightId, segment, apiParameters) {
      if (!flightId) {
        return false;
      }

      var queue = this.getQueue(flightId);

      if (!queue) {
        queue = this.createQueue(flightId);
      }

      if (queue.segments[segment]) {
        return null; /* this segment name already exists. */
      }

      queue.segments[segment] = {
        name: segment,
        apiParameters: apiParameters,
        filesAdded: null,
        filesChanged: Date.now(),
        fileUploaded: '', /* TODO: file manager observers can monitor this for updates on uploads */
        fileCount: 0,
        fileProcessedCount: 0,
        files: [],
        finishedFiles: [],
        uploadTimeout: null,
        attemptedRetries: 0,
        errorFiles: []
      };

      this.set('queues.' + flightId, queue);
      this.get('queues.' + flightId + '.observableSegments').pushObject(segment);

      return queue.segments[segment];
    },
    getQueue: function getQueue(flightId) {
      var queues = this.get('queues');
      var queue = queues[flightId];

      if (!queue || typeof queue === 'undefined' || queue.length === 0) {
        return null;
      }

      return queue;
    },
    getQueueSegment: function getQueueSegment(flightId, segment) {
      var queue = this.getQueue(flightId);

      if (queue && queue.segments[segment]) {
        return queue.segments[segment];
      }

      return null;
    },
    getAllQueues: function getAllQueues() {
      return this.get('queues');
    },
    getQueueCount: function getQueueCount() {
      var queues = this.getAllQueues();
      var queueTally = 0;

      Object.keys(queues).filter(function (queue) {
        return Object.prototype.hasOwnProperty.call(queues, queue);
      }).forEach(function () {
        queueTally += 1;
      });

      return queueTally;
    },
    getFinished: function getFinished(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      return queueSegment.finishedFiles;
    },
    getErrors: function getErrors(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      return queueSegment.errorFiles;
    },
    getQueueSegmentLength: function getQueueSegmentLength(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      if (!queueSegment || !queueSegment.files) {
        return 0;
      }

      return queueSegment.files.length;
    },
    getQueueSegmentStatus: function getQueueSegmentStatus(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      var total = queueSegment.files.length + queueSegment.finishedFiles.length + queueSegment.errorFiles.length;

      return {
        pendingFiles: queueSegment.files.length,
        finishedFiles: queueSegment.finishedFiles.length,
        errorFiles: queueSegment.errorFiles.length,
        totalFiles: total
      };
    },
    pushFile: function pushFile(flightId, segment, file) {
      var feedback = this.get('feedback');
      var queueSegment = this.getQueueSegment(flightId, segment);

      if (!queueSegment) {
        queueSegment = this.createQueueSegment(flightId, segment);
      }

      if (file.constructor.name && file.constructor.name === 'File' || !file.constructor.name && file.toString().indexOf('File') > 0) {
        /* This IF covers a fairly obscure case where a user selects a file that was already added resulting in a duplicate entry to the array. This ensures only one file each time. */
        if (!queueSegment.files.some(function (el) {
          return el.name === file.name && el.lastModified === file.lastModified;
        })) {
          queueSegment.files.push(file);
          this.setQueueSegmentFilesChanged(flightId, segment, file);
        }
      } else {
        feedback.reportError({ title: 'Error attaching file', detail: 'Attachment does not appear to be a valid JPG file' });
      }
    },
    popFile: function popFile(flightId /* , segment */) {
      var queueSegment = this.getQueueSegment(flightId);
      return queueSegment.files.pop();
    },
    deleteQueue: function deleteQueue(flightId) {
      var queues = this.getAllQueues();
      if (queues[flightId]) {
        delete queues[flightId];
        this.notifyPropertyChange('queues');
        return true;
      }
      return false;
    },
    clearQueueSegment: function clearQueueSegment(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      if (queueSegment) {
        queueSegment.files.length = 0;
        queueSegment.errorFiles.length = 0;
        queueSegment.finishedFiles.length = 0;
        this.setQueueSegmentFilesChanged(flightId, segment);
      }
    },
    emptyQueueSegment: function emptyQueueSegment(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      if (queueSegment) {
        queueSegment.files.length = 0; /* empty the array without losing the reference to the original object */
        this.setQueueSegmentFilesChanged(flightId, segment);
      }
    },
    uploadQueueSegment: function uploadQueueSegment(uploadFlightId, uploadSegment) {
      var service = this;
      var uploader = this.get('uploader');
      var queueSegment = this.getQueueSegment(uploadFlightId, uploadSegment);
      var fileList = queueSegment.files;
      var errorFiles = queueSegment.errorFiles;
      var uploadTimeout = queueSegment.uploadTimeout;

      var queueLength = service.get('queues.' + uploadFlightId + '.segments.' + uploadSegment + '.fileCount');

      uploader.set('currentUploadType', uploadSegment);

      if (!queueLength) {
        // set length for the first time around, so that retries don't reset total
        service.set('queues.' + uploadFlightId + '.segments.' + uploadSegment + '.fileCount', queueSegment.files.length);
      }

      var advanceUploadQueue = function advanceUploadQueue(flightId, segment) {
        var file = fileList[0]; // when removed from the queue, we have to remove with shift.

        // this is the callback from the xhr.upload method
        // eslint-disable-next-line no-unused-vars
        var handleResponse = function handleResponse(response) {
          /* Unsure how exactly response is being used below in try/catch so disabling linter. Seems like a vessel to dump output if the parser doesn't fail. */
          var delay = false;

          if (uploadTimeout) {
            clearTimeout(uploadTimeout);
            service.setQueueSegmentUploadtimeout(flightId, segment, null);
          }

          file = fileList.shift();

          try {
            JSON.parse(response);
            /* TODO add generated details from the response to the original object */
            service.set('queues.' + flightId + '.segments.' + segment + '.fileUploaded', file);
            queueSegment.finishedFiles.push(file);
            service.set('queues.' + flightId + '.segments.' + segment + '.fileProcessedCount', queueSegment.finishedFiles.length);
          } catch (err) {
            delay = true;
            errorFiles.push(file); // necessary step for retries.
          }

          service.set('currentFile', null);
          service.set('currentFlight', flightId);

          if (!delay) {
            return advanceUploadQueue(flightId, segment);
          }

          // delaying the advance by five seconds so that we don't advance too quickly
          // through the queue when there's a network problem.
          return service.setQueueSegmentUploadtimeout(flightId, segment, setTimeout(function () {
            return advanceUploadQueue(flightId, segment);
          }, 5000));
        };

        var uploadParams = queueSegment.apiParameters;
        if (file && file.taggedGroundControlPoints) uploadParams.data.taggedGroundControlPoints = JSON.stringify(file.taggedGroundControlPoints);
        uploadParams.data.file = file;

        if (fileList.length === 0) {
          if (errorFiles.length === 0) {
            queueSegment.completed = true;
            service.onSegmentProcessed(flightId, segment);

            if (typeof uploadParams.onSuccess === 'function') {
              return uploadParams.onSuccess(flightId, segment);
            }
          } else {
            service.increaseQueueSegmentAttemptedRetries(flightId, segment);
            if (queueSegment.attemptedRetries < 3) {
              service.retryUpload(flightId, segment);
            } else if (segment === 'flightPhotos') {
              service.onSegmentFailed(flightId);
            }
          }
          return true;
        }

        service.set('currentFile', file);

        return uploader.upload(uploadParams, handleResponse, { filePointer: file, name: file.name });
      };

      advanceUploadQueue(uploadFlightId, uploadSegment);
    },
    uploadQueue: function uploadQueue(flightId) {
      var _this = this;

      var queue = this.getQueue(flightId);
      var feedback = this.get('feedback');
      var store = this.get('store');
      var flight = store.peekRecord('flight', flightId);

      Object.keys(queue.segments).filter(function (segment) {
        return Object.prototype.hasOwnProperty.call(queue.segments, segment);
      }).forEach(function (segment) {
        _this.uploadQueueSegment(flightId, segment);

        if (segment === 'flightPhotos') {
          feedback.reportInfo({
            title: 'Upload Started',
            detail: 'Upload started for ' + flight.get('site.name') + ' - ' + flight.get('displayFlightTime') + '. Please keep this tab open and remain logged in until the upload is complete.'
          });
        }
      });
    },
    retryUpload: function retryUpload(flightId, segment) {
      var service = this;
      var queueSegment = this.getQueueSegment(flightId, segment);
      var errorQueue = queueSegment.errorFiles;

      while (errorQueue.length > 0) {
        var file = errorQueue.pop();
        this.pushFile(flightId, segment, file);
      }

      // If we have files to retry, it may be due to network problems, so let's delay for
      // a few seconds to give the UI a chance to reconnect.

      service.setQueueSegmentUploadtimeout(flightId, segment, setTimeout(function () {
        return service.uploadQueueSegment(flightId, segment);
      }, 8000));
    },
    setContextProperty: function setContextProperty(context, property, value) {
      if (!context.isDestroyed) {
        context.set(property, value);
      }
    },
    increaseQueueSegmentAttemptedRetries: function increaseQueueSegmentAttemptedRetries(flightId, segment) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      if (queueSegment) {
        this.set('queues.' + flightId + '.segments.' + segment + '.attemptedRetries', this.get('queues.' + flightId + '.segments.' + segment + '.attemptedRetries') + 1);
      }
    },
    setQueueSegmentUploadtimeout: function setQueueSegmentUploadtimeout(flightId, segment, uploadTimeout) {
      var queueSegment = this.getQueueSegment(flightId, segment);
      if (queueSegment) {
        this.set('queues.' + flightId + '.segments.' + segment + '.uploadTimeout', uploadTimeout);
      }
    },
    setQueueSegmentFilesChanged: function setQueueSegmentFilesChanged(flightId, segment, file) {
      if (!file) {
        file = { name: 'empty' };
      }
      var queueSegment = this.getQueueSegment(flightId, segment);
      if (queueSegment) {
        this.set('queues.' + flightId + '.segments.' + segment + '.filesChanged', Date.now() + segment + file.name); /* This is to generate an as-close-to-unique-as-is-reasonable to ensure the observers are always fired */
      }
    },
    onSegmentFailed: function onSegmentFailed(flightId) {
      var feedback = this.get('feedback');
      var store = this.get('store');
      var queue = this.getQueue(flightId);
      var flight = store.peekRecord('flight', flightId);

      this.set('queues.' + queue.flightId + '.status', 'failed');
      feedback.reportError({
        title: 'Upload Error',
        detail: 'An error occurred while uploading ' + flight.get('site.name') + ' - ' + flight.get('displayFlightTime') + '. This was most likely caused by an interruption in internet connectivity. Please check your connection and re-upload this map.'
      });
    },
    onSegmentProcessed: function onSegmentProcessed(flightId, segment) {
      var queue = this.getQueue(flightId);
      queue.processedSegments.push(segment);

      var incompleteSegments = Object.keys(queue.segments).filter(function (s) {
        return Object.prototype.hasOwnProperty.call(queue.segments, s) && !queue.segments[s].completed;
      });

      var queueComplete = incompleteSegments.length === 0;
      if (queueComplete && queue.onCompleted) {
        this.set('queues.' + queue.flightId + '.status', 'completed');
        queue.onCompleted(flightId);
      }
    }
  });
});