define('webui/services/socket-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    mapData: Ember.inject.service('mapData'),

    flightUpdate: null,
    tiledMapUpdate: null,
    activeFlightId: null,

    monitorSockets: function monitorSockets(activeSiteId, activeFlightId) {
      this.set('activeSiteId', activeSiteId);
      this.set('activeFlightId', activeFlightId);
      this.resetSockets();

      if (activeFlightId) {
        this.setupSocketHandlersForCurrentFlight();
        this.subscribeToSocketUpdatesForCurrentFlight();
      } else if (activeSiteId) {
        this.subscribeToSocketUpdatesForCurrentSite();
      }
    },
    resetSockets: function resetSockets() {
      io.socket.off('tiledmap');
      io.socket.off('flight');
    },
    setupSocketHandlersForCurrentFlight: function setupSocketHandlersForCurrentFlight() {
      var _this = this;

      io.socket.on('flight', function (event) {
        return _this.handleFlightResponse(event);
      });
      io.socket.on('tiledmap', function (event) {
        return _this.handleTiledMapResponse(event);
      });
      io.socket.on('changedetection', function (event) {
        return _this.handleChangeDetectionResponse(event);
      });
    },
    subscribeToSocketUpdatesForCurrentFlight: function subscribeToSocketUpdatesForCurrentFlight() {
      io.socket.get('/v1/flights/' + this.get('activeFlightId'), function (data) {
        return data.tiledMaps.forEach(function (map) {
          return io.socket.get('/v1/tiledMaps/' + map.id);
        });
      });
    },
    subscribeToSocketUpdatesForCurrentSite: function subscribeToSocketUpdatesForCurrentSite() {
      var store = this.get('store');

      store.query('blueprint', { site: this.get('activeSiteId') }).then(function (blueprints) {
        blueprints.forEach(function (blueprint) {
          io.socket.get('/v1/blueprints/' + blueprint.get('id'));

          if (blueprint.get('tiledMap.id')) {
            io.socket.get('/v1/tiledMaps/' + blueprint.get('tiledMap.id'));
          }
        });
      });
    },
    handleChangeDetectionResponse: function handleChangeDetectionResponse(event) {
      if (event.verb === 'updated') {
        this.get('store').push({
          data: {
            type: 'changeDetection',
            id: event.id,
            attributes: event.data
          }
        });
      }
    },
    handleFlightResponse: function handleFlightResponse(event) {
      switch (event.verb) {
        case 'addedTo':
          this.handleFlightAddedToVerb(event);
          break;

        case 'updated':
          this.handleFlightUpdatedVerb(event);
          break;

        default:
          break;
      }
    },
    handleFlightAddedToVerb: function handleFlightAddedToVerb(event) {
      if (event.attribute === 'tiledMaps') {
        io.socket.get('/v1/tiledMaps/' + event.added.id);
      }
    },
    handleFlightUpdatedVerb: function handleFlightUpdatedVerb(event) {
      if (event.data.classificationUpdate || event.data.classificationPreviewUpdate) {
        this.updateActiveFlight(event);
      }
    },
    updateActiveFlight: function updateActiveFlight(event) {
      var _this2 = this;

      this.get('store').peekRecord('flight', this.get('activeFlightId')).reload().then(function () {
        return _this2.set('flightUpdate', event);
      });
    },
    handleTiledMapResponse: function handleTiledMapResponse(event) {
      switch (event.verb) {
        case 'updated':
          this.handleTiledMapUpdatedVerb(event);
          break;

        case 'destroyed':
          this.handleTiledMapDestroyedVerb(event);
          break;

        default:
          break;
      }
    },
    handleTiledMapUpdatedVerb: function handleTiledMapUpdatedVerb(event) {
      this.addUpdatedMapLayer(event);
      this.updateTiledMapsForActiveFlight(event);
    },
    addUpdatedMapLayer: function addUpdatedMapLayer(event) {
      if (['digital-surface-model', 'digital-surface-model-preview', 'contour', 'elevation-data', 'slope-data'].indexOf(event.data.type) !== -1) {
        this.get('mapData').updateLayerForTiledMap(event.data);
      }
    },
    handleTiledMapDestroyedVerb: function handleTiledMapDestroyedVerb(event) {
      var destroyedTiledMapId = event.id;

      this.turnOffDestroyedMapLayer(destroyedTiledMapId);
      this.updateTiledMapsForActiveFlight(event);
    },
    turnOffDestroyedMapLayer: function turnOffDestroyedMapLayer(destroyedTiledMapId) {
      var store = this.get('store');
      var mapData = this.get('mapData');
      var tiledMap = store.peekRecord('tiledMap', destroyedTiledMapId);

      if (tiledMap) {
        var mapType = tiledMap.get('type');

        if (mapData.get('mapInstance') && mapData.doesLayerExist(mapType) && mapData.layerVisible(mapType) && mapType !== 'elevation-data') {
          mapData.toggleLayerVisibility(mapType);
        }
      }
    },
    updateTiledMapsForActiveFlight: function updateTiledMapsForActiveFlight(event) {
      var _this3 = this;

      var store = this.get('store');
      var activeFlightId = this.get('activeFlightId');
      var activeFlight = store.peekRecord('flight', activeFlightId);

      store.query('tiledMap', { flight: activeFlightId }).then(function () {
        return activeFlight.reload().then(function () {
          return _this3.set('tiledMapUpdate', event);
        });
      });
    }
  });
});