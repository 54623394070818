define('webui/pods/components/confirm-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleConfirmation: function toggleConfirmation() {
        this.toggleProperty('isShowingConfirmation');
        this.sendAction('toggleAction', this.get('param'));
      },
      confirm: function confirm() {
        this.toggleProperty('isShowingConfirmation');
        this.sendAction('action', this.get('param'));
      }
    }
  });
});