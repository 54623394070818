define('webui/pods/components/site-access-panel-user-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    user: null,
    editMode: false,

    pendingAccess: null,

    classNames: ['row', 'pane'],

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      removeUser: function removeUser() {
        this.sendAction('removeUserRelation', this.get('user'));
      },
      editUser: function editUser() {
        if (this.get('editMode')) {
          this.set('editMode', false);
        } else {
          this.set('editMode', true);
        }
      },
      saveUser: function saveUser() {
        var _this = this;

        /* This is essentially a dirty check to see if we need to save. */
        if (this.get('pendingAccess')) {
          this.get('user.currentSiteAccessRef').set('access', this.get('pendingAccess'));
          this.get('user.currentSiteAccessRef').save().then(function (us) {
            _this.set('user.currentSiteAccessRef', us); /* This is pretty much just a safety to make sure the latest UserSite record is stored. */
          });
        }
        this.set('editMode', false);
      },
      setUserAccess: function setUserAccess(access) {
        this.set('pendingAccess', access);
      }
    }
  });
});