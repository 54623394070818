define('webui/pods/components/status-dropdown/status-panel-file-manager-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fileManager: Ember.inject.service('file-manager'),
    store: Ember.inject.service('store'),

    tagName: 'ul',
    classNames: ['notification-list'],

    queues: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('queues', Ember.A()); /* enumerable reference to the FileManager queues */

      var fileManager = this.get('fileManager');
      var observedQueues = Ember.A();

      var glom = function glom(queues, queue) {
        Object.keys(queues[queue].segments).forEach(function (segment) {
          if (Object.prototype.hasOwnProperty.call(queues[queue].segments, segment)) {
            if (observedQueues.indexOf('queues.' + queue + '.segments.' + segment) === -1) {
              observedQueues.pushObject('queues.' + queue + '.segments.' + segment);

              fileManager.addObserver('queues.' + queue + '.segments.' + segment + '.fileUploaded', function () /* sender, key */{
                /* Not currently used */
              });
            }
          }
        });
      };

      var queues = fileManager.getAllQueues();
      Object.keys(queues).forEach(function (queue) {
        if (Object.prototype.hasOwnProperty.call(queues, queue)) {
          _this.get('queues').pushObject(queues[queue]);
          fileManager.addObserver('queues.' + queue + '.observableSegments.length', function (sender /* , key */) {
            glom(sender.getAllQueues(), queue);
          });
          glom(queues, queue);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      /* Theoretically, all the above observers need removed when this is destroyed, but that's not a straight-forward concept. */
      // let flightId = this.get('flightId');
      // let fileManager = this.get('fileManager');
      // fileManager.removeObserver(...);
    }
  });
});