define('webui/initializers/services', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'dataPanelService', 'service:data-panel');
    application.inject('controller', 'dataPanelService', 'service:data-panel');
    application.inject('component', 'dataPanelService', 'service:data-panel');
  }

  exports.default = {
    name: 'services',
    initialize: initialize
  };
});