define("webui/pods/components/client-overview/team-roster/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 20
                },
                "end": {
                  "line": 22,
                  "column": 20
                }
              },
              "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "badge");
              var el2 = dom.createTextNode("Team Roster");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 20
                  },
                  "end": {
                    "line": 34,
                    "column": 20
                  }
                },
                "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "site-access-table", [], ["sites", ["subexpr", "@mut", [["get", "model.sites", ["loc", [null, [33, 48], [33, 59]]]]], [], []], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [33, 65], [33, 69]]]]], [], []], "changeAccessLevels", ["subexpr", "action", ["changeAccessLevels"], [], ["loc", [null, [33, 89], [33, 118]]]]], ["loc", [null, [33, 22], [33, 120]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 18
                },
                "end": {
                  "line": 40,
                  "column": 18
                }
              },
              "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.setAttribute(el1, "style", "margin:10px 0;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "margin:10px 15px");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              var el3 = dom.createTextNode("Team Roster User:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.setAttribute(el1, "style", "margin:0;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "margin:10px 0;display:flex;justify-content:flex-end;");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn accent");
              dom.setAttribute(el2, "style", "margin-right:15px;");
              dom.setAttribute(el2, "type", "submit");
              var el3 = dom.createTextNode("Save");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn nofill");
              dom.setAttribute(el2, "style", "margin-right:15px;");
              var el3 = dom.createTextNode("Cancel");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [9]);
              var element5 = dom.childAt(element4, [1]);
              var element6 = dom.childAt(element4, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 3, 3);
              morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[2] = dom.createElementMorph(element5);
              morphs[3] = dom.createElementMorph(element6);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "checkbox", "name", "isTeamRoster", "checked", ["subexpr", "@mut", [["get", "currentUserIsTeamRoster", ["loc", [null, [29, 74], [29, 97]]]]], [], []]], ["loc", [null, [29, 22], [29, 99]]]], ["block", "unless", [["get", "currentUserIsTeamRoster", ["loc", [null, [32, 30], [32, 53]]]]], [], 0, null, ["loc", [null, [32, 20], [34, 31]]]], ["element", "action", ["updateSiteAccess", ["get", "user", ["loc", [null, [37, 104], [37, 108]]]]], [], ["loc", [null, [37, 76], [37, 110]]]], ["element", "action", ["toggleSiteAccessTable", ["get", "user", ["loc", [null, [38, 109], [38, 113]]]]], [], ["loc", [null, [38, 76], [38, 115]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 14
              },
              "end": {
                "line": 42,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "margin:10px 15px");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("strong");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" - ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "glyphicon glyphicon-remove pull-right clickable");
            dom.setAttribute(el3, "title", "Remove User");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "glyphicon glyphicon-pencil pull-right clickable");
            dom.setAttribute(el3, "style", "margin-right:15px;");
            dom.setAttribute(el3, "title", "Edit Site Access");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(element7, [1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var element11 = dom.childAt(element8, [5]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element9, [0]), 0, 0);
            morphs[2] = dom.createMorphAt(element9, 2, 2);
            morphs[3] = dom.createElementMorph(element10);
            morphs[4] = dom.createElementMorph(element11);
            morphs[5] = dom.createMorphAt(element8, 7, 7);
            morphs[6] = dom.createMorphAt(element7, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["list-group-item no-padding ", ["subexpr", "if", [["get", "user.showSiteAccessTable", ["loc", [null, [15, 59], [15, 83]]]], "list-group-item-open", ""], [], ["loc", [null, [15, 54], [15, 111]]]]]]], ["content", "user.fullName", ["loc", [null, [17, 34], [17, 51]]]], ["content", "user.email", ["loc", [null, [17, 63], [17, 77]]]], ["element", "action", ["confirmUserRemoval", ["get", "user.id", ["loc", [null, [18, 132], [18, 139]]]]], [], ["loc", [null, [18, 102], [18, 141]]]], ["element", "action", ["toggleSiteAccessTable", ["get", "user", ["loc", [null, [19, 167], [19, 171]]]]], [], ["loc", [null, [19, 134], [19, 173]]]], ["block", "if", [["get", "user.isOnTeamRoster", ["loc", [null, [20, 26], [20, 45]]]]], [], 0, null, ["loc", [null, [20, 20], [22, 27]]]], ["block", "if", [["get", "user.showSiteAccessTable", ["loc", [null, [24, 24], [24, 48]]]]], [], 1, null, ["loc", [null, [24, 18], [40, 25]]]]],
          locals: ["user"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row tab-data");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12 buttons");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "id", "client_user_list");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-sm-12");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6, "class", "btn form-control nofill");
          var el7 = dom.createTextNode("+ Add New User");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-sm-12");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("ul");
          dom.setAttribute(el6, "class", "list-group");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1, 1, 1]);
          var element13 = dom.childAt(element12, [1, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element13);
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [3, 1, 1]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["toggleCreateForm"], [], ["loc", [null, [7, 52], [7, 81]]]], ["block", "each", [["get", "users", ["loc", [null, [14, 22], [14, 27]]]]], [], 0, null, ["loc", [null, [14, 14], [42, 23]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 14
              },
              "end": {
                "line": 72,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "site-access-table", [], ["sites", ["subexpr", "@mut", [["get", "model.sites", ["loc", [null, [71, 42], [71, 53]]]]], [], []], "changeAccessLevels", ["subexpr", "action", ["changeAccessLevels"], [], ["loc", [null, [71, 73], [71, 102]]]]], ["loc", [null, [71, 16], [71, 104]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "container-fluid new-user-form tab-data");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row title no-margin text-center");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-sm-12");
          var el4 = dom.createTextNode("\n        Invite User to Team\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row no-margin");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("form");
          dom.setAttribute(el3, "class", "invite-box col-sm-12 form-inline");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row no-margin");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-xs-12 panel panel-default");
          dom.setAttribute(el5, "style", "padding:0;");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "style", "display:flex;align-items:baseline;");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7, "class", "form-inline");
          dom.setAttribute(el7, "style", "padding:10px;");
          var el8 = dom.createTextNode("New User's Email:");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7, "class", "form-group email");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          var el8 = dom.createTextNode("    ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          var el8 = dom.createTextNode("Team Roster User:");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("br");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "style", "padding:10px; padding-bottom:45px;");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("button");
          dom.setAttribute(el7, "class", "btn nofill pull-right");
          var el8 = dom.createTextNode("Cancel");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7, "class", "submit pull-right");
          dom.setAttribute(el7, "style", "margin-right:15px;");
          var el8 = dom.createElement("button");
          dom.setAttribute(el8, "class", "btn accent");
          dom.setAttribute(el8, "type", "submit");
          var el9 = dom.createTextNode("Add");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 3, 1]);
          var element1 = dom.childAt(element0, [1, 1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [7, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(element2, 9, 9);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
          morphs[4] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["element", "action", ["addUser", ["get", "newUser", ["loc", [null, [57, 31], [57, 38]]]]], ["on", "submit"], ["loc", [null, [57, 12], [57, 52]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "newUser", ["loc", [null, [63, 63], [63, 70]]]]], [], []], "maxlength", 255, "size", 40], ["loc", [null, [63, 44], [63, 94]]]], ["inline", "input", [], ["type", "checkbox", "name", "isTeamRoster", "checked", ["subexpr", "@mut", [["get", "newUserIsTeamRoster", ["loc", [null, [66, 66], [66, 85]]]]], [], []]], ["loc", [null, [66, 14], [66, 87]]]], ["block", "unless", [["get", "newUserIsTeamRoster", ["loc", [null, [70, 24], [70, 43]]]]], [], 0, null, ["loc", [null, [70, 14], [72, 25]]]], ["element", "action", ["cancelInvite"], [], ["loc", [null, [75, 52], [75, 77]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 95,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/client-overview/team-roster/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "unless", [["get", "showCreate", ["loc", [null, [1, 10], [1, 20]]]]], [], 0, 1, ["loc", [null, [1, 0], [83, 11]]]], ["inline", "modal-wrapper", [], ["modalId", "userRemoveConfirm", "componentName", "confirm-with-cancel-modal", "mainMessage", ["subexpr", "@mut", [["get", "mainMessage", ["loc", [null, [89, 14], [89, 25]]]]], [], []], "subMessage", "Doing so will remove this user's access to all sites.", "confirmButtonAction", ["subexpr", "action", ["removeUser"], [], ["loc", [null, [91, 22], [91, 43]]]], "confirmButtonText", "Remove User", "hideHeader", true], ["loc", [null, [86, 0], [94, 2]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});