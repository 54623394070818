define('webui/pods/components/client-overview/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    activeTab: 'sites',
    doTransition: 'doTransition',
    refreshModel: 'refreshModel',

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('activeTab')) {
        this.set('activeTab', 'sites');
      }
    },


    actions: {
      setActiveTab: function setActiveTab(tab) {
        this.set('activeTab', tab);
        this.set('showCreate', false);
      },
      doTransition: function doTransition(route, id, queryParams) {
        this.sendAction('doTransition', route, id, queryParams);
      }
    }
  });
});