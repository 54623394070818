define('webui/pods/components/table-edit-fields/boolean/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      this.set('value', this.get('record.' + this.get('column.propertyNameSave')));
    },


    actions: {
      update: function update(event) {
        this.set('record.' + this.get('column.propertyNameSave'), event.target.checked);
      }
    }
  });
});