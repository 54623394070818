define('webui/pods/components/create-map-wizard/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/data-panel', 'webui/mixins/numerical-conversion'], function (exports, _mixin, _dataPanel, _numericalConversion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CreateMapWizard = Ember.Object.extend({
    activeSite: null,
    targetFlight: null,
    targetFlightId: null, /* alias for targetFlight.id */
    flightTime: null,
    gcpFile: null,
    gcpId: null,
    photos: null,
    savedGCP: null,
    skipGCP: false,
    activeStep: 1,
    lastCompletedStep: 0,
    processingNotes: null,
    photoMeta: []
  });

  exports.default = Ember.Component.extend(_mixin.default, _dataPanel.default, _numericalConversion.default, {
    mapData: Ember.inject.service('map-data'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    fileManager: Ember.inject.service('file-manager'),

    wizardData: null,
    wizard: null, /* value for this is passed via template */
    activeFlight: null, /* value passed via template */
    activeSite: null, /* value passed via template */

    init: function init() {
      this._super.apply(this, arguments);
      this.wizardData = CreateMapWizard.create();
      this.wizardData.set('activeSite', this.activeSite);
      this.wizardData.set('activeFlight', this.activeFlight);

      var component = this;

      this.wizard.addObserver('isCanceled', function (o) {
        if (o.get('isCanceled') === true) {
          var fileManager = component.get('fileManager');
          fileManager.deleteQueue(component.wizardData.get('targetFlight.id'));
        }
      });

      this.wizard.set('data', this.wizardData);
    },
    resetScrollPosition: function resetScrollPosition() {
      window.scrollTo(0, 0);
    },


    actions: {
      resetWizard: function resetWizard() {
        /* TODO - not currently required */
      },
      advanceNextStep: function advanceNextStep() {
        /* Advances the wizard to the next available step. */
        var self = this;
        var currentStep = self.wizardData.get('activeStep') || 1;
        /* self.wizard.clearSuccessMessage(); */
        var GCPsTaggingPageCanBeSkipped = !self.wizardData.get('gcpFile');
        var stepIncrease = currentStep === 4 && GCPsTaggingPageCanBeSkipped ? 2 : 1;
        self.wizardData.set('activeStep', currentStep + stepIncrease);

        if (self.wizardData.get('lastCompletedStep') < currentStep) {
          self.wizardData.set('lastCompletedStep', currentStep);
        }

        this.resetScrollPosition();
      },
      gotoStep: function gotoStep(step) {
        /* TODO: not sure I like relying on lastCompletedStep here - revisit. */
        var self = this;
        switch (step) {
          case 6:
            if (self.wizardData.get('lastCompletedStep') >= 5) {
              self.wizard.clearSuccessMessage();
              self.wizardData.set('activeStep', 6);
            }
            break;
          case 5:
            if (self.wizardData.get('lastCompletedStep') >= 4) {
              self.wizard.clearSuccessMessage();
              self.wizardData.set('activeStep', 5);
            }
            break;
          case 4:
            if (self.wizardData.get('lastCompletedStep') >= 3) {
              self.wizard.clearSuccessMessage();
              self.wizardData.set('activeStep', 4);
            }
            break;
          case 3:
            if (self.wizardData.get('lastCompletedStep') >= 2) {
              self.wizard.clearSuccessMessage();
              self.wizardData.set('activeStep', 3);
            }
            break;
          case 2:
            if (self.wizardData.get('lastCompletedStep') >= 1) {
              self.wizard.clearSuccessMessage();
              self.wizardData.set('activeStep', 2);
            }
            break;
          case 1: /* Intentional fallthrough to default which shows the date/time screen. */
          default:
            self.wizard.clearSuccessMessage();
            self.wizardData.set('activeStep', 1);
            break;
        }

        this.resetScrollPosition();
      },
      doWizardExit: function doWizardExit() {
        this.sendAction('doWizardExit');
      },
      doWizardSuccess: function doWizardSuccess() {
        /* This code originally sent the user to the site route which redirected them to the most recent flight which could have been one not yet processed.
        this.sendAction('doTransition', 'site', this.wizardData.get('activeSite.id')); */
        this.sendAction('doWizardExit');
      }
    }

  });
});