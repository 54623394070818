define('webui/mixins/components/tooltips', ['exports', 'ember-tooltips/mixins/components/tooltips'], function (exports, _tooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tooltips.default;
    }
  });
});