define('webui/pods/admin/site/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    siteService: Ember.inject.service('site'),
    session: Ember.inject.service('session'),

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      controller.set('siteService', this.get('siteService'));
      this.set('siteService.adminSitesLoaded', false);
      this.store.unloadAll('site');
      Ember.RSVP.resolve(this.store.query('site', { limit: 9999 }).then(function (sites) {
        return sites.sortBy('name');
      })).then(function (results) {
        controller.set('model', results);
        _this.send('updateSites', results);
        _this.set('siteService.adminSitesLoaded', true);
        _this.get('session').set('allSiteDataLoaded', true);
      });
    },


    actions: {
      error: function error(_error) {
        console.error('admin-site error: ', _error);
      },
      loadSiteForEdit: function loadSiteForEdit(id) {
        if (id) {
          $('.selectpicker').val(undefined).change();
          this.transitionTo('admin.site.edit', id);
        }
      },
      loadFlightForEdit: function loadFlightForEdit(id) {
        this.transitionTo('admin.flight.edit', id);
      },
      updateSites: function updateSites() {},
      removeSite: function removeSite() /* site */{
        // need to figure out how to remove sites from the site search dropdown when they are deleted
      }
    }
  });
});