define('webui/models/annotation', ['exports', 'ember-data', 'npm:polylabel'], function (exports, _emberData, _npmPolylabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    mapData: Ember.inject.service('mapData'),

    annotationLayer: _emberData.default.belongsTo('annotation-layer', { async: false }),
    label: _emberData.default.attr('string'),
    annotationType: _emberData.default.attr('string'),
    featureType: _emberData.default.attr('string'),
    featureGeometry: _emberData.default.attr(),
    color: _emberData.default.attr('string', { defaultValue: 'blue' }),
    createdAt: _emberData.default.attr('date'),

    mapboxPointFeature: Ember.computed('label', 'annotationType', 'featureGeometry', function mapboxPointFeature() {
      var coordinates = (0, _npmPolylabel.default)([this.get('featureGeometry').map(function (point) {
        return [point.lng, point.lat];
      })]);

      if (['line', 'arrow'].indexOf(this.get('annotationType')) !== -1) {
        var feature = {
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: this.get('featureGeometry').map(function (point) {
              return [point.lng, point.lat];
            }),
            type: 'LineString'
          }
        };

        var coords = this.get('mapData').chunkLine(feature);
        coordinates = coords[Math.round(coords.length / 2)];
      }

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coordinates
        },
        properties: {
          name: this.get('label')
        }
      };
    }),

    mapboxArrowHeadFeature: Ember.computed('featureGeometry', function mapboxArrowHeadFeature() {
      var points = this.get('featureGeometry');
      var lastCoord = [points[points.length - 1].lng, points[points.length - 1].lat];
      var secondToLastCoord = [points[points.length - 2].lng, points[points.length - 2].lat];
      var lineBearing = window.turf.bearing(secondToLastCoord, lastCoord);

      return { type: 'Feature', properties: { name: 'test', rotation: lineBearing }, geometry: { type: 'Point', coordinates: lastCoord } };
    })
  });
});