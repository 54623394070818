define('webui/pods/components/map-uploader/component', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var customUploadControllers = { orthomap: 'tiledMaps' };

  var doUpload = function doUpload(options) {
    var formData = new FormData();
    var xhr = new XMLHttpRequest();
    var component = options.component;

    var product = component.files[options.fileType];

    var feedback = component.get('feedback');

    component.set('uploadError', false);

    xhr.onreadystatechange = function onreadystatechange() {
      if (this.readyState === XMLHttpRequest.DONE) {
        var index = component.queue.indexOf(options.file.name);
        if (index > -1) {
          component.queue.splice(index, 1);
        }

        if (this.status === 200) {
          if (product.totalUploaded >= product.totalUploadSize) {
            component.messages.push(options.file.name + ' uploaded!');
            $('[name="' + options.fileType + '"]').val('');
            component.files[options.fileType] = null;
          }
        } else {
          component.set('uploadError', true);
          var error = 'There was an error uploading ' + options.fileType + ' - ' + options.file.name;

          if (this.status === 400) {
            var _JSON$parse = JSON.parse(this.response),
                detail = _JSON$parse.detail;

            error = error + '. ' + detail;
          } else if (this.statusText) {
            error = error + '.  ' + this.statusText;
          }
          feedback.reportError({ title: 'Map upload error', detail: error });
        }

        if (Ember.isEmpty(options.component.queue)) {
          $('#submitBtn').prop('disabled', false).html('Upload');
          if (component.get('uploadError') === false) {
            feedback.reportSuccess('Upload successful');
          }
        }
      }
    };

    xhr.upload.onprogress = function onprogress(e) {
      var productProgress = void 0;
      var thisFileProgress = void 0;

      if (e.lengthComputable) {
        productProgress = Math.round((product.totalUploaded + e.loaded) / product.totalUploadSize * 100);
        thisFileProgress = Math.round(e.loaded / e.total * 100);

        if (thisFileProgress === 100) {
          product.totalUploaded += e.loaded;
          $('#UploadProgressIndicator-' + options.fileType).html('');
        } else {
          $('#UploadProgressIndicator-' + options.fileType).html('Upload ' + productProgress + '% complete');
        }
      }
    };

    xhr.open('POST', options.url);
    xhr.processData = false;
    xhr.withCredentials = true;
    Object.keys(options.formData).forEach(function (key) {
      formData.append(key, options.formData[key]);
    });
    formData.append('file', options.file);

    xhr.send(formData);
  };

  var xhrUpload = function xhrUpload(component) {
    Object.keys(component.files).forEach(function (fileType) {
      for (var i = 0; i < component.files[fileType].length; i += 1) {
        var url = void 0;
        var formData = {};

        if (fileType === 'orthomap') {
          var controller = customUploadControllers[fileType];
          url = _environment.default.APP.apiUrl + controller + '/upload';
          formData = {
            flightId: component.get('flightId')
          };
        } else {
          url = _environment.default.APP.apiUrl + 'products/upload';
          formData = {
            flightId: component.get('flightId'),
            productType: fileType
          };
        }

        doUpload({
          url: url,
          fileType: fileType,
          formData: formData,
          file: component.files[fileType][i],
          component: component
        });
      }
    });
  };

  exports.default = Ember.Component.extend({
    flightId: null,
    files: {},
    errors: [],
    messages: [],
    queue: [],

    feedback: Ember.inject.service('user-feedback'),

    didInsertElement: function didInsertElement() {
      var component = this;
      $('[type="file"]').bind('change', function change() {
        component.files[this.name] = this.files;
      });
    },


    actions: {
      submitUpload: function submitUpload() {
        this.set('errors', []);
        this.set('messages', []);
        this.set('queue', []);

        var component = this;
        var filecount = 0;

        var feedback = this.get('feedback');
        Object.keys(component.files).forEach(function (key) {
          if (component.files[key]) {
            filecount += component.files[key].length;
          }
        });

        if (filecount < 1) {
          feedback.reportError({ title: 'Map upload error', detail: 'Please select at least one file.' });
        } else {
          $('#submitBtn').prop('disabled', true).html('Uploading...');
          Object.keys(component.files).forEach(function (fileType) {
            component.files[fileType].totalUploadSize = 0;
            component.files[fileType].totalUploaded = 0;
            for (var i = 0; i < component.files[fileType].length; i += 1) {
              component.files[fileType].totalUploadSize += component.files[fileType][i].size;
              component.queue.push(component.files[fileType][i].name);
            }
          });
          xhrUpload(this);
        }
      }
    }

  });
});