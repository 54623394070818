define('webui/models/site-preference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    site: _emberData.default.belongsTo('site', { async: true }),
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('meaningful-string') /* this is a custom transform meant to filter out special values from the stored strings (mainly booleans)  */
  });
});