define('webui/pods/components/site-access-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    changeAccessLevels: 'changeAccessLevels',

    init: function init() {
      this._super();
      this.setupUserSiteData();
      this.sendCurrentAccessLevels();
    },
    setupUserSiteData: function setupUserSiteData() {
      var _this = this;

      var sites = this.get('sites');

      this.set('tableData', sites.sortBy('name').map(function (site) {
        var access = _this.get('store').peekAll('user-site').filterBy('user.id', _this.get('user.id')).filterBy('site', site.get('id')).get('firstObject.access');

        return { id: site.get('id'), name: site.get('name'), access: _this.get('user') ? access : 'site:none' };
      }));
    },
    sendCurrentAccessLevels: function sendCurrentAccessLevels() {
      this.sendAction('changeAccessLevels', this.get('tableData').map(function (site) {
        return { siteId: site.id, access: site.access === 'site:none' ? null : site.access };
      }));
    },


    actions: {
      setAccessLevel: function setAccessLevel(siteId, access) {
        this.set('tableData', this.get('tableData').map(function (site) {
          return site.id === siteId ? _extends({}, site, { access: access }) : site;
        }));
        this.sendCurrentAccessLevels();
      },
      selectAll: function selectAll(access) {
        this.set('tableData', this.get('tableData').map(function (site) {
          return _extends({}, site, { access: access });
        }));
        this.sendCurrentAccessLevels();
      }
    }
  });
});