define("webui/pods/components/create-map-step-gcp-tagging/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 4
              },
              "end": {
                "line": 25,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12 text-danger");
            var el3 = dom.createTextNode("The provided Ground Control Points could not be processed. Double check and re-upload your GCP file on the Correction Method step. If necessary, you may deselect turbo processing.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 4
              },
              "end": {
                "line": 29,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12 text-danger");
            var el3 = dom.createTextNode("The provided Ground Control Points could not be processed. Double check and re-upload your GCP file on the Correction Method step.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 2
            },
            "end": {
              "line": 30,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "wizardData.useTurboProcessing", ["loc", [null, [21, 10], [21, 39]]]]], [], 0, 1, ["loc", [null, [21, 4], [29, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 16
                  },
                  "end": {
                    "line": 43,
                    "column": 16
                  }
                },
                "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "selected", "");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" - ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element3, 'value');
                morphs[1] = dom.createMorphAt(element3, 0, 0);
                morphs[2] = dom.createMorphAt(element3, 2, 2);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "epsg", ["loc", [null, [42, 34], [42, 38]]]]], ["content", "epsg", ["loc", [null, [42, 50], [42, 58]]]], ["inline", "get", [["get", "epsgToName", ["loc", [null, [42, 67], [42, 77]]]], ["get", "epsg", ["loc", [null, [42, 78], [42, 82]]]]], [], ["loc", [null, [42, 61], [42, 84]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 16
                  },
                  "end": {
                    "line": 45,
                    "column": 16
                  }
                },
                "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" - ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element2, 'value');
                morphs[1] = dom.createMorphAt(element2, 0, 0);
                morphs[2] = dom.createMorphAt(element2, 2, 2);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "epsg", ["loc", [null, [44, 34], [44, 38]]]]], ["content", "epsg", ["loc", [null, [44, 41], [44, 49]]]], ["inline", "get", [["get", "epsgToName", ["loc", [null, [44, 58], [44, 68]]]], ["get", "epsg", ["loc", [null, [44, 69], [44, 73]]]]], [], ["loc", [null, [44, 52], [44, 75]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 14
                },
                "end": {
                  "line": 46,
                  "column": 14
                }
              },
              "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "epsg", ["loc", [null, [41, 26], [41, 30]]]], ["get", "wizardData.selectedEpsgCode", ["loc", [null, [41, 31], [41, 58]]]]], [], ["loc", [null, [41, 22], [41, 59]]]]], [], 0, 1, ["loc", [null, [41, 16], [45, 23]]]]],
            locals: ["epsg"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 6
              },
              "end": {
                "line": 51,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-3 detail-name");
            var el3 = dom.createTextNode("EPSG:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-9");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("em");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("select");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 3, 1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            morphs[1] = dom.createAttrMorph(element4, 'onChange');
            morphs[2] = dom.createMorphAt(element4, 1, 1);
            return morphs;
          },
          statements: [["attribute", "value", ["get", "wizardData.selectedEpsgCode", ["loc", [null, [39, 28], [39, 55]]]]], ["attribute", "onChange", ["subexpr", "action", ["updateEpsg"], ["value", "target.value"], ["loc", [null, [39, 67], [39, 111]]]]], ["block", "each", [["get", "epsgOptions", ["loc", [null, [40, 22], [40, 33]]]]], [], 0, null, ["loc", [null, [40, 14], [46, 23]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 8
              },
              "end": {
                "line": 61,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn btn-cancel js-trigger-save");
            var el2 = dom.createTextNode("Skip this step and have Identified tag GCPs");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["save"], [], ["loc", [null, [60, 57], [60, 74]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "map-details");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" \n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row button-row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn accent js-trigger-save");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "glyphicon glyphicon-ok");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" SAVE & NEXT\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [3, 1]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createElementMorph(element6);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "wizardData.selectedEpsgCode", ["loc", [null, [34, 12], [34, 39]]]]], [], 0, null, ["loc", [null, [34, 6], [51, 13]]]], ["element", "action", ["validate"], [], ["loc", [null, [56, 51], [56, 72]]]], ["block", "unless", [["get", "wizardData.useTurboProcessing", ["loc", [null, [59, 18], [59, 47]]]]], [], 1, null, ["loc", [null, [59, 8], [61, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 8
                },
                "end": {
                  "line": 69,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-cancel js-trigger-save");
              var el2 = dom.createTextNode("Skip this step and have Identified tag GCPs");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [["element", "action", ["save"], [], ["loc", [null, [68, 57], [68, 74]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 2
              },
              "end": {
                "line": 72,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row button-row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "unless", [["get", "wizardData.useTurboProcessing", ["loc", [null, [67, 18], [67, 47]]]]], [], 0, null, ["loc", [null, [67, 8], [69, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 2
            },
            "end": {
              "line": 72,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "not", [["get", "wizardData.useTurboProcessing", ["loc", [null, [64, 17], [64, 46]]]]], [], ["loc", [null, [64, 12], [64, 47]]]]], [], 0, null, ["loc", [null, [64, 2], [72, 2]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 2
            },
            "end": {
              "line": 78,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12 text-warning");
          var el3 = dom.createTextNode("Warning: Ground Control Points are over a mile away from flight photos");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 2
            },
            "end": {
              "line": 84,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12 text-warning");
          var el3 = dom.createTextNode("Warning: Photos are over a mile away from the previous flight's photos");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 95,
            "column": 7
          }
        },
        "moduleName": "webui/pods/components/create-map-step-gcp-tagging/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "create-map-step create-map-step-confirmation");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row heading");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Ground Control Point Tagging");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Each Ground Control Point should be tagged in at least 6-10 photos for tight vertical accuracy. The more, the better.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("The center of the Ground Control Points need to be tagged to the nearest pixel. The tighter, the better.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("After data is processed, it is a best practice to draw some cross sections around hard surfaces & Ground Control Points to make sure the vertical tolerance of the data meets your use case specifications.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Ground Control Points can be automatically tagged on future flights with a Smart Target. You can request Smart Targets to be mailed to you by reaching out to support@identifiedtech.com.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If Ground Control Points are not displayed on the map below, please adjust the EPSG Code.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "style", "color: rgb(44, 44, 44);");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element7, 5, 5);
        morphs[1] = dom.createMorphAt(element7, 7, 7);
        morphs[2] = dom.createMorphAt(element7, 9, 9);
        morphs[3] = dom.createMorphAt(element7, 11, 11);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showGCPProcessingError", ["loc", [null, [20, 8], [20, 30]]]]], [], 0, null, ["loc", [null, [20, 2], [30, 9]]]], ["block", "if", [["subexpr", "not", [["get", "showGCPProcessingError", ["loc", [null, [32, 13], [32, 35]]]]], [], ["loc", [null, [32, 8], [32, 36]]]]], [], 1, 2, ["loc", [null, [32, 2], [72, 9]]]], ["block", "if", [["get", "gcpWarningShown", ["loc", [null, [74, 8], [74, 23]]]]], [], 3, null, ["loc", [null, [74, 2], [78, 9]]]], ["block", "if", [["get", "photoWarningShown", ["loc", [null, [80, 8], [80, 25]]]]], [], 4, null, ["loc", [null, [80, 2], [84, 9]]]], ["inline", "modal-wrapper", [], ["modalId", "gcpTaggingValidationModal", "componentName", "gcp-tagging-validation-modal", "errorDetails", ["subexpr", "@mut", [["get", "errorDetails", ["loc", [null, [91, 17], [91, 29]]]]], [], []], "hideHeader", true, "confirmButtonAction", ["subexpr", "action", ["save"], [], ["loc", [null, [93, 24], [93, 39]]]]], ["loc", [null, [88, 2], [94, 4]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});