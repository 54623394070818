define('webui/pods/components/admin-user-form/component', ['exports', 'webui/mixins/error-message', 'webui/config/environment'], function (exports, _errorMessage, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorMessage.default, {
    store: Ember.inject.service(),
    actions: {
      submitModel: function submitModel() {
        var component = this;
        var action = this.get('save_action');
        var store = this.get('store');

        if (action === 'edit') {
          this.set('model.user.status', $('#status').val());

          this.get('model.user').save().then(this.setMessage('User saved')).catch(function (error) {
            component.setError(error);
          });
        } else if (action === 'create') {
          var newUser = store.createRecord('user', {
            firstName: this.get('model.firstName'),
            lastName: this.get('model.lastName'),
            email: this.get('model.email'),
            status: $('#status').val(),
            password: this.get('model.password'),
            passwordConfirm: this.get('model.passwordConfirm'),
            sendFlightDataUpdateEmails: this.get('model.sendFlightDataUpdateEmails')
          });
          newUser.save().then(this.setMessage('User created')).catch(function (error) {
            component.setError(error);
          });
        }
        if (this.get('successMessage') === 'User created') {
          $(':input').val('');
          $('#status').val('pending');
        }
      },
      selectClientValue: function selectClientValue(client) {
        this.set('newClient', client);
      },
      selectUserStatus: function selectUserStatus(status) {
        if (this.get('save_action') === 'create') {
          this.set('model.status', status);
        } else if (this.get('save_action') === 'edit') {
          this.set('model.user.status', status);
        }
      },
      addClient: function addClient() {
        var component = this;
        var client_id = this.get('newClient');
        var store = this.get('store');
        var client = store.peekRecord('client', client_id);
        var user = this.get('model.user');

        user.get('clients').pushObject(client);
        user.save().then(this.setMessage('Client association saved')).catch(function (error) {
          component.setError(error);
        });
      },
      removeClientAndSites: function removeClientAndSites(clientId) {
        var component = this;
        var user = this.get('model.user');
        var removeClientUrl = _environment.default.APP.apiUrl + 'userFromRoster/' + user.id + '/' + clientId;

        $.ajax({
          method: 'DELETE',
          contentType: 'json',
          url: removeClientUrl,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function (res) {
          user.reload();
          component.setMessage(res.message);
        }, function (err) {
          component.setError(err);
        });
      },
      sendInviteReminder: function sendInviteReminder() {
        var component = this;
        var user = this.get('model.user');
        var inviteReminderUrl = _environment.default.APP.apiUrl + 'users/' + user.id + '/invitationReminder';

        user.save().then(function () {
          $.ajax({
            method: 'POST',
            contentType: 'json',
            url: inviteReminderUrl,
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            }
          }).then(function () {
            component.setMessage('Invitation reminder email sent.');
          }, function () {
            component.setError('Invitation reminder email failed to send. Please try again later.');
          });
        }).catch(function (error) {
          component.setError(error);
        });
      }
    }
  });
});