define("webui/react/AlertBar", ["exports", "npm:react"], function (exports, _npmReact) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var AlertBar = function (_React$Component) {
    _inherits(AlertBar, _React$Component);

    function AlertBar(props) {
      _classCallCheck(this, AlertBar);

      var _this = _possibleConstructorReturn(this, (AlertBar.__proto__ || Object.getPrototypeOf(AlertBar)).call(this, props));

      _this.state = {
        activeMessage: props.activeMessage
      };
      return _this;
    }

    _createClass(AlertBar, [{
      key: "closeMessage",
      value: function closeMessage(message) {
        this.props.onMessageClose(message);
      }
    }, {
      key: "render",
      value: function render() {
        var component = this;

        return _npmReact.default.createElement(
          "div",
          { className: "alert-bar" },
          this.props.activeMessage && _npmReact.default.createElement(
            "div",
            { className: "alert alert-" + this.props.activeMessage.severity, role: "alert" },
            this.props.activeMessage.message,
            _npmReact.default.createElement(
              "a",
              { href: "#", className: "close-alert", onClick: function onClick(event) {
                  event.preventDefault();component.closeMessage(component.props.activeMessage);
                } },
              _npmReact.default.createElement("span", { className: "glyphicon glyphicon-remove" })
            )
          )
        );
      }
    }]);

    return AlertBar;
  }(_npmReact.default.Component);

  exports.default = AlertBar;
});