define('webui/pods/components/surface-layer-item/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    isActive: false,
    editMode: false,
    originalZoom: null,

    mapData: Ember.inject.service('map-data'),
    dataPanelService: Ember.inject.service('data-panel'),

    // upward actions
    setVisibleBlueprint: 'setVisibleBlueprint',
    removeVisibleBlueprint: 'removeVisibleBlueprint',

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('hasTiledMap', !!this.get('tiledMap.id'));
      this.set('status', this.get('tiledMap.id') && ['succeeded', 'warning'].indexOf(this.get('blueprint.status')) !== -1 ? this.get('tiledMap.status') : this.get('blueprint.status'));
      this.set('message', this.get('tiledMap.id') && ['succeeded', 'warning'].indexOf(this.get('blueprint.status')) !== -1 ? this.get('tiledMap.message') : this.get('blueprint.message'));
    },
    didInsertElement: function didInsertElement() {
      // check to restore isActive state when this is torn down and reinserted.
      var map = this.get('mapData.mapInstance');
      var dataPanelService = this.get('dataPanelService');
      var activeBlueprints = dataPanelService.get('persistantDataPanelState.siteGrades');
      if (activeBlueprints && activeBlueprints.length > 0) {
        if (activeBlueprints.findBy('id', this.get('blueprint.id'))) {
          this.set('isActive', true);
          var component = this;
          map.on('load', function () {
            return component.showBlueprint(component.get('blueprint'));
          });
        }
      }
    },
    showBlueprint: function showBlueprint(blueprint) {
      var mapData = this.get('mapData');
      var tiledMap = this.get('tiledMap');
      var map = this.get('mapData.mapInstance');
      var layerName = 'blueprint-layer-' + blueprint.get('id');
      var tileData = {
        type: 'raster',
        tiles: [],
        tileSize: 256,
        maxzoom: tiledMap.get('maxZoomLevel')
      };

      var layerData = {
        id: layerName,
        type: 'raster',
        source: layerName,
        minzoom: 0,
        maxzoom: mapData.get('maxZoomLevel'),
        layout: { visibility: 'visible' }
      };

      var prefix = tiledMap.get('urlPrefix');
      var fileUrl = tiledMap.get('fileUrl');

      if (prefix && prefix.slice(-1) !== '/' && fileUrl.charAt(0) !== '/') {
        prefix += '/';
      }
      if (fileUrl && fileUrl.slice(-1) !== '/') {
        fileUrl += '/';
      }

      tileData.tiles.push('' + prefix + fileUrl + '{z}/{x}/{y}.png');

      if (mapData.mapInstance.getLayer(layerName)) {
        mapData.mapInstance.removeLayer(layerName);
        mapData.mapInstance.removeSource(layerName);
      }

      map.addSource(layerName, tileData);
      map.addLayer(layerData, mapData.getLayerThatNewLayerShouldBeInsertedBefore());

      this.set('isActive', true);
      this.sendAction('setVisibleBlueprint', blueprint, 'siteGrades');

      this.trackEvent('Site Grade Overlay View', {
        siteId: this.get('site.id'), siteName: this.get('site.name'), blueprintId: blueprint.get('id'), blueprint: blueprint.get('name')
      });
    },
    hideBlueprint: function hideBlueprint(blueprint) {
      var map = this.get('mapData.mapInstance');
      var layerName = 'blueprint-layer-' + blueprint.get('id');

      map.setLayoutProperty(layerName, 'visibility', 'none');

      this.set('isActive', false);
      this.sendAction('removeVisibleBlueprint', blueprint, 'siteGrades');
    },


    actions: {
      toggleBlueprintLayer: function toggleBlueprintLayer(blueprint) {
        if (this.isActive === false) {
          this.showBlueprint(blueprint);
        } else {
          this.hideBlueprint(blueprint);
        }
      }
    }
  });
});