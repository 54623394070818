define('webui/pods/admin/job/route', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    actions: {
      downloadJobStatusHistory: function downloadJobStatusHistory() {
        location.href = _environment.default.APP.apiUrl + 'jobstatushistories?format=csv';
      }
    }
  });
});