define('webui/pods/components/field-panel-elevation/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    mapData: Ember.inject.service('map-data'),
    closePanel: 'closePanel',

    init: function init() {
      this._super();
      this.showElevationData();
      this.setElevationMarker();
      this.setupSocketOnHandler();
    },
    willDestroyElement: function willDestroyElement() {
      this.hideElevationData();
      this.removeElevationMarker();
      io.socket.off('elevationCalculation');
    },
    setupSocketOnHandler: function setupSocketOnHandler() {
      var context = this;

      io.socket.on('elevationCalculation', function (event) {
        var verb = event.verb,
            data = event.data;

        if (verb === 'updated') {
          var elevation = data.elevation,
              lat = data.lat,
              lng = data.lng,
              status = data.status,
              message = data.message;

          if (context.get('latitude') === lat && context.get('longitude') === lng) {
            if (status !== 'succeeded') {
              context.set('showWarning', true);
            }

            context.set('elevation', elevation);
            context.set('calculationStatus', status);
            if (message) context.set('calculationMessage', message);
          }
        }
      });
    },
    setElevationMarker: function setElevationMarker() {
      if (window && window.mapboxgl) {
        var map = this.get('mapData.mapInstance');

        map.addSource('point', { type: 'geojson', data: { type: 'FeatureCollection', features: [{ type: 'Feature', geometry: { type: 'Point', coordinates: [0, 0] } }] } });
        map.addLayer({
          id: 'point',
          type: 'symbol',
          source: 'point',
          layout: {
            'icon-image': 'fieldpin',
            'icon-size': 0.25,
            'icon-optional': false,
            visibility: 'visible'
          }
        });
      }
    },
    removeElevationMarker: function removeElevationMarker() {
      this.get('mapData.mapInstance').removeLayer('point');
      this.get('mapData.mapInstance').removeSource('point');
    },
    hideElevationData: function hideElevationData() {
      var mapData = this.get('mapData');

      mapData.mapInstance.off('touchstart', 'point', this.get('touchStartHandler'));
      mapData.mapInstance.off('touchend', this.get('touchEndHandler'));
      mapData.mapInstance.off('touchmove', this.get('touchMoveHandler'));
    },
    showElevationData: function showElevationData() {
      var component = this;
      var mapData = this.get('mapData');
      var map = mapData.mapInstance;

      var touchMoveHandler = function touchMoveHandler(e) {
        var geojson = map.getSource('point')._data;
        geojson.features[0].geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
        map.getSource('point').setData(geojson);
        component.set('showWarning', false);
        component.set('hasElevationReading', true);
        if (!component.get('initialized')) component.set('initialized', true);
      };

      var touchEndHandler = function touchEndHandler(e) {
        if (!component.get('initialized')) component.get('touchMoveHandler')(e, true);

        map.off('touchmove', component.get('touchMoveHandler'));
        map.dragPan.enable();

        component.set('calculationStatus', 'pending');
        component.set('latitude', e.lngLat.lat);
        component.set('longitude', e.lngLat.lng);
        component.set('displayLatitude', e.lngLat.lat.toFixed(8));
        component.set('displayLongitude', e.lngLat.lng.toFixed(8));

        io.socket.post('/v1/elevationCalculation', { flight: component.get('mapData.currentFlightId'), lat: component.get('latitude'), lng: component.get('longitude') }, function (response) {
          component.set('calculationStatus', response.status);
          component.set('calculationMessage', response.message);
          component.set('elevation', 'N/A');
        });
      };

      var touchStartHandler = function touchStartHandler(e) {
        if (e.points.length !== 1) return;

        map.dragPan.disable();
        map.on('touchmove', component.get('touchMoveHandler'));
        map.once('touchend', component.get('touchEndHandler'));
      };

      this.set('touchStartHandler', touchStartHandler);
      this.set('touchEndHandler', touchEndHandler);
      this.set('touchMoveHandler', touchMoveHandler);

      map.on('touchstart', 'point', this.get('touchStartHandler'));
      map.once('touchend', this.get('touchEndHandler'));

      this.trackEvent('Field Elevation Hover View', { site: this.get('mapData.currentSite.id'), flight: this.get('mapData.currentFlightId') });
    },


    actions: {
      closePanel: function closePanel() {
        this.sendAction('closePanel');
      }
    }
  });
});