define('webui/mixins/map-layer-formatting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Mixin.create({
    getRegionLabelSourceProperties: function getRegionLabelSourceProperties(features) {
      return { type: 'geojson', data: { type: 'FeatureCollection', features: features } };
    },
    getRegionLabelLayerProperties: function getRegionLabelLayerProperties(overrides) {
      return _extends({
        type: 'symbol',
        source: { type: 'geojson', data: { type: 'FeatureCollection', features: [] } },
        layout: {
          'symbol-placement': 'point',
          'text-field': '{name}',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-size': {
            stops: [[13, 10], [15, 11], [17, 12], [18, 13], [19, 16]]
          },
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
          'text-ignore-placement': true,
          'icon-ignore-placement': true
        },
        paint: {
          'text-color': '#fff',
          'text-halo-color': '#000',
          'text-halo-width': 1,
          'text-halo-blur': 2
        }
      }, overrides);
    },
    getArrowHeadLayerProperties: function getArrowHeadLayerProperties(_ref) {
      var id = _ref.id,
          iconColor = _ref.iconColor,
          featureGeometry = _ref.featureGeometry;

      return {
        id: id,
        type: 'symbol',
        source: { type: 'geojson', data: { type: 'FeatureCollection', features: [featureGeometry] } },
        layout: {
          'icon-image': 'arrow-head',
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.1, 22, 1.5], // 1,
          'icon-offset': { stops: [[18, [0, -5]], [22, [0, -3]]] },
          'icon-optional': false,
          'icon-rotate': { type: 'identity', property: 'rotation' },
          'icon-anchor': 'top',
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          visibility: 'visible'
        },
        paint: { 'icon-color': iconColor }
      };
    }
  });
});