define('webui/pods/components/client-overview/site-associations/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    activeTab: 'sites',
    doTransition: 'doTransition',

    actions: {
      toggleCreateForm: function toggleCreateForm() {
        this.toggleProperty('showCreate');
      },
      editSite: function editSite(siteId) {
        this.sendAction('doTransition', 'site', siteId);
      },
      doTransition: function doTransition(route, id, queryParams) {
        if (route === 'account.client') {
          this.set('showCreate', false);
        } else {
          this.sendAction('doTransition', route, id, queryParams);
        }
      }
    }
  });
});