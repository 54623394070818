define('webui/pods/components/true-tonnage-report/create-report-user-fields/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),

    init: function init() {
      this._super();
      this.createUserListForTrueTonnageReport();
    },
    createUserListForTrueTonnageReport: function createUserListForTrueTonnageReport() {
      var _this = this;

      var loggedInUser = this.get('store').peekRecord('user', this.get('session.data.authenticated.id'));
      var isLoggedInUserOnSiteAccessList = this.get('activeSiteUsers').filter(function (user) {
        return user.get('id') === _this.get('session.data.authenticated.id');
      }).length > 0;
      var userListForTrueTonnageReport = this.get('activeSiteUsers').toArray();
      if (!isLoggedInUserOnSiteAccessList) userListForTrueTonnageReport.push(loggedInUser);
      var nonPendingNonViewOnlyUsers = userListForTrueTonnageReport.filter(function (user) {
        return (user.get('isSiteAdmin') || user.get('isAccounting')) && !user.get('isPending');
      });
      var userAdminListForTrueTonnageReport = nonPendingNonViewOnlyUsers.filter(function (user) {
        var emailDomain = user.get('email').split('@').get(1);
        return emailDomain === 'identifiedtech.com';
      });

      this.set('userListForTrueTonnageReport', nonPendingNonViewOnlyUsers);
      this.set('userAdminListForTrueTonnageReport', userAdminListForTrueTonnageReport);
    },


    actions: {
      setPlannedTonnageInputUser: function setPlannedTonnageInputUser(userId) {
        this.sendAction('setPlannedTonnageInputUser', userId || null);
      },
      setPlantManager: function setPlantManager(userId) {
        this.sendAction('setPlantManager', userId || null);
      },
      setEngineeringManager: function setEngineeringManager(userId) {
        this.sendAction('setEngineeringManager', userId || null);
      },
      setAreaManager: function setAreaManager(userId) {
        this.sendAction('setAreaManager', userId || null);
      },
      setAccountingManager: function setAccountingManager(userId) {
        this.sendAction('setAccountingManager', userId || null);
      },
      setAuditor: function setAuditor(userId) {
        this.sendAction('setAuditor', userId || null);
      }
    }
  });
});