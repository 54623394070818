define('webui/models/blueprint', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    urlPrefix: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    fileSuffix: _emberData.default.attr('string'),
    site: _emberData.default.belongsTo('site', { async: true }),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    createdAt: _emberData.default.attr('date'),
    earthworkAnalyses: _emberData.default.hasMany('earthwork-analysis', { async: true }),
    order: _emberData.default.attr('number'),
    epsgCode: _emberData.default.attr('number'),
    fileUrlForCalculations: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    quantities: _emberData.default.hasMany('quantity', { async: true }),
    tiledMap: _emberData.default.belongsTo('tiled-map', { async: true }),
    density: _emberData.default.attr('number'),
    isUsedForVolumeCalcBaseplane: _emberData.default.attr('boolean'),

    displayUrl: function displayUrl() {
      return this.get('urlPrefix') + this.get('fileUrl');
    }.property('fileUrl')
  });
});