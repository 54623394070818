define('webui/react/FileInput', ['exports', 'npm:react', 'npm:lodash'], function (exports, _npmReact, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var FileInput = function (_React$Component) {
    _inherits(FileInput, _React$Component);

    _createClass(FileInput, null, [{
      key: 'testForEdge',
      value: function testForEdge() {
        var filter = new RegExp('Edge/');
        return filter.test(navigator.userAgent);
      }
    }]);

    function FileInput(props) {
      _classCallCheck(this, FileInput);

      var _this = _possibleConstructorReturn(this, (FileInput.__proto__ || Object.getPrototypeOf(FileInput)).call(this, props));

      var multiSelect = _this.props.multiSelect;


      _this.state = {
        fileAmalgam: ''
      };

      _this.multiSelectAllowed = multiSelect || false;
      _this.files = [];
      _this.isMSEdge = FileInput.testForEdge();

      _this.fileSelectionChanged = _this.fileSelectionChanged.bind(_this);
      return _this;
    }

    _createClass(FileInput, [{
      key: 'fileSelectionChanged',
      value: function fileSelectionChanged(event) {
        var _props = this.props,
            allowedFileExtensions = _props.allowedFileExtensions,
            allowedMimeTypes = _props.allowedMimeTypes,
            onFilesChanged = _props.onFilesChanged;

        this.files = event.target.files;

        var amalgam = '';
        var vettedFiles = [];
        var rejectedFiles = [];

        for (var i = 0; i < this.files.length; i += 1) {
          var fileApproved = false;

          if (allowedMimeTypes && (allowedMimeTypes[0] === '*/*' || allowedMimeTypes.indexOf(this.files[i].type.toLowerCase()) > -1)) {
            fileApproved = true;
          }

          if (!fileApproved && allowedFileExtensions && allowedFileExtensions.length) {
            var fileNameSegments = this.files[i].name.split('.');
            var lastSegment = fileNameSegments.length - 1;

            if (allowedFileExtensions.indexOf('.' + fileNameSegments[lastSegment].toLowerCase()) > -1) {
              fileApproved = true;
            }
          }

          if (fileApproved) {
            amalgam += (i > 0 ? ', ' : '') + this.files[i].name;
            vettedFiles.push(this.files[i]);
          } else {
            rejectedFiles.push(this.files[i]);
          }
        }

        if (vettedFiles.length) {
          this.setState({ fileAmalgam: amalgam });
        } else {
          this.setState({ fileAmalgam: '' });
        }

        onFilesChanged(vettedFiles, rejectedFiles);
      }
    }, {
      key: 'render',
      value: function render() {
        var component = this;
        var _props2 = this.props,
            allowedFileExtensions = _props2.allowedFileExtensions,
            allowedMimeTypes = _props2.allowedMimeTypes;
        var fileAmalgam = this.state.fileAmalgam;

        var name = 'fileInput';
        var acceptList = ['*/*'];
        if (allowedFileExtensions && allowedMimeTypes) {
          acceptList = _npmLodash.default.union(allowedFileExtensions, allowedMimeTypes);
        } else if (allowedFileExtensions) {
          acceptList = allowedFileExtensions;
        } else if (allowedMimeTypes) {
          acceptList = allowedMimeTypes;
        }

        return _npmReact.default.createElement(
          'div',
          { className: 'form-group form-inline' },
          component.isMSEdge ? _npmReact.default.createElement('input', { type: 'file', name: name, onChange: component.fileSelectionChanged, multiple: this.multiSelectAllowed, accept: acceptList }) : _npmReact.default.createElement(
            'label',
            { className: 'btn btn-primary' },
            'Browse\u2026',
            ' ',
            _npmReact.default.createElement('input', { type: 'file', name: name, style: { display: 'none' }, onChange: component.fileSelectionChanged, multiple: this.multiSelectAllowed, accept: acceptList })
          ),
          '\xA0',
          _npmReact.default.createElement('input', { type: 'text', className: 'form-control displayFileToUpload', value: fileAmalgam, readOnly: true })
        );
      }
    }]);

    return FileInput;
  }(_npmReact.default.Component);

  exports.default = FileInput;
});