define('webui/pods/components/blueprint-layer-item/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    isActive: false,
    editMode: false,
    originalZoom: null,

    mapData: Ember.inject.service('map-data'),
    dataPanelService: Ember.inject.service('data-panel'),

    setVisibleBlueprint: 'setVisibleBlueprint',
    removeVisibleBlueprint: 'removeVisibleBlueprint',

    didInsertElement: function didInsertElement() {
      // check to restore isActive state when this is torn down and reinserted.
      var map = this.get('mapData.mapInstance');
      var dataPanelService = this.get('dataPanelService');
      var activeBlueprints = dataPanelService.get('persistantDataPanelState.blueprints');
      if (activeBlueprints && activeBlueprints.length > 0) {
        if (activeBlueprints.findBy('id', this.get('blueprint.id'))) {
          this.set('isActive', true);
          var component = this;
          map.on('load', function () {
            return component.showBlueprint(component.get('blueprint'));
          });
        }
      }
    },
    siteMapBounds: function siteMapBounds() {
      var mapData = this.get('mapData');
      return mapData.currentMapBounds();
    },
    resetMap: function resetMap() {
      var mapData = this.get('mapData');
      mapData.safelyFitBounds(this.siteMapBounds());
      mapData.mapInstance.setZoom(this.originalZoom);
    },
    showBlueprint: function showBlueprint(blueprint) {
      var _this = this;

      var mapData = this.get('mapData');
      var tiledMap = blueprint.get('tiledMap');
      var url = tiledMap.get('displayUrl');
      var map = this.get('mapData.mapInstance');
      var layerName = 'blueprint-layer-' + blueprint.get('id');
      var sourceLayer = 'overlay-blueprint';
      var lineLayerName = layerName + '-line';
      var fillLayerName = layerName + '-fill';
      var labelLayerName = layerName + '-label';
      var layerSource = 'blueprint-source-' + blueprint.get('id');

      if (!this.originalZoom) {
        this.originalZoom = mapData.mapInstance.getZoom();
      }

      // Use the current center coordinates in the flyTo method
      this.resetMapToFitPoint();

      // add the tile source if not already available
      if (!map.getSource(layerSource)) {
        map.addSource(layerSource, {
          type: 'vector',
          tiles: [url + '{z}/{x}/{y}.pbf'],
          maxzoom: 20 // tiledMap.get('maxZoomLevel') - 1, // one layer less detail because it is breaking for some reason
        });
      }

      this.trackEvent('Blueprint Overlay View', {
        siteId: this.get('site.id'), siteName: this.get('site.name'), blueprintId: blueprint.get('id'), blueprint: blueprint.get('name')
      });
      if (map.getLayer(lineLayerName) || map.getLayer(fillLayerName) || map.getLayer(labelLayerName)) {
        // map layers exists, display them without loading

        map.setLayoutProperty(fillLayerName, 'visibility', 'visible');
        map.setLayoutProperty(lineLayerName, 'visibility', 'visible');
        map.setLayoutProperty(labelLayerName, 'visibility', 'visible');

        map.once('data', function () {
          map.once('idle', function () {
            var newLayerCenter = _this.calculateLayerCenter(map, lineLayerName);
            _this.flyToCoordinates(map, newLayerCenter);
          });
        });
      } else {
        // save some requests by only pulling the layer if it hasn't already been loaded

        map.addLayer({
          id: fillLayerName, // unique for this layer
          source: layerSource, // where to look for this
          'source-layer': sourceLayer, // used to filter multiple objects inside
          type: 'fill',
          paint: {
            'fill-color': {
              property: 'fill-color',
              type: 'identity'
            }
          },
          filter: ['==', 'type', 'polygon']
        });

        map.addLayer({
          id: lineLayerName, // unique for this layer
          source: layerSource, // where to look for this
          'source-layer': sourceLayer, // used to filter multiple objects inside
          type: 'line',
          paint: {
            'line-color': {
              property: 'line-color',
              type: 'identity'
            }
          },
          filter: ['==', 'type', 'line']
        }, fillLayerName);

        map.addLayer({
          id: labelLayerName, // unique for this layer
          source: layerSource, // where to look for this
          'source-layer': sourceLayer, // used to filter multiple objects inside
          type: 'symbol',
          layout: {
            'text-field': '{text-field}',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 14,
            'text-anchor': 'top',
            'text-allow-overlap': false
          },
          filter: ['==', 'type', 'label']
        }, lineLayerName);

        map.once('data', function () {
          map.once('idle', function () {
            var newLayerCenter = _this.calculateLayerCenter(map, lineLayerName);
            _this.flyToCoordinates(map, newLayerCenter);
          });
        });
      }
      this.set('isActive', true);
      this.sendAction('setVisibleBlueprint', blueprint, 'blueprints');
    },
    hideBlueprint: function hideBlueprint(blueprint) {
      var map = this.get('mapData.mapInstance');
      var layerName = 'blueprint-layer-' + blueprint.get('id');
      var lineLayerName = layerName + '-line';
      var fillLayerName = layerName + '-fill';
      var labelLayerName = layerName + '-label';

      map.setLayoutProperty(fillLayerName, 'visibility', 'none');
      map.setLayoutProperty(lineLayerName, 'visibility', 'none');
      map.setLayoutProperty(labelLayerName, 'visibility', 'none');

      this.set('isActive', false);
      this.sendAction('removeVisibleBlueprint', blueprint, 'blueprints');
    },


    actions: {
      toggleBlueprintLayer: function toggleBlueprintLayer(blueprint) {
        if (this.isActive === false) {
          this.showBlueprint(blueprint);
        } else {
          this.hideBlueprint(blueprint);
        }
      },
      editName: function editName() {
        this.toggleProperty('editMode');
      },
      saveName: function saveName() {
        this.get('blueprint').save();
        this.toggleProperty('editMode');
      }
    },
    resetMapToFitPoint: function resetMapToFitPoint() {
      var mapData = this.get('mapData');
      mapData.mapInstance.setPitch(0);
      mapData.mapInstance.resetNorth();
      mapData.fitMapToPoints(mapData.determineBoundaries(), 'lng', 'lat');
    },

    // Function to calculate the center of a layer based on its name
    calculateLayerCenter: function calculateLayerCenter(map, layerName) {
      var features = map.queryRenderedFeatures({ layers: [layerName] });
      var latLongArray = [];
      if (features.length > 0) {
        // Flatten coordinates for both LineString and MultiLineString geometries
        var flattenedCoordinates = features.flatMap(function (feature) {
          var geometrys = feature.geometry;
          if (geometrys.type === 'LineString') {
            return geometrys.coordinates;
          }
          if (geometrys.type === 'MultiLineString') {
            return geometrys.coordinates.flat();
          }
          return [];
        });

        // Check if there are valid coordinates
        if (flattenedCoordinates.length > 0) {
          // Calculate the average of all feature coordinates
          var center = flattenedCoordinates.reduce(function (acc, coordinates) {
            acc[0] += coordinates[0];
            acc[1] += coordinates[1];
            return acc;
          }, [0, 0]);

          center[0] /= flattenedCoordinates.length;
          center[1] /= flattenedCoordinates.length;
          localStorage.setItem(layerName, JSON.stringify(center));
          latLongArray = center;
        }
      } else {
        if (localStorage.getItem(layerName) === null) {
          latLongArray = map.getCenter().toArray();
        }
        var latLong = JSON.parse(localStorage.getItem(layerName));
        latLongArray = latLong;
      }
      return latLongArray;
    },

    // Function to fly to specific coordinates
    flyToCoordinates: function flyToCoordinates(map, coordinates) {
      var mapData = this.get('mapData');
      if (!this.originalZoom) {
        this.originalZoom = mapData.mapInstance.getZoom();
      }
      map.flyTo({
        center: coordinates,
        // zoom: this.originalZoom, // Adjust the zoom level as needed
        essential: true
      });
    }
  });
});