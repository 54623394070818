define('webui/models/site', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    latitudeBoundaryA: _emberData.default.attr('string'),
    longitudeBoundaryA: _emberData.default.attr('string'),
    latitudeBoundaryB: _emberData.default.attr('string'),
    longitudeBoundaryB: _emberData.default.attr('string'),
    boundaries: _emberData.default.attr(),
    preferSiteBoundariesForMapZoom: _emberData.default.attr('boolean', { defaultValue: false }),
    area: _emberData.default.attr('number', { defaultValue: 0 }),
    status: _emberData.default.attr('string'),
    blueprints: _emberData.default.hasMany('blueprint', { async: true }),
    flights: _emberData.default.hasMany('flight', { async: true }),
    clients: _emberData.default.hasMany('client', { async: true }),
    historicalExports: _emberData.default.hasMany('historical-export', { async: true }),
    materials: _emberData.default.hasMany('material', { async: true }),
    users: _emberData.default.hasMany('user', { async: true }),
    trackingRegions: _emberData.default.hasMany('tracking-region', { async: true }),
    sitePreferences: _emberData.default.hasMany('site-preference', { async: true }),
    picture: _emberData.default.hasMany('picture', { async: false }),
    uploadId: _emberData.default.attr('number'),
    uploadIdForDisplay: _emberData.default.attr('string'),
    crossSections: _emberData.default.hasMany('cross-section', { async: true }),
    quantities: _emberData.default.hasMany('quantity', { async: true }),
    workActivities: _emberData.default.hasMany('work-activity', { async: true }),
    trueTonnageReports: _emberData.default.hasMany('true-tonnage-report', { async: false }),
    urlPrefix: _emberData.default.attr('string'),
    gpsCalibrationFileName: _emberData.default.attr('string'),
    gpsCalibrationFileUrl: _emberData.default.attr('string'),
    staticElevations: _emberData.default.attr(),
    annotationLayers: _emberData.default.hasMany('annotation-layer', { async: false }),

    // TODO: convert NYA sites to 'undefined'
    adminOnly: function adminOnly() {
      return this.get('name') === '--NOT YET ASSOCIATED--';
    }.property('name'),
    client: Ember.computed('clients.@each', function client() {
      if (!this.get('clients').objectAt(0)) return null;

      return this.get('clients').objectAt(0);
    }),
    displayStatus: function displayStatus() {
      return this.get('status') === 'inactive' ? 'disabled' : this.get('status');
    }.property('status')
  });
});