define('webui/models/user-site', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    site: _emberData.default.attr('string'), // DS.belongsTo('site'), TODO: due to Ember Data contaminating the global record cache when the full site is retrieved here we're demoting this to just the ID
    user: _emberData.default.belongsTo('user'),
    grantor: _emberData.default.attr('string'),
    access: _emberData.default.attr('string')
  });
});