define('webui/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      this._super(data, type, record, options);

      if (record.record.get('password')) {
        data.meta = {
          password: record.record.get('password'),
          passwordConfirm: record.record.get('passwordConfirm')
        };
      }
    }
  });
});