define('webui/pods/components/floating-upload-status-widget/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fileManager: Ember.inject.service('file-manager'),
    uploader: Ember.inject.service('xhr-uploader'),

    pendingFiles: 0,
    finishedFiles: 0,
    errorFiles: 0,
    totalFiles: 0,
    percentageCompleted: 0,
    percentageErrors: 0,

    init: function init() {
      this._super();

      var uploader = this.get('uploader');
      var context = this;

      uploader.addObserver('currentUpload', function () {
        // check on file manager and set values
        context.updateUploadStatus();
      });
    },
    updateUploadStatus: function updateUploadStatus() {
      var fileManager = this.get('fileManager');
      var uploader = this.get('uploader');
      var currentFlight = fileManager.get('currentFlight');
      var queueStatus = void 0;
      var uploadTypeOfCurrentFlightIsPhoto = currentFlight && uploader.get('currentUploadType') === 'photo';

      if (uploadTypeOfCurrentFlightIsPhoto) {
        $('#floating-upload-status-widget').css('visibility', 'visible');

        if (currentFlight) {
          queueStatus = fileManager.queueStatus(currentFlight);

          this.set('pendingFiles', queueStatus.pendingFiles);
          this.set('finishedFiles', queueStatus.finishedFiles);
          this.set('errorFiles', queueStatus.errorFiles);
          this.set('totalFiles', queueStatus.totalFiles);
          this.set('percentageCompleted', this.getPercentage('finishedFiles'));
          this.set('percentageErrors', this.getPercentage('errorFiles'));
          this.set('percentagePending', this.getPercentage('pendingFiles'));
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      var uploader = this.get('uploader');
      uploader.removeObserver('currentUpload');
    },
    getPercentage: function getPercentage(type) {
      if (this.get(type) && this.get('totalFiles') > 0) {
        return Math.floor(this.get(type) / this.get('totalFiles') * 100);
      }
      return 0;
    },


    actions: {
      toggleProcessEstimates: function toggleProcessEstimates() {
        // Show the notations about how long uploads take
        var selector = $('.process-estimates');

        if (selector.css('visibility') === 'hidden') {
          return selector.css('visibility', 'visible');
        }
        return selector.css('visibility', 'hidden');
      },
      hideWidget: function hideWidget() {
        $('.statuswidgets').css('visibility', 'hidden');
      },
      hideEstimates: function hideEstimates() {
        $('.process-estimates').css('visibility', 'hidden');
      },
      openPhotoModal: function openPhotoModal() {
        $('#photo_uploader').modal('show');
      }
    }
  });
});