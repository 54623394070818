define('webui/services/user-feedback', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_mixin.default, {
    // message queues - items persist in these arrays until they reach the user
    error: [],
    success: [],
    info: [],

    pendingErrors: false,
    pendingSuccesses: false,
    pendingInfo: false,

    session: Ember.inject.service('session'),

    // report methods take message objects from API responses,
    // Ember error handling, or arbitrary functionality and push them into
    // the appropriate queue
    reportError: function reportError(error) {
      var _this = this;

      var formattedError = this.confirmFormat(error, 'Error');

      if (!(this.error.isAny('title', formattedError.title) && this.error.isAny('detail', formattedError.detail))) {
        this.error.pushObject(formattedError);
        this.trackEvent('Error', {
          errorTitle: formattedError.title,
          errorDetail: formattedError.detail
        });
      }
      this.set('pendingErrors', true);
      // Automatically remove the error message after 2-3 seconds
      var removeDelay = 10000; // 10 seconds
      setTimeout(function () {
        _this.error.removeObject(formattedError);
        _this.set('pendingErrors', false);
      }, removeDelay);
    },
    reportSuccess: function reportSuccess(success) {
      var _this2 = this;

      var formattedSuccess = this.confirmFormat(success, 'Success');
      if (!(this.success.isAny('title', formattedSuccess.title) && this.success.isAny('detail', formattedSuccess.detail))) {
        this.success.pushObject(formattedSuccess);
      }
      this.set('pendingSuccesses', true);
      // Automatically remove the success message after 2-3 seconds
      var removeDelay = 10000; // 10 seconds
      setTimeout(function () {
        _this2.success.removeObject(formattedSuccess);
        _this2.set('pendingSuccesses', false);
      }, removeDelay);
    },
    reportInfo: function reportInfo(info) {
      var _this3 = this;

      var formattedInfo = this.confirmFormat(info, 'Notice');
      if (!(this.info.isAny('title', formattedInfo.title) && this.info.isAny('detail', formattedInfo.detail))) {
        this.info.pushObject(info);
      }
      this.set('pendingInfo', true);

      // Automatically remove the info message after 2-3 seconds
      var removeDelay = 10000; // 10 seconds
      setTimeout(function () {
        _this3.info.removeObject(formattedInfo);
        _this3.set('pendingInfo', false);
      }, removeDelay);
    },


    // next methods pop the next item out of queue and return it to UI
    // return null when empty
    nextError: function nextError() {
      var next = this.error.popObject();
      if (!next || this.error.length < 1) {
        this.set('pendingErrors', false);
      }
      return next || null;
    },
    nextSuccess: function nextSuccess() {
      var next = this.success.popObject();
      if (!next || this.success.length < 1) {
        this.set('pendingSuccesses', false);
      }
      return next || null;
    },
    nextInfo: function nextInfo() {
      var next = this.info.popObject();
      if (!next || this.info.length < 1) {
        this.set('pendingInfo', false);
      }
      return next || null;
    },


    // process API response object and push it into appropriate queue
    handleResponse: function handleResponse(response) {
      // TODO: resolve responses returning as strings
      var formattedResponse = response;
      if (typeof response === 'string') {
        formattedResponse = JSON.parse(formattedResponse);
      }
      if (formattedResponse.status === 200) {
        this.reportSuccess(formattedResponse.message);
      } else if (formattedResponse.status === 500 || formattedResponse.status === 400 || formattedResponse.status === 403) {
        this.reportError(formattedResponse);
      } else if (formattedResponse.status >= 400 && formattedResponse.status <= 550) {
        console.error('user-feedback service caught unhandled error response!', formattedResponse);
      }
    },


    // helper function to convert string alerts into our accepted form
    // this could probably go away once the entire site is updated, but does provide
    // more flexible usage of this service
    confirmFormat: function confirmFormat(alert, title) {
      var formattedAlert = alert;
      if (typeof formattedAlert === 'string') {
        try {
          formattedAlert = JSON.parse(alert);
        } catch (err) {
          console.warn('Non-json-parseable error returned from server', err);
          formattedAlert = {
            title: title,
            detail: formattedAlert
          };
        }
        console.warn('user-feedback caught a string ' + title + ' alert - you should be passing alerts in the JSON form documented in API README');
      }
      // just in case it wasn't a string but was missing a title
      if (!formattedAlert.title) {
        formattedAlert.title = title;
      }
      return formattedAlert;
    }
  });
});