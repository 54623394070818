define("webui/pods/components/site-access-panel-user-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "fullname");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["content", "user.fullName", ["loc", [null, [4, 6], [4, 23]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "detail inactive");
          var el2 = dom.createTextNode("(invitation pending)");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "detail");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "user.accessLevelDisplay", ["loc", [null, [14, 24], [14, 51]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 22,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-floppy-save");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element7, 'onclick');
              return morphs;
            },
            statements: [["attribute", "onclick", ["subexpr", "action", ["saveUser"], [], ["loc", [null, [21, 60], [21, 81]]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 23,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "tool-tip", [], ["tooltipContent", "<p>Save</p>", "tooltipPlace", "right"], 0, null, ["loc", [null, [20, 4], [22, 17]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 4
                },
                "end": {
                  "line": 26,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-pencil");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element6, 'onclick');
              return morphs;
            },
            statements: [["attribute", "onclick", ["subexpr", "action", ["editUser"], [], ["loc", [null, [25, 55], [25, 76]]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "tool-tip", [], ["tooltipContent", "<p>Edit</p>", "tooltipPlace", "right"], 0, null, ["loc", [null, [24, 4], [26, 17]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 2
            },
            "end": {
              "line": 28,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editMode", ["loc", [null, [19, 8], [19, 16]]]]], [], 0, 1, ["loc", [null, [19, 2], [27, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 0
              },
              "end": {
                "line": 45,
                "column": 0
              }
            },
            "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "edit-form col-sm-12");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("form");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            var el4 = dom.createTextNode("Access level");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "radio-inline");
            var el4 = dom.createElement("input");
            dom.setAttribute(el4, "type", "radio");
            dom.setAttribute(el4, "value", "view");
            dom.setAttribute(el4, "name", "access");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("Limited Access");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "radio-inline");
            var el4 = dom.createElement("input");
            dom.setAttribute(el4, "type", "radio");
            dom.setAttribute(el4, "value", "adminview");
            dom.setAttribute(el4, "name", "access");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("View Only");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "radio-inline");
            var el4 = dom.createElement("input");
            dom.setAttribute(el4, "type", "radio");
            dom.setAttribute(el4, "value", "accounting");
            dom.setAttribute(el4, "name", "access");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("Accounting Access");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "radio-inline");
            var el4 = dom.createElement("input");
            dom.setAttribute(el4, "type", "radio");
            dom.setAttribute(el4, "value", "admin");
            dom.setAttribute(el4, "name", "access");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("Full Access");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "center");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4, "class", "btn-link");
            var el5 = dom.createTextNode("REMOVE USER");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var element1 = dom.childAt(element0, [4, 0]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element1, [], true);
            }
            var element2 = dom.childAt(element0, [6, 0]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element2, [], true);
            }
            var element3 = dom.childAt(element0, [8, 0]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element3, [], true);
            }
            var element4 = dom.childAt(element0, [10, 0]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element4, [], true);
            }
            var element5 = dom.childAt(element0, [12, 1]);
            var morphs = new Array(10);
            morphs[0] = dom.createAttrMorph(element0, 'name');
            morphs[1] = dom.createAttrMorph(element1, 'checked');
            morphs[2] = dom.createAttrMorph(element1, 'onchange');
            morphs[3] = dom.createAttrMorph(element2, 'checked');
            morphs[4] = dom.createAttrMorph(element2, 'onchange');
            morphs[5] = dom.createAttrMorph(element3, 'checked');
            morphs[6] = dom.createAttrMorph(element3, 'onchange');
            morphs[7] = dom.createAttrMorph(element4, 'checked');
            morphs[8] = dom.createAttrMorph(element4, 'onchange');
            morphs[9] = dom.createAttrMorph(element5, 'onclick');
            return morphs;
          },
          statements: [["attribute", "name", ["get", "user.id", ["loc", [null, [34, 17], [34, 24]]]]], ["attribute", "checked", ["get", "user.isLimitedAccess", ["loc", [null, [36, 91], [36, 111]]]]], ["attribute", "onchange", ["subexpr", "action", ["setUserAccess", "site:limited"], [], ["loc", [null, [36, 123], [36, 164]]]]], ["attribute", "checked", ["get", "user.isViewOnly", ["loc", [null, [37, 96], [37, 111]]]]], ["attribute", "onchange", ["subexpr", "action", ["setUserAccess", "site:view"], [], ["loc", [null, [37, 123], [37, 161]]]]], ["attribute", "checked", ["get", "user.isAccounting", ["loc", [null, [38, 97], [38, 114]]]]], ["attribute", "onchange", ["subexpr", "action", ["setUserAccess", "site:accounting"], [], ["loc", [null, [38, 126], [38, 170]]]]], ["attribute", "checked", ["get", "user.isSiteAdmin", ["loc", [null, [39, 92], [39, 108]]]]], ["attribute", "onchange", ["subexpr", "action", ["setUserAccess", "site:admin"], [], ["loc", [null, [39, 120], [39, 159]]]]], ["attribute", "onclick", ["subexpr", "action", ["removeUser"], [], ["loc", [null, [41, 41], [41, 64]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 0
            },
            "end": {
              "line": 46,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editMode", ["loc", [null, [32, 6], [32, 14]]]]], [], 0, null, ["loc", [null, [32, 0], [45, 7]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/site-access-panel-user-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "detail col-sm-10");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "detail email");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "actions col-xs-2");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element8, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element8, 5, 5);
        morphs[3] = dom.createMorphAt(element8, 6, 6);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "user.lastName", ["loc", [null, [2, 8], [2, 21]]]]], [], 0, null, ["loc", [null, [2, 2], [6, 9]]]], ["content", "user.email", ["loc", [null, [8, 4], [8, 18]]]], ["block", "if", [["subexpr", "eq", [["get", "user.status", ["loc", [null, [10, 12], [10, 23]]]], "pending"], [], ["loc", [null, [10, 8], [10, 34]]]]], [], 1, null, ["loc", [null, [10, 2], [12, 9]]]], ["block", "unless", [["get", "editMode", ["loc", [null, [13, 12], [13, 20]]]]], [], 2, null, ["loc", [null, [13, 2], [15, 13]]]], ["block", "unless", [["subexpr", "or", [["get", "authenticatedUser.isLimitedAccess", ["loc", [null, [18, 16], [18, 49]]]], ["get", "authenticatedUser.isViewOnly", ["loc", [null, [18, 50], [18, 78]]]], ["get", "user.isOnTeamRoster", ["loc", [null, [18, 79], [18, 98]]]]], [], ["loc", [null, [18, 12], [18, 99]]]]], [], 3, null, ["loc", [null, [18, 2], [28, 13]]]], ["block", "unless", [["subexpr", "or", [["get", "authenticatedUser.isLimitedAccess", ["loc", [null, [31, 14], [31, 47]]]], ["get", "authenticatedUser.isViewOnly", ["loc", [null, [31, 48], [31, 76]]]], ["get", "authenticatedUser.isAccounting", ["loc", [null, [31, 77], [31, 107]]]]], [], ["loc", [null, [31, 10], [31, 108]]]]], [], 4, null, ["loc", [null, [31, 0], [46, 11]]]], ["content", "yield", ["loc", [null, [47, 0], [47, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});