define('webui/pods/components/account-navigation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    doTransition: 'doTransition',
    // currentClient: null,
    // newSiteClient: null,
    showSiteListClient: null,
    showUserListClient: null,

    didRender: function didRender() {
      if (this.get('client.id')) {
        this.set('currentClient', this.get('client.id'));
      }
    },


    actions: {
      loadAccount: function loadAccount() {
        this.sendAction('doTransition', 'account');
      },
      loadClient: function loadClient(clientId) {
        this.set('currentClient', clientId);
        this.sendAction('doTransition', 'account.client', clientId);
      },
      loadSite: function loadSite(siteId) {
        this.sendAction('doTransition', 'site', siteId);
      },
      newSite: function newSite(clientId) {
        this.sendAction('doTransition', 'account.client', clientId, { queryParams: { activeTab: 'sites', showCreate: true } });
        this.set('newUserClient', null);
        this.set('newSiteClient', clientId);
      },
      newUser: function newUser(clientId) {
        this.set('newSiteClient', null);
        this.set('newUserClient', clientId);
        this.sendAction('doTransition', 'account.client', clientId, { queryParams: { activeTab: 'users', showCreate: true } });
      },
      showSiteList: function showSiteList(clientId) {
        if (clientId === this.get('showSiteListClient')) {
          this.set('showSiteListClient', null);
        } else {
          this.set('showSiteListClient', clientId);
        }
      },
      showUserList: function showUserList(clientId) {
        if (clientId === this.get('showUserListClient')) {
          this.set('showUserListClient', null);
        } else {
          this.set('showUserListClient', clientId);
        }
      }
    }
  });
});