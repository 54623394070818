define('webui/models/credit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    client: _emberData.default.belongsTo('client', { async: true }),
    datetime: _emberData.default.attr('string'),
    action: _emberData.default.attr('string')
  });
});