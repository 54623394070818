define('webui/pods/components/3d-embed/component', ['exports', 'webui/mixins/map-export'], function (exports, _mapExport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mapExport.default, {
    sketchfabApi: null,
    inExportMode: false,
    viewerReady: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.loadExportImages();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('flight.sketchfabModelId')) this._initSketchfabViewer();
    },
    _initSketchfabViewer: function _initSketchfabViewer() {
      var iframe = $('#sketchfab-api-frame')[0];
      var sketchfabApiVersion = '1.2.1';
      var urlId = this.get('flight.sketchfabModelId');
      var client = new Sketchfab(sketchfabApiVersion, iframe);

      client.init(urlId, {
        success: function success(api) {
          var _this = this;

          this.set('sketchfabApi', api);

          api.addEventListener('viewerready', function () {
            _this.set('viewerReady', true);
          });
        }.bind(this),
        error: function error() {},

        ui_stop: 0,
        ui_help: 0,
        ui_inspector: 0,
        ui_settings: 0,
        ui_vr: 0,
        ui_infos: 0,
        ui_watermark_link: 1,
        autostart: 0
      });
    },
    _saveScreenshotToPdf: function _saveScreenshotToPdf() {
      var _this2 = this;

      this.get('sketchfabApi').getScreenShot('image/png', function (err, result) {
        _this2.create3dModelPdf(result);
      });
    },


    actions: {
      toggleExportMode: function toggleExportMode() {
        if (this.get('inExportMode')) {
          this.set('inExportMode', false);
          $('#sketchfab-api-frame').removeClass('export-mode');
          this.resizeForViewMode('#sketchfab-api-frame', false);
        } else {
          this.set('inExportMode', true);
          $('#sketchfab-api-frame').addClass('export-mode');
          this.resizeForExportMode('#sketchfab-api-frame', false);
        }
      },
      downloadScreenshot: function downloadScreenshot() {
        $('#exportSpinner').removeClass('hidden');
        Ember.run.debounce(this, function saveScreenshotToPdf() {
          this._saveScreenshotToPdf();
          $('#exportSpinner').addClass('hidden');
        }, 100);
      },
      handleInputKeyup: function handleInputKeyup(event) {
        if (event.keyCode === 13 || event.keyCode === 27) {
          event.srcElement.blur();
        }
      },
      handleWidthBlur: function handleWidthBlur() {
        var widthString = $('#exportWidth').val();
        $('#exportWidth').val(this.setWidth('#sketchfab-api-frame', widthString));
      },
      handleHeightBlur: function handleHeightBlur() {
        var heightString = $('#exportHeight').val();
        $('#exportHeight').val(this.setHeight('#sketchfab-api-frame', heightString));
      }
    }
  });
});