define('webui/pods/components/true-tonnage-report/read-only-table/component', ['exports', 'webui/mixins/true-tonnage-report'], function (exports, _trueTonnageReport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend(_trueTonnageReport.default, {
    apiAction: 'submitChangeRequests',

    init: function init() {
      this._super();
      this.set('canDownloadExcel', this.get('trueTonnageReport.trueTonnageReportStep.code') === 'complete');
    },
    getMaterialData: function getMaterialData() {
      var _this = this;

      return this.getAllMaterialIDs().map(function (materialId) {
        var trueTonnageReportMaterial = _this.getTrueTonnageReportMaterial(materialId);

        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial.get('id'),
          materialId: trueTonnageReportMaterial.get('material'),
          name: trueTonnageReportMaterial.get('name'),
          key: trueTonnageReportMaterial.get('key'),
          density: trueTonnageReportMaterial.get('density'),
          volume: _this.formatNumberForDisplay(_this.getVolumeTotalForMaterial(materialId)),
          tonnage: _this.formatNumberForDisplay(_this.getTonnageTotalForMaterial(materialId)),
          tonnageNumber: _this.getTonnageTotalForMaterial(materialId),
          plannedTonnage: trueTonnageReportMaterial.get('plannedTonnage'),
          tonnageDifference: _this.getTonnageDifference(trueTonnageReportMaterial),
          tonnageVariance: _this.getTonnageVariance(trueTonnageReportMaterial),
          tonnageVarianceDisplay: _this.getTonnageVarianceDisplay(trueTonnageReportMaterial),
          showVarianceWarning: _this.showVarianceWarning(trueTonnageReportMaterial),
          changeRequested: trueTonnageReportMaterial.get('changeRequested'),
          changeRequestTonnage: trueTonnageReportMaterial.get('changeRequestTonnage'),
          changeRequestReason: trueTonnageReportMaterial.get('changeRequestReason'),
          regionData: _this.getRegionDataForMaterial(materialId),
          hideMaterial: trueTonnageReportMaterial.get('hideFromTrueTonnageReport')
        };
      }).filter(function (material) {
        return !material.hideMaterial;
      });
    },
    getRegionDataForMaterial: function getRegionDataForMaterial(materialId) {
      var _this2 = this;

      var materialForName = this.get('store').peekRecord('material', materialId);

      return this.getUniqueRegionIDsForMaterial(materialId).map(function (trueTonnageReportRegionId) {
        var revisions = _this2.getRevisionDataForRegion(trueTonnageReportRegionId).map(function (trueTonnageReportRegion) {
          return {
            trueTonnageReportRegionId: trueTonnageReportRegion.get('id'),
            materialId: trueTonnageReportRegion.get('material'),
            regionId: trueTonnageReportRegion.get('region'),
            name: trueTonnageReportRegion.get('name'),
            nameWithMaterialCode: materialForName.get('key') ? trueTonnageReportRegion.get('name').replace(materialForName.get('name'), materialForName.get('key')) : trueTonnageReportRegion.get('name'),
            netVolume: trueTonnageReportRegion.get('netVolume'),
            density: trueTonnageReportRegion.get('displayDensity'),
            displayTonnage: trueTonnageReportRegion.get('displayTonnage'),
            plannedTonnage: trueTonnageReportRegion.get('plannedTonnage'),
            tonnageDifference: trueTonnageReportRegion.get('tonnageDifference'),
            tonnageVarianceDisplay: trueTonnageReportRegion.get('tonnageVarianceDisplay'),
            boundaries: trueTonnageReportRegion.get('boundaries'),
            mapboxPointFeature: trueTonnageReportRegion.get('mapboxPointFeature'),
            changeRequestFulfilledComment: trueTonnageReportRegion.get('changeRequestFulfilledComment'),
            revisionDate: trueTonnageReportRegion.get('revisionDate'),
            wasDeleted: trueTonnageReportRegion.get('wasDeleted'),
            hideRegion: trueTonnageReportRegion.get('hideFromTrueTonnageReport')
          };
        });

        return _extends({}, revisions[0], { revisions: revisions });
      }).filter(function (region) {
        return !region.hideRegion;
      });
    },
    getRegionsForMaterialTotals: function getRegionsForMaterialTotals(materialId) {
      var _this3 = this;

      return this.getUniqueRegionIDsForMaterial(materialId).map(function (regionId) {
        return _this3.getRevisionDataForRegion(regionId)[0];
      }).filter(function (region) {
        return region.get('region') && !region.get('excludeRegion') && !region.get('hideFromTrueTonnageReport');
      });
    }
  });
});