define('webui/pods/components/region-edit-confirmation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cancelRegionChanges: 'cancelRegionChanges',
    saveRegionChanges: 'saveRegionChanges',

    actions: {
      cancelRegionChanges: function cancelRegionChanges() {
        this.sendAction('cancelRegionChanges');
      },
      saveRegionChanges: function saveRegionChanges() {
        this.sendAction('saveRegionChanges');
      }
    }
  });
});