define('webui/pods/components/job-list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    jobStatuses: {},

    willRender: function willRender() {
      // needed to initialize dropdown select.
      var jobStatuses = {
        pending: { selected: false, disabled: false },
        'running-part-one': { selected: false, disabled: true },
        'failed-part-one': { selected: false, disabled: false },
        'needs-human': { selected: false, disabled: false },
        'human-verified': { selected: false, disabled: false },
        'running-part-two': { selected: false, disabled: true },
        'failed-part-two': { selected: false, disabled: false },
        'needs-class': { selected: false, disabled: false },
        'human-class': { selected: false, disabled: false },
        'running-part-three': { selected: false, disabled: true },
        'failed-part-three': { selected: false, disabled: false },
        'upload-pending': { selected: false, disabled: false },
        'running-upload': { selected: false, disabled: true },
        'failed-upload': { selected: false, disabled: false },
        succeeded: { selected: false, disabled: false }
      };
      var type = 'list-group-item list-group-item-info';
      var status = this.get('job.status');
      var jobDurationInHours = this.get('job.durationInHours');
      var blockedStatuses = ['needs-human', 'needs-class'];
      var failedStatuses = ['failed-part-one', 'failed-part-two', 'failed-part-three', 'failed-upload'];
      var succeededStatuses = ['succeeded'];

      if (failedStatuses.indexOf(status) !== -1) {
        type = 'list-group-item list-group-item-danger';
      } else if (blockedStatuses.indexOf(status) !== -1) {
        type = 'list-group-item list-group-item-warning';
      } else if (succeededStatuses.indexOf(status) !== -1) {
        type = 'list-group-item list-group-item-success';
      }

      if (status) {
        /* If the job is destroyed it will still try to run through willRender and will fail. */
        jobStatuses[status].selected = true;
      }
      this.set('jobStatuses', jobStatuses);

      this.set('styling', type);
      if (jobDurationInHours) {
        if (jobDurationInHours >= 24 && jobDurationInHours < 48) {
          this.set('styling', type + ' job-duration-warning');
        } else if (jobDurationInHours >= 48) {
          this.set('styling', type + ' job-duration-danger');
        }
      }
    },


    actions: {
      deleteJob: function deleteJob() {
        this.get('job').destroyRecord();
        this.set('job', null); // set null to hide component
      },
      deleteJobAndFlight: function deleteJobAndFlight() {
        var job = this.get('job');
        var component = this;

        // b/c of model relation job.flight returns promise
        this.get('job.flight').then(function (flight) {
          flight.destroyRecord();
          job.destroyRecord();
          component.set('job', null); // set null to hide component
        });
      },
      updateJobStatus: function updateJobStatus(newStatus) {
        var job = this.get('job');
        this.sendAction('updateJobStatus', newStatus, job);
      }
    }
  });
});