define('webui/pods/components/elevation-data-view/component', ['exports', 'npm:lodash', 'ember-cli-segment/mixin'], function (exports, _npmLodash, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    store: Ember.inject.service('store'),
    feedback: Ember.inject.service('user-feedback'),
    mapData: Ember.inject.service('mapData'),
    session: Ember.inject.service('session'),
    socketManager: Ember.inject.service('socket-manager'),
    elevationLayerManager: Ember.inject.service('elevation-layer-manager'),
    popup: null,
    elevationDataLayers: Ember.A(),

    enabled: Ember.observer('elevationLayerManager.enabled', function updateEnabled() {
      if (this.get('elevationLayerManager.enabled')) {
        this.send('enable');
      } else {
        this.send('disable');
      }
    }),

    tiledMapUpdate: Ember.observer('socketManager.tiledMapUpdate', function tiledMapUpdate() {
      this.resetLayer();
    }),

    init: function init() {
      this._super();
      this.get('socketManager');
      this.get('elevationLayerManager');
    },
    resetLayer: function resetLayer() {
      var store = this.get('store');
      var activeFlightId = this.get('mapData.currentFlightId');
      var activeFlight = store.peekRecord('flight', activeFlightId);
      var elevationExists = activeFlight.get('tiledMaps').filter(function (map) {
        return map.get('status') === 'succeeded' && map.get('type') === 'elevation-data';
      }).length;

      if (!elevationExists) {
        this.send('disable');
      }
    },


    actions: {
      enable: function enable() {
        this.send('showElevationData');
      },
      disable: function disable() {
        this.send('hideElevationData');
      },
      hideElevationData: function hideElevationData() {
        var component = this;
        var mapData = this.get('mapData');

        this.get('elevationDataLayers').forEach(function (layer) {
          if (mapData.mapInstance.getLayer(layer)) {
            mapData.mapInstance.removeLayer(layer);
            mapData.mapInstance.off('mousemove', component.mouseMoveListener);
            mapData.mapInstance.off('click', component.clickListener);
            component.get('popup').remove();
          }
        });
      },
      showElevationData: function showElevationData() {
        var elevationData = this.get('mapData.currentSite.flights').findBy('id', this.get('mapData.currentFlightId')).get('tiledMaps').findBy('type', 'elevation-data');

        var trackData = {
          user: this.get('session.data.authenticated.email'),
          site: this.get('mapData.currentSite.id'),
          flight: this.get('mapData.currentFlightId')
        };

        if (!elevationData || elevationData.status === 'failed') {
          return null;
        }

        if (elevationData.status === 'pending') {
          return this.get('feedback').reportInfo({
            title: 'Elevation Data Not Ready',
            detail: 'The selected map is not ready yet. Please wait until it loads and try again.'
          });
        }

        // check if source has already been added
        var mapData = this.get('mapData');
        var map = mapData.mapInstance;
        var layerName = 'elevation-data-' + elevationData.id;
        if (!map.getSource(layerName)) {
          map.addSource(layerName, {
            type: 'vector',
            tiles: [elevationData.get('displayUrl') + '{z}/{x}/{y}.pbf'],
            maxzoom: 20 // elevationData.get('maxZoomLevel') - 1, // remove a zoom level because otherwise it breaks
          });
        }

        // remove any existing layers no matter what (for now)
        this.send('hideElevationData', false);

        if (map.getLayer(layerName)) {
          map.setLayoutProperty(layerName, 'visibility', 'visible');
          return null;
        }

        this.get('elevationDataLayers').push(layerName);

        map.addLayer({
          id: layerName,
          source: layerName,
          'source-layer': 'elevation-data',
          type: 'fill',
          paint: {
            'fill-opacity': 0.2,
            'fill-color': 'blue'
          },
          filter: ['has', 'label']
        });

        this.trackEvent('Elevation Hover View', trackData);

        var mapboxgl = mapData.MAPAPI;
        this.set('popup', new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        }));

        // Define the mouse listener callback on the component so that it can be
        // removed and redefined each time we change the elevation data.
        var component = this;
        this.mouseMoveListener = function mouseMoveListener(e) {
          var popup = component.get('popup');
          var features = map.queryRenderedFeatures(e.point, { layers: [layerName] });
          if (!features || !features.length) {
            popup.remove();
            return;
          }

          var lngLat = e.lngLat;

          popup.setLngLat(lngLat).setHTML('<h4>' + features[0].properties.label + '</h4>' + ('<h4>lat: ' + lngLat.lat.toFixed(8) + '</h4>') + ('<h4>lng: ' + lngLat.lng.toFixed(8) + '</h4>')).addTo(map);
        };

        this.clickListener = function clickListener(e) {
          var features = map.queryRenderedFeatures(e.point, { layers: [layerName] });
          if (!features[0].properties) {
            return;
          }

          var needsConvertedToFeet = features[0].properties.label.indexOf('m') !== -1;
          var height = features[0].properties.label.replace(' ft', '').replace(' m', '');
          if (needsConvertedToFeet) {
            height *= 3.28084;
          }
          var roundedHeight = _npmLodash.default.round(height, 1);

          component.get('elevationLayerManager').setSelectedElevation(roundedHeight);
        };

        map.on('mousemove', component.mouseMoveListener);
        map.on('click', component.clickListener);

        return null;
      }
    }
  });
});