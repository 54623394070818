define('webui/models/correction-method', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'), /* ['gcp', 'ppk-cors', 'ppk-self-known-point', 'ppk-self-unknown-point'] */
    baseStationSamplingRate: _emberData.default.attr('number'),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    correctionFiles: _emberData.default.hasMany('correction-file', { async: true })
  });
});