define('webui/pods/components/true-tonnage-report/editor-table/component', ['exports', 'webui/mixins/true-tonnage-report'], function (exports, _trueTonnageReport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_trueTonnageReport.default, {
    apiAction: 'submitRegionChanges',

    getAllMaterialIDs: function getAllMaterialIDs() {
      return this.getSiteMaterials().map(function (material) {
        return material.get('id');
      });
    },
    getMaterialData: function getMaterialData() {
      var _this = this;

      return this.getAllMaterialIDs().map(function (materialId) {
        var material = _this.getMaterial(materialId);
        var trueTonnageReportMaterial = _this.getTrueTonnageReportMaterial(materialId);
        var regionData = _this.getRegionDataForMaterial(materialId);

        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('id') : null,
          materialId: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('material') : material.get('id'),
          name: material.get('name'),
          density: material.get('density'),
          key: material.get('key'),
          volume: _this.formatNumberForDisplay(_this.getVolumeTotalForMaterial(materialId)),
          tonnage: _this.formatNumberForDisplay(_this.getTonnageTotalForMaterial(materialId)),
          tonnageNumber: _this.getTonnageTotalForMaterial(materialId),
          plannedTonnage: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('plannedTonnage') : material.get('plannedTonnage'),
          tonnageVarianceDisplay: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('tonnageVarianceDisplay') : material.get('tonnageVarianceDisplay'),
          changeRequested: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('changeRequested') : null,
          changeRequestTonnage: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('changeRequestTonnage') : null,
          changeRequestReason: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('changeRequestReason') : null,
          changeRequestedBy: trueTonnageReportMaterial ? _this.get('trueTonnageReport.' + trueTonnageReportMaterial.get('changeRequestStep.activeUserField') + '.fullNameAndEmail') : null,
          regionData: regionData,
          showRegions: regionData.reduce(function (regionHasChangeRequest, currentRegion) {
            return regionHasChangeRequest || currentRegion.changeRequested;
          }, false),
          needsCreated: !trueTonnageReportMaterial,
          wasModified: !trueTonnageReportMaterial || trueTonnageReportMaterial.get('changeRequested'),
          hideMaterial: trueTonnageReportMaterial ? trueTonnageReportMaterial.get('hideFromTrueTonnageReport') : false
        };
      }).filter(function (material) {
        return !material.hideMaterial;
      });
    },
    getIDsForBothHistoricalAndNewRegionIDs: function getIDsForBothHistoricalAndNewRegionIDs() {
      var _this2 = this;

      var materialId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var currentRegionIDs = this.getRegionsForMaterialTotals(materialId).map(function (region) {
        return region.get('id');
      });
      var historicalRegionIDs = this.getUniqueRegionIDsForMaterial(materialId);
      var historicalAndNewRegionIDs = [].concat(_toConsumableArray(new Set([].concat(_toConsumableArray(currentRegionIDs), _toConsumableArray(historicalRegionIDs)))));

      return historicalAndNewRegionIDs.filter(function (regionId) {
        var region = _this2.get('store').peekRecord('region', regionId);
        if (region && region.get('material.id') === materialId) return true;

        var revisions = _this2.getRevisionDataForRegion(regionId);
        if (!region && revisions[0].get('material') === materialId) return true;

        return false;
      });
    },
    getRegionDataForMaterial: function getRegionDataForMaterial(materialId) {
      var _this3 = this;

      var materialForName = this.get('store').peekRecord('material', materialId);

      return this.getIDsForBothHistoricalAndNewRegionIDs(materialId).map(function (regionId) {
        var region = _this3.get('store').peekRecord('region', regionId);
        var revisionData = _this3.getRevisionDataForRegion(regionId);
        var revisions = revisionData.map(function (trueTonnageReportRegion) {
          return {
            trueTonnageReportRegionId: trueTonnageReportRegion.get('id'),
            regionId: trueTonnageReportRegion.get('region'),
            materialId: region ? region.get('material.id') : trueTonnageReportRegion.get('material'),
            name: trueTonnageReportRegion.get('name'),
            nameWithMaterialCode: materialForName.get('key') ? trueTonnageReportRegion.get('name').replace(materialForName.get('name'), materialForName.get('key')) : trueTonnageReportRegion.get('name'),
            netVolume: trueTonnageReportRegion.get('netVolume'),
            density: trueTonnageReportRegion.get('displayDensity'),
            displayTonnage: trueTonnageReportRegion.get('displayTonnage'),
            plannedTonnage: trueTonnageReportRegion.get('plannedTonnage'),
            tonnageVarianceDisplay: trueTonnageReportRegion.get('tonnageVarianceDisplay'),
            boundaries: trueTonnageReportRegion.get('boundaries'),
            mapboxPointFeature: trueTonnageReportRegion.get('mapboxPointFeature'),
            changeRequested: trueTonnageReportRegion.get('changeRequested'),
            changeRequestedBy: _this3.get('trueTonnageReport.' + trueTonnageReportRegion.get('changeRequestStep.activeUserField') + '.fullNameAndEmail'),
            changeRequestTonnage: trueTonnageReportRegion.get('changeRequestTonnage'),
            changeRequestReason: trueTonnageReportRegion.get('changeRequestReason'),
            changeRequestFulfilledComment: trueTonnageReportRegion.get('changeRequestFulfilledComment'),
            revisionDate: trueTonnageReportRegion.get('revisionDate'),
            hideRegion: trueTonnageReportRegion.get('hideFromTrueTonnageReport')
          };
        });

        var headerRegion = void 0;
        var isNewRegion = revisions.length === 0;
        if (isNewRegion) {
          headerRegion = {
            regionId: region.get('id'),
            name: region.get('name'),
            nameWithMaterialCode: region.get('material.key') ? region.get('name').replace(region.get('material.name'), region.get('material.key')) : region.get('name'),
            materialId: region.get('material.id'),
            netVolume: region.get('netVolume'),
            density: region.get('displayDensity'),
            displayTonnage: region.get('displayTonnage'),
            plannedTonnage: region.get('plannedTonnage'),
            tonnageVarianceDisplay: region.get('tonnageVarianceDisplay'),
            regionWasModified: true
          };
        } else {
          var trueTonnageReportRegion = revisionData[0];
          var regionWasDeleted = trueTonnageReportRegion.get('wasDeleted');
          var regionNeedsDeleted = !region && !regionWasDeleted;

          headerRegion = _extends({}, revisions[0]);
          headerRegion.materialId = region ? region.get('material.id') : headerRegion.materialId;
          headerRegion.name = region ? region.get('name') : headerRegion.name;
          headerRegion.netVolume = region ? region.get('netVolume') : '';
          headerRegion.density = region ? region.get('displayDensity') : '';
          headerRegion.displayTonnage = region ? region.get('displayTonnage') : '';
          headerRegion.plannedTonnage = region ? region.get('plannedTonnage') : '';
          headerRegion.tonnageVarianceDisplay = region ? region.get('tonnageVarianceDisplay') : '';
          headerRegion.regionNeedsDeleted = regionNeedsDeleted;
          headerRegion.regionWasModified = !regionWasDeleted && (regionNeedsDeleted || trueTonnageReportRegion.get('changeRequested') || region.get('updatedAt') > trueTonnageReportRegion.get('createdAt'));
        }

        return _extends({}, headerRegion, { revisions: revisions });
      }).filter(function (region) {
        return !region.hideRegion;
      });
    },
    getRegionsForMaterialTotals: function getRegionsForMaterialTotals() {
      var _this4 = this;

      var materialId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var hiddenRegionIds = this.get('store').peekAll('true-tonnage-report-region').filter(function (trueTonnageRegion) {
        return trueTonnageRegion.get('trueTonnageReport.flight.id') === _this4.get('flightId');
      }).filter(function (trueTonnageRegion) {
        return trueTonnageRegion.get('hideFromTrueTonnageReport');
      }).map(function (trueTonnageRegion) {
        return trueTonnageRegion.get('region');
      });

      return this.get('store').peekAll('region').filter(function (region) {
        return region.get('flight.id') === _this4.get('flightId');
      }).filter(function (region) {
        return region.get('material.id') === materialId;
      }).filter(function (region) {
        return !(hiddenRegionIds.indexOf(region.get('id')) !== -1);
      }).sortBy('name');
    },
    getMaterialChanges: function getMaterialChanges() {
      return this.getMaterialsFromFormWithChangeRequest().map(function (trueTonnageReportMaterial) {
        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial.trueTonnageReportMaterialId,
          plannedTonnage: trueTonnageReportMaterial.plannedTonnage === '' ? null : trueTonnageReportMaterial.plannedTonnage,
          needsCreated: trueTonnageReportMaterial.needsCreated,
          changeRequested: false,
          changeRequestTonnage: null,
          changeRequestReason: null
        };
      });
    },
    getMaterialsFromFormWithChangeRequest: function getMaterialsFromFormWithChangeRequest() {
      return this.getMaterialsFromForm().filter(function (trueTonnageReportMaterial) {
        return trueTonnageReportMaterial.wasModified;
      });
    },
    getRegionChanges: function getRegionChanges() {
      return this.getRegionsFromFormWithModifications().map(function (trueTonnageReportRegion) {
        return {
          trueTonnageReportRegionId: trueTonnageReportRegion.trueTonnageReportRegionId,
          regionId: trueTonnageReportRegion.regionId,
          regionNeedsDeleted: trueTonnageReportRegion.regionNeedsDeleted,
          changeRequestFulfilledComment: trueTonnageReportRegion.newChangeRequestFulfilledComment
        };
      });
    },
    getRegionsFromFormWithModifications: function getRegionsFromFormWithModifications() {
      return this.getRegionsFromForm().filter(function (trueTonnageReportRegion) {
        return trueTonnageReportRegion.regionWasModified;
      });
    },
    getReportError: function getReportError() {
      if (this.areThereUnsavedRegions()) {
        return { title: 'You have unsaved regions', detail: 'Please save all regions before submitting.' };
      }

      if (this.areThereRegionsStillBeingProcessed()) {
        return { title: 'Regions Are Still Being Processed', detail: 'One or more regions are still being processed. Please submit after all regions have finished processing.' };
      }

      return null;
    }
  });
});