define('webui/pods/components/cdt-available-comparisons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    selectComparison: 'selectComparison',
    displayComparison: 'displayComparison',
    hideComparison: 'hideComparison',
    didRender: function didRender() {
      $('.noclick').on('click', function (event) {
        event.stopPropagation();
      });
    },

    actions: {
      selectComparison: function selectComparison(type, model) {
        this.sendAction('selectComparison', type, model);
      },
      displayComparison: function displayComparison(changeDetection, model, type) {
        this.sendAction('displayComparison', changeDetection, model, type);
      },
      hideComparison: function hideComparison(changeDetection, model) {
        this.sendAction('hideComparison', changeDetection, model);
      },
      goToFileManager: function goToFileManager() {
        this.sendAction('goToFileManager', this.get('currentFlight.site.id'), 'dsm');
      }
    }
  });
});