define('webui/services/feature-flags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    featuresMap: {},

    loadFeaturesMap: function loadFeaturesMap() {
      var featuresMap = this.get('session.data.authenticated.featuresMap');
      this.set('featuresMap', featuresMap || {});
    },
    featureEnabled: function featureEnabled(feature) {
      return this.get('featuresMap')[feature] === true;
    }
  });
});