define('webui/mixins/check-for-map-load', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fakeLayerCount: 0,
    checkForMapLoad: function checkForMapLoad(controller) {
      var _this = this;

      var mapData = this.get('mapData');
      var loadTimeout = void 0;
      mapData.set('mapLoaded', false);
      controller.set('hasMapboxLoaded', false);

      var checkForMapLoad = function checkForMapLoad() {
        if (mapData.get('mapLoaded')) {
          if (loadTimeout) clearTimeout(loadTimeout);

          return controller.set('hasMapboxLoaded', true);
        }
        _this.addFakeLayer();
        loadTimeout = setTimeout(checkForMapLoad, 200);
        return null;
      };

      Ember.run.next(checkForMapLoad);
    },
    addFakeLayer: function addFakeLayer() {
      // sometimes mapbox doesn't fully load on its own. Adding a layer is one way to coerce it to finish loading.
      this.set('fakeLayerCount', this.get('fakeLayerCount') + 1);
      if (!this.get('mapData.mapInstance')) return;
      try {
        if (!this.get('mapData').doesLayerExist('force-map-load-event-to-trigger-' + this.get('fakeLayerCount'))) {
          this.get('mapData').mapInstance.addLayer({ id: 'force-map-load-event-to-trigger-' + this.get('fakeLayerCount'), type: 'symbol', source: { type: 'geojson', data: { type: 'FeatureCollection', features: [] } } }); // this is a hack to make sure mapbox load event fires
        }
      } catch (err) {
        // try again next time
      }
    }
  });
});