define('webui/mixins/material-trends-chart-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getMaterialTrendsChartData: function getMaterialTrendsChartData(selectedFlights, materials, displayMaterialAsCode) {
      var _this = this;

      var labels = selectedFlights.map(function (flight) {
        return _this.dateString(flight.get('executeAt'), false);
      });
      var series = [];

      materials.forEach(function (material, i) {
        var materialTonnagesPerFlight = [];
        selectedFlights.forEach(function (flight) {
          var regionsWithMaterial = flight.get('regions').filter(function (region) {
            return region.get('material.id') === material.get('id');
          });
          var tonnage = regionsWithMaterial.reduce(function (accumulator, region) {
            return accumulator + region.get('tonnage');
          }, 0);
          materialTonnagesPerFlight.push(tonnage.toFixed(2));
        });
        series.push({ name: displayMaterialAsCode && material.get('key') ? material.get('key') : material.get('name'), materialId: material.get('id'), data: materialTonnagesPerFlight, className: 'color-series-' + i });
      });
      return { labels: labels, series: series };
    }
  });
});