define('webui/helpers/in-export-mode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    mapData: Ember.inject.service('map-data'),

    onToggleLayer: Ember.observer('mapData.inExportMode', function onToggleLayer() {
      this.recompute();
    }),

    compute: function compute() {
      return this.get('mapData').inExportMode;
    }
  });
});