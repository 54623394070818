define('webui/pods/components/file-manager/component', ['exports', 'npm:react', 'npm:react-dom', 'webui/react/FileManager'], function (exports, _npmReact, _npmReactDom, _FileManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    siteDataAccess: Ember.inject.service('data-access/site'),
    uploader: Ember.inject.service('xhr-uploader'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    statusService: Ember.inject.service('status-panel'),

    makeReactElement: function makeReactElement() {
      this.set('reactComponent', _npmReact.default.createElement(_FileManager.default, {
        site: this.get('site'),
        availableSites: this.get('availableSites'),
        fileUploadService: this.get('uploader'),
        dataService: this.get('store'),
        siteDataAccess: this.get('siteDataAccess'),
        statusService: this.get('statusService'),
        emberComponent: this,
        emberComponentParent: this.get('parent'),
        startingMode: this.get('startingMode')
      }));
    },
    renderReactElement: function renderReactElement() {
      _npmReactDom.default.render(this.get('reactComponent'), this.get('element'));
    },
    generateReactElement: function generateReactElement() {
      this.makeReactElement();
      this.renderReactElement();
    },
    didRender: function didRender() {
      this.generateReactElement();
      this.$(this.el).addClass('calc-height'); /* This is necessary to spoof a full-page look even if the content isn't big enough to fill the viewport. */
    },
    willDestroyElement: function willDestroyElement() {
      this._super();
      _npmReactDom.default.unmountComponentAtNode(this.get('element'));
    },


    actions: {
      doTransition: function doTransition(route, args) {
        this.sendAction('doTransition', route, args);
      },
      doSiteChange: function doSiteChange(id, mode) {
        this.sendAction('doTransition', 'site-file-manager', [id, mode]);
        this.generateReactElement();
      },
      doExitFileManager: function doExitFileManager(siteId) {
        this.sendAction('doTransition', 'site', [siteId]);
      }
    }
  });
});