define("webui/pods/components/field-nav/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 12
                },
                "end": {
                  "line": 9,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/field-nav/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "slopemap-legend-display-content");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "map.0", ["loc", [null, [8, 59], [8, 72]]]]],
            locals: ["map"],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 12
                },
                "end": {
                  "line": 16,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/field-nav/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "slopemap-legend-display-content");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element2, 'style');
              return morphs;
            },
            statements: [["attribute", "style", ["concat", ["background-color: ", ["get", "map.1", ["loc", [null, [15, 86], [15, 93]]]], ";"]]]],
            locals: ["map"],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/field-nav/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "field-legend-panel");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-xs-12 slopemap-legend-display-container");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col-xs-12 slopemap-legend-display-container");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [3, 1]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["field-legend-container ", ["subexpr", "if", [["get", "flight.subtitle", ["loc", [null, [3, 44], [3, 59]]]], "with-subtitle", "without-subtitle"], [], ["loc", [null, [3, 39], [3, 96]]]]]]], ["block", "each", [["get", "settingsManager.slopeMapColorMapDisplaySafe", ["loc", [null, [7, 20], [7, 63]]]]], [], 0, null, ["loc", [null, [7, 12], [9, 21]]]], ["block", "each", [["get", "settingsManager.slopeMapColorMapDisplaySafe", ["loc", [null, [14, 20], [14, 63]]]]], [], 1, null, ["loc", [null, [14, 12], [16, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/field-nav/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "tiledMap", ["loc", [null, [2, 13], [2, 21]]]], ["get", "tiledMap.checked", ["loc", [null, [2, 22], [2, 38]]]]], [], ["loc", [null, [2, 8], [2, 39]]]]], [], 0, null, ["loc", [null, [2, 2], [21, 9]]]]],
        locals: ["tiledMap"],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 2
              },
              "end": {
                "line": 42,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/field-nav/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "field-legend-panel");
            dom.setAttribute(el2, "style", "display:flex;justify-content:space-evenly;flex-wrap:wrap;");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            var el5 = dom.createTextNode("Max Elevation Change:");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "field-panel-input");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ft");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            var el5 = dom.createTextNode("Elevation Threshold:");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "field-panel-input");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ft");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            var el5 = dom.createTextNode("Area Threshold:");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "field-panel-input");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ft");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("sup");
            var el6 = dom.createTextNode("2");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 3]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 3]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["field-legend-container ", ["subexpr", "if", [["get", "flight.subtitle", ["loc", [null, [26, 44], [26, 59]]]], "with-subtitle", "without-subtitle"], [], ["loc", [null, [26, 39], [26, 96]]]]]]], ["content", "tiledMap.changeDetection.maxElevationChangeFt", ["loc", [null, [30, 42], [30, 91]]]], ["content", "tiledMap.changeDetection.elevationThresholdFt", ["loc", [null, [34, 42], [34, 91]]]], ["content", "tiledMap.changeDetection.areaThresholdSqFt", ["loc", [null, [38, 42], [38, 88]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/field-nav/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "tiledMap", ["loc", [null, [25, 13], [25, 21]]]], ["get", "tiledMap.checked", ["loc", [null, [25, 22], [25, 38]]]]], [], ["loc", [null, [25, 8], [25, 39]]]]], [], 0, null, ["loc", [null, [25, 2], [42, 9]]]]],
        locals: ["tiledMap"],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 0
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/field-nav/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "field-panel-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "field-panel-layers", [], ["tiledMaps", ["subexpr", "@mut", [["get", "tiledMaps", ["loc", [null, [47, 35], [47, 44]]]]], [], []], "flight", ["subexpr", "@mut", [["get", "flight", ["loc", [null, [47, 52], [47, 58]]]]], [], []], "site", ["subexpr", "@mut", [["get", "site", ["loc", [null, [47, 64], [47, 68]]]]], [], []], "qualityReport", ["subexpr", "@mut", [["get", "qualityReport", ["loc", [null, [47, 83], [47, 96]]]]], [], []]], ["loc", [null, [47, 4], [47, 98]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 0
            },
            "end": {
              "line": 55,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/field-nav/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "field-panel-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "field-panel-elevation", [], ["elevation-data", ["subexpr", "@mut", [["get", "tiledMaps.elevation-data", ["loc", [null, [53, 43], [53, 67]]]]], [], []]], ["loc", [null, [53, 4], [53, 69]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/field-nav/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "field-panel-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "field-panel-measurements", [], ["flights", ["subexpr", "@mut", [["get", "flights", ["loc", [null, [59, 39], [59, 46]]]]], [], []], "siteGrades", ["subexpr", "@mut", [["get", "siteGrades", ["loc", [null, [59, 58], [59, 68]]]]], [], []], "flight", ["subexpr", "@mut", [["get", "flight", ["loc", [null, [59, 76], [59, 82]]]]], [], []], "site", ["subexpr", "@mut", [["get", "site", ["loc", [null, [59, 88], [59, 92]]]]], [], []]], ["loc", [null, [59, 4], [59, 94]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/field-nav/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1, "id", "field-nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "field-nav-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "active");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "hillicon hillicon-layers nav-icon");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "nav-label");
        var el7 = dom.createTextNode("Layers");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "active");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "hillicon hillicon-elevation nav-icon");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "nav-label");
        var el7 = dom.createTextNode("Grade Check");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "active pull-right");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "glyphicon glyphicon-tasks nav-icon");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "nav-label");
        var el7 = dom.createTextNode("Measurements");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [10, 1, 1]);
        var element6 = dom.childAt(element5, [1, 0]);
        var element7 = dom.childAt(element5, [3, 0]);
        var element8 = dom.childAt(element5, [5, 0]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[5] = dom.createAttrMorph(element6, 'class');
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createAttrMorph(element7, 'class');
        morphs[8] = dom.createElementMorph(element7);
        morphs[9] = dom.createAttrMorph(element8, 'class');
        morphs[10] = dom.createElementMorph(element8);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "each", [["get", "tiledMaps.slope-data", ["loc", [null, [1, 8], [1, 28]]]]], [], 0, null, ["loc", [null, [1, 0], [22, 9]]]], ["block", "each", [["get", "tiledMaps.change-detection", ["loc", [null, [24, 8], [24, 34]]]]], [], 1, null, ["loc", [null, [24, 0], [43, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "activeTool", ["loc", [null, [45, 10], [45, 20]]]], "layers"], [], ["loc", [null, [45, 6], [45, 30]]]]], [], 2, null, ["loc", [null, [45, 0], [49, 7]]]], ["block", "if", [["subexpr", "eq", [["get", "activeTool", ["loc", [null, [51, 10], [51, 20]]]], "elevation"], [], ["loc", [null, [51, 6], [51, 33]]]]], [], 3, null, ["loc", [null, [51, 0], [55, 7]]]], ["block", "if", [["subexpr", "eq", [["get", "activeTool", ["loc", [null, [57, 10], [57, 20]]]], "measurements"], [], ["loc", [null, [57, 6], [57, 36]]]]], [], 4, null, ["loc", [null, [57, 0], [61, 7]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "activeTool", ["loc", [null, [66, 53], [66, 63]]]], "layers"], [], ["loc", [null, [66, 49], [66, 73]]]], "active", ""], [], ["loc", [null, [66, 44], [66, 87]]]]]]], ["element", "action", ["selectTool", "layers"], [], ["loc", [null, [66, 89], [66, 121]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "activeTool", ["loc", [null, [67, 53], [67, 63]]]], "elevation"], [], ["loc", [null, [67, 49], [67, 76]]]], "active", ""], [], ["loc", [null, [67, 44], [67, 90]]]]]]], ["element", "action", ["selectTool", "elevation"], [], ["loc", [null, [67, 92], [67, 127]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "activeTool", ["loc", [null, [68, 64], [68, 74]]]], "measurements"], [], ["loc", [null, [68, 60], [68, 90]]]], "active", ""], [], ["loc", [null, [68, 55], [68, 104]]]]]]], ["element", "action", ["selectTool", "measurements"], [], ["loc", [null, [68, 106], [68, 144]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});