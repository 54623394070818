define('webui/pods/components/true-tonnage-report/approver-table/component', ['exports', 'webui/mixins/true-tonnage-report'], function (exports, _trueTonnageReport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend(_trueTonnageReport.default, {
    apiAction: 'submitChangeRequests',
    changeRequestCount: 0,

    getMaterialData: function getMaterialData() {
      var _this = this;

      var areThereRevisions = this.areThereRevisions();
      return this.getAllMaterialIDs().map(function (materialId) {
        var trueTonnageReportMaterial = _this.getTrueTonnageReportMaterial(materialId);
        var regionData = _this.getRegionDataForMaterial(materialId, areThereRevisions);
        var shouldDisplayCallToAction = areThereRevisions && _this.getShouldDisplayCallToActionForMaterial(regionData);

        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial.get('id'),
          materialId: trueTonnageReportMaterial.get('material'),
          name: trueTonnageReportMaterial.get('name'),
          key: trueTonnageReportMaterial.get('key'),
          density: trueTonnageReportMaterial.get('density'),
          volume: _this.formatNumberForDisplay(_this.getVolumeTotalForMaterial(materialId)),
          tonnage: _this.formatNumberForDisplay(_this.getTonnageTotalForMaterial(materialId)),
          tonnageNumber: _this.getTonnageTotalForMaterial(materialId),
          plannedTonnage: trueTonnageReportMaterial.get('plannedTonnage'),
          tonnageDifference: _this.getTonnageDifference(trueTonnageReportMaterial),
          tonnageVarianceDisplay: _this.getTonnageVarianceDisplay(trueTonnageReportMaterial),
          showVarianceWarning: _this.showVarianceWarning(trueTonnageReportMaterial),
          changeRequested: trueTonnageReportMaterial.get('changeRequested'),
          changeRequestTonnage: trueTonnageReportMaterial.get('changeRequestTonnage'),
          changeRequestReason: trueTonnageReportMaterial.get('changeRequestReason'),
          hideMaterial: trueTonnageReportMaterial.get('hideFromTrueTonnageReport'),
          regionData: regionData,
          showRegions: shouldDisplayCallToAction
        };
      }).filter(function (material) {
        return !material.hideMaterial;
      });
    },
    getRegionDataForMaterial: function getRegionDataForMaterial(materialId, areThereRevisions) {
      var _this2 = this;

      var materialForName = this.get('store').peekRecord('material', materialId);

      return this.getUniqueRegionIDsForMaterial(materialId).map(function (trueTonnageReportRegionId) {
        var revisions = _this2.getRevisionDataForRegion(trueTonnageReportRegionId).map(function (trueTonnageReportRegion) {
          return {
            trueTonnageReportRegionId: trueTonnageReportRegion.get('id'),
            materialId: trueTonnageReportRegion.get('material'),
            regionId: trueTonnageReportRegion.get('region'),
            name: trueTonnageReportRegion.get('name'),
            nameWithMaterialCode: materialForName.get('key') ? trueTonnageReportRegion.get('name').replace(materialForName.get('name'), materialForName.get('key')) : trueTonnageReportRegion.get('name'),
            netVolume: trueTonnageReportRegion.get('netVolume'),
            density: trueTonnageReportRegion.get('displayDensity'),
            displayTonnage: trueTonnageReportRegion.get('displayTonnage'),
            plannedTonnage: trueTonnageReportRegion.get('plannedTonnage'),
            tonnageDifference: trueTonnageReportRegion.get('tonnageDifference'),
            tonnageVarianceDisplay: trueTonnageReportRegion.get('tonnageVarianceDisplay'),
            changeRequestFulfilledComment: trueTonnageReportRegion.get('changeRequestFulfilledComment'),
            revisionDate: trueTonnageReportRegion.get('revisionDate'),
            createdAt: trueTonnageReportRegion.get('createdAt'),
            boundaries: trueTonnageReportRegion.get('boundaries'),
            mapboxPointFeature: trueTonnageReportRegion.get('mapboxPointFeature'),
            wasDeleted: trueTonnageReportRegion.get('wasDeleted'),
            hideRegion: trueTonnageReportRegion.get('hideFromTrueTonnageReport')
          };
        }).filter(function (region) {
          return !region.hideRegion;
        });

        if (areThereRevisions && revisions[0] && _this2.getLatestRevisionDate() === new Date(revisions[0].createdAt).getTime()) {
          revisions[0].shouldDisplayCallToAction = true;
        }

        return _extends({}, revisions[0], { revisions: revisions });
      }).filter(function (_ref) {
        var revisions = _ref.revisions;
        return revisions.length > 0;
      });
    },
    areThereRevisions: function areThereRevisions() {
      return new Date(this.getTrueTonnageReportRegions().sortBy('createdAt').get('firstObject.createdAt')).getTime() !== new Date(this.getTrueTonnageReportRegions().sortBy('createdAt').get('lastObject.createdAt')).getTime();
    },
    getLatestRevisionDate: function getLatestRevisionDate() {
      return new Date(this.getTrueTonnageReportRegions().sortBy('createdAt').get('lastObject.createdAt')).getTime();
    },
    getRegionsForMaterialTotals: function getRegionsForMaterialTotals(materialId) {
      var _this3 = this;

      return this.getUniqueRegionIDsForMaterial(materialId).map(function (regionId) {
        return _this3.getRevisionDataForRegion(regionId)[0];
      }).filter(function (region) {
        return region.get('region');
      }).filter(function (region) {
        return !region.get('excludeRegion');
      }).filter(function (region) {
        return !region.get('hideFromTrueTonnageReport');
      });
    },
    getMaterialChanges: function getMaterialChanges() {
      return this.getMaterialsFromFormWithChangeRequest().map(function (trueTonnageReportMaterial) {
        return {
          trueTonnageReportMaterialId: trueTonnageReportMaterial.trueTonnageReportMaterialId,
          changeRequested: trueTonnageReportMaterial.changeRequested,
          changeRequestTonnage: trueTonnageReportMaterial.changeRequestTonnage,
          changeRequestReason: trueTonnageReportMaterial.changeRequestReason
        };
      });
    },
    getMaterialsFromFormWithChangeRequest: function getMaterialsFromFormWithChangeRequest() {
      return this.getMaterialsFromForm().filter(function (trueTonnageReportMaterial) {
        return trueTonnageReportMaterial.changeRequested;
      });
    },
    getRegionChanges: function getRegionChanges() {
      return this.getRegionsFromFormWithChangeRequest().map(function (trueTonnageReportRegion) {
        return {
          trueTonnageReportRegionId: trueTonnageReportRegion.trueTonnageReportRegionId,
          changeRequested: trueTonnageReportRegion.changeRequested,
          changeRequestTonnage: trueTonnageReportRegion.changeRequestTonnage,
          changeRequestReason: trueTonnageReportRegion.changeRequestReason
        };
      });
    },
    getRegionsFromFormWithChangeRequest: function getRegionsFromFormWithChangeRequest() {
      return this.getRegionsFromForm().filter(function (trueTonnageReportRegion) {
        return trueTonnageReportRegion.changeRequested;
      });
    },
    getReportError: function getReportError() {
      var allChanges = [].concat(_toConsumableArray(this.getMaterialChanges()), _toConsumableArray(this.getRegionChanges()));
      var requiredFieldsAreMissing = allChanges.filter(function (change) {
        return !change.changeRequestReason || !change.changeRequestTonnage;
      }).length > 0;

      if (requiredFieldsAreMissing) {
        return {
          title: 'Missing Required Fields',
          detail: 'Please enter an "Expected Tonnage" and "Reason for Change Request" for all change requests.'
        };
      }

      return false;
    },


    actions: {
      toggleChangeRequest: function toggleChangeRequest(entity) {
        if (!entity.excludeRegion && !entity.excludeMaterial) {
          Ember.set(entity, 'changeRequested', !Ember.get(entity, 'changeRequested'));
          this.updateChangeRequestCount();
        }
      }
    }
  });
});