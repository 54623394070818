define("webui/pods/components/modal-wrapper/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 6,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" hide header and close icon ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 10
              },
              "end": {
                "line": 10,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "title", ["loc", [null, [9, 12], [9, 21]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "modal-header");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "close glyphicon glyphicon-remove");
          dom.setAttribute(el2, "data-dismiss", "modal");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "title", ["loc", [null, [8, 16], [8, 21]]]]], [], 0, null, ["loc", [null, [8, 10], [10, 17]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 10
              },
              "end": {
                "line": 27,
                "column": 12
              }
            },
            "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["get", "componentName", ["loc", [null, [17, 24], [17, 37]]]]], ["mainMessage", ["subexpr", "@mut", [["get", "mainMessage", ["loc", [null, [18, 26], [18, 37]]]]], [], []], "subMessage", ["subexpr", "@mut", [["get", "subMessage", ["loc", [null, [19, 25], [19, 35]]]]], [], []], "confirmButtonAction", ["subexpr", "@mut", [["get", "confirmButtonAction", ["loc", [null, [20, 34], [20, 53]]]]], [], []], "cancelButtonAction", ["subexpr", "@mut", [["get", "cancelButtonAction", ["loc", [null, [21, 33], [21, 51]]]]], [], []], "confirmButtonText", ["subexpr", "@mut", [["get", "confirmButtonText", ["loc", [null, [22, 32], [22, 49]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [23, 20], [23, 25]]]]], [], []], "error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [24, 20], [24, 25]]]]], [], []], "errorDetails", ["subexpr", "@mut", [["get", "errorDetails", ["loc", [null, [25, 27], [25, 39]]]]], [], []], "params", ["subexpr", "@mut", [["get", "params", ["loc", [null, [26, 21], [26, 27]]]]], [], []]], ["loc", [null, [17, 12], [26, 29]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 12
              },
              "end": {
                "line": 39,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["get", "componentName", ["loc", [null, [28, 26], [28, 39]]]]], ["activeSiteUsers", ["subexpr", "@mut", [["get", "activeSiteUsers", ["loc", [null, [29, 32], [29, 47]]]]], [], []], "authenticatedUser", ["subexpr", "@mut", [["get", "authenticatedUser", ["loc", [null, [30, 34], [30, 51]]]]], [], []], "mainMessage", ["subexpr", "@mut", [["get", "mainMessage", ["loc", [null, [31, 28], [31, 39]]]]], [], []], "subMessage", ["subexpr", "@mut", [["get", "subMessage", ["loc", [null, [32, 27], [32, 37]]]]], [], []], "confirmButtonAction", ["subexpr", "@mut", [["get", "confirmButtonAction", ["loc", [null, [33, 36], [33, 55]]]]], [], []], "cancelButtonAction", ["subexpr", "@mut", [["get", "cancelButtonAction", ["loc", [null, [34, 35], [34, 53]]]]], [], []], "confirmButtonText", ["subexpr", "@mut", [["get", "confirmButtonText", ["loc", [null, [35, 34], [35, 51]]]]], [], []], "error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [36, 22], [36, 27]]]]], [], []], "errorDetails", ["subexpr", "@mut", [["get", "errorDetails", ["loc", [null, [37, 29], [37, 41]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [38, 22], [38, 27]]]]], [], []]], ["loc", [null, [28, 14], [38, 29]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 40,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "params", ["loc", [null, [16, 16], [16, 22]]]]], [], 0, 1, ["loc", [null, [16, 10], [39, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/modal-wrapper/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal modal-wrapper fade");
        dom.setAttribute(el1, "data-keyboard", "true");
        dom.setAttribute(el1, "data-show", "false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-dialog");
        dom.setAttribute(el2, "role", "dialog");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-content");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "modal-body info-contents");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'data-backdrop');
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "id", ["get", "modalId", ["loc", [null, [1, 43], [1, 50]]]]], ["attribute", "data-backdrop", ["concat", [["subexpr", "if", [["get", "useBackdrop", ["loc", [null, [1, 112], [1, 123]]]], "true", "false"], [], ["loc", [null, [1, 107], [1, 140]]]]]]], ["block", "if", [["get", "hideHeader", ["loc", [null, [4, 12], [4, 22]]]]], [], 0, 1, ["loc", [null, [4, 6], [13, 13]]]], ["block", "if", [["get", "componentName", ["loc", [null, [15, 14], [15, 27]]]]], [], 2, null, ["loc", [null, [15, 8], [40, 15]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});