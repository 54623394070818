define('webui/router', ['exports', 'webui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  // eslint-disable-next-line array-callback-return
  Router.map(function () {
    this.route('login');
    this.route('logout');
    this.route('account', function () {
      this.route('client', { path: 'client/:id' }, function () {});
    });
    this.route('flight', { path: 'flight/:id' });

    this.route('site', { path: 'site/:id' }, function () {
      this.route('not-found');
    });

    this.route('admin', function () {
      this.route('site', function () {
        this.route('create');
        this.route('edit', { path: 'edit/:site_id' });
      });
      this.route('user', function () {
        this.route('create');
        this.route('edit', { path: 'edit/:id' });
      });
      this.route('client', function () {
        this.route('create');
        this.route('edit', { path: 'edit/:id' });
      });
      this.route('flight', function () {
        this.route('edit', { path: 'edit/:id' });
      });
      this.route('job', function () {
        this.route('status');
        this.route('processing');
        this.route('history');
      });
    });
    this.route('site-not-found');
    this.route('reset', function () {
      this.route('request');
      this.route('sent');
      this.route('expired');
      this.route('password', { path: 'password/:token' });
    });
    this.route('site-flights-new', { path: 'site/:site_id/flights/new' });
    /* Yes, this is borderline obnoxious to have two independent paths with the same route defined this way, but Ember doesn't give another option. */
    this.route('site-file-manager', { path: 'site/:site_id/filemanager' });
    this.route('site-file-manager', { path: 'site/:site_id/filemanager/:starting_fileset' });
    this.route('site-material-manager', { path: 'site/:site_id/materials' });
    this.route('site-import-export-regions-manager', { path: 'site/:site_id/regions' });
    this.route('field-login', { path: 'field/login/:id/:token/:flight' });
    this.route('field');
    this.route('field', { path: 'field/:flight' });
    this.route('field-login-error', { path: 'field-login-error' });

    this.route('tools', function () {
      this.route('coordinate-converter');
    });

    this.route('404', { path: '/*path' });
  });

  exports.default = Router;
});