define('webui/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    isAdmin: _emberData.default.attr('boolean'),
    usesCredits: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    displayStatus: function displayStatus() {
      return this.get('status') === 'inactive' ? 'disabled' : this.get('status');
    }.property('status'),
    sites: _emberData.default.hasMany('site', { async: true }),
    users: _emberData.default.hasMany('user', { async: true }),
    credits: _emberData.default.hasMany('credit', { async: true }),

    creditCount: Ember.computed('credits', 'usesCredits', function executeAt() {
      if (!this.get('usesCredits')) {
        return null;
      }

      return this.get('credits').reduce(function (total, credit) {
        return total + credit.get('amount');
      }, 0);
    })
  });
});