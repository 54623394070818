define('webui/pods/components/true-tonnage-report/tier2-analytics-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rows: [{
      name: 'Difference Report',
      value: 1
    }, {
      name: 'Derivative Report',
      value: 2
    }],
    feedback: Ember.inject.service('user-feedback'),

    init: function init() {
      this._super();
    },
    didRender: function didRender() {
      this.setupSelectPickers();
    },
    setupSelectPickers: function setupSelectPickers() {
      $('#export-row-selector').selectpicker();
    },
    getDropdownOptions: function getDropdownOptions(selectElementId) {
      return Array.from(document.querySelector(selectElementId).querySelectorAll('option'));
    },
    getSelectedItems: function getSelectedItems(selectElementId) {
      return this.getDropdownOptions(selectElementId).filter(function (option) {
        return option.selected;
      }).map(function (option) {
        return option.value;
      });
    },


    actions: {
      CSVExport: function CSVExport() {
        var settings = {};
        try {
          settings = this.getSelectedItems('#export-row-selector');
        } catch (e) {
          Ember.Logger.error(e);
        }
        if (settings.length > 0) {
          this.sendAction('CSVExport', settings);
        } else {
          this.get('feedback').reportError({
            title: 'Error',
            detail: 'Please select at least one report to be exported'
          });
        }
      },
      pdfExport: function pdfExport() {
        var settings = {};
        try {
          settings = this.getSelectedItems('#export-row-selector');
        } catch (e) {
          Ember.Logger.error(e);
        }
        if (settings.length > 0) {
          this.sendAction('pdfExport', settings);
        } else {
          this.get('feedback').reportError({
            title: 'Error',
            detail: 'Please select at least one report to be exported'
          });
        }
      },
      toggleDownloadForm: function toggleDownloadForm() {
        this.sendAction('toggleDownloadForm');
      }
    }
  });
});