define('webui/pods/components/status-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['dropdown'],
    statusService: Ember.inject.service('status-panel'),

    statusMenuOpen: false,

    actions: {
      toggleStatusMenu: function toggleStatusMenu() {
        this.toggleProperty('statusMenuOpen');
      },
      toggleStatusHeader: function toggleStatusHeader(component) {
        Ember.set(component, 'display', !Ember.get(component, 'display'));
      }
    }
  });
});