define('webui/models/tiled-map', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var mapTypes = {
    orthomosaic: 'UAV Imagery',
    contour: 'Contour Lines',
    'digital-surface-model': 'Elevation Map',
    'slope-data': 'Slope Data',
    'haul-road': 'Haul Road'
  };

  exports.default = _emberData.default.Model.extend({
    fileUrl: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    minZoomLevel: _emberData.default.attr('number'),
    maxZoomLevel: _emberData.default.attr('number'),
    urlPrefix: _emberData.default.attr('string'),
    layerName: _emberData.default.attr(),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    fieldLinks: _emberData.default.hasMany('field-link', { async: true }),

    displayUrl: function displayUrl() {
      var prefix = this.get('urlPrefix');
      var fileUrl = this.get('fileUrl');

      if (prefix && prefix.slice(-1) !== '/' && fileUrl.charAt(0) !== '/') {
        prefix += '/';
      }
      if (fileUrl && fileUrl.slice(-1) !== '/') {
        fileUrl += '/';
      }
      return prefix + fileUrl;
    }.property('fileUrl'),

    displayType: function displayType() {
      return mapTypes[this.get('type')];
    }.property('type')
  });
});