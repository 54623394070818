define('webui/services/elevation-layer-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    enabled: false,
    selectedElevation: null,
    usedBy: new Set(),

    enable: function enable(componentName) {
      this.set('enabled', true);
      this.get('usedBy').add(componentName);
    },
    disable: function disable(componentName) {
      this.get('usedBy').delete(componentName);
      if (this.get('usedBy').size === 0) {
        this.set('enabled', false);
      }
    },
    setSelectedElevation: function setSelectedElevation(elevation) {
      this.set('selectedElevation', elevation);
    }
  });
});