define('webui/pods/components/true-tonnage-report/true-tonnage-report-revision-table/component', ['exports', 'webui/mixins/map-layer-formatting'], function (exports, _mapLayerFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mapLayerFormatting.default, {
    mapData: Ember.inject.service('mapData'),

    drawRevision: function drawRevision(revision) {
      var revisionId = Ember.get(revision, 'trueTonnageReportRegionId');

      this.get('mapData').drawFeature({
        id: revisionId, type: 'Polygon', color: 'white', points: Ember.get(revision, 'boundaries')
      });
      this.get('mapData.mapInstance').addSource(revisionId, this.getRegionLabelSourceProperties([Ember.get(revision, 'mapboxPointFeature')]));
      this.get('mapData.mapInstance').addLayer(this.getRegionLabelLayerProperties({ id: revisionId, source: revisionId }));
    },
    unDrawRevision: function unDrawRevision(revision) {
      var revisionId = Ember.get(revision, 'trueTonnageReportRegionId');

      this.get('mapData').deletePolygonFromPanel(revisionId);
      this.get('mapData.mapInstance').removeSource(revisionId);
      this.get('mapData.mapInstance').removeLayer(revisionId);
    },


    actions: {
      toggleRevisionDrawing: function toggleRevisionDrawing(revision) {
        Ember.set(revision, 'isRegionCurrentlyDrawn', !Ember.get(revision, 'isRegionCurrentlyDrawn'));
        if (Ember.get(revision, 'isRegionCurrentlyDrawn')) {
          this.drawRevision(revision);
        } else {
          this.unDrawRevision(revision);
        }
      }
    }
  });
});