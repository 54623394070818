define('webui/models/tracking-region', ['exports', 'ember-data', 'webui/mixins/string-formatting', 'npm:lodash', 'npm:polylabel'], function (exports, _emberData, _stringFormatting, _npmLodash, _npmPolylabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    name: _emberData.default.attr('string'),
    display: true,
    boundaries: _emberData.default.attr(),

    mapboxPointFeature: Ember.computed('name', 'boundaries', function mapboxPointFeature() {
      var coords = _npmLodash.default.map(this.get('boundaries'), function (point) {
        return [point.lng, point.lat];
      });
      var center = (0, _npmPolylabel.default)([coords]);

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: center
        },
        properties: {
          name: this.get('name')
        }
      };
    }),

    area: _emberData.default.attr('number'),
    areaDisplay: Ember.computed('area', function areaDisplay() {
      return this.numberWithCommas(this.get('area'));
    }),
    site: _emberData.default.belongsTo('site', { async: true }),
    earthworkAnalyses: _emberData.default.hasMany('earthwork-analysis', { async: true }),
    needsEarthworkRecalculated: _emberData.default.attr('boolean'),
    regionBlueprint: _emberData.default.belongsTo('region-blueprint', { async: false }),
    chartOptions: {
      showArea: false,
      lineSmooth: false,
      onlyInteger: true,
      axisX: {
        showGrid: false
      }
    },
    createdAt: _emberData.default.attr('string'),
    isLocked: Ember.computed('regionBlueprint', function isLocked() {
      return this.get('regionBlueprint.areRegionsLocked');
    }),
    isLockedToolTipText: Ember.computed('regionBlueprint', function isLockedToolTipText() {
      return '<p>This region is linked to a locked DXF. It can only be removed by deleting ' + this.get('regionBlueprint.name') + '.dxf in the file manager.</p>';
    }),
    createdDateTime: function createdDateTime() {
      return this.dateTime(this.get('createdAt'), true);
    }.property('createdAt')

  });
});