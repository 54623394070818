define('webui/pods/components/wizard-overlay/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /*
    NOTE: This implementation is incomplete - was originally developed as a prototype/proof of concept that may have future value.
   */

  var WizardAssistant = Ember.Object.extend({
    message: null,
    data: null, setMessage: function setMessage(type, copy) {
      this.set('message', {
        type: type,
        copy: copy
      });
    }
  });

  exports.default = Ember.Component.extend({

    componentName: null,
    htmlId: null, /* not required, but when the template is embedded an ID may be assigned */

    assistant: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.assistant = WizardAssistant.create();

      if (!this.get('htmlId')) {
        this.set('htmlId', this.elementId + '-wizard');
      }
    },
    didInsertElement: function didInsertElement() {
      /*
      var modalWrapperDiv = $('#modal-overlays');
      if(!modalWrapperDiv) {
        modalWrapperDiv = $('<div id="modal-overlays"></div>').appendTo($('body'));
      }
      modalWrapperDiv.append(this.$()); */
    },


    actions: {
      closeWizard: function closeWizard() {
        this.$('.wizard-overlay').hide();
      },
      showWizard: function showWizard() {
        this.$('.wizard-overlay').show();
      }
    }
  });
});