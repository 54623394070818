define('webui/pods/components/field-link-send-modal/component', ['exports', 'ember-cli-segment/mixin', 'webui/config/environment'], function (exports, _mixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    feedback: Ember.inject.service('user-feedback'),
    mapData: Ember.inject.service('map-data'),
    phoneNumber: '',

    clearForm: function clearForm() {
      this.set('fieldUserPhoneNumber', '');
      this.set('phoneNumber', '');
      this.set('userId', '');
    },
    changeUser: function changeUser(userId) {
      this.set('userId', userId);
      var user = this.get('store').peekRecord('user', userId);
      if (user) {
        var phoneNumber = user.get('fieldUser.phoneNumber');
        if (phoneNumber) {
          return this.set('fieldUserPhoneNumber', phoneNumber);
        }
      }

      return this.set('fieldUserPhoneNumber', null);
    },


    actions: {
      clearForm: function clearForm() {
        this.clearForm();
      },
      changeUser: function changeUser(userId) {
        this.changeUser(userId);
      },
      sendFieldLink: function sendFieldLink() {
        var formatNumberForSystem = function formatNumberForSystem(number) {
          return number.replace(/\D/g, '');
        };
        var feedback = this.get('feedback');
        var store = this.get('store');
        var siteId = this.get('mapData.currentSiteId');
        var flightId = this.get('mapData.currentFlightId');
        var userId = this.get('userId');
        var phoneNumber = this.get('phoneNumber');

        var user = this.get('store').peekRecord('user', userId);
        if (user && user.get('fieldUser.phoneNumber')) {
          phoneNumber = user.get('fieldUser.phoneNumber');
        }
        phoneNumber = formatNumberForSystem(phoneNumber);

        var data = {
          siteId: siteId, flightId: flightId, userId: userId, phoneNumber: phoneNumber
        };
        $.ajax({
          method: 'POST', url: _environment.default.APP.apiUrl + 'fieldUsers', crossDomain: true, data: data, xhrFields: { withCredentials: true }
        }).done(function (res) {
          store.push({ data: { type: 'field-user', id: res.fieldUser.id, attributes: res.fieldUser } });
          if (userId) {
            store.findRecord('user', userId, { reload: true });
          }
          feedback.reportSuccess({ detail: 'Field link invitation was successfully sent.' });
        }).fail(function (error) {
          if (error.status === 400) {
            var detail = error.responseJSON.detail;

            feedback.reportError({ detail: detail });
          } else {
            feedback.reportError({ detail: 'Field link invitation could not be sent. Please try again.' });
          }
        });
        this.trackEvent('Send Field Link', { flight: flightId });

        return this.clearForm();
      }
    }
  });
});