define('webui/mixins/string-formatting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var prependZero = function prependZero(num) {
    return num < 10 ? '0' + String(num) : num;
  };

  exports.default = Ember.Mixin.create({
    // Helper function that takes in a string timestamp and converts it into a standardized MM/DD/YY format
    dateString: function dateString(stringTime) {
      var prepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var excludeYear = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var rawTime = new Date(stringTime);
      var month = rawTime.getMonth() + 1;
      var year = rawTime.getFullYear().toString().substr(2, 2);
      var date = rawTime.getDate();

      if (prepend) {
        month = prependZero(month);
        date = prependZero(date);
        year = prependZero(year);
      }

      return excludeYear ? month + '/' + date : month + '/' + date + '/' + year + ' ';
    },
    numberWithCommas: function numberWithCommas(val) {
      if (val !== null && typeof val !== 'undefined') {
        var pieces = val.toString().split('.');
        pieces[0] = pieces[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return pieces.reduce(function (number, piece) {
          return number.length === 0 ? number : number + '.' + piece;
        });
      }
      return 0;
    },


    // Helper function that takes in either a raw Javscript Date object or a string timestamp and converts it to a human-readable datetime string
    dateTimeString: function dateTimeString(rawTime, dateDelimeter, timeDelimeter, separator) {
      var prependDay = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

      if (typeof rawTime === 'string') {
        rawTime = new Date(rawTime);
      }
      // Can't just use a falseness check because '' is an acceptable delimeter
      if (dateDelimeter === undefined) {
        dateDelimeter = '/';
      }
      if (timeDelimeter === undefined) {
        timeDelimeter = ':';
      }
      if (separator === undefined) {
        separator = '—'; // Em dash
      }

      var minutes = rawTime.getMinutes();
      var hours = rawTime.getHours();
      var month = rawTime.getMonth() + 1;
      var day = rawTime.getDate();
      var suffix = hours > 11 ? 'PM' : 'AM';

      if (hours === 0) {
        hours = 12;
      }

      if (hours > 12) {
        hours -= 12;
      }

      hours = prependZero(hours);
      minutes = prependZero(minutes);
      month = prependZero(month);
      day = prependDay ? prependZero(day) : day;

      return '' + month + dateDelimeter + day + dateDelimeter + rawTime.getFullYear() + ' ' + separator + ' ' + hours + timeDelimeter + minutes + ' ' + suffix;
    },
    getValidFileSystemDatetime: function getValidFileSystemDatetime(date) {
      return this.dateTimeString(date, '-', '-', '__');
    },
    dateTime: function dateTime(timestampString) {
      var dateTime = new Date(timestampString);
      var day = dateTime.getDate().toString().padStart(2, '0');
      var month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
      var year = dateTime.getFullYear();
      var hour = dateTime.getHours().toString().padStart(2, '0');
      var minute = dateTime.getMinutes().toString().padStart(2, '0');
      var meridian = hour >= 12 ? 'PM' : 'AM';
      var hour12 = hour % 12 || 12;

      return day + '/' + month + '/' + year + ' \u2014 ' + hour12 + ':' + minute + ' ' + meridian;
    }
  });
});