define("webui/pods/components/create-map-step-photo-upload/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("input");
          dom.setAttribute(el1, "type", "file");
          dom.setAttribute(el1, "name", "files[]");
          dom.setAttribute(el1, "directory", "");
          dom.setAttribute(el1, "allowdirs", "");
          dom.setAttribute(el1, "multiple", "");
          dom.setAttribute(el1, "accept", "image/*,.mrk,.obs");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'id');
          morphs[1] = dom.createAttrMorph(element4, 'style');
          return morphs;
        },
        statements: [["attribute", "id", ["concat", ["file-", ["get", "elementId", ["loc", [null, [18, 55], [18, 64]]]]]]], ["attribute", "style", ["concat", [["get", "browseStyle", ["loc", [null, [18, 136], [18, 147]]]]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 8
            },
            "end": {
              "line": 23,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "btn btn-default btn-file js-trigger-browse");
          var el2 = dom.createTextNode("\n            Browse for photos");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "type", "file");
          dom.setAttribute(el2, "name", "files[]");
          dom.setAttribute(el2, "directory", "");
          dom.setAttribute(el2, "allowdirs", "");
          dom.setAttribute(el2, "multiple", "");
          dom.setAttribute(el2, "accept", "image/jpg,image/jpeg,.mrk,.obs");
          dom.setAttribute(el2, "style", "display: none;");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'style');
          morphs[1] = dom.createAttrMorph(element3, 'id');
          return morphs;
        },
        statements: [["attribute", "style", ["concat", [["get", "browseStyle", ["loc", [null, [20, 77], [20, 88]]]]]]], ["attribute", "id", ["concat", ["file-", ["get", "elementId", ["loc", [null, [21, 74], [21, 83]]]]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 37,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row photo-meta-progress");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "progress");
          dom.setAttribute(el3, "style", "height:20px");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "progress-bar progress-bar-primary progress-bar-striped");
          dom.setAttribute(el4, "role", "progressbar");
          dom.setAttribute(el4, "aria-valuemin", "0");
          dom.setAttribute(el4, "aria-valuemax", "100");
          var el5 = dom.createTextNode("\n              processing\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'aria-valuenow');
          morphs[1] = dom.createAttrMorph(element1, 'style');
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["concat", [["get", "metaProgress", ["loc", [null, [31, 116], [31, 128]]]]]]], ["attribute", "style", ["concat", ["width: ", ["get", "metaProgress", ["loc", [null, [31, 186], [31, 198]]]], "%; height:20px"]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 8
            },
            "end": {
              "line": 51,
              "column": 59
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("strong");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["content", "queueLength", ["loc", [null, [51, 35], [51, 50]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 59
            },
            "end": {
              "line": 51,
              "column": 69
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("No");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 8
            },
            "end": {
              "line": 52,
              "column": 159
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" | ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "#");
          dom.setAttribute(el1, "class", "removeFile");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "glyphicon glyphicon-remove-circle");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Remove All");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["removeAllFiles"], [], ["loc", [null, [52, 61], [52, 88]]]]],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 10
            },
            "end": {
              "line": 59,
              "column": 89
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "placeholder");
          var el2 = dom.createTextNode("Ready to import photos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child7 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 92
            },
            "end": {
              "line": 72,
              "column": 179
            }
          },
          "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "glyphicon glyphicon-repeat spinner");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 76,
            "column": 6
          }
        },
        "moduleName": "webui/pods/components/create-map-step-photo-upload/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "create-map-step create-map-step-photo-upload");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row heading");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-sm-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Photo Upload");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("All photos for this processing job must be uploaded at once. Multiple uploads will yield multiple maps. You can either search for files on your computer using the ‘browse’ button below, or drag and drop your files directly on to the screen.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "id", "drag_upload_form");
        dom.setAttribute(el2, "method", "post");
        dom.setAttribute(el2, "action", "");
        dom.setAttribute(el2, "enctype", "multipart/form-data");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row browse-button-row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-12");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row fileDropStatus");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-12");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "glyphicon glyphicon-repeat spinner");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-6 col-xs-12 gcp-filename-meta");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-6 col-xs-12 file-list-meta");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" photo");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" to upload\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-12");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "drop-target");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row button-row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn accent js-trigger-save");
        dom.setAttribute(el4, "disabled", "");
        var el5 = dom.createTextNode("SAVE & NEXT ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [5]);
        var element7 = dom.childAt(element6, [7, 3]);
        var element8 = dom.childAt(element6, [9, 1, 1]);
        var element9 = dom.childAt(element5, [7, 1, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element6, 3, 3);
        morphs[2] = dom.createMorphAt(element7, 1, 1);
        morphs[3] = dom.createMorphAt(element7, 3, 3);
        morphs[4] = dom.createMorphAt(element7, 5, 5);
        morphs[5] = dom.createMorphAt(element8, 1, 1);
        morphs[6] = dom.createMorphAt(element8, 3, 3);
        morphs[7] = dom.createElementMorph(element9);
        morphs[8] = dom.createMorphAt(element9, 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "isMSEdge", ["loc", [null, [17, 14], [17, 22]]]]], [], 0, 1, ["loc", [null, [17, 8], [23, 15]]]], ["block", "if", [["get", "metaLoading", ["loc", [null, [27, 10], [27, 21]]]]], [], 2, null, ["loc", [null, [27, 4], [37, 11]]]], ["block", "if", [["get", "queueLength", ["loc", [null, [51, 14], [51, 25]]]]], [], 3, 4, ["loc", [null, [51, 8], [51, 76]]]], ["inline", "if", [["subexpr", "not-eq", [["get", "queueLength", ["loc", [null, [51, 95], [51, 106]]]], 1], [], ["loc", [null, [51, 87], [51, 109]]]], "s"], [], ["loc", [null, [51, 82], [51, 115]]]], ["block", "if", [["get", "queueLength", ["loc", [null, [52, 14], [52, 25]]]]], [], 5, null, ["loc", [null, [52, 8], [52, 166]]]], ["block", "if", [["subexpr", "lt", [["get", "queueLength", ["loc", [null, [59, 20], [59, 31]]]], 1], [], ["loc", [null, [59, 16], [59, 34]]]]], [], 6, null, ["loc", [null, [59, 10], [59, 96]]]], ["inline", "visual-file-uploader", [], ["flightId", ["subexpr", "@mut", [["get", "wizardData.targetFlight.id", ["loc", [null, [61, 21], [61, 47]]]]], [], []], "queueChanged", ["subexpr", "action", ["queueChanged"], [], ["loc", [null, [62, 25], [62, 48]]]]], ["loc", [null, [60, 10], [63, 12]]]], ["element", "action", ["save"], [], ["loc", [null, [72, 58], [72, 75]]]], ["block", "if", [["get", "metaLoading", ["loc", [null, [72, 98], [72, 109]]]]], [], 7, null, ["loc", [null, [72, 92], [72, 186]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }());
});