define('webui/pods/components/create-map-step-correction-method/component', ['exports', 'npm:lodash', 'webui/config/environment'], function (exports, _npmLodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uploader: Ember.inject.service('xhr-uploader'),
    feedback: Ember.inject.service('user-feedback'),

    wizardData: null, /* object created in create-map-wizard */
    wizard: null, /* this is passed via the template */

    isMSEdge: false,

    noCorrection: false,

    correctionMethod: 'gcp',

    gcpFile: null,
    gpsFile: null,

    ppkFiles: null,
    ppkLogFiles: null,
    ppkBaseStation: null,

    baseStationLat: null,
    baseStationLong: null,
    baseStationEllipsoidHeight: null,
    baseStationAntennaHeight: null,

    corsSamplingRate: null,

    ppkFilePending: null,
    ppkLogFilePending: null,

    rtkMethod: null,

    canSave: false,

    allowedGcpFileTypes: ['text'],
    allowedGcpFileTypesForCheck: ['text/csv', 'text/plain'],
    allowedGcpFileExtensions: /\.((csv)|(txt)|(\d{2}o))$/i,
    allowedPpkFileExtensions: /\.((ppk)|(txt)|(mrk)|(obs)|(log)|(sbf)|(\d{2}o))$/i,
    allowedGPSCalibrationFileExtensions: /\.((csv)|(txt)|(dc)|(cal)|(gc3)|(\d{2}o))$/i,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('gpsFile', { placeholder: true, name: this.get('wizardData.activeSite.gpsCalibrationFileName') });
      this.set('isMSEdge', /Edge\//.test(navigator.userAgent));

      if (this.wizardData.get('skipGCP')) {
        this.set('noCorrection', true);
      }

      if (this.wizardData.get('correctionMethod')) {
        this.set('correctionMethod', this.wizardData.get('correctionMethod'));
      }

      if (this.wizardData.get('ppkBaseStation')) {
        this.set('ppkBaseStation', this.wizardData.get('ppkBaseStation'));
      }

      if (this.wizardData.get('corsSamplingRate')) {
        this.set('corsSamplingRate', this.wizardData.get('corsSamplingRate'));
      }

      if (this.wizardData.get('gcpFile')) {
        this.set('gcpFile', this.wizardData.get('gcpFile'));
      }

      if (this.wizardData.get('ppkFiles')) {
        this.set('ppkFiles', this.wizardData.get('ppkFiles'));
      } else {
        this.set('ppkFiles', Ember.A());
      }

      if (this.wizardData.get('ppkLogFiles')) {
        this.set('ppkLogFiles', this.wizardData.get('ppkLogFiles'));
      } else {
        this.set('ppkLogFiles', Ember.A());
      }

      var processingNotes = this.wizardData.get('processingNotes') || {};

      if (processingNotes.baseStationLat) {
        this.set('baseStationLat', processingNotes.baseStationLat);
      }

      if (processingNotes.baseStationLong) {
        this.set('baseStationLong', processingNotes.baseStationLong);
      }

      if (processingNotes.baseStationEllipsoidHeight) {
        this.set('baseStationEllipsoidHeight', processingNotes.baseStationEllipsoidHeight);
      }

      if (processingNotes.baseStationAntennaHeight) {
        this.set('baseStationAntennaHeight', processingNotes.baseStationAntennaHeight);
      } else {
        this.set('baseStationAntennaHeight', 2); /* Default value */
      }

      var useTurboProcessingValueHasBeenEnteredByUser = typeof this.wizardData.get('useTurboProcessing') === 'undefined';
      this.set('useTurboProcessing', useTurboProcessingValueHasBeenEnteredByUser ? true : this.wizardData.get('useTurboProcessing'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('s3Bucket', _environment.default.APP.S3host);
      this.evaluateSave(); /* Check default or loaded values to see if we can save. */
    },
    didInsertElement: function didInsertElement() {
      this.$('.accordion-header').toggleClass('inactive-header');

      /* Default to using GCP when component renders */
      var $accordionHeader = this.$('.accordion-header').first();
      var $accordionContent = this.$('.accordion-content').first();

      switch (this.get('correctionMethod')) {
        case 'none':
          /* This only happens if the user has previously skipped GCP upload */
          $accordionHeader = this.$('.accordion-header').last();
          $accordionContent = this.$('.accordion-content').last();
          break;
        case 'ppk':
          $accordionHeader = this.$('.accordion-header').eq(1);
          $accordionContent = this.$('.accordion-content').eq(1);
          break;
        default:
          break;
      }

      $accordionHeader.toggleClass('active-header').toggleClass('inactive-header');
      $accordionContent.slideDown().toggleClass('open-content');

      /* TODO this animation needs scoped slightly better so it only happens within this component instance */
      this.$('.accordion-header').click(function click() {
        if ($(this).is('.inactive-header')) {
          $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
          $(this).toggleClass('active-header').toggleClass('inactive-header');
          $(this).next().slideToggle().toggleClass('open-content');
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.accordion-header').off();
    },
    allowSave: function allowSave() {
      /* This should only be used after validation. */
      this.set('canSave', true);
      // this.$('.js-trigger-save').removeAttr('disabled');
    },
    preventSave: function preventSave() {
      this.set('canSave', false);
      // this.$('.js-trigger-save').attr('disabled', 'disabled');
    },
    setCorrectionMethod: function setCorrectionMethod(method) {
      this.set('correctionMethod', method);
    },
    evaluateSave: function evaluateSave() {
      var canSave = false;
      switch (this.get('correctionMethod')) {
        case 'ppk':
          if (this.get('ppkFiles').length) {
            var ppkFiles = this.get('ppkFiles');
            var mrkFileCount = 0;
            var obsFileCount = 0;

            switch (this.get('ppkBaseStation')) {
              case 'cors':
                if (this.get('corsSamplingRate')) {
                  canSave = true;
                }
                break;
              case 'owned-known':
                if (this.get('ppkLogFiles').length && this.get('baseStationLat') && this.get('baseStationLong') && this.get('baseStationEllipsoidHeight') && this.get('baseStationAntennaHeight')) {
                  canSave = true;
                }
                break;
              case 'owned-unknown':
                if (this.get('ppkLogFiles').length && this.get('baseStationAntennaHeight')) {
                  canSave = true;
                }
                break;
              default:
                break;
            }

            _npmLodash.default.forEach(ppkFiles, function (file) {
              if (file.name.substr(file.name.length - 4).toLowerCase() === '.mrk') {
                mrkFileCount += 1;
              }
              if (file.name.substr(file.name.length - 4).toLowerCase() === '.obs') {
                obsFileCount += 1;
              }
            });

            canSave = canSave && mrkFileCount === obsFileCount;
          }
          break;
        case 'gcp':
          if (this.get('gcpFile')) {
            canSave = true;
          }
          break;
        case 'rtk':
          if (this.get('gcpFile') && this.get('rtkMethod')) {
            canSave = true;
          }
          break;
        case 'none':
          canSave = true; /* User is skipping correction */
          break;
        default:
          break;
      }

      if (canSave) {
        this.allowSave();
      } else {
        this.preventSave();
      }
    },
    addFileToEmberArray: function addFileToEmberArray(arrayName, file) {
      if (!this.get(arrayName).some(function (el) {
        return el.name === file.name && el.lastModified === file.lastModified;
      })) {
        this.get(arrayName).pushObject(file);
      } else {
        // console.log('Skipping duplicate file in ' + arrayName);
      }
    },
    evaluateInputValue: function evaluateInputValue(input) {
      var $input = $(input);
      var inputVal = $input.val();
      var isValid = true;
      if (inputVal.length > 0 && $input.attr('data-val-type')) {
        switch ($input.attr('data-val-type')) {/* Right now we only have numeric fields with basic min/max requirements */
          case 'number':
            if (!$.isNumeric(inputVal)) {
              isValid = false;
            } else {
              if ($input.attr('data-val-min')) {
                if (inputVal < Number($input.attr('data-val-min'))) {
                  isValid = false;
                }
              }

              if ($input.attr('data-val-max')) {
                if (inputVal > Number($input.attr('data-val-max'))) {
                  isValid = false;
                }
              }
            }
            break;
          default:
            break;
        }
      }

      if (!isValid) {
        /* TODO: There is an obscure use case where a user will have entered a valid value and then try to change it to something invalid.
           The form will still be considered valid, but the first value will be saved - not the pending invalid one. Not a huge problem, just bad UX.
        */
        $input.addClass('error');
        this.preventSave();
      } else {
        this.set($input.attr('name'), inputVal);
        $input.removeClass('error');
        this.evaluateSave();
      }

      return isValid;
    },
    getInvalidPpkFiles: function getInvalidPpkFiles(files, fileListName, fileTypeDisplayName) {
      var ppkFiles = this.get('ppkFiles');
      var allowedPpkFileExtensionsRegex = this.get('allowedPpkFileExtensions');
      var failedValidationMsgParts = [fileTypeDisplayName + ' files'];
      var alertLevel = '';
      var failedValidationMsg = '';
      var mrkFileCount = 0;
      var obsFileCount = 0;

      if (fileListName === 'ppkFiles') {
        _npmLodash.default.forEach(files, function (file) {
          if (file.name.substr(file.name.length - 4).toLowerCase() === '.mrk') {
            mrkFileCount += 1;
          }
          if (file.name.substr(file.name.length - 4).toLowerCase() === '.obs') {
            obsFileCount += 1;
          }
        });

        _npmLodash.default.forEach(ppkFiles, function (file) {
          if (file.name.substr(file.name.length - 4).toLowerCase() === '.mrk') {
            mrkFileCount += 1;
          }
          if (file.name.substr(file.name.length - 4).toLowerCase() === '.obs') {
            obsFileCount += 1;
          }
        });
      }

      var filesWithInvalidExtension = _npmLodash.default.reject(files, function (file) {
        return allowedPpkFileExtensionsRegex.test(file.name);
      });
      var invalidFiles = _npmLodash.default.union(filesWithInvalidExtension);

      if (typeof invalidFiles === 'undefined') {
        invalidFiles = [];
      }
      if (typeof files === 'undefined') {
        files = [];
      }

      if (invalidFiles.length === 0 && mrkFileCount === obsFileCount) {
        return { invalidFilesFound: false, alertLevel: null };
      }

      if (filesWithInvalidExtension.length > 0) {
        if (fileListName !== 'ppkFiles') {
          failedValidationMsgParts.push('must have a .ppk, .txt, .log, .sbf or .*o extension');
        } else {
          failedValidationMsgParts.push('must have a .ppk, .txt, .log, .sbf, .*o, .mrk, or .obs extension');
        }
      }

      if (mrkFileCount !== obsFileCount) {
        if (filesWithInvalidExtension.length > 0) {
          failedValidationMsgParts.push('and');
        }
        failedValidationMsgParts.push('.obs files must have a corresponding .mrk file and vice versa');
      }

      failedValidationMsg = failedValidationMsgParts.join(' ') + '.';
      if (invalidFiles.length === files.length || mrkFileCount !== obsFileCount) {
        alertLevel = 'danger';
      } else {
        alertLevel = 'warning';
        failedValidationMsg = failedValidationMsg + ' - ' + invalidFiles.length + ' files ignored.';
      }

      return {
        invalidFilesFound: true, invalidFiles: invalidFiles, message: failedValidationMsg, alertLevel: alertLevel
      };
    },
    ppkFileUploadHandler: function ppkFileUploadHandler(event, fileListName, fileTypeDisplayName) {
      var component = this;
      var input = event.target;
      var invalidUploads = this.getInvalidPpkFiles(input.files, fileListName, fileTypeDisplayName);
      var filesToAdd = _npmLodash.default.difference(input.files, invalidUploads.invalidFiles);

      _npmLodash.default.forEach(filesToAdd, function (file) {
        component.addFileToEmberArray(fileListName, file);
      });
      if (invalidUploads.invalidFilesFound) component.wizard.setMessage(invalidUploads.alertLevel, invalidUploads.message);

      $(input).val(null); /* Reset the input value so the onChange event will fire again regardless of selection. */
      component.evaluateSave();
    },


    actions: {
      save: function save() {
        /* Accepted CorrectionMethod types: ['gcp', 'ppk-cors', 'ppk-self-known-point', 'ppk-self-unknown-point', 'rtk-mobile-base-station', 'rtk-vrs-network'] */
        this.wizardData.set('corsSamplingRate', 0); /* Always default this to 0 - it may be set to 1 or 5 if ppk-cors is utilized */
        var processingNotes = this.wizardData.get('processingNotes') || {};
        delete processingNotes.baseStationLat; /* Remove these variables in case the user changed their mind and selected something else. */
        delete processingNotes.baseStationLong;
        delete processingNotes.baseStationEllipsoidHeight;
        delete processingNotes.baseStationAntennaHeight;

        switch (this.get('correctionMethod')) {
          case 'ppk':
            this.wizardData.set('correctionMethod', 'ppk');
            if (this.get('ppkFiles').length) {
              this.wizardData.set('ppkFiles', this.get('ppkFiles'));
              switch (this.get('ppkBaseStation')) {
                case 'cors':
                  this.wizardData.set('ppkBaseStation', 'cors');
                  this.wizardData.set('correctionMethodType', 'ppk-cors');
                  if (this.get('corsSamplingRate')) {
                    this.wizardData.set('corsSamplingRate', this.get('corsSamplingRate'));
                  }
                  break;
                case 'owned-known':
                  this.wizardData.set('correctionMethodType', 'ppk-self-known-point');
                  this.wizardData.set('ppkBaseStation', this.get('ppkBaseStation'));
                  processingNotes.baseStationLat = this.get('baseStationLat');
                  processingNotes.baseStationLong = this.get('baseStationLong');
                  processingNotes.baseStationEllipsoidHeight = this.get('baseStationEllipsoidHeight');
                  processingNotes.baseStationAntennaHeight = this.get('baseStationAntennaHeight');
                  this.wizardData.set('processingNotes', processingNotes);
                  if (this.get('ppkLogFiles').length) {
                    this.wizardData.set('ppkLogFiles', this.get('ppkLogFiles'));
                  }
                  break;
                case 'owned-unknown':
                  this.wizardData.set('correctionMethodType', 'ppk-self-unknown-point');
                  this.wizardData.set('ppkBaseStation', this.get('ppkBaseStation'));
                  processingNotes.baseStationAntennaHeight = this.get('baseStationAntennaHeight');
                  this.wizardData.set('processingNotes', processingNotes);
                  if (this.get('ppkLogFiles').length) {
                    this.wizardData.set('ppkLogFiles', this.get('ppkLogFiles'));
                  }
                  break;
                default:
                  break;
              }
            }
            /* PPK has an optional GCP file so we check and set it here independent of the chosen correction method */
            if (this.get('gcpFile')) {
              this.wizardData.set('gcpFile', this.get('gcpFile'));
            } else if (this.get('wizardData.gcpFile')) {
              this.set('wizardData.gcpFile', null); /* Unusual case where a user might go back and remove a GCP. */
            }

            if (this.get('gpsFile')) {
              this.wizardData.set('gpsFile', this.get('gpsFile'));
            } else if (this.get('wizardData.gpsFile')) {
              this.set('wizardData.gpsFile', null); /* Unusual case where a user might go back and remove a GCP. */
            }
            break;
          case 'gcp':
            this.wizardData.set('correctionMethod', 'gcp');
            this.wizardData.set('correctionMethodType', 'gcp');
            if (this.get('gcpFile')) {
              this.wizardData.set('gcpFile', this.get('gcpFile'));
            }
            /* Clear these arrays just in case the user has changed their mind */
            this.wizardData.set('ppkFiles', null);
            this.wizardData.set('ppkLogFiles', null);
            this.wizardData.set('processingNotes', processingNotes);
            break;
          case 'rtk':
            this.wizardData.set('correctionMethod', 'rtk');
            switch (this.get('rtkMethod')) {
              case 'mobile-base-station':
                this.wizardData.set('rtkMethod', 'mobile-base-station');
                this.wizardData.set('correctionMethodType', 'rtk-mobile-base-station');
                break;
              case 'vrs-network':
                this.wizardData.set('rtkMethod', 'vrs-network');
                this.wizardData.set('correctionMethodType', 'rtk-vrs-network');
                break;
              default:
                break;
            }

            if (this.get('gcpFile')) {
              this.wizardData.set('gcpFile', this.get('gcpFile'));
            } else if (this.get('wizardData.gcpFile')) {
              this.set('wizardData.gcpFile', null); /* Unusual case where a user might go back and remove a GCP. */
            }

            if (this.get('gpsFile')) {
              this.wizardData.set('gpsFile', this.get('gpsFile'));
            } else if (this.get('wizardData.gpsFile')) {
              this.set('wizardData.gpsFile', null); /* Unusual case where a user might go back and remove a GCP. */
            }
            break;
          case 'none':
            this.wizardData.set('correctionMethod', 'none');
            /* Clear these arrays just in case the user has changed their mind */
            this.wizardData.set('ppkFiles', null);
            this.wizardData.set('ppkLogFiles', null);
            this.wizardData.set('gcpFile', null);
            this.wizardData.set('processingNotes', processingNotes);

            this.wizardData.set('skipGCP', true);
            break;
          default:
            break;
        }

        this.wizardData.set('useTurboProcessing', this.get('correctionMethod') === 'ppk' ? false : this.get('useTurboProcessing'));
        var shouldClearGCPTags = this.get('correctionMethod') === 'none' && this.wizardData.get('photoMeta');
        if (shouldClearGCPTags) {
          this.wizardData.get('photoMeta').forEach(function (photo) {
            photo.taggedGCPs = [];
          });
        }

        this.sendAction('advanceNextStep'); /* Go to step 3 */
      },
      addPpkFile: function addPpkFile() {
        this.get('ppkFiles').pushObject(this.get('ppkFilePending'));
        this.set('ppkFilePending', null);
        this.evaluateSave();
      },
      addPpkLogFile: function addPpkLogFile() {
        this.get('ppkLogFiles').pushObject(this.get('ppkLogFilePending'));
        this.set('ppkLogFilePending', null);
        this.evaluateSave();
      },
      removePpkFile: function removePpkFile(file) {
        this.get('ppkFiles').removeObject(file);
        this.ppkFileUploadHandler(event, 'ppkFiles', 'Uploaded UAV PPK');
        this.evaluateSave();
      },
      removePpkLogFile: function removePpkLogFile(file) {
        this.get('ppkLogFiles').removeObject(file);
        this.evaluateSave();
      },
      removeGcpFile: function removeGcpFile() {
        this.set('gcpFile', null);
        this.evaluateSave();
      },
      removeGPSFile: function removeGPSFile() {
        this.set('gpsFile', null);
        this.evaluateSave();
      },
      skip: function skip() {
        this.set('noCorrection', true);
        this.setCorrectionMethod('none');
        this.evaluateSave();
      },
      usePpkCorrection: function usePpkCorrection() {
        this.set('noCorrection', false);
        this.setCorrectionMethod('ppk');
        this.evaluateSave();
      },
      useRtkCorrection: function useRtkCorrection() {
        this.set('noCorrection', false);
        this.setCorrectionMethod('rtk');
        this.evaluateSave();
      },
      useGcpCorrection: function useGcpCorrection() {
        /* Since 'use GCP" is the default, this is fired if the user chooses to skip, changes their mind and then clicks the "use GCP" option */
        this.set('noCorrection', false);
        this.setCorrectionMethod('gcp');
        this.evaluateSave();
      },
      setPpkStation: function setPpkStation(station) {
        this.set('ppkBaseStation', station);
        this.evaluateSave();
      },
      setRtkMethod: function setRtkMethod(station) {
        this.set('rtkMethod', station);
        this.evaluateSave();
      },
      setCorsSamplingRate: function setCorsSamplingRate(rate) {
        this.set('corsSamplingRate', rate);
        this.evaluateSave();
      },
      onPpkLogInputChange: function onPpkLogInputChange(event) {
        this.ppkFileUploadHandler(event, 'ppkLogFiles', 'Uploaded Base Station log');
      },
      onPpkInputChange: function onPpkInputChange(event) {
        this.ppkFileUploadHandler(event, 'ppkFiles', 'Uploaded UAV PPK');
      },
      onGPSInputChange: function onGPSInputChange(event) {
        var input = event.target;
        var attemptedFile = input.files[0];
        var allowedGPSCalibrationFileExtensions = this.get('allowedGPSCalibrationFileExtensions');
        if (!allowedGPSCalibrationFileExtensions.test(attemptedFile.name)) {
          this.wizard.setMessage('danger', 'The GPS calibration file must be a .dc, .cal, .gc3, .csv or .txt document.');
        } else {
          this.set('gpsFile', attemptedFile);
          $(input).val(null); /* This empties the input's value to make sure the change event always triggers after browse. */
          this.evaluateSave();
        }
      },
      onGcpInputChange: function onGcpInputChange(event) {
        var _this = this;

        var errorMessage = 'GCP files must be .CSV or .TXT and follow the formatting "pointID, easting, northing, elevation."';
        var input = event.target;
        var attemptedFile = input.files[0];
        var allowedGcpFileExtensions = this.get('allowedGcpFileExtensions');

        if (!allowedGcpFileExtensions.test(attemptedFile.name)) {
          this.wizard.setMessage('danger', errorMessage);
        } else {
          var isFileProperlyFormatted = true;
          var reader = new FileReader();
          reader.addEventListener('load', function () {
            var gcps = reader.result.split(/[\r\n]+/).map(function (rowString) {
              var row = rowString.split(',');

              return { id: row[0], easting: row[1], northing: row[2], elevation: row[3] };
            });

            if (gcps.length === 0) {
              isFileProperlyFormatted = false;
            } else {
              var firstRowIsHeader = isNaN(gcps[0].easting);
              if (firstRowIsHeader) gcps.shift();

              if (gcps.length === 0) {
                isFileProperlyFormatted = false;
              } else {
                gcps.forEach(function (gcp) {
                  var hasNorthAndOrEasting = gcp.easting || gcp.northing;
                  var northingOrEeastingIsNaN = Number.isNaN(parseFloat(gcp.easting)) || Number.isNaN(parseFloat(gcp.northing));
                  if (hasNorthAndOrEasting && northingOrEeastingIsNaN) isFileProperlyFormatted = false;
                });
              }
            }

            if (!isFileProperlyFormatted) {
              _this.wizard.setMessage('danger', errorMessage);
            } else {
              _this.wizard.clearMessage();
              _this.set('gcpFile', attemptedFile);
              $(input).val(null); /* This empties the input's value to make sure the change event always triggers after browse. */
              _this.evaluateSave();
            }
          });
          reader.readAsText(attemptedFile);
        }
      },
      onInputChange: function onInputChange(event) {
        this.evaluateInputValue(event.target);
      }
    }
  });
});