define('webui/react/FileManagerPluginDxf', ['exports', 'npm:react', 'npm:lodash', 'webui/react/FileInput', 'webui/config/environment'], function (exports, _npmReact, _npmLodash, _FileInput, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var FileManagerPluginDxf = function (_React$Component) {
    _inherits(FileManagerPluginDxf, _React$Component);

    _createClass(FileManagerPluginDxf, null, [{
      key: 'copyInputToClipboard',
      value: function copyInputToClipboard(input) {
        if (input) {
          input.select();
          document.execCommand('copy');
        }
      }
    }, {
      key: 'limitEpsgToNumbers',
      value: function limitEpsgToNumbers(event) {
        /* This is effectively only used by old (read IE) browsers - all modern ones will only allow numbers into a type=number form field. */
        var key = event.keyCode || event.charCode;
        if (key && !/[0-9]/g.test(String.fromCharCode(key))) {
          event.preventDefault();
        }
      }
    }, {
      key: 'prettifyFileSize',
      value: function prettifyFileSize(kbs) {
        var fileSize = '';
        if (!kbs) {
          fileSize = '';
        } else if (kbs >= 1048576) {
          fileSize = (kbs / 1048576).toFixed(2) + ' GB';
        } else if (kbs >= 1024) {
          fileSize = (kbs / 1024).toFixed(2) + ' MB';
        } else if (kbs >= 1) {
          fileSize = kbs.toString() + ' KB';
        } else {
          fileSize = '< 1 KB';
        }
        return fileSize;
      }
    }]);

    function FileManagerPluginDxf(props) {
      _classCallCheck(this, FileManagerPluginDxf);

      var _this = _possibleConstructorReturn(this, (FileManagerPluginDxf.__proto__ || Object.getPrototypeOf(FileManagerPluginDxf)).call(this, props));

      _this.file = props.file; /* Intentionally outside of state so we don't accidentally trigger updates while tracking incidental changes. */

      _this.state = {
        uploadProgress: 0,
        uploading: false,
        unqualifiedFile: {},

        shareMode: false,
        deleteMode: false,
        editMode: false,
        actionsMenuVisible: false,

        isValidForm: false,
        userFiles: [],

        changingFileStatus: null
      };

      _this.mimeTypes = ['application/dxf', 'application/x-autocad', 'application/x-dxf', 'drawing/x-dxf', 'image/vnd.dxf', 'image/x-autocad', 'image/x-dxf', 'zz-application/zz-winassoc-dxf'];

      _this.fileExtensions = ['.dxf'];

      _this.eventCleanupBucket = [];

      if (props.file) {
        _this.pendingFileName = props.file.name; /* default to the current name */
        _this.pendingEpsgCode = props.file.epsgCode;
      }

      _this.save = _this.save.bind(_this);
      _this.deleteFile = _this.deleteFile.bind(_this);
      _this.downloadFile = _this.downloadFile.bind(_this);
      _this.setPendingFileName = _this.setPendingFileName.bind(_this);
      _this.setPendingFileEpsg = _this.setPendingFileEpsg.bind(_this);

      _this.cancel = _this.cancel.bind(_this);
      _this.filesChanged = _this.filesChanged.bind(_this);
      // this.changeEpsgCode = this.changeEpsgCode.bind(this);
      _this.uploadFile = _this.uploadFile.bind(_this);

      _this.registerEventSaveOnEnter = _this.registerEventSaveOnEnter.bind(_this);
      return _this;
    }

    _createClass(FileManagerPluginDxf, [{
      key: 'componentDidMount',
      value: function componentDidMount() {
        var file = this.props.file;

        if (file && !file) {
          this.setState({ file: file });
        }
      }
    }, {
      key: 'componentWillReceiveProps',
      value: function componentWillReceiveProps(nextProps) {
        var file = this.props.file;


        if (nextProps.file && nextProps.file.status && nextProps.file.status === 'pending') {
          this.subscribeToUpdates(nextProps.file.id);
        }

        if (nextProps.file) {
          if (!this.pendingEpsgCode || file && file.epsgCode !== nextProps.file.epsgCode) {
            this.pendingEpsgCode = nextProps.file.epsgCode;
          }

          if (!this.pendingFileName || file && file.name !== nextProps.file.name) {
            this.pendingFileName = nextProps.file.name;
          }
        }
      }
    }, {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        /* Cleanup before deconstruction */
        this.eventCleanupBucket.forEach(function (o) {
          /* Most browsers shouldn't need handholding here, but to prevent memory leaks in old ones we'll tear these out. */
          o.el.removeEventListener(o.event, o.method);
        });

        io.socket.off('overlayblueprint');
      }
    }, {
      key: 'setPendingFileName',
      value: function setPendingFileName(event) {
        this.pendingFileName = event.target.value;
      }
    }, {
      key: 'setPendingFileEpsg',
      value: function setPendingFileEpsg(event) {
        if (!isNaN(event.target.value)) {
          this.pendingEpsgCode = _npmLodash.default.parseInt(event.target.value);
          this.validate();
        }
      }
    }, {
      key: 'toggleActionsMenu',
      value: function toggleActionsMenu() {
        var actionsMenuVisible = this.state.actionsMenuVisible;


        if (actionsMenuVisible) {
          this.hideActionsMenu();
        } else {
          this.showActionsMenu();
        }
      }
    }, {
      key: 'hideActionsMenu',
      value: function hideActionsMenu() {
        this.setState({ actionsMenuVisible: false });
      }
    }, {
      key: 'showActionsMenu',
      value: function showActionsMenu() {
        this.setState({ actionsMenuVisible: true });
      }
    }, {
      key: 'registerEventSaveOnEnter',
      value: function registerEventSaveOnEnter(input) {
        var component = this;
        if (input) {
          /* ref callback passes "null" when the element is destroyed. */
          var saveOnEnter = function saveOnEnter(event) {
            if (event.keyCode === 13) {
              /* Enter key */
              component.save(event);
            }
          };

          component.eventCleanupBucket.push({ el: input, event: 'keydown', method: saveOnEnter });
          input.addEventListener('keydown', saveOnEnter);
        }
      }
    }, {
      key: 'reset',
      value: function reset() {
        this.setState({
          shareMode: false,
          deleteMode: false,
          editMode: false,
          actionsMenuVisible: false
        });
      }
    }, {
      key: 'downloadFile',
      value: function downloadFile() /* event */{
        var file = this.props.file;

        var downloadTarget = _environment.default.APP.apiUrl + 'overlayBlueprints/download/' + file.id;
        window.open(downloadTarget);
        this.hideActionsMenu();
      }
    }, {
      key: 'save',
      value: function save() /* event */{
        var file = this.props.file;
        var _state = this.state,
            editMode = _state.editMode,
            userFiles = _state.userFiles;


        if (file) {
          var modifiedFile = _npmLodash.default.clone(file);
          if (editMode) {
            var changed = false;

            if (file.name !== this.pendingFileName) {
              // console.log('Saving record - changing filename:', this.props.file.name, this.pendingFileName);
              modifiedFile.name = this.pendingFileName;
              changed = true;
            }

            /* TODO: this has been left here for future use once DXF reprocessing is sorted out */
            if (file.epsgCode !== this.pendingEpsgCode) {
              // console.log('Saving record - changing epsg', this.props.file.epsgCode, this.pendingEpsgCode);
              modifiedFile.epsgCode = this.pendingEpsgCode;
              modifiedFile.status = 'pending';
              changed = true;
            }

            if (changed) {
              this.changeFile(file, modifiedFile);
            } else {
              // console.log('No change - exiting edit mode.');
            }
          } else {
              // console.log('Enabling edit mode for file: ' + this.props.file.key);
            }

          this.setState(function (prevState) {
            return { editMode: !prevState.editMode };
          });
        } else {
          var component = this;
          this.setState({ processing: true });
          if (userFiles && userFiles.length > 0) {
            for (var i = 0; i < userFiles.length; i += 1) {
              component.uploadFile(userFiles[i]);
            }
          }
        }
      }
    }, {
      key: 'changeFile',
      value: function changeFile(oldFile, changedFile) {
        var component = this;
        component.setState({ changingFileStatus: changedFile.status });
        return component.props.dataService.findRecord('overlayBlueprint', oldFile.id, { reload: true }).then(function (f) {
          f.set('name', changedFile.name);
          f.set('epsgCode', changedFile.epsgCode);

          if (changedFile.status !== f.get('status')) {
            f.set('status', changedFile.status); /* This should really only be triggered on EPSG changing which would set the status to pending. */
          }

          return f.save().then(function (success) {
            component.setState({ changingFileStatus: null });

            if (oldFile.epsgCode !== changedFile.epsgCode) {
              /* If the EPSG number has changed we trigger a new processing job. */
              var uploadParams = {
                controller: 'overlayBlueprints',
                method: 'reprocess',
                uuid: changedFile.id,
                body: {
                  overlayBlueprint: {
                    epsgCode: changedFile.epsgCode
                  }
                }
              };

              component.props.fileUploadService.startJob(uploadParams, function (status, response) {
                if (status === 202) {/* Job processing was Accepted */
                  /* We don't actually have to do anything here since this process is unmonitored from this point
                    console.info('OverlayBlueprint reprocessing job started.', response); */
                } else {
                  console.warn(response);
                }
              });
            }
            component.props.onChangeFile(oldFile, changedFile, 'dxf'); /* Tell FileManager to update it's lists */
            return success;
          }, function (err) {
            console.error('Unable to update DXF record.', changedFile, err);
            return null;
          });
        });
      }
    }, {
      key: 'deleteFile',
      value: function deleteFile() /* event */{
        var component = this;
        var _props = this.props,
            dataService = _props.dataService,
            file = _props.file;


        this.reset();
        dataService.findRecord('overlayBlueprint', file.id, { reload: true }).then(function (f) {
          var result = f.destroyRecord();
          if (result) {
            component.raiseMessage('success', component.props.file.name + ' successfully deleted.');
            component.props.statusService.removeItemById('status-panel-blueprint-upload', component.props.file.id);
            /* Note: once onDeleteFile has been called this instance could receive new props which might change the file definition. Do any cleanup before notifying FileManager. */
            component.props.onDeleteFile(component.props.file, 'dxf');
          } else {
            console.error('Unable to delete record.', f);
          }
          return result;
        });
      }
    }, {
      key: 'filesChanged',
      value: function filesChanged(approvedFiles, rejectedFiles) {
        var component = this;
        if (approvedFiles.length) {
          this.setState({ userFiles: approvedFiles }, function () {
            component.validate();
          });
        }

        if (rejectedFiles.length) {
          if (approvedFiles.length) {
            this.raiseMessage('warning', 'Some files were ignored because they were not a .DXF');
          } else {
            this.raiseMessage('danger', 'The uploaded file must be a .DXF');
          }
        }
      }
    }, {
      key: 'validate',
      value: function validate() {
        var userFiles = this.state.userFiles;

        var userFilesValid = false;
        var epsgCodeValid = false;

        if (!isNaN(this.pendingEpsgCode) && Number.isInteger(this.pendingEpsgCode) && this.pendingEpsgCode > -1) {
          epsgCodeValid = true;
        }

        if (userFiles.length > 0) {
          userFilesValid = true;
        }

        var passes = epsgCodeValid && userFilesValid;
        this.setState({ isValidForm: passes });
      }
    }, {
      key: 'registerUploadProgress',
      value: function registerUploadProgress(progress) {
        this.setState({ uploadProgress: progress });
      }
    }, {
      key: 'uploadFile',
      value: function uploadFile(file) {
        var component = this;

        file.processingType = 'dxf';
        file.epsgCode = this.pendingEpsgCode;

        var _props2 = this.props,
            emberComponent = _props2.emberComponent,
            fileUploadService = _props2.fileUploadService,
            onUploadFile = _props2.onUploadFile;


        var tmpFile = {
          processing: true, uploadProgress: 0, name: file.name, epsgCode: file.epsgCode, category: file.processingType
        };

        var fileNameSegments = file.name.split('.');
        var lastSegment = fileNameSegments.length - 1;

        if ( /* fileTypes.indexOf(file.type) === -1 && */fileNameSegments[lastSegment].toLowerCase() !== 'dxf') {
          console.error('Overlay Blueprint is not a DXF type file.');
          // return feedback.reportError({title: 'Blueprint upload error', detail: 'The blueprint file must be a valid DXF.'});
        }

        this.setState({ unqualifiedFile: tmpFile, uploading: true });
        onUploadFile(null, tmpFile, 'dxf');

        var data = {
          'overlayBlueprint.site': component.props.site.id,
          'overlayBlueprint.epsgCode': file.epsgCode
        };

        /* Note: the API currently expects an array for the file so even though we're only passing one we're spoofing an array for the payload. */
        var uploadParams = {
          data: data,
          file: [file],
          controller: 'overlayBlueprints',
          fieldname: 'file',
          component: emberComponent,
          onUploadProgress: function onUploadProgress(progress) {
            component.props.onUploadProgress(tmpFile, progress, 'dxf');
          }
        };

        fileUploadService.upload(uploadParams, function (response) {
          var responseBody = void 0;

          if (!fileUploadService.success) {
            // feedback.handleResponse(response);
            console.error(response);
          } else {
            responseBody = JSON.parse(response);

            var createdFile = responseBody.overlayBlueprint;
            createdFile.category = 'dxf';
            createdFile.key = createdFile.id;

            component.props.onUploadFile(tmpFile, createdFile, 'dxf');
            component.raiseMessage('success', 'New DXF successfully uploaded.');

            component.props.dataService.push({ data: { type: 'overlayBlueprint', id: createdFile.id, attributes: createdFile } });
            var newRecord = component.props.dataService.peekRecord('overlayBlueprint', createdFile.id);
            var siteRecord = component.props.dataService.peekRecord('site', component.props.site.id);
            newRecord.set('site', siteRecord);
            component.props.statusService.pushItem('status-panel-blueprint-upload', newRecord, 'overlayBlueprint');
          }
        });
      }
    }, {
      key: 'cancel',
      value: function cancel() /* event */{
        var onCancel = this.props.onCancel;

        onCancel();
      }
    }, {
      key: 'raiseMessage',
      value: function raiseMessage(severity, text) {
        var onRaiseMessage = this.props.onRaiseMessage;

        var message = {
          severity: severity,
          message: text
        };
        onRaiseMessage(message);
      }
    }, {
      key: 'subscribeToUpdates',
      value: function subscribeToUpdates(blueprintId) {
        var context = this;

        io.socket.on('overlayblueprint', function (body) {
          if (body.verb === 'updated' && body.data.status !== 'pending') {
            var newFile = body.data;
            context.props.updateFileStatus(newFile);
          }
        });
        io.socket.get('/v1/overlayBlueprints/' + blueprintId);
      }
    }, {
      key: 'render',
      value: function render() {
        var component = this;
        var file = this.props.file;
        var _state2 = this.state,
            actionsMenuVisible = _state2.actionsMenuVisible,
            changingFileStatus = _state2.changingFileStatus,
            deleteMode = _state2.deleteMode,
            editMode = _state2.editMode,
            isValidForm = _state2.isValidForm,
            shareMode = _state2.shareMode;

        var propFile = file;

        if (!file) {
          /* File was not passed meaning we need to render a "create new" form. */
          var canSave = isValidForm;

          return _npmReact.default.createElement(
            'div',
            { className: 'col-xs-12 item' },
            _npmReact.default.createElement(
              'div',
              { className: 'row' },
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-7 cell file-browse form-inline' },
                _npmReact.default.createElement(_FileInput.default, { mode: 'dxf', allowedMimeTypes: this.mimeTypes, allowedFileExtensions: this.fileExtensions, onFilesChanged: component.filesChanged })
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-5 cell file-actions' },
                _npmReact.default.createElement(
                  'button',
                  { className: 'btn accent', type: 'button', onClick: component.save, disabled: !canSave },
                  'SAVE NEW DXF'
                ),
                ' ',
                _npmReact.default.createElement(
                  'button',
                  { className: 'btn-link', type: 'button', onClick: component.cancel },
                  'CANCEL'
                )
              )
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'row file-epsg' },
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-6 cell file-epsg-code form-inline' },
                _npmReact.default.createElement(
                  'span',
                  { className: 'padded' },
                  'EPSG code: '
                ),
                _npmReact.default.createElement('input', { type: 'number', placeholder: '(e.g. 2242)', name: 'newEpsg', className: 'form-control', onChange: component.setPendingFileEpsg, onKeyPress: FileManagerPluginDxf.limitEpsgToNumbers })
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-3 cell padded' },
                _npmReact.default.createElement(
                  'a',
                  { href: '#', onClick: function onClick(e) {
                      e.preventDefault();window._elev.openArticle(73);
                    }, target: '_blank' },
                  '(What\'s this?)'
                )
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-3 cell padded' },
                _npmReact.default.createElement(
                  'a',
                  { href: 'http://spatialreference.org/ref/epsg/', target: '_blank', rel: 'noopener noreferrer' },
                  'Don\'t know your code?'
                )
              )
            )
          );
        }

        /* Special markup for files being uploaded */
        if (file.processing) {
          return _npmReact.default.createElement(
            'div',
            { className: 'col-xs-12 item processing' },
            _npmReact.default.createElement(
              'div',
              { className: 'status-bar' },
              _npmReact.default.createElement('div', { className: 'bar', style: { width: propFile.uploadProgress + '%' } })
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'row' },
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-4 cell padded file-name' },
                propFile.name
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-6 cell padded file-upload-progress' },
                propFile.uploadProgress < 100 ? 'Upload Progress: ' + propFile.uploadProgress + '%' : _npmReact.default.createElement(
                  'span',
                  null,
                  'Upload Complete - Processing File',
                  _npmReact.default.createElement(
                    'span',
                    { className: 'spinner-wrapper' },
                    _npmReact.default.createElement('span', { className: 'glyphicon glyphicon-repeat spinner' })
                  )
                )
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'col-xs-2 cell file-actions' },
                _npmReact.default.createElement('button', { className: 'btn glyphicon-full glyphicons-option-horizontal', type: 'button', disabled: true })
              )
            )
          );
        }

        var prettyFileSize = FileManagerPluginDxf.prettifyFileSize(file.fileSize);
        var isEditable = file.status && file.status !== 'pending' && changingFileStatus !== 'pending';
        var isWarning = file.status === 'warning';
        var isFailed = file.status === 'failed';

        var epsgCode = file.epsgCode ? file.epsgCode : _npmReact.default.createElement(
          'span',
          { className: 'danger' },
          _npmReact.default.createElement('span', { className: 'glyphicon-full glyphicons-alert' }),
          ' ',
          'No EPSG code entered'
        );

        return _npmReact.default.createElement(
          'div',
          { className: 'col-xs-12 item', 'data-id': file.key },
          _npmReact.default.createElement(
            'div',
            { className: 'row' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-5 cell file-name' + (editMode ? '' : ' padded') },
              editMode ? _npmReact.default.createElement('input', { defaultValue: propFile.name, name: 'newFileName', className: 'form-control', onChange: component.setPendingFileName, maxLength: 255, ref: component.registerEventSaveOnEnter, tabIndex: 1 }) : propFile.name
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-2 cell padded file-size' },
              prettyFileSize
            ),
            isEditable && _npmReact.default.createElement(
              'div',
              { className: 'col-xs-5 cell file-actions' },
              _npmReact.default.createElement(
                'button',
                { className: 'btn-link', type: 'button', onClick: component.save },
                editMode ? 'SAVE' : 'EDIT NAME OR EPSG'
              ),
              ' ',
              _npmReact.default.createElement('button', { className: 'btn glyphicon-full glyphicons-option-horizontal' + (actionsMenuVisible ? ' toggled' : ''), type: 'button', onClick: function onClick() {
                  component.toggleActionsMenu();
                } }),
              actionsMenuVisible && _npmReact.default.createElement(
                'div',
                { className: 'actions-menu' },
                _npmReact.default.createElement(
                  'div',
                  { className: 'link', onClick: this.downloadFile },
                  'DOWNLOAD'
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'link', onClick: function onClick() {
                      component.setState({ shareMode: true, actionsMenuVisible: false });
                    } },
                  'SHARE'
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'link', onClick: function onClick() {
                      component.setState({ deleteMode: true, actionsMenuVisible: false });
                    } },
                  'DELETE'
                )
              )
            ),
            !isEditable && _npmReact.default.createElement(
              'div',
              { className: 'col-xs-5 cell file-actions' },
              'Processing...'
            )
          ),
          _npmReact.default.createElement(
            'div',
            { className: 'row file-epsg' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-6 cell padded file-epsg-code form-inline' },
              'EPSG code:',
              editMode ? _npmReact.default.createElement('input', { type: 'number', defaultValue: file.epsgCode, placeholder: '(e.g. 2242)', name: 'newEpsg_' + file.id, className: 'form-control', onChange: component.setPendingFileEpsg, ref: this.registerEventSaveOnEnter, tabIndex: 2 }) : epsgCode
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-3 cell padded' },
              editMode && _npmReact.default.createElement(
                'a',
                { href: '#', onClick: function onClick(e) {
                    e.preventDefault();window._elev.openArticle(73);
                  }, target: '_blank' },
                '(What\'s this?)'
              )
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-3 cell padded' },
              editMode && _npmReact.default.createElement(
                'a',
                { href: 'http://spatialreference.org/ref/epsg/', target: '_blank', rel: 'noopener noreferrer' },
                'Don\'t know your code?'
              )
            )
          ),
          isWarning && _npmReact.default.createElement(
            'div',
            { className: 'row file-epsg' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-12 cell processing-status warning' },
              _npmReact.default.createElement(
                'div',
                null,
                _npmReact.default.createElement('span', { className: 'glyphicon glyphicon-info-sign' }),
                '\xA0',
                file.message && file.message.message && file.message.message
              )
            )
          ),
          isFailed && _npmReact.default.createElement(
            'div',
            { className: 'row file-epsg' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-12 cell processing-status failed' },
              _npmReact.default.createElement(
                'div',
                null,
                _npmReact.default.createElement('span', { className: 'glyphicon glyphicon-warning-sign' }),
                '\xA0',
                file.message && file.message.message && file.message.message
              )
            )
          ),
          deleteMode && _npmReact.default.createElement(
            'div',
            { className: 'row dialog dialog-delete' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-7 cell padded file-name' },
              'Are you sure you\'d like to delete',
              propFile.name,
              '?'
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-5 cell file-actions' },
              _npmReact.default.createElement(
                'button',
                { className: 'btn', type: 'button', onClick: component.deleteFile },
                'DELETE'
              ),
              ' ',
              _npmReact.default.createElement(
                'button',
                { className: 'btn nofill', type: 'button', onClick: function onClick() {
                    component.setState({ deleteMode: false });
                  } },
                'CANCEL'
              )
            )
          ),
          shareMode && _npmReact.default.createElement(
            'div',
            { className: 'row dialog dialog-share' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-11 cell file-share-link' },
              _npmReact.default.createElement('input', { type: 'text', name: 'shareLink', className: 'form-control', value: file.urlPrefix + file.fileUrl, readOnly: true, ref: FileManagerPluginDxf.copyInputToClipboard })
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-1 cell file-actions' },
              _npmReact.default.createElement('button', { className: 'btn glyphicon-full glyphicons-remove', type: 'button', onClick: function onClick() {
                  component.setState({ shareMode: false });
                } })
            )
          )
        );
      }
    }]);

    return FileManagerPluginDxf;
  }(_npmReact.default.Component);

  FileManagerPluginDxf.renderMenu = function (activeMode, _onClick) {
    return _npmReact.default.createElement(
      'div',
      { className: 'group' + (activeMode === 'dxf' ? ' active' : ''), key: 'dxf', onClick: function onClick() {
          _onClick('dxf');
        } },
      _npmReact.default.createElement(
        'h4',
        null,
        'Linework'
      ),
      _npmReact.default.createElement(
        'ul',
        null,
        _npmReact.default.createElement(
          'li',
          null,
          'Blueprints'
        )
      )
    );
  };

  FileManagerPluginDxf.fetchFiles = function (dataService, siteId) {
    return dataService.query('overlayBlueprint', { site: siteId }).then(function (result) {
      var fetched = result.sortBy('createdAt').reverse().toArray().map(function (o) {
        var file = o.toJSON();
        file.key = o.get('id');
        file.id = file.key;
        file.category = 'dxf';
        return file;
      });
      return fetched;
    });
  };
  exports.default = FileManagerPluginDxf;
});