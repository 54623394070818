define('webui/models/true-tonnage-report', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    flight: _emberData.default.belongsTo('flight'),
    trueTonnageReportStep: _emberData.default.belongsTo('true-tonnage-report-step'),
    regionInputUser: _emberData.default.belongsTo('user', { async: true }),
    plannedTonnageEntryUser: _emberData.default.belongsTo('user', { async: true }),
    plantManager: _emberData.default.belongsTo('user', { async: true }),
    engineeringManager: _emberData.default.belongsTo('user', { async: true }),
    areaManager: _emberData.default.belongsTo('user', { async: true }),
    accountingManager: _emberData.default.belongsTo('user', { async: true }),
    auditor: _emberData.default.belongsTo('user', { async: true }),
    trueTonnageReportRegions: _emberData.default.hasMany('true-tonnage-report-region', { async: false }),
    trueTonnageReportMaterials: _emberData.default.hasMany('true-tonnage-report-material', { async: false }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    activeUser: function activeUser() {
      return this.get('' + this.get('trueTonnageReportStep.activeUserField'));
    }.property('trueTonnageReportStep'),

    createdDate: function createdDate() {
      return this.dateString(this.get('createdAt'), true);
    }.property('createdAt'),

    completedDate: function completedDate() {
      return this.dateString(this.get('updatedAt'), true);
    }.property('updatedAt')
  });
});