define('webui/pods/components/site-create-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    selectedClient: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var feedback = this.get('feedback');
      var client = this.get('client');

      if (client) {
        this.set('selectedClient', client);
      } else {
        if (this.get('model.clients.length') === 1) {
          this.set('selectedClient', this.get('model.clients').objectAt(0));
        }
        if (this.get('model.clients.length') < 1) {
          feedback.reportError({ title: 'Site creation error', detail: 'There are no clients associated with this account.' });
        }
      }

      if (!this.get('site')) {
        this.set('site', {});
      }
    },


    doTransition: 'doTransition',

    actions: {
      selectClient: function selectClient(client) {
        if (client) {
          this.set('selectedClient', this.get('model.clients').findBy('id', client));
        }
      },
      createSite: function createSite(site) {
        var component = this;
        var store = this.get('store');
        var client = this.get('selectedClient');
        var feedback = this.get('feedback');

        if (client) {
          var newSite = store.createRecord('site', {
            name: site.name,
            description: site.description,
            clients: [client],
            status: 'active'
          });
          newSite.save().then(function (savedSite) {
            var dataPanelService = component.get('dataPanelService');
            dataPanelService.set('openPanelOnLoad', 'site-info-panel');
            dataPanelService.set('openModalOnLoad', 'finish-new-site-prompt');
            return component.sendAction('doTransition', 'site', savedSite.id);
          });
        } else {
          feedback.reportError({ title: 'Missing Client Association', detail: 'You must select a client for the new site' });
        }
      },
      cancelCreate: function cancelCreate(clientId) {
        if (clientId) {
          this.sendAction('doTransition', 'account.client', clientId);
        } else {
          this.sendAction('doTransition', 'account');
        }
      }
    }
  });
});