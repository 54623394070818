define('webui/pods/admin/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      if (this.get('session.data.authenticated.isAdmin') !== true) {
        // user is not admin, bouncing out of admin
        this.transitionTo('/');
      }
    }
  });
});