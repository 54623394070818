define('webui/models/true-tonnage-report-region', ['exports', 'ember-data', 'webui/mixins/string-formatting', 'webui/mixins/volume-calc-region', 'npm:polylabel'], function (exports, _emberData, _stringFormatting, _volumeCalcRegion, _npmPolylabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, _volumeCalcRegion.default, {
    trueTonnageReport: _emberData.default.belongsTo('true-tonnage-report', { async: true }),
    region: _emberData.default.attr('string'),
    material: _emberData.default.attr('string'),
    changeRequested: _emberData.default.attr('boolean'),
    changeRequestStep: _emberData.default.belongsTo('true-tonnage-report-step'),
    changeRequestTonnage: _emberData.default.attr('number'),
    changeRequestReason: _emberData.default.attr('string'),
    changeRequestFulfilledComment: _emberData.default.attr('string'),
    wasDeleted: _emberData.default.attr('boolean'),
    hideFromTrueTonnageReport: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    boundaries: _emberData.default.attr(),
    area: _emberData.default.attr('number'),
    plannedTonnage: _emberData.default.attr('number'),
    density: _emberData.default.attr('number'),
    baseplaneType: _emberData.default.attr('string', { defaultValue: 'bestfit' }),
    baseplaneElevation: _emberData.default.attr('number', { defaultValue: null }),
    cubicYardsCut: _emberData.default.attr('number'),
    cubicYardsFill: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    statusDisplay: Ember.computed('status', 'cubicYardsFill', 'region', function statusDisplay() {
      var savedStatus = this.get('status');
      return savedStatus;
    }),
    messageDisplay: Ember.computed('status', 'message', 'cubicYardsFill', 'region', function messageDisplay() {
      var message = '';

      if (this.get('message.name')) {
        message += this.get('message.name') + ': ';
      }

      if (this.get('message.message')) {
        message += this.get('message.message');
      }

      if (this.get('message.statusCode')) {
        message += '(' + this.get('message.statusCode') + ')';
      }

      return message;
    }),

    revisionDate: function revisionDate() {
      return this.dateTimeString(this.get('createdAt'));
    }.property('createdAt'),

    mapboxPointFeature: Ember.computed('name', 'revisionDate', 'boundaries', function mapboxPointFeature() {
      if (!this.get('boundaries')) return null;

      var coordinates = this.get('boundaries').map(function (point) {
        return [point.lng, point.lat];
      });

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: (0, _npmPolylabel.default)([coordinates])
        },
        properties: {
          name: this.get('name') + ' (' + this.get('revisionDate') + ')'
        }
      };
    })
  });
});