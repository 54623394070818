define('webui/pods/components/table-edit-fields/text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      this.set('value', this.get('record.' + this.get('column.propertyName')));
    },


    actions: {
      update: function update(value) {
        this.set('record.' + this.get('column.propertyName'), value);
      }
    }
  });
});