define('webui/pods/admin/user/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(user) {
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', user.id),
        allClients: this.store.query('client', {})
      });
    },
    afterModel: function afterModel(model) {
      var myClients = model.user.get('clients');
      var availableClients = model.allClients;

      myClients.forEach(function (item) {
        availableClients = availableClients.rejectBy('id', item.id);
      });

      model.availableClients = availableClients;
    }
  });
});