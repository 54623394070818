define('webui/pods/components/table-edit-fields/remove-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    init: function init() {
      var _this = this;

      this._super();

      this.set('modelID', this.get('record.id'));
      var usedByTrueTonnageReport = this.get('store').peekAll('true-tonnage-report-region').filter(function (trueTonnageReportRegion) {
        return trueTonnageReportRegion.get('trueTonnageReport.id') && trueTonnageReportRegion.get('material') === _this.get('record.id');
      }).length;
      var usedInRegion = this.get('record.regions').length;
      var isInUse = usedByTrueTonnageReport || usedInRegion;

      if (isInUse) {
        this.set('componentName', 'error-modal');
        this.set('error', 'The material \'' + this.get('record.name') + '\' cannot be deleted since it is used by a Volume Calc Region and/or a True Tonnage Report.');
      } else {
        this.set('componentName', 'confirm-with-cancel-modal');
        this.set('deleteMessage', 'Are you sure you would like to remove the material \'' + this.get('record.name') + '\'?');
      }
    },


    actions: {
      showConfirmation: function showConfirmation(modelID) {
        $('#' + modelID).modal('show');
      },
      confirmRemove: function confirmRemove(modelID) {
        $('#' + modelID).modal('hide');

        var removeRowAction = this.get('removeRowAction');
        removeRowAction(this.get('record'));
      }
    }
  });
});