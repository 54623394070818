define('webui/pods/components/field-panel-measurements/component', ['exports', 'webui/mixins/numerical-conversion', 'webui/mixins/map-layer-formatting', 'ember-cli-segment/mixin'], function (exports, _numericalConversion, _mapLayerFormatting, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GEOAPI = window.turf;
  var PolygonMeasurementTypes = ['2DArea', '3DArea', 'VolumeCalc', '3DQuantity'];

  exports.default = Ember.Component.extend(_mapLayerFormatting.default, _numericalConversion.default, _mixin.default, {
    feedback: Ember.inject.service('user-feedback'),
    mapData: Ember.inject.service('mapData'),
    store: Ember.inject.service('store'),

    siteGrades: 'siteGrades',
    flights: 'flights',
    workActivity: 'workActivity',
    isEditingDensity: false,

    measurementTypesForSelect: [{ type: '2DArea', displayType: 'Area' }, { type: '2DLine', displayType: 'Distance' }, { type: '3DQuantity', displayType: 'Quantity' }, { type: '3DArea', displayType: 'Surface Area' }, { type: '3DLine', displayType: 'Surface Distance' }, { type: 'VolumeCalc', displayType: 'Volume Calc' }],
    measurementDrawTypes: {
      '2DLine': 'work_activity_2d_line',
      '3DLine': 'work_activity_3d_line',
      '2DArea': 'work_activity_2d_area',
      '3DArea': 'work_activity_3d_area',
      VolumeCalc: 'work_activity_volume_calc',
      '3DQuantity': 'work_activity_3d_quantity'
    },
    closePanel: 'closePanel',
    regions: [],
    isDrawing: false,
    step: 2,

    init: function init() {
      this._super();
      this.createWorkActivityWithDefaultValues();

      var component = this;
      this.setupSocketOnHandler();
      this.addRegionLabelLayer();
      Ember.run.next(function () {
        component.setListeners();
      });
    },
    didInsertElement: function didInsertElement() {
      var component = this;
      Ember.run.next(function () {
        setTimeout(function () {
          return component.turnOnDrawMode();
        }, 500);
      });
    },
    createWorkActivityWithDefaultValues: function createWorkActivityWithDefaultValues() {
      var measurementType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'VolumeCalc';

      var defaultId = void 0;var defaultType = void 0;
      if (this.get('siteGrades').length > 0) {
        defaultId = this.get('siteGrades')[0].id;
        defaultType = 'Blueprint';
      } else if (this.get('flights').length > 0) {
        defaultId = this.get('flights')[0].get('dsmId');
        defaultType = 'Product';
      }

      var unsavedWorkActivities = this.get('store').peekAll('workActivity').filter(function (workActivity) {
        return workActivity.id === null;
      });
      unsavedWorkActivities.forEach(function (workActivity) {
        return workActivity.destroyRecord();
      });

      var workActivity = this.get('store').createRecord('work-activity', {
        measurementType: measurementType,
        featureType: 'Polygon',
        featureGeometry: [],
        flight: this.get('flight'),
        site: this.get('site'),
        surface1: defaultId,
        surface2: this.get('flight.dsmId') ? this.get('flight.dsmId') : defaultId,
        surface1Type: defaultType,
        surface2Type: this.get('flight.dsmId') ? 'Product' : defaultType,
        baseplaneType: 'bestfit',
        baseplaneElevation: null,
        resultType: 'Net',
        result: [],
        editMode: true,
        typeIsNew: true,
        status: 'pending'
      });

      this.set('workActivity', workActivity);
    },
    setupSocketOnHandler: function setupSocketOnHandler() {
      var context = this;

      io.socket.on('workActivity', function (event) {
        if (event.verb === 'updated') {
          context.get('workActivity').set('status', event.data.status);
          context.get('workActivity').set('baseplaneElevation', event.data.baseplaneElevation);
          context.get('workActivity').set('result', event.data.result);
          context.get('workActivity').set('message', event.data.message);
          context.updateRegionLabels();
        }
      });
    },
    turnOffDrawMode: function turnOffDrawMode() {
      this.set('isDrawing', false);
      this.get('mapData').changeDrawingMode('static');
    },
    turnOnDrawMode: function turnOnDrawMode() {
      var mapData = this.get('mapData');
      var drawingMode = this.get('measurementDrawTypes.' + this.get('workActivity.measurementType'));

      if (!this.isDestroyed) {
        this.set('isDrawing', true);
        mapData.changeDrawingMode(drawingMode);
      }
    },
    setListeners: function setListeners() {
      var context = this;
      var mapData = this.get('mapData');
      var qualifier = 'work-activity';

      if (!mapData.hasListeners(qualifier)) {
        mapData.addListener('update', qualifier, function () {
          return context.setFeatureGeometry();
        });
        mapData.addListener('render', qualifier, function () {
          return context.updateShowUndoButtonProperty();
        });
        mapData.addListener('modechange', qualifier, function (mode) {
          if (mode.mode === 'simple_select') {
            mapData.changeDrawingMode(mapData.get('currentDrawingMode'));
          }
        });
      }
    },
    updateShowUndoButtonProperty: function updateShowUndoButtonProperty() {
      var drawingManager = this.get('mapData.drawingManager');
      var hasUserDrawn = drawingManager.getAll().features.reduce(function (current, next) {
        return current || next.geometry.coordinates[0] && next.geometry.coordinates[0][0];
      }, false);

      if (!this.isDestroyed) {
        this.set('showUndoButton', hasUserDrawn);
      }
    },
    removeWorkActivityResults: function removeWorkActivityResults() {
      var workActivity = this.get('workActivity');

      workActivity.set('result', []);
    },
    setFeatureGeometry: function setFeatureGeometry() {
      var workActivity = this.get('workActivity');
      var features = this.get('mapData.drawingManager').getAll();
      features.features = features.features.filter(function (feature) {
        return !feature.properties.isLocked;
      });

      workActivity.set('featureGeometry', this.extractPoints(features));
    },
    extractPoints: function extractPoints(featureGeometry) {
      return featureGeometry.features.map(function (feature) {
        if (Array.isArray(feature.geometry.coordinates[0][0])) {
          return feature.geometry.coordinates[0].map(function (coords) {
            return { lng: coords[0], lat: coords[1] };
          });
        }

        return feature.geometry.coordinates.map(function (coords) {
          return { lng: coords[0], lat: coords[1] };
        });
      });
    },
    save: function save() {
      var _this = this;

      var context = this;
      var feedback = this.get('feedback');
      var mapData = this.get('mapData');
      var store = this.get('store');
      var tempWorkActivity = this.get('workActivity');
      var measurementType = tempWorkActivity.get('measurementType');
      var featureType = PolygonMeasurementTypes.indexOf(measurementType) !== -1 ? 'Polygon' : 'LineString';

      this.setFeatureGeometry();

      var workActivity = this.get('store').createRecord('work-activity', {
        activityType: '',
        measurementType: measurementType,
        featureType: featureType,
        featureGeometry: tempWorkActivity.get('featureGeometry'),
        flight: this.get('flight'),
        site: this.get('site'),
        surface1: tempWorkActivity.get('surface1'),
        surface2: tempWorkActivity.get('surface2'),
        surface1Type: tempWorkActivity.get('surface1Type'),
        surface2Type: tempWorkActivity.get('surface2Type'),
        resultType: tempWorkActivity.get('resultType'),
        baseplaneType: tempWorkActivity.get('baseplaneType'),
        baseplaneElevation: tempWorkActivity.get('baseplaneElevation'),
        result: [],
        status: 'pending'
      });

      if (measurementType === '2DLine') {
        var result = workActivity.get('featureGeometry').map(function (currentValue) {
          return { Distance: mapData.calculateLength(GEOAPI.lineString(currentValue.map(mapData.sanitizePoints))) };
        });

        workActivity.set('resultType', 'Distance');
        workActivity.set('result', result);
        workActivity.set('surface1Type', 'None');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', null);
        workActivity.set('surface2', null);
        workActivity.set('status', 'succeeded');
      }

      if (measurementType === '3DLine') {
        if (!workActivity.get('surface1')) {
          workActivity.set('status', 'failed');
          workActivity.set('message', { name: 'Surface Missing', message: 'Calculation cannot be run because there is no available surface for this flight.' });
        }

        workActivity.set('resultType', 'Surface Distance');
        workActivity.set('surface1Type', 'Product');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', this.get('workActivity.flight.dsmId'));
        workActivity.set('surface2', null);
      }

      if (measurementType === '2DArea') {
        var _result = workActivity.get('featureGeometry').map(function (currentValue) {
          return { Area: _this.squareMetersToSquareFeet(mapData.calculateAreaFromGeoJSON(GEOAPI.polygon([currentValue.map(mapData.sanitizePoints)]))) };
        });

        workActivity.set('resultType', 'Area');
        workActivity.set('result', _result);
        workActivity.set('surface1Type', 'None');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', null);
        workActivity.set('surface2', null);
        workActivity.set('status', 'succeeded');
      }

      if (measurementType === '3DArea') {
        if (!workActivity.get('surface1')) {
          workActivity.set('status', 'failed');
          workActivity.set('message', { name: 'Surface Missing', message: 'Calculation cannot be run because there is no available surface for this flight.' });
        }

        workActivity.set('resultType', 'Surface Area');
        workActivity.set('surface1Type', 'Product');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', this.get('workActivity.flight.dsmId'));
        workActivity.set('surface2', null);
      }

      if (measurementType === 'VolumeCalc') {
        if (!workActivity.get('surface1')) {
          workActivity.set('status', 'failed');
          workActivity.set('message', { name: 'Surface Missing', message: 'Calculation cannot be run because there is no available surface for this flight.' });
        }

        workActivity.set('surface1Type', 'Product');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', this.get('workActivity.flight.dsmId'));
        workActivity.set('surface2', null);

        if (!workActivity.get('baseplaneElevation')) {
          workActivity.set('baseplaneElevation', 0);
        }
      }

      if (measurementType === '3DQuantity') {
        if (!workActivity.get('surface1') || !workActivity.get('surface2')) {
          workActivity.set('status', 'failed');
          workActivity.set('message', { name: 'Surface Missing', message: 'Calculation cannot be run because there are no available surfaces for this site.' });
        }

        workActivity.set('surface1Type', store.peekRecord('blueprint', workActivity.get('surface1')) ? 'Blueprint' : 'Product');
        workActivity.set('surface2Type', store.peekRecord('blueprint', workActivity.get('surface2')) ? 'Blueprint' : 'Product');
      }

      this.set('workActivity', workActivity);
      if (workActivity.get('status') !== 'failed') {
        if (measurementType === '2DLine' || measurementType === '2DArea') {
          this.updateRegionLabels();
        } else {
          io.socket.post('/v1/field/workActivities', { workActivity: workActivity.toJSON() }, function (res) {
            if (res.workActivity) {
              context.get('workActivity').set('status', res.workActivity.status);
              context.get('workActivity').set('result', res.workActivity.result);
              context.get('workActivity').set('message', res.workActivity.message);
            } else {
              feedback.reportError(res);
            }
          });
        }
      }

      this.trackEvent('Field Measurement Created', { flight: this.get('workActivity.flight.id'), measurementType: this.get('workActivity.displayMeasurementType') });
    },
    addRegionLabelLayer: function addRegionLabelLayer() {
      this.get('mapData').mapInstance.addLayer(this.getRegionLabelLayerProperties({ id: 'region-labels', source: 'draw-source' }));
    },
    updateRegionLabels: function updateRegionLabels() {
      this.get('mapData').getDrawSource().setData({ type: 'FeatureCollection', features: this.get('workActivity.mapboxPointFeature') });
    },
    willDestroyElement: function willDestroyElement() {
      this.cleanUpRegionUI();
      this.get('mapData').deleteListenersForQualifier('work-activity');
      this.get('mapData.mapInstance').removeLayer('region-labels');
      io.socket.off('workActivity');
    },
    cleanUpRegionUI: function cleanUpRegionUI() {
      var mapData = this.get('mapData');
      var context = this;

      try {
        this.get('workActivity').removeObserver('mapboxPointFeature', this, function () {
          context.updateRegionLabels();
        });
      } catch (e) {
        /* Pass through */
      }

      mapData.getDrawSource().setData({ type: 'FeatureCollection', features: [] });
      mapData.cleanUp();
    },


    actions: {
      closePanel: function closePanel() {
        this.sendAction('closePanel');
      },
      changeMeasurementType: function changeMeasurementType(measurementType) {
        this.set('regions', []);
        this.createWorkActivityWithDefaultValues(measurementType);
        this.cleanUpRegionUI();
      },
      changeStep: function changeStep(step) {
        var context = this;
        this.set('step', step);

        if (step === 2) {
          Ember.run.next(function () {
            context.turnOnDrawMode();
          });
        } else if (this.get('isDrawing')) {
          this.turnOffDrawMode();
        }

        if (step === 3) {
          this.save();
        }
      },
      deleteRegion: function deleteRegion() {
        var drawingManager = this.get('mapData.drawingManager');
        var features = drawingManager.getAll().features.filter(function (feature) {
          return !feature.properties.isLocked;
        });

        if (features.length > 0) {
          features.map(function (_ref) {
            var id = _ref.id;
            return drawingManager.delete(id);
          });
          this.removeWorkActivityResults();
          this.setFeatureGeometry();
          this.updateRegionLabels();
          this.turnOnDrawMode();
        }
      },
      toggleEditDensity: function toggleEditDensity() {
        this.toggleProperty('isEditingDensity');
      },
      handleEnter: function handleEnter() {
        this.set('isEditingDensity', false);
      }
    }
  });
});