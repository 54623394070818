define('webui/pods/components/true-tonnage-report/true-tonnage-chart/component', ['exports', 'npm:chartist', 'webui/mixins/string-formatting', 'webui/mixins/true-tonnage-chart-data', 'npm:chartist-plugin-tooltips', 'npm:chartist-plugin-legend'], function (exports, _npmChartist, _stringFormatting, _trueTonnageChartData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_stringFormatting.default, _trueTonnageChartData.default, {
    store: Ember.inject.service('store'),
    pdfOptions: Ember.inject.service('pdf-options'),
    showGraph: false,
    labels: [],
    expectedTonnages: [],
    actualTonnages: [],
    materials: [],
    selectedMaterialId: null,
    showFlightDates: false,
    allFlights: null,
    selFirstFlightDate: null,
    isBlankSelected: null,
    feedback: Ember.inject.service('user-feedback'),
    dataPanelService: Ember.inject.service('data-panel'),

    init: function init() {
      var _this = this;

      this._super();
      var flightsWithReports = this.getFlightsWithTrueTonnageReport(this.get('siteId'));
      if (flightsWithReports.length < 2) {
        this.set('showGraph', false);
      }

      var store = this.get('store');
      this.set('flightDates', []);

      var flightsAtSite = store.peekAll('flight').filter(function (flight) {
        return flight.get('site.id') === _this.get('siteId');
      }).sortBy('executeAt');
      // const orderedFlights = flightsAtSite.sortBy('executeAt').reverse();
      this.set('flightsForSelection', flightsAtSite);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('shouldRefreshChart')) {
        this.set('shouldRefreshChart', false);
      }
      this.set('materials', this.getMaterials());
      var materials = this.getMaterials();
      this.set('materials', materials);
      this.get('pdfOptions').setMaterial(materials);
    },
    didRender: function didRender() {
      if (this.get('showGraph')) {
        this.setChartData(this.get('selectedMaterialId'));
        this.clearChart();
        this.renderChart();
      }
      if (this.get('showFlightDates')) {
        this.showFlightDates = true;
      }
    },
    setChartData: function setChartData() {
      var materialId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      this.get('pdfOptions').set('excludedMaterialsForTrueTonnageChart', this.get('excludedMaterials'));
      this.get('pdfOptions').set('excludedRegionsForTrueTonnageChart', this.get('excludedRegions'));
      this.get('pdfOptions').set('selectedMaterialForTrueTonnageChart', materialId);

      var _getTrueTonnageChartD = this.getTrueTonnageChartData(this.get('excludedMaterials'), this.get('excludedRegions'), this.get('siteId'), materialId),
          labels = _getTrueTonnageChartD.labels,
          expectedTonnages = _getTrueTonnageChartD.expectedTonnages,
          actualTonnages = _getTrueTonnageChartD.actualTonnages;

      this.set('labels', labels);
      this.set('expectedTonnages', expectedTonnages);
      this.set('actualTonnages', actualTonnages);
    },
    renderChart: function renderChart() {
      function SuppressForeignObjectPlugin(chart) {
        chart.supportsForeignObject = false;
      }
      var labels = this.get('labels');
      var series = [{
        name: 'On Hand Inventory',
        data: this.get('expectedTonnages')
      }, {
        name: 'True Tonnage',
        data: this.get('actualTonnages')
      }];

      var data = { labels: labels, series: series };
      var options = {
        // fullWidth: true,
        axisX: {
          labelOffset: {
            x: 0,
            y: 5
          }
        },
        axisY: {
          labelInterpolationFnc: function labelInterpolationFnc(text) {
            return text + ' tons';
          },
          offset: 75
        },
        plugins: [SuppressForeignObjectPlugin, _npmChartist.default.plugins.legend({
          position: document.getElementById('legend')
        }), _npmChartist.default.plugins.tooltip({
          appendToBody: true,
          class: 'id-tooltip',
          transformTooltipTextFnc: function transformTooltipTextFnc(text) {
            return parseFloat(text).toFixed(2) + ' tons';
          }
        })]
      };

      _npmChartist.default.Line('#true-tonnage-chart', data, options);
    },
    clearChart: function clearChart() {
      $('#true-tonnage-chart').empty();
      $('#legend').empty();
    },
    getMaterials: function getMaterials() {
      var _this2 = this;

      var flightsWithTrueTonnageReport = this.getFlightsWithTrueTonnageReport(this.get('siteId'));
      var uniqueMaterials = {};
      var regionLists = flightsWithTrueTonnageReport.map(function (flight) {
        return flight.get('trueTonnageReport.trueTonnageReportRegions');
      });

      regionLists.forEach(function (regionList) {
        regionList.forEach(function (region) {
          if (region.get('material')) uniqueMaterials[region.get('material')] = _this2.get('store').peekRecord('material', region.get('material'));
        });
      });

      return Object.keys(uniqueMaterials).map(function (key) {
        return uniqueMaterials[key];
      });
    },


    actions: {
      hideGraph: function hideGraph() {
        this.getShowFlightDates();
        this.set('showGraph', false);
        this.set('pdfOptions.trueTonnageGraphInExport', false);
        Ember.$('#hide-elem').attr('style', 'display: inline-block !important; width: fit-content; padding-right: 10px;');
        Ember.$('#hide-element').attr('style', 'display: inline-block !important; width: fit-content;');
      },
      showGraph: function showGraph() {
        this.getShowFlightDates();
        this.set('showGraph', true);
        this.set('pdfOptions.trueTonnageGraphInExport', true);
        Ember.$('#hide-elem').attr('style', 'display: inline-block !important; width: fit-content; padding-right: 10px;');
        Ember.$('#hide-element').attr('style', 'display: inline-block !important; width: fit-content;');
      },
      setMaterial: function setMaterial(materialId) {
        this.clearChart();
        if (materialId === 'Total') {
          this.set('selectedMaterialId', null);
          this.get('pdfOptions').set('selectedMaterialsForTrueTonnageChart', null);
          this.setChartData();
        } else {
          this.set('selectedMaterialId', materialId);
          this.get('pdfOptions').set('selectedMaterialForTrueTonnageChart', materialId);
          this.setChartData(materialId);
        }
        this.renderChart();
      },
      handleFirstFlight: function handleFirstFlight(firstFlight) {
        this.get('dataPanelService').set('selectedFirstFlight', firstFlight);
      },
      handleLastFlight: function handleLastFlight(lastFlight) {
        this.get('dataPanelService').set('selectedLastFlight', lastFlight);
      }
    }
  });
});