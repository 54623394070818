define('webui/pods/components/on-hand-inventory-import-field-selection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    cancelImport: 'cancelImport',
    copyImportDataIntoPlannedTonnageField: 'copyImportDataIntoPlannedTonnageField',

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('displayFieldSelectModal')) {
        this.set('materialCodeColumnName', this.get('importedColumnNames')[0]);
        this.set('onHandInventoryColumnName', this.get('importedColumnNames')[0]);
      }
    },
    selectRowForUnmatched: function selectRowForUnmatched(_ref) {
      var _this = this;

      var materialId = _ref.materialId,
          userSelection = _ref.userSelection;

      var unmatchedMaterials = this.get('unmatchedMaterials');

      var updatedMaterials = unmatchedMaterials.map(function (material) {
        var updatedMaterial = _extends({}, material);

        if (material.materialId === materialId) {
          updatedMaterial.userSelection = parseInt(userSelection, 10);
          updatedMaterial.plannedTonnage = _this.get('importedMaterials.' + updatedMaterial.userSelection + '.' + _this.get('importedColumnMap.' + _this.get('onHandInventoryColumnName')));
        }

        return updatedMaterial;
      });

      this.set('unmatchedMaterials', updatedMaterials);
    },
    resetForm: function resetForm() {
      this.set('materialCodeColumnName', null);
      this.set('onHandInventoryColumnName', null);
      this.set('unmatchedMaterials', null);
      this.set('unmatchedMaterialsExist', null);
    },
    resetFormToInitialState: function resetFormToInitialState() {
      this.set('unmatchedMaterials', null);
      this.set('unmatchedMaterialsExist', null);
    },


    actions: {
      setMaterialCodeColumn: function setMaterialCodeColumn(columnName) {
        this.set('materialCodeColumnName', columnName);
        this.resetFormToInitialState();
      },
      setOnHandInventoryColumn: function setOnHandInventoryColumn(columnName) {
        this.set('onHandInventoryColumnName', columnName);
      },
      cancelImport: function cancelImport() {
        this.sendAction('cancelImport');
        this.resetForm();
      },
      selectRowForUnmatched: function selectRowForUnmatched(materialId, userSelection) {
        this.selectRowForUnmatched({ materialId: materialId, userSelection: userSelection });
      },
      importWithAutomaticMaterialMatching: function importWithAutomaticMaterialMatching() {
        var _this2 = this;

        var materialCodeColumnName = this.get('materialCodeColumnName');
        var onHandInventoryColumnName = this.get('onHandInventoryColumnName');
        var materialCodeColumnKey = this.get('importedColumnMap.' + materialCodeColumnName);
        var onHandInventoryColumnKey = this.get('importedColumnMap.' + onHandInventoryColumnName);
        var importData = this.get('importedMaterials').map(function (materialRow, index) {
          return {
            rowNumber: index,
            materialCode: materialRow[materialCodeColumnKey],
            onHandInventory: materialRow[onHandInventoryColumnKey]
          };
        }).filter(function (_ref2) {
          var materialCode = _ref2.materialCode;
          return materialCode;
        });

        var unmatchedMaterials = [];
        this.get('reportData').filter(function (_ref3) {
          var regionData = _ref3.regionData;
          return regionData && regionData.length > 0;
        }).forEach(function (_ref4) {
          var materialId = _ref4.materialId;

          if (materialId) {
            var materialKey = _this2.get('store').peekRecord('material', materialId).get('key');
            var materialName = _this2.get('store').peekRecord('material', materialId).get('name');
            var matchingRows = importData.filter(function (_ref5) {
              var materialCode = _ref5.materialCode;
              return materialCode && materialCode.toString().indexOf(materialKey) !== -1;
            });
            var matched = matchingRows.length === 1;
            var options = matchingRows.length === 0 ? [].concat(_toConsumableArray(importData)) : [].concat(_toConsumableArray(matchingRows));
            options.unshift({ materialCode: 'Do Not Include', onHandInventory: 0, rowNumber: -1 });
            var userSelection = matched ? matchingRows[0].rowNumber : options[0].rowNumber;
            var plannedTonnage = _this2.get('importedMaterials.' + userSelection + '.' + onHandInventoryColumnKey);
            unmatchedMaterials.push({ matched: matched, materialId: materialId, materialKey: materialKey, options: options, materialName: materialName, userSelection: userSelection, plannedTonnage: plannedTonnage });
          }
        });

        var unmatchedMaterialsExist = unmatchedMaterials.filter(function (_ref6) {
          var matched = _ref6.matched;
          return !matched;
        }).length;
        if (unmatchedMaterialsExist) {
          this.set('unmatchedMaterials', unmatchedMaterials);
          this.set('unmatchedMaterialsExist', true);
        } else {
          this.sendAction('copyImportDataIntoPlannedTonnageField', unmatchedMaterials);
          this.resetForm();
        }

        return null;
      },
      importWithManuallySelectedMaterials: function importWithManuallySelectedMaterials() {
        this.sendAction('copyImportDataIntoPlannedTonnageField', this.get('unmatchedMaterials'));
        this.resetForm();
      }
    }
  });
});