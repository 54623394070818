define('webui/mixins/filter-earthworks-by-blueprint', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    filterEarthworksByBlueprint: function filterEarthworksByBlueprint(earthworks, blueprint) {
      return earthworks.sortBy('executeAt').filter(function (e) {
        return e.get('blueprint.id') === blueprint;
      });
    }
  });
});