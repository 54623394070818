define('webui/pods/components/create-map-step-gcp-tagging/component', ['exports', 'npm:proj4', 'webui/mixins/numerical-conversion'], function (exports, _npmProj, _numericalConversion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_numericalConversion.default, {
    turf: window.turf,
    feedback: Ember.inject.service('user-feedback'),
    session: Ember.inject.service('session'),
    fileManager: Ember.inject.service('file-manager'),
    statePlane: Ember.inject.service('state-plane'),
    store: Ember.inject.service('store'),
    showGCPProcessingError: false,

    wizardData: null, /* object created in create-map-wizard */
    wizard: null, /* this is passed via the template */
    accuracyMap: null,

    init: function init() {
      this._super();

      var epsgDict = this.get('statePlane').getAllEpsgCodes();
      this.set('epsgToName', epsgDict);
      this.set('epsgOptions', Object.keys(epsgDict));
    },
    loadGCPFile: function loadGCPFile(onLoad) {
      var component = this;
      var file = this.wizardData.get('gcpFile');

      if (file) {
        var reader = new FileReader();
        reader.addEventListener('load', function () {
          var gcps = reader.result.split(/[\r\n]+/).map(function (rowString) {
            var row = rowString.split(',');

            return { id: row[0], easting: row[1], northing: row[2], elevation: row[3] };
          });

          var gcpsWithoutSmartTarget = gcps.filter(function (gcp) {
            return gcp.id.toLowerCase() !== 'st';
          });
          var firstRowIsHeader = isNaN(gcpsWithoutSmartTarget[0].easting);
          if (firstRowIsHeader) gcpsWithoutSmartTarget.shift();
          component.set('gcpsFromFile', gcpsWithoutSmartTarget);
          onLoad();
        });
        reader.readAsText(file);
      }
    },
    setupEPSGCode: function setupEPSGCode() {
      if (this.get('wizardData.selectedEpsgCode')) return;
      this.set('wizardData.selectedEpsgCode', 2272);

      var photoMeta = this.wizardData.get('photoMeta');
      if (photoMeta[0]) {
        var statePlane = this.get('statePlane').latLonToStatePlane(photoMeta[0].GpsLatitude, photoMeta[0].GpsLongitude);
        if (statePlane) {
          this.set('wizardData.selectedEpsgCode', statePlane.properties.defaultEPSG);
        }
      }
    },
    setupGCPData: function setupGCPData() {
      var component = this;
      var gcpsFromFile = component.get('gcpsFromFile');
      var turf = component.get('turf');
      var photoMeta = this.wizardData.get('photoMeta');
      var GCPsForPhotos = new Map();
      var gcpData = [];

      try {
        if (photoMeta[0]) {
          // 'wizardData.selectedEpsgCode'
          var statePlane = component.get('statePlane').getStatePlaneFromEPSGCode(component.get('wizardData.selectedEpsgCode'));
          if (gcpsFromFile.length > 0) {
            gcpsFromFile.forEach(function (gcp) {
              if (gcp.easting && gcp.northing) {
                var coordinates = [parseFloat(gcp.easting), parseFloat(gcp.northing)];
                var fromProjection = statePlane.properties.defaultProjection;
                var toProjection = '+proj=longlat +datum=WGS84 +no_defs ';
                var gcpPoint = turf.point((0, _npmProj.default)(fromProjection, toProjection, coordinates));

                var photosToCheck = [];
                photoMeta.forEach(function (photo) {
                  var photoPoint = turf.point([photo.GpsLongitude, photo.GpsLatitude]);
                  if (turf.distance(photoPoint, gcpPoint, { units: 'feet' }) <= 250) {
                    photosToCheck.push(photo);
                    if (!GCPsForPhotos.has(photo.fileName)) GCPsForPhotos.set(photo.fileName, []);
                    GCPsForPhotos.set(photo.fileName, [].concat(_toConsumableArray(GCPsForPhotos.get(photo.fileName)), [gcp.id]));
                  }
                });

                gcpData.push({
                  id: gcp.id,
                  original: gcp,
                  longitude: gcpPoint.geometry.coordinates[0],
                  latitude: gcpPoint.geometry.coordinates[1],
                  point: gcpPoint,
                  photosToCheck: photosToCheck
                });
              }
            });

            if (GCPsForPhotos.size === 0) {
              // if photo dont align with GCPs, maybe the user flipped easting/northing
              gcpData = [];
              gcpsFromFile.forEach(function (gcp) {
                if (gcp.easting && gcp.northing) {
                  var coordinates = [parseFloat(gcp.northing), parseFloat(gcp.easting)];
                  var fromProjection = statePlane.properties.defaultProjection;
                  var toProjection = '+proj=longlat +datum=WGS84 +no_defs ';
                  var gcpPoint = turf.point((0, _npmProj.default)(fromProjection, toProjection, coordinates));

                  var photosToCheck = [];
                  photoMeta.forEach(function (photo) {
                    var photoPoint = turf.point([photo.GpsLongitude, photo.GpsLatitude]);
                    if (turf.distance(photoPoint, gcpPoint, { units: 'feet' }) <= 250) {
                      photosToCheck.push(photo);
                      if (!GCPsForPhotos.has(photo.fileName)) GCPsForPhotos.set(photo.fileName, []);
                      GCPsForPhotos.set(photo.fileName, [].concat(_toConsumableArray(GCPsForPhotos.get(photo.fileName)), [gcp.id]));
                    }
                  });

                  gcpData.push({
                    id: gcp.id,
                    original: gcp,
                    longitude: gcpPoint.geometry.coordinates[0],
                    latitude: gcpPoint.geometry.coordinates[1],
                    point: gcpPoint,
                    photosToCheck: photosToCheck
                  });
                }
              });
            }

            if (GCPsForPhotos.size === 0) {
              // if we tried both combinations of northing/easting and photo still don't align, lets show all the photos and they can select the gcp for each tag
              gcpData = [];
              gcpsFromFile.forEach(function (gcp) {
                if (gcp.easting && gcp.northing) {
                  var coordinates = [parseFloat(gcp.easting), parseFloat(gcp.northing)];
                  var fromProjection = statePlane.properties.defaultProjection;
                  var toProjection = '+proj=longlat +datum=WGS84 +no_defs ';
                  var gcpPoint = turf.point((0, _npmProj.default)(fromProjection, toProjection, coordinates));

                  var photosToCheck = [];
                  photoMeta.forEach(function (photo) {
                    photosToCheck.push(photo);
                    if (!GCPsForPhotos.has(photo.fileName)) GCPsForPhotos.set(photo.fileName, []);
                    GCPsForPhotos.set(photo.fileName, [].concat(_toConsumableArray(GCPsForPhotos.get(photo.fileName)), [gcp.id]));
                  });

                  gcpData.push({
                    id: gcp.id,
                    original: gcp,
                    longitude: gcpPoint.geometry.coordinates[0],
                    latitude: gcpPoint.geometry.coordinates[1],
                    point: gcpPoint,
                    photosToCheck: photosToCheck
                  });
                }
              });
            }
          }
          component.set('GCPsForPhotos', GCPsForPhotos);
          component.set('gcpData', gcpData);
        }
      } catch (err) {
        component.set('showGCPProcessingError', true);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var component = this;
      this.loadGCPFile(function () {
        component.setupEPSGCode();
        component.setupGCPData();
        if (!_this.get('showGCPProcessingError')) component.showMap();
      });
    },
    getPhotoMetaByName: function getPhotoMetaByName(fileName) {
      return this.wizardData.get('photoMeta').filter(function (photo) {
        return photo.fileName === fileName;
      })[0];
    },
    getPhotoByName: function getPhotoByName(fileName) {
      return this.wizardData.get('photos').filter(function (photo) {
        return photo.name === fileName;
      })[0];
    },
    setGCPTarget: function setGCPTarget(_ref) {
      var clickedX = _ref.clickedX,
          clickedY = _ref.clickedY;

      var gcpID = this.get('currentGCP');
      var fileName = this.get('fileNameOfCurrentPhoto');
      var currentTags = this.getPhotoMetaByName(fileName).taggedGCPs;
      var updatedTags = currentTags.filter(function (taggedGCP) {
        return taggedGCP.id !== gcpID;
      });
      updatedTags.push({ id: gcpID, x: clickedX, y: clickedY });

      this.getPhotoMetaByName(fileName).taggedGCPs = updatedTags;
    },
    displayPins: function displayPins() {
      var displayedPhotos = new Set();
      var component = this;
      var features = [];
      component.get('gcpData').forEach(function (gcp) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [gcp.longitude, gcp.latitude]
          },
          properties: {
            title: gcp.id,
            type: 'gcp',
            icon: 'rifle-scope',
            lat: gcp.latitude,
            lng: gcp.longitude,
            'icon-allow-overlap': true
          }
        });

        gcp.photosToCheck.forEach(function (photo) {
          if (!displayedPhotos.has(photo.fileName)) {
            features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [photo.GpsLongitude, photo.GpsLatitude]
              },
              properties: {
                gcp: gcp.id,
                fileName: photo.fileName,
                lat: photo.GpsLatitude,
                lng: photo.GpsLongitude,
                icon: Object.keys(photo.taggedGCPs).length ? 'green-circle' : 'yellow-circle',
                height: photo.height,
                width: photo.width,
                'icon-allow-overlap': true,
                type: 'photo'
              }
            });
            displayedPhotos.add(photo.fileName);
          }
        });
      });

      if (this.get('accuracyMap').getLayer('upload-photos')) {
        this.get('accuracyMap').removeLayer('upload-photos');
        this.get('accuracyMap').removeSource('upload-photos');
      }

      this.get('accuracyMap').addLayer({
        id: 'upload-photos',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: features
          }
        },
        layout: {
          'icon-image': '{icon}',
          'icon-size': 1,
          'icon-allow-overlap': true,
          'text-allow-overlap': true
        }
      });
    },
    showMap: function showMap() {
      var _this2 = this;

      var component = this;
      var photoMeta = this.wizardData.get('photoMeta');

      if (photoMeta[0] && $('#accuracy-map').length) {
        var averageLng = photoMeta.reduce(function (sum, photo) {
          return sum + parseFloat(photo.GpsLongitude);
        }, 0) / photoMeta.length;
        var averageLat = photoMeta.reduce(function (sum, photo) {
          return sum + parseFloat(photo.GpsLatitude);
        }, 0) / photoMeta.length;

        var accuracyMap = new window.mapboxgl.Map({
          container: 'accuracy-map',
          style: 'mapbox://styles/mapbox/streets-v9',
          center: [averageLng, averageLat],
          zoom: 15
        });

        accuracyMap.addControl(new window.mapboxgl.ScaleControl({
          maxWidth: 150,
          unit: 'imperial'
        }), 'bottom-right');

        component.set('accuracyMap', accuracyMap);
        accuracyMap.on('load', function () {
          component.displayPins();

          accuracyMap.on('mouseenter', 'upload-photos', function () {
            accuracyMap.getCanvas().style.cursor = 'pointer';
          });

          var popup = new window.mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          accuracyMap.on('mousemove', 'upload-photos', function (e) {
            var turf = component.get('turf');
            var hoveredPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
            var featuresOfHover = accuracyMap.queryRenderedFeatures(e.point);

            var shortestDistance = Infinity;
            var closestGroundControlPointFeature = void 0;
            for (var _i = 0; _i < featuresOfHover.length; _i += 1) {
              if (featuresOfHover[_i].source === 'upload-photos' && featuresOfHover[_i].properties.type === 'gcp') {
                var currentPoint = turf.point([featuresOfHover[_i].properties.lng, featuresOfHover[_i].properties.lat]);
                var currentDistance = turf.distance(hoveredPoint, currentPoint, { units: 'feet' });
                if (currentDistance < shortestDistance) {
                  shortestDistance = currentDistance;
                  closestGroundControlPointFeature = featuresOfHover[_i];
                }
              }
            }

            if (closestGroundControlPointFeature) {
              var coordinates = closestGroundControlPointFeature.geometry.coordinates.slice();
              var _closestGroundControl = closestGroundControlPointFeature.properties,
                  title = _closestGroundControl.title,
                  lat = _closestGroundControl.lat,
                  lng = _closestGroundControl.lng;


              var numberOfTags = 0;
              _this2.get('wizardData.photoMeta').forEach(function (photo) {
                photo.taggedGCPs.forEach(function (tag) {
                  if (tag.id === title) numberOfTags += 1;
                });
              });

              var content = document.createElement('div');
              content.innerHTML = '<div>\n              <div>Ground Control Point: ' + title + '</div>\n              <div>Latitude: ' + lat + '</div>\n              <div>Longitude: ' + lng + '</div>\n              <div>' + numberOfTags + ' / 10 Photos Tagged</div>\n            </div>';

              popup.setLngLat(coordinates).setDOMContent(content).addTo(accuracyMap);
            } else {
              popup.remove();
            }
          });

          accuracyMap.on('mouseleave', 'upload-photos', function () {
            accuracyMap.getCanvas().style.cursor = '';
            popup.remove();
          });

          accuracyMap.on('mouseup', 'upload-photos', function (e) {
            var turf = component.get('turf');
            var clickedPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
            var featuresOfClick = accuracyMap.queryRenderedFeatures(e.point);
            var GCPsForPhotos = component.get('GCPsForPhotos');

            var shortestDistance = Infinity;
            var closestFeature = void 0;
            for (var _i2 = 0; _i2 < featuresOfClick.length; _i2 += 1) {
              if (featuresOfClick[_i2].source === 'upload-photos' && featuresOfClick[_i2].properties.type === 'photo') {
                var currentPoint = turf.point([featuresOfClick[_i2].properties.lng, featuresOfClick[_i2].properties.lat]);
                var currentDistance = turf.distance(clickedPoint, currentPoint, { units: 'feet' });
                if (currentDistance < shortestDistance) {
                  shortestDistance = currentDistance;
                  closestFeature = featuresOfClick[_i2];
                }
              }
            }

            var drawTarget = function drawTarget() {
              var photo = component.getPhotoMetaByName(component.get('fileNameOfCurrentPhoto'));
              var currentTags = photo.taggedGCPs;
              var foundGCP = currentTags.filter(function (taggedGCP) {
                return taggedGCP.id === component.get('currentGCP');
              })[0];

              if (!foundGCP) {
                $('#xOverlayLine').css('width', '0');
                $('#yOverlayLine').css('height', '0');
                $('#clear-tag-group').css('display', 'none');
              } else {
                var photoRect = document.getElementById('light-photo').getBoundingClientRect();
                var containerRect = document.getElementById('light-photo-container').getBoundingClientRect();
                var scaleFactor = component.get('widthOfCurrentPhoto') / photoRect.width;
                var xPixel = foundGCP.x / scaleFactor + photoRect.left - containerRect.left;
                var yPixel = foundGCP.y / scaleFactor + photoRect.top - containerRect.top;

                $('#xOverlayLine').css('left', xPixel + 'px');
                $('#yOverlayLine').css('top', yPixel + 'px');
                $('#xOverlayLine').css('width', '1px');
                $('#yOverlayLine').css('height', '1px');
                $('#clear-tag-group').css('display', 'block');
              }
            };

            var changePhoto = function changePhoto(selectedPhoto) {
              $('#light-photo-loading-indicator').replaceWith('<span id="light-photo-loading-indicator" class="glyphicon glyphicon-repeat spinner" style="min-width:20px;"/>');

              var gcp = selectedPhoto.gcp,
                  fileName = selectedPhoto.fileName;

              var photo = component.getPhotoByName(fileName);
              var GCPsForPhoto = GCPsForPhotos.get(fileName);
              var dataReader = new FileReader();
              dataReader.addEventListener('load', function () {
                var displayUrl = dataReader.result;
                var image = new Image();
                image.src = displayUrl;
                image.onload = function onload() {
                  component.set('heightOfCurrentPhoto', this.height);
                  component.set('widthOfCurrentPhoto', this.width);
                  component.set('fileNameOfCurrentPhoto', fileName);
                  component.set('currentGCP', gcp);

                  var gcpString = gcp;
                  if (GCPsForPhoto.length > 1) {
                    gcpString = '<select id="gcp-tag-select">' + GCPsForPhoto.reduce(function (out, gcpId) {
                      return out + '<option ' + (gcp === gcpId ? 'selected="selected"' : '') + '>' + gcpId + '</option>';
                    }, '') + '</select>';
                  }

                  var landscapeRatio = this.height / this.width;
                  var portraitRatio = this.width / this.height;
                  var photoIsPortrait = this.height > this.width;
                  var photoContainerWidth = photoIsPortrait ? 75 * landscapeRatio + 'vh' : 75 * portraitRatio + 'vh';
                  var photoContainerMaxHeight = photoIsPortrait ? 90 * portraitRatio + 'vw' : 90 * landscapeRatio + 'vw';

                  $('#light-photo-container').css('max-height', photoContainerMaxHeight);
                  $('.tagging-container').css('width', photoContainerWidth);
                  $('#gcpName').html(gcpString);
                  $('#photoName').text(fileName);
                  $('#light-photo').replaceWith('<img id="light-photo" src=\'' + displayUrl + '\' style="cursor:crosshair;width:100%;" />');
                  $('#light-photo-loading-indicator').replaceWith('<span id="light-photo-loading-indicator" style="min-width:20px;"/>');

                  var changeGCPHandler = function changeGCPHandler(event) {
                    component.set('currentGCP', event.target.value);
                    drawTarget();
                  };

                  var gcpSelectorElement = document.getElementById('gcp-tag-select');
                  if (gcpSelectorElement) gcpSelectorElement.addEventListener('change', changeGCPHandler);

                  var initializeZoom = function initializeZoom() {
                    var max_scale = 50;
                    var factor = 0.25;
                    var container = $('#light-photo-container');
                    var target = $('#light-photo');
                    var size = { w: target.width(), h: target.height() };
                    var pos = { x: 0, y: 0 };
                    var zoom_target = { x: 0, y: 0 };
                    var zoom_point = { x: 0, y: 0 };
                    var scale = 1;

                    var update = function update() {
                      return target.css('transform', 'translate(' + pos.x + 'px, ' + pos.y + 'px) scale(' + scale + ', ' + scale + ')');
                    };

                    var scrolled = function scrolled(e2) {
                      var offset = container.offset();
                      zoom_point.x = e2.originalEvent.pageX - offset.left;
                      zoom_point.y = e2.originalEvent.pageY - offset.top;

                      e2.preventDefault();
                      var delta = e2.delta || e2.originalEvent.wheelDelta;
                      if (delta === undefined) {
                        // we are on firefox
                        delta = e2.originalEvent.detail * -1;
                      }
                      delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

                      // determine the point on where the slide is zoomed in
                      zoom_target.x = (zoom_point.x - pos.x) / scale;
                      zoom_target.y = (zoom_point.y - pos.y) / scale;

                      // apply zoom
                      scale += delta * factor * scale;
                      scale = Math.max(1, Math.min(max_scale, scale));

                      // calculate x and y based on zoom
                      pos.x = -zoom_target.x * scale + zoom_point.x;
                      pos.y = -zoom_target.y * scale + zoom_point.y;

                      // Make sure the slide stays in its container area when zooming out
                      if (pos.x > 0) pos.x = 0;
                      if (pos.x + size.w * scale < size.w) pos.x = -size.w * (scale - 1);
                      if (pos.y > 0) pos.y = 0;
                      if (pos.y + size.h * scale < size.h) pos.y = -size.h * (scale - 1);

                      update();
                      drawTarget();
                    };

                    target.css('transform-origin', '0 0');
                    target.on('mousewheel DOMMouseScroll', scrolled);
                    $('#xOverlayLine').on('mousewheel DOMMouseScroll', scrolled);
                    $('#yOverlayLine').on('mousewheel DOMMouseScroll', scrolled);
                  };

                  initializeZoom();
                  drawTarget();
                };
              });

              dataReader.readAsDataURL(photo);
            };

            if (closestFeature && closestFeature.properties.type === 'photo') {
              var nextPhotoHandler = function nextPhotoHandler(e2) {
                e2.preventDefault();

                var currentIndex = void 0;
                var fileNames = Array.from(GCPsForPhotos, function (_ref2) {
                  var _ref3 = _slicedToArray(_ref2, 1),
                      name = _ref3[0];

                  return name;
                });
                fileNames.forEach(function (searchName, index) {
                  if (searchName === component.get('fileNameOfCurrentPhoto')) currentIndex = index;
                });

                var nextFileName = fileNames[currentIndex + 1];
                if (!nextFileName) {
                  ;

                  var _fileNames = _slicedToArray(fileNames, 1);

                  nextFileName = _fileNames[0];
                }var GCPsForPhoto = GCPsForPhotos.get(nextFileName);
                var currentGCP = component.get('currentGCP');
                var nextGCP = GCPsForPhoto.indexOf(currentGCP) !== -1 ? currentGCP : GCPsForPhoto[0];

                changePhoto({ gcp: nextGCP, fileName: nextFileName });
              };

              var previousPhotoHandler = function previousPhotoHandler(e2) {
                e2.preventDefault();

                var currentIndex = void 0;
                var fileNames = Array.from(GCPsForPhotos, function (_ref4) {
                  var _ref5 = _slicedToArray(_ref4, 1),
                      name = _ref5[0];

                  return name;
                });
                fileNames.forEach(function (searchName, index) {
                  if (searchName === component.get('fileNameOfCurrentPhoto')) currentIndex = index;
                });

                var nextFileName = fileNames[currentIndex - 1];
                if (!nextFileName) nextFileName = fileNames[fileNames.length - 1];
                var GCPsForPhoto = GCPsForPhotos.get(nextFileName);
                var currentGCP = component.get('currentGCP');
                var nextGCP = GCPsForPhoto.indexOf(currentGCP) !== -1 ? currentGCP : GCPsForPhoto[0];

                changePhoto({ gcp: nextGCP, fileName: nextFileName });
              };

              var clearPhotoHandler = function clearPhotoHandler(e2) {
                e2.preventDefault();

                var gcpID = _this2.get('currentGCP');
                var fileName = _this2.get('fileNameOfCurrentPhoto');
                var currentTags = _this2.getPhotoMetaByName(fileName).taggedGCPs;
                var updatedTags = currentTags.filter(function (taggedGCP) {
                  return taggedGCP.id !== gcpID;
                });

                component.getPhotoMetaByName(fileName).taggedGCPs = updatedTags;
                drawTarget();
              };

              var mouseDownHandler = function mouseDownHandler(event) {
                var xPositionOfCursorWhenClicked = event.clientX;
                var yPositionOfCursorWhenClicked = event.clientY;
                var element = document.getElementById('light-photo');
                var photoContainer = document.getElementById('light-photo-container');

                var rect = element.getBoundingClientRect();
                var containerRect = photoContainer.getBoundingClientRect();
                var left = containerRect.left,
                    right = containerRect.right,
                    top = containerRect.top,
                    bottom = containerRect.bottom;

                var scaleFactor = component.get('widthOfCurrentPhoto') / rect.width;
                var clickedX = (xPositionOfCursorWhenClicked - rect.left) * scaleFactor;
                var clickedY = (yPositionOfCursorWhenClicked - rect.top) * (component.get('heightOfCurrentPhoto') / rect.height);

                var cursorIsToTheLeftOfImage = xPositionOfCursorWhenClicked < left;
                var cursorIsToTheRightOfImage = xPositionOfCursorWhenClicked > right;
                var cursorIsAboveImage = yPositionOfCursorWhenClicked < top;
                var cursorIsBelowImage = yPositionOfCursorWhenClicked > bottom;
                var cursorIsInsideImage = !cursorIsToTheLeftOfImage && !cursorIsToTheRightOfImage && !cursorIsAboveImage && !cursorIsBelowImage;

                if (cursorIsInsideImage && !Number.isNaN(clickedX) && !Number.isNaN(clickedY) && clickedX !== Infinity && clickedY !== Infinity) {
                  component.setGCPTarget({ clickedX: clickedX, clickedY: clickedY });
                  drawTarget();
                }
              };

              var keyPressHandler = function keyPressHandler(event) {
                if (event.code === 'Space') nextPhotoHandler(event);
                if (event.code === 'Enter') $.magnificPopup.close();
              };

              var src = '<div class="photo-popup">\n              <div style="display:flex;justify-content:flex-start;flex-wrap:wrap;align-items:center;margin-bottom:10px;" id="tagging-controls" class="tagging-container">\n                <div>GCP: <span id="gcpName"/></div>\n                <div class="flex-spacer" />\n                <div>Photo: <span id="photoName"/></div>\n                <div class="flex-spacer" />\n                <div class="btn-group btn-group-xs" style="min-width: fit-content;">\n                  <button id="previous-photo-button" type="button" class="btn btn-cancel" style="outline: none;">Previous Photo</button>\n                  <button id="next-photo-button" type="button" class="btn btn-cancel" style="outline: none;">Next Photo (Spacebar)</button>\n                </div>\n                <div class="flex-spacer" />\n                <div id="clear-tag-group" class="btn-group btn-group-xs" style="min-width: fit-content;">\n                  <button id="clear-tag-button" type="button" class="btn btn-danger" style="outline: none;">Delete Tag</button>\n                </div>\n                <div class="flex-spacer" />\n                <span id="light-photo-loading-indicator"/>\n              </div>\n              <div id="light-photo-container" class="tagging-container">\n                <span id="xOverlayLine" style="cursor:crosshair;"></span>\n                <span id="yOverlayLine" style="cursor:crosshair;"></span>\n                <img id="light-photo" src="" style="cursor:crosshair;width:100%;" />\n              </div>\n              <div style="font-style: italic;" class="tagging-container">\n                Place your cursor over the target, zoom in to find the exact pixel marking the center of your Ground Control Point. Every pixel counts!\n              </div>\n            </div>';

              $.magnificPopup.open({
                items: { src: src, type: 'inline' },
                callbacks: {
                  open: function open() {
                    document.getElementById('next-photo-button').addEventListener('click', nextPhotoHandler);
                    document.getElementById('previous-photo-button').addEventListener('click', previousPhotoHandler);
                    document.getElementById('clear-tag-button').addEventListener('click', clearPhotoHandler);
                    document.addEventListener('mousedown', mouseDownHandler);
                    document.addEventListener('keyup', keyPressHandler);

                    changePhoto(closestFeature.properties);
                  },
                  close: function close() {
                    component.displayPins();
                    document.removeEventListener('mousedown', mouseDownHandler);
                    document.getElementById('next-photo-button').removeEventListener('click', nextPhotoHandler);
                    document.getElementById('previous-photo-button').removeEventListener('click', previousPhotoHandler);
                    document.getElementById('clear-tag-button').removeEventListener('click', clearPhotoHandler);
                    document.removeEventListener('keyup', keyPressHandler);
                  }
                }
              });
            }
          });
        });

        var i = new Image();
        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/images/glyphicons/png/green-pin.png');
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i.onload = function () {
            accuracyMap.addImage('green-circle', i);
            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
          /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
            (i : any).cacheControl = imgData.cacheControl;
            (i : any).expires = imgData.expires;
            */
          i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr.send();

        var i2 = new Image();
        var xhr2 = new XMLHttpRequest();
        xhr2.open('GET', '/assets/images/glyphicons/png/yellow-pin.png');
        xhr2.responseType = 'arraybuffer';
        xhr2.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i2.onload = function () {
            accuracyMap.addImage('yellow-circle', i2);
            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr2.response)], { type: 'image/png' });
          i2.src = xhr2.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr2.send();

        var i3 = new Image();
        var xhr3 = new XMLHttpRequest();
        xhr3.open('GET', '/assets/images/glyphicons/png/glyphicons-378-riflescope.png');
        xhr3.responseType = 'arraybuffer';
        xhr3.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i3.onload = function () {
            accuracyMap.addImage('rifle-scope', i3);
            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr3.response)], { type: 'image/png' });
          i3.src = xhr3.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr3.send();
      }
    },
    willDestroyElement: function willDestroyElement() {
      var accuracyMap = this.get('accuracyMap');
      if (accuracyMap) {
        accuracyMap.remove();
        this.set('accuracyMap', null);
      }

      $('#accuracy-map').html('');
    },
    validateTaggedGCPs: function validateTaggedGCPs() {
      var tagInfo = {};

      this.get('gcpData').forEach(function (gcp) {
        tagInfo[gcp.id] = { id: gcp.id, tags: [] };
      });

      this.get('wizardData.photoMeta').forEach(function (photo) {
        photo.taggedGCPs.forEach(function (tag) {
          tagInfo[tag.id].tags.push(tag);
        });
      });

      this.set('errorDetails', Object.keys(tagInfo).map(function (key) {
        return tagInfo[key];
      }));
      $('#gcpTaggingValidationModal').modal('show');
    },


    actions: {
      updateEpsg: function updateEpsg(epsg) {
        this.set('wizardData.selectedEpsgCode', epsg);
        this.setupGCPData();
        this.displayPins();
      },
      validate: function validate() {
        this.validateTaggedGCPs();
      },
      save: function save() {
        var taggedPhotosMap = new Map();
        this.wizardData.get('photoMeta').filter(function (photo) {
          return photo.taggedGCPs.length > 0;
        }).forEach(function (_ref6) {
          var fileName = _ref6.fileName,
              taggedGCPs = _ref6.taggedGCPs;
          return taggedPhotosMap.set(fileName, taggedGCPs);
        });

        this.wizardData.get('photos').forEach(function (photo) {
          photo.taggedGroundControlPoints = taggedPhotosMap.get(photo.name) ? taggedPhotosMap.get(photo.name) : [];
        });

        this.sendAction('advanceNextStep');
      }
    }

  });
});