define('webui/models/correction-file', ['exports', 'ember-data', 'webui/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'), /* ['gcp-file', 'uav-ppk-log', 'base-station-log'] */
    fileName: _emberData.default.attr('string'),
    urlPrefix: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    downloadUrl: function downloadUrl() {
      return _environment.default.APP.apiUrl + 'correctionFiles/download/' + this.get('id');
    }.property('id'),
    shareUrl: Ember.computed('fileUrl', function shareUrl() {
      var encodedUrl = encodeURIComponent(decodeURIComponent(this.get('fileUrl'))).replace(/%2F/gi, '/');
      return '' + this.get('urlPrefix') + encodedUrl;
    }),
    flight: _emberData.default.belongsTo('correctionMethod', { async: true })
  });
});