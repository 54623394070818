define('webui/components/models-table/themes/semanticui/columns-dropdown', ['exports', 'ember-models-table/components/models-table/themes/semanticui/columns-dropdown'], function (exports, _columnsDropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _columnsDropdown.default;
    }
  });
});