define('webui/pods/components/cdt-ui/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/site-grade'], function (exports, _mixin, _siteGrade) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, _siteGrade.default, {
    store: Ember.inject.service('store'),
    statusService: Ember.inject.service('status-panel'),
    closeFloatingPanel: 'closeFloatingPanel',
    comparisonModel: null,
    comparisonType: null,

    lastBlueprintUpdateTime: Ember.observer('statusService.lastBlueprintUpdateTime', function lastBlueprintUpdateTime() {
      this.set('cdtParams.availableBlueprints', this.getSiteGrades());
    }),

    init: function init() {
      this._super();
      this.get('statusService');
      this.set('isReadOnly', this.get('authenticatedUser.isViewOnly'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var cdtParams = {};
      var allFlights = this.get('activeSite.flights').sortBy('executeAt').reverse();
      var currentFlightTime = this.get('activeFlight.executeAt');
      cdtParams.currentFlight = this.get('activeFlight');
      cdtParams.availableFlights = allFlights.filter(function (flight) {
        flight.set('changeDetection', null);
        return flight.get('executeAt') < currentFlightTime && flight.get('status') === 'succeeded';
      });

      cdtParams.availableBlueprints = this.getSiteGrades();
      cdtParams.changeDetectionMaps = this.get('tiledMaps.change-detection').findBy('flight', this.get('activeFlight.id'));
      cdtParams.changeDetections = this.get('changeDetections');

      cdtParams.availableBlueprints.forEach(function (blueprint) {
        blueprint.set('changeDetection', null);
      });

      cdtParams.changeDetections.forEach(function (cd) {
        var flight = cdtParams.availableFlights.findBy('id', cd.get('comparisonFlight.id'));
        var blueprint = cdtParams.availableBlueprints.findBy('id', cd.get('comparisonBlueprint.id'));

        if (flight) {
          flight.set('changeDetection', cd);
        }
        if (blueprint) {
          blueprint.set('changeDetection', cd);
        }
      });
      cdtParams.currentChangeDetection = this.get('currentChangeDetection');
      this.set('cdtParams', cdtParams);
    },
    resetCDTState: function resetCDTState() {
      // refreshing cd state so the menu is aware of new CDs
      var store = this.get('store');
      var component = this;
      store.query('changeDetection', { flight: this.get('activeFlight.id') }).then(function (changeDetections) {
        component.set('changeDetections', changeDetections);
      });
    },
    didInsertElement: function didInsertElement() {
      var component = this;
      io.socket.on('changedetection', function () {
        return component.resetCDTState();
      });

      return this.resetCDTState();
    },


    actions: {
      closePanel: function closePanel() {
        this.sendAction('closeFloatingPanel');
      },
      setCurrentChangeDetection: function setCurrentChangeDetection(cd) {
        this.set('currentChangeDetection', cd);
      },
      confirmDelete: function confirmDelete() {
        var statusService = this.get('statusService');
        var cd = this.get('currentChangeDetection');

        $('#cdtDeleteConfirm').modal('hide');
        $('#cdtDeleteConfirm').trigger('deleteCD', { cd: cd });

        cd.destroyRecord().then(function () {
          statusService.removeItem('status-panel-cdt', cd);
          // return component.getCDTData();
        }).catch(function (err) {
          console.error('error in cdt delete', err);
        });
      },
      goToFileManager: function goToFileManager(id, mode) {
        this.sendAction('goToFileManager', id, mode);
      }
    }
  });
});