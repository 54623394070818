define('webui/react/QualityReportsPanel', ['exports', 'npm:react', 'webui/config/environment'], function (exports, _npmReact, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var calculateAverageDifferenceOfPointsSamples = function calculateAverageDifferenceOfPointsSamples(pointSamples) {
    var pointSamplesForAverageDifference = [].concat(_toConsumableArray(pointSamples));
    var pointSamplesWithADifferenceValue = pointSamplesForAverageDifference.filter(function (samplePoint) {
      return !isNaN(samplePoint.difference);
    });
    var sumOfPointSampleDifferences = pointSamplesWithADifferenceValue.reduce(function (totalError, samplePoint) {
      return totalError + Math.abs(samplePoint.difference);
    }, 0);

    return sumOfPointSampleDifferences / pointSamplesWithADifferenceValue.length;
  };

  var QualityReportPanel = function (_React$Component) {
    _inherits(QualityReportPanel, _React$Component);

    function QualityReportPanel(props) {
      _classCallCheck(this, QualityReportPanel);

      var _this = _possibleConstructorReturn(this, (QualityReportPanel.__proto__ || Object.getPrototypeOf(QualityReportPanel)).call(this, props));

      var component = _this;
      io.socket.on('pointSample', function (event) {
        if (event.verb === 'updated') {
          component.loadPointSamples(event.id);
        }
      });

      var pointSample = props.flight.get('pointSample.id') ? props.flight.get('pointSample') : null;
      var pointSamples = pointSample ? pointSample.get('pointSamples') : [];
      if (pointSample) {
        io.socket.get('/v1/pointSamples/' + pointSample.get('id'));
      }

      var units = '';
      if (pointSample && pointSample.get('units')) {
        units = pointSample.get('units').indexOf('foot') !== -1 ? 'feet' : 'meters';
      }

      _this.state = {
        photos: props.flight.get('photos').toArray(),
        showPhotos: false,
        showPointSamples: false,
        showPointSampleUpload: false,
        showPointSampleDelete: false,
        isUploading: false,
        pointSampleErrorThresholdForTextField: 0.25,
        pointSampleErrorThreshold: 0.25,
        pointSamples: pointSamples,
        units: units,
        uploadStatus: pointSample ? pointSample.get('status') : null,
        uploadMessage: pointSample ? pointSample.get('messageDisplay') : null,
        pointSampleCount: pointSamples.length,
        pointSampleDifferenceAverage: calculateAverageDifferenceOfPointsSamples(pointSamples),
        reports: props.flight.get('qualityReports').toArray().map(function (el) {
          var report = el.toJSON();
          report.id = el.get('id'); /* for some reason, ID is not included as part of the JSON object */
          report.isDisplayed = false; /* track the display state of this particular report */
          report.key = report.id; /* to stop a React warning we add "key" as a unique ID */
          return report;
        })
      };

      _this.selectFile = _this.selectFile.bind(_this);
      _this.importPointSamples = _this.importPointSamples.bind(_this);
      _this.loadPointSamples = _this.loadPointSamples.bind(_this);
      _this.changePointSampleErrorThreshold = _this.changePointSampleErrorThreshold.bind(_this);
      _this.updatePointSampleErrorThreshold = _this.updatePointSampleErrorThreshold.bind(_this);
      _this.deletePointSamples = _this.deletePointSamples.bind(_this);
      _this.togglePointSampleDelete = _this.togglePointSampleDelete.bind(_this);
      _this.toggleShowPointSampleUpload = _this.toggleShowPointSampleUpload.bind(_this);
      return _this;
    }

    _createClass(QualityReportPanel, [{
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        var _state = this.state,
            showPhotos = _state.showPhotos,
            showPointSamples = _state.showPointSamples;

        io.socket.off('pointSample');

        if (showPhotos) {
          this.togglePhotos();
        }

        if (showPointSamples) {
          this.togglePointSamples();
        }

        this.hideAllQualityReports(); /* This isn't ideal, but since Ember services can close the surrounding component, there doesn't seem to be a better way to directly initiate this. */
      }
    }, {
      key: 'leavePanel',
      value: function leavePanel() {
        $('#quality-reports-tool-bar').removeClass('active'); /* This is how it's done in the Ember component so we're just perpetuating for lack of a better alternative... */
        this.bubbleEmberAction('closeDataPanel');
      }
    }, {
      key: 'bubbleEmberAction',
      value: function bubbleEmberAction(actionName) {
        var _props = this.props,
            emberComponent = _props.emberComponent,
            emberComponentParent = _props.emberComponentParent;

        if (emberComponentParent.actions[actionName]) {
          emberComponentParent.actions[actionName].call(emberComponent);
        } else {
          console.error('The associated Ember Parent of QualityReportsPanel does not have an action "' + actionName + '".');
        }
      }
    }, {
      key: 'loadPointSamples',
      value: function loadPointSamples(pointSampleId) {
        var component = this;
        var dataService = this.props.dataService;


        dataService.findRecord('point-sample', pointSampleId, { reload: true }).then(function (pointSample) {
          component.props.flight.set('pointSample', pointSample);
          var pointSamples = pointSample.get('pointSamples');
          var units = '';
          if (pointSample && pointSample.get('units')) {
            units = pointSample.get('units').indexOf('foot') !== -1 ? 'feet' : 'meters';
          }

          component.setState({
            pointSamples: pointSamples,
            units: units,
            uploadStatus: pointSample ? pointSample.get('status') : null,
            uploadMessage: pointSample ? pointSample.get('messageDisplay') : null,
            pointSampleCount: pointSamples.length,
            pointSampleDifferenceAverage: calculateAverageDifferenceOfPointsSamples(pointSamples)
          });
        });
      }
    }, {
      key: 'updatePointSampleErrorThreshold',
      value: function updatePointSampleErrorThreshold() {
        var _state2 = this.state,
            pointSampleErrorThresholdForTextField = _state2.pointSampleErrorThresholdForTextField,
            showPointSamples = _state2.showPointSamples;

        this.setState({ pointSampleErrorThreshold: pointSampleErrorThresholdForTextField, thresholdNotUpdatedYet: false });

        if (showPointSamples) {
          this.togglePointSamples(this.togglePointSamples);
        }
      }
    }, {
      key: 'changePointSampleErrorThreshold',
      value: function changePointSampleErrorThreshold(e) {
        if (isNaN(e.target.value)) {
          return;
        }

        this.setState({ pointSampleErrorThresholdForTextField: e.target.value, thresholdNotUpdatedYet: true });
      }
    }, {
      key: 'selectFile',
      value: function selectFile(event) {
        this.setState({ importFile: event.target.files[0] });
      }
    }, {
      key: 'importPointSamples',
      value: function importPointSamples() {
        var component = this;
        var _props2 = this.props,
            emberComponent = _props2.emberComponent,
            flight = _props2.flight;

        var file = component.state.importFile;
        var data = new FormData();
        data.append('flight', component.props.flight.id);
        data.append('pointSamples', file);

        component.setState({ isUploading: true });

        $.ajax({
          url: _environment.default.APP.apiUrl + 'pointSamples/upload',
          data: data,
          processData: false,
          contentType: false,
          type: 'POST',
          success: function success(res) {
            if (res.errors && res.errors.length) {
              component.displayError('An error occurred while importing your point samples. Please double check your spreadsheet, confirm that column headers match the template, and try again.', res.errors);
              component.setState({ isUploading: false });
            } else {
              component.displayInfo();

              io.socket.get('/v1/pointSamples/' + res.pointSample.id, function (socketRes) {
                component.loadPointSamples(socketRes.pointSample.id);
              });

              $('#point-sample-file').val(null);
              component.setState({
                importFile: null, showPointSampleUpload: false, uploadStatus: 'pending', isUploading: false
              });
              if (component.state.showPointSamples) {
                component.togglePointSamples();
              }
            }
          },
          error: function error() {
            component.displayError('An error occurred while importing your point samples. Please double check your spreadsheet, confirm that column headers match the template, and try again.');
            component.setState({ isUploading: false });
          }
        });

        emberComponent.trackEvent('Point Samples Imported', { siteId: flight.get('site.id'), siteName: flight.get('site.name') });
      }
    }, {
      key: 'deletePointSamples',
      value: function deletePointSamples() {
        var component = this;
        var data = new FormData();
        data.append('flight', component.props.flight.id);

        this.setState({ showPointSampleDelete: false });
        $.ajax({
          url: _environment.default.APP.apiUrl + 'pointSamples/delete',
          data: data,
          processData: false,
          contentType: false,
          type: 'DELETE',
          success: function success() {
            component.props.flight.set('pointSample', null);

            component.setState({
              uploadStatus: null,
              uploadMessage: null,
              pointSampleCount: 0,
              pointSampleSuppliedAverage: 0,
              pointSampleSampledAverage: 0,
              pointSampleDifferenceAverage: 0
            });

            if (component.state.showPointSamples) {
              component.togglePointSamples();
            }
          },
          error: function error() {
            component.displayError('An error occurred while deleting your point samples. Please try again.');
          }
        });
      }
    }, {
      key: 'displayInfo',
      value: function displayInfo() {
        var emberComponent = this.props.emberComponent;

        emberComponent.actions.displayInfo.call(emberComponent);
      }
    }, {
      key: 'displayError',
      value: function displayError(error, errorDetails) {
        var emberComponent = this.props.emberComponent;

        emberComponent.actions.displayError.call(emberComponent, error, errorDetails);
      }
    }, {
      key: 'togglePointSampleDelete',
      value: function togglePointSampleDelete() {
        var showPointSampleDelete = this.state.showPointSampleDelete;


        this.setState({ showPointSampleDelete: !showPointSampleDelete, showPointSampleUpload: false });
      }
    }, {
      key: 'toggleShowPointSampleUpload',
      value: function toggleShowPointSampleUpload() {
        var _state3 = this.state,
            importFile = _state3.importFile,
            showPointSampleUpload = _state3.showPointSampleUpload;


        this.setState({ showPointSampleUpload: !showPointSampleUpload, importFile: showPointSampleUpload ? null : importFile, showPointSampleDelete: false });
      }
    }, {
      key: 'toggleQualityReport',
      value: function toggleQualityReport(report) {
        var mapData = this.props.mapData;

        var map = mapData.mapInstance;
        var component = this;

        if (report) {
          if (map.getLayer(report.id) && map.getLayoutProperty(report.id, 'visibility') === 'visible') {
            component.hideQualityReport(report.id);
          } else {
            /* This ensures there's only one report visible at a time. */
            component.hideAllQualityReports();
            component.showQualityReport(report);
          }
        } else {
          /* No report by this point means we just want to hide any visible reports. */
          component.hideAllQualityReports();
        }
      }
    }, {
      key: 'hideAllQualityReports',
      value: function hideAllQualityReports() {
        var _this2 = this;

        var reports = this.state.reports;


        reports.forEach(function (o) {
          if (o.isDisplayed) {
            _this2.hideQualityReport(o.id);
          }
        });
      }
    }, {
      key: 'hideQualityReport',
      value: function hideQualityReport(reportId) {
        var mapData = this.props.mapData;
        var reports = this.state.reports;

        var map = mapData.mapInstance;

        if (map.getLayer(reportId)) {
          map.setLayoutProperty(reportId, 'visibility', 'none');
          var report = reports.find(function (o) {
            return o.id === reportId;
          });
          if (report) {
            report.isDisplayed = false;
            this.setState(report);
          }
          return true;
        }

        return false;
      }
    }, {
      key: 'showQualityReport',
      value: function showQualityReport(report) {
        var _props3 = this.props,
            emberComponent = _props3.emberComponent,
            flight = _props3.flight,
            mapData = _props3.mapData;

        var map = mapData.mapInstance;
        var geo = report.gcpPoints;

        geo.features.forEach(function (o) {
          /* Assemble the title for the marker text */
          o.properties.title = o.properties.name + '\nerrX: ' + o.properties.errX + '\nerrY: ' + o.properties.errY + '\nerrZ: ' + o.properties.errZ;
        });

        if (!map.getLayer(report.id)) {
          map.addLayer({
            id: report.id,
            type: 'symbol',
            source: {
              type: 'geojson',
              data: geo
            },
            layout: {
              'icon-image': 'riflescope',
              'icon-size': 1,
              'icon-optional': false,
              'text-field': '{title}',
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-offset': [1, 0],
              'text-anchor': 'left',
              'text-justify': 'left',
              'text-allow-overlap': true,
              'icon-allow-overlap': true,
              visibility: 'visible'
            },
            paint: {
              'text-color': '#fff',
              'text-halo-color': '#000',
              'text-halo-width': 1,
              'text-halo-blur': 2
            }
          });
        } else {
          map.setLayoutProperty(report.id, 'visibility', 'visible');
        }

        report.isDisplayed = true;
        this.setState(report);

        var eventDetail = {
          siteId: flight.get('site.id'),
          siteName: flight.get('site.name'),
          qualityReportId: report.id,
          qualityReportType: report.label
        };

        emberComponent.trackEvent('QR GCPs Viewed', eventDetail); /* React doesn't do mixins so rather than reinvent the wheel we rely on the wrapper components trackEvent() function for segment analytics.   */

        /* REFERENCE: This is the method for adding an HTML element to the page as a marker - allows for greater control of styling
         var glyph = document.createElement('span');
         glyph.className = "glyphicon glyphicon-screenshot";
         glyph.style.fontSize = "30px";
          new mapboxgl.Marker(glyph)
         .setLngLat([-79.947440,40.888800])
         .addTo(map);
         */
        return map;
      }
    }, {
      key: 'togglePointSamples',
      value: function togglePointSamples(callback) {
        var layerName = 'point-samples';
        var mapData = this.props.mapData;

        var map = mapData.mapInstance;
        var _state4 = this.state,
            pointSamples = _state4.pointSamples,
            pointSampleErrorThreshold = _state4.pointSampleErrorThreshold,
            showPointSamples = _state4.showPointSamples,
            units = _state4.units;


        this.setState({ showPointSamples: !showPointSamples }, callback);
        if (showPointSamples) {
          return map.setLayoutProperty(layerName, 'visibility', 'none');
        }

        if (map.getSource(layerName)) {
          map.removeSource(layerName);
        }
        if (map.getLayer(layerName)) {
          map.removeLayer(layerName);
        }

        var features = pointSamples.map(function (pointSample) {
          var lat = pointSample.lat,
              lng = pointSample.lng,
              suppliedElevation = pointSample.suppliedElevation,
              sampledElevation = pointSample.sampledElevation,
              difference = pointSample.difference;

          var showWarning = isNaN(difference) || Math.abs(difference) > pointSampleErrorThreshold;

          var suppliedElevationLine = 'Supplied Elevation: ' + suppliedElevation.toFixed(2) + ' ' + units;
          var sampledElevationLine = !isNaN(sampledElevation) ? '<br/>Sampled Elevation: ' + sampledElevation.toFixed(2) + ' ' + units : '';
          var differenceLine = !isNaN(difference) ? '<br/>Difference: ' + difference.toFixed(2) + ' ' + units : '';

          var icon = 'red-circle';
          if (!isNaN(difference)) {
            icon = showWarning ? 'yellow-circle' : 'green-circle';
          }

          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lng, lat]
            },
            properties: {
              description: '' + suppliedElevationLine + sampledElevationLine + differenceLine,
              icon: icon,
              'icon-allow-overlap': true
            }
          };
        });

        map.addLayer({
          id: layerName,
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: features
            }
          },
          layout: {
            'icon-image': '{icon}',
            'icon-size': 1,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            // "text-field": "{title}",
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [1, 0],
            'text-anchor': 'left',
            'text-justify': 'left',
            visibility: 'visible'
          },
          paint: {
            'text-color': '#fff',
            'text-halo-color': '#000',
            'text-halo-width': 1,
            'text-halo-blur': 2
          }
        });

        var popup = new window.mapboxgl.Popup({ closeButton: false, closeOnClick: false });

        map.on('mouseenter', layerName, function (e) {
          map.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties.description;


          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        map.on('mouseleave', layerName, function () {
          map.getCanvas().style.cursor = '';
          popup.remove();
        });

        return null;
      }
    }, {
      key: 'togglePhotos',
      value: function togglePhotos() {
        var expectedRtkFlag = 50;
        var layerName = 'qr-photos';
        var _props4 = this.props,
            emberComponent = _props4.emberComponent,
            flight = _props4.flight,
            mapData = _props4.mapData;

        var map = mapData.mapInstance;
        var _state5 = this.state,
            photos = _state5.photos,
            showPhotos = _state5.showPhotos;


        this.setState({ showPhotos: !showPhotos });
        if (showPhotos) {
          return map.setLayoutProperty(layerName, 'visibility', 'none');
        }

        if (!map.getLayer(layerName)) {
          var features = photos.map(function (photo) {
            var showWarning = true;
            if (photo.get('RtkFlag') !== null) {
              showWarning = photo.get('RtkFlag') !== expectedRtkFlag;
            }

            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [photo.get('longitude'), photo.get('latitude')]
              },
              properties: {
                title: photo.get('fileName'),
                icon: showWarning ? 'yellow-circle' : 'green-circle',
                displayUrl: '' + _environment.default.APP.S3host + photo.get('fileUrl'),
                height: photo.get('exif.imageSize.height'),
                lat: photo.get('latitude'),
                lng: photo.get('longitude'),
                width: photo.get('exif.imageSize.width'),
                'icon-allow-overlap': true
              }
            };
          });

          map.addLayer({
            id: layerName,
            type: 'symbol',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: features
              }
            },
            layout: {
              'icon-image': '{icon}',
              'icon-size': 1,
              'icon-allow-overlap': true,
              'text-allow-overlap': true
              // "text-field": "{title}",
              // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              // "text-offset": [0, 0.6],
              // "text-anchor": "top"
            }
          });

          map.on('mouseenter', layerName, function () {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', layerName, function () {
            map.getCanvas().style.cursor = '';
          });

          map.on('mousedown', layerName, function (e) {
            var _window = window,
                turf = _window.turf;

            var clickedPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
            var featuresOfClick = map.queryRenderedFeatures(e.point);

            var shortestDistance = Infinity;
            var closestPhoto = void 0;
            for (var i = 0; i < featuresOfClick.length; i += 1) {
              if (featuresOfClick[i].source === layerName && featuresOfClick[i].properties.lng && featuresOfClick[i].properties.lat) {
                var currentPoint = turf.point([featuresOfClick[i].properties.lng, featuresOfClick[i].properties.lat]);
                var currentDistance = turf.distance(clickedPoint, currentPoint, { units: 'feet' });
                if (currentDistance < shortestDistance) {
                  shortestDistance = currentDistance;
                  closestPhoto = featuresOfClick[i];
                }
              }
            }

            if (closestPhoto) {
              var _closestPhoto$propert = closestPhoto.properties,
                  displayUrl = _closestPhoto$propert.displayUrl,
                  title = _closestPhoto$propert.title;


              var image = new Image();
              image.src = displayUrl;
              image.onload = function onload() {
                var landscapeRatio = this.height / this.width;
                var portraitRatio = this.width / this.height;
                var photoIsPortrait = this.height > this.width;
                var photoContainerWidth = photoIsPortrait ? 75 * landscapeRatio + 'vh' : 75 * portraitRatio + 'vh';
                var photoContainerMaxHeight = photoIsPortrait ? 90 * portraitRatio + 'vw' : 90 * landscapeRatio + 'vw';

                var src = '<div class="photo-popup"">\n              <div>' + title + '&nbsp;&nbsp;</div>\n              <div id=\'light-photo-container\' style="width:' + photoContainerWidth + ';max-height:' + photoContainerMaxHeight + ';">\n                <img id="light-photo" src=\'' + displayUrl + '\' style="width:100%;" />\n              </div>\n            </div>';

                $.magnificPopup.open({
                  items: { src: src, type: 'inline' },
                  callbacks: {
                    open: function open() {
                      var initializeZoom = function initializeZoom() {
                        var max_scale = 50;
                        var factor = 0.25;
                        var container = $('#light-photo-container');
                        var target = $('#light-photo');
                        var size = { w: target.width(), h: target.height() };
                        var pos = { x: 0, y: 0 };
                        var zoom_target = { x: 0, y: 0 };
                        var zoom_point = { x: 0, y: 0 };
                        var scale = 1;

                        var update = function update() {
                          return target.css('transform', 'translate(' + pos.x + 'px, ' + pos.y + 'px) scale(' + scale + ', ' + scale + ')');
                        };

                        var scrolled = function scrolled(e2) {
                          var offset = container.offset();
                          zoom_point.x = e2.pageX - offset.left;
                          zoom_point.y = e2.pageY - offset.top;

                          e2.preventDefault();
                          var delta = e2.delta || e2.originalEvent.wheelDelta;
                          if (delta === undefined) {
                            // we are on firefox
                            delta = e2.originalEvent.detail;
                          }
                          delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

                          // determine the point on where the slide is zoomed in
                          zoom_target.x = (zoom_point.x - pos.x) / scale;
                          zoom_target.y = (zoom_point.y - pos.y) / scale;

                          // apply zoom
                          scale += delta * factor * scale;
                          scale = Math.max(1, Math.min(max_scale, scale));

                          // calculate x and y based on zoom
                          pos.x = -zoom_target.x * scale + zoom_point.x;
                          pos.y = -zoom_target.y * scale + zoom_point.y;

                          // Make sure the slide stays in its container area when zooming out
                          if (pos.x > 0) pos.x = 0;
                          if (pos.x + size.w * scale < size.w) pos.x = -size.w * (scale - 1);
                          if (pos.y > 0) pos.y = 0;
                          if (pos.y + size.h * scale < size.h) pos.y = -size.h * (scale - 1);

                          update();
                        };

                        target.css('transform-origin', '0 0');
                        target.on('mousewheel DOMMouseScroll', scrolled);
                        $('#xOverlayLine').on('mousewheel DOMMouseScroll', scrolled);
                        $('#yOverlayLine').on('mousewheel DOMMouseScroll', scrolled);
                      };

                      initializeZoom();
                    }
                  }
                });
              };
            }
          });
        } else {
          map.setLayoutProperty(layerName, 'visibility', 'visible');
        }

        emberComponent.trackEvent('Photos toggled ON', { siteId: flight.get('site.id'), siteName: flight.get('site.name') });

        return map;
      }
    }, {
      key: 'downloadCSV',
      value: function downloadCSV() {
        var _props5 = this.props,
            apiURL = _props5.apiURL,
            flight = _props5.flight;

        window.location.href = apiURL + 'pointSamples/download/' + flight.get('id');
      }
    }, {
      key: 'render',
      value: function render() {
        var _this3 = this;

        var _state6 = this.state,
            importFile = _state6.importFile,
            isUploading = _state6.isUploading,
            photos = _state6.photos,
            pointSampleCount = _state6.pointSampleCount,
            pointSampleDifferenceAverage = _state6.pointSampleDifferenceAverage,
            pointSampleErrorThresholdForTextField = _state6.pointSampleErrorThresholdForTextField,
            reports = _state6.reports,
            showPhotos = _state6.showPhotos,
            showPointSamples = _state6.showPointSamples,
            showPointSampleDelete = _state6.showPointSampleDelete,
            showPointSampleUpload = _state6.showPointSampleUpload,
            thresholdNotUpdatedYet = _state6.thresholdNotUpdatedYet,
            units = _state6.units,
            uploadMessage = _state6.uploadMessage,
            uploadStatus = _state6.uploadStatus;


        return _npmReact.default.createElement(
          'div',
          { id: 'qualityReportsPanel' },
          _npmReact.default.createElement(
            'div',
            { className: 'panel-header row' },
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-10 text-left' },
              'Quality Reports'
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'col-xs-2 text-right' },
              _npmReact.default.createElement('span', { className: 'glyphicon glyphicon-remove', onClick: function onClick() {
                  _this3.leavePanel();
                } })
            )
          ),
          _npmReact.default.createElement(
            'div',
            { className: 'panel-body row' },
            _npmReact.default.createElement(
              'div',
              { key: 'point-samples', className: 'pane' + (showPointSamples ? ' displayed' : '') + (showPointSampleUpload ? ' upload-displayed' : '') },
              _npmReact.default.createElement(
                'div',
                { className: 'header row' },
                _npmReact.default.createElement(
                  'div',
                  { className: 'col-xs-6' },
                  _npmReact.default.createElement(
                    'h4',
                    null,
                    'Point Samples'
                  )
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'col-xs-6 text-right' },
                  (uploadStatus === 'warning' || uploadStatus === 'failed') && _npmReact.default.createElement('div', { className: 'glyphicon-full glyphicons-warning-sign regionHeaderIcon ' + uploadStatus, alt: uploadMessage, title: uploadMessage }),
                  uploadStatus === 'pending' && _npmReact.default.createElement('span', { className: 'glyphicon glyphicon-repeat spinner regionHeaderIcon' }),
                  (uploadStatus === 'succeeded' || uploadStatus === 'warning') && _npmReact.default.createElement('span', { 'data-tool-tip-text': 'Download Point Samples', className: 'glyphicon glyphicon-cloud-download clickable global-tool-tip global-tool-tip-left regionHeaderIcon', onClick: function onClick() {
                      return _this3.downloadCSV();
                    } }),
                  !this.props.isReadOnly && _npmReact.default.createElement('span', { 'data-tool-tip-text': 'Import Point Samples', className: 'glyphicon glyphicon-cloud-upload clickable global-tool-tip global-tool-tip-left regionHeaderIcon', onClick: function onClick() {
                      return _this3.toggleShowPointSampleUpload();
                    } }),
                  (uploadStatus === 'succeeded' || uploadStatus === 'warning') && !this.props.isReadOnly && _npmReact.default.createElement('span', { 'data-tool-tip-text': 'Delete Point Samples', className: 'glyphicon glyphicon-remove clickable global-tool-tip global-tool-tip-left regionHeaderIcon', onClick: this.togglePointSampleDelete }),
                  (uploadStatus === 'succeeded' || uploadStatus === 'warning') && _npmReact.default.createElement('span', { 'data-tool-tip-text': 'Show Point Samples', className: 'glyphicon glyphicons-custom-eye-toggle clickable global-tool-tip global-tool-tip-left regionHeaderIcon', onClick: function onClick() {
                      return _this3.togglePointSamples();
                    } })
                )
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'line' },
                _npmReact.default.createElement(
                  'span',
                  { className: 'label' },
                  'Number of Point Samples:'
                ),
                _npmReact.default.createElement(
                  'span',
                  { className: 'value' },
                  ' ',
                  pointSampleCount
                )
              ),
              pointSampleCount > 0 && _npmReact.default.createElement(
                'span',
                null,
                _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Average Difference:'
                  ),
                  _npmReact.default.createElement(
                    'span',
                    { className: 'value' },
                    ' ',
                    pointSampleDifferenceAverage.toFixed(2),
                    ' ',
                    units
                  )
                ),
                showPointSamples && _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  units ? _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Warning Threshold (',
                    units,
                    '):'
                  ) : _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Warning Threshold:'
                  ),
                  _npmReact.default.createElement('input', { style: { color: 'black', width: '100px' }, value: pointSampleErrorThresholdForTextField, onChange: this.changePointSampleErrorThreshold }),
                  '\xA0\xA0',
                  _npmReact.default.createElement(
                    'button',
                    {
                      style: {
                        width: 'auto', margin: '5px 0 5px', fontSize: '.85em', backgroundColor: '#9FA8DA', border: 'none', borderRadius: '3px', fontWeight: 'bold'
                      },
                      className: 'btn',
                      type: 'button',
                      disabled: !thresholdNotUpdatedYet,
                      onClick: this.updatePointSampleErrorThreshold
                    },
                    'Update'
                  )
                )
              ),
              showPointSampleDelete && _npmReact.default.createElement(
                'div',
                { className: 'confirm-button-wrapper' },
                _npmReact.default.createElement('hr', null),
                'Are you sure you want to remove the point samples for this flight?',
                _npmReact.default.createElement('br', null),
                _npmReact.default.createElement(
                  'div',
                  { className: 'text-right' },
                  _npmReact.default.createElement(
                    'button',
                    { className: 'btn btn-primary confirm', type: 'button', onClick: this.deletePointSamples },
                    'Remove'
                  ),
                  _npmReact.default.createElement(
                    'button',
                    { className: 'btn cancel white-background', type: 'button', onClick: this.togglePointSampleDelete },
                    'Cancel'
                  )
                )
              ),
              showPointSampleUpload && _npmReact.default.createElement(
                'div',
                null,
                _npmReact.default.createElement('hr', null),
                'Download point sample template',
                _npmReact.default.createElement(
                  'a',
                  { style: { color: 'lightBlue' }, href: 'https://itc-production-data.s3.amazonaws.com/templates/Point Sample Template.csv' },
                  'here'
                ),
                '.',
                _npmReact.default.createElement(
                  'div',
                  { className: 'file-input-wrapper' },
                  _npmReact.default.createElement(
                    'label',
                    { className: 'btn btn-file' },
                    'BROWSE',
                    ' ',
                    _npmReact.default.createElement('input', { style: { display: 'none' }, onChange: this.selectFile, accept: 'text/csv', type: 'file', id: 'point-sample-file' })
                  ),
                  importFile && _npmReact.default.createElement(
                    'div',
                    { className: 'file-input-name-display' },
                    importFile.name
                  )
                ),
                importFile && _npmReact.default.createElement(
                  'div',
                  { className: 'text-right' },
                  _npmReact.default.createElement('br', null),
                  _npmReact.default.createElement(
                    'button',
                    { className: 'btn btn-success', type: 'button', disabled: !!isUploading, onClick: function onClick() {
                        _this3.importPointSamples();
                      } },
                    'Import'
                  )
                )
              )
            ),
            photos.length > 0 && _npmReact.default.createElement(
              'div',
              { className: 'pane' + (showPhotos ? ' displayed' : ''), key: 'photos' },
              _npmReact.default.createElement(
                'div',
                { className: 'header row' },
                _npmReact.default.createElement(
                  'div',
                  { className: 'col-xs-10' },
                  _npmReact.default.createElement(
                    'h4',
                    null,
                    'Photos'
                  )
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'col-xs-2 text-right' },
                  _npmReact.default.createElement('span', { className: 'glyphicon glyphicons-custom-eye-toggle clickable', onClick: function onClick() {
                      return _this3.togglePhotos();
                    } })
                )
              )
            ),
            reports.map(function (report) {
              return _npmReact.default.createElement(
                'div',
                { className: 'pane' + (report.isDisplayed ? ' displayed' : ''), key: report.id },
                _npmReact.default.createElement(
                  'div',
                  { className: 'header row' },
                  _npmReact.default.createElement(
                    'div',
                    { className: 'col-xs-10' },
                    _npmReact.default.createElement(
                      'h4',
                      null,
                      report.coordinateSystem
                    )
                  ),
                  _npmReact.default.createElement(
                    'div',
                    { className: 'col-xs-2 text-right' },
                    _npmReact.default.createElement('span', { className: 'glyphicon glyphicons-custom-eye-toggle clickable', onClick: function onClick() {
                        _this3.toggleQualityReport(report);
                      } })
                  )
                ),
                _npmReact.default.createElement(
                  'h5',
                  null,
                  report.label
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Number of Images:'
                  ),
                  _npmReact.default.createElement(
                    'span',
                    { className: 'value' },
                    ' ',
                    report.photosCount
                  )
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Number of GCPs:'
                  ),
                  _npmReact.default.createElement(
                    'span',
                    { className: 'value' },
                    ' ',
                    report.gcpsCount
                  )
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Ground Sampling Distance:'
                  ),
                  _npmReact.default.createElement(
                    'span',
                    { className: 'value' },
                    ' ',
                    report.groundSamplingDistance,
                    ' ',
                    'cms/pixel'
                  )
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Mean RMS Error:'
                  ),
                  _npmReact.default.createElement(
                    'span',
                    { className: 'value' },
                    ' ',
                    report.rmsError
                  )
                ),
                _npmReact.default.createElement(
                  'div',
                  { className: 'line' },
                  _npmReact.default.createElement(
                    'span',
                    { className: 'label' },
                    'Mean z-RMS Error:'
                  ),
                  _npmReact.default.createElement(
                    'span',
                    { className: 'value' },
                    ' ',
                    report.rmsErrorZ
                  )
                )
              );
            })
          )
        );
      }
    }]);

    return QualityReportPanel;
  }(_npmReact.default.Component);

  exports.default = QualityReportPanel;
});