define("webui/pods/components/on-hand-inventory-import-field-selection/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 14
              },
              "end": {
                "line": 20,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element7, 'value');
            morphs[1] = dom.createMorphAt(element7, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["get", "columnName", ["loc", [null, [19, 32], [19, 42]]]]], ["content", "columnName", ["loc", [null, [19, 45], [19, 59]]]]],
          locals: ["columnName"],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 14
              },
              "end": {
                "line": 35,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element6, 'value');
            morphs[1] = dom.createMorphAt(element6, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["get", "columnName", ["loc", [null, [34, 32], [34, 42]]]]], ["content", "columnName", ["loc", [null, [34, 45], [34, 59]]]]],
          locals: ["columnName"],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 18
                    },
                    "end": {
                      "line": 50,
                      "column": 116
                    }
                  },
                  "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "unmatchedMaterial.materialKey", ["loc", [null, [50, 83], [50, 116]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 116
                    },
                    "end": {
                      "line": 50,
                      "column": 158
                    }
                  },
                  "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "unmatchedMaterial.materialName", ["loc", [null, [50, 124], [50, 158]]]]],
                locals: [],
                templates: []
              };
            }();
            var child2 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 55,
                        "column": 22
                      },
                      "end": {
                        "line": 57,
                        "column": 22
                      }
                    },
                    "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("option");
                    dom.setAttribute(el1, "selected", "selected");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element3, 'value');
                    morphs[1] = dom.createMorphAt(element3, 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "value", ["get", "option.rowNumber", ["loc", [null, [56, 60], [56, 76]]]]], ["content", "option.materialCode", ["loc", [null, [56, 79], [56, 102]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 57,
                        "column": 22
                      },
                      "end": {
                        "line": 59,
                        "column": 22
                      }
                    },
                    "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("option");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element2, 'value');
                    morphs[1] = dom.createMorphAt(element2, 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "value", ["get", "option.rowNumber", ["loc", [null, [58, 40], [58, 56]]]]], ["content", "option.materialCode", ["loc", [null, [58, 59], [58, 82]]]]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 20
                    },
                    "end": {
                      "line": 60,
                      "column": 20
                    }
                  },
                  "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "unmatchedMaterial.userSelection", ["loc", [null, [55, 32], [55, 63]]]], ["get", "option.rowNumber", ["loc", [null, [55, 64], [55, 80]]]]], [], ["loc", [null, [55, 28], [55, 81]]]]], [], 0, 1, ["loc", [null, [55, 22], [59, 29]]]]],
                locals: ["option"],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 47,
                    "column": 12
                  },
                  "end": {
                    "line": 64,
                    "column": 12
                  }
                },
                "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-6");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-6");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("select");
                dom.setAttribute(el3, "class", "form-control");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("                  ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var element5 = dom.childAt(element4, [3, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
                morphs[1] = dom.createAttrMorph(element5, 'onChange');
                morphs[2] = dom.createMorphAt(element5, 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["get", "displayMaterialAsCode", ["loc", [null, [50, 29], [50, 50]]]], ["get", "unmatchedMaterial.materialKey", ["loc", [null, [50, 51], [50, 80]]]]], [], ["loc", [null, [50, 24], [50, 81]]]]], [], 0, 1, ["loc", [null, [50, 18], [50, 165]]]], ["attribute", "onChange", ["subexpr", "action", [["subexpr", "action", ["selectRowForUnmatched", ["get", "unmatchedMaterial.materialId", ["loc", [null, [53, 97], [53, 125]]]]], [], ["loc", [null, [53, 65], [53, 126]]]]], ["value", "target.value"], ["loc", [null, [53, 56], [53, 149]]]]], ["block", "each", [["get", "unmatchedMaterial.options", ["loc", [null, [54, 28], [54, 53]]]]], [], 2, null, ["loc", [null, [54, 20], [60, 29]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 10
                },
                "end": {
                  "line": 65,
                  "column": 10
                }
              },
              "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["get", "unmatchedMaterial.matched", ["loc", [null, [47, 22], [47, 47]]]]], [], 0, null, ["loc", [null, [47, 12], [64, 23]]]]],
            locals: ["unmatchedMaterial", "index"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 8
              },
              "end": {
                "line": 66,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            var el4 = dom.createTextNode("A number of materials could not be matched to the spreadsheet. They will need to be matched manually.");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "unmatchedMaterials", ["loc", [null, [46, 18], [46, 36]]]]], [], 0, null, ["loc", [null, [46, 10], [65, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 8
              },
              "end": {
                "line": 73,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn");
            var el2 = dom.createTextNode("Import");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["importWithManuallySelectedMaterials"], [], ["loc", [null, [72, 30], [72, 78]]]]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 8
              },
              "end": {
                "line": 75,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn");
            var el2 = dom.createTextNode("Import");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["importWithAutomaticMaterialMatching"], [], ["loc", [null, [74, 30], [74, 78]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 80,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "confirmation-modal");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h4");
          var el5 = dom.createTextNode("Import On Hand Inventory");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          dom.setAttribute(el2, "style", "margin-right: -10px;");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12");
          dom.setAttribute(el3, "style", "max-height: 500px; overflow-y: auto;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-xs-12");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("h5");
          var el7 = dom.createTextNode("Which column represents the Material Code?");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-xs-12");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("select");
          dom.setAttribute(el6, "class", "form-control");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-xs-12");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("h5");
          var el7 = dom.createTextNode("Which column represents the On Hand Inventory?");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "row");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "col-xs-12");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("select");
          dom.setAttribute(el6, "class", "form-control");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "button-box col-xs-12");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn nofill");
          var el5 = dom.createTextNode("Cancel Import");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [3, 1]);
          var element10 = dom.childAt(element9, [3, 1, 1]);
          var element11 = dom.childAt(element9, [9, 1, 1]);
          var element12 = dom.childAt(element8, [5, 1]);
          var element13 = dom.childAt(element12, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element10, 'onChange');
          morphs[1] = dom.createMorphAt(element10, 1, 1);
          morphs[2] = dom.createAttrMorph(element11, 'onChange');
          morphs[3] = dom.createMorphAt(element11, 1, 1);
          morphs[4] = dom.createMorphAt(element9, 11, 11);
          morphs[5] = dom.createMorphAt(element12, 1, 1);
          morphs[6] = dom.createElementMorph(element13);
          return morphs;
        },
        statements: [["attribute", "onChange", ["subexpr", "action", ["setMaterialCodeColumn"], ["value", "target.value"], ["loc", [null, [17, 50], [17, 105]]]]], ["block", "each", [["get", "importedColumnNames", ["loc", [null, [18, 22], [18, 41]]]]], [], 0, null, ["loc", [null, [18, 14], [20, 23]]]], ["attribute", "onChange", ["subexpr", "action", ["setOnHandInventoryColumn"], ["value", "target.value"], ["loc", [null, [32, 50], [32, 108]]]]], ["block", "each", [["get", "importedColumnNames", ["loc", [null, [33, 22], [33, 41]]]]], [], 1, null, ["loc", [null, [33, 14], [35, 23]]]], ["block", "if", [["get", "unmatchedMaterialsExist", ["loc", [null, [39, 14], [39, 37]]]]], [], 2, null, ["loc", [null, [39, 8], [66, 15]]]], ["block", "if", [["get", "unmatchedMaterialsExist", ["loc", [null, [71, 14], [71, 37]]]]], [], 3, 4, ["loc", [null, [71, 8], [75, 15]]]], ["element", "action", ["cancelImport"], [], ["loc", [null, [76, 35], [76, 60]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 7
          }
        },
        "moduleName": "webui/pods/components/on-hand-inventory-import-field-selection/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "displayFieldSelectModal", ["loc", [null, [1, 6], [1, 29]]]]], [], 0, null, ["loc", [null, [1, 0], [80, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});