define('webui/pods/admin/site/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(site) {
      return this.store.findRecord('site', site.site_id);
    },
    afterModel: function afterModel(model) {
      model.allClients = this.store.query('client', {});
      model.flights = model.get('flights').sortBy('executeAt').reverse();
      return model;
    },

    actions: {
      doTransition: function doTransition(route, id) {
        if (id) {
          this.transitionTo(route, id);
        } else {
          this.transitionTo(route);
        }
      }
    }
  });
});