define('webui/components/models-table/pagination-numeric', ['exports', 'ember-models-table/components/models-table/pagination-numeric'], function (exports, _paginationNumeric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paginationNumeric.default;
    }
  });
});