define("webui/pods/flight/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "webui/pods/flight/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", [["get", "uiComponent", ["loc", [null, [3, 16], [3, 27]]]]], ["site", ["subexpr", "@mut", [["get", "model.site", ["loc", [null, [3, 33], [3, 43]]]]], [], []], "flight", ["subexpr", "@mut", [["get", "model.flight", ["loc", [null, [3, 51], [3, 63]]]]], [], []]], ["loc", [null, [3, 4], [3, 65]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "webui/pods/flight/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "tiled-map", [], ["allFlights", ["subexpr", "@mut", [["get", "model.allFlights", ["loc", [null, [6, 19], [6, 35]]]]], [], []], "tiledMaps", ["subexpr", "@mut", [["get", "model.tiledMaps", ["loc", [null, [7, 18], [7, 33]]]]], [], []], "site", ["subexpr", "@mut", [["get", "model.site", ["loc", [null, [8, 13], [8, 23]]]]], [], []], "flight", ["subexpr", "@mut", [["get", "model.flight", ["loc", [null, [9, 15], [9, 27]]]]], [], []], "class", "full-height", "mapSliderActivated", ["subexpr", "@mut", [["get", "mapSliderActivated", ["loc", [null, [11, 27], [11, 45]]]]], [], []], "activeSiteUsers", ["subexpr", "@mut", [["get", "model.site.users", ["loc", [null, [12, 24], [12, 40]]]]], [], []], "authenticatedUser", ["subexpr", "@mut", [["get", "model.authenticatedUser", ["loc", [null, [13, 26], [13, 49]]]]], [], []]], ["loc", [null, [5, 4], [14, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 33,
              "column": 2
            }
          },
          "moduleName": "webui/pods/flight/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "site-flight-navigation", [], ["activeFlight", ["subexpr", "@mut", [["get", "model.flight", ["loc", [null, [19, 19], [19, 31]]]]], [], []], "activeSite", ["subexpr", "@mut", [["get", "model.site", ["loc", [null, [20, 17], [20, 27]]]]], [], []], "allSites", ["subexpr", "@mut", [["get", "model.allSites", ["loc", [null, [21, 15], [21, 29]]]]], [], []], "allFlights", ["subexpr", "@mut", [["get", "model.allFlights", ["loc", [null, [22, 17], [22, 33]]]]], [], []], "pendingFlights", ["subexpr", "@mut", [["get", "model.pendingFlights", ["loc", [null, [23, 21], [23, 41]]]]], [], []], "changeDetections", ["subexpr", "@mut", [["get", "model.changeDetections", ["loc", [null, [24, 23], [24, 45]]]]], [], []], "tiledMaps", ["subexpr", "@mut", [["get", "model.tiledMaps", ["loc", [null, [25, 16], [25, 31]]]]], [], []], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [26, 12], [26, 17]]]]], [], []], "goToFileManager", "goToFileManager", "goToMaterialManager", "goToMaterialManager", "goToRegionManager", "goToRegionManager", "mapSliderActivated", ["subexpr", "@mut", [["get", "mapSliderActivated", ["loc", [null, [30, 25], [30, 43]]]]], [], []], "updateFlightModelProducts", "updateFlightModelProducts"], ["loc", [null, [18, 4], [32, 6]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "webui/pods/flight/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "map-block");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 0
            },
            "end": {
              "line": 40,
              "column": 0
            }
          },
          "moduleName": "webui/pods/flight/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "loading-spinner", ["loc", [null, [39, 2], [39, 21]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "webui/pods/flight/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-fluid wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "uiComponent", ["loc", [null, [2, 8], [2, 19]]]]], [], 0, 1, ["loc", [null, [2, 2], [15, 9]]]], ["block", "if", [["get", "hasMapboxLoaded", ["loc", [null, [17, 8], [17, 23]]]]], [], 2, 3, ["loc", [null, [17, 2], [35, 9]]]], ["block", "unless", [["get", "hasMapboxLoaded", ["loc", [null, [38, 10], [38, 25]]]]], [], 4, null, ["loc", [null, [38, 0], [40, 11]]]], ["content", "dynamic-stylesheet", ["loc", [null, [42, 0], [42, 22]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});