define('webui/pods/components/create-map-step-processing-notes/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/data-panel', 'webui/mixins/numerical-conversion'], function (exports, _mixin, _dataPanel, _numericalConversion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, _dataPanel.default, _numericalConversion.default, {
    mapData: Ember.inject.service('map-data'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    fileManager: Ember.inject.service('file-manager'),

    wizardData: null, /* object created in create-map-wizard */
    wizard: null, /* this is passed via the template */

    distance: null,
    accuracy: null,
    contourInterval: null,
    contourResolution: null,
    contourSize: null,
    classification: null,
    densePointCloudLas: false,
    customNotes: null,

    equipment: false,
    conveyors: false,
    vehicles: false,
    buildings: false,

    canSave: true, /* By default the user can skip this page if they don't want to add custom notes. */

    init: function init() {
      this._super.apply(this, arguments);

      if (this.wizardData.get('processingNotes')) {
        var notes = this.wizardData.get('processingNotes');

        this.set('distance', notes.distance);
        this.set('accuracy', notes.accuracy);
        this.set('contourInterval', notes.contourInterval);
        this.set('contourResolution', notes.contourResolution);
        this.set('contourSize', notes.contourSize);
        this.set('densePointCloudLas', notes.densePointCloudLas);
        this.set('customNotes', notes.customNotes);
        this.set('equipment', notes.classification && notes.classification.indexOf('Equipment') !== -1);
        this.set('conveyors', notes.classification && notes.classification.indexOf('Conveyors') !== -1);
        this.set('vehicles', notes.classification && notes.classification.indexOf('Vehicles') !== -1);
        this.set('buildings', notes.classification && notes.classification.indexOf('Buildings') !== -1);
      }

      this.set('useTurboProcessing', this.wizardData.get('useTurboProcessing'));
    },
    evaluateForm: function evaluateForm() {
      var validForm = true;
      this.$('form[name="processingNotes"] input, form[name="processingNotes"] textarea').each(function (i, el) {
        var $el = $(el);
        var inputVal = $el.val();
        var isValid = true;
        if (inputVal.length > 0 && $el.attr('data-val-type')) {
          switch ($el.attr('data-val-type')) {/* Right now we only have numeric fields with basic min/max requirements */
            case 'number':
              if (!$.isNumeric(inputVal)) {
                isValid = false;
              } else {
                if ($el.attr('data-val-min')) {
                  if (inputVal < Number($el.attr('data-val-min'))) {
                    isValid = false;
                  }
                }

                if ($el.attr('data-val-max')) {
                  if (inputVal > Number($el.attr('data-val-max'))) {
                    isValid = false;
                  }
                }
              }
              break;
            default:
              break;
          }
        }

        if (!isValid) {
          $el.addClass('error');
          validForm = false;
        } else {
          $el.removeClass('error');
        }
      });

      this.set('canSave', validForm);
      return validForm;
    },


    actions: {
      save: function save() {
        if (this.evaluateForm()) {
          var classification = [];
          var o = this.wizardData.get('processingNotes') || {};
          this.wizardData.set('processingNotes', null); /* Default to null - this is a catch all in case the user erases a custom value after previously saving. */
          this.$('form[name="processingNotes"] input, form[name="processingNotes"] textarea').each(function (i, el) {
            if (el.type === 'checkbox') {
              o[el.name] = el.checked;
            } else if (el.value.length > 0) {
              o[el.name] = el.value;
            } else if (o[el.name]) {
              delete o[el.name];
            }
          });

          if (o.equipment) {
            classification.push('Equipment');
          }
          if (o.conveyors) {
            classification.push('Conveyors');
          }
          if (o.vehicles) {
            classification.push('Vehicles');
          }
          if (o.buildings) {
            classification.push('Buildings');
          }
          o.classification = classification.join(', ');

          if (!$.isEmptyObject(o)) {
            this.wizardData.set('processingNotes', o);
          }
          this.sendAction('advanceNextStep'); /* Go to step 4 */
        }
      },
      onInputChange: function onInputChange() /* event */{
        this.evaluateForm();
      }
    }
  });
});