define('webui/pods/admin/flight/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(flight) {
      return Ember.RSVP.hash({
        flight: this.store.query('flight', { id: flight.id }).then(function (flights) {
          return flights.objectAt(0);
        })
      });
    },

    actions: {
      doTransition: function doTransition(route, id) {
        if (id) {
          this.transitionTo(route, id);
        } else {
          this.transitionTo(route);
        }
      }
    }
  });
});