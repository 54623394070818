define('webui/pods/components/modal-wrapper/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    doTransition: 'doTransition',

    actions: {
      doTransition: function doTransition(route, id) {
        this.sendAction('doTransition', route, id);
      }
    }
  });
});