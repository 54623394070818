define('webui/models/photo', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fileName: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    sequenceNumber: _emberData.default.attr('string'),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    make: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    latitudeRef: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('number'),
    longitudeRef: _emberData.default.attr('string'),
    altitude: _emberData.default.attr('number'),
    altitudeRef: _emberData.default.attr('number'),
    RtkFlag: _emberData.default.attr('number'),
    RtkStdLon: _emberData.default.attr('number'),
    RtkStdLat: _emberData.default.attr('number'),
    RtkStdHgt: _emberData.default.attr('number'),
    exif: _emberData.default.attr(),
    uploaded: _emberData.default.attr('boolean')
  });
});