define('webui/pods/components/action-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    name: 'action-bar',

    activeSite: null,
    analysesForActiveSite: [],
    activeFlight: null,
    dataPanelService: Ember.inject.service('data-panel'),
    mapData: Ember.inject.service('map-data'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    earthworkAnalyses: [],

    openPanel: {
      'charts-panel': false,
      'site-info-panel': false,
      'flight-info-panel': false,
      'site-access-panel': false,
      'panel-uploader': false,
      'regions-panel': false,
      'downloads-panel': false,
      'panel-blueprints': false,
      'cdt-ui': false,
      'cross-sections': false
    },

    mapSliderActivatedObserver: Ember.observer('mapSliderActivated', function mapSliderActivatedObserver() {
      var dataPanelService = this.get('dataPanelService');
      var panel = dataPanelService.get('dataPanelComponent');

      if (this.get('dataPanelService.mapSliderActivated') && dataPanelService.get('isOpen') && (panel === 'flight-info-panel' || panel === 'quality-reports-panel' || panel === 'classifications-panel' || panel === 'regions-panel' || panel === 'downloads-panel' || panel === 'panel-pictures' || panel === 'site-info-panel')) {
        this.showPanel(panel, 'flight');
      }

      if (this.get('openPanel.cdt-ui')) {
        this.showFloatingPanel('cdt-ui');
      }

      var mapInstance = this.get('mapData.mapInstance');
      mapInstance.getStyle().layers.forEach(function (layer) {
        if (layer.id.substring(0, 8) === 'cut-fill' && mapInstance.getLayer(layer.id)) {
          mapInstance.removeLayer(layer.id);
        }
      });
    }),

    init: function init() {
      var _this = this;

      this._super();
      var component = this;
      var dataPanelService = this.get('dataPanelService');

      // check for panel and/or modal intended to open on initial load (ie redirecting from route with no panels)
      var userIsReadOnly = this.get('store').peekRecord('user', this.get('session.data.authenticated.id')).get('isLimitedAccess');
      var currentPanel = dataPanelService.get('openPanelOnLoad');
      var currentModal = dataPanelService.get('openModalOnLoad');
      if (currentPanel) {
        if (!userIsReadOnly) {
          this.send('showPanel', currentPanel, 'site', true);
        }
        dataPanelService.set('openPanelOnLoad', null);
      }

      if (currentModal) {
        this.set('currentModal', currentModal);
        this.set('openModalOnLoad', currentModal);
      }

      this.setUploadedSurfaceStatus();
      this.set('productSocketHandler', function (event) {
        if (event.verb === 'update') {
          Ember.RSVP.resolve(_this.get('store').pushPayload({ product: event.data })).then(function () {
            return component.setUploadedSurfaceStatus();
          });
        }
      });
      io.socket.on('product', this.get('productSocketHandler'));
      if (this.get('activeFlight.rasterProduct')) io.socket.get('/v1/products/' + this.get('activeFlight.rasterProduct.id'));
    },
    willDestroyElement: function willDestroyElement() {
      io.socket.off('product', this.get('productSocketHandler'));
    },
    setUploadedSurfaceStatus: function setUploadedSurfaceStatus() {
      var activeFlightDoesNotHaveARasterProduct = !this.get('activeFlight.rasterProduct');
      var surfaceIsBeingUploaded = this.get('activeFlight.rasterProduct.status') === 'pending';
      var surfaceWasSuccessfullyReplaced = this.get('activeFlight.rasterProduct.status') === 'succeeded' && this.get('activeFlight.rasterProduct.fileUrl').indexOf('user_replaced') !== -1;

      this.set('isUsingUserUploadedRaster', activeFlightDoesNotHaveARasterProduct || surfaceIsBeingUploaded || surfaceWasSuccessfullyReplaced);
    },
    didRender: function didRender() {
      var dataPanelService = this.get('dataPanelService');

      if (this.get('currentModal') && dataPanelService.get('openModalOnLoad')) {
        $('#onLoadModal').modal('show');
        dataPanelService.set('openModalOnLoad', null);
      }

      if (dataPanelService.get('resetActivePanelLinks')) {
        this.turnOffActivePanelLinks();
        dataPanelService.set('resetActivePanelLinks', false);
      }
    },
    turnOffActivePanelLinks: function turnOffActivePanelLinks() {
      var component = this;
      var panels = Object.keys(this.get('openPanel'));
      panels.forEach(function (thisPanel) {
        component.set('openPanel.' + thisPanel, false);
      });
    },
    showPanel: function showPanel(panelName, panelType, forceOpen) {
      var component = this;
      var dataPanelService = component.get('dataPanelService');

      dataPanelService.set('horizontalSideBar', ['cross-sections-panel', 'quantities-panel'].indexOf(panelName) !== -1);

      // close open floating panel
      if (dataPanelService.get('floatingPanelIsOpen')) {
        var openFloatingPanel = dataPanelService.get('floatingPanelComponent');
        dataPanelService.set('floatingPanelComponent', null);
        dataPanelService.set('floatingPanelIsOpen', false);
        this.set('openPanel.' + openFloatingPanel, false);
      }

      // optional param for bypassing toggle behavior and insisting on an open panel
      if (typeof forceOpen === 'undefined') {
        forceOpen = false;
      }

      if (dataPanelService.get('isOpen') && panelName !== dataPanelService.get('dataPanelComponent')) {
        // Reset all button states
        var panels = Object.keys(this.get('openPanel'));
        panels.forEach(function (thisPanel) {
          component.set('openPanel.' + thisPanel, false);
        });
      }

      // otherwise resume normal toggle behavior
      if (dataPanelService.get('isOpen') && panelName === dataPanelService.get('dataPanelComponent') && !forceOpen) {
        component.toggleProperty('openPanel.' + panelName);
        dataPanelService.toggleProperty('isOpen');
      } else {
        if (dataPanelService.get('isOpen') && dataPanelService.get('dataPanelComponent') === 'quality-reports-panel' && dataPanelService.get('dataPanelComponent') !== panelName) {
          component.toggleProperty('openPanel.' + panelName);
          dataPanelService.toggleProperty('isOpen');
        } // manually closing here before reopening allows QR panel react unmount lifecycle function to run

        Ember.run.next(function () {
          component.set('openPanel.' + panelName, true);
          dataPanelService.set('currentPanelType', panelType);
          dataPanelService.set('dataPanelComponent', panelName);
          dataPanelService.set('isOpen', true);
        });
      }
      Ember.run.next(function () {
        dataPanelService.set('openPanelOnLoad', panelName);
      });
    },
    showFloatingPanel: function showFloatingPanel(panelName) {
      // close regular panels and reset button state
      var dataPanelService = this.get('dataPanelService');
      var openPanel = dataPanelService.get('dataPanelComponent');
      var openFloatingPanel = dataPanelService.get('floatingPanelComponent');

      dataPanelService.set('horizontalSideBar', false);
      // toggle off and return, if specified panel is currently open
      if (openFloatingPanel && openFloatingPanel === panelName) {
        dataPanelService.set('floatingPanelComponent', null);
        dataPanelService.set('floatingPanelIsOpen', false);
        this.set('openPanel.' + panelName, false);
        return;
      }

      if (openPanel) {
        dataPanelService.set('dataPanelComponent', null);
        dataPanelService.set('isOpen', false);
        this.set('openPanel.' + openPanel, false);
      }

      // display specified panel
      this.set('openPanel.' + panelName, true);
      dataPanelService.set('floatingPanelComponent', panelName);
      dataPanelService.set('floatingPanelIsOpen', true);
    },


    actions: {
      showFloatingPanel: function showFloatingPanel(panelName) {
        this.showFloatingPanel(panelName);
      },
      showPanel: function showPanel(panelName, panelType, forceOpen) {
        this.showPanel(panelName, panelType, forceOpen);
      }
    }
  });
});