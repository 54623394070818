define('webui/services/data-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isOpen: false,
    activeAnalyses: [],
    dataPanelState: {}, /* Transient object used to store incidental state while a panel is open. This is cleared when the panel type changes. */
    persistantDataPanelState: {}, /* Persistant object used to store state that should persist when changing panels. */
    dataPanelComponent: undefined,
    currentPanelType: undefined,
    openPanelOnLoad: null,
    openModalOnLoad: null,
    resetActivePanelLinks: false,
    horizontalSideBar: false,
    showTrueTonnageReport: false,
    mapSliderActivated: false,
    shouldRender: false,
    tonnageVarianceWarningThreshold: 5,
    isOpenRegionalAction: false,
    // TODO: Temporary fix for charts not being replotted.
    // Charts-panel depends on activeAnalyses being modified to replot data.
    // Find another hook for this.
    dataPanelChanged: function dataPanelChanged() {
      if (this.get('dataPanelComponent') !== 'charts-panel') {
        this.set('activeAnalyses', []);
      }

      this.set('dataPanelState', {}); /* On component change clear any stored state. */
    }.observes('dataPanelComponent'),

    closeOpenPanels: function closeOpenPanels() {
      this.set('dataPanelComponent', null);
      this.set('isOpen', false);
      this.set('floatingPanelComponent', null);
      this.set('floatingPanelIsOpen', false);
      this.set('resetActivePanelLinks', true);
    },
    toggleMapSlider: function toggleMapSlider() {
      this.toggleProperty('mapSliderActivated');
    },
    setTonnageVarianceWarningThreshold: function setTonnageVarianceWarningThreshold(tonnageVarianceWarningThreshold) {
      this.set('tonnageVarianceWarningThreshold', tonnageVarianceWarningThreshold);
    }
  });
});