define('webui/react/FileManager', ['exports', 'npm:react', 'webui/react/FileManagerList', 'webui/react/FileManagerPluginDsm', 'webui/react/FileManagerPluginDxf', 'webui/react/FileManagerPluginRegionDxf', 'webui/react/AlertBar', 'webui/config/environment'], function (exports, _npmReact, _FileManagerList, _FileManagerPluginDsm, _FileManagerPluginDxf, _FileManagerPluginRegionDxf, _AlertBar, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var FileManager = function (_React$Component) {
    _inherits(FileManager, _React$Component);

    /* ====== Lifecycle events ====== */

    function FileManager(props) {
      _classCallCheck(this, FileManager);

      var _this = _possibleConstructorReturn(this, (FileManager.__proto__ || Object.getPrototypeOf(FileManager)).call(this, props));

      var startingMode = _this.props.startingMode;


      /* Add new plugins to this array. The name must be unique or there will be conflicts. */
      _this.plugins = [{ name: 'dsm', element: _FileManagerPluginDsm.default }, { name: 'dxf', element: _FileManagerPluginDxf.default }, { name: 'region-dxf', element: _FileManagerPluginRegionDxf.default }];

      _this.state = {
        uploadProgress: 0,
        mode: startingMode || 'dsm',
        newFileMode: false,
        messageQueue: [],
        activeMessage: null,
        lastMove: null,
        lastHover: null
      };

      _this.changeMode = _this.changeMode.bind(_this);

      _this.uploadFile = _this.uploadFile.bind(_this);
      _this.deleteFile = _this.deleteFile.bind(_this);
      _this.changeFile = _this.changeFile.bind(_this);
      _this.registerUploadProgress = _this.registerUploadProgress.bind(_this);
      _this.updateOrder = _this.updateOrder.bind(_this);
      _this.updateFileStatus = _this.updateFileStatus.bind(_this);
      _this.updateHover = _this.updateHover.bind(_this);
      _this.updateMove = _this.updateMove.bind(_this);
      _this.saveOrder = _this.saveOrder.bind(_this);

      _this.changeNewFileMode = _this.changeNewFileMode.bind(_this);
      _this.changeSite = _this.changeSite.bind(_this);

      _this.closeMessage = _this.closeMessage.bind(_this);
      _this.raiseMessage = _this.raiseMessage.bind(_this);
      return _this;
    }

    _createClass(FileManager, [{
      key: 'componentDidMount',
      value: function componentDidMount() {
        var _this2 = this;

        /* DOM manipulation and data fetching go here. */
        this.plugins.forEach(function (el) {
          return _this2.fetchFiles(el);
        });
      }
    }, {
      key: 'componentDidUpdate',
      value: function componentDidUpdate() {
        var _state = this.state,
            activeMessage = _state.activeMessage,
            messageQueue = _state.messageQueue;

        if (messageQueue.length > 0 && !activeMessage) {
          this.showNextMessage();
        }
      }
    }, {
      key: 'fetchFiles',
      value: function fetchFiles(el) {
        var component = this;
        if (el.element.fetchFiles) {
          /* Dynamic state indicating that the files have begun to be fetched */
          var x = {};
          x['fetching_files_' + el.name] = true;
          this.setState(x);

          el.element.fetchFiles(component.props.dataService, component.props.site.get('id'), component.props.siteDataAccess).then(function (results) {
            var o = {};
            o['files_' + el.name] = results;
            o['fetching_files_' + el.name] = false;
            component.setState(o);
          });
        } else {
          console.error('A file plugin does not define the static fetchFiles() method.', el);
        }
      }
    }, {
      key: 'bubbleEmberAction',
      value: function bubbleEmberAction(actionName) {
        var _emberComponent$actio;

        var emberComponent = this.props.emberComponent;

        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        (_emberComponent$actio = emberComponent.actions[actionName]).call.apply(_emberComponent$actio, [emberComponent].concat(args));
      }
    }, {
      key: 'unshiftFile',
      value: function unshiftFile(file, category) {
        var component = this;

        component.setState(function (prevState /* , props */) {
          var tmp = {};
          var x = prevState['files_' + category].slice();

          x.unshift(file);

          tmp['files_' + category] = x;
          return tmp;
        });
      }
    }, {
      key: 'pushFile',
      value: function pushFile(file, category) {
        var component = this;
        component.setState(function (prevState /* , props */) {
          var tmp = {};
          var x = prevState['files_' + category].slice();

          x.push(file);

          tmp['files_' + category] = x;
          return tmp;
        });
      }
    }, {
      key: 'removeFile',
      value: function removeFile(file, category) {
        var component = this;
        component.setState(function (prevState /* , props */) {
          var tmp = {};

          var x = prevState['files_' + category].slice();

          var i = x.findIndex(function (el) {
            return el.id === file.id;
          });
          if (i > -1) {
            x.splice(i, 1); /* Remove the file by index - note splice() changes the array in-place and returns the removed element. */
            tmp['files_' + category] = x;
            return tmp;
          }
          return null;
        });
      }
    }, {
      key: 'swapFile',
      value: function swapFile(fileToReplace, newFile, category) {
        var component = this;
        component.setState(function (prevState /* , props */) {
          var tmp = {};

          var x = prevState['files_' + category].slice();
          var i = x.indexOf(fileToReplace);
          if (i > -1) {
            x[i] = newFile;
          }

          tmp['files_' + category] = x;
          return tmp;
        });
      }
    }, {
      key: 'deleteFile',
      value: function deleteFile(file, category) {
        this.removeFile(file, category);
      }
    }, {
      key: 'changeFile',
      value: function changeFile(originalFile, changedFile, category) {
        this.swapFile(originalFile, changedFile, category);
      }
    }, {
      key: 'uploadFile',
      value: function uploadFile(tempFile, file, category) {
        this.cancelNewFile();
        if (tempFile) {
          this.swapFile(tempFile, file, category);
        } else {
          this.unshiftFile(file, category);
        }
        this.saveOrder();
      }
    }, {
      key: 'registerUploadProgress',
      value: function registerUploadProgress(object, progress, category) {
        // eslint-disable-next-line react/destructuring-assignment
        var tmp = this.state['files_' + category].slice();
        var i = tmp.indexOf(object);
        if (i > -1) {
          tmp[i].uploadProgress = progress;

          var x = {};
          x['files_' + category] = tmp;

          this.setState(x);
        }
      }
    }, {
      key: 'changeMode',
      value: function changeMode(newMode) {
        var _this3 = this;

        this.setState({ mode: newMode });
        this.clean();

        this.plugins.forEach(function (el) {
          if (el.name === newMode) {
            _this3.fetchFiles(el);
          }
        });
      }
    }, {
      key: 'changeNewFileMode',
      value: function changeNewFileMode(enabled) {
        if (enabled) {
          this.enableNewFileMode();
        } else {
          this.cancelNewFile();
        }
      }
    }, {
      key: 'clean',
      value: function clean() {
        /* Return any user-induced states to defaults. */
        this.setState({ newFileMode: false });
      }
    }, {
      key: 'enableNewFileMode',
      value: function enableNewFileMode() {
        this.setState({ newFileMode: true });
      }
    }, {
      key: 'cancelNewFile',
      value: function cancelNewFile() {
        this.setState({ newFileMode: false });
      }
    }, {
      key: 'changeSite',
      value: function changeSite(event) {
        var emberComponent = this.props.emberComponent;
        var mode = this.state.mode;

        emberComponent.actions.doSiteChange.call(emberComponent, event.target.value, mode);
      }
    }, {
      key: 'cancel',
      value: function cancel() {
        var site = this.props.site;

        this.bubbleEmberAction('doExitFileManager', site.id);
      }
    }, {
      key: 'raiseMessage',
      value: function raiseMessage(message) {
        var component = this;

        this.setState(function (prevState /* , props */) {
          var mq = prevState.messageQueue.slice();
          mq.push(message);
          return { messageQueue: mq };
        }, function () {
          component.showNextMessage(); /* This auto-shows the latest message. Remove to switch to a queue-based model. */
        });
      }
    }, {
      key: 'closeMessage',
      value: function closeMessage() /* message */{
        this.setState(function () {
          return (/* prevState, props */{ activeMessage: null }
          );
        });

        this.showNextMessage();
      }
    }, {
      key: 'showNextMessage',
      value: function showNextMessage() {
        this.setState(function (prevState /* , props */) {
          if (prevState.messageQueue.length > 0) {
            var mq = prevState.messageQueue.slice();
            var m = mq.splice(0, 1);
            return { activeMessage: m[0], messageQueue: mq };
          }

          return null;
        });
      }
    }, {
      key: 'updateHover',
      value: function updateHover() {
        var _setState;

        var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var mode = this.state.mode;

        // eslint-disable-next-line react/destructuring-assignment
        var currentFiles = this.state['files_' + mode];
        var newFiles = [];

        currentFiles.forEach(function (file) {
          file.hover = key === file.id;
          newFiles.push(file);
        });

        // eslint-disable-next-line react/destructuring-assignment
        this.setState((_setState = {}, _defineProperty(_setState, 'files_' + mode, newFiles), _defineProperty(_setState, 'lastHover', key), _setState));
      }
    }, {
      key: 'updateMove',
      value: function updateMove() {
        var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        this.setState({ lastMove: key });
      }
    }, {
      key: 'updateFileStatus',
      value: function updateFileStatus(updatedFile) {
        var mode = this.state.mode;

        var updatedFileId = updatedFile.id;
        // eslint-disable-next-line react/destructuring-assignment
        var currentFiles = [].concat(_toConsumableArray(this.state['files_' + mode]));
        var updatedFiles = currentFiles.map(function (file) {
          var currentFile = file;
          if (file.id === updatedFileId) {
            currentFile.fileSize = updatedFile.fileSize;
            currentFile.status = updatedFile.status;
            currentFile.message = updatedFile.message;
            if (updatedFile.regionCount) currentFile.regionCount = updatedFile.regionCount;
          }

          return currentFile;
        });

        this.setState(_defineProperty({}, 'files_' + mode, updatedFiles));
      }
    }, {
      key: 'updateOrder',
      value: function updateOrder() {
        var _state2 = this.state,
            lastHover = _state2.lastHover,
            lastMove = _state2.lastMove,
            mode = _state2.mode;

        var from = lastMove;
        var to = lastHover;
        // eslint-disable-next-line react/destructuring-assignment
        var currentFiles = this.state['files_' + mode];
        var movedFile = currentFiles.filter(function (file) {
          return !file.id && from === 'new' || file.id === from;
        })[0];
        var changesNeedSaved = false;
        var newFiles = [];

        if (to === 'top') {
          newFiles.push(movedFile);
        }

        if (to !== from && from !== null && to !== null) {
          currentFiles.forEach(function (file) {
            if (file.id !== movedFile.id) {
              newFiles.push(file);
              if (!file.id && to === 'new' || file.id === to) {
                newFiles.push(movedFile);
              }
            }
          });

          for (var i = 0; i < newFiles.length; i += 1) {
            if (newFiles[i].id !== currentFiles[i].id) {
              changesNeedSaved = true;
            }
          }

          this.setState({ lastHover: null, lastMove: null });
          if (changesNeedSaved) {
            this.setState(_defineProperty({}, 'files_' + mode, newFiles));
            this.saveOrder();
            this.raiseMessage({ severity: 'success', message: 'Comparison Surface Order Saved' });
          }
        }
      }
    }, {
      key: 'saveOrder',
      value: function saveOrder() {
        var component = this;
        var mode = this.state.mode;


        if (mode === 'dsm') {
          setTimeout(function () {
            var reorderedFiles = component.state['files_' + component.state.mode].toArray();
            for (var i = 0; i < reorderedFiles.length; i += 1) {
              var url = _environment.default.APP.apiUrl + 'blueprints/' + reorderedFiles[i].id;
              var data = JSON.stringify({ blueprint: { id: reorderedFiles[i].id, order: i + 1 } });

              $.ajax({
                method: 'PUT', contentType: 'json', url: url, data: data, crossDomain: true, xhrFields: { withCredentials: true }
              });
            }
          }, 500);
        }
      }
    }, {
      key: 'render',
      value: function render() {
        var _this4 = this;

        var component = this;
        var _state3 = this.state,
            activeMessage = _state3.activeMessage,
            lastHover = _state3.lastHover,
            mode = _state3.mode,
            newFileMode = _state3.newFileMode;
        var _props = this.props,
            dataService = _props.dataService,
            fileUploadService = _props.fileUploadService,
            statusService = _props.statusService;
        var _props2 = this.props,
            availableSites = _props2.availableSites,
            site = _props2.site;

        /* React complains if we pass an ember object into JSX so we need to get the raw properties as a JSON object. Debatable if we should do this before passing the array or not... */
        site = site.toJSON();
        site.id = component.props.site.get('id');

        availableSites = availableSites.toArray().map(function (el) {
          var o = el;
          o.id = el.id;
          o.key = o.id;
          return o;
        });

        // eslint-disable-next-line react/destructuring-assignment
        var areFilesFetched = this.state['fetching_files_' + mode];
        // eslint-disable-next-line react/destructuring-assignment
        var listedFiles = this.state['files_' + mode] || []; /* The default empty array here is to prevent the result from ever being null so Array.length statements don't fail. */
        var activePlugin = this.plugins.find(function (el) {
          return el.name === component.state.mode;
        });

        var siteNameDisplay = 'File Manager for ' + site.name;
        return _npmReact.default.createElement(
          'div',
          { className: 'file-manager' },
          _npmReact.default.createElement(
            'div',
            { className: 'col-xs-12 alert-bar-wrapper' },
            _npmReact.default.createElement(_AlertBar.default, { activeMessage: activeMessage, onMessageClose: this.closeMessage })
          ),
          _npmReact.default.createElement(
            'div',
            { className: 'col-xs-2 sidebar' },
            this.plugins.map(function (o) {
              return o.element.renderMenu(mode, component.changeMode);
            })
          ),
          _npmReact.default.createElement(
            'div',
            { className: 'col-xs-10 main' },
            _npmReact.default.createElement(
              'div',
              { className: 'menu' },
              _npmReact.default.createElement(
                'button',
                { className: 'btn', type: 'button', onClick: function onClick() {
                    _this4.cancel();
                  } },
                _npmReact.default.createElement('span', { className: 'glyphicon glyphicon-arrow-left' }),
                ' ',
                'Back to Map'
              )
            ),
            _npmReact.default.createElement(
              'div',
              { className: 'main-content' },
              _npmReact.default.createElement(
                'h1',
                null,
                siteNameDisplay
              ),
              _npmReact.default.createElement(
                'div',
                { className: 'site-selector row' },
                _npmReact.default.createElement(
                  'div',
                  { className: 'col-xs-5 col-md-4' },
                  _npmReact.default.createElement(
                    'div',
                    { className: 'head navigation-menu-label' },
                    'Site name:'
                  ),
                  _npmReact.default.createElement(
                    'select',
                    { name: 'sitename', className: 'form-control siteSelect selectpicker', defaultValue: site.id, 'data-live-search': 'true', onChange: this.changeSite },
                    _npmReact.default.createElement(
                      'optgroup',
                      { label: 'Current Site' },
                      _npmReact.default.createElement(
                        'option',
                        { value: 'null', disabled: true },
                        site.name
                      )
                    ),
                    _npmReact.default.createElement(
                      'optgroup',
                      { label: 'Options' },
                      availableSites.map(function (el) {
                        return _npmReact.default.createElement(
                          'option',
                          { value: el.id, key: el.id },
                          el.name
                        );
                      })
                    )
                  )
                )
              ),
              _npmReact.default.createElement(_FileManagerList.default, {
                updateOrder: this.updateOrder,
                updateFileStatus: this.updateFileStatus,
                updateMove: this.updateMove,
                updateHover: this.updateHover,
                lastHover: lastHover,
                files: listedFiles,
                fetchingFiles: areFilesFetched,
                plugin: activePlugin,
                newFileMode: newFileMode,
                fileUploadService: fileUploadService,
                dataService: dataService,
                statusService: statusService,
                mode: mode,
                changeMode: component.changeMode,
                saveOrder: component.saveOrder,
                site: site,
                onUploadFile: this.uploadFile,
                onUploadProgress: this.registerUploadProgress,
                onDeleteFile: this.deleteFile,
                onChangeFile: this.changeFile,
                onChangeNewFileMode: this.changeNewFileMode,
                onRaiseMessage: this.raiseMessage
              })
            )
          )
        );
      }
    }]);

    return FileManager;
  }(_npmReact.default.Component);

  exports.default = FileManager;
});