define('webui/initializers/ember-tooltips', ['exports', 'webui/config/environment', 'webui/mixins/components/tooltips'], function (exports, _environment, _tooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  /* This is in the app tree so we can access ENV */

  function initialize() {
    var defaultOptions = {
      addTo: ['Component']
    };
    var overridingOptions = _environment.default.tooltips || {};
    var options = Ember.merge(defaultOptions, overridingOptions);

    /* TODO - Needs test coverage for addTo */

    if (Ember.typeOf(options.addTo) === 'array') {
      options.addTo.forEach(function (className) {
        Ember[className].reopen(_tooltips.default);
      });
    }
  }

  exports.default = {
    name: 'ember-tooltips',
    initialize: initialize
  };
});