define('webui/pods/field-login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel(transition) {
      var context = this;
      var _transition$params$fi = transition.params['field-login'],
          id = _transition$params$fi.id,
          token = _transition$params$fi.token,
          flight = _transition$params$fi.flight;


      if (this.get('session.data.authenticated.fieldUser.id')) {
        context.set('hasErrors', false);
        return context.transitionTo('field', flight);
      }

      return this.get('session').authenticate('authenticator:custom', { id: id, token: token, type: 'field' }).then(function () {
        context.set('hasErrors', false);
        return context.transitionTo('field', flight);
      }).catch(function (err) {
        var serverResponse = err.responseJSON;
        if (serverResponse && serverResponse.detail) {
          context.set('errorMessage', serverResponse.detail);
        } else if (err.status === 403) {
          context.set('errorMessage', 'This Field Link Has Expired');
        } else {
          context.set('errorMessage', 'Server Error. Please try again in a few minutes');
        }

        context.set('hasErrors', true);
      });
    },
    setupController: function setupController(controller) {
      controller.set('errorMessage', this.get('errorMessage'));
      controller.set('hasErrors', this.get('hasErrors'));
    }
  });
});