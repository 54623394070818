define('webui/pods/account/client/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['activeTab', 'showCreate'],
    activeTab: null,
    showCreate: false
  });
});