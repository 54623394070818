define("webui/pods/components/cdt-available-comparisons/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "newRegionButton");
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn");
          var el3 = dom.createTextNode("GO TO FILE MANAGER");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element14);
          return morphs;
        },
        statements: [["element", "action", ["goToFileManager"], [], ["loc", [null, [9, 55], [9, 83]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 12
                },
                "end": {
                  "line": 17,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "#");
              dom.setAttribute(el1, "class", "active");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element11);
              morphs[1] = dom.createMorphAt(element11, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["hideComparison", ["get", "blueprint.changeDetection", ["loc", [null, [16, 67], [16, 92]]]], ["get", "blueprint", ["loc", [null, [16, 93], [16, 102]]]]], [], ["loc", [null, [16, 41], [16, 104]]]], ["content", "blueprint.name", ["loc", [null, [16, 105], [16, 123]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 12
                  },
                  "end": {
                    "line": 19,
                    "column": 12
                  }
                },
                "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "href", "#");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element10);
                morphs[1] = dom.createMorphAt(element10, 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["displayComparison", ["get", "blueprint.changeDetection", ["loc", [null, [18, 55], [18, 80]]]], ["get", "blueprint", ["loc", [null, [18, 81], [18, 90]]]], "blueprint"], [], ["loc", [null, [18, 26], [18, 104]]]], ["content", "blueprint.name", ["loc", [null, [18, 105], [18, 123]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 20,
                      "column": 14
                    },
                    "end": {
                      "line": 22,
                      "column": 14
                    }
                  },
                  "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "href", "#");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element9);
                  morphs[1] = dom.createMorphAt(element9, 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["selectComparison", "blueprint", ["get", "blueprint", ["loc", [null, [21, 68], [21, 77]]]]], [], ["loc", [null, [21, 28], [21, 79]]]], ["content", "blueprint.name", ["loc", [null, [21, 80], [21, 98]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 19,
                    "column": 12
                  },
                  "end": {
                    "line": 23,
                    "column": 12
                  }
                },
                "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "unless", [["get", "isReadOnly", ["loc", [null, [20, 24], [20, 34]]]]], [], 0, null, ["loc", [null, [20, 14], [22, 25]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 12
                },
                "end": {
                  "line": 23,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "blueprint.changeDetection.status", ["loc", [null, [17, 22], [17, 54]]]]], [], 0, 1, ["loc", [null, [17, 12], [23, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 12
                },
                "end": {
                  "line": 28,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-ok");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 12
                },
                "end": {
                  "line": 31,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-warning-sign");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element8, 'title');
              return morphs;
            },
            statements: [["attribute", "title", ["concat", [["get", "blueprint.changeDetection.message.message", ["loc", [null, [30, 70], [30, 111]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 12
                },
                "end": {
                  "line": 34,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-info-sign");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element7, 'title');
              return morphs;
            },
            statements: [["attribute", "title", ["concat", [["get", "blueprint.changeDetection.message.message", ["loc", [null, [33, 67], [33, 108]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child5 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 12
                },
                "end": {
                  "line": 37,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-repeat spinner");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 40,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-10");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-2 noclick");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var element13 = dom.childAt(element12, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element12, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element13, 1, 1);
            morphs[2] = dom.createMorphAt(element13, 2, 2);
            morphs[3] = dom.createMorphAt(element13, 3, 3);
            morphs[4] = dom.createMorphAt(element13, 4, 4);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "activeChangeDetection", ["loc", [null, [15, 23], [15, 44]]]], ["subexpr", "eq", [["get", "blueprint.changeDetection.id", ["loc", [null, [15, 49], [15, 77]]]], ["get", "activeChangeDetection", ["loc", [null, [15, 78], [15, 99]]]]], [], ["loc", [null, [15, 45], [15, 100]]]]], [], ["loc", [null, [15, 18], [15, 101]]]]], [], 0, 1, ["loc", [null, [15, 12], [23, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "blueprint.changeDetection.status", ["loc", [null, [26, 22], [26, 54]]]], "succeeded"], [], ["loc", [null, [26, 18], [26, 67]]]]], [], 2, null, ["loc", [null, [26, 12], [28, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "blueprint.changeDetection.status", ["loc", [null, [29, 22], [29, 54]]]], "failed"], [], ["loc", [null, [29, 18], [29, 64]]]]], [], 3, null, ["loc", [null, [29, 12], [31, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "blueprint.changeDetection.status", ["loc", [null, [32, 22], [32, 54]]]], "warning"], [], ["loc", [null, [32, 18], [32, 65]]]]], [], 4, null, ["loc", [null, [32, 12], [34, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "blueprint.changeDetection.status", ["loc", [null, [35, 22], [35, 54]]]], "pending"], [], ["loc", [null, [35, 18], [35, 65]]]]], [], 5, null, ["loc", [null, [35, 12], [37, 19]]]]],
          locals: ["blueprint"],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 41,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "availableBlueprints", ["loc", [null, [12, 14], [12, 33]]]]], [], 0, null, ["loc", [null, [12, 6], [40, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 4
            },
            "end": {
              "line": 43,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("em");
          var el2 = dom.createTextNode("You don't have any surfaces to compare to yet. Upload using the file manager.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 12
                },
                "end": {
                  "line": 53,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "#");
              dom.setAttribute(el1, "class", "active");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "map-subtitle");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element4, 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["hideComparison", ["get", "flight.changeDetection", ["loc", [null, [52, 67], [52, 89]]]], ["get", "flight", ["loc", [null, [52, 90], [52, 96]]]]], [], ["loc", [null, [52, 41], [52, 98]]]], ["content", "flight.displayFlightTime", ["loc", [null, [52, 99], [52, 127]]]], ["content", "flight.subtitle", ["loc", [null, [52, 154], [52, 173]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 12
                  },
                  "end": {
                    "line": 55,
                    "column": 12
                  }
                },
                "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "href", "#");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "map-subtitle");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element3);
                morphs[1] = dom.createMorphAt(element3, 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["displayComparison", ["get", "flight.changeDetection", ["loc", [null, [54, 55], [54, 77]]]], ["get", "flight", ["loc", [null, [54, 78], [54, 84]]]], "flight"], [], ["loc", [null, [54, 26], [54, 95]]]], ["content", "flight.displayFlightTime", ["loc", [null, [54, 96], [54, 124]]]], ["content", "flight.subtitle", ["loc", [null, [54, 151], [54, 170]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 14
                    },
                    "end": {
                      "line": 58,
                      "column": 14
                    }
                  },
                  "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "href", "#");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "map-subtitle");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element2);
                  morphs[1] = dom.createMorphAt(element2, 0, 0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["selectComparison", "flight", ["get", "flight", ["loc", [null, [57, 65], [57, 71]]]]], [], ["loc", [null, [57, 28], [57, 73]]]], ["content", "flight.displayFlightTime", ["loc", [null, [57, 74], [57, 102]]]], ["content", "flight.subtitle", ["loc", [null, [57, 129], [57, 148]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 12
                  },
                  "end": {
                    "line": 59,
                    "column": 12
                  }
                },
                "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "unless", [["get", "isReadOnly", ["loc", [null, [56, 24], [56, 34]]]]], [], 0, null, ["loc", [null, [56, 14], [58, 25]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 12
                },
                "end": {
                  "line": 59,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "flight.changeDetection.status", ["loc", [null, [53, 22], [53, 51]]]]], [], 0, 1, ["loc", [null, [53, 12], [59, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 12
                },
                "end": {
                  "line": 64,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-ok");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 12
                },
                "end": {
                  "line": 67,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-warning-sign");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              return morphs;
            },
            statements: [["attribute", "title", ["concat", [["get", "flight.changeDetection.message.message", ["loc", [null, [66, 70], [66, 108]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 12
                },
                "end": {
                  "line": 70,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-info-sign");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'title');
              return morphs;
            },
            statements: [["attribute", "title", ["concat", [["get", "flight.changeDetection.message.message", ["loc", [null, [69, 67], [69, 105]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child5 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 12
                },
                "end": {
                  "line": 73,
                  "column": 12
                }
              },
              "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-repeat spinner");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 6
              },
              "end": {
                "line": 76,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-10");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-2 noclick");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element6, 1, 1);
            morphs[2] = dom.createMorphAt(element6, 2, 2);
            morphs[3] = dom.createMorphAt(element6, 3, 3);
            morphs[4] = dom.createMorphAt(element6, 4, 4);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "activeChangeDetection", ["loc", [null, [51, 23], [51, 44]]]], ["subexpr", "eq", [["get", "flight.changeDetection.id", ["loc", [null, [51, 49], [51, 74]]]], ["get", "activeChangeDetection", ["loc", [null, [51, 75], [51, 96]]]]], [], ["loc", [null, [51, 45], [51, 97]]]]], [], ["loc", [null, [51, 18], [51, 98]]]]], [], 0, 1, ["loc", [null, [51, 12], [59, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "flight.changeDetection.status", ["loc", [null, [62, 22], [62, 51]]]], "succeeded"], [], ["loc", [null, [62, 18], [62, 64]]]]], [], 2, null, ["loc", [null, [62, 12], [64, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "flight.changeDetection.status", ["loc", [null, [65, 22], [65, 51]]]], "failed"], [], ["loc", [null, [65, 18], [65, 61]]]]], [], 3, null, ["loc", [null, [65, 12], [67, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "flight.changeDetection.status", ["loc", [null, [68, 22], [68, 51]]]], "warning"], [], ["loc", [null, [68, 18], [68, 62]]]]], [], 4, null, ["loc", [null, [68, 12], [70, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "flight.changeDetection.status", ["loc", [null, [71, 22], [71, 51]]]], "pending"], [], ["loc", [null, [71, 18], [71, 62]]]]], [], 5, null, ["loc", [null, [71, 12], [73, 19]]]]],
          locals: ["flight"],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 4
            },
            "end": {
              "line": 77,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "availableFlights", ["loc", [null, [48, 14], [48, 30]]]]], [], 0, null, ["loc", [null, [48, 6], [76, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 6
            },
            "end": {
              "line": 79,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("em");
          var el2 = dom.createTextNode("There are no older flights.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 83,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/cdt-available-comparisons/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dropdown");
        dom.setAttribute(el1, "id", "cdt-dropdown-menu");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default dropdown-toggle");
        dom.setAttribute(el2, "type", "button");
        dom.setAttribute(el2, "data-toggle", "dropdown");
        dom.setAttribute(el2, "aria-haspopup", "true");
        dom.setAttribute(el2, "aria-expanded", "true");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "glyphicon glyphicon-triangle-right");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "dropdown-menu dropdown-menu-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "dropdown-header");
        var el4 = dom.createTextNode("Comparison Surfaces");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "role", "separator");
        dom.setAttribute(el3, "class", "divider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "dropdown-header spacer");
        var el4 = dom.createTextNode("Flights");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "role", "separator");
        dom.setAttribute(el3, "class", "divider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0, 3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element15, 5, 5);
        morphs[1] = dom.createMorphAt(element15, 6, 6);
        morphs[2] = dom.createMorphAt(element15, 12, 12);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isReadOnly", ["loc", [null, [8, 14], [8, 24]]]]], [], 0, null, ["loc", [null, [8, 4], [10, 15]]]], ["block", "if", [["subexpr", "gt", [["get", "availableBlueprints.length", ["loc", [null, [11, 14], [11, 40]]]], 0], [], ["loc", [null, [11, 10], [11, 43]]]]], [], 1, 2, ["loc", [null, [11, 4], [43, 11]]]], ["block", "if", [["subexpr", "gt", [["get", "availableFlights.length", ["loc", [null, [47, 14], [47, 37]]]], 0], [], ["loc", [null, [47, 10], [47, 40]]]]], [], 3, 4, ["loc", [null, [47, 4], [79, 11]]]], ["content", "yield", ["loc", [null, [82, 0], [82, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});