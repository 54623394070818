define('webui/mixins/site-grade', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service('store'),
    settingsManager: Ember.inject.service('settings-manager'),

    getSiteGrades: function getSiteGrades() {
      var site = this.get('settingsManager').getSite();
      var allBlueprints = this.get('store').peekAll('blueprint');
      var siteGradesForCurrentSite = allBlueprints.filterBy('site.id', site.id).filterBy('type', 'site-grade').filterBy('status', 'succeeded');
      var primaryBlueprintHasBeenChosenByUser = siteGradesForCurrentSite.toArray().length && siteGradesForCurrentSite.toArray()[0].get('order');
      var sortedSiteGrades = siteGradesForCurrentSite.sortBy(primaryBlueprintHasBeenChosenByUser ? 'order' : 'createdAt');

      return sortedSiteGrades;
    },
    getPrimarySiteGrade: function getPrimarySiteGrade() {
      var siteGrades = this.getSiteGrades();
      var primaryBlueprintHasBeenChosenByUser = siteGrades.toArray().length && siteGrades.toArray()[0].get('order');

      return primaryBlueprintHasBeenChosenByUser ? siteGrades.get('firstObject') : siteGrades.get('lastObject'); // if they manually set order then take primary aka first, other wise last
    }
  });
});