define('webui/models/earthwork-analysis', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    type: _emberData.default.attr('string'),
    cubicYardsCut: _emberData.default.attr('number'),
    cubicYardsFill: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    region: _emberData.default.belongsTo('region', { async: true }),
    trackingRegion: _emberData.default.belongsTo('trackingRegion', { async: true }),
    product: _emberData.default.belongsTo('product', { async: true }),
    blueprint: _emberData.default.belongsTo('blueprint', { async: true }),
    executeAt: Ember.computed('flight', function executeAt() {
      return this.get('flight.executeAt');
    }),
    flightTime: Ember.computed('executeAt', function flightTime() {
      return this.dateString(this.get('executeAt'));
    }),
    cubicYardsCutDisplay: Ember.computed('cubicYardsCut', function cubicYardsCutDisplay() {
      return this.numberWithCommas(this.get('cubicYardsCut'));
    }),
    cubicYardsFillDisplay: Ember.computed('cubicYardsFill', function cubicYardsFillDisplay() {
      return this.numberWithCommas(Math.abs(this.get('cubicYardsFill')));
    }),
    netVolume: Ember.computed('cubicYardsFill', 'cubicYardsCut', function netVolume() {
      return this.numberWithCommas(this.get('cubicYardsCut') + this.get('cubicYardsFill'));
    }),
    netVolumeNumber: Ember.computed('cubicYardsFill', 'cubicYardsCut', function netVolumeNumber() {
      return this.get('cubicYardsCut') + this.get('cubicYardsFill');
    }),
    statusDisplay: Ember.computed('status', 'cubicYardsFill', 'region', function statusDisplay() {
      return this.get('status');
    }),
    messageDisplay: Ember.computed('status', 'message', 'cubicYardsFill', 'region', function messageDisplay() {
      var message = '';

      if (this.get('message.name')) {
        message += this.get('message.name') + ': ';
      }

      if (this.get('message.message')) {
        message += this.get('message.message');
      }

      if (this.get('message.statusCode')) {
        message += '(' + this.get('message.statusCode') + ')';
      }

      return message;
    }),
    succeeded: Ember.computed('statusDisplay', function succeeded() {
      return this.get('statusDisplay') === 'succeeded';
    }),
    pending: Ember.computed('statusDisplay', function pending() {
      return this.get('statusDisplay') === 'pending';
    }),
    waiting: Ember.computed('statusDisplay', function waiting() {
      return this.get('statusDisplay') === 'waiting';
    }),
    failed: Ember.computed('statusDisplay', function failed() {
      return this.get('statusDisplay') === 'failed';
    })
  });
});