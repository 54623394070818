define('webui/pods/components/file-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isMSEdge: false,

    testForEdge: function testForEdge() {
      var filter = new RegExp('Edge/');
      return filter.test(navigator.userAgent);
    },
    didInsertElement: function didInsertElement() {
      $(':file').bind('change', function change() {
        var textInfo = '';
        if (this.files.length > 1) {
          textInfo = this.files.length + ' files selected';
        } else if (this.files.length === 1) {
          textInfo = this.files[0].name;
        }
        var textBox = $(this).parents('.input-group').find(':text');
        textBox.val(textInfo);
      });
    }
  });
});