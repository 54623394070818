define('webui/app', ['exports', 'npm:bugsnag-js', 'ember-load-initializers', 'webui/resolver', 'webui/config/environment'], function (exports, _npmBugsnagJs, _emberLoadInitializers, _resolver, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // import feedback from './app/services';

  var session = JSON.parse(localStorage.getItem('ember_simple_auth:session'));
  if (!session || !session.authenticated || !session.authenticated.id || session.authenticated.id === -1 || session.authenticated.id === -2) {
    var tempID = session && session.authenticated && session.authenticated.id && session.authenticated.id === -1 ? -2 : -1;
    localStorage.setItem('ember_simple_auth:session', JSON.stringify({ authenticated: { authenticator: 'authenticator:custom', id: tempID } }));
  }

  var bugsnagClient = void 0;

  if (_environment.default.bugsnagApiKey) {
    bugsnagClient = (0, _npmBugsnagJs.default)(_environment.default.bugsnagApiKey, { releaseStage: _environment.default.environment || 'development' });
  } else {
    console.warn('Bugsnag API key is not set. Bugsnag is disabled.');
  }

  Ember.MODEL_FACTORY_INJECTIONS = true;

  Ember.onerror = function onerror(error) {
    // catches routing errors etc
    if (bugsnagClient) {
      bugsnagClient.notify(error);
    }
    console.error(error);
    // feedback.reportError(error);
  };

  Ember.RSVP.on('error', function (error) {
    // catches promise errors
    console.error('promise error observed', error);
  });

  Ember.Logger.error = function error(message, cause, stack) {
    // catches all runtime errors / overrides default logging
    console.error('catching runtime error', message, cause, stack);
  };

  Ember.RSVP.configure('onerror', function (e) {
    console.error('RSVP onerror error', e);
  });

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  if (_environment.default.environment === 'production') {
    document.title = 'SiteIQ';
  }

  exports.default = App;
});