define('webui/pods/components/create-map-step-datetime/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/data-panel', 'webui/mixins/numerical-conversion', 'moment'], function (exports, _mixin, _dataPanel, _numericalConversion, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, _dataPanel.default, _numericalConversion.default, {
    mapData: Ember.inject.service('map-data'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    fileManager: Ember.inject.service('file-manager'),

    currDateTime: (0, _moment.default)(), /* component default time - defaults to render time. */
    dateTimeFormat: 'MM/DD/YY - h:mm A',

    wizardData: null, /* object created in create-map-wizard */
    wizard: null, /* this is passed via the template */
    subtitle: null,

    init: function init() {
      this._super.apply(this, arguments);

      /* if we have a saved timestamp we'll update the default time here if the component is re-displayed. */
      var savedFlightTime = this.wizardData.get('flightTime');
      var savedFlightSubtitle = this.wizardData.get('subtitle');
      if (savedFlightTime) {
        this.set('currDateTime', savedFlightTime);
      } else {
        this.set('currDateTime', (0, _moment.default)()); /* This was not the original behavior, but it makes sense to update this time with every new load of the wizard. */
      }
      if (savedFlightSubtitle) {
        this.set('subtitle', savedFlightSubtitle);
      }
    },
    didInsertElement: function didInsertElement() {
      this.$('.datetimepicker').datetimepicker({
        inline: true,
        sideBySide: true,
        maxDate: (0, _moment.default)()
      }).data('DateTimePicker').date(this.currDateTime);
    },
    createFlight: function createFlight() {
      var store = this.get('store');
      var site = this.wizardData.get('activeSite');

      var userTimestamp = this.$('.datetimepicker').data('DateTimePicker').date();
      var fullDate = userTimestamp;
      var executeAt = fullDate.toISOString();
      var context = this;
      var subtitle = context.$('#subtitle').val() === '' ? null : context.$('#subtitle').val();
      var flightData = {
        site: site,
        executeAt: executeAt,
        status: 'pending',
        subtitle: subtitle
      };

      if (this.wizardData.get('targetFlight.id')) {
        store.findRecord('flight', this.wizardData.get('targetFlight.id')).then(function (flight) {
          context.wizardData.set('subtitle', subtitle);
          flight.set('subtitle', subtitle);
          flight.save();
        });
      }

      var savedTimestamp = this.wizardData.get('flightTime');
      if (savedTimestamp && savedTimestamp.valueOf() === userTimestamp.valueOf()) {
        /* Nothing has changed - just go to next step. */
        context.sendAction('advanceNextStep'); /* Go to step 2 */
      } else if (savedTimestamp && savedTimestamp.valueOf() !== userTimestamp.valueOf()) {
        /* User changed the time from original - we need to update the record */
        store.findRecord('flight', this.wizardData.get('targetFlight.id')).then(function (flight) {
          context.wizardData.set('flightTime', userTimestamp);
          flight.set('executeAt', userTimestamp.toISOString());
          flight.save().then(function () {
            context.sendAction('advanceNextStep'); /* Go to step 2 */
          }).catch(function (error) {
            console.error('Error updating flight time!', error.message);
          });
        });
      } else {
        this.wizardData.set('flightTime', userTimestamp);
        this.set('currDateTime', userTimestamp);
        this.wizardData.set('subtitle', subtitle);

        var newFlight = store.createRecord('flight', flightData);
        newFlight.save().then(function (flight) {
          context.sendAnalytics(flight, site);

          context.wizardData.set('targetFlightId', flight.get('id'));
          context.wizardData.set('targetFlight', flight);

          context.sendAction('advanceNextStep'); /* Go to step 2 */
        }).catch(function (error) {
          console.error('Error creating flight!', error.message);
          context.wizard.setMessage('danger', 'Unable to create a map from the date provided.'); /* Not very helpful, but at least we show something? */
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.datetimepicker').data('DateTimePicker').destroy();
    },


    actions: {
      save: function save() {
        this.createFlight();
      }
    },

    sendAnalytics: function sendAnalytics(flight, site) {
      var mapData = this.get('mapData');
      var user = this.get('session.data.authenticated');
      var flightId = flight.id;
      var siteId = site.id;
      var siteName = site.get('name');
      var siteBoundaries = site.get('boundaries');
      var clients = site.get('clients');
      var clientIds = clients.map(function (client) {
        return client.get('id');
      });
      var clientNames = clients.map(function (client) {
        return client.get('name');
      });
      var siteArea = site.get('area');
      var poly = void 0;

      var toTrack = {
        userId: user.id,
        clientIds: clientIds,
        clientNames: clientNames,
        siteName: siteName,
        siteId: siteId,
        flightId: flightId
      };

      if (!siteArea) {
        // TODO write a database migrations for site area
        // if this site doesn't yet have area, this is the fallback:
        // Draw a polygon of the site boundaries, get the area, and delete it.
        // This way, gradually, all sites will have area saved on them.
        try {
          var featureProps = {
            id: siteId,
            color: 'blue',
            observable_as: 'any',
            points: siteBoundaries,
            type: 'Polygon'
          };

          mapData.drawFeature(featureProps);
          poly = mapData.drawingManager.get(mapData.polygons[siteId]);
          siteArea = mapData.GEOAPI.area(poly);
          siteArea = this.squareMetersToSquareFeet(siteArea);
          site.set('area', siteArea);
          site.save();
          mapData.drawingManager.delete(mapData.polygons[siteId]);
        } catch (e) {
          console.error('Error getting site area', e);
        }
      }

      if (siteArea) {
        toTrack.acreage = this.squareMetersToAcres(siteArea);
      }

      this.trackEvent('Flight Completed', toTrack);
    }
  });
});