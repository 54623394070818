define('webui/pods/components/field-map/component', ['exports', 'webui/mixins/measurements-on-map', 'webui/mixins/numerical-conversion', 'webui/mixins/map-export', 'ember-cli-segment/mixin'], function (exports, _measurementsOnMap, _numericalConversion, _mapExport, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_measurementsOnMap.default, _numericalConversion.default, _mapExport.default, _mixin.default, {
    mapData: Ember.inject.service('map-data'),
    tiledMaps: 'tiledMaps',
    siteGrades: 'siteGrades',
    flights: 'flights',

    didInsertElement: function didInsertElement() {
      var mapData = this.get('mapData');

      mapData.set('currentSite', this.get('site'));
      mapData.set('currentFlightId', this.get('flight.id'));
      mapData.createMapInstance(this.get('tiledMaps'), this.$('.tiled-map-canvas')[0], 0, true);
      this.fetchFieldPinImage(mapData.mapInstance);
      this.fetchArrowHeadImage(mapData.mapInstance);
      this.fetchGcpPinImage(mapData.mapInstance);
      this.set('layers', this.get('tiledMaps'));
    },
    fetchFieldPinImage: function fetchFieldPinImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('fieldPinImage')) {
        /* NOTE: In Mapbox v. 0.35 and higher there is a function "loadImage()" that will do all of this XHR stuff for us - consider this a filler until Mapbox is upgraded. */
        var i = new Image();

        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/images/glyphicons/png/field-map-pin.png');
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i.onload = function () {
            instance.addImage('fieldpin', i);

            component.set('fieldPinImage', 'fieldpin');

            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
          /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
           (i : any).cacheControl = imgData.cacheControl;
           (i : any).expires = imgData.expires;
           */
          i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr.send();
      }

      return instance;
    },
    fetchArrowHeadImage: function fetchArrowHeadImage(instance) {
      var component = this;

      if (!this.get('pictureArrowHeadImage')) {
        instance.loadImage('/assets/images/glyphicons/png/white-arrow-head.png', function (error, image) {
          instance.addImage('arrow-head', image, { sdf: true });
          component.set('pictureMarkerImage', 'arrow-head');
        });
      }

      return instance;
    },
    fetchGcpPinImage: function fetchGcpPinImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('gcpPinImage')) {
        /* NOTE: In Mapbox v. 0.35 and higher there is a function "loadImage()" that will do all of this XHR stuff for us - consider this a filler until Mapbox is upgraded. */
        var i = new Image();

        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/images/glyphicons/png/glyphicons-378-riflescope.png');
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i.onload = function () {
            instance.addImage('riflescope', i);

            component.set('gcpPinImage', 'riflescope');

            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
          /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
           (i : any).cacheControl = imgData.cacheControl;
           (i : any).expires = imgData.expires;
           */
          i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr.send();
      }

      return instance;
    }
  });
});