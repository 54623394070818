define('webui/pods/components/field-nav/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    settingsManager: Ember.inject.service('settings-manager'),

    tiledMaps: 'tiledMaps',
    siteGrades: 'siteGrades',
    flights: 'flights',
    flight: 'flight',
    site: 'site',
    qualityReport: 'qualityReport',
    activeTool: null,

    init: function init() {
      this._super();
    },


    actions: {
      selectTool: function selectTool(toolName) {
        this.set('activeTool', toolName === this.get('activeTool') ? null : toolName);
      },
      closePanel: function closePanel() {
        this.set('activeTool', null);
      }
    }
  });
});