define('webui/mixins/site-user-access-processor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    processSiteUserAccess: function processSiteUserAccess(site, authenticatedUser) {
      /* The purpose of this function is to take a site and session user and resolve the user object's access level to the site.
       This does do some indirect variable creation (via user.assignSiteAccess) which isn't ideal, but considering the usage I believe to be acceptable. */

      var self = this;
      var session = this.get('session');
      var users = site.get('users');
      var client = site.get('clients.firstObject');

      return Ember.RSVP.all(users.map(function (user) {
        if (user.get('id') === authenticatedUser.get('id')) {
          return null; /* Abandon processing because we set the permissions for the session user after this. */
        }

        return self.store.query('user-site', { user: user.get('id'), site: site.get('id') }, { reload: true }).then(function (userSites) {
          var role = userSites.findBy('site', site.get('id'));
          var clientUserIds = client.get('users').map(function (clientUser) {
            return clientUser.get('id');
          });
          var isOnTeamRoster = clientUserIds.indexOf(user.get('id')) !== -1;

          user.assignSiteAccess(site, role, false, isOnTeamRoster);
        });
      })).then(function () {
        var activeUserIsAuthenticatedUser = session && session.get('data.authenticated.id') === authenticatedUser.get('id');
        var activeUserIsClientAdmin = activeUserIsAuthenticatedUser ? session.get('data.authenticated.isAdmin') : false;

        /* Because this is the active user, we already did a full fetch of the data and don't need to cherry-pick the userSites like we did for the site users. */
        var role = authenticatedUser.get('userSites').findBy('site', site.get('id'));
        var clientUserIds = client.get('users').map(function (clientUser) {
          return clientUser.get('id');
        });
        var isOnTeamRoster = clientUserIds.indexOf(authenticatedUser.get('id')) !== -1;

        authenticatedUser.assignSiteAccess(site, role, activeUserIsClientAdmin, isOnTeamRoster);
      });
    }
  });
});