define('webui/models/material', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    name: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    density: _emberData.default.attr('number'),
    displayDensity: Ember.computed('density', function displayDensity() {
      return this.numberWithCommas(this.get('density'));
    }),
    key: _emberData.default.attr('string'),
    dollarValue: _emberData.default.attr('number'),

    regions: _emberData.default.hasMany('region', { async: false }),
    site: _emberData.default.belongsTo('site', { async: false }),
    isArchived: _emberData.default.attr('boolean'),
    isArchivedDisplay: Ember.computed('isArchived', function isArchivedDisplay() {
      return this.get('isArchived') ? 'Yes' : 'No';
    }),

    trueTonnageReportRegions: _emberData.default.hasMany('true-tonnage-report-region', { async: false }),
    uniqueKey: Ember.computed('key', 'name', 'density', function displayDensity() {
      var density = this.get('density') ? ' - ' + this.get('density') + ' tons/yd3' : '';
      return this.get('name') + ' (' + this.get('key') + ')' + density;
    })
  });
});