define('webui/pods/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    statusService: Ember.inject.service('status-panel'),
    featureFlags: Ember.inject.service('feature-flags'),

    beforeModel: function beforeModel(transition) {
      this.get('featureFlags').loadFeaturesMap();
      if (!this.get('session.isAuthenticated') && transition) {
        transition.abort();
        this.transitionTo('login');
      } else {
        // if session is authenticated, we may have the elev.io user hash.
        var user = this.get('session.data.authenticated');
        if (user.hash && window._elev) {
          // this will show the elev.io help tab to the user.
          var elevUser = {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            user_hash: user.hash
          };
          if (window._elev.changeUser) {
            window._elev.changeUser(elevUser);
          } else {
            window._elev.user = elevUser;
          }
        }

        this.get('statusService').loadPending();
      }
    }
  });
});