define('webui/pods/admin/user/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      Ember.RSVP.resolve(this.store.query('user', {}).then(function (users) {
        return users.sortBy('fullName');
      })).then(function (results) {
        controller.set('model', results);
        _this.send('updateUsers', results);
      });
    },


    actions: {
      error: function error(_error) {
        console.error('admin-user error: ', _error);
      },
      loadUserForEdit: function loadUserForEdit(id) {
        if (id) {
          $('.selectpicker').val(undefined).change();
          this.transitionTo('admin.user.edit', id);
        }
      },
      updateUsers: function updateUsers() {}
    }
  });
});