define("webui/pods/components/true-tonnage-report/planned-tonnage-table/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 24
              },
              "end": {
                "line": 24,
                "column": 88
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "material.key", ["loc", [null, [24, 72], [24, 88]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 88
              },
              "end": {
                "line": 24,
                "column": 113
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "material.name", ["loc", [null, [24, 96], [24, 113]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 8
              },
              "end": {
                "line": 32,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "style", "padding:0 2px;");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "class", "form-control ember-view ember-text-field");
            dom.setAttribute(el2, "type", "number");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element3, 'data-material-id');
            morphs[1] = dom.createAttrMorph(element3, 'value');
            morphs[2] = dom.createAttrMorph(element3, 'data-material-id');
            morphs[3] = dom.createAttrMorph(element3, 'oninput');
            return morphs;
          },
          statements: [["attribute", "data-material-id", ["get", "material.materialId", ["loc", [null, [30, 101], [30, 120]]]]], ["attribute", "value", ["get", "material.plannedTonnage", ["loc", [null, [30, 131], [30, 154]]]]], ["attribute", "data-material-id", ["get", "material.materialId", ["loc", [null, [30, 176], [30, 195]]]]], ["attribute", "oninput", ["subexpr", "action", ["updatePlannedTonnage"], [], ["loc", [null, [30, 206], [30, 239]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 8
                },
                "end": {
                  "line": 34,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("th");
              dom.setAttribute(el1, "class", "text-right");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "material.plannedTonnage", ["loc", [null, [33, 33], [33, 60]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 8
                },
                "end": {
                  "line": 36,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("th");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 8
              },
              "end": {
                "line": 36,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "material.name", ["loc", [null, [32, 22], [32, 35]]]], "Total"], [], ["loc", [null, [32, 18], [32, 44]]]]], [], 0, 1, ["loc", [null, [32, 8], [36, 8]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child4 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 14
                },
                "end": {
                  "line": 42,
                  "column": 14
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["glyphicon ", ["subexpr", "if", [["get", "material.showRegions", ["loc", [null, [41, 44], [41, 64]]]], "glyphicon-chevron-up", "glyphicon-chevron-down"], [], ["loc", [null, [41, 39], [41, 114]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 10
              },
              "end": {
                "line": 44,
                "column": 10
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["btn btn-table-action ", ["subexpr", "if", [["get", "material.showRegions", ["loc", [null, [39, 53], [39, 73]]]], "active", ""], [], ["loc", [null, [39, 48], [39, 87]]]]]]], ["element", "action", ["toggleRegions", ["get", "material", ["loc", [null, [39, 114], [39, 122]]]]], [], ["loc", [null, [39, 89], [39, 124]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Show All Regions</p>", "tooltipPlace", "left"], 0, null, ["loc", [null, [40, 14], [42, 27]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child5 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 28
                  },
                  "end": {
                    "line": 51,
                    "column": 88
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "region.nameWithMaterialCode", ["loc", [null, [51, 57], [51, 88]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 88
                  },
                  "end": {
                    "line": 51,
                    "column": 111
                  }
                },
                "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "region.name", ["loc", [null, [51, 96], [51, 111]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 8
                },
                "end": {
                  "line": 58,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "scope", "row");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              dom.setAttribute(el2, "style", "padding: 2px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
              return morphs;
            },
            statements: [["block", "if", [["get", "displayMaterialAsCode", ["loc", [null, [51, 34], [51, 55]]]]], [], 0, 1, ["loc", [null, [51, 28], [51, 118]]]], ["content", "region.netVolume", ["loc", [null, [52, 35], [52, 55]]]], ["content", "region.density", ["loc", [null, [53, 35], [53, 53]]]], ["content", "region.displayTonnage", ["loc", [null, [54, 35], [54, 60]]]], ["content", "region.plannedTonnage", ["loc", [null, [55, 35], [55, 60]]]]],
            locals: ["region"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 6
              },
              "end": {
                "line": 59,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["subexpr", "sort-by", ["name", ["get", "material.regionData", ["loc", [null, [49, 32], [49, 51]]]]], [], ["loc", [null, [49, 16], [49, 52]]]]], [], 0, null, ["loc", [null, [49, 8], [58, 17]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 4
            },
            "end": {
              "line": 60,
              "column": 4
            }
          },
          "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          dom.setAttribute(el1, "class", "active");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "scope", "row");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("th");
          dom.setAttribute(el2, "class", "text-right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "text-right");
          dom.setAttribute(el2, "style", "padding: 2px;");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [7]), 0, 0);
          morphs[4] = dom.createMorphAt(element4, 9, 9);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [11]), 1, 1);
          morphs[6] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "displayMaterialAsCode", ["loc", [null, [24, 35], [24, 56]]]], ["get", "material.key", ["loc", [null, [24, 57], [24, 69]]]]], [], ["loc", [null, [24, 30], [24, 70]]]]], [], 0, 1, ["loc", [null, [24, 24], [24, 120]]]], ["content", "material.volume", ["loc", [null, [25, 31], [25, 50]]]], ["content", "material.density", ["loc", [null, [26, 31], [26, 51]]]], ["content", "material.tonnage", ["loc", [null, [27, 31], [27, 51]]]], ["block", "if", [["get", "material.materialId", ["loc", [null, [28, 14], [28, 33]]]]], [], 2, 3, ["loc", [null, [28, 8], [36, 15]]]], ["block", "unless", [["subexpr", "eq", [["get", "material.name", ["loc", [null, [38, 24], [38, 37]]]], "Total"], [], ["loc", [null, [38, 20], [38, 46]]]]], [], 4, null, ["loc", [null, [38, 10], [44, 21]]]], ["block", "if", [["get", "material.showRegions", ["loc", [null, [48, 12], [48, 32]]]]], [], 5, null, ["loc", [null, [48, 6], [59, 13]]]]],
        locals: ["material"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/true-tonnage-report/planned-tonnage-table/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-12 text-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "btn btn-file");
        dom.setAttribute(el3, "style", "margin-top: 10px;");
        var el4 = dom.createTextNode("\n      Import On Hand Inventory ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "style", "display: none;");
        dom.setAttribute(el4, "type", "file");
        dom.setAttribute(el4, "id", "on-hand-inventory-input-file");
        dom.setAttribute(el4, "accept", "text/csv,application/csv,application/json,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "glyphicon glyphicon-cloud-upload");
        dom.setAttribute(el4, "style", "margin-left:10px;");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "table table-sm table-bordered table-sticky");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("Region");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("Volume (yd");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("sup");
        var el6 = dom.createTextNode("3");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("Density (tons/yd");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("sup");
        var el6 = dom.createTextNode("3");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        var el5 = dom.createTextNode("True Tonnage (US Tons)");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "text-right");
        dom.setAttribute(el4, "style", "width:140px;");
        var el5 = dom.createTextNode("On Hand Inventory");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "style", "width:35px;");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1, 1, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element5, 'disabled');
        morphs[1] = dom.createAttrMorph(element5, 'onchange');
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2, 3]), 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "displayFieldSelectModal", ["loc", [null, [4, 118], [4, 141]]]]], ["attribute", "onchange", ["subexpr", "action", ["importPlannedTonnage"], [], ["loc", [null, [4, 153], [4, 186]]]]], ["block", "each", [["get", "reportData", ["loc", [null, [22, 12], [22, 22]]]]], [], 0, null, ["loc", [null, [22, 4], [60, 13]]]], ["inline", "on-hand-inventory-import-field-selection", [], ["displayFieldSelectModal", ["subexpr", "@mut", [["get", "displayFieldSelectModal", ["loc", [null, [64, 67], [64, 90]]]]], [], []], "reportData", ["subexpr", "@mut", [["get", "reportData", ["loc", [null, [64, 102], [64, 112]]]]], [], []], "importedMaterials", ["subexpr", "@mut", [["get", "importedMaterials", ["loc", [null, [64, 131], [64, 148]]]]], [], []], "importedColumnMap", ["subexpr", "@mut", [["get", "importedColumnMap", ["loc", [null, [64, 167], [64, 184]]]]], [], []], "importedColumnNames", ["subexpr", "@mut", [["get", "importedColumnNames", ["loc", [null, [64, 205], [64, 224]]]]], [], []], "displayMaterialAsCode", ["subexpr", "@mut", [["get", "displayMaterialAsCode", ["loc", [null, [64, 247], [64, 268]]]]], [], []]], ["loc", [null, [64, 0], [64, 270]]]], ["inline", "true-tonnage-report/true-tonnage-report-form", [], ["deleteTrueTonnageReport", ["subexpr", "action", ["deleteTrueTonnageReport"], [], ["loc", [null, [67, 26], [67, 60]]]], "submitTrueTonnageReport", ["subexpr", "action", ["submitTrueTonnageReport"], [], ["loc", [null, [68, 26], [68, 60]]]], "isFormSaving", ["subexpr", "@mut", [["get", "isFormSaving", ["loc", [null, [69, 15], [69, 27]]]]], [], []], "canSubmitTrueTonnageForm", true, "canDeleteTrueTonnageForm", ["subexpr", "@mut", [["get", "canDeleteTrueTonnageForm", ["loc", [null, [71, 27], [71, 51]]]]], [], []]], ["loc", [null, [66, 0], [72, 2]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});