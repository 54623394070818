define('webui/pods/components/job-instance-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    instances: [],

    generateModel: function generateModel() {
      var component = this;var store = this.get('store');

      store.query('instance', {}).then(function (instances) {
        component.set('instances', instances.sortBy('id'));
        setTimeout(function () {
          if (window.instanceDisplay) {
            component.generateModel();
          }
        }, 60000);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      window.instanceDisplay = true;
      this._super.apply(this, arguments);

      this.generateModel();

      $(document).ready(function () /* event */{
        _this.arrangeJobPanels();
      });

      $(window).resize(function () /* event */{
        _this.arrangeJobPanels();
      });
    },
    didRender: function didRender() {
      this.arrangeJobPanels();
    },
    arrangeJobPanels: function arrangeJobPanels() {
      var $jobStatus = $('.job-status');
      var $jobServers = $('.job-server', $jobStatus);

      var reserveSpace = 0;
      $('body > .ember-view > .container-fluid').each(function (i, el) {
        reserveSpace += $(el).outerHeight();
      });
      var mainHeight = $(window).height() - reserveSpace;
      $jobStatus.css('height', mainHeight);

      var multiple = Math.ceil($jobServers.length / 4);
      if (multiple < 1) {
        multiple = 1;
      }
      $jobServers.css({ height: mainHeight / multiple });
    },
    willDestroyElement: function willDestroyElement() {
      window.instanceDisplay = false;
      this._super.apply(this, arguments);
      $(window).off();
    }
  });
});