define('webui/models/annotation-layer', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    site: _emberData.default.belongsTo('site', { async: false }),
    flight: _emberData.default.belongsTo('flight', { async: false }),
    name: _emberData.default.attr('string'),
    annotations: _emberData.default.hasMany('annotation', { async: false })
  });
});