define('webui/pods/index/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'webui/mixins/default-flight'], function (exports, _applicationRouteMixin, _defaultFlight) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _defaultFlight.default, {
    session: Ember.inject.service('session'),
    feedback: Ember.inject.service('user-feedback'),

    beforeModel: function beforeModel(transition) {
      var route = this;

      transition.abort();

      if (this.get('session.data.authenticated.status') === 'pending') {
        return route.transitionTo('account');
      }

      if (this.get('session.intendedTransition')) {
        return route.transitionTo(this.get('session.intendedTransition'));
      }

      return route.store.query('site', { status: 'active', limit: 1 }).then(function (sites) {
        var site = sites.objectAt(0);
        if (site) {
          var defaultFlight = route.getDefaultFlightForSite(site.get('flights'));

          if (defaultFlight && defaultFlight.id) {
            return route.transitionTo('flight', defaultFlight.id);
          }
          return route.transitionTo('site', site.id);
        }
        return route.transitionTo('site-not-found');
      }).fail(function fail() {
        this.get('feedback').reportError({ title: 'Access error', detail: 'Problem getting the most recent flight.' });
      });
    }
  });
});