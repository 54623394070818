define('webui/helpers/format-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumber = formatNumber;
  function formatNumber(params) {
    return params.toLocaleString();
  }

  exports.default = Ember.Helper.helper(formatNumber);
});