define("webui/pods/components/csv-export-settings-modal/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 4
              },
              "end": {
                "line": 24,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "button-box col-xs-12");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "btn nofill");
            var el4 = dom.createTextNode("Cancel");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1, 1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element23);
            return morphs;
          },
          statements: [["element", "action", ["toggleDownloadForm"], [], ["loc", [null, [21, 35], [21, 67]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      Regular Report\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      Accounting Report\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var element25 = dom.childAt(element24, [1]);
          var element26 = dom.childAt(element24, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element25, 'class');
          morphs[1] = dom.createElementMorph(element25);
          morphs[2] = dom.createAttrMorph(element26, 'class');
          morphs[3] = dom.createElementMorph(element26);
          morphs[4] = dom.createMorphAt(element24, 5, 5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["btn ", ["subexpr", "if", [["get", "ttrCSV", ["loc", [null, [10, 28], [10, 34]]]], "false", " nofill"], [], ["loc", [null, [10, 23], [10, 54]]]]]]], ["element", "action", ["toggleRegularCSV"], [], ["loc", [null, [10, 56], [10, 86]]]], ["attribute", "class", ["concat", ["btn ", ["subexpr", "if", [["get", "accountingCSV", ["loc", [null, [14, 28], [14, 41]]]], "false", " nofill"], [], ["loc", [null, [14, 23], [14, 61]]]]]]], ["element", "action", ["toggleAccountingCSV"], [], ["loc", [null, [14, 63], [14, 96]]]], ["block", "unless", [["subexpr", "or", [["get", "ttrCSV", ["loc", [null, [18, 18], [18, 24]]]], ["get", "accountingCSV", ["loc", [null, [18, 25], [18, 38]]]]], [], ["loc", [null, [18, 14], [18, 39]]]]], [], 0, null, ["loc", [null, [18, 4], [24, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 4
                  },
                  "end": {
                    "line": 36,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "selected", "selected");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element19 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element19, 'value');
                morphs[1] = dom.createMorphAt(element19, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "column.name", ["loc", [null, [35, 21], [35, 32]]]]]]], ["content", "column.name", ["loc", [null, [35, 56], [35, 71]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 4
                  },
                  "end": {
                    "line": 38,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element18 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element18, 'value');
                morphs[1] = dom.createMorphAt(element18, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "column.name", ["loc", [null, [37, 21], [37, 32]]]]]]], ["content", "column.name", ["loc", [null, [37, 36], [37, 51]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 4
                },
                "end": {
                  "line": 39,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "column.checked", ["loc", [null, [34, 10], [34, 24]]]]], [], 0, 1, ["loc", [null, [34, 4], [38, 11]]]]],
            locals: ["column"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 2
              },
              "end": {
                "line": 41,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("select");
            dom.setAttribute(el1, "id", "export-column-selector");
            dom.setAttribute(el1, "class", "selectpicker");
            dom.setAttribute(el1, "data-width", "fit");
            dom.setAttribute(el1, "multiple", "");
            dom.setAttribute(el1, "data-actions-box", "true");
            dom.setAttribute(el1, "data-selected-text-format", "static");
            dom.setAttribute(el1, "title", "Columns");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "columns", ["loc", [null, [33, 12], [33, 19]]]]], [], 0, null, ["loc", [null, [33, 4], [39, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 4
                },
                "end": {
                  "line": 48,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element17, 'value');
              morphs[1] = dom.createMorphAt(element17, 0, 0);
              return morphs;
            },
            statements: [["attribute", "value", ["concat", [["get", "row", ["loc", [null, [47, 21], [47, 24]]]]]]], ["content", "row", ["loc", [null, [47, 48], [47, 55]]]]],
            locals: ["row"],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 2
              },
              "end": {
                "line": 50,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("select");
            dom.setAttribute(el1, "id", "export-row-selector");
            dom.setAttribute(el1, "class", "selectpicker");
            dom.setAttribute(el1, "data-width", "fit");
            dom.setAttribute(el1, "multiple", "");
            dom.setAttribute(el1, "data-actions-box", "true");
            dom.setAttribute(el1, "data-selected-text-format", "static");
            dom.setAttribute(el1, "title", "Rows");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "rows", ["loc", [null, [46, 12], [46, 16]]]]], [], 0, null, ["loc", [null, [46, 4], [48, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 4
                  },
                  "end": {
                    "line": 58,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "selected", "selected");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element16, 'value');
                morphs[1] = dom.createMorphAt(element16, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "surface", ["loc", [null, [57, 21], [57, 28]]]]]]], ["content", "surface", ["loc", [null, [57, 52], [57, 63]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 58,
                    "column": 4
                  },
                  "end": {
                    "line": 60,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element15, 'value');
                morphs[1] = dom.createMorphAt(element15, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "surface", ["loc", [null, [59, 21], [59, 28]]]]]]], ["content", "surface", ["loc", [null, [59, 32], [59, 43]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 4
                },
                "end": {
                  "line": 61,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "surface", ["loc", [null, [56, 14], [56, 21]]]], ["get", "currentSiteGradeBlueprint.name", ["loc", [null, [56, 22], [56, 52]]]]], [], ["loc", [null, [56, 10], [56, 53]]]]], [], 0, 1, ["loc", [null, [56, 4], [60, 11]]]]],
            locals: ["surface"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 2
              },
              "end": {
                "line": 63,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("select");
            dom.setAttribute(el1, "id", "export-surface-selector");
            dom.setAttribute(el1, "class", "selectpicker");
            dom.setAttribute(el1, "data-width", "fit");
            dom.setAttribute(el1, "multiple", "");
            dom.setAttribute(el1, "data-actions-box", "true");
            dom.setAttribute(el1, "data-selected-text-format", "static");
            dom.setAttribute(el1, "title", "Comparison Surfaces");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "surfaces", ["loc", [null, [55, 12], [55, 20]]]]], [], 0, null, ["loc", [null, [55, 4], [61, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 4
                  },
                  "end": {
                    "line": 72,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "selected", "selected");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "map-subtitle");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element14 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element14, 'value');
                morphs[1] = dom.createMorphAt(element14, 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "flight.id", ["loc", [null, [70, 21], [70, 30]]]]]]], ["content", "flight.displayFlightTime", ["loc", [null, [70, 54], [70, 82]]]], ["content", "flight.subtitle", ["loc", [null, [71, 29], [71, 48]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 72,
                    "column": 4
                  },
                  "end": {
                    "line": 75,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "map-subtitle");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element13 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element13, 'value');
                morphs[1] = dom.createMorphAt(element13, 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "flight.id", ["loc", [null, [73, 21], [73, 30]]]]]]], ["content", "flight.displayFlightTime", ["loc", [null, [73, 34], [73, 62]]]], ["content", "flight.subtitle", ["loc", [null, [73, 89], [73, 108]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 4
                },
                "end": {
                  "line": 76,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "flight.selected", ["loc", [null, [69, 10], [69, 25]]]]], [], 0, 1, ["loc", [null, [69, 4], [75, 11]]]]],
            locals: ["flight"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 2
              },
              "end": {
                "line": 78,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("select");
            dom.setAttribute(el1, "id", "export-flight-selector");
            dom.setAttribute(el1, "class", "selectpicker");
            dom.setAttribute(el1, "data-width", "fit");
            dom.setAttribute(el1, "data-actions-box", "true");
            dom.setAttribute(el1, "multiple", "");
            dom.setAttribute(el1, "data-selected-text-format", "static");
            dom.setAttribute(el1, "title", "Flights");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "flights", ["loc", [null, [68, 12], [68, 19]]]]], [], 0, null, ["loc", [null, [68, 4], [76, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 6
              },
              "end": {
                "line": 88,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "style", "float:left; margin-top:1em");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "checkbox");
            dom.setAttribute(el2, "id", "min-names");
            dom.setAttribute(el2, "name", "min-names");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  Number Regions  \n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element12, [], true);
            }
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element12, 'checked');
            morphs[1] = dom.createAttrMorph(element12, 'onchange');
            return morphs;
          },
          statements: [["attribute", "checked", ["get", "pdfOptions.useMinifiedRegionNames", ["loc", [null, [85, 73], [85, 106]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleUseMinifiedRegionNames"], [], ["loc", [null, [86, 19], [86, 61]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 6
                },
                "end": {
                  "line": 97,
                  "column": 6
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("textarea");
              dom.setAttribute(el1, "style", "resize:vertical;");
              dom.setAttribute(el1, "id", "custom-header-text");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element10, 'onchange');
              morphs[1] = dom.createMorphAt(element10, 0, 0);
              return morphs;
            },
            statements: [["attribute", "onchange", ["subexpr", "action", ["updateCustomHeader"], ["value", "target.value"], ["loc", [null, [95, 74], [96, 31]]]]], ["content", "pdfOptions.customHeader", ["loc", [null, [96, 32], [96, 59]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 6
              },
              "end": {
                "line": 98,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "style", "float:left; margin-top:1em");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "checkbox");
            dom.setAttribute(el2, "id", "custom-header-check");
            dom.setAttribute(el2, "name", "custom-header-check");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  Add Header  \n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1, 1]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element11, [], true);
            }
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element11, 'checked');
            morphs[1] = dom.createAttrMorph(element11, 'onchange');
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "checked", ["get", "pdfOptions.customHeader", ["loc", [null, [91, 93], [91, 116]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleCustomHeader", "ttrHeaders"], [], ["loc", [null, [92, 19], [92, 64]]]]], ["block", "if", [["get", "pdfOptions.customHeader", ["loc", [null, [94, 12], [94, 35]]]]], [], 0, null, ["loc", [null, [94, 6], [97, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 2
            },
            "end": {
              "line": 103,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "button-box col-xs-12");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn");
          var el4 = dom.createTextNode("Download");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn nofill");
          var el4 = dom.createTextNode("Cancel");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [10, 1]);
          var element21 = dom.childAt(element20, [4]);
          var element22 = dom.childAt(element20, [6]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[4] = dom.createMorphAt(element20, 1, 1);
          morphs[5] = dom.createMorphAt(element20, 2, 2);
          morphs[6] = dom.createElementMorph(element21);
          morphs[7] = dom.createElementMorph(element22);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "columns.length", ["loc", [null, [30, 8], [30, 22]]]]], [], 0, null, ["loc", [null, [30, 2], [41, 9]]]], ["block", "if", [["get", "rows.length", ["loc", [null, [43, 8], [43, 19]]]]], [], 1, null, ["loc", [null, [43, 2], [50, 9]]]], ["block", "if", [["get", "surfaces.length", ["loc", [null, [52, 8], [52, 23]]]]], [], 2, null, ["loc", [null, [52, 2], [63, 9]]]], ["block", "if", [["get", "flights.length", ["loc", [null, [65, 8], [65, 22]]]]], [], 3, null, ["loc", [null, [65, 2], [78, 9]]]], ["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "type", ["loc", [null, [83, 20], [83, 24]]]], "volume-calc"], [], ["loc", [null, [83, 16], [83, 39]]]], ["subexpr", "eq", [["get", "type", ["loc", [null, [83, 44], [83, 48]]]], "true-tonnage"], [], ["loc", [null, [83, 40], [83, 64]]]]], [], ["loc", [null, [83, 12], [83, 65]]]]], [], 4, null, ["loc", [null, [83, 6], [88, 13]]]], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [89, 16], [89, 20]]]], "true-tonnage"], [], ["loc", [null, [89, 12], [89, 36]]]]], [], 5, null, ["loc", [null, [89, 6], [98, 13]]]], ["element", "action", ["downloadCSV"], [], ["loc", [null, [99, 26], [99, 51]]]], ["element", "action", ["toggleDownloadForm"], [], ["loc", [null, [100, 33], [100, 65]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 110,
                    "column": 4
                  },
                  "end": {
                    "line": 112,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "selected", "selected");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element3, 'value');
                morphs[1] = dom.createMorphAt(element3, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "column.name", ["loc", [null, [111, 21], [111, 32]]]]]]], ["content", "column.name", ["loc", [null, [111, 56], [111, 71]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 4
                  },
                  "end": {
                    "line": 114,
                    "column": 4
                  }
                },
                "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element2, 'value');
                morphs[1] = dom.createMorphAt(element2, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "column.name", ["loc", [null, [113, 21], [113, 32]]]]]]], ["content", "column.name", ["loc", [null, [113, 36], [113, 51]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 109,
                  "column": 4
                },
                "end": {
                  "line": 115,
                  "column": 4
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "column.checked", ["loc", [null, [110, 10], [110, 24]]]]], [], 0, 1, ["loc", [null, [110, 4], [114, 11]]]]],
            locals: ["column"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 2
              },
              "end": {
                "line": 117,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("select");
            dom.setAttribute(el1, "id", "export-column-selector");
            dom.setAttribute(el1, "class", "selectpicker");
            dom.setAttribute(el1, "data-width", "fit");
            dom.setAttribute(el1, "multiple", "");
            dom.setAttribute(el1, "data-actions-box", "true");
            dom.setAttribute(el1, "data-selected-text-format", "static");
            dom.setAttribute(el1, "title", "Columns");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "columns", ["loc", [null, [109, 12], [109, 19]]]]], [], 0, null, ["loc", [null, [109, 4], [115, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 127,
                  "column": 8
                },
                "end": {
                  "line": 129,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "style", "color:red;");
              var el2 = dom.createTextNode("Enter required value");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 126,
                "column": 8
              },
              "end": {
                "line": 130,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "pdfOptions.coLocLob", ["loc", [null, [127, 18], [127, 37]]]]], [], 0, null, ["loc", [null, [127, 8], [129, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 132,
                "column": 6
              },
              "end": {
                "line": 137,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("textarea");
            dom.setAttribute(el1, "id", "custom-header-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element1, 'style');
            morphs[1] = dom.createAttrMorph(element1, 'onchange');
            morphs[2] = dom.createMorphAt(element1, 0, 0);
            return morphs;
          },
          statements: [["attribute", "style", ["concat", ["resize:vertical; height: 30px; ", ["subexpr", "unless", [["get", "pdfOptions.coLocLob", ["loc", [null, [134, 55], [134, 74]]]], "border-color:red; border-style: solid; border-width: 2px; border-radius: 5px;"], [], ["loc", [null, [134, 46], [134, 156]]]]]]], ["attribute", "onchange", ["subexpr", "action", ["updateCoLocLobHeaders"], ["value", "target.value"], ["loc", [null, [135, 41], [136, 10]]]]], ["content", "pdfOptions.coLocLob", ["loc", [null, [136, 11], [136, 34]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 6
              },
              "end": {
                "line": 147,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("textarea");
            dom.setAttribute(el1, "style", "resize:vertical; height: 100px;");
            dom.setAttribute(el1, "id", "custom-header-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'onchange');
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "onchange", ["subexpr", "action", ["updateCustomHeader"], ["value", "target.value"], ["loc", [null, [145, 89], [146, 31]]]]], ["content", "pdfOptions.customHeader", ["loc", [null, [146, 32], [146, 59]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 2
            },
            "end": {
              "line": 152,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "button-box col-xs-12");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "style", "float:left;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "style", "margin-top:1em");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "checkbox");
          dom.setAttribute(el5, "id", "custom-header-check");
          dom.setAttribute(el5, "name", "custom-header-check");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("  Add CO.LOC.LOB  \n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "style", "float:left;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "style", "margin-top:1em");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "checkbox");
          dom.setAttribute(el5, "id", "custom-header-check");
          dom.setAttribute(el5, "name", "custom-header-check");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("  Add Header  \n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn");
          var el4 = dom.createTextNode("Download");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn nofill");
          var el4 = dom.createTextNode("Cancel");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [2, 1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element5, [1, 1]);
          if (this.cachedFragment) {
            dom.repairClonedNode(element6, [], true);
          }
          var element7 = dom.childAt(element4, [5, 1, 1]);
          if (this.cachedFragment) {
            dom.repairClonedNode(element7, [], true);
          }
          var element8 = dom.childAt(element4, [9]);
          var element9 = dom.childAt(element4, [11]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element6, 'checked');
          morphs[2] = dom.createAttrMorph(element6, 'onchange');
          morphs[3] = dom.createMorphAt(element5, 3, 3);
          morphs[4] = dom.createMorphAt(element4, 3, 3);
          morphs[5] = dom.createAttrMorph(element7, 'checked');
          morphs[6] = dom.createAttrMorph(element7, 'onchange');
          morphs[7] = dom.createMorphAt(element4, 7, 7);
          morphs[8] = dom.createElementMorph(element8);
          morphs[9] = dom.createElementMorph(element9);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "columns.length", ["loc", [null, [106, 8], [106, 22]]]]], [], 0, null, ["loc", [null, [106, 2], [117, 9]]]], ["attribute", "checked", ["get", "coLobLobActive", ["loc", [null, [123, 96], [123, 110]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleCoLocLobHeaders"], [], ["loc", [null, [124, 21], [124, 56]]]]], ["block", "if", [["get", "coLobLobActive", ["loc", [null, [126, 14], [126, 28]]]]], [], 1, null, ["loc", [null, [126, 8], [130, 15]]]], ["block", "if", [["get", "coLobLobActive", ["loc", [null, [132, 12], [132, 26]]]]], [], 2, null, ["loc", [null, [132, 6], [137, 13]]]], ["attribute", "checked", ["get", "pdfOptions.customHeader", ["loc", [null, [140, 95], [140, 118]]]]], ["attribute", "onchange", ["subexpr", "action", ["toggleCustomHeader", "accountingHeaders"], [], ["loc", [null, [141, 21], [141, 73]]]]], ["block", "if", [["get", "pdfOptions.customHeader", ["loc", [null, [144, 12], [144, 35]]]]], [], 3, null, ["loc", [null, [144, 6], [147, 13]]]], ["element", "action", ["downloadCSV", "accountingCSV"], [], ["loc", [null, [148, 26], [148, 67]]]], ["element", "action", ["toggleDownloadForm"], [], ["loc", [null, [149, 33], [149, 65]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 153,
            "column": 6
          }
        },
        "moduleName": "webui/pods/components/csv-export-settings-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "confirmation-modal");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createTextNode("Report Settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element27 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element27, 3, 3);
        morphs[1] = dom.createMorphAt(element27, 7, 7);
        morphs[2] = dom.createMorphAt(element27, 9, 9);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "eq", [["get", "this.type", ["loc", [null, [8, 12], [8, 21]]]], "true-tonnage"], [], ["loc", [null, [8, 8], [8, 37]]]]], [], 0, null, ["loc", [null, [8, 2], [26, 9]]]], ["block", "if", [["subexpr", "or", [["get", "ttrCSV", ["loc", [null, [29, 12], [29, 18]]]], ["get", "normalReport", ["loc", [null, [29, 19], [29, 31]]]]], [], ["loc", [null, [29, 8], [29, 32]]]]], [], 1, null, ["loc", [null, [29, 2], [103, 9]]]], ["block", "if", [["get", "accountingCSV", ["loc", [null, [105, 8], [105, 21]]]]], [], 2, null, ["loc", [null, [105, 2], [152, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});