define('webui/models/region', ['exports', 'ember-data', 'webui/mixins/string-formatting', 'webui/mixins/volume-calc-region', 'npm:polylabel'], function (exports, _emberData, _stringFormatting, _volumeCalcRegion, _npmPolylabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isFillHigh = function isFillHigh(area, cubicYardsFill) {
    if (!area) return false;

    var volumeInches = cubicYardsFill * Math.pow(36, 3);
    var areaInches = area * Math.pow(12, 2);
    var avgFill = volumeInches / areaInches;

    return avgFill < -2;
  };

  var baseplaneTypes = { bestfit: 'Best Fit', lowest: 'Lowest Point', custom: 'Custom', blueprint: 'Site Grade' };

  exports.default = _emberData.default.Model.extend(_stringFormatting.default, _volumeCalcRegion.default, {
    name: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    display: true,
    displayChangeDetection: false,
    copiedFromPreviousFlight: false,
    boundaries: _emberData.default.attr(),
    updatedAt: _emberData.default.attr('string'),
    area: _emberData.default.attr('number'),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    material: _emberData.default.belongsTo('material', { async: false }),
    earthworkAnalyses: _emberData.default.hasMany('earthwork-analysis', { async: true }),
    needsEarthworkRecalculated: _emberData.default.attr('boolean'),
    changeDetection: _emberData.default.belongsTo('change-detection', { async: true }),
    density: _emberData.default.attr('number', { defaultValue: 0 }),
    densityId: Ember.computed('id', function densityId() {
      return this.get('id') + '-density';
    }),
    editId: Ember.computed('id', function editId() {
      return this.get('id') + '-edit';
    }),
    color: _emberData.default.attr('string', { defaultValue: 'blue' }),
    baseplaneType: _emberData.default.attr('string', { defaultValue: 'bestfit' }),
    baseplaneBlueprint: _emberData.default.belongsTo('blueprint', { async: true }),
    baseplaneElevation: _emberData.default.attr('number', { defaultValue: null }),
    plannedTonnage: _emberData.default.attr('number', { defaultValue: null }),
    plannedTonnageId: Ember.computed('id', function plannedTonnageId() {
      return this.get('id') + '-plannedTonnage';
    }),
    baseplaneTypeDisplay: function materialDisplay() {
      return baseplaneTypes[this.get('baseplaneType')];
    }.property('baseplaneType'),

    tonnageVarianceDisplayColor: Ember.computed('tonnageVariance', function tonnageVarianceDisplayColor() {
      if (this.get('tonnageVariance') > 6) return 'red';
      if (this.get('tonnageVariance') > 3) return 'yellow';
      return 'green';
    }),

    regionNameWithMaterialCode: function regionNameWithMaterialCode() {
      if (!this.get('material.key')) return this.get('name');

      return this.get('name').replace(this.get('material.name'), this.get('material.key'));
    }.property('material'),

    executeAt: Ember.computed('flight', function executeAt() {
      return this.get('flight.executeAt');
    }),
    flightTime: Ember.computed('executeAt', function flightTime() {
      return this.dateString(this.get('executeAt'));
    }),
    cubicYardsCutDisplay: Ember.computed('cubicYardsCut', function cubicYardsCutDisplay() {
      return this.numberWithCommas(this.get('cubicYardsCut'));
    }),
    cubicYardsFillDisplay: Ember.computed('cubicYardsFill', function cubicYardsFillDisplay() {
      return this.numberWithCommas(Math.abs(this.get('cubicYardsFill')));
    }),

    cubicYardsCut: Ember.computed('earthworkAnalyses.@each.cubicYardsCut', function cubicYardsCut() {
      if (!this.get('earthworkAnalyses').objectAt(0)) return null;

      return this.get('earthworkAnalyses').objectAt(0).get('cubicYardsCut');
    }),
    cubicYardsFill: Ember.computed('earthworkAnalyses.@each.cubicYardsFill', function cubicYardsFill() {
      if (!this.get('earthworkAnalyses').objectAt(0)) return null;

      return this.get('earthworkAnalyses').objectAt(0).get('cubicYardsFill');
    }),

    status: Ember.computed('earthworkAnalyses.@each.status', function status() {
      if (!this.get('earthworkAnalyses').objectAt(0)) return null;

      return this.get('earthworkAnalyses').objectAt(0).get('status');
    }),

    statusDisplay: Ember.computed('status', 'cubicYardsFill', 'area', function statusDisplay() {
      var savedStatus = this.get('status');
      return isFillHigh(this.get('area'), this.get('cubicYardsFill')) && savedStatus === 'succeeded' ? 'warning' : savedStatus;
    }),

    succeeded: Ember.computed('statusDisplay', function succeeded() {
      return this.get('statusDisplay') === 'succeeded';
    }),
    pending: Ember.computed('statusDisplay', function pending() {
      return this.get('statusDisplay') === 'pending';
    }),
    waiting: Ember.computed('statusDisplay', function waiting() {
      return this.get('statusDisplay') === 'waiting';
    }),
    failed: Ember.computed('statusDisplay', function failed() {
      return this.get('statusDisplay') === 'failed';
    }),

    message: Ember.computed('earthworkAnalyses.@each.message', function message() {
      if (!this.get('earthworkAnalyses').objectAt(0)) return null;

      return this.get('earthworkAnalyses').objectAt(0).get('message');
    }),

    messageDisplay: Ember.computed('status', 'message', 'cubicYardsFill', 'area', function messageDisplay() {
      var message = '';

      if (this.get('message.name')) {
        message += this.get('message.name') + ': ';
      }

      if (this.get('message.message')) {
        message += this.get('message.message');
      }

      if (this.get('message.statusCode')) {
        message += '(' + this.get('message.statusCode') + ')';
      }

      if (this.get('status') === 'succeeded' && isFillHigh(this.get('area'), this.get('cubicYardsFill'))) {
        message = 'Check that region points are placed accurately on stockpile toe. Higher-than-usual fill volume was detected.';
      }

      return message;
    }),

    mapboxPointFeature: Ember.computed('name', 'boundaries', function mapboxPointFeature() {
      var coordinates = this.get('boundaries').map(function (point) {
        return [point.lng, point.lat];
      });

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: (0, _npmPolylabel.default)([coordinates])
        },
        properties: {
          name: this.get('name'),
          regionName: this.get('name'),
          id: this.get('id'),
          regionNameWithMaterialCode: this.get('regionNameWithMaterialCode')
        }
      };
    }),

    selectableMaterials: Ember.computed('flight', 'material', function selectableMaterials() {
      var _this = this;

      var materials = this.get('flight.site.materials').filter(function (material) {
        return !material.get('isArchived') || _this.get('material.id') === material.get('id');
      }).sortBy('name');

      return materials;
    })
  });
});