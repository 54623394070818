define('webui/models/overlay-blueprint', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    epsgCode: _emberData.default.attr('number'),
    urlPrefix: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    site: _emberData.default.belongsTo('site', { async: true }),
    tiledMap: _emberData.default.belongsTo('tiled-map', { async: true }),
    createdAt: _emberData.default.attr('date')
  });
});