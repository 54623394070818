define('webui/pods/components/status-dropdown/status-panel-blueprint-region/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    statusService: Ember.inject.service('status-panel'),

    tagName: 'ul',
    classNames: ['notification-list'],

    actions: {
      removeItem: function removeItem(ob) {
        var statusService = this.get('statusService');
        statusService.removeItem('status-panel-blueprint-region', ob);
      }
    }
  });
});