define('webui/pods/components/video-embed/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    selectedVideo: null,
    videoUrl: null,

    init: function init() {
      this._super();
      var flyoverVideos = this.get('flight.flyoverVideos');
      if (flyoverVideos.get('length') > 0) {
        var selectedVideo = flyoverVideos.get('firstObject');
        var videoUrl = '' + selectedVideo.get('urlPrefix') + selectedVideo.get('fileUrl');
        this.set('selectedVideo', selectedVideo);
        this.set('videoUrl', videoUrl);
      }
    },


    actions: {
      selectVideo: function selectVideo(flyoverVideoId) {
        var store = this.get('store');
        var video = store.peekRecord('flyoverVideo', flyoverVideoId);
        var videoUrl = '' + video.get('urlPrefix') + video.get('fileUrl');
        this.set('selectedVideo', video);
        this.set('videoUrl', videoUrl);
        $('#video-embed').load();
      }
    }
  });
});