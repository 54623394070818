define('webui/pods/components/flight-info-panel/flyover-video-info/component', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    deleteMode: false,
    tag: 'ul',
    classNames: ['list-group-item', 'row', 'products-list-item', 'flex-container'],

    actions: {
      editFlyoverVideo: function editFlyoverVideo() {
        this.set('editingFlyoverVideo', true);
      },
      saveFlyoverVideo: function saveFlyoverVideo() {
        var flyoverVideo = this.get('flyoverVideo');
        var newName = $('#' + flyoverVideo.get('id') + '-name').val();
        if (newName.length > 0) {
          flyoverVideo.set('name', newName);
          flyoverVideo.save();
          this.set('editingFlyoverVideo', false);
        }
      },
      shareVideoLink: function shareVideoLink(modalId) {
        $('#' + modalId).modal('show');
        this.trackEvent('Copy Flyover Video URL');
      },
      toggleDeleteForm: function toggleDeleteForm() {
        this.toggleProperty('deleteMode');
      },
      deleteFlyoverVideo: function deleteFlyoverVideo() {
        this.get('flyoverVideo').destroyRecord();
      }
    }
  });
});