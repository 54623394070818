define('webui/models/work-activity', ['exports', 'ember-data', 'webui/mixins/numerical-conversion', 'webui/mixins/string-formatting', 'npm:polylabel'], function (exports, _emberData, _numericalConversion, _stringFormatting, _npmPolylabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var PolygonMeasurementTypes = ['2DArea', '3DArea', 'VolumeCalc', '3DQuantity'];
  var measurementDisplayTypes = {
    '2DLine': 'Distance', '3DLine': 'Surface Distance', '2DArea': 'Area', '3DArea': 'Surface Area', VolumeCalc: 'Volume Calc', '3DQuantity': 'Quantity'
  };
  var textUnits = {
    '2DLine': 'ft', '3DLine': 'ft', '2DArea': 'sqft', '3DArea': 'sqft', VolumeCalc: 'cuyd', '3DQuantity': 'cuyd'
  };
  var HTMLUnits = {
    '2DLine': 'ft', '3DLine': 'ft', '2DArea': 'ft<sup>2</sup>', '3DArea': 'ft<sup>2</sup>', VolumeCalc: 'yd<sup>3</sup>', '3DQuantity': 'yd<sup>3</sup>'
  };
  var baseplaneTypes = { bestfit: 'Best Fit', lowest: 'Lowest Point', custom: 'Custom' };

  exports.default = _emberData.default.Model.extend(_numericalConversion.default, _stringFormatting.default, {
    activityType: _emberData.default.attr('string'),
    measurementType: _emberData.default.attr('string'),
    featureType: _emberData.default.attr('string'),
    featureGeometry: _emberData.default.attr(),
    result: _emberData.default.attr(),
    resultType: _emberData.default.attr('string'),
    site: _emberData.default.belongsTo('site', { async: true }),
    flight: _emberData.default.belongsTo('flight', { async: true }),
    surface1: _emberData.default.attr('string'),
    surface2: _emberData.default.attr('string'),
    surface1Type: _emberData.default.attr('string'),
    surface2Type: _emberData.default.attr('string'),
    baseplaneType: _emberData.default.attr('string', { defaultValue: 'bestfit' }),
    baseplaneElevation: _emberData.default.attr('number', { defaultValue: null }),
    token: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    message: _emberData.default.attr(),
    isSiteLevel: _emberData.default.attr('boolean'),

    // ember only
    display: true,
    editMode: false,
    typeIsNew: false,

    messageDisplay: Ember.computed('message', function messageDisplay() {
      var message = '';

      if (this.get('message.name')) {
        message += this.get('message.name') + ': ';
      }

      if (this.get('message.message')) {
        message += this.get('message.message');
      }

      if (this.get('message.statusCode')) {
        message += '(' + this.get('message.statusCode') + ')';
      }

      return message;
    }),

    succeeded: Ember.computed('status', function succeeded() {
      if (this.get('status') === 'succeeded') {
        return true;
      }
      return false;
    }),
    pending: Ember.computed('status', function pending() {
      if (this.get('status') === 'pending') {
        return true;
      }
      return false;
    }),
    warning: Ember.computed('status', function warning() {
      if (this.get('status') === 'warning') {
        return true;
      }
      return false;
    }),
    failed: Ember.computed('status', function failed() {
      if (this.get('status') === 'failed') {
        return true;
      }
      return false;
    }),

    startingSurface: Ember.computed('surface1', 'surface1Type', function startingSurface() {
      if (!this.get('surface1')) {
        return '';
      }

      if (this.get('surface1Type') === 'Blueprint') {
        return this.get('store').peekRecord('blueprint', this.get('surface1')).get('name');
      }

      var flight = this.get('store').peekRecord('product', this.get('surface1')).get('flight');
      return flight.get('displayFlightTime') + '<span class=\'map-subtitle\'>' + (flight.get('subtitle') || '') + '</span>';
    }),

    endingSurface: Ember.computed('surface2', 'surface2Type', function endingSurface() {
      if (!this.get('surface2')) {
        return '';
      }

      if (this.get('surface2Type') === 'Blueprint') {
        return this.get('store').peekRecord('blueprint', this.get('surface2')).get('name');
      }

      var flight = this.get('store').peekRecord('product', this.get('surface2')).get('flight');
      return flight.get('displayFlightTime') + '<span class=\'map-subtitle\'>' + (flight.get('subtitle') || '') + '</span>';
    }),

    processedResult: Ember.computed('result', function processedResult() {
      var _this = this;

      var results = this.get('result').map(function (result) {
        var value = result[_this.get('resultType')];
        return _this.get('resultType') === 'Fill' ? Math.abs(value) : value;
      });
      return results;
    }),

    workQuantity: Ember.computed('processedResult', function workQuantity() {
      return this.get('processedResult').reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0);
    }),

    workQuantityHTMLUnits: Ember.computed('measurementType', function workQuantityHTMLUnits() {
      return HTMLUnits[this.get('measurementType')];
    }),

    workQuantityDisplay: Ember.computed('workQuantity', 'workQuantityHTMLUnits', 'measurementType', function workQuantityDisplay() {
      var displayedWorkQuantity = this.get('resultType') === 'Fill' ? Math.abs(this.get('workQuantity')) : this.get('workQuantity');
      var value = this.numberWithCommas(Math.round(displayedWorkQuantity));
      var units = this.get('workQuantityHTMLUnits');

      if (this.get('measurementType') === '2DArea') {
        var acres = this.squareFeetToAcres(displayedWorkQuantity);
        return value + ' ' + units + '&emsp;(' + acres + ' acres)';
      }

      return value + ' ' + units;
    }),

    processedResultMulti: Ember.computed('result', '', function processedResultMulti() {
      var results = this.get('result').map(function (result) {
        var processedResult = _extends({}, result);
        Object.keys(processedResult).forEach(function (type) {
          processedResult[type] = type === 'Fill' ? Math.abs(processedResult[type]) : processedResult[type];
        });
        return processedResult;
      });

      return results;
    }),

    workQuantityMulti: Ember.computed('result', function workQuantity() {
      var _this2 = this;

      var zeroes = {};
      Object.keys(this.get('result')[0]).forEach(function (key) {
        zeroes[key] = 0;
      });

      var results = this.get('result').reduce(function (accumulator, currentValue) {
        var sum = _extends({}, accumulator);

        Object.keys(sum).forEach(function (key) {
          sum[key] += key === 'Fill' ? Math.abs(currentValue[key]) : currentValue[key];
        });

        return sum;
      }, zeroes);

      return Object.keys(results).sort().map(function (type) {
        var value = type === 'Fill' ? Math.abs(results[type]) : results[type];
        var formattedValue = _this2.numberWithCommas(Math.round(value));
        var units = _this2.get('workQuantityHTMLUnits');

        return { type: type, value: formattedValue, units: units };
      });
    }),

    totalsByResultType: Ember.computed('result', 'surface1', 'surface1Type', 'flight', 'measurementType', function totalsByResultType() {
      var _this3 = this;

      var totals = [];
      var keys = Object.keys(this.get('result')[0]);

      keys.forEach(function (key, index) {
        totals[index] = { resultType: key, total: 0 };
      });
      this.get('result').forEach(function (region) {
        return keys.forEach(function (key, index) {
          totals[index].total += region[key];
        });
      });

      var displayTotals = totals.map(function (resultType) {
        resultType.total = _this3.numberWithCommas(Math.round(resultType.total));
        return resultType;
      });

      return displayTotals;
    }),

    drawType: Ember.computed('measurementType', function drawType() {
      return PolygonMeasurementTypes.indexOf(this.get('measurementType')) !== -1 ? 'Polygon' : 'LineString';
    }),

    displayDrawType: Ember.computed('measurementType', function displayDrawType() {
      return PolygonMeasurementTypes.indexOf(this.get('measurementType')) !== -1 ? 'Polygon' : 'Line';
    }),

    displayMeasurementType: Ember.computed('measurementType', function displayMeasurementType() {
      return measurementDisplayTypes[this.get('measurementType')];
    }),

    mapboxPointFeature: Ember.computed('activityType', 'featureGeometry', 'featureType', 'measurementType', 'processedResult', 'regionsHaveBeenCopied', function mapboxPointFeature() {
      var _this4 = this;

      var features = [];
      this.get('featureGeometry').forEach(function (geometry, index) {
        if (!Array.isArray(geometry)) {
          return;
        }

        var valueDisplay = '';
        if (typeof _this4.get('processedResultMulti')[index] !== 'undefined') {
          if (_this4.get('isSiteLevel')) {
            Object.keys(_this4.get('processedResultMulti')[index]).sort().forEach(function (type) {
              var value = _this4.numberWithCommas(Math.round(_this4.get('processedResultMulti')[index][type]));
              var units = textUnits[_this4.get('measurementType')];

              valueDisplay += '\n' + type + ': ' + value + ' (' + units + ')';
            });
          } else {
            var value = _this4.numberWithCommas(Math.round(_this4.get('processedResult')[index]));
            var units = textUnits[_this4.get('measurementType')];

            valueDisplay = '\n' + value + ' (' + units + ')';
          }
        }

        var coords = geometry.map(function (point) {
          return [point.lng, point.lat];
        });
        var center = void 0;
        if (_this4.get('featureType') === 'Polygon') {
          center = (0, _npmPolylabel.default)([coords]);
        } else {
          center = (0, _npmPolylabel.default)([coords]);
        }

        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center
          },
          properties: {
            name: '' + _this4.get('activityType') + valueDisplay
          }
        });
      });

      return features;
    }),

    density: _emberData.default.attr('number', { defaultValue: 1 }),

    displayDensity: Ember.computed('density', function displayDensity() {
      return this.numberWithCommas(this.get('density'));
    }),

    tonnage: Ember.computed('density', 'workQuantity', function tonnage() {
      if (this.get('density') === 0) {
        return null;
      }
      return Math.abs(Math.round(this.get('workQuantity') * this.get('density') * 100) / 100);
    }),

    displayTonnage: Ember.computed('tonnage', function displayTonnage() {
      return this.numberWithCommas(Math.abs(this.get('tonnage')));
    }),

    baseplaneTypeDisplay: Ember.computed('baseplaneType', function baseplaneTypeDisplay() {
      return baseplaneTypes[this.get('baseplaneType')];
    }),

    baseplaneElevationDisplay: Ember.computed('baseplaneElevation', function baseplaneElevationDisplay() {
      if (!this.get('baseplaneElevation')) {
        return null;
      }

      return this.numberWithCommas(parseFloat(this.get('baseplaneElevation')).toFixed(2));
    })
  });
});