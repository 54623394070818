define('webui/mixins/measurements-on-map', ['exports', 'ember-cli-segment/mixin'], function (exports, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_mixin.default, {
    createMeasurementPopup: function createMeasurementPopup(position, content) {
      var mapData = this.get('mapData');
      var popup = new mapData.MAPAPI.Popup({ closeOnClick: false }).setLngLat(position) // pop at end
      .setHTML(['<h5>', content, '</h5>'].join(''));
      return popup;
    },
    displayMeasurementPopup: function displayMeasurementPopup(measurementPopup) {
      var mapData = this.get('mapData');
      if (measurementPopup) {
        this.removeMeasurementPopup();
        measurementPopup.addTo(mapData.mapInstance);
        mapData.set('popup.measurement', measurementPopup); // Look for programmatic opening method.
      }
    },
    displayExistingMeasurementPopup: function displayExistingMeasurementPopup() {
      var existingPopUp = this.getMeasurementPopup();
      this.displayMeasurementPopup(existingPopUp);
    },
    removeMeasurementPopup: function removeMeasurementPopup() {
      var existingPopUp = this.getMeasurementPopup();
      if (existingPopUp) {
        existingPopUp.remove();
      }
    },
    getMeasurementPopup: function getMeasurementPopup() {
      var mapData = this.get('mapData');
      var existingPopUp = mapData.get('popup.measurement');
      return existingPopUp;
    },
    drawAndMeasureOnMap: function drawAndMeasureOnMap() {
      var _this = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var mapData = this.get('mapData'); // available from the context of the mixin
      var measurementUnit = ' ft.'; // This displays as part of the label - will need changed if metric is ever used.
      var qualifier = 'line_measurement';
      if (type === 'work_activity') {
        qualifier = 'work_activity_2d_line';
      }

      mapData.setListeningMode(qualifier);
      if (mapData.get('currentDrawingMode') !== qualifier) {
        mapData.changeDrawingMode(qualifier);
      }

      var mapbox = mapData.get('mapInstance');

      var layerPaintOptions = {
        'text-color': '#fff',
        'text-halo-color': '#000',
        'text-halo-width': 1,
        'text-halo-blur': 2
      };

      /* Recreating a layer breaks the labels so we check for it first. */
      var layerCold = mapbox.getLayer('line-measurement-labels-cold');
      if (!layerCold) {
        mapbox.addLayer({
          id: 'line-measurement-labels-cold',
          type: 'symbol',
          source: 'mapbox-gl-draw-cold',
          layout: {
            'symbol-placement': 'line',
            'text-font': ['Open Sans Regular'],
            'text-field': '{user_title}', /* Note that setFeatureProperty() automatically prepends "user_" to the property name here. */
            'text-size': 16
          },
          paint: layerPaintOptions
        });
      }

      var layerHot = mapbox.getLayer('line-measurement-labels-hot');
      if (!layerHot) {
        mapbox.addLayer({
          id: 'line-measurement-labels-hot',
          type: 'symbol',
          source: 'mapbox-gl-draw-hot',
          layout: {
            'symbol-placement': 'line',
            'text-font': ['Open Sans Regular'],
            'text-field': '{title}',
            'text-size': 16
          },
          paint: layerPaintOptions
        });
      }

      /* The listeners are lazily injected the first time they're needed. The alternative here would be to require the
         implementing object to establish the listeners, and that seems untenable/unintuitive. */
      if (!mapData.hasListeners(qualifier)) {
        mapData.addListener('create', qualifier, function (line) {
          if (mapData.get('currentDrawingMode') === qualifier && line.features.length && line.features[0].geometry.type === 'LineString') {
            var length = line.features[0].geometry.coordinates.length;

            var position = line.features[0].geometry.coordinates[length - 1];
            var content = mapData.calculateLength(line.features[0]) + measurementUnit;
            if (qualifier !== 'work_activity_2d_line') {
              /* Note: setFeatureProperty() checks both the hot and cold sources for the ID. */
              mapData.drawingManager.setFeatureProperty(line.features[0].id, 'title', content);
              mapData.drawingManager.setFeatureProperty(line.features[0].id, 'observable_as', qualifier);
              mapData.drawingManager.setFeatureProperty(line.features[0].id, 'drawing_type', 'manual');
            }
            mapData.set('line', line);
            /* MapData only expects one active line. */
            _this.displayMeasurementPopup(_this.createMeasurementPopup(position, content));
          }
        }).addListener('update', qualifier, function (line) {
          if (line.features.length && line.features[0].geometry.type === 'LineString' && (mapData.get('currentDrawingMode') === qualifier || line.features[0].properties.observable_as === qualifier)) {
            var length = line.features[0].geometry.coordinates.length;

            var position = line.features[0].geometry.coordinates[length - 1];
            var content = mapData.calculateLength(line.features[0]) + measurementUnit;

            mapData.drawingManager.setFeatureProperty(line.features[0].id, 'title', content); /* Update the label with the new measurement. */
            mapData.set('line', line);
            _this.displayMeasurementPopup(_this.createMeasurementPopup(position, content));
          }
        }).addListener('selectionchange', qualifier, function (line) {
          if (line.features.length && line.features[0].geometry.type === 'LineString' && (mapData.get('currentDrawingMode') === qualifier || line.features[0].properties.observable_as === qualifier)) {
            /* TODO see if the selection has changed by comparing IDs */
            var length = line.features[0].geometry.coordinates.length;

            var position = line.features[0].geometry.coordinates[length - 1];
            var content = mapData.calculateLength(line.features[0]) + measurementUnit;

            mapData.set('line', line);
            /* if a new line is selected change the popup location. */
            _this.displayMeasurementPopup(_this.createMeasurementPopup(position, content));
          }
        }).addListener('modechange', qualifier, function (mode) {
          /* This sets the drawing mode to line drawing for as long as the user has the measuring tool selected.
           * Note that the drawing library automatically changes the mode to "simple_select" once a line is completed so we
           * look for that change here and force it from selection mode to line mode.
           * NOTE: because mapData listeners automatically check for drawing mode (based on 'qualifier') we don't need to double check
           * here. */
          if (mapData.get('currentDrawingMode') === qualifier && mode.mode === 'simple_select') {
            mapData.drawingManager.changeMode('draw_line_string');
          }
        }).addListener('render', qualifier, function () /* event */{
          if (mapData.get('currentDrawingMode') === qualifier || mapData.getListeningMode() === qualifier) {
            /* The "hot" source comprises items in active draw. We check them here and update the label as they are drawn to dynamically
             * change the label value as the cursor is dragged over the map.
             * Note: the render hook is called every time the mouse moves so abusing this could lead to performance issues. */
            var source = mapData.mapInstance.getSource('mapbox-gl-draw-hot');
            var dirty = false;
            // eslint-disable-next-line no-underscore-dangle
            source._data.features.forEach(function (el) {
              if (el.geometry.type === 'LineString') {
                // eslint-disable-next-line no-param-reassign
                el.properties.title = mapData.calculateLength(el) + measurementUnit;
                dirty = true;
              }
            });

            if (dirty) {
              source.fire('dataloading', { dataType: 'source' });
              /* This tells mapbox that the data was changed so a redraw occurs. */
              // eslint-disable-next-line no-underscore-dangle
              source._updateWorkerData(function (err) {
                if (err) {
                  return source.fire('error', { error: err });
                }
                return source.fire('data', { dataType: 'source', sourceDataType: 'content' });
              });
            }
          }
        });
      }
    },
    deleteLines: function deleteLines() {
      var mapData = this.get('mapData');
      var popupToDelete = mapData.get('popup.measurement');
      if (mapData.mapInstance.getLayer('line-measurement-labels-cold')) {
        mapData.mapInstance.removeLayer('line-measurement-labels-cold'); /* This does not fail silently if the layer doesn't exist so we check for it. */
      }
      if (mapData.mapInstance.getLayer('line-measurement-labels-hot')) {
        mapData.mapInstance.removeLayer('line-measurement-labels-hot');
      }

      var data = mapData.drawingManager.getAll();
      if (data.features) {
        data.features.forEach(function (el) {
          if (el.geometry.type === 'LineString' && el.properties.observable_as === 'line_measurement' && el.properties.drawing_type === 'manual') {
            mapData.drawingManager.delete(el.id);
          }
        });
      }

      if (popupToDelete) {
        popupToDelete.remove();
      }
    },
    cleanUpDrawAndMeasure: function cleanUpDrawAndMeasure() {
      this.deleteLines();
    }
  });
});