define('webui/pods/admin/client/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    updateClients: function updateClients(data) {
      var controller = this.get('controller');
      if (data) {
        controller.set('model', data.sortBy('name'));
      }
    },


    actions: {
      updateClients: function updateClients(data) {
        this.updateClients(data);
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);
      this.updateClients(this.get('store').peekAll('client'));
      controller.set('columns', [{
        propertyName: 'name',
        routeName: 'admin.client.edit'
      }, {
        propertyName: 'isAdmin',
        title: 'Admin?',
        filterWithSelect: true
      }, {
        propertyName: 'displayStatus',
        title: 'Status',
        filterWithSelect: true
      }, {
        propertyName: 'city'
      }, {
        propertyName: 'state',
        filterWithSelect: true
      }, {
        propertyName: 'usesCredits',
        title: 'Uses Credits',
        filterWithSelect: true
      }, {
        propertyName: 'creditCount',
        title: 'Credit Count'
      }]);
    }
  });
});