define("webui/pods/components/quantities-panel/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 14,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/quantities-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "slimButton");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("+ New  Quantities Region");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n             \n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "slimButton");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("Go To File Manager");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1, 1]);
          var element4 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createElementMorph(element3);
          morphs[2] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "mapData.currentDrawingMode", ["loc", [null, [8, 61], [8, 87]]]], "quantity"], [], ["loc", [null, [8, 57], [8, 99]]]], "active"], [], ["loc", [null, [8, 52], [8, 110]]]]]]], ["element", "action", ["newQuantity"], [], ["loc", [null, [8, 20], [8, 44]]]], ["element", "action", ["goToFileManager"], [], ["loc", [null, [12, 20], [12, 48]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 14
              },
              "end": {
                "line": 25,
                "column": 14
              }
            },
            "moduleName": "webui/pods/components/quantities-panel/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            dom.setAttribute(el1, "selected", "selected");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Feet");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "staticSurface.value", ["loc", [null, [24, 33], [24, 52]]]]]]], ["content", "staticSurface.value", ["loc", [null, [24, 76], [24, 99]]]]],
          locals: ["staticSurface"],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 39,
              "column": 8
            }
          },
          "moduleName": "webui/pods/components/quantities-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "btn-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("select");
          dom.setAttribute(el2, "id", "static-selector");
          dom.setAttribute(el2, "class", "selectpicker");
          dom.setAttribute(el2, "data-container", "body");
          dom.setAttribute(el2, "multiple", "");
          dom.setAttribute(el2, "data-tick-icon", "glyphicon-remove");
          dom.setAttribute(el2, "data-width", "150px");
          dom.setAttribute(el2, "data-selected-text-format", "static");
          dom.setAttribute(el2, "title", "Static Elevations");
          dom.setAttribute(el2, "data-style", "panel-selector");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "btn-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "input-group");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "input-group-btn");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn custom-surface-add-button");
          dom.setAttribute(el4, "type", "button");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "glyphicon glyphicon-plus");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3, 1]);
          var element2 = dom.childAt(element1, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["block", "each", [["get", "staticSurfaces", ["loc", [null, [23, 22], [23, 36]]]]], [], 0, null, ["loc", [null, [23, 14], [25, 23]]]], ["inline", "input", [], ["class", "custom-floor-input", "type", "number", "placeholder", "Add Static Elevation", "size", "20", "key-up", "updateCustomElevation", "value", ["subexpr", "@mut", [["get", "customElevation", ["loc", [null, [31, 146], [31, 161]]]]], [], []]], ["loc", [null, [31, 14], [31, 164]]]], ["element", "action", ["addCustomElevation"], [], ["loc", [null, [33, 76], [33, 107]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 8
              },
              "end": {
                "line": 52,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/quantities-panel/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "quantity-display", [], ["quantity", ["subexpr", "@mut", [["get", "quantity", ["loc", [null, [51, 38], [51, 46]]]]], [], []], "updatedAt", ["subexpr", "@mut", [["get", "quantity.updatedAt", ["loc", [null, [51, 57], [51, 75]]]]], [], []], "activeFlight", ["subexpr", "@mut", [["get", "activeFlight", ["loc", [null, [51, 89], [51, 101]]]]], [], []], "activeSite", ["subexpr", "@mut", [["get", "activeSite", ["loc", [null, [51, 113], [51, 123]]]]], [], []], "siteGrades", ["subexpr", "@mut", [["get", "siteGrades", ["loc", [null, [51, 135], [51, 145]]]]], [], []], "staticSurfaces", ["subexpr", "@mut", [["get", "staticSurfaces", ["loc", [null, [51, 161], [51, 175]]]]], [], []], "isReadOnly", ["subexpr", "@mut", [["get", "isReadOnly", ["loc", [null, [51, 187], [51, 197]]]]], [], []]], ["loc", [null, [51, 10], [51, 199]]]]],
          locals: ["quantity"],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 6
            },
            "end": {
              "line": 53,
              "column": 6
            }
          },
          "moduleName": "webui/pods/components/quantities-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "draw-data", ["loc", [null, [50, 16], [50, 25]]]]], [], 0, null, ["loc", [null, [50, 8], [52, 17]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 0
            },
            "end": {
              "line": 63,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/quantities-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "color: #333333;");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "csv-export-settings-modal", [], ["type", "quantity", "toggleDownloadForm", ["subexpr", "action", ["toggleDownloadForm"], [], ["loc", [null, [61, 68], [61, 97]]]], "downloadCSV", ["subexpr", "action", ["downloadCSV"], [], ["loc", [null, [61, 110], [61, 132]]]]], ["loc", [null, [61, 4], [61, 135]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/quantities-panel/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "quantitiesPanel");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-top");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-header row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-10 text-left");
        var el5 = dom.createTextNode("\n        Quantities    \n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("           \n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "slimButton");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "class", "regionReport");
        var el7 = dom.createTextNode("Generate Report for Excel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-2 text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "glyphicon glyphicon-remove");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-body");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "regions container-fluid");
        dom.setAttribute(el3, "style", "padding-bottom:40px;");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [3, 1]);
        var element9 = dom.childAt(element6, [3, 1]);
        var element10 = dom.childAt(element5, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element7, 1, 1);
        morphs[1] = dom.createElementMorph(element8);
        morphs[2] = dom.createMorphAt(element7, 5, 5);
        morphs[3] = dom.createElementMorph(element9);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
        morphs[5] = dom.createMorphAt(element10, 3, 3);
        morphs[6] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isReadOnly", ["loc", [null, [6, 18], [6, 28]]]]], [], 0, null, ["loc", [null, [6, 8], [14, 19]]]], ["element", "action", ["toggleDownloadForm"], [], ["loc", [null, [17, 18], [17, 49]]]], ["block", "unless", [["get", "isReadOnly", ["loc", [null, [20, 18], [20, 28]]]]], [], 1, null, ["loc", [null, [20, 8], [39, 19]]]], ["element", "action", ["leavePanel"], [], ["loc", [null, [42, 49], [42, 72]]]], ["block", "if", [["get", "draw-data", ["loc", [null, [49, 12], [49, 21]]]]], [], 2, null, ["loc", [null, [49, 6], [53, 13]]]], ["content", "yield", ["loc", [null, [55, 4], [55, 13]]]], ["block", "if", [["get", "showCSVSettings", ["loc", [null, [59, 6], [59, 21]]]]], [], 3, null, ["loc", [null, [59, 0], [63, 7]]]], ["inline", "region-edit-confirmation", [], ["unsavedPolygonsExist", ["subexpr", "@mut", [["get", "unsavedPolygonsExist", ["loc", [null, [65, 48], [65, 68]]]]], [], []], "type", "Quantity Region", "typeText", "region(s)", "panelLocation", "bottom"], ["loc", [null, [65, 0], [65, 137]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});