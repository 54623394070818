define('webui/pods/components/data-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    didInsertElement: function didInsertElement() {
      this._super();
      this.set('canRerender', true);
    },


    actions: {
      updateFlightModelProducts: function updateFlightModelProducts() {
        this.sendAction('updateFlightModelProducts');
      },
      updateSiteUsers: function updateSiteUsers(user) {
        this.sendAction('doSiteUsersUpdate', user);
      },
      closeDataPanel: function closeDataPanel() {
        var dataPanelService = this.get('dataPanelService');
        dataPanelService.set('isOpen', false);
        dataPanelService.set('totalsChartComponent', undefined);
        dataPanelService.set('weeklyChartComponent', undefined);
        dataPanelService.set('dataPanelComponent', undefined);
      },
      closeFloatingPanel: function closeFloatingPanel() {
        var dataPanelService = this.get('dataPanelService');
        dataPanelService.set('floatingPanelIsOpen', false);
        dataPanelService.set('floatingPanelComponent', null);
      },
      goToFileManager: function goToFileManager(id, mode) {
        this.sendAction('goToFileManager', id, mode);
      },
      goToMaterialManager: function goToMaterialManager(id) {
        this.sendAction('goToMaterialManager', id);
      },
      goToRegionManager: function goToRegionManager(id) {
        this.sendAction('goToRegionManager', id);
      }
    }
  });
});