define('webui/pods/components/floating-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    closePanel: 'closePanel',
    setCurrentChangeDetection: 'setCurrentChangeDetection',
    actions: {
      closePanel: function closePanel() {
        this.sendAction('closePanel');
      },
      setCurrentChangeDetection: function setCurrentChangeDetection(cd) {
        this.sendAction('setCurrentChangeDetection', cd);
      },
      goToFileManager: function goToFileManager(id, mode) {
        this.sendAction('goToFileManager', id, mode);
      }
    }
  });
});