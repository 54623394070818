define('webui/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'webui/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sessionsUrl = _environment.default.APP.apiUrl + 'sessions';

  exports.default = _base.default.extend({
    authenticate: function authenticate(_ref) {
      var email = _ref.email,
          password = _ref.password,
          id = _ref.id,
          token = _ref.token,
          type = _ref.type;

      var data = type === 'field' ? { id: id, token: token } : { email: email, password: password };
      var sessionRoute = type === 'field' ? 'field' : '';

      return Ember.RSVP.resolve(Ember.$.ajax({
        method: 'POST',
        url: sessionsUrl + '/' + sessionRoute,
        crossDomain: true,
        xhrFields: { withCredentials: true },
        data: data
      })
      // also can return responseText, xhr
      .then(function (response) {
        return response;
      }, function (xhrError) {
        return xhrError;
      }).then(function (response) {
        try {
          io.socket.disconnect();
        } catch (e) {
          console.warn('Socket already disconnected. Bet you\'re using firefox.', e);
        }
        return response;
      }).then(function (response) {
        try {
          io.socket.reconnect();
        } catch (e) {
          console.warn('In socket reconnect; didn\'t work; bet you\'re using firefox.', e);
        }
        return response;
      }));
    },
    restore: function restore() {
      return Ember.$.ajax({
        method: 'GET',
        url: sessionsUrl,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).then(function (response) {
        return response;
      }, function (xhr) {
        return xhr.responseJSON || xhr.responseText;
      });
    },
    invalidate: function invalidate() {
      return Ember.$.ajax({
        type: 'DELETE',
        url: sessionsUrl,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).then(function (response) {
        return response;
      }, function (xhr) {
        console.error('error deleting session', xhr);
        return xhr.responseJSON || xhr.responseText;
      });
    }
  });
});