define('webui/models/true-tonnage-report-material', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    trueTonnageReport: _emberData.default.belongsTo('true-tonnage-report', { async: true }),
    material: _emberData.default.attr('string'),
    plannedTonnage: _emberData.default.attr('number'),
    displayPlannedTonnage: Ember.computed('plannedTonnage', function displayPlannedTonnage() {
      return this.numberWithCommas(this.get('plannedTonnage'));
    }),
    changeRequested: _emberData.default.attr('boolean'),
    changeRequestStep: _emberData.default.belongsTo('true-tonnage-report-step'),
    changeRequestTonnage: _emberData.default.attr('number'),
    changeRequestReason: _emberData.default.attr('string'),
    changeRequestFulfilledComment: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    density: _emberData.default.attr('number'),
    displayDensity: Ember.computed('density', function displayDensity() {
      return this.numberWithCommas(this.get('density'));
    }),
    key: _emberData.default.attr('string'),
    dollarValue: _emberData.default.attr('number'),
    hideFromTrueTonnageReport: _emberData.default.attr('boolean')
  });
});