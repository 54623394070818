define('webui/models/user', ['exports', 'ember-data', 'webui/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    sendFlightDataUpdateEmails: _emberData.default.attr('boolean', { defaultValue: true }),
    clients: _emberData.default.hasMany('client', { async: true }),
    sites: _emberData.default.hasMany('site', { async: true }),
    userSites: _emberData.default.hasMany('user-site', { async: true }),
    fieldUser: _emberData.default.belongsTo('field-user', { async: true }),
    historicalExports: _emberData.default.hasMany('historical-export', { async: true }),
    fullName: function fullName() {
      if (!this.get('firstName') && !this.get('lastName')) {
        return 'No Name Provided';
      }

      return this.get('firstName') + ' ' + this.get('lastName');
    }.property('firstName', 'lastName'),
    fullNameAndEmail: function fullNameAndEmail() {
      if (this.get('firstName') && this.get('lastName')) {
        return this.get('fullName') + ' (' + this.get('email') + ')';
      }

      return this.get('email');
    }.property('fullName', 'email'),
    currentSite: null, /* Assigned as part of the route's user resolution */
    currentSiteAccess: 'unassigned', /* Determined from resolveSiteAccess() */
    currentSiteAccessRef: null, /* Assigned as part of the route's user resolution */
    inheritsClientAdmin: false, /* Assigned as part of the route's user resolution */
    isSiteAdmin: false, /* Assigned in resolveSiteAccess() */
    isLimitedAccess: false, /* Assigned in resolveSiteAccess() */
    isViewOnly: false, /* Assigned in resolveSiteAccess() */
    isAccounting: false, /* Assigned in resolveSiteAccess() */
    isSuperUser: false, /* Assigned in resolveSiteAccess() */
    isOnTeamRoster: false, /* Assigned in resolveSiteAccess() */
    permissionLevelSortOrder: 0, /* Assigned in resolveSiteAccess() */
    isPending: Ember.computed.equal('status', 'pending'),
    initials: function initials() {
      var firstInitial = this.get('firstName') ? this.get('firstName').substr(0, 1).toUpperCase() : '';
      var lastInitial = this.get('lastName') ? this.get('lastName').substr(0, 1).toUpperCase() : '';

      return '' + firstInitial + lastInitial;
    }.property('firstName', 'lastName'),
    currentSiteAccessChanged: function currentSiteAccessChanged() {
      this.resolveSiteAccess();
    }.observes('currentSiteAccessRef.access'), /* TODO: hypothetically this could monitor currentSite and the full currentSiteAccessRef object as well, but there doesn't seem to be immediate benefit to it now. */
    assignSiteAccess: function assignSiteAccess(site, role, isClientAdmin, isOnTeamRoster) {
      this.set('currentSite', site);
      this.set('inheritsClientAdmin', isClientAdmin);
      this.set('isOnTeamRoster', isOnTeamRoster);
      this.set('currentSiteAccessRef', role); /* Passing the ref to this record around makes deleting it much less error prone since Ember reference handling is questionable at best. */
    },
    resolveSiteAccess: function resolveSiteAccess() {
      /* This is an intentionally limited and "trusting" method because the permissions system is primitive. This type of
       * functionality could be included in the route and applied to each site user, but this keeps it centralized and restricts
       * assignments to the user model. Pre-fetching of role information is left to the route to keep the model as clean as possible.
       * TODO: once the permissions system is expanded, this should probably become part of a permissions service. It is here
       * now because it simplifies embedding user permission flags into Ember templates, but this benefit will be moot after
       * porting to React. */

      var user = this;
      var siteId = this.get('currentSite.id');
      var role = this.get('currentSiteAccessRef');
      /* TODO: known limitation - only active session users are eligible for "isClientAdmin." User's listed as part of a site's users do not go through that check.
       * This means the label applied to the user will show based on what was saved to the database and not what the actual access level may be. */
      var isClientAdmin = this.get('inheritsClientAdmin');

      if (role && role.get('site') === siteId) {
        var access = 'unassigned';
        if (role.get('access')) {
          access = siteId + ':' + role.get('access');
        }

        user.set('currentSiteAccess', access);

        if (isClientAdmin || access === siteId + ':' + _environment.default.siteAccessEnum.siteAdmin) {
          /* TODO: if logic ever changes and site and client admins differ this will need changed. */
          user.set('isSiteAdmin', true);
          user.set('isLimitedAccess', false);
          user.set('isViewOnly', false);
          user.set('isAccounting', false);
          if (isClientAdmin) {
            user.set('isSuperUser', true);
          } else {
            user.set('isSuperUser', false);
          }
          user.set('permissionLevelSortOrder', 3);
        } else if (access === siteId + ':' + _environment.default.siteAccessEnum.siteLimited) {
          user.set('isSiteAdmin', false);
          user.set('isLimitedAccess', true);
          user.set('isViewOnly', false);
          user.set('isAccounting', false);
          user.set('isSuperUser', false);
          user.set('permissionLevelSortOrder', 1);
        } else if (access === siteId + ':' + _environment.default.siteAccessEnum.siteView) {
          user.set('isSiteAdmin', false);
          user.set('isLimitedAccess', false);
          user.set('isViewOnly', true);
          user.set('isAccounting', false);
          user.set('isSuperUser', false);
          user.set('permissionLevelSortOrder', 2);
        } else if (access === siteId + ':' + _environment.default.siteAccessEnum.siteAccounting) {
          user.set('isSiteAdmin', false);
          user.set('isLimitedAccess', false);
          user.set('isViewOnly', false);
          user.set('isAccounting', true);
          user.set('isSuperUser', false);
          user.set('permissionLevelSortOrder', 4);
        } else {
          user.set('isSiteAdmin', false);
          user.set('isLimitedAccess', false);
          user.set('isViewOnly', false);
          user.set('isSuperUser', false);
          user.set('permissionLevelSortOrder', 0);
        }
      } else if (isClientAdmin) {
        /* Client admin status is determined in the route and assigned to the active user and because it is "god mode" is perpetuated regardless of site. */
        /* If we don't have a site connection then make sure limited defaults are applied. Realistically this shouldn't be hit. */
        /* TODO: we may need to have a step in here to check and see if the site is a member of the user's client sites and set a special role. */
        if (isClientAdmin) {
          /* Client admin status is determined in the route and assigned to the active user and because it is "god mode" is perpetuated regardless of site. */
          user.set('currentSiteAccess', 'client:admin');
          user.set('currentSiteAccessRef', null);
          user.set('isSuperUser', true);
          user.set('isSiteAdmin', true);
          user.set('isLimitedAccess', false);
          user.set('isViewOnly', false);
          user.set('isAccounting', false);
        } else {
          user.set('currentSiteAccess', 'unassigned');
          user.set('currentSiteAccessRef', null);
          user.set('isSuperUser', false);
          user.set('isSiteAdmin', false);
          user.set('isLimitedAccess', true);
          user.set('isViewOnly', false);
          user.set('isAccounting', false);
        }
      }

      if (user.get('isLimitedAccess')) {
        user.set('permissionLevelSortOrder', 1);
      }
      if (user.get('isViewOnly')) {
        user.set('permissionLevelSortOrder', 2);
      }
      if (user.get('isAccounting')) {
        user.set('permissionLevelSortOrder', 5);
      }
      if (user.get('isSiteAdmin')) {
        user.set('permissionLevelSortOrder', 3);
      }
      if (user.get('isOnTeamRoster')) {
        user.set('permissionLevelSortOrder', 4);
      }
    },


    accessLevelDisplay: function accessLevelDisplay() {
      if (this.get('isOnTeamRoster')) {
        return 'Team Roster';
      }
      if (this.get('isSiteAdmin')) {
        return 'Full Access';
      }
      if (this.get('isLimitedAccess')) {
        return 'Limited Access';
      }
      if (this.get('isViewOnly')) {
        return 'View Only';
      }
      if (this.get('isAccounting')) {
        return 'Accounting Access';
      }

      return 'Unassigned';
    }.property('isOnTeamRoster', 'isSiteAdmin', 'isLimitedAccess', 'isViewOnly', 'isAccounting')
  });
});