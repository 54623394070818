define("webui/pods/components/cdt-ui/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/cdt-ui/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("\n    - outer wrapper for change detection tool UI\n    - include any future components (ie floating status widget)\n    - here to share parent scope\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        return morphs;
      },
      statements: [["inline", "floating-panel", [], ["position", "top", "title", "Change Detection Tool", "content", "cdt-menu", "params", ["subexpr", "@mut", [["get", "cdtParams", ["loc", [null, [11, 11], [11, 20]]]]], [], []], "goToFileManager", ["subexpr", "action", ["goToFileManager"], [], ["loc", [null, [12, 20], [12, 46]]]], "isReadOnly", ["subexpr", "@mut", [["get", "isReadOnly", ["loc", [null, [13, 15], [13, 25]]]]], [], []]], ["loc", [null, [7, 0], [14, 2]]]], ["inline", "modal-wrapper", [], ["modalId", "cdtCalculateConfirm", "componentName", "confirm-without-cancel-modal", "mainMessage", "Your visual comparison is now rendering", "subMessage", "This process can take up to several hours. While you wait, you may calculate additional comparisons and use other Site IQ features.", "confirmButtonText", "I Understand", "hideHeader", "true"], ["loc", [null, [16, 0], [22, 21]]]], ["inline", "modal-wrapper", [], ["modalId", "cdtDeleteConfirm", "componentName", "confirm-with-cancel-modal", "mainMessage", "Are you sure you’d like to delete this comparison?", "subMessage", "Doing so will require you to recalculate if you’d like to look at this particular comparison again.", "confirmButtonAction", ["subexpr", "action", ["confirmDelete"], [], ["loc", [null, [29, 22], [29, 46]]]], "confirmButtonText", "Delete this comparison", "hideHeader", "true"], ["loc", [null, [24, 0], [31, 21]]]], ["content", "yield", ["loc", [null, [33, 0], [33, 9]]]]],
      locals: [],
      templates: []
    };
  }());
});