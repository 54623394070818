define('webui/services/settings-manager', ['exports', 'npm:lodash', 'webui/config/environment'], function (exports, _npmLodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    currentSite: null,

    cutFillColorMap: [[-9999, 'rgba(255, 255, 255, 0)'], [-10, '#70000a'], [-9, '#8c000c'], [-8, '#99000d'], [-7, '#a21723'], [-6, '#ab2e39'], [-5, '#b4454f'], [-4, '#be5c65'], [-3, '#c7737b'], [-2, '#d08b91'], [-1, '#e3b9bd'], [0, '#ffffff'], [1, '#8eaace'], [2, '#7899c4'], [3, '#6188ba'], [4, '#4b77b1'], [5, '#3466a7'], [6, '#1e559d'], [7, '#084594'], [8, '#083f87'], [9, '#07397a'], [10, '#06336c'], [9999, 'rgba(255, 255, 255, 0)']],

    slopeMapColorMap: ['#2BDB31', '#F9FA00', '#DB2D2B'],

    slopeMapColorMapDisplaySafe: function slopeMapColorMapDisplaySafe() {
      var result = [];
      var color1 = this.hex2rgb(this.get('slopeMapColorMap')[0]);
      var color2 = this.hex2rgb(this.get('slopeMapColorMap')[1]);
      result = result.concat(this.interpolateColors(color1, color2, 4));
      result.pop();
      color1 = this.hex2rgb(this.get('slopeMapColorMap')[1]);
      color2 = this.hex2rgb(this.get('slopeMapColorMap')[2]);
      result = result.concat(this.interpolateColors(color1, color2, 4));

      result = _npmLodash.default.map(result, function (r, i) {
        r.push(0.6);
        var color = 'rgba(' + r.join(',') + ')';
        return [(i + 1) * 3 + '&deg;', color];
      });

      result[result.length - 1][0] += '+';

      return result;
    }.property('slopeMapColorMap'),

    cutFillColorMapReverse: function cutFillColorMapReverse() {
      /* Slice (copy) or it reverses the main color map used for map layering */
      return this.get('cutFillColorMap').slice().reverse();
    }.property('cutFillColorMap'),

    cutFillColorMapDisplaySafe: function cutFillColorMapDisplaySafe() {
      /* This does not include transparent outer limits for use in CSS and visual displays of the color gradient..
       * The array is reversed so that cut colors are first in the array as is convention across the app. */
      var service = this;
      var result = [];
      this.get('cutFillColorMap').forEach(function (el, i) {
        if (i > 0 && i < service.get('cutFillColorMap.length') - 1) {
          result.push(el);
        }
      });
      return result.reverse();
    }.property('cutFillColorMap'),

    /* Common tracked site preferences - basically, anything we have to access repeatedly */
    loadPreferencesBySite: function loadPreferencesBySite(site) {
      var _this = this;

      this.set('currentSite', site);
      /* There's about a 40% chance that the preferences will not have fully loaded by the time the route calls this function
       * In order to account for this, we check to see if the sitePreferences array has been "fulfilled" and if it hasn't we set
       * an observer to call next steps as soon as it has.
       */
      if (site.get('sitePreferences.isFulfilled') === true) {
        this.registerCommonSitePreferences(site.get('sitePreferences'));
      } else {
        site.addObserver('sitePreferences.isFulfilled', function () {
          if (site.get('sitePreferences.isFulfilled') === true) {
            _this.registerCommonSitePreferences(site.get('sitePreferences'));
          }
        });
      }
    },
    registerCommonSitePreferences: function registerCommonSitePreferences(sitePreferences) {
      var p = _environment.default.preferences;
      if (!$.isEmptyObject(sitePreferences)) {
        this.set(p.site.cutColor.name, this.getSitePreferenceValue(p.site.cutColor.name, p.site.cutColor.defaultValue));
        this.set(p.site.fillColor.name, this.getSitePreferenceValue(p.site.fillColor.name, p.site.fillColor.defaultValue));
        this.processCutFillColor();

        this.set(p.cdt.showDifference.name, this.getSitePreferenceValue(p.cdt.showDifference.name, p.cdt.showDifference.defaultValue));
        this.set(p.cdt.showCurrentElevation.name, this.getSitePreferenceValue(p.cdt.showCurrentElevation.name, p.cdt.showCurrentElevation.defaultValue));
        this.set(p.cdt.showComparisonSurface.name, this.getSitePreferenceValue(p.cdt.showComparisonSurface.name, p.cdt.showComparisonSurface.defaultValue));
        this.set(p.cdt.gridSpacing.name, this.getSitePreferenceValue(p.cdt.gridSpacing.name, p.cdt.gridSpacing.defaultValue));
      }
    },
    getSite: function getSite() {
      return this.get('currentSite');
    },
    getSitePreference: function getSitePreference(name, defaultValue) {
      var store = this.get('store');
      var found = null;

      if (this.get('currentSite.sitePreferences')) {
        found = this.get('currentSite.sitePreferences').find(function (el) {
          return el.get('name') === name;
        });
      }

      if (!found && store && typeof defaultValue !== 'undefined' && defaultValue !== null) {
        /* Note, this isn't saved yet - we just create an instance and leave it up to the component to determine if it should be saved to the DB. */
        found = store.createRecord('site-preference', {
          site: this.getSite(),
          name: name,
          value: defaultValue
        });
      }
      return found;
    },
    getSitePreferenceValue: function getSitePreferenceValue(name, defaultValue) {
      var found = null;

      if (this.get('currentSite.sitePreferences')) {
        found = this.get('currentSite.sitePreferences').find(function (el) {
          return el.get('name') === name;
        });
      }

      if (found && found.get('value') !== null && typeof found.get('value') !== 'undefined') {
        return found.get('value');
      }if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
        return defaultValue;
      }

      return null;
    },
    setSitePreference: function setSitePreference(name, setValue) {
      var store = this.get('store');
      var isDirty = false;

      var sitePreference = this.getSitePreference(name);
      if (!sitePreference) {
        var newPref = store.createRecord('site-preference', {
          site: this.getSite(),
          name: name,
          value: setValue
        });
        newPref.save();
        isDirty = true;
      } else if (sitePreference.get('value') !== setValue) {
        /* if the value changed update the database record */
        sitePreference.set('value', setValue);
        sitePreference.save();
        isDirty = true;
      }

      if (isDirty) {
        switch (name) {
          case _environment.default.preferences.site.fillColor.name:
          case _environment.default.preferences.site.cutColor.name:
            /* if either cut or fill color changes, redraw the color map  */
            this.processCutFillColor();
            break;
          default:
            break;
        }
      }

      this.set(name, setValue);
    },
    processCutFillColor: function processCutFillColor() {
      var cutColor = this.getSitePreferenceValue(_environment.default.preferences.site.cutColor.name, _environment.default.preferences.site.cutColor.defaultValue);
      var fillColor = this.getSitePreferenceValue(_environment.default.preferences.site.fillColor.name, _environment.default.preferences.site.fillColor.defaultValue);

      var cutRgb = this.hex2rgb(cutColor);
      var fillRgb = this.hex2rgb(fillColor);
      var colorSteps = [];
      var mark = -10;

      var white = { red: 255, green: 255, blue: 255 };
      var fillInterpolate = this.interpolateColors(fillRgb, white, 11);
      var cutInterpolate = this.interpolateColors(white, cutRgb, 11);

      colorSteps.push([-9999, 'rgba(255, 255, 255, 0)']);

      fillInterpolate.forEach(function (el) {
        colorSteps.push([mark, 'rgba(' + el[0] + ',' + el[1] + ',' + el[2] + ',1)']);
        mark += 1;
      });

      cutInterpolate.forEach(function (el, i) {
        if (i > 0) {
          /* skip white since it's already accounted for in fill colors */
          colorSteps.push([mark, 'rgba(' + el[0] + ',' + el[1] + ',' + el[2] + ',1)']);
          mark += 1;
        }
      });

      colorSteps.push([9999, 'rgba(255, 255, 255, 0)']);

      this.set('cutFillColorMap', colorSteps);
    },
    hex2rgb: function hex2rgb(color) {
      /* TODO - this function could be more fool-proof (validate incoming hex / accept hex shorthand colors [#fff]) */
      var hexColor = color;var r = void 0;var g = void 0;var b = void 0;
      if (hexColor.charAt(0) === '#') {
        hexColor = hexColor.substr(1);
      }

      r = '' + hexColor.charAt(0) + hexColor.charAt(1);
      g = '' + hexColor.charAt(2) + hexColor.charAt(3);
      b = '' + hexColor.charAt(4) + hexColor.charAt(5);

      r = parseInt(r, 16);
      g = parseInt(g, 16);
      b = parseInt(b, 16);

      return { red: r, green: g, blue: b };
    },

    // Returns a single rgb color interpolation between given rgb color
    // based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
    interpolateColor: function interpolateColor(color1, color2) {
      var factor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.5;

      var result = color1.slice();
      for (var i = 0; i < 3; i += 1) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
      }
      return result;
    },

    // Based on https://jsfiddle.net/002v98LL/
    interpolateColors: function interpolateColors(color1, color2, steps) {
      var stepFactor = 1 / (steps - 1);
      var interpolatedColorArray = [];

      for (var i = 0; i < steps; i += 1) {
        interpolatedColorArray.push(this.interpolateColor([color1.red, color1.green, color1.blue], [color2.red, color2.green, color2.blue], stepFactor * i));
      }

      return interpolatedColorArray;
    }
  });
});