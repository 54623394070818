define('webui/pods/components/field-panel-layers/component', ['exports', 'ember-cli-segment/mixin', 'webui/mixins/annotation-layer'], function (exports, _mixin, _annotationLayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_annotationLayer.default, _mixin.default, {
    mapData: Ember.inject.service('map-data'),
    settingsManager: Ember.inject.service('settings-manager'),
    store: Ember.inject.service('store'),
    closePanel: 'closePanel',
    tiledMaps: 'tiledMaps',
    activeTab: 'general',
    satelliteChecked: false,
    threeDChecked: false,
    gcpsChecked: false,

    init: function init() {
      var _this = this;

      this._super();
      this.initializeCurrentTiledMap();
      var terrainLayer = this.get('tiledMaps.3d-terrain.firstObject');
      this.set('has3DTerrain', terrainLayer && terrainLayer.get('status') === 'succeeded');

      var siteAnnotations = this.get('site.annotationLayers').filter(function (annotationLayer) {
        return !annotationLayer.get('flight');
      });
      var flightAnnotations = this.get('site.annotationLayers').filter(function (annotationLayer) {
        return annotationLayer.get('flight.id') === _this.get('flight.id');
      });

      this.set('annotationLayers', [].concat(_toConsumableArray(siteAnnotations), _toConsumableArray(flightAnnotations)));
      this.set('hasQualityReport', this.get('qualityReport'));
    },
    initializeCurrentTiledMap: function initializeCurrentTiledMap() {
      var _this2 = this;

      var map = this.get('mapData.mapInstance');
      var store = this.get('store');

      map.getStyle().layers.forEach(function (layer) {
        var type = layer.id.substr(0, 8);
        var id = layer.id.substr(9, 36);

        if (type === 'cut-fill' && type + '-' + id === layer.id) {
          _this2.set('currentTiledMap', store.peekRecord('tiled-map', id));
        }
      });
    },
    hideCurrentCDT: function hideCurrentCDT() {
      var map = this.get('mapData.mapInstance');
      var currentTiledMap = this.get('currentTiledMap');

      if (currentTiledMap) {
        currentTiledMap.set('checked', false);
      }

      map.getStyle().layers.forEach(function (layer) {
        if (layer.id.substr(0, 8) === 'cut-fill') {
          map.removeLayer(layer.id);
          map.removeSource(layer.id);
        }
      });
    },
    displayCDT: function displayCDT(tiledMap) {
      var component = this;
      var settingsManager = this.get('settingsManager');
      var changeDetection = tiledMap.get('changeDetection');
      var mapData = this.get('mapData');
      var map = mapData.mapInstance;
      var labelGeojsons = changeDetection.get('labelGeojsons');
      var labelLevels = {
        25: {
          minzoom: 19, maxzoom: 24, 'text-size': 14, 'text-allow-overlap': true
        },
        100: {
          minzoom: 17, maxzoom: 19, 'text-size': 14, 'text-allow-overlap': true
        },
        250: {
          minzoom: 13, maxzoom: 17, 'text-size': { stops: [[13, 8], [14, 9], [15, 10], [16, 11], [17, 12]] }, 'text-allow-overlap': true
        }
      };

      var id = 'cut-fill-' + tiledMap.id;
      var paint = { 'fill-color': { property: 'VALUE', stops: settingsManager.get('cutFillColorMap') }, 'fill-opacity': 0.7 };
      map.addSource(id, { type: 'vector', tiles: [tiledMap.get('displayUrl') + '{z}/{x}/{y}.pbf'], maxzoom: 20 });
      map.addLayer({
        id: id, source: id, 'source-layer': 'change-detection', type: 'fill', paint: paint, filter: ['has', 'VALUE']
      }, mapData.getLayerThatNewLayerShouldBeInsertedBefore());

      // Display the CDT cut/fill/elevation labels if any were generated
      if (labelGeojsons) {
        Object.keys(labelLevels).forEach(function (labelKey) {
          if (Object.prototype.hasOwnProperty.call(labelGeojsons, labelKey)) {
            var labelId = 'cut-fill-' + labelKey + '-labels-' + tiledMap.id;
            var labelDict = labelLevels[labelKey];
            var layout = {
              'symbol-placement': 'point',
              'text-field': '{diff}\n{uav1}\n{uav2}',
              'text-size': labelDict['text-size'],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-allow-overlap': labelDict['text-allow-overlap'],
              'text-padding': 1
            };
            var labelPaint = { 'text-color': '#000', 'text-halo-color': '#fff', 'text-halo-width': 1 };
            map.addSource(labelId, { type: 'geojson', data: '' + tiledMap.get('urlPrefix') + labelGeojsons[labelKey], maxzoom: 20 });
            map.addLayer({
              id: labelId, source: labelId, type: 'symbol', layout: layout, minzoom: labelDict.minzoom, maxzoom: labelDict.maxzoom, paint: labelPaint
            }, mapData.getLayerThatNewLayerShouldBeInsertedBefore());
          }
        });
      }

      component.trackEvent('Viewing Field Change Detection', {
        changeDetectionId: component.get('currentChangeDetection.id'),
        comparisonFlightId: component.get('currentChangeDetection.comparisonFlight.id'),
        comparisonFlightExecuteAt: component.get('currentChangeDetection.comparisonFlight.executeAt'),
        comparisonBlueprintId: component.get('currentChangeDetection.comparisonBlueprint.id'),
        flightId: component.get('flight.id'),
        executeAt: component.get('flight.executeAt'),
        siteId: component.get('site.id'),
        site: component.get('site.name')
      });

      component.set('currentChangeDetection', changeDetection);
    },
    showSiteGrade: function showSiteGrade(tiledMap) {
      var mapData = this.get('mapData');
      var map = this.get('mapData.mapInstance');
      var id = 'blueprint-layer-' + tiledMap.get('id');

      var prefix = tiledMap.get('urlPrefix');
      var fileUrl = tiledMap.get('fileUrl');

      if (prefix && prefix.slice(-1) !== '/' && fileUrl.charAt(0) !== '/') {
        prefix += '/';
      }
      if (fileUrl && fileUrl.slice(-1) !== '/') {
        fileUrl += '/';
      }

      var sourceData = {
        type: 'raster', tiles: ['' + prefix + fileUrl + '{z}/{x}/{y}.png'], tileSize: 256, maxzoom: tiledMap.get('maxZoomLevel')
      };
      var layerData = {
        id: id, source: id, type: 'raster', minzoom: 0, maxzoom: mapData.get('maxZoomLevel'), layout: { visibility: 'visible' }
      };
      map.addSource(id, sourceData);
      map.addLayer(layerData, mapData.getLayerThatNewLayerShouldBeInsertedBefore());

      this.trackEvent('Field Site Grade Overlay View', { siteId: this.get('site.id'), siteName: this.get('site.name'), blueprintId: tiledMap.get('id') });
    },
    hideSiteGrade: function hideSiteGrade(tileMap) {
      var map = this.get('mapData.mapInstance');
      var id = 'blueprint-layer-' + tileMap.get('id');

      map.removeLayer(id);
      map.removeSource(id);
    },
    showLineWork: function showLineWork(tiledMap) {
      var mapData = this.get('mapData');
      var map = mapData.mapInstance;
      var source = 'overlay-blueprint-layer-' + tiledMap.get('id');
      var sourceLayer = 'overlay-blueprint';

      map.addSource(source, { type: 'vector', tiles: [tiledMap.get('displayUrl') + '{z}/{x}/{y}.pbf'], maxzoom: 20 });

      var fillLayerId = source + '-fill';
      var fillLayerPaint = { 'fill-color': { property: 'fill-color', type: 'identity' } };
      map.addLayer({
        id: fillLayerId, source: source, 'source-layer': sourceLayer, type: 'fill', paint: fillLayerPaint, filter: ['==', 'type', 'polygon']
      }, mapData.getLayerThatNewLayerShouldBeInsertedBefore());

      var lineLayerId = source + '-line';
      var lineLayerPaint = { 'line-color': { property: 'line-color', type: 'identity' } };
      map.addLayer({
        id: lineLayerId, source: source, 'source-layer': sourceLayer, type: 'line', paint: lineLayerPaint, filter: ['==', 'type', 'line']
      }, fillLayerId);

      var labelLayerId = source + '-label';
      var labelLayerLayout = {
        'text-field': '{text-field}', 'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'], 'text-size': 14, 'text-anchor': 'top', 'text-allow-overlap': false
      };
      map.addLayer({
        id: labelLayerId, source: source, 'source-layer': sourceLayer, type: 'symbol', layout: labelLayerLayout, filter: ['==', 'type', 'label']
      }, lineLayerId);

      this.trackEvent('Field Blueprint Overlay View', { siteId: this.get('site.id'), siteName: this.get('site.name'), blueprintId: tiledMap.get('id') });
    },
    hideLineWork: function hideLineWork(tiledMap) {
      var map = this.get('mapData.mapInstance');
      var id = 'overlay-blueprint-layer-' + tiledMap.get('id');

      map.removeLayer(id + '-fill');
      map.removeLayer(id + '-line');
      map.removeLayer(id + '-label');
      map.removeSource(id);
    },


    actions: {
      toggleAnnotationLayer: function toggleAnnotationLayer(annotationLayer) {
        this.set('annotationLayer', annotationLayer);
        this.set('label-layer-id', 'annotation-labels-' + this.get('annotationLayer.id'));
        this.set('selectedIds', []);

        if (!annotationLayer.get('isDisplayed')) {
          this.turnOnLayer();
          this.trackEvent('Field Display Annotation Layer', { siteId: this.get('site.id'), siteName: this.get('site.name') });
        } else {
          this.turnOffLayer();
        }
      },
      toggleSatellite: function toggleSatellite() {
        this.toggleProperty('satelliteChecked');
        return this.get('mapData').toggleLayerVisibility('id-satellite-layer');
      },
      toggle3D: function toggle3D() {
        this.toggleProperty('threeDChecked');
        if (!this.get('has3DTerrain')) {
          return;
        }

        var mapData = this.get('mapData');
        var areWeTurning3DOn = !mapData.get('currentActiveLayers.3d-terrain-layer');
        this.set('isIn3DMode', areWeTurning3DOn);

        var newPitch = areWeTurning3DOn ? 5 : 0;
        var currentZoom = mapData.mapInstance.getZoom();
        var amountToZoomOut = currentZoom > 18 ? 18 : currentZoom;
        var newZoom = areWeTurning3DOn ? amountToZoomOut : currentZoom;
        mapData.mapInstance.setZoom(newZoom);
        mapData.mapInstance.setPitch(newPitch);
        mapData.toggle3DTerrain();
        setTimeout(function () {
          return mapData.mapInstance.easeTo({ zoom: mapData.mapInstance.getZoom() - 0.01, duration: 10 });
        }, 100);
      },
      toggleGcps: function toggleGcps() {
        this.toggleProperty('gcpsChecked');
        if (!this.get('hasQualityReport')) {
          return;
        }

        var areWeTurningGcpsOn = this.get('gcpsChecked');
        var mapData = this.get('mapData');
        var map = mapData.mapInstance;
        var report = this.get('qualityReport');

        if (areWeTurningGcpsOn) {
          if (!map.getLayer(report.id)) {
            var geo = report.get('gcpPoints');
            geo.features.forEach(function (o) {
              /* Assemble the title for the marker text */
              o.properties.title = o.properties.name;
            });
            map.addLayer({
              id: report.id,
              type: 'symbol',
              source: {
                type: 'geojson',
                data: geo
              },
              layout: {
                'icon-image': 'riflescope',
                'icon-size': 1,
                'icon-optional': false,
                'text-field': '{title}',
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [1, 0],
                'text-anchor': 'left',
                'text-justify': 'left',
                'text-allow-overlap': true,
                'icon-allow-overlap': true,
                visibility: 'visible'
              },
              paint: {
                'text-color': '#fff',
                'text-halo-color': '#000',
                'text-halo-width': 1,
                'text-halo-blur': 2
              }
            });
          } else {
            map.setLayoutProperty(report.id, 'visibility', 'visible');
          }
        } else {
          map.setLayoutProperty(report.id, 'visibility', 'none');
        }
      },
      toggleLayer: function toggleLayer(type, tiledMap) {
        var isLayerOn = tiledMap.get('checked');
        var mapData = this.get('mapData');

        if (isLayerOn) {
          tiledMap.set('checked', false);
        } else {
          tiledMap.set('checked', true);
        }

        if (type === 'general') {
          mapData.toggleLayerVisibility(tiledMap.get('type'));

          if (mapData.layerVisible(tiledMap.get('type') && tiledMap.get('type') !== 'orthomosaic')) {
            this.trackEvent('Field Overlay View (' + tiledMap.get('type') + ')', { siteId: this.get('site.id'), siteName: this.get('site.name'), flightId: this.get('flight.id') });
          }
        }

        if (type === 'surface') {
          if (isLayerOn) {
            this.hideSiteGrade(tiledMap);
          } else {
            this.showSiteGrade(tiledMap);
          }
        }

        if (type === 'linework') {
          if (isLayerOn) {
            this.hideLineWork(tiledMap);
          } else {
            this.showLineWork(tiledMap);
          }
        }

        if (type === 'cdt') {
          this.hideCurrentCDT();
          this.set('currentTiledMap', null);
          if (!isLayerOn) {
            this.displayCDT(tiledMap);
            this.set('currentTiledMap', tiledMap);
          }
        }
      },
      toggleHiddenInfo: function toggleHiddenInfo() {
        var infoDisplayState = document.getElementById('hidden-info').style.display;
        document.getElementById('hidden-info').style.display = infoDisplayState === 'flex' ? 'none' : 'flex';
        var buttonDisplayState = document.getElementById('info-button').style.display || 'flex';
        document.getElementById('info-button').style.display = buttonDisplayState === 'flex' ? 'none' : 'flex';
      },
      setActiveTab: function setActiveTab(activeTab) {
        this.set('activeTab', activeTab);
      },
      closePanel: function closePanel() {
        this.sendAction('closePanel');
      }
    }
  });
});