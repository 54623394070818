define('webui/pods/components/work-activity-display/component', ['exports', 'webui/mixins/numerical-conversion', 'ember-cli-segment/mixin'], function (exports, _numericalConversion, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var componentName = 'measurements';
  var PolygonMeasurementTypes = ['2DArea', '3DArea', 'VolumeCalc', '3DQuantity'];
  var GEOAPI = window.turf;

  exports.default = Ember.Component.extend(_numericalConversion.default, _mixin.default, {
    mapData: Ember.inject.service('mapData'),
    store: Ember.inject.service('store'),
    feedback: Ember.inject.service('user-feedback'),
    elevationLayerManager: Ember.inject.service('elevation-layer-manager'),

    closeNewActivityAndScrollToPrexistingType: 'closeNewActivityAndScrollToPrexistingType',
    cancelNewWorkActivity: 'cancelNewWorkActivity',
    deleteWorkActivity: 'deleteWorkActivity',
    turnOffDrawMode: 'turnOffDrawMode',
    resetDrawMode: 'resetDrawMode',
    handleSave: 'handleSave',
    handleCancel: 'handleCancel',
    redraw: 'redraw',

    selectedElevation: Ember.observer('elevationLayerManager.selectedElevation', function updateSelectedElevation() {
      if (!this.get('workActivity.isDrawing')) {
        this.get('workActivity').set('baseplaneElevation', this.get('elevationLayerManager.selectedElevation'));
      }
    }),

    validate: function validate() {
      var workActivity = this.get('workActivity');
      var workActivityType = workActivity.get('activityType');
      var workActivityMeasurementType = workActivity.get('measurementType');
      var preExistingMeasurementType = this.get('measurementTypesForActivityType')[workActivityType];
      var workActivityFlightId = workActivity.get('flight.id');
      var isSiteLevel = workActivity.get('isSiteLevel');

      if (!workActivityType) {
        return 'Activity Type is required.';
      }

      var workActivitiesThatMatchThisCurrentFlightAndType = this.get('store').peekAll('work-activity').filter(function (activity) {
        return activity.get('activityType') === workActivityType && activity.get('flight.id') === workActivityFlightId;
      });

      if (!isSiteLevel && workActivitiesThatMatchThisCurrentFlightAndType.length > 1) {
        return 'There can only be one of a given activity type per flight.';
      }

      if (!isSiteLevel && preExistingMeasurementType && preExistingMeasurementType !== workActivityMeasurementType) {
        return 'Activity type of ' + workActivityType + ' is already associated to measurement type ' + preExistingMeasurementType;
      }

      return null;
    },
    save: function save() {
      var _this = this;

      var context = this;
      var feedback = this.get('feedback');
      var mapData = this.get('mapData');
      var store = this.get('store');
      var workActivity = this.get('workActivity');
      var isCreate = workActivity.id === null;
      var measurementType = workActivity.get('measurementType');
      var featureType = PolygonMeasurementTypes.indexOf(measurementType) !== -1 ? 'Polygon' : 'LineString';
      workActivity.set('featureType', featureType);
      workActivity.set('featureGeometry', workActivity.get('featureGeometry').filter(function (geo) {
        return geo;
      }));

      if (measurementType === '2DLine') {
        var result = workActivity.get('featureGeometry').map(function (currentValue) {
          return { Distance: mapData.calculateLength(GEOAPI.lineString(currentValue.map(mapData.sanitizePoints))) };
        });

        workActivity.set('resultType', 'Distance');
        workActivity.set('result', result);
        workActivity.set('surface1Type', 'None');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', null);
        workActivity.set('surface2', null);
        workActivity.set('status', 'succeeded');
      }

      if (measurementType === '3DLine') {
        workActivity.set('resultType', 'Surface Distance');
        workActivity.set('surface1Type', 'Product');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', this.get('workActivity.flight.dsmId'));
        workActivity.set('surface2', null);
      }

      if (measurementType === '2DArea') {
        var _result = workActivity.get('featureGeometry').map(function (currentValue) {
          return { Area: _this.squareMetersToSquareFeet(mapData.calculateAreaFromGeoJSON(GEOAPI.polygon([currentValue.map(mapData.sanitizePoints)]))) };
        });

        workActivity.set('resultType', 'Area');
        workActivity.set('result', _result);
        workActivity.set('surface1Type', 'None');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', null);
        workActivity.set('surface2', null);
        workActivity.set('status', 'succeeded');
      }

      if (measurementType === '3DArea') {
        workActivity.set('resultType', 'Surface Area');
        workActivity.set('surface1Type', 'Product');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', this.get('workActivity.flight.dsmId'));
        workActivity.set('surface2', null);
      }

      if (measurementType === 'VolumeCalc') {
        workActivity.set('surface1Type', 'Product');
        workActivity.set('surface2Type', 'None');
        workActivity.set('surface1', this.get('workActivity.flight.dsmId'));
        workActivity.set('surface2', null);
        if (!workActivity.get('baseplaneElevation')) {
          workActivity.set('baseplaneElevation', 0);
        }
      }

      if (measurementType === '3DQuantity') {
        workActivity.set('surface1Type', store.peekRecord('blueprint', workActivity.get('surface1')) ? 'Blueprint' : 'Product');
        workActivity.set('surface2Type', store.peekRecord('blueprint', workActivity.get('surface2')) ? 'Blueprint' : 'Product');
      }

      workActivity.save().then(function (savedRecord) {
        workActivity.set('editMode');
        workActivity.set('typeIsNew');
        io.socket.get('/v1/workActivities/' + savedRecord.id);
        context.sendAction('handleSave', savedRecord.id);
      }).catch(function (err) {
        feedback.reportError(err.errors[0]);
        Ember.run.next(function () {
          context.sendAction('redraw');
          if (isCreate) {
            // workActivity.destroy();
          } else {
            workActivity.rollbackAttributes();
          }
        });
      });

      this.trackEvent('Measurement Created', { flight: this.get('workActivity.flight.id'), measurementType: this.get('workActivity.displayMeasurementType') });
    },
    displayError: function displayError(error) {
      this.get('feedback').reportError(error);
    },


    actions: {
      saveWorkActivity: function saveWorkActivity() {
        if (this.validate()) {
          this.displayError(this.validate());
        } else {
          this.save();
          this.get('elevationLayerManager').disable(componentName);
        }
      },
      cancelChanges: function cancelChanges() {
        this.sendAction('handleCancel', this.get('workActivity.id'));
        this.get('elevationLayerManager').disable(componentName);
      },
      removeWorkActivity: function removeWorkActivity() {
        this.sendAction('deleteWorkActivity', this.get('workActivity.id'));
        this.get('elevationLayerManager').disable(componentName);
      },
      changeActivityType: function changeActivityType(target) {
        var context = this;
        var activityType = target.value;
        var newTypeSelected = !activityType;
        var workActivity = this.get('workActivity');
        var isDrawing = workActivity.get('isDrawing');
        var measurementTypes = this.get('measurementTypesForActivityType');
        var oldMeasurementType = workActivity.get('measurementType');
        var newMeasurementType = newTypeSelected ? oldMeasurementType : measurementTypes[activityType];
        var selectedActivityType = this.get('activityTypesForSelect').filter(function (type) {
          return type.activityType === activityType;
        })[0];

        if (selectedActivityType && selectedActivityType.activityTypeExistsForFlight) {
          return context.sendAction('closeNewActivityAndScrollToPrexistingType', activityType);
        }

        workActivity.set('typeIsNew', newTypeSelected);
        workActivity.set('activityType', activityType);
        workActivity.set('measurementType', newMeasurementType);

        if (this.get('copyRegionData')[activityType] && !workActivity.get('regionsHaveBeenCopied')) {
          workActivity.set('showCopyButton', true);
          workActivity.set('mostRecentFlightDisplay', this.get('copyRegionData')[activityType].mostRecentFlightDisplay);
          workActivity.set('mostRecentRegions', this.get('copyRegionData')[activityType].mostRecentRegions);
          workActivity.set('mostRecentStartingSurface', this.get('copyRegionData')[activityType].mostRecentStartingSurface);
          workActivity.set('mostRecentEndingSurface', this.get('copyRegionData')[activityType].mostRecentEndingSurface);
          workActivity.set('mostRecentBaseplanType', this.get('copyRegionData')[activityType].mostRecentBaseplanType);
          workActivity.set('mostRecentBaseplaneElevation', this.get('copyRegionData')[activityType].mostRecentBaseplaneElevation);
        } else {
          workActivity.set('showCopyButton', false);
        }

        if (oldMeasurementType !== newMeasurementType && !newTypeSelected) {
          workActivity.set('featureGeometry', []);
          Ember.run.next(function () {
            context.sendAction('redraw');
            if (isDrawing) {
              context.sendAction('resetDrawMode', workActivity);
            }
          });
        }

        return null;
      },
      changeBaseplaneType: function changeBaseplaneType(target) {
        var baseplaneType = target.value;

        this.get('workActivity').set('baseplaneType', target.value);
        if (baseplaneType === 'custom') {
          this.get('elevationLayerManager').enable(componentName);
        } else {
          this.get('elevationLayerManager').disable(componentName);
        }
      },
      copyRegions: function copyRegions() {
        var workActivity = this.get('workActivity');
        var currentFeatureGeometry = workActivity.get('featureGeometry');
        var isDrawing = this.get('workActivity.isDrawing');
        if (!workActivity.get('regionsHaveBeenCopied')) {
          workActivity.get('mostRecentRegions').forEach(function (region) {
            currentFeatureGeometry.push(region);
          });

          workActivity.set('featureGeometry', currentFeatureGeometry);
          workActivity.set('surface1', workActivity.get('mostRecentStartingSurface'));
          workActivity.set('surface2', workActivity.get('mostRecentEndingSurface'));
          workActivity.set('baseplaneType', workActivity.get('mostRecentBaseplanType'));
          workActivity.set('baseplaneElevation', workActivity.get('mostRecentBaseplaneElevation'));
          workActivity.set('showCopyButton', false);
          this.sendAction('turnOffDrawMode');
          this.sendAction('redraw', 'new-region');
          if (isDrawing) {
            this.sendAction('resetDrawMode', workActivity);
          }
          workActivity.set('regionsHaveBeenCopied', true);
        }
      },
      changeMeasurementType: function changeMeasurementType(newMeasurementType) {
        var context = this;
        var workActivity = this.get('workActivity');
        var isDrawing = workActivity.get('isDrawing');
        var oldMeasurementType = workActivity.get('measurementType');
        workActivity.set('measurementType', newMeasurementType);
        if (oldMeasurementType !== newMeasurementType) {
          workActivity.set('featureGeometry', []);
          Ember.run.next(function () {
            context.sendAction('redraw');
            if (isDrawing) {
              context.sendAction('resetDrawMode', workActivity);
            }
          });
        }
      },
      handleSelectKeypress: function handleSelectKeypress() /* event */{
        // not sure if we need this yet
      },
      resetDrawMode: function resetDrawMode() {
        if (this.get('workActivity.isDrawing')) {
          this.sendAction('turnOffDrawMode');
        } else {
          this.sendAction('resetDrawMode', this.get('workActivity'));
        }
      },
      editMode: function editMode() {
        this.get('workActivity').set('editMode', !this.get('workActivity.editMode'));
        if (this.get('workActivity.editMode') && this.get('workActivity').get('baseplaneType') === 'custom') {
          this.get('elevationLayerManager').enable(componentName);
        }
      }
    }
  });
});