define('webui/mixins/simple-region-names', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getSimplifiedRegionNames: function getSimplifiedRegionNames(flightId) {
      var indexBy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';

      var simplifiedRegionNames = {};
      var flight = this.get('store').peekRecord('flight', flightId);
      var regionsSortedFromLeftToRight = flight.get('regions').filter(function (region) {
        return region.display;
      }).toArray().sort(function (a, b) {
        return a.get('mapboxPointFeature.geometry.coordinates.0') - b.get('mapboxPointFeature.geometry.coordinates.0');
      });
      regionsSortedFromLeftToRight.forEach(function (region, index) {
        var key = region.get(indexBy);
        simplifiedRegionNames[key] = index + 1;
      });

      return simplifiedRegionNames;
    }
  });
});