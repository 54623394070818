define("webui/pods/components/status-dropdown/status-panel-file-manager-upload/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 10
                },
                "end": {
                  "line": 8,
                  "column": 10
                }
              },
              "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-ok");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 10
                },
                "end": {
                  "line": 11,
                  "column": 10
                }
              },
              "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-warning-sign");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              return morphs;
            },
            statements: [["attribute", "title", ["concat", [["get", "queue.message.message", ["loc", [null, [10, 68], [10, 89]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 10
                },
                "end": {
                  "line": 14,
                  "column": 10
                }
              },
              "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "glyphicon glyphicon-repeat spinner");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 24
                  },
                  "end": {
                    "line": 21,
                    "column": 72
                  }
                },
                "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Flight Photos");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 72
                    },
                    "end": {
                      "line": 21,
                      "column": 106
                    }
                  },
                  "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("PPKs");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 21,
                        "column": 106
                      },
                      "end": {
                        "line": 21,
                        "column": 157
                      }
                    },
                    "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Base Station Logs");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.0",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 21,
                          "column": 157
                        },
                        "end": {
                          "line": 21,
                          "column": 190
                        }
                      },
                      "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("GCP");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  var child0 = function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.0",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 21,
                            "column": 190
                          },
                          "end": {
                            "line": 21,
                            "column": 235
                          }
                        },
                        "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("GPS Calibration");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  }();
                  var child1 = function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.5.0",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 21,
                            "column": 235
                          },
                          "end": {
                            "line": 21,
                            "column": 254
                          }
                        },
                        "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("Other Files");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  }();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.0",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 21,
                          "column": 190
                        },
                        "end": {
                          "line": 21,
                          "column": 254
                        }
                      },
                      "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "if", [["subexpr", "eq", [["get", "segment", ["loc", [null, [21, 204], [21, 211]]]], "gps"], [], ["loc", [null, [21, 200], [21, 218]]]]], [], 0, 1, ["loc", [null, [21, 190], [21, 254]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                }();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 21,
                        "column": 157
                      },
                      "end": {
                        "line": 21,
                        "column": 254
                      }
                    },
                    "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "eq", [["get", "segment", ["loc", [null, [21, 171], [21, 178]]]], "gcp"], [], ["loc", [null, [21, 167], [21, 185]]]]], [], 0, 1, ["loc", [null, [21, 157], [21, 254]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 106
                    },
                    "end": {
                      "line": 21,
                      "column": 254
                    }
                  },
                  "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "segment", ["loc", [null, [21, 120], [21, 127]]]], "ppkLogs"], [], ["loc", [null, [21, 116], [21, 138]]]]], [], 0, 1, ["loc", [null, [21, 106], [21, 254]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 72
                  },
                  "end": {
                    "line": 21,
                    "column": 254
                  }
                },
                "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "segment", ["loc", [null, [21, 86], [21, 93]]]], "ppk"], [], ["loc", [null, [21, 82], [21, 100]]]]], [], 0, 1, ["loc", [null, [21, 72], [21, 254]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 10
                },
                "end": {
                  "line": 22,
                  "column": 10
                }
              },
              "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createElement("small");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("/");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element0, 0, 0);
              morphs[1] = dom.createMorphAt(element0, 2, 2);
              morphs[2] = dom.createMorphAt(element0, 4, 4);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "segment", ["loc", [null, [21, 34], [21, 41]]]], "flightPhotos"], [], ["loc", [null, [21, 30], [21, 57]]]]], [], 0, 1, ["loc", [null, [21, 24], [21, 261]]]], ["inline", "get", [["subexpr", "get", [["get", "queue.segments", ["loc", [null, [21, 273], [21, 287]]]], ["get", "segment", ["loc", [null, [21, 288], [21, 295]]]]], [], ["loc", [null, [21, 268], [21, 296]]]], "fileProcessedCount"], [], ["loc", [null, [21, 262], [21, 319]]]], ["inline", "get", [["subexpr", "get", [["get", "queue.segments", ["loc", [null, [21, 331], [21, 345]]]], ["get", "segment", ["loc", [null, [21, 346], [21, 353]]]]], [], ["loc", [null, [21, 326], [21, 354]]]], "fileCount"], [], ["loc", [null, [21, 320], [21, 368]]]]],
            locals: ["segment"],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 26,
                "column": 2
              }
            },
            "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "notification-list-item");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "notification-container");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "notification-message");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createElement("strong");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(element3, 1, 1);
            morphs[1] = dom.createMorphAt(element3, 2, 2);
            morphs[2] = dom.createMorphAt(element3, 3, 3);
            morphs[3] = dom.createMorphAt(dom.childAt(element4, [1, 0]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
            morphs[5] = dom.createMorphAt(element4, 5, 5);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "queue.status", ["loc", [null, [6, 20], [6, 32]]]], "completed"], [], ["loc", [null, [6, 16], [6, 45]]]]], [], 0, null, ["loc", [null, [6, 10], [8, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "queue.status", ["loc", [null, [9, 20], [9, 32]]]], "failed"], [], ["loc", [null, [9, 16], [9, 42]]]]], [], 1, null, ["loc", [null, [9, 10], [11, 17]]]], ["block", "if", [["subexpr", "eq", [["get", "queue.status", ["loc", [null, [12, 20], [12, 32]]]], "pending"], [], ["loc", [null, [12, 16], [12, 43]]]]], [], 2, null, ["loc", [null, [12, 10], [14, 17]]]], ["content", "queue.flight.site.name", ["loc", [null, [17, 23], [17, 49]]]], ["content", "queue.flight.displayFlightTime", ["loc", [null, [18, 15], [18, 49]]]], ["block", "each", [["get", "queue.observableSegments", ["loc", [null, [19, 18], [19, 42]]]]], [], 3, null, ["loc", [null, [19, 10], [22, 19]]]]],
          locals: ["queue"],
          templates: [child0, child1, child2, child3]
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "queues", ["loc", [null, [2, 10], [2, 16]]]]], [], 0, null, ["loc", [null, [2, 2], [26, 11]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 9
          }
        },
        "moduleName": "webui/pods/components/status-dropdown/status-panel-file-manager-upload/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "queues", ["loc", [null, [1, 6], [1, 12]]]]], [], 0, null, ["loc", [null, [1, 0], [27, 7]]]], ["content", "yield", ["loc", [null, [28, 0], [28, 9]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});