define('webui/pods/components/instance-type-list-item/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    instanceTypes: {},

    willRender: function willRender() {
      // needed to initialize dropdown select.
      var instanceTypes = {
        'g3.4xlarge': { label: 'g3.4xlarge - average jobs', selected: false, disabled: true },
        'g3s.xlarge': { label: 'g3s.xlarge - gpu steps', selected: false, disabled: true },
        'c5.4xlarge': { label: 'c5.4xlarge - non-gpu steps', selected: false, disabled: true },
        'c5.12xlarge': { label: 'c5.12xlarge - large non-gpu steps', selected: false, disabled: true }
      };
      var instanceType = this.get('instanceType');
      var canStart = this.get('canStart');

      /* If the job is destroyed it will still try to run through willRender and will fail. */
      if (instanceType) {
        instanceTypes[instanceType].selected = true;
        this.set('currentInstanceType', instanceTypes[instanceType].label);
      }

      if (canStart) {
        instanceTypes = _npmLodash.default.mapValues(instanceTypes, function (val) {
          val.disabled = false;
          return val;
        });
      }
      this.set('instanceTypes', instanceTypes);
    },


    actions: {
      updateInstanceType: function updateInstanceType(newInstanceType) {
        var instance = this.get('instance');
        this.sendAction('updateInstanceType', newInstanceType, instance);
      }
    }
  });
});