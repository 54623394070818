define("webui/pods/components/3d-embed/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 26,
                "column": 8
              }
            },
            "moduleName": "webui/pods/components/3d-embed/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "glyphicon-full glyphicons-file-export");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 48,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/3d-embed/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("iframe");
          dom.setAttribute(el1, "class", "embedded-fullscreen");
          dom.setAttribute(el1, "src", "");
          dom.setAttribute(el1, "id", "sketchfab-api-frame");
          dom.setAttribute(el1, "frameborder", "0");
          dom.setAttribute(el1, "allow", "autoplay; fullscreen; vr");
          dom.setAttribute(el1, "allowvr", "");
          dom.setAttribute(el1, "allowfullscreen", "");
          dom.setAttribute(el1, "mozallowfullscreen", "true");
          dom.setAttribute(el1, "webkitallowfullscreen", "true");
          dom.setAttribute(el1, "onmousewheel", "");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "glyph-container");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "download-container");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("strong");
          var el4 = dom.createTextNode("Export Dimensions (inches):");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "id", "exportHeight");
          dom.setAttribute(el3, "class", "exportDimensionsEdit");
          dom.setAttribute(el3, "placeholder", "Height");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        by\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "id", "exportWidth");
          dom.setAttribute(el3, "class", "exportDimensionsEdit");
          dom.setAttribute(el3, "placeholder", "Width");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn btn-primary download");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "glyphicon glyphicon-download-alt");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("Download Screenshot\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "glyphicon glyphicon-repeat spinner hidden");
          dom.setAttribute(el3, "id", "exportSpinner");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3, 1]);
          var element1 = dom.childAt(fragment, [5]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element2, [5]);
          var element5 = dom.childAt(element2, [7]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 1, 1);
          morphs[3] = dom.createAttrMorph(element1, 'class');
          morphs[4] = dom.createAttrMorph(element3, 'value');
          morphs[5] = dom.createAttrMorph(element3, 'onkeyup');
          morphs[6] = dom.createAttrMorph(element3, 'onblur');
          morphs[7] = dom.createAttrMorph(element4, 'value');
          morphs[8] = dom.createAttrMorph(element4, 'onkeyup');
          morphs[9] = dom.createAttrMorph(element4, 'onblur');
          morphs[10] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["map-control\n                  map-export\n                  ", ["subexpr", "if", [["get", "inExportMode", ["loc", [null, [21, 23], [21, 35]]]], "on", "off"], [], ["loc", [null, [21, 18], [21, 48]]]], "\n                  ", ["subexpr", "if", [["get", "viewerReady", ["loc", [null, [22, 23], [22, 34]]]], "", "inactive"], [], ["loc", [null, [22, 18], [22, 51]]]]]]], ["element", "action", ["toggleExportMode"], [], ["loc", [null, [23, 8], [23, 37]]]], ["block", "tool-tip", [], ["tooltipContent", "<p>Export Map</p>", "tooltipPlace", "left"], 0, null, ["loc", [null, [24, 8], [26, 21]]]], ["attribute", "class", ["concat", ["download-bar ", ["subexpr", "unless", [["get", "inExportMode", ["loc", [null, [30, 38], [30, 50]]]], "hidden"], [], ["loc", [null, [30, 29], [30, 61]]]]]]], ["attribute", "value", ["get", "exportHeight", ["loc", [null, [34, 91], [34, 103]]]]], ["attribute", "onkeyup", ["subexpr", "action", ["handleInputKeyup"], [], ["loc", [null, [34, 114], [34, 143]]]]], ["attribute", "onblur", ["subexpr", "action", ["handleHeightBlur"], [], ["loc", [null, [34, 151], [34, 180]]]]], ["attribute", "value", ["get", "exportWidth", ["loc", [null, [38, 89], [38, 100]]]]], ["attribute", "onkeyup", ["subexpr", "action", ["handleInputKeyup"], [], ["loc", [null, [38, 111], [38, 140]]]]], ["attribute", "onblur", ["subexpr", "action", ["handleWidthBlur"], [], ["loc", [null, [38, 148], [38, 176]]]]], ["element", "action", ["downloadScreenshot"], [], ["loc", [null, [40, 16], [40, 47]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 2
            },
            "end": {
              "line": 58,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/3d-embed/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "container");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-xs-12");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h2");
          var el5 = dom.createTextNode("Sorry! There isn't a 3D model of this flight yet.");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/3d-embed/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "three-d-model-container");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "flight.sketchfabModelId", ["loc", [null, [3, 8], [3, 31]]]]], [], 0, 1, ["loc", [null, [3, 2], [58, 9]]]], ["content", "yield", ["loc", [null, [60, 2], [60, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});