define('webui/mixins/chartist-legend', ['exports', 'npm:chartist'], function (exports, _npmChartist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({
    LegendPlugin: function LegendPlugin(options) {
      var defaultOptions = {
        className: '',
        classNames: false,
        removeAll: false,
        legendNames: false,
        clickable: true,
        onClick: null,
        position: 'top'
      };

      var cachedDOMPosition = void 0;

      if (options && options.position) {
        if (!(options.position === 'top' || options.position === 'bottom' || options.position instanceof HTMLElement)) {
          throw Error('The position you entered is not a valid position');
        }
        if (options.position instanceof HTMLElement) {
          // Detatch DOM element from options object, because Chartist.extend
          // currently chokes on circular references present in HTMLElements
          cachedDOMPosition = options.position;
          delete options.position;
        }
      }

      options = _npmChartist.default.extend({}, defaultOptions, options);

      if (cachedDOMPosition) {
        // Reattatch the DOM Element position if it was removed before
        options.position = cachedDOMPosition;
      }

      return function legend(chart) {
        function removeLegendElement() {
          var legendElement = chart.container.querySelector('.ct-legend');
          if (legendElement) {
            legendElement.parentNode.removeChild(legendElement);
          }
        }

        // Set a unique className for each series so that when a series is removed,
        // the other series still have the same color.
        function setSeriesClassNames() {
          options.series = options.series.map(function (series, seriesIndex) {
            if ((typeof series === 'undefined' ? 'undefined' : _typeof(series)) !== 'object') {
              series = {
                value: series
              };
            }
            series.className = series.className || chart.options.classNames.series + '-' + _npmChartist.default.alphaNumerate(seriesIndex);
            return series;
          });
        }

        function createLegendElement() {
          var legendElement = document.createElement('ul');
          legendElement.className = 'ct-legend';
          if (chart instanceof _npmChartist.default.Pie) {
            legendElement.classList.add('ct-legend-inside');
          }
          if (typeof options.className === 'string' && options.className.length > 0) {
            legendElement.classList.add(options.className);
          }
          legendElement.style.cssText = 'white-space:nowrap;overflow:hidden;';
          if (chart.options.width) {
            legendElement.style.cssText += 'width: ' + chart.options.width + 'px;margin: 0 auto;';
          }
          return legendElement;
        }

        // Get the right array to use for generating the legend.
        function getLegendNames(useLabels) {
          return options.legendNames || (useLabels ? chart.data.labels : options.series);
        }

        // Initialize the array that associates series with legends.
        // -1 indicates that there is no legend associated with it.
        function initSeriesMetadata(useLabels) {
          var seriesMetadata = new Array(options.series.length);
          for (var i = 0; i < options.series.length; i += 1) {
            seriesMetadata[i] = {
              data: options.series[i],
              label: useLabels ? chart.data.labels[i] : null,
              legend: -1
            };
          }
          return seriesMetadata;
        }

        function createNameElement(i, legendText, classNamesViable, seriesStartsActivated) {
          var li = document.createElement('li');
          li.classList.add('ct-series-' + i);
          // Append specific class to a legend element, if viable classes are given
          if (classNamesViable) {
            li.classList.add(options.classNames[i]);
          }
          if (!seriesStartsActivated) {
            li.classList.add('inactive');
          }
          li.setAttribute('data-legend', i);
          li.textContent = legendText;
          return li;
        }

        // Append the legend element to the DOM
        function appendLegendToDOM(legendElement) {
          if (!(options.position instanceof HTMLElement)) {
            switch (options.position) {
              case 'top':
                chart.container.insertBefore(legendElement, chart.container.childNodes[0]);
                break;

              case 'bottom':
                chart.container.insertBefore(legendElement, null);
                break;

              default:
                break;
            }
          } else {
            // Appends the legend element as the last child of a given HTMLElement
            options.position.insertBefore(legendElement, null);
          }
        }

        function addClickHandler(legendElement, legends, seriesMetadata, useLabels) {
          legendElement.addEventListener('click', function (e) {
            var li = e.target;
            if (li.parentNode !== legendElement || !li.hasAttribute('data-legend')) return;
            e.preventDefault();

            var legendIndex = parseInt(li.getAttribute('data-legend'), 10);
            var legendItem = legends[legendIndex];

            if (!legendItem.active) {
              legendItem.active = true;
              li.classList.remove('inactive');
            } else {
              legendItem.active = false;
              li.classList.add('inactive');

              var activeCount = legends.filter(function (filterLegend) {
                return filterLegend.active;
              }).length;
              if (!options.removeAll && activeCount === 0) {
                // If we can't disable all series at the same time, let's
                // reenable all of them:
                for (var i = 0; i < legends.length; i += 1) {
                  legends[i].active = true;
                  legendElement.childNodes[i].classList.remove('inactive');
                }
              }
            }

            var newSeries = [];
            var newLabels = [];

            for (var j = 0; j < seriesMetadata.length; j += 1) {
              if (seriesMetadata[j].legend !== -1 && legends[seriesMetadata[j].legend].active) {
                newSeries.push(seriesMetadata[j].data);
                newLabels.push(seriesMetadata[j].label);
              }
            }
            chart.data.series = newSeries;
            if (useLabels) {
              chart.data.labels = newLabels;
            }
            chart.updatedFromLegend = true;
            chart.update();

            if (options.onClick) {
              options.onClick(chart, e);
            }
          });
        }

        removeLegendElement();

        var legendElement = createLegendElement();
        var useLabels = chart instanceof _npmChartist.default.Pie && chart.data.labels && chart.data.labels.length;
        var legendNames = getLegendNames(useLabels);
        var seriesMetadata = initSeriesMetadata(useLabels);
        var legends = [];

        // Check if given class names are viable to append to legends
        var classNamesViable = Array.isArray(options.classNames) && options.classNames.length === legendNames.length;

        // Loop through all legends to set each name in a list item.
        legendNames.forEach(function (eachLegend, i) {
          var legendText = eachLegend.name || eachLegend;
          var legendSeries = eachLegend.series || [i];
          var seriesStartsActivated = options.startingSeries.indexOf(i) !== -1;

          var li = createNameElement(i, legendText, classNamesViable, seriesStartsActivated);
          legendElement.appendChild(li);

          legendSeries.forEach(function (seriesIndex) {
            seriesMetadata[seriesIndex].legend = i;
          });

          legends.push({
            text: legendText,
            series: legendSeries,
            active: seriesStartsActivated
          });
        });

        chart.on('created', function () {
          if (chart.updatedFromLegend) {
            chart.updatedFromLegend = false;
          } else {
            legendNames.forEach(function (eachLegend, i) {
              $(options.container + ' li.ct-series-' + i).addClass('inactive');
              legends[i].active = false;
            });

            chart.data.series.forEach(function (series) {
              var index = series.className.replace('color-series-', '');

              $(options.container + ' li.ct-series-' + index).removeClass('inactive');
              legends[index].active = true;
            });
          }

          appendLegendToDOM(legendElement);
        });

        if (options.clickable) {
          setSeriesClassNames();
          addClickHandler(legendElement, legends, seriesMetadata, useLabels);
        }
      };
    }
  });
});