define('webui/pods/components/table-edit-fields/region-color-picker/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    options: Ember.computed('availableColors.[]', function () {
      return [{ value: 'blue', label: 'blue' }, { value: 'red', label: 'red' }, { value: 'orange', label: 'orange' }, { value: 'yellow', label: 'yellow' }, { value: 'green', label: 'green' }, { value: 'grey', label: 'grey' }].map(function (color) {
        return { label: color.label, value: color.value };
      });
    })
  });
});