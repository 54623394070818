define('webui/pods/components/cross-sections-panel/component', ['exports', 'webui/mixins/data-panel', 'ember-cli-segment/mixin', 'webui/mixins/site-grade', 'webui/mixins/region-manager'], function (exports, _dataPanel, _mixin, _siteGrade, _regionManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dataPanel.default, _mixin.default, _siteGrade.default, _regionManager.default, {
    session: Ember.inject.service('session'),
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    mapData: Ember.inject.service('mapData'),
    regionMetaManager: Ember.inject.service('region-meta-manager'),
    dataPanelService: Ember.inject.service('data-panel'),
    statusPanel: Ember.inject.service('status-panel'),

    currentFlight: null,
    currentSite: null,

    dataType: 'cross-section',
    regionType: 'Cross Section Region',
    showUploader: false,
    showConfirm: false,
    closeDataPanel: 'closeDataPanel',
    listenerId: 'cross_section',
    socketUri: 'crosssections',
    socket: 'crossSection',

    lastBlueprintUpdateTime: Ember.observer('statusPanel.lastBlueprintUpdateTime', function lastBlueprintUpdateTime() {
      this.setUpComponent();
    }),
    /**
    * setUpComponent
    *
    * @description :: Redraws regions and resets listeners when the user switches
    *              :: between flights.
    */
    setUpComponent: function setUpComponent() {
      var mapData = this.get('mapData');
      if (!this.get('isReadOnly')) mapData.setListeningMode(this.listenerId);
      this.cleanUpRegionUI(this.dataType);
      this.setCrossSectionMarker();
      this.set('siteGrades', this.getSiteGrades());
      this.prepareRegions();
      this.get('mapData').resetDrawingMode();
    },
    prepareRegions: function prepareRegions() {
      var component = this;

      component.loadAndSetDrawData();
      component.subscribeToRegionAndEarthworkUpdates(this.dataType);
      component.draw();
      Ember.run.next(function () {
        component.setListeners(component.dataType);
      });
    },
    loadAndSetDrawData: function loadAndSetDrawData() {
      var sortedCrossSections = this.get('activeSite.crossSections').sortBy('name');
      this.set('draw-data', sortedCrossSections);
    },
    init: function init() {
      this._super();
      this.get('statusPanel');
      var mapData = this.get('mapData');
      var isReadOnly = this.get('authenticatedUser.isViewOnly');

      this.set('isReadOnly', isReadOnly);
      mapData.setListeningMode(this.listenerId);
      this.setupHoverFunction();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('activeSite.id') !== this.get('currentSite') || this.get('activeFlight.id') !== this.get('currentFlight')) {
        this.set('currentSite', this.get('activeSite.id'));
        this.set('currentFlight', this.get('activeFlight.id'));
        this.setUpComponent();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var component = this;

      if (this.get('activeSite.id') !== this.get('currentSite') || this.get('activeFlight.id') !== this.get('currentFlight')) {
        this.set('currentSite', this.get('activeSite.id'));
        this.set('currentFlight', this.get('activeFlight.id'));
        Ember.run.next(function () {
          component.setUpComponent();
        });
      }
    },
    didRender: function didRender() {
      $('#select_blueprint').val(this.get('currentSiteGradeBlueprint.id'));
      $('.regionElement input').on('keyup', function keyup(event) {
        if (event.keyCode === 13 || event.keyCode === 27) {
          $(this).blur();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('mapData').changeDrawingMode('move');
      this.cleanUpRegionUI(this.dataType);
      this.get('mapData').deleteListenersForQualifier(this.listenerId);
      this.removeHoverListeners();
      io.socket.off(this.socket);
    },
    draw: function draw() {
      var _this = this;

      var context = this;
      var mapData = this.get('mapData');
      var crossSections = this.get('draw-data').sortBy('name');
      var displayedCrossSections = crossSections.filter(function (section) {
        return section.display;
      });
      var lineFeatures = displayedCrossSections.map(function (crossSection) {
        return {
          id: crossSection.get('id'),
          color: crossSection.get('color'),
          observable_as: context.listenerId,
          points: crossSection.get('points'),
          type: 'LineString',
          isLocked: _this.get('isReadOnly')
        };
      });

      lineFeatures.forEach(function (lineFeature) {
        return mapData.drawFeature(lineFeature);
      });
      displayedCrossSections.forEach(function (crossSection) {
        return context.subscribeToNonEarthworkRegion(crossSection, context.dataType);
      });

      var regionsSource = {
        type: 'FeatureCollection',
        features: displayedCrossSections.map(function (crossSection) {
          return crossSection.get('mapboxPointFeature');
        })
      };

      mapData.getDrawSource().setData(regionsSource);
      context.addRegionLabelLayer();
      context.addHoverListeners();
    },
    getNewRegionParams: function getNewRegionParams(line) {
      var mapData = this.get('mapData');
      var pattern = new RegExp(/cross section (\d+)/);

      var newRecordParams = {
        name: 'cross section ' + this.getHighestNumberedRegion(pattern),
        points: line.features[0].geometry.coordinates.map(function (coords) {
          return { lng: coords[0], lat: coords[1] };
        }), // start and end point of line segment
        chunks: mapData.chunkLine(line.features[0]).map(function (coords) {
          return { lng: coords[0], lat: coords[1] };
        }),
        totalLength: mapData.calculateLength(line.features[0]), // feet
        site: mapData.get('currentSite'),
        blueprint: null,
        report: {},
        flights: [],
        status: 'pending'
      };

      return newRecordParams;
    },
    updateRegionProperties: function updateRegionProperties(region, newRegion) {
      var mapData = this.get('mapData');

      region.set('points', newRegion.features[0].geometry.coordinates.map(function (coords) {
        return { lng: coords[0], lat: coords[1] };
      }));
      region.set('chunks', mapData.chunkLine(newRegion.features[0]).map(function (coords) {
        return { lng: coords[0], lat: coords[1] };
      }));
      region.set('totalLength', mapData.calculateLength(newRegion.features[0]));
    },
    addHoverListeners: function addHoverListeners() {
      this.removeHoverListeners();
      this.get('mapData.mapInstance').on('mousemove', this.get('hoverFunction'));
    },
    setupHoverFunction: function setupHoverFunction() {
      var context = this;
      var mapData = this.get('mapData');
      var map = this.get('mapData.mapInstance');
      var store = this.get('store');
      var formatLegendValue = function formatLegendValue(num) {
        return num < 10 && num >= 0 ? '0' + String(num) : String(num);
      };
      var lastId = 0;
      var lastIndex = 0;

      this.set('hoverFunction', function (e) {
        var pointPadding = 10;
        var paddedPointBox = [new window.mapboxgl.Point(e.point.x - pointPadding, e.point.y - pointPadding), new window.mapboxgl.Point(e.point.x + pointPadding, e.point.y + pointPadding)];
        var features = map.queryRenderedFeatures(paddedPointBox);

        context.get('crossSectionMarker').setLngLat([0, 0]);

        if (features.length === 0) {
          $('#chart-' + lastId + ' .ct-grid.ct-horizontal:nth-of-type(' + lastIndex + ')').css('stroke-width', 0);

          var flightCounter = 0;
          var legendSelector = '#legend-' + lastId + ' .ct-series-';
          var legendValue = $(legendSelector + flightCounter).html();
          while (legendValue) {
            var legendValueLength = legendValue.indexOf(' —— ') !== -1 ? legendValue.indexOf(' —— ') : legendValue.length;
            $(legendSelector + flightCounter).html(legendValue.substring(0, legendValueLength));

            flightCounter += 1;
            legendValue = $(legendSelector + flightCounter).html();
          }
        } else {
          features.forEach(function (feature) {
            if (feature.properties.user_observable_as === 'cross_section' && mapData.drawingManager.getSelected().features.length === 0) {
              var cursorLat = e.lngLat.lat;
              var cursorLng = e.lngLat.lng;
              var id = feature.properties.id;

              var section = store.peekRecord('cross-section', id);

              if (!section) {
                // for new cross sections we will have the feature id, use this to get the section id
                id = mapData.get('polygonsByMbId.' + id);
                section = store.peekRecord('cross-section', id);
              }
              var data = section.get('report.series');
              var lowest_diff = Number.MAX_SAFE_INTEGER;
              var closest_index = 0;

              $('#chart-' + lastId + ' .ct-grid.ct-horizontal:nth-of-type(' + lastIndex + ')').css('stroke-width', 0);
              section.get('chunks').forEach(function (chunk, index) {
                var current_diff = Math.abs(chunk.lng - cursorLng) + Math.abs(chunk.lat - cursorLat);
                if (current_diff < lowest_diff) {
                  lowest_diff = current_diff;
                  closest_index = index;
                }
              });

              lastIndex = closest_index + 1;

              var griditem = $('#chart-' + id + ' .ct-grid.ct-horizontal:nth-of-type(' + (closest_index + 1) + ')');
              if (griditem[0]) {
                var rect1 = griditem[0].getBoundingClientRect();
                var rect2 = $('#chart-' + section.id + ' .ct-labels')[0].getBoundingClientRect();
                var overlap = rect1.left < rect2.right;
                if (!overlap) {
                  griditem.css('stroke-width', 2);
                }
              }

              context.get('crossSectionMarker').setLngLat([section.get('chunks')[closest_index].lng, section.get('chunks')[closest_index].lat]);

              var _flightCounter = 0;
              var _legendSelector = '#legend-' + id + ' .ct-series-';
              var _legendValue = $('' + _legendSelector + _flightCounter).html();
              while (_legendValue && data.length) {
                var _legendValueLength = _legendValue.indexOf(' —— ') !== -1 ? _legendValue.indexOf(' —— ') : _legendValue.length;
                if (data[_flightCounter] && data[_flightCounter][closest_index] && data[_flightCounter][closest_index].y) {
                  $('' + _legendSelector + _flightCounter).html(_legendValue.substring(0, _legendValueLength) + ' \u2014\u2014 ' + formatLegendValue(data[_flightCounter][closest_index].y));
                } else {
                  $('' + _legendSelector + _flightCounter).html('' + _legendValue.substring(0, _legendValueLength));
                }

                _flightCounter += 1;
                _legendValue = $('' + _legendSelector + _flightCounter).html();
              }
              lastId = id;
            }
          });
        }
      });
    },
    removeHoverListeners: function removeHoverListeners() {
      this.get('mapData.mapInstance').off('mousemove', this.get('hoverFunction'));
    },


    actions: {
      cancelRegionChanges: function cancelRegionChanges() {
        this.cleanUpRegionUI(this.dataType);
        this.draw();
      },
      saveRegionChanges: function saveRegionChanges() {
        this.saveRegionChanges();
      },
      goToFileManager: function goToFileManager() {
        this.sendAction('goToFileManager', this.get('activeSite.id'), 'dsm');
      },
      leavePanel: function leavePanel() {
        this.sendAction('closeDataPanel');
        $('#cross-sections-tool-bar').removeClass('active');
      },
      toggleSectionDisplay: function toggleSectionDisplay(section) {
        section.set('display', !section.get('display'));
        this.cleanUpRegionUI(this.dataType);
        this.draw();
      },
      removeRegion: function removeRegion(region) {
        var id = region.get('id');
        var store = this.get('store');
        var regions = this.get('draw-data');
        var mapData = this.get('mapData');
        var inputElement = $('input[name="' + id + '"]');

        inputElement.off('focus');
        inputElement.off('blur'); // These need to be removed to prevent a false trigger update event

        mapData.deletePolygonFromPanel(id);
        regions.removeObject(region);
        store.peekRecord(this.dataType, region.id).destroyRecord();

        this.updateRegionLabels();
        this.get('regionMetaManager').removeDrawingRegionHelperInformationLayer(); // this is handled in region manager for other types, but for some reason doesn't trigger for cross sections
      },
      newCrossSection: function newCrossSection() {
        this.setDrawingModeForNewRegions(this.listenerId);
      }
    }
  });
});