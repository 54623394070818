define('webui/models/flight', ['exports', 'ember-data', 'webui/mixins/string-formatting'], function (exports, _emberData, _stringFormatting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_stringFormatting.default, {
    executeAt: _emberData.default.attr('string'),
    subtitle: _emberData.default.attr('string'),
    sketchfabModelId: _emberData.default.attr('string'),
    youtubeVideoId: _emberData.default.attr('string'),
    flyoverVideos: _emberData.default.hasMany('flyover-video', { async: true }),
    site: _emberData.default.belongsTo('site', { async: true }),
    tiledMaps: _emberData.default.hasMany('tiled-map', { async: true }),
    boundaries: _emberData.default.attr(),
    jobs: _emberData.default.hasMany('job', { async: true }),
    correctionMethods: _emberData.default.hasMany('correction-method', { async: true }),
    products: _emberData.default.hasMany('product', { async: true }),
    regions: _emberData.default.hasMany('region', { async: true }),
    status: _emberData.default.attr('string'),
    disabled: _emberData.default.attr('boolean'),
    qualityReports: _emberData.default.hasMany('quality-report', { async: true }),
    photos: _emberData.default.hasMany('photo', { async: true }),
    pointSample: _emberData.default.belongsTo('point-sample', { async: true }),
    classificationStatus: _emberData.default.attr('string'),
    classificationPreviewStatus: _emberData.default.attr('string'),
    classificationError: _emberData.default.attr('string'),
    classificationPreviewError: _emberData.default.attr('string'),
    classifiedRegions: _emberData.default.attr(),
    classifiedRegionsForPreview: _emberData.default.attr(),
    communications: _emberData.default.hasMany('communication', { async: true }),
    trueTonnageReport: _emberData.default.belongsTo('true-tonnage-report', { async: false }),
    defaultVolumeCalcBaselpane: _emberData.default.belongsTo('blueprint', { async: false }),
    credits: _emberData.default.hasMany('credit', { async: true }),
    locked: _emberData.default.attr('boolean'),
    annotationLayers: _emberData.default.hasMany('annotation-layer', { async: false }),

    displayDate: function displayDate() {
      return this.dateString(this.get('executeAt'), true);
    }.property('executeAt'),

    displayFlightTime: function displayFlightTime() {
      return this.dateTimeString(this.get('executeAt'));
    }.property('executeAt'),

    displayFlightTimeForCrossSection: function displayFlightTimeForCrossSection() {
      return this.dateTimeString(this.get('executeAt'), '/', ':', '—', true);
    }.property('executeAt'),

    rasterProduct: Ember.computed('products', function rasterProduct() {
      var dsmProducts = this.get('products').filter(function (product) {
        return product.get('productType') === 'digital-surface-model' && product.get('fileUrl').substr(-8) === '_dsm.tif';
      });

      return dsmProducts[0] ? dsmProducts[0] : null;
    }),

    dsmId: Ember.computed('rasterProduct', function dsmId() {
      return this.get('rasterProduct') ? this.get('rasterProduct.id') : null;
    })
  });
});