define('webui/pods/meaningful-string/transform', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var meaningfulValue = serialized;
      var testVal = serialized || null;
      if (testVal === 'bool:false') {
        meaningfulValue = false;
      } else if (testVal === 'bool:true') {
        meaningfulValue = true;
      }
      return meaningfulValue;
    },
    serialize: function serialize(deserialized) {
      var meaningfulValue = deserialized;
      if (deserialized === false) {
        meaningfulValue = 'bool:false';
      } else if (deserialized === true) {
        meaningfulValue = 'bool:true';
      }
      return meaningfulValue;
    }
  });
});