define('webui/pods/components/tiled-map/component', ['exports', 'webui/mixins/measurements-on-map', 'webui/mixins/numerical-conversion', 'webui/mixins/map-export', 'ember-cli-segment/mixin'], function (exports, _measurementsOnMap, _numericalConversion, _mapExport, _mixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tiledMapTypesDisplayedUsingRightSideBar = ['digital-surface-model', 'contour', 'elevation-data', 'slope-data'];

  var slider = void 0;
  exports.default = Ember.Component.extend(_measurementsOnMap.default, _numericalConversion.default, _mapExport.default, _mixin.default, {
    mapData: Ember.inject.service('map-data'), // This service stores the google map (and google API) for sharing with other map components.
    feedback: Ember.inject.service('user-feedback'),
    session: Ember.inject.service('session'),
    socketManager: Ember.inject.service('socket-manager'),
    elevationLayerManager: Ember.inject.service('elevation-layer-manager'),
    store: Ember.inject.service('store'),
    dataPanelService: Ember.inject.service('data-panel'),
    pdfOptions: Ember.inject.service('pdf-options'),
    statusPanel: Ember.inject.service('status-panel'),
    originalZoom: undefined,
    originalCenter: undefined,
    originalPitch: undefined,
    originalBearing: undefined,
    trackData: {},
    originalDevicePixelRatio: window.devicePixelRatio,
    toggleMapSlider: 'toggleMapSlider',
    tiledMaps: 'tiledMaps',
    isMessagePanelDisplaying: false,
    currentSiteNeedsResetDueToNavigatingAwayFromMap: true,

    tiledMapUpdate: Ember.observer('socketManager.tiledMapUpdate', function tiledMapUpdate() {
      this.resetMaps(this.get('socketManager.tiledMapUpdate'));
      this.loadSuccessfullyProcessedMaps(this.get('socketManager.tiledMapUpdate'));
    }),

    resetMaps: function resetMaps(event) {
      var _this = this;

      tiledMapTypesDisplayedUsingRightSideBar.forEach(function (type) {
        var currentMaps = _this.get('tiledMaps.' + type);

        if (type === 'elevation-data') {
          currentMaps = [];
        } else if (currentMaps[_this.get('startPosition')] && currentMaps[_this.get('startPosition')].get('id') === event.id) {
          currentMaps[_this.get('startPosition')] = null;
        }
        _this.set('tiledMaps.' + type, currentMaps);
      });
    },
    loadSuccessfullyProcessedMaps: function loadSuccessfullyProcessedMaps(event) {
      var _this2 = this;

      var mapData = this.get('mapData');

      this.get('store').peekRecord('flight', this.get('flight.id')).get('tiledMaps').filter(function (map) {
        return map.get('status') === 'succeeded';
      }).forEach(function (tiledMap) {
        if (tiledMapTypesDisplayedUsingRightSideBar.indexOf(tiledMap.get('type')) !== -1) {
          var currentMaps = _this2.get('tiledMaps.' + tiledMap.get('type'));

          if (tiledMap.get('type') === 'elevation-data') {
            currentMaps[0] = tiledMap;
          } else {
            if (event.data && event.data.type === tiledMap.get('type')) {
              mapData.deactivateLayer(tiledMap.get('type'));
            }
            currentMaps[_this2.get('startPosition')] = tiledMap;
          }
          _this2.set('tiledMaps.' + tiledMap.get('type'), currentMaps);
        }
      });
      this.setMapToggles(this.get('startPosition'));
    },
    init: function init() {
      this._super();
      this.setMapDataExportMode();
      this.subscribeToSockets();
      if (this.get('allFlights')) {
        this.setMapSliderDefaults();
        this.setMapToggles(this.get('startPosition'));
        this.setInitialFlightDateDisplayForTimeLapse();
        var terrainLayer = this.get('site.flights').findBy('id', this.get('flight.id')).get('tiledMaps').findBy('type', '3d-terrain');
        this.set('has3DTerrain', terrainLayer && terrainLayer.get('status') === 'succeeded');
      }
    },
    setMapDataExportMode: function setMapDataExportMode() {
      this.get('mapData').set('inExportMode', false);
    },
    subscribeToSockets: function subscribeToSockets() {
      this.get('socketManager').monitorSockets(this.get('site.id'), this.get('flight.id'));
    },
    setMapSliderDefaults: function setMapSliderDefaults() {
      var orderedFlights = this.get('allFlights').sortBy('executeAt');
      var flightId = this.get('flight.id');
      var mapIndex = 0;
      orderedFlights.forEach(function (flight, index) {
        if (flight.get('id') === flightId) {
          mapIndex = index;
        }
      });
      this.set('startPosition', mapIndex);
      this.set('maxPosition', orderedFlights.length - 1);
    },
    setMapToggles: function setMapToggles(position) {
      this.set('currentOrtho', this.get('tiledMaps.orthomosaic').objectAt(position));
      this.set('currentDSM', this.get('tiledMaps.digital-surface-model').objectAt(position));
      this.set('currentContour', this.get('tiledMaps.contour').objectAt(position));
      this.set('currentSlopeData', this.get('tiledMaps.slope-data').objectAt(position));
    },
    setInitialFlightDateDisplayForTimeLapse: function setInitialFlightDateDisplayForTimeLapse() {
      var orderedFlights = this.get('allFlights').sortBy('executeAt');
      var flightDateDisplay = orderedFlights.objectAt(this.get('startPosition')).get('displayFlightTime');

      this.set('flightDateDisplay', flightDateDisplay);
      this.set('firstMapSelected', this.get('startPosition') === 0);
      this.set('lastMapSelected', this.get('startPosition') === orderedFlights.length - 1);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // initial state setup
      /*
       * This lifecycle hook can be used to set/read component state.
       */
      var mapData = this.get('mapData');

      if (this.updatedAttr('mapData.currentSiteId', this.get('site.id')) || this.get('currentSiteNeedsResetDueToNavigatingAwayFromMap')) {
        mapData.cleanUp('site');
        mapData.set('currentSite', this.get('site'));
        this.set('doCleanup', true); // this will trigger the clearMap method at didInsertElement.
        this.set('currentSiteNeedsResetDueToNavigatingAwayFromMap', false);
      }

      mapData.set('currentFlightId', this.get('flight.id'));
      mapData.set('activeFlight', this.get('flight'));

      this.loadExportImages(); // in MapExport mixin
      this.set('trackData', { siteName: this.get('site.name'), user: this.get('session.data.authenticated.email'), flight: this.get('flight.id') });
      this.parseHistoricalExports();
    },
    parseHistoricalExports: function parseHistoricalExports() {
      var _this3 = this;

      var historicalExports = this.get('store').peekAll('historical-export').filter(function (row) {
        return row.get('site.id') === _this3.get('activeSite.id');
      });
      var loggedInUserId = this.get('session.data.authenticated.id');
      var myExports = historicalExports.filter(function (historicalExport) {
        return historicalExport.get('user.id') === loggedInUserId;
      }).sortBy('fileName');
      var otherExports = historicalExports.filter(function (historicalExport) {
        return historicalExport.get('user.id') !== loggedInUserId;
      }).sortBy('fileName');

      this.set('hasHistoricalExports', historicalExports.toArray().length > 0);
      this.set('myExports', myExports);
      this.set('otherExports', otherExports);
    },
    didInsertElement: function didInsertElement() {
      var _this4 = this;

      /*
       * This lifecycle hook should be used for things that affect the component DOM directly.
       */
      var mapData = this.get('mapData');

      Ember.run.next(function () {
        // needs to be in next() to wait for the layer to be loaded.
        mapData.set('toggleLayerVisibilityChange', !mapData.get('toggleLayerVisibilityChange'));
      });

      document.onkeydown = function (e) {
        if (_this4.get('dataPanelService.mapSliderActivated')) {
          if (e.code === 'ArrowLeft') {
            e.preventDefault();
            _this4.viewPrevious();
          }
          if (e.code === 'ArrowRight') {
            e.preventDefault();
            _this4.viewNext();
          }
        }
      };

      if (this.get('allFlights')) {
        this.setupFlightSlider();
      }

      if (this.get('doCleanup')) {
        return this.clearMap();
      }

      return this.renderTiles();
    },
    setupFlightSlider: function setupFlightSlider() {
      var _this5 = this;

      var orderedFlights = this.get('allFlights').sortBy('executeAt');
      slider = $('#flight-slider').slider({ tooltip: 'hide' });

      slider.on('change', function (changed) {
        return _this5.setMapToggles(changed.value.newValue);
      });
      slider.on('change', function (changed) {
        _this5.set('flightDateDisplay', orderedFlights.objectAt(changed.value.newValue).get('displayFlightTime'));
        _this5.set('firstMapSelected', changed.value.newValue === 0);
        _this5.set('lastMapSelected', changed.value.newValue === orderedFlights.length - 1);
      });
      slider.on('change', _.debounce(function (changed) {
        return _this5.get('mapData').changeMap(changed.value.newValue);
      }, 500));
    },
    willDestroyElement: function willDestroyElement() {
      /*
        While this cleanup hook on the component lifecycle only concerns the measurement mixin...
        it makes more sense to put the lifecycle hook on the element that has a lifecycle--
        in this case, the component.
      */

      var mapData = this.get('mapData');
      mapData.cleanUp();
      return this.cleanUpDrawAndMeasure();
    },


    /* state-checking functions, pseudo-getters and setters */

    updatedAttr: function updatedAttr(parameter, value) {
      /* this checks for diff before doing update */
      var currentValue = this.get(parameter);
      if (currentValue !== value) {
        this.set(parameter, value);
        return true;
      }
      return false;
    },


    /* Methods for printing map tiles. */

    clearMap: function clearMap() {
      this.$('.map-canvas').empty();
      return this.renderTiles();
    },
    renderTiles: function renderTiles() {
      var mapData = this.get('mapData');

      mapData.createMapInstance(this.get('tiledMaps'), this.$('.tiled-map-canvas')[0], this.get('startPosition'));
      this.fetchQualityReportMarkerImage(mapData.mapInstance);
      this.fetchPictureMarkerImage(mapData.mapInstance);
      this.fetchGreenMarkerImage(mapData.mapInstance);
      this.fetchYellowMarkerImage(mapData.mapInstance);
      this.fetchRedMarkerImage(mapData.mapInstance);
      this.fetchArrowHeadImage(mapData.mapInstance);
    },
    fetchQualityReportMarkerImage: function fetchQualityReportMarkerImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('qualityReportMarkerImage')) {
        /* NOTE: In Mapbox v. 0.35 and higher there is a function "loadImage()" that will do all of this XHR stuff for us - consider this a filler until Mapbox is upgraded. */
        var i = new Image();

        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/images/glyphicons/png/glyphicons-378-riflescope.png');
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i.onload = function () {
            instance.addImage('riflescope', i);

            component.set('qualityReportMarkerImage', 'riflescope');

            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
          /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
           (i : any).cacheControl = imgData.cacheControl;
           (i : any).expires = imgData.expires;
           */
          i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr.send();
      }

      return instance;
    },
    fetchArrowHeadImage: function fetchArrowHeadImage(instance) {
      var component = this;

      if (!this.get('pictureArrowHeadImage')) {
        instance.loadImage('/assets/images/glyphicons/png/white-arrow-head.png', function (error, image) {
          instance.addImage('arrow-head', image, { sdf: true });
          component.set('pictureMarkerImage', 'arrow-head');
        });
      }

      return instance;
    },
    fetchPictureMarkerImage: function fetchPictureMarkerImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('pictureMarkerImage')) {
        /* NOTE: In Mapbox v. 0.35 and higher there is a function "loadImage()" that will do all of this XHR stuff for us - consider this a filler until Mapbox is upgraded. */
        var i = new Image();

        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/images/glyphicons/png/map-pin-32.png');
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i.onload = function () {
            instance.addImage('map-pin', i);

            component.set('pictureMarkerImage', 'map-pin');

            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
          /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
            (i : any).cacheControl = imgData.cacheControl;
            (i : any).expires = imgData.expires;
            */
          i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr.send();
      }

      return instance;
    },
    fetchGreenMarkerImage: function fetchGreenMarkerImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('greenMarkerImage')) {
        var i = new Image();
        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/images/glyphicons/png/green-pin.png');
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
          var urlCreator = window.URL || window.webkitURL;
          i.onload = function () {
            instance.addImage('green-circle', i);
            component.set('greenMarkerImage', 'green-circle');
            urlCreator.revokeObjectURL(i.src);
          };

          var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

          var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
          /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
            (i : any).cacheControl = imgData.cacheControl;
            (i : any).expires = imgData.expires;
            */
          i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
        };
        xhr.send();
      }

      return instance;
    },
    fetchYellowMarkerImage: function fetchYellowMarkerImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('yellowMarkerImage')) {
        if (!this.get('yellowMarkerImage')) {
          var i = new Image();
          var xhr = new XMLHttpRequest();
          xhr.open('GET', '/assets/images/glyphicons/png/yellow-pin.png');
          xhr.responseType = 'arraybuffer';
          xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            i.onload = function () {
              instance.addImage('yellow-circle', i);
              component.set('yellowMarkerImage', 'yellow-circle');
              urlCreator.revokeObjectURL(i.src);
            };

            var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

            var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
            /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
              (i : any).cacheControl = imgData.cacheControl;
              (i : any).expires = imgData.expires;
              */
            i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
          };
          xhr.send();
        }
      }

      return instance;
    },
    fetchRedMarkerImage: function fetchRedMarkerImage(instance) {
      /* Note, the actual layers using this are created in ../react/QualityReportsPanel.js, but it made sense to fetch the icon as soon as the map was created. */
      var component = this;

      if (!this.get('redMarkerImage')) {
        if (!this.get('redMarkerImage')) {
          var i = new Image();
          var xhr = new XMLHttpRequest();
          xhr.open('GET', '/assets/images/glyphicons/png/red-pin.png');
          xhr.responseType = 'arraybuffer';
          xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            i.onload = function () {
              instance.addImage('red-circle', i);
              component.set('redMarkerImage', 'red-circle');
              urlCreator.revokeObjectURL(i.src);
            };

            var transparentPngUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=';

            var blob = new window.Blob([new Uint8Array(xhr.response)], { type: 'image/png' });
            /* These are part of the function in 0.35 that I based this fill off of, but seem unnecessary?
              (i : any).cacheControl = imgData.cacheControl;
              (i : any).expires = imgData.expires;
              */
            i.src = xhr.response.byteLength ? urlCreator.createObjectURL(blob) : transparentPngUrl;
          };
          xhr.send();
        }
      }

      return instance;
    },
    resetMap: function resetMap() {
      var mapData = this.get('mapData');
      mapData.mapInstance.setPitch(0);
      mapData.mapInstance.resetNorth();
      mapData.fitMapToPoints(mapData.determineBoundaries(), 'lng', 'lat');
    },
    exportMap: function exportMap() {
      var mapData = this.get('mapData');

      if (mapData.inExportMode) {
        mapData.set('inExportMode', false);

        // Make the map fill the screen again
        $('.tiled-map-canvas').addClass('view-mode');
        this.resizeForViewMode('.tiled-map-canvas', true);

        // Reset the map to the pre-export pitch, bearing, zoom, and center (zoom + center = bounds in most cases, but fitBounds() can occassionally trigger MapBox bugs here if things are clicked quickly, so resetting these individually is a good option)
        mapData.mapInstance.setPitch(this.originalPitch);
        mapData.mapInstance.setBearing(this.originalBearing);
        mapData.mapInstance.setCenter(this.originalCenter);
        mapData.mapInstance.zoomTo(this.originalZoom); // We can only animate one thing here -- multiple animations entangle listeners and can cause an unrecoverable stage -- so we choose to animate zoom because it's the most perceptible to the user

        // Restore the original devicePixelRatio
        window.devicePixelRatioToUse = this.originalDevicePixelRatio;

        mapData.removeMinZoomRestriction('export map');

        this.displayExistingMeasurementPopup();
      } else {
        mapData.set('inExportMode', true);
        // Store the map's pre-export pitch, bearing, zoom, and center
        this.originalZoom = mapData.mapInstance.getZoom();
        this.originalCenter = mapData.mapInstance.getCenter();
        this.originalBearing = mapData.mapInstance.getBearing();
        this.originalPitch = mapData.mapInstance.getPitch();

        // Store the pre-export devicePixelRatio
        this.originalDevicePixelRatio = window.devicePixelRatio;
        mapData.addMinZoomRestriction('export map', 2);

        // Set a devicePixelRatio of 4 -- per https://github.com/mpetroff/print-maps/blob/master/js/script.js, DPI for PDF export is 96 times this
        window.devicePixelRatioToUse = 4;

        $('.tiled-map-canvas').removeClass('view-mode');
        this.resizeForExportMode('.tiled-map-canvas', true); // in MapExport mixin
        this.resetMap();

        this.removeMeasurementPopup();
      }
    },
    viewPrevious: function viewPrevious() {
      var minValue = 0;
      var currentFlightIndex = slider.slider('getValue');
      var previousFlightIndex = currentFlightIndex - 1;

      if (previousFlightIndex >= minValue) {
        slider.slider('setValue', previousFlightIndex, true, true);
      }
    },
    viewNext: function viewNext() {
      var maxValue = this.get('allFlights').length - 1;
      var currentFlightIndex = slider.slider('getValue');
      var nextFlightIndex = currentFlightIndex + 1;

      if (nextFlightIndex <= maxValue) {
        slider.slider('setValue', nextFlightIndex, true, true);
      }
    },


    actions: {
      viewPrevious: function viewPrevious() {
        this.viewPrevious();
      },
      viewNext: function viewNext() {
        this.viewNext();
      },
      toggleMapSlider: function toggleMapSlider() {
        var mapData = this.get('mapData');
        if (this.get('dataPanelService.mapSliderActivated')) {
          slider.slider('refresh');
          this.setInitialFlightDateDisplayForTimeLapse();
          this.get('mapData').changeMap(this.get('startPosition'));
          this.get('mapData.mapInstance.keyboard').enable();
        } else {
          this.get('mapData.mapInstance.keyboard').disable();

          if (this.get('mapData.inExportMode')) {
            this.exportMap();
          }

          if (this.get('elevationLayerManager.enabled')) {
            this.get('elevationLayerManager').disable();
          }

          if (mapData.get('currentActiveLayers.3d-terrain-layer')) {
            var toggle3DLayer = $('.toggle-3d-layer');
            toggle3DLayer.removeClass('on');
            toggle3DLayer.addClass('off');
            this.set('isIn3DMode', false);
            mapData.toggle3DTerrain();
            this.resetMap();
          }
        }

        this.sendAction('toggleMapSlider');
      },
      toggleFieldLinkPanel: function toggleFieldLinkPanel() {
        $('#field-link-send-modal').modal('show');
        $('#field-link-phone-number').focus();
      },
      handleInputKeyup: function handleInputKeyup(event) {
        if (event.keyCode === 13 || event.keyCode === 27) {
          event.srcElement.blur();
        }
      },
      handleWidthBlur: function handleWidthBlur() {
        var exportWidth = $('#exportWidth');
        var widthString = exportWidth.val();
        exportWidth.val(this.setWidth('.tiled-map-canvas', widthString)); // in MapExport mixin
      },
      handleHeightBlur: function handleHeightBlur() {
        var exportHeight = $('#exportHeight');
        var heightString = exportHeight.val();
        exportHeight.val(this.setHeight('.tiled-map-canvas', heightString)); // in MapExport mixin
      },
      recenterMap: function recenterMap() {
        this.resetMap();
      },
      toggleSatelliteView: function toggleSatelliteView() {
        var toggleSatLayer = $('.toggle-sat-layer');
        var mapData = this.get('mapData');
        if (mapData.get('currentActiveLayers.id-satellite-layer')) {
          toggleSatLayer.removeClass('on');
          toggleSatLayer.addClass('off');
        } else {
          toggleSatLayer.removeClass('off');
          toggleSatLayer.addClass('on');
        }
        return mapData.toggleLayerVisibility('id-satellite-layer');
      },
      toggle3DTerrain: function toggle3DTerrain() {
        if (!this.get('has3DTerrain')) {
          return;
        }

        var toggle3DLayer = $('.toggle-3d-layer');
        var mapData = this.get('mapData');

        if (mapData.get('currentDrawingMode') === 'line_measurement') {
          this.cleanUpDrawAndMeasure();
          mapData.revertListeningMode();
          mapData.changeDrawingMode('move');
        }

        var areWeTurning3DOn = !mapData.get('currentActiveLayers.3d-terrain-layer');
        this.set('isIn3DMode', areWeTurning3DOn);

        if (areWeTurning3DOn) {
          toggle3DLayer.removeClass('off');
          toggle3DLayer.addClass('on');
        } else {
          toggle3DLayer.removeClass('on');
          toggle3DLayer.addClass('off');
        }

        var newPitch = areWeTurning3DOn ? 5 : 0;
        var currentZoom = mapData.mapInstance.getZoom();
        var amountToZoomOut = currentZoom > 18 ? 18 : currentZoom;
        var newZoom = areWeTurning3DOn ? amountToZoomOut : currentZoom;
        mapData.mapInstance.setZoom(newZoom);
        mapData.mapInstance.setPitch(newPitch);
        mapData.toggle3DTerrain();
        setTimeout(function () {
          return mapData.mapInstance.easeTo({ zoom: mapData.mapInstance.getZoom() - 0.01, duration: 10 });
        }, 100);
      },


      /**
       * @Deprecated in favor of showMenu & hideMenu
       * */
      toggleMapLayerSelector: function toggleMapLayerSelector() {
        // show the selector for map layers.
        var selector = $('.map-layer-selector');

        if (selector.not(':visible').length > 0) {
          return selector.show();
        }
        return selector.hide();
      },
      measureDistance: function measureDistance() {
        if (this.get('isIn3DMode')) return null;

        var mapData = this.get('mapData');

        if (mapData.get('currentDrawingMode') === 'line_measurement') {
          this.cleanUpDrawAndMeasure();
          mapData.revertListeningMode();
          return mapData.changeDrawingMode('move');
        }

        this.trackEvent('Line Measurement', this.get('trackData'));
        return this.drawAndMeasureOnMap();
      },
      exportMap: function exportMap() {
        this.exportMap();
      },
      downloadMapPDF: function downloadMapPDF() {
        $('#exportSpinner').removeClass('hidden');
        Ember.run.debounce(this, function debounce() {
          this.createMapPDF(); // in MapExport mixin
        }, 100);
      },
      toggleElevation: function toggleElevation() {
        var componentName = 'tiled-map';
        if (!this.get('elevationLayerManager.enabled')) {
          this.get('elevationLayerManager').enable(componentName);
        } else {
          this.get('elevationLayerManager').disable(componentName);
        }
      },
      showMenu: function showMenu(selector) {
        var component = this;
        var key = selector.replace(/[^\w]*/gi, ''); /* Clean the selector of any special chars so we have a variable-safe string we can use to identify this timer instance */
        clearTimeout(component.get('menu-timer-' + key)); /* Stop any timers from hiding the menu while the user is hovering in it. */
        var menu = $(selector);
        if (menu.not(':visible').length > 0) {
          menu.show();
        }
      },
      hideMenu: function hideMenu(selector) {
        var component = this;
        var key = selector.replace(/[^\w]*/gi, ''); /* Clean the selector of any special chars so we have a variable-safe string we can use to identify this timer instance */
        component.set('menu-timer-' + key, setTimeout(function () {
          $(selector).hide();
        }, 300)); /* Set the timer to hide the menu after 300ms. */
      },
      openMessagePanel: function openMessagePanel() {
        $('._elevio_launcher').hide();
        this.set('isMessagePanelDisplaying', true);
      },
      closeMessagePanel: function closeMessagePanel() {
        $('._elevio_launcher').show();
        this.set('isMessagePanelDisplaying', false);
      },
      toggleStatusPanel: function toggleStatusPanel() {
        var display = this.get('statusPanel.display');
        this.set('statusPanel.display', !display);
      },
      removeTimeLapse: function removeTimeLapse() {
        this.get('dataPanelService').set('mapSliderActivated', false);
      }
    }
  });
});