define('webui/pods/components/classifications-panel/component', ['exports', 'webui/mixins/data-panel', 'ember-cli-segment/mixin', 'webui/mixins/region-manager', 'webui/config/environment'], function (exports, _dataPanel, _mixin, _regionManager, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tiledMapTypes = ['digital-surface-model', 'contour', 'elevation-data', 'slope-data'];

  exports.default = Ember.Component.extend(_dataPanel.default, _mixin.default, _regionManager.default, {
    session: Ember.inject.service('session'),
    feedback: Ember.inject.service('user-feedback'),
    store: Ember.inject.service('store'),
    mapData: Ember.inject.service('mapData'),
    socketManager: Ember.inject.service('socket-manager'),
    regionMetaManager: Ember.inject.service('region-meta-manager'),
    statePlane: Ember.inject.service('state-plane'),

    currentFlight: null,
    currentSite: null,

    waitingForAPI: false,
    pendingChanges: false,
    previewReady: false,
    hasClassifiedRaster: false,
    previewIsBeingRendered: false,
    classifiedSurfaceIsBeingRendered: false,
    TiledMapsAreStillRendering: false,

    displayPreviewError: false,
    displayClassificationError: false,
    displayAPIError: null,

    previewSelected: true,
    elevationTextPlaceholder: 'Enter elevation change (ft)',

    settingsManager: Ember.inject.service('settings-manager'),
    dataType: 'classification',
    closeDataPanel: 'closeDataPanel',
    listenerId: 'polygon_classification',
    socket: 'classifications',

    tiledMapUpdate: Ember.observer('socketManager.tiledMapUpdate', function tiledMapUpdate() {
      this.set('pendingChanges', false);
      this.setUpComponent();
    }),

    flightUpdate: Ember.observer('socketManager.flightUpdate', function flightUpdate() {
      this.set('pendingChanges', false);
      this.setUpComponent();
    }),

    init: function init() {
      this._super();
      this.get('socketManager');

      var component = this;
      var store = this.get('store');
      var mapData = this.get('mapData');
      var activeFlightId = this.get('activeFlight.id');
      var statePlane = this.get('statePlane');

      mapData.resetDrawingMode();
      mapData.setListeningMode(this.listenerId);
      component.setDeleteListener();
      component.turnOnDSM();

      component.set('apiEndPoint', _environment.default.APP.apiUrl + 'flights/' + activeFlightId + '/');

      var jobs = store.peekAll('job').filter(function (j) {
        return j.get('flight.id') === activeFlightId;
      });
      try {
        var epsgCode = jobs[0].get('epsgCode');
        var units = 'ft';
        if (epsgCode) {
          units = statePlane.epsgToUnits(epsgCode);
        }
        component.set('elevationTextPlaceholder', 'Enter elevation change (' + units + ')');
      } catch (e) {
        // some flights may not have a job
      }
    },
    turnOnDSM: function turnOnDSM() {
      var store = this.get('store');
      var mapData = this.get('mapData');
      var selector = '.layer-toggle.digital-surface-model';
      var activeFlightId = this.get('activeFlight.id');
      var activeFlight = store.peekRecord('flight', activeFlightId);
      var activeDSMCount = activeFlight.get('tiledMaps').filter(function (tiledMap) {
        return tiledMap.get('type') === 'digital-surface-model' && tiledMap.get('status') === 'succeeded';
      }).toArray().length;

      if (mapData.doesLayerExist('digital-surface-model') && !mapData.layerVisible('digital-surface-model') && activeDSMCount > 0) {
        mapData.toggleLayerVisibility('digital-surface-model');

        $(selector).addClass('on');
        $(selector + ' > .glyphicons-unchecked').removeClass('glyphicons-unchecked').addClass('glyphicons-check');
      }
    },
    turnOffDSM: function turnOffDSM() {
      var mapData = this.get('mapData');
      var selector = '.layer-toggle.digital-surface-model';

      if (mapData.layerVisible('digital-surface-model')) {
        mapData.toggleLayerVisibility('digital-surface-model');

        $(selector).removeClass('on');
        $(selector + ' > .glyphicons-unchecked').removeClass('glyphicons-unchecked').addClass('glyphicons-check');
        $(selector + ' > .glyphicons-check').removeClass('glyphicons-check').addClass('glyphicons-unchecked');
      }
    },
    setUpComponent: function setUpComponent() {
      var component = this;
      var mapData = this.get('mapData');
      var store = this.get('store');
      var activeFlightId = this.get('activeFlight.id');
      var activeFlight = store.peekRecord('flight', activeFlightId);
      var raster = activeFlight.get('products').filterBy('productType', 'digital-surface-model').filter(function (item) {
        return item.get('fileUrl').endsWith('_dsm.tif');
      }).get('firstObject');
      var pendingTiledMapCount = activeFlight.get('tiledMaps').filter(function (tiledMap) {
        return tiledMapTypes.indexOf(tiledMap.get('type')) !== -1 && tiledMap.get('status') === 'pending';
      }).toArray().length;
      var pendingChanges = this.get('pendingChanges') || activeFlight.get('classificationPreviewStatus') === 'failed';

      component.set('displayPreviewError', activeFlight.get('classificationPreviewStatus') === 'failed');
      component.set('displayClassificationError', activeFlight.get('classificationStatus') === 'failed');
      component.set('classificationError', activeFlight.get('classificationError') === 'Error Segmenting Triangles. Polygon boundaries intersect.' ? 'Polygon boundaries intersect. Please avoid sharp angles in classification regions.' : activeFlight.get('classificationError'));
      component.set('classificationPreviewError', activeFlight.get('classificationPreviewError') === 'Error Segmenting Triangles. Polygon boundaries intersect.' ? 'Polygon boundaries intersect. Please avoid sharp angles in classification regions.' : activeFlight.get('classificationPreviewError'));
      component.set('TiledMapsAreStillRendering', pendingTiledMapCount > 0);
      component.set('classifiedSurfaceIsBeingRendered', activeFlight.get('classificationStatus') === 'pending');

      this.set('previewSelected', true);
      this.set('pendingChanges', pendingChanges);
      if (raster) {
        this.set('hasClassifiedRaster', raster.get('fileUrl') !== raster.get('historicalFileUrl') && activeFlight.get('classifiedRegions').length > 0);
      }

      component.set('previewReady', !pendingChanges && activeFlight.get('classificationPreviewStatus') === 'succeeded');
      component.set('previewIsBeingRendered', activeFlight.get('classificationPreviewStatus') === 'pending');

      if (component.get('previewIsBeingRendered') || component.get('classifiedSurfaceIsBeingRendered')) {
        mapData.changeDrawingMode('static');
      } else {
        mapData.changeDrawingMode();
      }

      if (mapData.doesLayerExist('digital-surface-model-preview') && component.get('previewReady') && !mapData.layerVisible('digital-surface-model-preview') && !component.get('TiledMapsAreStillRendering')) {
        mapData.toggleLayerVisibility('digital-surface-model-preview');
      }

      if (!component.get('previewReady') && mapData.layerVisible('digital-surface-model-preview')) {
        mapData.toggleLayerVisibility('digital-surface-model-preview');
      }

      this.cleanUpRegionUI(this.dataType);
      this.prepareRegions();
    },
    prepareRegions: function prepareRegions() {
      var component = this;
      var store = this.get('store');
      var activeFlightId = this.get('activeFlight.id');
      var activeFlight = store.peekRecord('flight', activeFlightId);
      var classifications = activeFlight.get('classifiedRegionsForPreview') ? activeFlight.get('classifiedRegionsForPreview') : [];
      var regions = [];

      Object.keys(classifications).forEach(function (classification) {
        if (Object.prototype.hasOwnProperty.call(classifications, classification)) {
          var region = classifications[classification];
          var boundaries = region;
          var elevationChange = null;
          if (region.boundaries) {
            boundaries = region.boundaries;
            elevationChange = region.elevationChange;
          }
          regions.push({ boundaries: boundaries, elevationChange: elevationChange });
        }
      });

      component.set('draw-data', regions);
      component.draw();

      Ember.run.next(function () {
        component.setListeners(component.dataType);
      });
    },
    draw: function draw() {
      var _this = this;

      var mapData = this.get('mapData');
      var regions = this.get('draw-data');
      var count = 0;

      regions.forEach(function (region) {
        var points = region;
        var elevationChange = null;
        if (region.boundaries) {
          points = region.boundaries;
          elevationChange = region.elevationChange;
        }
        var featureProps = {
          id: '' + count,
          color: elevationChange !== null && elevationChange !== 0 ? 'red' : 'blue',
          observable_as: _this.listenerId,
          points: points,
          type: 'Polygon',
          elevationChange: elevationChange
        };

        mapData.drawFeature(featureProps);
        count += 1;
      });
    },
    updateRegion: function updateRegion(polygon) {
      var id = polygon.features[0].id;

      var currentClassifications = this.get('draw-data');
      var elevationChange = currentClassifications[id].elevationChange;

      this.saveNewRegion(polygon, 'classification', elevationChange);
    },


    // eslint-disable-next-line no-unused-vars
    saveNewRegion: function saveNewRegion(polygon) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'classification';
      var elevationChange = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var mapData = this.get('mapData');
      var store = this.get('store');
      var activeFlightId = this.get('activeFlight.id');
      var activeFlight = store.peekRecord('flight', activeFlightId);

      var id = polygon.features[0].id;

      var currentClassifications = this.get('draw-data');
      var boundaries = polygon.features[0].geometry.coordinates[0].map(function (coords) {
        return { lng: coords[0], lat: coords[1] };
      });

      if (polygon.type && polygon.type === 'draw.delete') {
        delete currentClassifications[id];
      } else {
        currentClassifications[id] = {
          boundaries: boundaries,
          elevationChange: elevationChange
        };
      }

      activeFlight.set('classifiedRegionsForPreview', currentClassifications);
      this.set('draw-data', currentClassifications);
      this.set('pendingChanges', true);
      this.set('previewReady', false);

      if (mapData.layerVisible('digital-surface-model-preview')) {
        mapData.toggleLayerVisibility('digital-surface-model-preview');
      }
      this.updateRegionFeatures(id);
    },
    setDeleteListener: function setDeleteListener() {
      var component = this;
      var store = this.get('store');
      var drawingManager = this.get('mapData.drawingManager');
      var regionMetaManager = this.get('regionMetaManager');
      var activeFlightId = this.get('activeFlight.id');
      var activeFlight = store.peekRecord('flight', activeFlightId);

      $('.maps-container').unbind('keyup');
      $('.maps-container').on('keyup', function (event) {
        if (event.keyCode === 8 || event.keyCode === 46) {
          var selectedRegions = drawingManager.getSelectedIds();

          if (selectedRegions.length > 0) {
            regionMetaManager.removeDrawingRegionHelperInformationLayer();
            var currentClassifications = component.get('draw-data');

            selectedRegions.forEach(function (regionId) {
              drawingManager.delete(regionId);
              delete currentClassifications[regionId];
            });

            activeFlight.set('classifiedRegionsForPreview', currentClassifications);
            component.set('draw-data', currentClassifications);
            component.set('pendingChanges', true);
            component.set('previewReady', false);
            component.set('activeRegionId', null);
          }
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var component = this;
      var store = this.get('store');
      var activeFlightId = component.get('activeFlight.id');
      var activeFlight = store.peekRecord('flight', activeFlightId);

      if (this.get('activeFlight.id') !== this.get('currentFlight')) {
        this.set('currentFlight', this.get('activeFlight.id'));
        activeFlight.reload().then(function () {
          component.setUpComponent();
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var component = this;
      if (this.get('activeFlight.id') !== this.get('currentFlight')) {
        this.set('currentFlight', this.get('activeFlight.id'));
        Ember.run.next(function () {
          return component.setUpComponent();
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      var mapData = this.get('mapData');

      this.updateDirtyPolygons(this.dataType);
      this.cleanUpRegionUI(this.dataType);
      this.turnOffDSM();

      mapData.deleteListenersForQualifier(this.listenerId);
      if (mapData.doesLayerExist('digital-surface-model-preview') && mapData.layerVisible('digital-surface-model-preview')) {
        mapData.toggleLayerVisibility('digital-surface-model-preview');
      }
    },
    hidePreviewLayer: function hidePreviewLayer() {
      if (this.get('mapData').layerVisible('digital-surface-model-preview')) {
        this.get('mapData').toggleLayerVisibility('digital-surface-model-preview');
      }
    },
    showPreviewLayer: function showPreviewLayer() {
      if (!this.get('mapData').layerVisible('digital-surface-model-preview')) {
        this.get('mapData').toggleLayerVisibility('digital-surface-model-preview');
      }
    },
    activateRegion: function activateRegion(id) {
      this.set('activeRegionId', id);
      if (id) {
        var activeElevationChange = this.get('draw-data')[id].elevationChange;
        this.set('activeRegionElevation', activeElevationChange);
      } else {
        this.set('activeRegionElevation', null);
      }
    },
    updateRegionFeatures: function updateRegionFeatures(regionId) {
      var mapData = this.get('mapData');
      var drawData = this.get('draw-data');
      var _drawData$regionId = drawData[regionId],
          boundaries = _drawData$regionId.boundaries,
          elevationChange = _drawData$regionId.elevationChange;

      var featureProps = {
        id: regionId,
        color: elevationChange !== null && elevationChange !== 0 ? 'red' : 'blue',
        observable_as: this.listenerId,
        points: boundaries,
        type: 'Polygon',
        elevationChange: elevationChange
      };

      mapData.drawFeature(featureProps);
    },


    actions: {
      cancelChanges: function cancelChanges() {
        var _this2 = this;

        var activeFlight = this.get('store').peekRecord('flight', this.get('activeFlight.id'));
        var previewTiledMap = activeFlight.get('tiledMaps').filterBy('type', 'digital-surface-model-preview').get('firstObject');

        if (previewTiledMap) {
          previewTiledMap.destroyRecord();
        }

        activeFlight.set('classificationPreviewStatus', 'none');
        activeFlight.set('classificationStatus', 'none');
        activeFlight.set('classifiedRegionsForPreview', activeFlight.get('classifiedRegions'));
        activeFlight.save().then(function () {
          return _this2.setUpComponent();
        });
      },
      generatePreview: function generatePreview() {
        if (this.get('TiledMapsAreStillRendering')) {
          return this.get('feedback').reportError({ detail: 'Surface editing is unavailable while map layers are still being rendered.' });
        }

        var component = this;
        var store = this.get('store');
        var activeFlightId = this.get('activeFlight.id');
        var activeFlight = store.peekRecord('flight', activeFlightId);
        var classifications = this.get('draw-data');
        var updatedClassifications = [];

        Object.keys(classifications).forEach(function (classification) {
          if (Object.prototype.hasOwnProperty.call(classifications, classification)) {
            updatedClassifications.push(classifications[classification]);
          }
        });

        component.set('waitingForAPI', true);

        var url = this.apiEndPoint + 'previewSurface';
        var data = JSON.stringify({ classifiedRegionsForPreview: updatedClassifications });
        return $.ajax({
          method: 'PUT', contentType: 'json', url: url, data: data, crossDomain: true, xhrFields: { withCredentials: true }
        }).always(function (res) {
          activeFlight.reload().then(function () {
            component.set('displayAPIError', !res.success);
            component.set('waitingForAPI', false);
            component.setUpComponent();
          });
        });
      },
      closeClassificationPanel: function closeClassificationPanel() {
        this.sendAction('closeDataPanel');
        $('#classifications-tool-bar').removeClass('active');
      },
      hidePreviewLayer: function hidePreviewLayer() {
        this.set('previewSelected', false);
        this.hidePreviewLayer();
      },
      showPreviewLayer: function showPreviewLayer() {
        this.set('previewSelected', true);
        this.showPreviewLayer();
      },
      showSaveModal: function showSaveModal() {
        $('#classificationConfirm').modal('show');
      },
      saveClassifications: function saveClassifications() {
        var component = this;
        var store = this.get('store');
        var activeFlightId = this.get('activeFlight.id');
        var activeFlight = store.peekRecord('flight', activeFlightId);
        var classifications = this.get('draw-data');
        var updatedClassifications = [];

        Object.keys(classifications).forEach(function (classification) {
          if (Object.prototype.hasOwnProperty.call(classifications, classification)) {
            updatedClassifications.push(classifications[classification]);
          }
        });

        component.set('saveWarning', false);
        component.set('waitingForAPI', true);
        $('#classificationConfirm').modal('hide');

        var url = this.apiEndPoint + 'classifySurface';
        var data = JSON.stringify({ classifiedRegions: updatedClassifications });
        $.ajax({
          method: 'PUT', contentType: 'json', url: url, data: data, crossDomain: true, xhrFields: { withCredentials: true }
        }).always(function (res) {
          activeFlight.reload().then(function () {
            component.set('displayAPIError', !res.success);
            component.set('waitingForAPI', false);
            component.setUpComponent();
          });
        });
        this.trackEvent('Surface Generated', { flight: activeFlightId });
      },
      showDeleteModal: function showDeleteModal() {
        $('#classificationRemoveConfirm').modal('show');
      },
      deleteRegions: function deleteRegions() {
        var _this3 = this;

        if (this.get('TiledMapsAreStillRendering')) {
          this.set('deleteWarning', false);
          $('#classificationRemoveConfirm').modal('hide');
          return this.get('feedback').reportError({ detail: 'Removal of classifications is unavailable while map layers are still being rendered.' });
        }

        var component = this;
        var store = this.get('store');
        var activeFlightId = this.get('activeFlight.id');
        var activeFlight = store.peekRecord('flight', activeFlightId);
        var url = this.apiEndPoint + 'removeClassifications';

        component.set('activeRegionId', null);
        component.set('deleteWarning', false);
        component.set('waitingForAPI', true);
        $('#classificationRemoveConfirm').modal('hide');

        return $.ajax({
          method: 'PUT', contentType: 'json', url: url, crossDomain: true, xhrFields: { withCredentials: true }
        }).always(function (res) {
          activeFlight.reload().then(function () {
            activeFlight.set('classifiedRegionsForPreview', []);
            component.cleanUpRegionUI(_this3.dataType);
            component.prepareRegions();

            component.set('displayAPIError', !res.success);
            component.set('waitingForAPI', false);
            component.setUpComponent();
          });
        });
      },
      newRegion: function newRegion() {
        this.setDrawingModeForNewRegions(this.listenerId);
      },
      updateElevation: function updateElevation(value) {
        var updatedValue = parseFloat(value);
        if (isNaN(updatedValue) || updatedValue === 0) {
          updatedValue = null;
        }
        var id = this.get('activeRegionId');
        var classifications = this.get('draw-data');
        classifications[id].elevationChange = updatedValue;
        this.set('pendingChanges', true);
        this.updateRegionFeatures(id);
      }
    }
  });
});