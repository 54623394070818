define('webui/mixins/numerical-conversion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    metersToFeet: function metersToFeet(val) {
      return Math.round(val * 3.2808398950131 * 10) / 10;
    },
    squareMetersToSquareFeet: function squareMetersToSquareFeet(val) {
      return Math.round(val * 10.764 * 10) / 10;
    },
    squareMetersToAcres: function squareMetersToAcres(val) {
      return Math.round(val * 0.000247105 * 10) / 10;
    },
    squareFeetToAcres: function squareFeetToAcres(val) {
      return Math.round(val * 0.000022956841139 * 10) / 10;
    },


    degreesToGrade: {
      0: 0,
      0.1: 0.17,
      0.2: 0.35,
      0.3: 0.52,
      0.4: 0.70,
      0.5: 0.87,
      0.57: 1,
      0.6: 1.05,
      0.7: 1.22,
      0.8: 1.40,
      0.9: 1.57,
      1: 1.75,
      2: 3.49,
      3: 5.24,
      4: 6.99,
      5: 8.75,
      6: 10.5,
      7: 12.3,
      8: 14.1,
      9: 15.8,
      10: 17.6,
      11: 19.4,
      12: 21.3,
      13: 23.1,
      14: 24.9,
      15: 26.8,
      16: 28.7,
      17: 30.6,
      18: 32.5,
      19: 34.4,
      20: 36.4,
      21: 38.4,
      22: 40.4,
      23: 42.4,
      24: 44.5,
      25: 46.6,
      26: 48.8,
      27: 51.0,
      28: 53.2,
      29: 55.4,
      30: 57.7,
      31: 60.1,
      32: 62.5,
      33: 64.9,
      34: 67.5,
      35: 70.0,
      36: 72.7,
      37: 75.4,
      38: 78.1,
      39: 81.0,
      40: 83.9,
      41: 86.9,
      42: 90.0,
      43: 93.3,
      44: 96.6,
      45: 100.0,
      46: 103.6,
      47: 107.2,
      48: 111.1,
      49: 115.0,
      50: 119.2,
      51: 123.5,
      52: 128.0,
      53: 132.7,
      54: 137.6,
      55: 142.8,
      56: 148.3,
      57: 154.0,
      58: 160.0,
      59: 166.4,
      60: 173.2,
      61: 180.4,
      62: 188.1,
      63: 196.3,
      64: 205.0,
      65: 214.5,
      66: 224.6,
      67: 235.6,
      68: 247.5,
      69: 260.5,
      70: 274.7,
      71: 290.4,
      72: 307.8,
      73: 327.1,
      74: 348.7,
      75: 373.2,
      76: 401.1,
      77: 433.1,
      78: 470.5,
      79: 514.5,
      80: 567.1,
      81: 631.4,
      82: 711.5,
      83: 814.4,
      84: 951.4,
      85: 1143,
      86: 1430,
      87: 1908,
      88: 2864,
      89: 5729,
      90: '&infin;'
    }

  });
});