define("webui/pods/components/site-access-panel/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 8
                },
                "end": {
                  "line": 31,
                  "column": 8
                }
              },
              "moduleName": "webui/pods/components/site-access-panel/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1, "class", "radio-inline");
              var el2 = dom.createElement("input");
              dom.setAttribute(el2, "type", "radio");
              dom.setAttribute(el2, "value", "admin");
              dom.setAttribute(el2, "name", "access");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Full Access");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'onchange');
              return morphs;
            },
            statements: [["attribute", "onchange", ["subexpr", "action", ["setInviteUserAccess", "site:admin"], [], ["loc", [null, [30, 93], [30, 138]]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 6
              }
            },
            "moduleName": "webui/pods/components/site-access-panel/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h5");
            var el3 = dom.createTextNode("Choose access level");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "radio-inline");
            var el3 = dom.createElement("input");
            dom.setAttribute(el3, "type", "radio");
            dom.setAttribute(el3, "value", "view");
            dom.setAttribute(el3, "name", "access");
            dom.setAttribute(el3, "checked", "");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Limited Access");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "radio-inline");
            var el3 = dom.createElement("input");
            dom.setAttribute(el3, "type", "radio");
            dom.setAttribute(el3, "value", "adminview");
            dom.setAttribute(el3, "name", "access");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("View Only");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "radio-inline");
            var el3 = dom.createElement("input");
            dom.setAttribute(el3, "type", "radio");
            dom.setAttribute(el3, "value", "accounting");
            dom.setAttribute(el3, "name", "access");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Accounting Access");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            if (this.cachedFragment) {
              dom.repairClonedNode(dom.childAt(element1, [3, 0]), [], true);
            }
            var element2 = dom.childAt(element1, [3, 0]);
            var element3 = dom.childAt(element1, [5, 0]);
            var element4 = dom.childAt(element1, [7, 0]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'onchange');
            morphs[1] = dom.createAttrMorph(element3, 'onchange');
            morphs[2] = dom.createAttrMorph(element4, 'onchange');
            morphs[3] = dom.createMorphAt(element1, 9, 9);
            return morphs;
          },
          statements: [["attribute", "onchange", ["subexpr", "action", ["setInviteUserAccess", "site:limited"], [], ["loc", [null, [26, 100], [26, 147]]]]], ["attribute", "onchange", ["subexpr", "action", ["setInviteUserAccess", "site:view"], [], ["loc", [null, [27, 97], [27, 141]]]]], ["attribute", "onchange", ["subexpr", "action", ["setInviteUserAccess", "site:accounting"], [], ["loc", [null, [28, 98], [28, 148]]]]], ["block", "if", [["get", "authenticatedUser.isSiteAdmin", ["loc", [null, [29, 14], [29, 43]]]]], [], 0, null, ["loc", [null, [29, 8], [31, 15]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 43,
              "column": 2
            }
          },
          "moduleName": "webui/pods/components/site-access-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "add-users");
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          dom.setAttribute(el2, "class", "map-uploads");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "form-group");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "center");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn accent");
          dom.setAttribute(el4, "type", "submit");
          var el5 = dom.createTextNode("SEND INVITATION");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "center");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn-link");
          var el5 = dom.createTextNode("CANCEL");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1]);
          var element6 = dom.childAt(element5, [5, 1]);
          var element7 = dom.childAt(element5, [7, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element5);
          morphs[1] = dom.createElementMorph(element5);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
          morphs[3] = dom.createMorphAt(element5, 3, 3);
          morphs[4] = dom.createAttrMorph(element6, 'disabled');
          morphs[5] = dom.createAttrMorph(element7, 'onclick');
          return morphs;
        },
        statements: [["element", "action", ["submitInvitation", ["get", "inviteUser", ["loc", [null, [19, 38], [19, 48]]]], ["get", "inviteUserAccess", ["loc", [null, [19, 49], [19, 65]]]]], ["on", "submit"], ["loc", [null, [19, 10], [19, 79]]]], ["element", "action", ["checkEmail", ["get", "inviteUser", ["loc", [null, [19, 102], [19, 112]]]]], ["on", "input"], ["loc", [null, [19, 80], [19, 125]]]], ["inline", "form-input", [], ["value", ["subexpr", "@mut", [["get", "inviteUser", ["loc", [null, [21, 27], [21, 37]]]]], [], []], "class", "emails", "placeholder", "user@company.com", "onchange", ["subexpr", "@mut", [["get", "checkEmail", ["loc", [null, [21, 93], [21, 103]]]]], [], []]], ["loc", [null, [21, 8], [21, 105]]]], ["block", "if", [["subexpr", "or", [["get", "authenticatedUser.isSiteAdmin", ["loc", [null, [23, 16], [23, 45]]]], ["get", "authenticatedUser.isViewOnly", ["loc", [null, [23, 46], [23, 74]]]]], [], ["loc", [null, [23, 12], [23, 75]]]]], [], 0, null, ["loc", [null, [23, 6], [33, 13]]]], ["attribute", "disabled", ["subexpr", "not", [["get", "validEmail", ["loc", [null, [35, 64], [35, 74]]]]], [], ["loc", [null, [35, 58], [35, 76]]]]], ["attribute", "onclick", ["subexpr", "action", ["cancelInvitation"], [], ["loc", [null, [38, 41], [38, 70]]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.0",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 4
              },
              "end": {
                "line": 55,
                "column": 4
              }
            },
            "moduleName": "webui/pods/components/site-access-panel/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "site-access-panel-user-card", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [51, 13], [51, 17]]]]], [], []], "authenticatedUser", ["subexpr", "@mut", [["get", "authenticatedUser", ["loc", [null, [52, 26], [52, 43]]]]], [], []], "removeUserRelation", ["subexpr", "action", ["removeUserRelation"], [], ["loc", [null, [53, 27], [53, 56]]]]], ["loc", [null, [50, 6], [54, 8]]]]],
          locals: ["user"],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 57,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/site-access-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "access-body row");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "sortedSiteUsers", ["loc", [null, [49, 12], [49, 27]]]]], [], 0, null, ["loc", [null, [49, 4], [55, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.0",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "webui/pods/components/site-access-panel/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "placeholder");
          var el2 = dom.createTextNode("There are no users assigned to this site yet.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "webui/pods/components/site-access-panel/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel-header row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-10 text-left");
        var el3 = dom.createTextNode("\n    Site Access\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-2 text-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "glyphicon glyphicon-remove");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel-body");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main-field newRegionButton");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn");
        var el4 = dom.createTextNode("+ ADD USER TO SITE");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createElement("hr");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 3, 1]);
        var element9 = dom.childAt(fragment, [2]);
        var element10 = dom.childAt(element9, [1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element8);
        morphs[1] = dom.createAttrMorph(element10, 'disabled');
        morphs[2] = dom.createAttrMorph(element10, 'onclick');
        morphs[3] = dom.createMorphAt(element9, 3, 3);
        morphs[4] = dom.createMorphAt(element9, 7, 7);
        morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["element", "action", ["leavePanel"], [], ["loc", [null, [6, 45], [6, 68]]]], ["attribute", "disabled", ["get", "addUserMode", ["loc", [null, [13, 35], [13, 46]]]]], ["attribute", "onclick", ["subexpr", "action", ["beginInvitation"], [], ["loc", [null, [13, 57], [13, 85]]]]], ["block", "if", [["get", "addUserMode", ["loc", [null, [16, 8], [16, 19]]]]], [], 0, null, ["loc", [null, [16, 2], [43, 9]]]], ["block", "if", [["get", "activeSiteUsers", ["loc", [null, [47, 6], [47, 21]]]]], [], 1, 2, ["loc", [null, [47, 0], [59, 7]]]], ["content", "yield", ["loc", [null, [63, 0], [63, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});