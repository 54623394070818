define('webui/pods/admin/site/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    siteService: Ember.inject.service('site'),

    updateSites: function updateSites(data) {
      var controller = this.get('controller');
      if (data) {
        controller.set('model', data.sortBy('name'));
      }
    },


    actions: {
      updateSites: function updateSites(data) {
        this.updateSites(data);
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('siteService', this.get('siteService'));
      this.set('controller', controller);
      this.updateSites(this.get('store').peekAll('site'));
      controller.set('columns', [{
        propertyName: 'name',
        routeName: 'admin.site.edit'
      }, {
        propertyName: 'displayStatus',
        title: 'Status',
        filterWithSelect: true
      }, {
        propertyName: 'preferSiteBoundariesForMapZoom',
        title: 'Prefer Site Boundaries',
        filterWithSelect: true
      }, {
        component: 'clients-for-table',
        title: 'Client'
      }]);
    }
  });
});