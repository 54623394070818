define("webui/pods/components/table-edit-fields/region-color-picker/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 9
          }
        },
        "moduleName": "webui/pods/components/table-edit-fields/region-color-picker/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("select");
        dom.setAttribute(el1, "name", "color");
        dom.setAttribute(el1, "class", "form-control selectpicker");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("option");
        dom.setAttribute(el2, "value", "blue");
        dom.setAttribute(el2, "key", "blue");
        var el3 = dom.createTextNode("blue");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("option");
        dom.setAttribute(el2, "value", "red");
        dom.setAttribute(el2, "key", "red");
        var el3 = dom.createTextNode("red");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("option");
        dom.setAttribute(el2, "value", "orange");
        dom.setAttribute(el2, "key", "orange");
        var el3 = dom.createTextNode("orange");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("option");
        dom.setAttribute(el2, "value", "yellow");
        dom.setAttribute(el2, "key", "yellow");
        var el3 = dom.createTextNode("yellow");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("option");
        dom.setAttribute(el2, "value", "green");
        dom.setAttribute(el2, "key", "green");
        var el3 = dom.createTextNode("green");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("option");
        dom.setAttribute(el2, "value", "grey");
        dom.setAttribute(el2, "key", "grey");
        var el3 = dom.createTextNode("grey");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(element0, [7]);
        var element5 = dom.childAt(element0, [9]);
        var element6 = dom.childAt(element0, [11]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'onchange');
        morphs[1] = dom.createAttrMorph(element1, 'selected');
        morphs[2] = dom.createAttrMorph(element2, 'selected');
        morphs[3] = dom.createAttrMorph(element3, 'selected');
        morphs[4] = dom.createAttrMorph(element4, 'selected');
        morphs[5] = dom.createAttrMorph(element5, 'selected');
        morphs[6] = dom.createAttrMorph(element6, 'selected');
        return morphs;
      },
      statements: [["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "record.color", ["loc", [null, [1, 79], [1, 91]]]]], [], ["loc", [null, [1, 74], [1, 92]]]]], ["value", "target.value"], ["loc", [null, [1, 65], [1, 115]]]]], ["attribute", "selected", ["subexpr", "eq", ["blue", ["get", "record.color", ["loc", [null, [2, 55], [2, 67]]]]], [], ["loc", [null, [2, 43], [2, 69]]]]], ["attribute", "selected", ["subexpr", "eq", ["red", ["get", "record.color", ["loc", [null, [3, 52], [3, 64]]]]], [], ["loc", [null, [3, 41], [3, 66]]]]], ["attribute", "selected", ["subexpr", "eq", ["orange", ["get", "record.color", ["loc", [null, [4, 61], [4, 73]]]]], [], ["loc", [null, [4, 47], [4, 75]]]]], ["attribute", "selected", ["subexpr", "eq", ["yellow", ["get", "record.color", ["loc", [null, [5, 61], [5, 73]]]]], [], ["loc", [null, [5, 47], [5, 75]]]]], ["attribute", "selected", ["subexpr", "eq", ["green", ["get", "record.color", ["loc", [null, [6, 58], [6, 70]]]]], [], ["loc", [null, [6, 45], [6, 72]]]]], ["attribute", "selected", ["subexpr", "eq", ["grey", ["get", "record.color", ["loc", [null, [7, 55], [7, 67]]]]], [], ["loc", [null, [7, 43], [7, 69]]]]]],
      locals: [],
      templates: []
    };
  }());
});